import React                                      from 'react';
import {StyledSettingsWrapper}                    from "../settings/styles";
import {StyledStepFormWrapper, StyledStepWrapper} from "../createRequest/components/generalStep/styles";
import {Heading2}                                 from "../../globalStyledComponents";
import FieldsBuilder           from "../../uiKit/fieldsBuilder";
import Button                  from "../../uiKit/button/button";
import Form                    from '@atlaskit/form';
import {IFormField}            from "../../uiKit/fieldsBuilder/interfaces";
import {getFormError}          from "../../formUtils/getFormError";
import {ISelectItem}           from "../../uiKit/select";
import {useGetDict}            from "../../hooks/useGetDicti";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_REPORT}                    from "../../utils/mutations";
import {GET_AGREEMENT_AGENTS, ME} from "../../utils/queries";
import {ErrorMessage}                     from "../../components/errorMessage";
import {getFormValue}          from "../../formUtils/getFormValue";
import moment                  from "moment";
import {formatDateToUsa}       from "../../formUtils/fieldsValidation";
import {useCallNotification}   from "../../hooks/useCallNotification";
import {fileDownloader}        from "../../helpers/fileDownloader";

const selectReportTypes: ISelectItem[] = [
  {label: "Отчет по заключенным договорам", value: "1"},
]

const Reports = () => {
  const {setNotification} = useCallNotification();
  const {data: selectBanks, loading: loadingBanks} = useGetDict("banks");
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const [createReport, {loading: loadingCreateReport}] = useMutation(CREATE_REPORT);
  const {data: dataAgents, loading: loadingAgents} = useQuery(GET_AGREEMENT_AGENTS);
  const selectAgents = ((dataAgents||{}).getAgent||[]).map(({SUBJISN, AGENTNAME}) => ({label: AGENTNAME, value: SUBJISN}));
  const {kias} = (dataUser || {}).me || {};
  const {UpperDeptISN} = kias || {};


  const form: IFormField[] = [
    {
      fieldItems: {
        type: "select",
        name: "reportType",
        placeholder: "Отчет",
        isRequired: true,
        errorMessage: "Выберите отчет",
        defaultValue: selectReportTypes[0],
        items: selectReportTypes
      }
    },
    {
      columns: 2, columnGap: 4,
      fieldItems: [
        {
          type: "date",
          name: "dateBeg",
          placeholder: "Дата подписания с",
          isRequired: true,
          errorMessage: "Выберите дату начала периода",
        },
        {
          type: "date",
          name: "dateEnd",
          placeholder: "Дата подписания по",
          isRequired: true,
          errorMessage: "Выберите дату окончания периода",
        }
      ]
    },
    {
      isHidden: UpperDeptISN === "171602000",
      fieldItems: {
        type: "select",
        name: "frontNodeIsn",
        isLoading: loadingAgents,
        items: selectAgents,
        placeholder: "Точка продаж",
        isSearchAvailable: true,
        isClearable: true
      }
    }
  ];

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {dateBeg, dateEnd, frontNodeIsn} = getFormValue(data);
    console.log(getFormValue(data))
    if (moment(formatDateToUsa(dateEnd)).isBefore(formatDateToUsa(dateBeg))) return setNotification({
      type: "error",
      text: "Дата окончания не может быть раньше даты начала"
    })
    createReport({
      variables: {
        ...getFormValue(data),
        frontNodeIsn: Number(frontNodeIsn) || null
      }
    })
      .then(({data: {getReportSign: {Document: {Bytes, FileName}}}}) => {
        fileDownloader(`data:image/png;base64,${Bytes}`, FileName)
      })
      .catch(error => setNotification({type: "error", text: error}))
  }

  if (errorUser) return <ErrorMessage error={errorUser}/>

  return (
    <StyledSettingsWrapper>
      <StyledStepWrapper>
        <Heading2>Отчеты</Heading2>
        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps}>
              <StyledStepFormWrapper>
                <FieldsBuilder formFields={form}/>
              </StyledStepFormWrapper>
              <Button
                isLoading={loadingCreateReport}
                isDisabled={false}
                type="submit"
                width="100%" appearance="filled">
                Скачать отчет
              </Button>
            </form>
          )}
        </Form>
      </StyledStepWrapper>
    </StyledSettingsWrapper>
  )
};

export default Reports;