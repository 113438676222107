import React                   from 'react';
import {
  StyledCalculationDataWrapper, StyledRemark,
  StyledRiskFormWrapper, StyledRiskInformation
}                              from "./styles";
import Toggle                  from "../../../../uiKit/toggle";
import {IFormField}            from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder
                               from "../../../../uiKit/fieldsBuilder";
import {useParams}             from "react-router";
import {useQuery}              from "@apollo/react-hooks";
import {DOCS, GET_AGREEMENT}   from "../../../../utils/queries";
import {
  ALLOWANCE_ISN,
  BORROWER_ALLOWANCE_ISN,
  BORROWER_SUB_CLASS_ISN,
  CLASS_ISN_BORROWERS,
  CLASS_ISN_OBJECTS,
  CLASS_ISN_TITLES,
  DISCOUNT_ISN,
  getKiasDate,
  getValueFromObjectsAttributes,
  HEIGHT_WEIGHT_ISN,
  PRESSURE_ISN,
  PROPERTY_COST_ISN,
  PROPERTY_YEAR_OF_BUILD_ISN,
  REMARK_ISN,
  SHARE_ISN,
  TARIFF_ISN
}                              from "../../../../helpers/buildTemplateForBack";
import {Heading4, TextButton}  from "../../../../globalStyledComponents";
import {ErrorMessage}          from "../../../../components/errorMessage";
import {StyledInformationItem} from "../../../applicationById/components/header/styles";
import {money}                 from "../../../../utils/formats";
import {QuestionIcon}          from "../../../../icons/ui/Question";
import {stringToNumber}        from "../../../../formUtils/getFormValue";

interface IFormCategoryItem {
  title: string,
  risks: any[],
  forms: { form: IFormField[], informationItems: { label: string, value: string }[], remark?: string }[]
}

export const UnderwritingForm = ({stateIsFormToggleOpen, setIsFormToggleOpen}: { stateIsFormToggleOpen: boolean, setIsFormToggleOpen: any }) => {
  const {id} = useParams();
  const {data: dataAgreement, loading: loadingAgreement, error: errorAgreement} = useQuery(GET_AGREEMENT, {variables: {isn: id}});
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {variables: {isn: id}});
  const {objects} = (dataAgreement || {}).agreementCalc || {};
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {data, risks: docRisks, statusCode} = (docsList || [])[0] || {};
  const isCanceled: boolean = ["annul", "clientRefusal"].includes(statusCode);
  const {agreementIsn} = data || {}

  const borrowersRisks = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS)
  const propertiesRisks = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS)
  const titleRisks = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_TITLES)

  const formRiskLifeBuilder = (risks: any[]) => {
    if (!risks) return [];
    return (risks || []).map<any>((object) => {
      const {SubClassISN, AGROBJECT_ADDATTR, SubjFullName, SubjBirthDay, ISN} = object || {};
      const currentRisk = (docRisks || []).find(({object}) => ISN === object);
      const {data: dataCurrentRisk} = currentRisk || {};
      const {basicTariff} = dataCurrentRisk || {};
      const {row} = AGROBJECT_ADDATTR || {};
      const share: number = stringToNumber(getValueFromObjectsAttributes(object, SHARE_ISN));
      const discount: number = stringToNumber(getValueFromObjectsAttributes(object, DISCOUNT_ISN));
      const allowance: number = stringToNumber(getValueFromObjectsAttributes(object, BORROWER_ALLOWANCE_ISN));
      const heightWeight: number = stringToNumber(getValueFromObjectsAttributes(object, HEIGHT_WEIGHT_ISN));
      const pressure: number = stringToNumber(getValueFromObjectsAttributes(object, PRESSURE_ISN));
      const remark = String(((row || []).find(({ATTRISN}) => ATTRISN === REMARK_ISN) || {}).REMARK || "");
      return {
        informationItems: [
          {label: "ФИО", value: SubjFullName},
          {label: "Дата рождения", value: getKiasDate(SubjBirthDay)},
          {label: "Тип", value: SubClassISN === BORROWER_SUB_CLASS_ISN ? "Заемщик" : "Созаемщик"}
        ],
        form: [
          {
            columns: 2,
            columnGap: 2,
            fieldItems: [
              {
                type: "number",
                name: `heightWeight-${ISN}`,
                defaultValue: heightWeight,
                isDisabled: !!agreementIsn || isCanceled,
                placeholder: "Повышающий коэф (рост/вес) %",
                errorMessage: "Введите повышающий коэф",
                digitsAfterDot: 2
              },
              {
                type: "number",
                name: `pressure-${ISN}`,
                defaultValue: pressure,
                isDisabled: !!agreementIsn || isCanceled,
                placeholder: "Повышающий коэф давление %",
                errorMessage: "Введите повышающий коэф",
                digitsAfterDot: 2
              }
            ]
          },
          {
            columns: 2,
            columnGap: 2,
            fieldItems: [
              {
                type: "number",
                name: `tariff-${ISN}`,
                defaultValue: basicTariff,
                isDisabled: true,
                digitsAfterDot: 4,
                // isRequired: true,
                placeholder: "Базовый тариф",
                errorMessage: "Введите тариф"
              },
              {
                type: "range",
                name: `share-${ISN}`,
                defaultValue: share,
                isRequired: true,
                placeholder: "Доля %",
                isDisabled: true,
                startValue: 0,
                endValue: 100,
                errorMessage: ""
              }
            ]
          },
          {
            columns: 2,
            columnGap: 2,
            fieldItems: [
              {
                type: "number",
                name: `discount-${ISN}`,
                defaultValue: discount,
                isDisabled: !!agreementIsn || isCanceled,
                placeholder: "Скидка",
                errorMessage: "Введите скидку",
                digitsAfterDot: 2,
                validationType: "minMax",
                maxValue: 100
              },
              {
                type: "number",
                name: `allowance-${ISN}`,
                isDisabled: !!agreementIsn || isCanceled,
                placeholder: "Повышающий коэф %",
                defaultValue: allowance,
                errorMessage: "Введите повышающий коэф",
                digitsAfterDot: 2
              },
            ]
          },
        ],
        remark: remark || undefined
      }
    })
  }
  const formRiskPropertyBuilder = (risks: any[]) => {
    if (!risks) return [];
    return (risks || []).map<any>((object) => {
      const {AGROBJADDR, SubClassName, ISN} = object || {};
      const allowance: number = stringToNumber(getValueFromObjectsAttributes(object, ALLOWANCE_ISN));
      const tariff: number = stringToNumber(getValueFromObjectsAttributes(object, TARIFF_ISN));
      const cost: number = stringToNumber(getValueFromObjectsAttributes(object, PROPERTY_COST_ISN));
      const yearOfBuild: number = stringToNumber(getValueFromObjectsAttributes(object, PROPERTY_YEAR_OF_BUILD_ISN));
      const discount: number = stringToNumber(getValueFromObjectsAttributes(object, DISCOUNT_ISN));
      const {ADDRESS: address} = (AGROBJADDR || {}).row || {};
      return {
        informationItems: [
          {label: "Адрес имущества", value: address},
          {label: "Тип имущества", value: `${SubClassName}, год постройки: ${yearOfBuild}`},
          {label: "Стоимость", value: money(cost)},
        ],
        form: [
          {
            fieldItems: {
              type: "number",
              name: `allowance-${ISN}`,
              isDisabled: !!agreementIsn || isCanceled,
              placeholder: "Повышающий коэф %",
              defaultValue: allowance,
              errorMessage: "Введите повышающий коэф",
              digitsAfterDot: 2
            }
          },
          {
            columns: 2,
            columnGap: 2,
            fieldItems: [
              {
                type: "number",
                name: `tariff-${ISN}`,
                defaultValue: tariff,
                isRequired: true,
                isDisabled: !!agreementIsn || isCanceled,
                digitsAfterDot: 4,
                placeholder: "Базовый тариф",
                errorMessage: "Введите тариф"
              },
              {
                type: "number",
                name: `discount-${ISN}`,
                defaultValue: discount,
                isDisabled: !!agreementIsn || isCanceled,
                placeholder: "Скидка",
                errorMessage: "Введите скидку",
                digitsAfterDot: 2,
                validationType: "minMax",
                maxValue: 100
              }
            ]
          },
        ]
      }
    })
  }
  const formRiskTitleBuilder = (risks: any[]) => {
    if (!risks) return [];
    return (risks || []).map<any>((object) => {
      const {AGROBJADDR, SubClassName, ISN} = object || {};
      const tariff: number = stringToNumber(getValueFromObjectsAttributes(object, TARIFF_ISN));
      const allowance: number = stringToNumber(getValueFromObjectsAttributes(object, ALLOWANCE_ISN));
      const discount: number = stringToNumber(getValueFromObjectsAttributes(object, DISCOUNT_ISN));
      const {ADDRESS: address} = (AGROBJADDR || {}).row || {};
      return {
        informationItems: [
          {label: "Титульный адрес", value: address},
          {label: "Тип титула", value: SubClassName},
        ],
        form: [
          {
            fieldItems: {
              type: "number",
              name: `allowance-${ISN}`,
              defaultValue: allowance,
              isDisabled: !!agreementIsn || isCanceled,
              placeholder: "Повышающий коэф %",
              errorMessage: "Введите повышающий коэф",
              digitsAfterDot: 2
            }
          },
          {
            columns: 2,
            columnGap: 2,
            fieldItems: [
              {
                type: "number",
                name: `tariff-${ISN}`,
                defaultValue: tariff,
                isDisabled: !!agreementIsn || isCanceled,
                isRequired: true,
                digitsAfterDot: 4,
                placeholder: "Базовый тариф",
                errorMessage: "Введите тариф"
              },
              {
                type: "number",
                name: `discount-${ISN}`,
                defaultValue: discount,
                isDisabled: !!agreementIsn || isCanceled,
                placeholder: "Скидка",
                errorMessage: "Введите скидку",
                digitsAfterDot: 2,
                validationType: "minMax",
                maxValue: 100
              }
            ]
          },
        ]
      }
    })
  }

  const risksCategories: IFormCategoryItem[] = [
    {risks: borrowersRisks, title: "Риск Жизнь", forms: formRiskLifeBuilder(borrowersRisks)},
    {
      risks: propertiesRisks,
      title: "Риск Имущество",
      forms: formRiskPropertyBuilder(propertiesRisks)
    },
    {risks: titleRisks, title: "Риск Титул", forms: formRiskTitleBuilder(titleRisks)},
  ]

  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  return (
    <Toggle isOpen={stateIsFormToggleOpen} onClick={isOpen => setIsFormToggleOpen(isOpen)}
            isLoading={loadingAgreement || loadingDocs}
            header={"Общие сведения по заявке"}>
      <StyledCalculationDataWrapper>
        {risksCategories.map(({risks, title, forms}, index) => {
          if ((risks || []).length === 0 || (forms || []).length === 0) return null;
          return (
            <StyledRiskFormWrapper key={`riskForm-${index}`}>
              <Heading4>{title}</Heading4>
              {forms.map(({form, informationItems, remark}, index) => (
                <React.Fragment key={`currentRiskForm-${index}`}>
                  <StyledRiskInformation>
                    {informationItems.map(({label, value}, index) => (
                      <StyledInformationItem key={`info-item-${index}`}>
                        <TextButton>{label}</TextButton>
                        <TextButton>{value}</TextButton>
                      </StyledInformationItem>
                    ))}
                  </StyledRiskInformation>
                  <FieldsBuilder formFields={form}/>
                  {!!remark && <StyledRemark><QuestionIcon/><TextButton>{remark}</TextButton></StyledRemark>}
                </React.Fragment>
              ))}
            </StyledRiskFormWrapper>
          )
        })}
      </StyledCalculationDataWrapper>
    </Toggle>
  )
};

export default UnderwritingForm;