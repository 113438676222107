import React                                         from "react";
import {StyledStepFormHeader, StyledStepFormWrapper} from "../generalStep/styles";
import {Heading3, TextButton}                        from "../../../../globalStyledComponents";
import StoreAgreementStep                            from "../../store";

export const StepsLayout = ({title, children} : {title: string, children: any}) => {
  const [{agreementStep: {agreementStepState: {step}}}]: any = StoreAgreementStep();

  return (
   <StyledStepFormWrapper>
     <StyledStepFormHeader>
       <Heading3>{title}</Heading3>
       <TextButton>Шаг {step}</TextButton>
     </StyledStepFormHeader>
     {children}
   </StyledStepFormWrapper>
 )
}

export default StepsLayout;