import styled        from "styled-components";
import {grey, white} from "../../globalStyledComponents";

export const StyledRangePicker = styled.div`
position: relative;
`;
export const StyledRangeBar = styled.div`
  position: absolute;
  width: 100%;
  background: ${grey};
  height: 3px;
  margin-top: -3px;
  cursor: pointer;
`;
export const StyledRangeDot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  cursor: grab;
  border-radius: 50%;
  border: 1px solid ${grey};
  background: ${white};
`;