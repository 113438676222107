import React                          from 'react';
import {StyledTab, StyledTabsWrapper} from "./styles";
import {TextButton}                   from "../../globalStyledComponents";

export interface ITabsItem {
  label: string
  value: string | number
}

interface TabsProps {
  tabs: ITabsItem[]
  onChange?: (activeTab: ITabsItem) => void
  activeTab?: ITabsItem
  tabWidth: number
  tabMargin?: number
}

export const Tabs = ({tabs, onChange, activeTab, tabWidth, tabMargin}: TabsProps) => {
  const [stateActiveTab, setActiveTab] = React.useState<number | undefined>(undefined);
  React.useEffect(() => {
    if (!tabs.length) return;
    if (activeTab) return setActiveTab(tabs.findIndex(({value}) => value === activeTab.value))
    setActiveTab(0);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!onChange || stateActiveTab === undefined) return;
    onChange(tabs[stateActiveTab])
    // eslint-disable-next-line
  }, [stateActiveTab]);

  const setActiveTabHandler = (index: number) => {
    if (stateActiveTab === index) return;
    setActiveTab(index);
  }

  return (
    <StyledTabsWrapper activeTab={stateActiveTab} tabMargin={tabMargin || 0} tabWidth={tabWidth}>
      {!!tabs.length && tabs.map(({label, value}, index) => (
        <StyledTab
          tabMargin={tabMargin || 0}
          tabWidth={tabWidth}
          onClick={() => setActiveTabHandler(index)}
          key={`tab-${index}`}
          isActive={stateActiveTab === index}>
          <TextButton>{label}</TextButton>
        </StyledTab>
      ))}
    </StyledTabsWrapper>
  )
};

export default Tabs;