export const getFormValueWithArray = (whereFind: any, namesList: string[], nameForList: string) => {
  if (!whereFind || !namesList || !namesList.length || !nameForList) return {};
  const allItemsObject: {[key: string]: any} = Object.entries(whereFind).reduce<any>((prev, [key, value]) => {
    const simpleKey: string = key.split(("-"))[0];
    const keyIndex: string = key.split(("-"))[1];
    if (namesList.includes(simpleKey)) return {
      ...prev,
      [keyIndex]: {
        ...prev[keyIndex],
        [simpleKey]: value
      }
    }
    return prev
  }, {});
  const itemsList: any[] = Object.entries(allItemsObject).map(([, value]) => ({...value}));
  const clearObject: any = Object.entries(whereFind).reduce<any>((prev, [key, value]) => {
    const simpleKey: string = key.split(("-"))[0];
    if (namesList.includes(simpleKey)) return prev;
    return {...prev, [key]: value}
  }, {})
  return {
    ...clearObject,
    [nameForList]: itemsList
  }
};