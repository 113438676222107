import React                                          from 'react';
import {StyledFormTextField, StyledToggleAreaWrapper} from "./styles";
import Toggle                                         from "../../../../uiKit/toggle";
import {CommentsToggle, DocumentsToggle}              from "./components/rightToggles";
import {useQuery, useLazyQuery}                       from "@apollo/react-hooks";
import {ANKETA_TEMPLATE, DOCS}                        from "../../../../utils/queries";
import {useParams}                                    from "react-router";
import {IStepsProviderProps}                          from "../../../createRequest/components/stepsProvider";
import {
  getOneLevelValue,
  templateToFormRebuild
}                                                     from "../../../../helpers/templateToFormRebuild";
import {ANKETA_ATTRIBUTES}                            from "../../../../helpers/buildTemplateForBack";
import {TextButton}                                   from "../../../../globalStyledComponents";
import {ErrorMessage}                                 from "../../../../components/errorMessage";
import {StyledToggleAreaLeft}                         from "../../../underwriting/components/underwritingForm/styles";

const togglesLeftLoading = [
  "Общие сведения о страхователе",
  "Сведения о занятости страхователя",
  "Основные положения кредитного договора",
  "Страхование жизни, потери трудоспособности залогодателя",
  "Страхование имущества от гибели, утраты, повреждения",
  "Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности",
  "Дополнительная информация о страхователе"
]

export const LeftToggleArea = ({templateVariables, isLoading}: { templateVariables: any, isLoading: boolean }) => {
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const {parts} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const toggleItems: IStepsProviderProps[] = templateToFormRebuild(parts);
  const oneLevelValues = getOneLevelValue(parts);

  const togglesWithValue: { title: string, items: { isn: string, label: string, value: string }[] }[] = toggleItems.reduce<any>((prev, {form, title, kiasTitle}) => {
    const {step1, step2, step3, step5, step6, step7} = ANKETA_ATTRIBUTES;
    switch (kiasTitle) {
      case "Общие сведения о страхователе":
        return [...prev, {
          title, items: step1.reduce<{ isn: string, name: string }[]>((prev, {isn}) => {
            if (!oneLevelValues[`nivanavi-${isn}`]) return prev;
            return [...prev, {...oneLevelValues[`nivanavi-${isn}`]}];
          }, [])
        }]
      case "Сведения о занятости страхователя":
        return [...prev, {
          title, items: step2.reduce<{ isn: string, name: string }[]>((prev, {isn}) => {
            if (!oneLevelValues[`nivanavi-${isn}`]) return prev;
            return [...prev, {...oneLevelValues[`nivanavi-${isn}`]}];
          }, [])
        }]
      case "Основные положения  кредитного договора":
        return [...prev, {
          title, items: step3.reduce<{ isn: string, name: string }[]>((prev, {isn}) => {
            if (!oneLevelValues[`nivanavi-${isn}`]) return prev;
            return [...prev, {...oneLevelValues[`nivanavi-${isn}`]}];
          }, [])
        }]
      case "Страхование жизни, потери трудоспособности залогодателя вопросы общего характера":
        return [...prev, {
          title, items: form.reduce<any>((prev, {fieldItems}) => {
            const name: string = (fieldItems as any || {}).name || "";
            if (!oneLevelValues[name]) return prev;
            return [...prev, {...oneLevelValues[name]}];
          }, [])
        }]
      case "Страхование имущества от гибели, утраты, повреждения":
        return [...prev, {
          title, items: step5.reduce<{ isn: string, name: string }[]>((prev, {isn}) => {
            if (!oneLevelValues[`nivanavi-${isn}`]) return prev;
            return [...prev, {...oneLevelValues[`nivanavi-${isn}`]}];
          }, [])
        }]
      case "Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности":
        return [...prev, {
          title, items: step6.reduce<{ isn: string, name: string }[]>((prev, {isn}) => {
            if (!oneLevelValues[`nivanavi-${isn}`]) return prev;
            return [...prev, {...oneLevelValues[`nivanavi-${isn}`]}];
          }, [])
        }]
      case "Дополнительная информация о страхователе":
        return [...prev, {
          title, items: step7.reduce<{ isn: string, name: string }[]>((prev, {isn}) => {
            if (!oneLevelValues[`nivanavi-${isn}`]) return prev;
            return [...prev, {...oneLevelValues[`nivanavi-${isn}`]}];
          }, [])
        }]
      default:
        return prev;
    }
  }, [])

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: templateVariables})
    // eslint-disable-next-line
  }, [templateVariables])

  if (togglesWithValue.length === 0) return (
    <StyledToggleAreaLeft>
      {togglesLeftLoading.map((title, index) => (
        <Toggle
          header={title} key={`application-toggle-left-${index}`}
          isLoading={true}>
        </Toggle>
      ))}
    </StyledToggleAreaLeft>
  );

  if (errorAnketaTemplate) return <ErrorMessage error={errorAnketaTemplate}/>
  return (
    <StyledToggleAreaLeft>
      {togglesWithValue.map(({title, items}, index) => (
        <Toggle
          header={title}
          scrollToTop={true}
          key={`application-toggle-left-${index}`}
          isLoading={isLoading || loadingAnketaTemplate}>
          {items.length === 0 && <TextButton>pusto</TextButton>}
          {items.map(({isn, label, value}) => (
            <StyledFormTextField key={`textField-${isn}`}>
              <TextButton>{label}</TextButton>
              <TextButton>{value || "Не заполнено"}</TextButton>
            </StyledFormTextField>
          ))}
        </Toggle>
      ))}
    </StyledToggleAreaLeft>
  )
};

export const ToggleArea = () => {
  const {id} = useParams();
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {
      isn: id
    }
  });
  const {items: docsList} = (dataDocs||{}).docs || {};
  const {data} = (docsList || [])[0] || {};
  const {templateVariables} = data || {};

  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  return (
    <StyledToggleAreaWrapper>
      <LeftToggleArea isLoading={loadingDocs} templateVariables={templateVariables}/>

      <div>
        <DocumentsToggle header="Прикрепленные документы" type="C" id={id}/>
        <CommentsToggle header="Обсуждение"/>
      </div>
    </StyledToggleAreaWrapper>
  )
};

export default ToggleArea;