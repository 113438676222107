import React                   from 'react';
import Modal, {ModalProps}     from "../../../../uiKit/modal";
import Button                  from "../../../../uiKit/button/button";
import {useHistory, useParams} from "react-router";
import {useGetDict}            from "../../../../hooks/useGetDicti";
import {IFormField}            from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder           from "../../../../uiKit/fieldsBuilder";
import {StyledButtonsGroup}    from "../../../roles/components/header/styles";
import {getFormError}                 from "../../../../formUtils/getFormError";
import {getFormValue, stringToNumber} from "../../../../formUtils/getFormValue";
import Form                           from '@atlaskit/form';
import moment                  from "moment";
import {useCallNotification}   from "../../../../hooks/useCallNotification";
import {useMutation}           from "@apollo/react-hooks";
import {CREATE_ADDENDUM}       from "../../../../utils/mutations";

const AddRisksAddendumModal = ({header, isOpen, data, onClose}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id} = useParams();
  const history = useHistory();
  const {data: checkboxRisks, loading: loadingRisks} = useGetDict("risks");
  const [createAddendum, {loading: loadingCreateAddendum}] = useMutation(CREATE_ADDENDUM);
  const date = moment().format("DD.MM.YYYY");

  const form: IFormField[] = [
    {
      label: "Риски",
      columns: 3,
      fieldItems: checkboxRisks.map(({value: name, label}) => ({
        name,
        type: "checkbox",
        label,
        isDisabled: data[name]
      }))
    },
    {
      fieldMargin: 5,
      fieldItems: {
        name: "riskSum",
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите остаток по кредиту",
        placeholder: "Остаток по кредиту на момент дострахования"
      }
    },
  ]

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValue(data);
    const {riskLife, riskTitle, riskProperty, riskSum} = formData;
    const haveProperty: boolean = riskProperty || data["riskProperty"];
    if (!riskLife && !riskTitle && !riskProperty) return setNotification({type: "error", text: "Не выбраны риски"});
    if (riskTitle && !haveProperty) return setNotification({type: "error", text: "Нельзя выбрать риск титул без риска имущество"});

    const stepsStack: string = ["riskProperty", "riskTitle", "riskLife"].reduce<string[]>((prev, risk) => {
      if (!formData[risk]) return prev;
      return [...prev, risk];
    }, []).join('-');

    createAddendum({
      variables: {
        agrIsn: id,
        dataBeg: date,
        dateSign: date,
        addendumReason: "858991",
        addLife: riskLife ? 1 : 0,
        addProp: riskProperty ? 1 : 0,
        addTitul: riskTitle ? 1 : 0,
        riskSum: stringToNumber(riskSum)
      }
    }).then(({data: {addendum: {ISN}}}) => history.push(`/createRequest/${ISN}/addRisks/${stepsStack}`))
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <Modal
      isLoading={loadingRisks}
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                type="submit"
                width="100%"
                isLoading={loadingCreateAddendum}
                appearance="filled">
                Применить
              </Button>
              <Button
                onClick={() => onClose()}
                width="100%"
                isLoading={loadingCreateAddendum}
                appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default AddRisksAddendumModal;