import {useMutation}                  from "@apollo/react-hooks";
import {getClearPhone}                from "../formUtils/getFormValue";
import {useCallNotification}          from "./useCallNotification";
import {CLIENT_INIT, SAVE_ATTACHMENT} from "../utils/mutations";

export const useSendAnketa = () => {
  const {setNotification} = useCallNotification();
  const [saveAttachment, {loading: loadingSaveAttachment}] = useMutation(SAVE_ATTACHMENT);
  const [clientInit, {loading: loadingClientInit}] = useMutation(CLIENT_INIT);

  const sendAnketa = (agreementFormState: any, isnAgreement: string) => {
    if (!agreementFormState || !isnAgreement) return setNotification({type: "error", text: "Нет данных или не указан isn котировки"});
    (agreementFormState.peoples || []).forEach(person => {
      const {fillingType, attachments, firstName, lastName, parentName, phoneMobile, birthDate} = person || {};
      switch (fillingType) {
        case "upload":
        case "declaration":
          (attachments || []).forEach(file => {
            saveAttachment({variables: {type: "C", ref: isnAgreement.replace("-", ""), file}})
              .catch(error => setNotification({type: "error", text: error}))
          })
          break;
        case "send":
          clientInit({variables: {firstName, lastName, parentName, phoneMobile, birthDate, agrCalcIsn: isnAgreement, userLogin: getClearPhone(phoneMobile)}})
            .catch(error => setNotification({type: "error", text: error}))
          break;
        default:
          break;
      }
    })
  };

  const saveAdditionalFiles = (agreementFormState: any, isnAgreement: string) => {
    if (!agreementFormState || !isnAgreement) return setNotification({type: "error", text: "Нет данных или не указан isn котировки"});
    (agreementFormState.additionalDocuments || []).forEach(file => {
      saveAttachment({variables: {type: "C", ref: isnAgreement.replace("-", ""), file}})
        .catch(error => setNotification({type: "error", text: error}))
    })
  };

  return {
    sendAnketa,
    saveAdditionalFiles,
    loading: loadingSaveAttachment || loadingClientInit
  }
};