import styled from "styled-components";

export const StyledTableAreaWrapper = styled.div`
 margin-bottom: 4rem; 
 
 h3 {
  margin-bottom: 3rem;
 }
 padding: 4rem 4rem 0 4rem;
 
 
     @media screen and (max-width: 767px) {
 h3 {
  margin-bottom: 2rem;
 }
  margin-bottom: 3rem;
    padding: 3rem 1.5rem 0 1.5rem;
   
  }
`;

export const StyledDiscountArea = styled.div`
 display: flex;
 align-items: center;
 .iconButton {
  margin-right: 1.5rem;
 }  
`;

export const StyledInlineEditableTableField = styled.div`
 padding: 0 1rem;
`;
