import React                                         from "react";
import {StyledStepFormHeader, StyledStepFormWrapper} from "../../../generalStep/styles";
import {Heading3}                                    from "../../../../../../globalStyledComponents";
import {IFormField}                from "../../../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder               from "../../../../../../uiKit/fieldsBuilder";
import Form                        from '@atlaskit/form';
import {getFormError}              from "../../../../../../formUtils/getFormError";
import {getFormValue}              from "../../../../../../formUtils/getFormValue";
import StoreAgreementForm          from "../../../../store";
import {AnketaPaginationButtons}   from "../../index";
import {useCallNotification}       from "../../../../../../hooks/useCallNotification";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {ANKETA_TEMPLATE}           from "../../../../../../utils/queries";
import {UPDATE_ANKETA}             from "../../../../../../utils/mutations";
import {Loader}                                    from "../../../../../../uiKit/loader";
import {anketaPartsBuilder, anketaSettingsBuilder} from "../../../../../../helpers/buildTemplateForBack";

export const SendToClientFillingForm = ({person, onClose}) => {
  const {setNotification} = useCallNotification();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [updateAnketa, {loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const {peoples, templateVariables} = agreementFormState || {};
  const {isn: isnPerson, phoneMobile} = person || {};

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: templateVariables})
    // eslint-disable-next-line
  }, [templateVariables])

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "input",
        name: "clientPhone",
        isRequired: true,
        mask: "+7 (999) 999-99-99",
        defaultValue: phoneMobile || "",
        errorMessage: "Введите телефон",
        validationType: "phone",
        placeholder: "Телефон"
      }
    }
  ]

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;

    const partsWithValue: any[] = anketaPartsBuilder({...agreementFormState, ...person}, parts);
    const settingsWithValue: any[] = anketaSettingsBuilder({...agreementFormState, ...person}, settings);
    updateAnketa({
      variables: {
        clientIsn: isnPerson,
        settings: settingsWithValue,
        parts: partsWithValue,
        templateVariables
      }
    }).then(({data: {anketa: {isn: isnAnketa}}}) => {
      const {clientPhone} = getFormValue(data);
      updateAgreementForm({peoples: (peoples||[]).map(person => {
          const {isn} = person || {};
          if (isn === isnPerson) return {
            ...person,
            isnAnketa,
            phoneMobile: clientPhone,
            fillingType: "send"
          };
          return person;
        })});
      onClose();
    }).catch(error => setNotification({type: "error", text: error}))
  }

  if (loadingAnketaTemplate) return <Loader type="simple"/>
  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})

  return (
    <>
      <StyledStepFormWrapper>
        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps} id="sendClientForm">
              <StyledStepFormHeader>
                <Heading3>Данные клиента</Heading3>
              </StyledStepFormHeader>
              <FieldsBuilder formFields={form}/>
            </form>
          )}
        </Form>
      </StyledStepFormWrapper>
      <AnketaPaginationButtons isLoading={loadingUpdateAnketa} onClose={onClose} formId={"sendClientForm"}/>
    </>
  )
}

export default SendToClientFillingForm;