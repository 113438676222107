import React                     from "react";
import Modal, {ModalProps}       from "../../../../uiKit/modal";
import Form                      from '@atlaskit/form';
import Button                    from "../../../../uiKit/button/button";
import {IFormField}              from "../../../../uiKit/fieldsBuilder/interfaces";
import {getFormError}            from "../../../../formUtils/getFormError";
import {getFormValue}            from "../../../../formUtils/getFormValue";
import FieldsBuilder             from "../../../../uiKit/fieldsBuilder";
import {IRadioItem}              from "../../../../uiKit/radio";
import {StyledButtonsGroup}      from "../../../roles/components/header/styles";
import {useGetDict}              from "../../../../hooks/useGetDicti";
import StoreAgreementForm        from "../../store";
import {getVariablesForTemplate} from "../../../../helpers/getVariablesForTemplate";
import StoreAgreementStep        from "../../store";
import {useHistory}              from "react-router";

const propertyTypes: IRadioItem[] = [
  {label: "Строящееся жилье", value: "2"},
  {label: "Готовое жилье", value: "1"},
];

export const SkipPrecalculationModal = ({header, isOpen, onClose}: ModalProps) => {
  const history = useHistory();
  const {data: checkboxRisks} = useGetDict("risks");
  const {data: selectAgreementTypes, loading: loadingAgreementTypes} = useGetDict("agreementTypes");
  const [statePropertyType, setPropertyType] = React.useState<IRadioItem>(propertyTypes[0]);
  const [stateRisks, setRisks] = React.useState<string[]>([]);
  const [{
    agreementStep: {
      agreementStepState: {
        step,
        stepsCount
      }
    }
  }, {agreementStep: actionsAgreementStep}]: any = StoreAgreementStep();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });

  const changeStep = (step: number) => {
    if (step > stepsCount || step < 0) return;
    actionsAgreementStep.setState({
      agreementStepState: {
        step,
        stepsCount
      }
    });
  }

  const changeRisksHandler = (value: boolean, risk: string) => {
    if (!value && risk === "riskProperty") return setRisks(stateRisks.filter(stateRisk => !["riskProperty", "riskTitle"].includes(stateRisk)));
    if (!value) return setRisks(stateRisks.filter(stateRisk => stateRisk !== risk));
    return setRisks([...stateRisks, risk])
  }

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {riskLife, riskTitle, riskProperty, insureType} = getFormValue(data);

    const risks = statePropertyType.value === "2" ?
      {
        riskLife: true,
        riskTitle: false,
        riskProperty: false
      }
      :
      {
        riskLife,
        riskTitle,
        riskProperty
      }

    const dataForTemplate = {
      property: statePropertyType.value,
      insureType,
      isNotClearStore: true,
      ...risks,
    }

    history.push("/createRequest")
    updateAgreementForm({
      ...dataForTemplate,
      templateVariables: getVariablesForTemplate(dataForTemplate),
    })
    changeStep(step + 1)
  };

  const form: IFormField[] = [
    {
      label: "Вид договора",
      fieldItems: {
        type: "radio",
        columns: 2,
        name: "insureType",
        returnFullValue: true,
        isLoading: loadingAgreementTypes,
        defaultValue: selectAgreementTypes[0],
        items: selectAgreementTypes,
        isRequired: true,
        errorMessage: "Выберите вид договора",
      }
    },
    {
      fieldMargin: statePropertyType.value === "2" ? 5 : undefined,
      label: "Тип жилья",
      fieldItems: {
        type: "radio",
        columns: 2,
        name: "propertyType",
        defaultValue: statePropertyType,
        items: propertyTypes,
        onChange: value => setPropertyType(value)
      }
    },
    {
      fieldMargin: 5,
      isHidden: statePropertyType.value === "2",
      label: "Риски",
      columns: 3,
      fieldItems: checkboxRisks.map(({value: name, label}) => ({
        name,
        type: "checkbox",
        label,
        defaultValue: stateRisks.includes(name),
        isDisabled: name === "riskTitle" && !stateRisks.includes("riskProperty"),
        onChange: (value) => changeRisksHandler(value, name)
      }))
    },
  ]

  return (
    <Modal
      width={60}
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                type="submit"
                width="100%"
                appearance="filled">
                Применить
              </Button>
              <Button
                onClick={() => onClose()}
                width="100%"
                appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default SkipPrecalculationModal;