import React                                                                          from "react";
import {Heading3}                                                                     from "../../../../globalStyledComponents";
import Table, {ITableHeadItem, ITableRowItem}                                                      from "../../../../uiKit/table";
import {CLASS_ISN_BENEFICARY, CLASS_ISN_INSURED, CLASS_ISN_INSURER, CLASS_ISN_SIGNER, getKiasDate} from "../../../../helpers/buildTemplateForBack";
import {useQuery}                                                                                  from "@apollo/react-hooks";
import {GET_CONTRACT, ME}                                                             from "../../../../utils/queries";
import {ErrorMessage}                                                                 from "../../../../components/errorMessage";
import {useParams}                                                                    from "react-router";
import {StyledSubjectsTableHeader}                                                    from "./styles";

const headsList: string[] = ["Роль", "Контрагент", /*"%",*/ "Начало", "Окончание"]

const head: ITableHeadItem = {
  cells: headsList.map((content, index) => ({key: `head-${index}`, content}))
};

export const SubjectsTable = () => {
  const {id} = useParams();
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {data: dataContract, loading: loadingContract, error: errorContract} = useQuery(GET_CONTRACT, {
    variables: {
      isn: id
    }
  });
  const {roles} = (dataContract || {}).agreement || {};
  const {type} = (dataUser || {}).me || {};
  const rolesByUserType = type === "admin" ? roles : (roles || []).filter(({ClassISN}) => [CLASS_ISN_INSURER, CLASS_ISN_INSURED, CLASS_ISN_BENEFICARY, CLASS_ISN_SIGNER].includes(ClassISN))
  const rows: ITableRowItem[] = (rolesByUserType || []).map(role => {
    const {ISN, ClassName, SUBJFULLNAME, DateBeg, DateEnd} = role || {};
    return {
      key: `row-${ISN}`,
      id: ISN,
      cells: [
        {key: `cell-${ISN}`, content: ClassName},
        {key: `cell-${ISN}`, content: SUBJFULLNAME},
        // {key: `cell-${ISN}`, content: 0},
        {key: `cell-${ISN}`, content: getKiasDate(DateBeg)},
        {key: `cell-${ISN}`, content: getKiasDate(DateEnd)}
      ]
    }
  })

  if (errorContract) return <ErrorMessage error={errorContract}/>;
  if (errorUser) return <ErrorMessage error={errorUser}/>;

  return (
    <>
      <StyledSubjectsTableHeader>
        <Heading3>Участники договора</Heading3>
      </StyledSubjectsTableHeader>
      <Table
        isLoading={loadingContract}
        emptyViewMessage="Нет участников"
        head={head}
        rows={rows}
      />
    </>
  )
}