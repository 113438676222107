import React                                               from 'react';
import {StyledRangeBar, StyledRangeDot, StyledRangePicker} from "./styles";
import {Range, getTrackBackground}                         from 'react-range';
import {blue, grey}                                        from "../../globalStyledComponents";
import {NumberInputSwitcher}                               from "../floatInput";
import {stringToNumber}                                    from "../../formUtils/getFormValue";

interface IRangeProps {
  value?: number
  onChange?: (value: number) => void
  placeholder?: string
  startValue: number
  endValue: number
  errorMessage?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isRequired?: boolean
  isLoading?: boolean
}

export const RangePicker = ({onChange, errorMessage, isLoading, isRequired, isDisabled, isInvalid, value, startValue, endValue, placeholder}: IRangeProps) => {
  const [stateRange, setRange] = React.useState<number | undefined>(value)
  React.useEffect(() => {
    if (onChange) onChange(stateRange || 0)
    // eslint-disable-next-line
  }, [String(stateRange)])

  React.useEffect(() => {
    if (!stringToNumber(value) || stateRange === value) return;
    setRange(stringToNumber(value))
    // eslint-disable-next-line
  }, [value])

  const changeRangeHandler = (values) => {
    if (isDisabled || isLoading) return;
    return setRange(values[0])
  }

  return (
    <StyledRangePicker>
      <NumberInputSwitcher
        digitsAfterDot={2}
        errorMessage={errorMessage}
        isInvalid={!!isInvalid}
        isLoading={!!isLoading}
        isRequired={!!isRequired}
        isDisabled={!!isDisabled}
        value={stateRange ? String(stateRange) : undefined}
        placeholder={placeholder}
        onChange={value => setRange(stringToNumber(value))}
      />
      <Range
        values={[(stateRange || 0) > endValue ? endValue : stateRange || 0]}
        step={1}
        min={startValue}
        max={endValue}
        onChange={changeRangeHandler}
        renderTrack={({props, children}) => (
          <StyledRangeBar {...props} style={{
            background: getTrackBackground({
              values: [(stateRange || 0) > endValue ? endValue : stateRange || 0],
              colors: [blue, grey],
              min: startValue,
              max: endValue
            }),
          }}>
            {children}
          </StyledRangeBar>
        )}

        renderThumb={({props}) => (
          <StyledRangeDot {...props}/>
        )}
      />

    </StyledRangePicker>
  )
};

export default RangePicker;
