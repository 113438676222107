import React               from "react";
import {
  StyledLeftMenu,
  StyledNavigationLinksWrapper,
  StyledNavigationLink,
  StyledLogo,
  StyledLeftMenuWrapper
}                          from "./styles";
import {LogoDesktop}       from "../../../icons/logo/LogoDesktop";
import Contacts            from "./components/contacts";
import StoreNavigation     from "./store"
import {useHistory}        from "react-router";
import {useWindowWidth}    from '@react-hook/window-size'
import {useQuery}          from "@apollo/react-hooks";
import {ME}                from "../../../utils/queries";
import {rolesRightHandler} from "../../../helpers/rolesRightHandler";
import {ErrorMessage}      from "../../errorMessage";
import StoreOnboarding     from "../../onboarding/store";

interface ILinkItem {
  label: string
  link: string
  isHidden?: boolean
}

export const LeftMenu = () => {
  const [{navigation: {navigationState: {isOpen}}}, {navigation: actionsNavigation}]: any = StoreNavigation();
  const [, {onboarding: actionsOnboarding}]: any = StoreOnboarding();
  const windowWidth = useWindowWidth({wait: 250});
  const history = useHistory();
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {pathname} = history.location;
  const toggleNavigation = (isOpen) => actionsNavigation.setState({
    navigationState: {
      isOpen
    }
  });
  const toggleOnboarding = (isOpen) => actionsOnboarding.setState({
    onboardingState: {
      isOpen
    }
  });

  React.useEffect(() => {
    if (isOpen && windowWidth < 768) return toggleNavigation(false);
    // eslint-disable-next-line
  }, [pathname])

  React.useEffect(() => {
    localStorage.setItem("leftMenuIsOpen", `${isOpen}`)
    // eslint-disable-next-line
  }, [isOpen])

  const desktopLinks: ILinkItem[] = [
    {label: "Статистика", link: "/", isHidden: !rolesRightHandler("statisticsPage", functions, type, isAdmin)},
    {
      label: "Журнал заявок",
      link: "/agreementsLog/1",
      isHidden: !rolesRightHandler("applicationsLogPage", functions, type, isAdmin)
    },
    {
      label: "Журнал договоров",
      link: "/contractsLog/1",
      isHidden: !rolesRightHandler("agreementsLogPage", functions, type, isAdmin)
    },
    {
      label: "Журнал пролонгаций",
      link: "/prolongationLog/1",
      isHidden: !rolesRightHandler("prolongationsLogPage", functions, type, isAdmin)
    },
    {label: "Роли и группы", link: "/roles", isHidden: !isAdmin},
    {label: "Настройки", link: "/settings", isHidden: !rolesRightHandler("settingsPage", functions, type, isAdmin)},
    {label: "Отчеты", link: "/reports", isHidden: !rolesRightHandler("reportsPage", functions, type, isAdmin)}
  ];

  const mobileLinks: ILinkItem[] = [
    {label: "Профиль", link: "/profile"},
    {label: "Выйти", link: "/authorization"}
  ]

  const clientLinks: ILinkItem[] = [
    {label: "Главная", link: "/myPolicies"},
    {label: "Мои договора", link: "/myAgreements"},
    {label: "Заполнить анкету", link: "/myAnketa"},
  ]

  const linksBuilder = () => {
    switch (type) {
      case "admin":
      case "user":
      case "kias":
        if (windowWidth < 768) return [...desktopLinks, ...mobileLinks]
        return desktopLinks
      case "client":
        if (windowWidth < 768) return [...clientLinks, ...mobileLinks]
        return clientLinks;
      default:
        return []
    }
  }
  const links: ILinkItem[] = linksBuilder()

  const setActiveLinkHandler = (index: number, link: string) => {
    if (link === history.location.pathname) return;
    history.push(link);
  };
  const activeLinkIndex = links.filter(({isHidden}) => !isHidden).findIndex(({link}) => link === history.location.pathname);
  if (errorUser) return <ErrorMessage error={errorUser}/>
  return (
    <StyledLeftMenuWrapper isOpen={isOpen}>
      <StyledLeftMenu>
        <div>
          <StyledLogo onClick={() => history.push(type === "client" ? "/myPolicies" : "/")}>
            <LogoDesktop/>
          </StyledLogo>
          <StyledNavigationLinksWrapper activeLink={activeLinkIndex < 0 ? undefined : activeLinkIndex}>
            {links.map(({label, link, isHidden}, index) => {
              if (isHidden) return null;
              return (
                <StyledNavigationLink
                  onClick={() => {
                    if (link === "help") return toggleOnboarding(true);
                    setActiveLinkHandler(index, link)
                  }}
                  key={`nav-link-${index}`}
                  isActive={history.location.pathname === link}
                >
                  {label}
                </StyledNavigationLink>
              )
            })}
          </StyledNavigationLinksWrapper>
        </div>
        <Contacts/>
      </StyledLeftMenu>
    </StyledLeftMenuWrapper>
  )
};

export default LeftMenu;