import React                                     from 'react';
import {Heading3, TextButton}                    from "../../../../globalStyledComponents";
import Button
                                                 from "../../../../uiKit/button/button";
import Status
                                                 from "../../../../uiKit/status";
import FlyButtons                                from "../../../../components/flyButtons";
import {
  StyledEventsButtons,
  StyledHeaderInformation,
  StyledHeaderWrapper, StyledInformationItem, StyledAgreementNumber
}                                                from "../../../applicationById/components/header/styles";
import {useLazyQuery, useMutation, useQuery}     from "@apollo/react-hooks";
import {
  CHECK_INVOICE_STATUS,
  DOCS,
  DOWNLOAD_CONTRACT,
  GET_CONTRACT,
  GET_KV_AGENT,
  ME
}                                                from "../../../../utils/queries";
import {useHistory, useParams}                   from "react-router";
import {money}                                   from "../../../../utils/formats";
import {
  StyledContractHeader,
  StyledImportantNumber, StyledSignetAgreementButtons,
  StyledTotalInformation,
  StyledTotalInformationItem
}                                                from "./styles";
import {StyledButtonsGroupAutoSize}              from "../../../roles/components/header/styles";
import {DownloadIcon}                            from "../../../../icons/ui/Download";
import {ErrorMessage}                            from "../../../../components/errorMessage";
import {getSummForPay}                           from "../../../../helpers/templateToFormRebuild";
import {useWindowWidth}                          from "@react-hook/window-size";
import {useCallNotification}                     from "../../../../hooks/useCallNotification";
import LoadingString                             from "../../../../uiKit/loadingString";
import DropDown, {IDropDownListItem}             from "../../../../uiKit/dropDownArea";
import {
  CLASS_ISN_AGENT, CLASS_ISN_AGENT_UOS2,
  CLASS_ISN_BORROWERS,
  CLASS_ISN_OBJECTS,
  getValueByAttributeId, kiasAgreementToFormState,
  KV_DISCOUNT_ISN,
  SBERBANK_ISN
} from "../../../../helpers/buildTemplateForBack";
import {fileDownloader}                          from "../../../../helpers/fileDownloader";
import {rolesRightHandler}                       from "../../../../helpers/rolesRightHandler";
import {SelectArrowIcon}                         from "../../../../icons/ui/SelectArrow";
import {CHANGE_CONTRACT_STATUS, CREATE_ADDENDUM} from "../../../../utils/mutations";
import {stringToNumber}                          from "../../../../formUtils/getFormValue";
import PayPeriodModal                            from "../../../createInvoice/components/payPeriodModal";
import moment                                    from "moment";
import AddRisksAddendumModal                     from "./AddRisksAddendumModal";
import ProlongationModal                         from "./ProlongationModal";

const templateSberLife = `${process.env.REACT_APP_SBER_PROJECT_LIFE_ISN}`;
const templateSberProperty = `${process.env.REACT_APP_SBER_PROJECT_PROPERTY_ISN}`;

export const ContractRegistrationHeader = ({
                                             isLoading,
                                             setSubmitType,
                                             isDisabled,
                                             showProlongDeclaration
                                           }: { isLoading: boolean, setSubmitType: any, showProlongDeclaration: any, isDisabled }) => {
  const {setNotification} = useCallNotification();
  const windowWidth = useWindowWidth({wait: 250});
  const {id} = useParams();
  const history = useHistory();
  const [stateDropDownIsOpen, setDropDownIsOpen] = React.useState<boolean>(false);
  const [stateDownloadDropDown, setDownloadDropDown] = React.useState<boolean>(false);
  const [statePayPeriodModalIsOpen, setPayPeriodModalIsOpen] = React.useState<boolean>(false);
  const [stateAddRiskModal, setAddRiskModal] = React.useState<boolean>(false);
  const [stateProlongationModal, setProlongationModal] = React.useState<boolean>(false);
  const [downloadContract, {
    data: dataDownloadContract,
    loading: loadingDownloadContract,
    error: errorDownloadContract
  }] = useLazyQuery(DOWNLOAD_CONTRACT, {fetchPolicy: "cache-and-network"});
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {isn: id},
    fetchPolicy: "network-only"
  });
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {
    data: dataInvoiceStatus,
    loading: loadingInvoiceStatus,
    error: errorInvoiceStatus
  } = useQuery(CHECK_INVOICE_STATUS, {variables: {isn: id}});
  const [getKv, {data: dataGetKv, error: errorGetKv}] = useLazyQuery(GET_KV_AGENT);
  const {data: dataContract, loading: loadingContract, error: errorContract} = useQuery(GET_CONTRACT, {
    variables: {
      isn: id
    }
  });
  const [createAddendum, {loading: loadingCreateAddendum}] = useMutation(CREATE_ADDENDUM);
  const [changeContractStatus, {loading: loadingChangeContractStatus}] = useMutation(CHANGE_CONTRACT_STATUS, {refetchQueries: ["agreement", "docs"], awaitRefetchQueries: true});
  const {objects, curCode, attribs, sums, prevIsn} = (dataContract || {}).agreement || {};
  const {riskLife, riskProperty, riskTitle} = kiasAgreementToFormState({agreementData: (dataContract || {}).agreement || {}})
  const borrowers = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS)
  const properties = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS)
  const urlFile = ((dataDownloadContract || {}).agreementForm || {}).url;
  const bankIsn = getValueByAttributeId(attribs, "creditOrganizationName");
  const {Komission_Sum: agentKv} = dataGetKv?.getKvAgent || {}
  const isSber: boolean = bankIsn === SBERBANK_ISN;
  const isSberbankDoubleAgreement: boolean = isSber && borrowers.length !== 0 && properties.length !== 0;
  const {isAdmin, type, role, show_kv} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {kias} = (dataUser || {}).me || {};
  const isPartner: boolean = (kias || {}).IsPartner === "1";
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {number, user: createdAt, added, data, statusCode, needProlongation, prevAgreementIsn, nextAgreementIsn, nextInvoiceAllowed} = (docsList || [])[0] || {};
  const {agreementCalcIsn, prolongDeclaration} = data || {};
  const prolongDeclarationOk = prolongDeclaration || !riskLife;

  const discount = stringToNumber(attribs?.find(({isn}) => isn === KV_DISCOUNT_ISN)?.val);
  const sumAgreementWithDiscount: number = Number(getSummForPay(objects, curCode, true))
  const sumWithoutDiscount: number = (sumAgreementWithDiscount * 100) / (100 - discount)

  const defaultAgentRole = isSber ? (dataContract?.agreement?.roles||[]).find(({ClassISN}) => ClassISN === CLASS_ISN_AGENT) : (dataContract?.agreement?.roles||[]).find(({ClassISN}) => ClassISN === CLASS_ISN_AGENT_UOS2);
  const defaultAgentSelectValue = defaultAgentRole ? {label: defaultAgentRole.SUBJFULLNAME, value: defaultAgentRole.SubjISN} : undefined;

  const date = moment().format("DD.MM.YYYY");
  const isCanceled: boolean = ["annul"].includes(statusCode);
  const invoiceStatus: string = ((dataInvoiceStatus || {}).invoiceStatus || {}).PAYSTATUSNAME === "Оплачен" ? "payed" : "notPay";

  const isnInvoice = (Array.isArray(sums) ? (sums || []).find(({Discr}) => Discr === "f") || {} : sums || {}).OperDocISN;

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  React.useEffect(() => {
    if (!isPartner) return;
    getKv({
      variables: {isn: id}
    })
    // eslint-disable-next-line
  }, [isPartner])

  const downloadProjectHandler = (template?/*?: "13371" | "13372"*/) => {
    downloadContract({
      variables: {
        isn: id,
        bankIsn,
        project: 1,
        template
      }
    })
  };

  const prolongationHandler = (addendumReason: string, agreementEditType: string) => {
    createAddendum({
      variables: {
        agrIsn: id,
        dataBeg: date,
        dateSign: date,
        addendumReason
      }
    }).then(({data: {addendum: {ISN}}}) => history.push(`/createRequest/${ISN}/${agreementEditType}`))
      .catch(error => setNotification({type: "error", text: error}))
  }

  const editContract = (isGoBack?: boolean) => {
    changeContractStatus({
      variables: {
        isn: id,
        status: "С"
      }
    }).then(() => {
      if (isGoBack) return history.push(`/createRequest/${agreementCalcIsn}/edit`)
    })
      .catch(error => setNotification({type: "error", text: error}))
  }

  const informationList = [
    {
      title: "Номер договора", description: <StyledAgreementNumber>
        {number}
        {!prevAgreementIsn && !nextAgreementIsn && <Button
            isLoading={isLoading || loadingContract}
            appearance="link"
            isDisabled={!agreementCalcIsn}
            tooltip={isDisabled ? "Требуется перерасчет" : ""}
            onClick={ev => {
              ev.preventDefault();
              history.push(`/agreement/${agreementCalcIsn}`)
            }}
        >
            Перейти к заявке
        </Button>}
        {!!prevAgreementIsn && <Button
            isLoading={isLoading || loadingDocs}
            appearance="link"
          //isDisabled={!agreementCalcIsn}
            tooltip={isDisabled ? "Требуется перерасчет" : ""}
            onClick={ev => {
              ev.preventDefault();
              history.push(`/contract/${prevAgreementIsn}`)
            }}
        >
            Предыдущий договор
        </Button>}
        {!!nextAgreementIsn && <Button
            isLoading={isLoading || loadingDocs}
            appearance="link"
            onClick={ev => {
              ev.preventDefault();
              history.push(`/contract/${nextAgreementIsn}`)
            }}
        >
          Следующий договор
        </Button>}
      </StyledAgreementNumber>
    },
    {
      title: "Статус",
      description: <LoadingString isLoading={loadingContract || loadingInvoiceStatus}>
        <Status status={statusCode}/>
        {statusCode === "signed" && <Status status={invoiceStatus}/>}
      </LoadingString>
    },
    {
      title: "Создана",
      description: <LoadingString
        isLoading={loadingDocs}>{`${(createdAt || "").name || "Не известный"}
        ${added}
        `}</LoadingString>
    },
    {
      title: "Точка продаж (группа)",
      description: <LoadingString
        isLoading={loadingDocs}>{`${createdAt?.type === "admin" ? "Администратор" : `${createdAt?.group?.name || "неизвестно"}`}`}</LoadingString>
    },
    // {
    //   title: "Последнее изменение",
    //   description: <LoadingString
    //     isLoading={loadingContract}>{`${(editor || "").name || "Не известный"} ${edited || "неизвестно когда"}`}</LoadingString>
    // },
    // {
    //   title: "Статус платежа",
    //   description: <LoadingString isLoading={loadingInvoiceStatus}><Status status={invoiceStatus}/></LoadingString>
    // }
  ]

  const totalInformationList = [
    {
      title: "Итого",
      description: <LoadingString
        isLoading={loadingContract}><TextButton>{money(sumWithoutDiscount)} {curCode || "RUB"}</TextButton></LoadingString>
    },
    {
      title: `Со скидкой КВ (${discount}%)`,
      description: <LoadingString
        isLoading={loadingContract}><TextButton>{money(sumAgreementWithDiscount)} {curCode || "RUB"}</TextButton></LoadingString>
    },
    ...(agentKv && !!show_kv ? [{
      title: "Вы заработаете",
      description: <LoadingString
        isLoading={loadingContract}><StyledImportantNumber><TextButton>{money(agentKv)} RUB</TextButton></StyledImportantNumber></LoadingString>
    }] : [])
  ]

  const sberDownloadDropDown: { label: string, event: () => void }[] = [
    {label: "Жизнь", event: () => downloadProjectHandler(templateSberLife)},
    {label: "Имущество", event: () => downloadProjectHandler(templateSberProperty)},
  ]

  const dropDownItems: IDropDownListItem[] = [
    {label: "С изменением условий", isDisabled: !agreementCalcIsn, event: () => editContract(true)},
    {label: "Без изменения условий", event: () => editContract()}
  ]

  if (errorDownloadContract) setNotification({type: "error", text: errorDownloadContract})
  if (errorGetKv) setNotification({type: "error", text: errorGetKv})
  if (errorInvoiceStatus) setNotification({type: "error", text: errorInvoiceStatus})
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  if (errorContract) return <ErrorMessage error={errorContract}/>

  const dropDownItemsForPayed: IDropDownListItem[] = [
    {label: "Дострахование рисков", event: () => setAddRiskModal(true)},
    {label: "Перерасчет", event: () => prolongationHandler("858981", "recalculation")}
  ]

  const haveRightsForProlongation: boolean = rolesRightHandler("prolongationsLogPage", functions, type, isAdmin);
  const haveRightForEditAgreement: boolean = rolesRightHandler("editAgreement", functions, type, isAdmin);
  const haveAnyPayments: boolean = (invoiceStatus === "payed");

  const downloadProjectButton = statusCode !== "signed" &&
      <Button
          isLoading={isLoading || loadingDownloadContract}
          appearance="link"
          iconBefore={<DownloadIcon/>}
          isDisabled={isDisabled || isCanceled}
          tooltip={isDisabled ? "Требуется перерасчет" : ""}
          onClick={ev => {
            ev.preventDefault();
            if (!isSberbankDoubleAgreement) return downloadProjectHandler();
            return setDownloadDropDown(true)
          }}
      >
        {windowWidth < 768 ? "Скачать" : "Скачать проект договора"}
      </Button>;

  const agreementEventButtons = statusCode === "signed" ?
    <FlyButtons isWhiteBackground={true}>
      <StyledSignetAgreementButtons>
        <Button
          isLoading={loadingDocs || loadingContract || loadingChangeContractStatus}
          isDisabled={!rolesRightHandler("invoicePage", functions, type, isAdmin || isCanceled)}
          appearance="transparent"
          onClick={() => history.push(`/createInvoice/${id}`)}>
          Получить документы
        </Button>

        {!needProlongation && haveRightsForProlongation && haveAnyPayments && nextInvoiceAllowed &&
            <Button
              isDisabled={isCanceled || isnInvoice}
              appearance="filled"
              width={windowWidth < 768 ? "100%" : undefined}
              isLoading={loadingChangeContractStatus || loadingCreateAddendum || loadingDocs || loadingContract}
              onClick={() => setPayPeriodModalIsOpen(true)}>
              Оплата очередного взноса
            </Button>
        }

        {!needProlongation && !(haveAnyPayments && nextInvoiceAllowed) && !haveAnyPayments && <DropDown
            trigger={
              <Button
                  isDisabled={haveAnyPayments ? isCanceled : !haveRightForEditAgreement || isCanceled}
                  iconAfter={<SelectArrowIcon direction={stateDropDownIsOpen ? "top" : "down"}/>}
                  appearance="filled"
                  width={windowWidth < 768 ? "100%" : undefined}
                  isLoading={loadingChangeContractStatus || loadingCreateAddendum || loadingDocs || loadingContract}
                  onClick={() => setDropDownIsOpen(true)}>
                Внести изменения
              </Button>}
            position={windowWidth < 768 ? "top" : undefined}
            isDropDownList={true}
            items={haveAnyPayments ? dropDownItemsForPayed : dropDownItems}
            isOpen={stateDropDownIsOpen}
            onClose={() => setDropDownIsOpen(false)}/>}

        {needProlongation && haveRightsForProlongation &&
            <Button
                appearance="filled"
                width={windowWidth < 768 ? "100%" : undefined}
                isLoading={loadingChangeContractStatus || loadingCreateAddendum || loadingDocs || loadingContract}
                onClick={() => setProlongationModal(true)}>
                Продлить договор
            </Button>}


      </StyledSignetAgreementButtons>
    </FlyButtons>
    :
    <FlyButtons isWhiteBackground={true}>
      <StyledButtonsGroupAutoSize>
        <Button
          isLoading={isLoading || loadingContract || loadingDocs}
          appearance="transparent"
          isDisabled={isCanceled}
          onClick={() => setSubmitType("preCalc")}
          form="contract-form">
          Пересчитать премию
        </Button>
        {(!prevIsn || prolongDeclarationOk) && <Button
          isLoading={isLoading || loadingContract || loadingDocs}
          appearance="filled"
          isDisabled={isDisabled || isCanceled}
          onClick={() => setSubmitType(undefined)}
          tooltip={isDisabled ? "Требуется перерасчет" : ""}
          form="contract-form">
          Подписать договор
        </Button>}
        {prevIsn && !prolongDeclarationOk && <Button
            isLoading={isLoading || loadingContract || loadingDocs}
            appearance="filled"
            isDisabled={isDisabled || isCanceled}
            onClick={() => showProlongDeclaration()}>
            Декларация
        </Button>}
      </StyledButtonsGroupAutoSize>
    </FlyButtons>

  return (
    <>
      <StyledHeaderWrapper>
        <StyledContractHeader>
          <Heading3>Общие сведения</Heading3>
          <StyledEventsButtons>
            {isSberbankDoubleAgreement ?
              <DropDown
                trigger={downloadProjectButton}
                isOpen={stateDownloadDropDown}
                isDropDownList={true}
                items={sberDownloadDropDown}
                onClose={() => setDownloadDropDown(false)}
              />
              : downloadProjectButton}

            {agreementEventButtons}

          </StyledEventsButtons>
        </StyledContractHeader>

        <StyledHeaderInformation>
          {informationList.map(({title, description}, index) => (
            <StyledInformationItem key={`info-item-${index}`}>
              <TextButton>{title}</TextButton>
              <TextButton>{description}</TextButton>
            </StyledInformationItem>
          ))}
          <StyledTotalInformation>
            {totalInformationList.map(({title, description}, index) => (
              <StyledTotalInformationItem key={`info-item-${index}`}>
                <TextButton>{title}</TextButton>
                {description}
              </StyledTotalInformationItem>
            ))}
          </StyledTotalInformation>
        </StyledHeaderInformation>
      </StyledHeaderWrapper>
      <PayPeriodModal
        isOpen={statePayPeriodModalIsOpen}
        header="Оплата очередного взноса"
        onClose={() => setPayPeriodModalIsOpen(false)}/>
      <AddRisksAddendumModal
        isOpen={stateAddRiskModal}
        data={{riskLife, riskProperty, riskTitle}}
        header="Дострахование рисков"
        onClose={() => setAddRiskModal(false)}/>
      <ProlongationModal
        isOpen={stateProlongationModal}
        data={{defaultAgentSelectValue, isSber}}
        header="Пролонгация договора"
        onClose={() => setProlongationModal(false)}/>
    </>
  )
};

export default ContractRegistrationHeader;
