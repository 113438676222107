import React                                            from 'react';
import Modal, {ModalProps}                              from "../../../uiKit/modal";
import Button                                           from "../../../uiKit/button/button";
import {useCallNotification}                            from "../../../hooks/useCallNotification";
import {StyledButtonsGroup}                             from "../../roles/components/header/styles";
import {useLazyQuery, useMutation}                      from "@apollo/react-hooks";
import {AGREEMENT_PROLONGATION}                         from "../../../utils/mutations";
import {GET_AGREEMENT_AGENTS, GET_MULTI_YEAR_AGREEMENT} from "../../../utils/queries";
import {ErrorMessage}                                   from "../../../components/errorMessage";
import {IFormField}                                     from "../../../uiKit/fieldsBuilder/interfaces";
import moment                                           from "moment";
import {getFormError}                                   from "../../../formUtils/getFormError";
import {getFormValue, stringToNumber}                   from "../../../formUtils/getFormValue";
import {useHistory}                                     from "react-router";
import FieldsBuilder                                    from "../../../uiKit/fieldsBuilder";
import Form                                             from '@atlaskit/form';
import {StyledProlongationInfoItem}                     from "../styles";
import {Text4}                                          from "../../../globalStyledComponents";

const ContinueContractBody = (props: ModalProps) => {
  const {setNotification} = useCallNotification();
  const history = useHistory();

  const [getMultiyearAgreement, {data: dataMultiyearAgreement, loading: loadingMultiyearAgreement, error: errorMultiyearAgreement}] = useLazyQuery(GET_MULTI_YEAR_AGREEMENT, {fetchPolicy: 'network-only'});

  const [getAgents, {data: dataAgents, loading: loadingAgents}] = useLazyQuery(GET_AGREEMENT_AGENTS);

  const [agreementProlongation, {loading: loadingAgreementProlongation}] = useMutation(AGREEMENT_PROLONGATION);

  const selectAgents = ((dataAgents || {}).getAgent || []).map(({SUBJISN, AGENTNAME}) => ({label: AGENTNAME, value: SUBJISN}));

  const {AGRID, AGRISN, DATEBEG, DATEEND, POLICY_HOLDER, PRODUCT} = dataMultiyearAgreement?.getMultiyearAgreement || {};
  const {LASTNAME, FIRSTNAME, PARENTNAME} = POLICY_HOLDER || {};

  const policyHolderName: string | undefined = !!LASTNAME || !!FIRSTNAME || !!PARENTNAME ? `${LASTNAME || ''} ${FIRSTNAME || ''} ${PARENTNAME || ''}`.trim() : undefined

  const isShowProlongationForm: boolean = !!AGRISN;

  React.useEffect(() => {
    if (!AGRISN) return;
    getAgents({variables: {agreementIsn: AGRISN}})
  }, [AGRISN, getAgents])

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValue(data);
    if (!!formData.contractNumber) return getMultiyearAgreement({variables: {id: formData.contractNumber}});
    if (!AGRISN) return setNotification({type: 'error', text: 'no AGRISN'})
    const {limitSum} = formData;
    agreementProlongation({
      variables: {
        isn: AGRISN,
        ...formData,
        limitSum: Number(stringToNumber(limitSum))
      }
    })
      .then(({data: {agreementProlongation}}) => {
        if (!agreementProlongation) return setNotification({type: "error", text: "Отсутствует id договора"})
        return history.push(`/contract/${agreementProlongation}`)
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const date = moment().format("DD.MM.YYYY");

  const form: IFormField[] = [
    {
      fieldItems: {
        name: "limitSum",
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите страховую сумму",
        placeholder: "Страховая сумма"
      }
    },
    {
      fieldItems: {
        name: "signDate",
        type: "date",
        isRequired: true,
        validationType: "minMaxDate",
        minDate: date,
        defaultValue: date,
        errorMessage: "Выберите дату подписания",
        placeholder: "Дата подписания"
      }
    },
    {
      isHidden: !selectAgents.length,
      fieldItems: {
        name: "agentIsn",
        type: "select",
        items: selectAgents,
        errorMessage: "Выберите агента",
        placeholder: "Агент",
        isClearable: true,
        isSearchAvailable: true,
      }
    },
  ]

  const formAgreementId: IFormField[] = [
    {
      fieldItems: {
        name: "contractNumber",
        type: "input",
        isRequired: true,
        errorMessage: "Введите номер договора",
        placeholder: "Номер договора"
      }
    }
  ]

  if (errorMultiyearAgreement) return <ErrorMessage error={errorMultiyearAgreement}/>

  return (
    <Form onSubmit={onFormSubmit}>
      {({formProps}) => (
        <form {...formProps}>
          {isShowProlongationForm && <FieldsBuilder formFields={form}/>}
          {!isShowProlongationForm && <FieldsBuilder formFields={formAgreementId}/>}

          {!!AGRID && <StyledProlongationInfoItem><Text4>Номер договора:&nbsp;</Text4><Text4>{AGRID}</Text4></StyledProlongationInfoItem>}
          {!!DATEBEG && <StyledProlongationInfoItem><Text4>Дата начала:&nbsp;</Text4><Text4>{DATEBEG}</Text4></StyledProlongationInfoItem>}
          {!!DATEEND && <StyledProlongationInfoItem><Text4>Дата окончания:&nbsp;</Text4><Text4>{DATEEND}</Text4></StyledProlongationInfoItem>}
          {!!PRODUCT && <StyledProlongationInfoItem><Text4>Продукт:&nbsp;</Text4><Text4>{PRODUCT}</Text4></StyledProlongationInfoItem>}
          {!!policyHolderName && <StyledProlongationInfoItem><Text4>Имя страхователя:&nbsp;</Text4><Text4>{policyHolderName}</Text4></StyledProlongationInfoItem>}

          <StyledButtonsGroup>
            <Button appearance="filled" type='submit' isLoading={loadingMultiyearAgreement || loadingAgents || loadingAgreementProlongation}>
              {isShowProlongationForm ? 'Продлить' : 'Получить договор'}
            </Button>
            <Button onClick={props.onClose} appearance="transparent" isLoading={loadingMultiyearAgreement || loadingAgents || loadingAgreementProlongation}>
              Отмена
            </Button>
          </StyledButtonsGroup>
        </form>
      )}
    </Form>
  )
}

export const ContinueContractModal = (props: ModalProps) => (
  <Modal {...props}>
    <ContinueContractBody {...props}/>
  </Modal>
)

export default ContinueContractModal;