import React                                                               from "react";
import {StyledDropDownBody, StyledDropDownListItem, StylesDropDownWrapper} from "./styles";
import ClickAwayListener                                                   from "react-click-away-listener";
import {useWindowWidth}                                                    from "@react-hook/window-size";
import Modal                                                               from "../modal";
import {TextButton}                                                        from "../../globalStyledComponents";
import {idGenerator}                                                       from "../../helpers/idGenerator";

export interface IDropDownListItem {
  label: string
  value?: string
  event?: () => void
  isDisabled?: boolean
}

interface IDropDownProps {
  trigger: any
  bodyWidth?: "100%"
  maxBodyWidth?: number
  isOpen: boolean
  onClose: () => void
  children?: any
  isDropDownList?: boolean
  items?: IDropDownListItem[]
  position?: "top" | "bottom"
}

const DropDownList = ({isOpen, onClose, position, bodyWidth, maxBodyWidth, items}: IDropDownProps) => {
  return (
    <>
      {isOpen &&
      <ClickAwayListener onClickAway={(ev) => {
        ev.preventDefault();
        onClose()
      }}>
        <StyledDropDownBody position={typeof position === "undefined" ? "bottom" : position} maxWidth={maxBodyWidth}
                            width={bodyWidth}>
          {(items || []).map(({label, event, isDisabled}, index) => (
            <StyledDropDownListItem
              onClick={event && !isDisabled ? () => event() : undefined}
              isDisabled={!!isDisabled}
              key={`dropDown-list-item-${index}`}>
              <TextButton>{label}</TextButton>
            </StyledDropDownListItem>
          ))}
        </StyledDropDownBody>
      </ClickAwayListener>
      }
    </>
  )
};

export const DropDown = (props: IDropDownProps) => {
  const id = idGenerator();
  const windowWidth = useWindowWidth({wait: 250});
  const {trigger, isDropDownList, maxBodyWidth, position, bodyWidth, isOpen, onClose, children} = props;
  const clickAwayHandler = (ev) => {
    ev.preventDefault();
    const className: string = String(ev.target.className) || "";
    if (className.startsWith("rc-calendar")) return;
    onClose();
  }
  return (
    <StylesDropDownWrapper className="nivanaviDropDown">
      {trigger}
      {isDropDownList ?
        <DropDownList {...props}/>
        :
        <>
          {windowWidth < 768 && <Modal onClose={onClose} isOpen={isOpen}>
            {children}
          </Modal>}
          {windowWidth > 767 && isOpen &&
          <ClickAwayListener onClickAway={ev => clickAwayHandler(ev)}>
            <StyledDropDownBody id={`dropDownElement-${id}`} position={typeof position === "undefined" ? "bottom" : position} maxWidth={maxBodyWidth}
                                width={bodyWidth}>
              {children}
            </StyledDropDownBody>
          </ClickAwayListener>}
        </>
      }
    </StylesDropDownWrapper>
  )
};

export default DropDown;