import React, {useState} from 'react';
import { Heading2}                 from "../../globalStyledComponents";
import {StyledUnderwritingWrapper} from "./styles";
import Form                        from '@atlaskit/form';
import ToggleArea                  from "./components/toggleArea";
import TableArea                   from "./components/tableArea";
import ApplicationByIdHeader       from "./components/header";
import SellerForm from "./components/sellerForm";
import {
  agreementSellerFilling,
  agreementUnderwritingFilling,
  getRestrictionsObject, KV_DISCOUNT_ISN
} from "../../helpers/buildTemplateForBack";
import {getFormValue, stringToNumber} from "../../formUtils/getFormValue";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {DOCS, GET_AGREEMENT, ME} from "../../utils/queries";
import {useParams} from "react-router";
import {CALC_AGREEMENT, UPDATE_AGREEMENT_CALC_FULL} from "../../utils/mutations";
import {useCallNotification} from "../../hooks/useCallNotification";
import {rolesRightHandler} from "../../helpers/rolesRightHandler";
import ChangeDiscountModal from "./components/header/ChangeDiscountModal";
import DisapproveModal from "./components/header/DisapproveModal";

export const AgreementById = () => {

  const {id} = useParams();
  const {data: dataAgreement, loading: loadingAgreement, error: errorAgreement} = useQuery(GET_AGREEMENT, {variables: {isn: id}});
  const agreementData = (dataAgreement || {}).agreementCalc || {};

  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL);
  const [calcAgreement, {loading: loadingCalcAgreement}] = useMutation(CALC_AGREEMENT, {refetchQueries: ["agreementCalc", "anketaTemplate"]});

  const {setNotification} = useCallNotification();

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role, restrictions} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {maxDiscount} = getRestrictionsObject(restrictions);

  const canChangeDiscount = !!maxDiscount;
  const canChangeAllowance = rolesRightHandler("applyAllowance", functions, type, isAdmin);

  const [stateDiscountModalIsOpen, setDiscountModalIsOpen] = React.useState<boolean>(false);
  const [stateDisapproveModalIsOpen, setDisapproveModalIsOpen] = React.useState<boolean>(false);
  const [stateSubmitType, setSubmitType] = useState<string|undefined>(undefined);

  const {objects, curCode, attribs} = (dataAgreement || {}).agreementCalc || {};
  const discount = stringToNumber(attribs?.find(({isn}) => isn === KV_DISCOUNT_ISN)?.val);

  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {
      isn: id
    }
  });
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {statusCode, risks, number, user: createdAt, data, added} = (docsList || [])[0] || {};

  const onFormSubmit = (data) => {

    if(stateSubmitType != 'recalc'){
      return;
    }

    console.log('onFormSubmit');

    const {agreementVariables, discountsList} = agreementSellerFilling(getFormValue(data), agreementData, canChangeDiscount, canChangeAllowance);

    agreementCalc({
      variables: agreementVariables
    })
      .then(({data: {agreementCalcFull}}) => {
        const {isn: isnAgreement} = agreementCalcFull || {};
        calcAgreement({variables: {isn: isnAgreement}})
          .then(() => {
            return setNotification({type: "success", text: "Данные успешно обновлены"});
          })
          .catch(error => {
            setNotification({type: "error", text: error})
          })
      })
      .catch(error => setNotification({type: "error", text: error}));
  }

  return (
    <>
      <Heading2>Согласование заявки</Heading2>
      <StyledUnderwritingWrapper>
        {(canChangeDiscount || canChangeAllowance) ?
          <Form onSubmit={data => onFormSubmit(data)}>
            {({formProps}) => (
              <form {...formProps} id="sellerForm">
                <ApplicationByIdHeader
                  showRecalcButton={true}
                  recalcLoading={loadingAgreementCalc || loadingCalcAgreement}
                  setDiscountModalIsOpen={setDiscountModalIsOpen}
                  setDisapproveModalIsOpen={setDisapproveModalIsOpen}
                  setSubmitType={setSubmitType}
                />
                <SellerForm
                  canChangeDiscount={canChangeDiscount}
                  canChangeAllowance={canChangeAllowance}
                  maxDiscount={maxDiscount}
                />
              </form>
            )}
          </Form>
          :
          <ApplicationByIdHeader
            showRecalcButton={false}
            recalcLoading={false}
            setDiscountModalIsOpen={setDiscountModalIsOpen}
            setDisapproveModalIsOpen={setDisapproveModalIsOpen}
            setSubmitType={setSubmitType}
          />
        }
        <TableArea/>
        <ToggleArea/>
        <ChangeDiscountModal
          data={{discount, agreementData: (dataAgreement || {}).agreementCalc || {}}}
          onClose={() => setDiscountModalIsOpen(false)}
          isOpen={stateDiscountModalIsOpen}
          header="Применить скидку"/>
        <DisapproveModal
          onClose={() => setDisapproveModalIsOpen(false)}
          isOpen={stateDisapproveModalIsOpen}
          data={{objects, risks}}
          header="Повторное согласование"/>
      </StyledUnderwritingWrapper>
    </>
  )
};

export default AgreementById;