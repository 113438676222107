import React                                                 from "react";
import FieldsBuilder
                                                             from "../../../../uiKit/fieldsBuilder";
import {IFormField}                                          from "../../../../uiKit/fieldsBuilder/interfaces";
import {radioBoolean, radioSex}                              from "../../staticData";
import {IRadioItem}                                          from "../../../../uiKit/radio";
import Form                                                  from '@atlaskit/form';
import {getFormError}                                        from "../../../../formUtils/getFormError";
import {getFormValue, getSelectDefaultValue, getSelectValue} from "../../../../formUtils/getFormValue";
import {useLazyQuery, useMutation, useQuery}                 from '@apollo/react-hooks';
import {CONTRAGENT_FIND, UPDATE_CONTRAGENT} from "../../../../utils/mutations";
import {useGetDict}                                          from "../../../../hooks/useGetDicti";
import {fieldsValidate, formatDateToUsa}                     from "../../../../formUtils/fieldsValidation";
import StoreAgreementForm                                    from "../../store";
import {IStepsProviderProps}   from "../stepsProvider";
import StepsLayout             from "../stepsLayout";
import StepsPaginationButton   from "../stepsPaginationButton";
import {StyledStepFormHeader}  from "./styles";
import {Heading3}              from "../../../../globalStyledComponents";
import {getFormValueWithArray} from "../../../../formUtils/getFormValueWithArray";
import {useCallNotification}   from "../../../../hooks/useCallNotification";
import moment                                                from "moment";
import {CONTRAGENT, ME}                                      from "../../../../utils/queries";
import {getPerson, getRestrictionsObject}  from "../../../../helpers/buildTemplateForBack";
import {ISelectItem}                                         from "../../../../uiKit/select";
import UseSaveAddressPerson    from "../../../../hooks/useSaveAddressPerson";
import {FioValue}              from "../../../../uiKit/daDataFioNew/types";
import {rolesRightHandler} from "../../../../helpers/rolesRightHandler";
import {isNewRegion} from "../../../../utils/address";

interface IContragentFormProps {
  isn?: string
  number?: number
  stateContragents?: IContragentItem[]
  setContragents?: any
  isShowShare?: boolean
  defaultShare?: number
}

const mode = process.env.REACT_APP_MODE

export const CONTRAGENT_FIELDS = ["fullName", "birthDate", "addressRadio", "phoneMobile", "email", "gender", "addr1", "addr2", "authDocSer", "authDocNumber", "authDocDate", "authDocIssuerCode", "authDocIssuer", "countryIsn"];

const setFieldName = (name: string, number?: number) => typeof number === "number" ? `${name}-${number}` : name

export const ContragentForm = ({isn, number, stateContragents, setContragents, isShowShare, defaultShare}: IContragentFormProps) => {
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const {setNotification} = useCallNotification();
  const {data: selectCountries, loading: loadingCountries} = useGetDict("nationality");
  // const {data: selectFamilyStatus, loading: loadingFamilyStatus} = useGetDict("familyStatus");
  const [getContragent, {
    data: dataQueryContragent,
    loading: loadingQueryContragent,
    error: errorQueryContragent
  }] = useLazyQuery(CONTRAGENT);
  const [findContragent, {data: dataContragent, loading: loadingContragent}] = useMutation(CONTRAGENT_FIND);
  const [stateShowAddress, setShowAddress] = React.useState<IRadioItem>(radioBoolean[0]);
  const [stateCountry, setCountry] = React.useState<ISelectItem>(selectCountries[0]);
  const [stateGender, setGender] = React.useState<IRadioItem>(radioSex[0]);
  const [stateFullName, setFullName] = React.useState<string>(mode === "dev" ? "Иванов Иван Иванович" : "");
  const [statePhoneMobile, setPhoneMobile] = React.useState<string>(mode === "dev" ? "+7 (900) 000-00-08" : "");
  const [stateContragentIsn, setContragentIsn] = React.useState<string>("");
  const [stateBirthDate, setBirthDate] = React.useState<string>(mode === "dev" ? "" : "");
  const [stateAuthDocSer, setAuthDocSer] = React.useState<string>("");
  const [stateAuthDocNumber, setAuthDocNumber] = React.useState<string>("");
  const [stateAuthDocDate, setAuthDocDate] = React.useState<string>("");
  const [stateAuthDocIssuerCode, setAuthDocIssuerCode] = React.useState<string>("");
  const [stateAuthDocIssuer, setAuthDocIssuer] = React.useState<string>("");
  const [stateEmail, setEmail] = React.useState<string>("");
  const [stateAddr1, setAddr1] = React.useState<string>("");

  const {fullName, birthDate, phoneMobile} = (dataQueryContragent || {}).contragent || {};
  const contragentInfo = ((dataContragent || {}).GetSubjectInfo || [])[0];
  const {
    email,
    gender,
    addr1,
    addr2,
    authDocSer,
    authDocNumber,
    authDocDate,
    authDocIssuerCode,
    authDocIssuer,
    countryIsn,
    phoneMobile: phoneMobileFromSearch
  } = contragentInfo || {};
  const {birthDate: storeBirthDate, borrowers} = agreementFormState || {};
  const defaultGenderValue: string | undefined = gender?.value || ((borrowers || [])[0] || {}).sex?.value;

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);

  React.useEffect(() => {
    const gender: IRadioItem | undefined = radioSex.find(({value}) => value === defaultGenderValue);
    if (!gender) return;
    setGender(gender);
  }, [defaultGenderValue])

  React.useEffect(() => {
    if (number !== 0 || !storeBirthDate || stateBirthDate === storeBirthDate) return;
    setBirthDate(storeBirthDate)
    // eslint-disable-next-line
  }, [storeBirthDate])

  React.useEffect(() => {
    if (fullName && stateFullName !== fullName) setFullName(fullName);
    if (birthDate && stateBirthDate !== birthDate) setBirthDate(birthDate);
    if (phoneMobile && statePhoneMobile !== phoneMobile) setPhoneMobile(phoneMobile);
    if (authDocSer && stateAuthDocSer !== authDocSer) setAuthDocSer(authDocSer);
    if (authDocNumber && stateAuthDocNumber !== authDocNumber) setAuthDocNumber(authDocNumber);
    if (authDocDate && stateAuthDocDate !== authDocDate) setAuthDocDate(authDocDate);
    if (authDocIssuerCode && stateAuthDocIssuerCode !== authDocIssuerCode) setAuthDocIssuerCode(authDocIssuerCode);
    if (authDocIssuer && stateAuthDocIssuer !== authDocIssuer) setAuthDocIssuer(authDocIssuer);
    if (email && !stateEmail) setEmail(email);
    if (addr1 && !stateAddr1) setAddr1(addr1);
    if (phoneMobileFromSearch && !statePhoneMobile) setPhoneMobile(phoneMobileFromSearch);
    // eslint-disable-next-line
  }, [fullName, birthDate, phoneMobile, authDocSer, authDocNumber, email, addr1, authDocDate, authDocIssuerCode, authDocIssuer])

  React.useEffect(() => {
    if (!loadingContragent && !loadingQueryContragent) return;
    return setNotification({
      type: "loading",
      text: "Загружаем данные"
    })
    // eslint-disable-next-line
  }, [loadingContragent, loadingQueryContragent])

  React.useEffect(() => {
    if (!isn) return;
    getContragent({variables: {isn}})
    // eslint-disable-next-line
  }, [isn])

  React.useEffect(() => {
    if (!stateFullName || !statePhoneMobile || !stateBirthDate || loadingContragent || stateContragentIsn) return;
    if (
      fieldsValidate({value: {value: stateFullName}, validationType: "fullName"}) ||
      fieldsValidate({value: statePhoneMobile, validationType: "phone"}) ||
      fieldsValidate({value: stateBirthDate, validationType: "date"})) return;
    if(authDocSer && authDocNumber && authDocDate && authDocIssuerCode && stateAuthDocIssuer && email && addr1) {
      console.log('Phone changed, but no need to search contragent');
        return;
    }
    console.log('Search contragent by name, phone, birthdate:', stateFullName, statePhoneMobile, stateBirthDate);
    findContragent({
      variables: {
        fullName: stateFullName,
        phone: statePhoneMobile,
        birthday: stateBirthDate,
        needCreated: 0
      }
    })
      .then(({data: {GetSubjectInfo}}) => {
        if (((GetSubjectInfo || [])[0] || {}).isn){
            console.log('Contragent found:', GetSubjectInfo[0]);
        } else {
          return setNotification({
            type: "warning",
            text: "Не найден"
          });
        }
      })
      .catch(error => setNotification({type: "error", text: error}))
    // eslint-disable-next-line
  }, [stateFullName, statePhoneMobile, stateBirthDate])

  React.useEffect(() => {
    if (!stateFullName || !stateBirthDate || !stateAuthDocSer || !stateAuthDocNumber || loadingContragent || stateContragentIsn) return;
    if (
        fieldsValidate({value: {value: stateFullName}, validationType: "fullName"}) ||
        fieldsValidate({value: stateBirthDate, validationType: "date"}) ||
        fieldsValidate({value: stateAuthDocSer, validationType: "passportSerial"}) ||
        fieldsValidate({value: stateAuthDocNumber, validationType: "passportNumber"})
    ) return;
    console.log('Search contragent by name, birthdate, passport:', stateFullName, stateBirthDate, stateAuthDocSer, stateAuthDocNumber);
    findContragent({
      variables: {
        fullName: stateFullName,
        birthday: stateBirthDate,
        docClassIsn: "1165",
        docClassName: "Паспорт гражданина России",
        docSer: stateAuthDocSer,
        docNo: stateAuthDocNumber,
        needCreated: 0
      }
    })
        .then(({data: {GetSubjectInfo}}) => {
          if (((GetSubjectInfo || [])[0] || {}).isn) {
            console.log('Contragent found:', GetSubjectInfo[0]);
          }
        })
        .catch(error => setNotification({type: "error", text: error}))
    // eslint-disable-next-line
  }, [stateFullName, stateBirthDate, stateAuthDocSer, stateAuthDocNumber])

  React.useEffect(() => {
    if (!stateContragentIsn || loadingContragent) return;
    if (fieldsValidate({value: stateContragentIsn, validationType: "contragentIsn"})) return;
    console.log('Search contragent by isn:', stateContragentIsn);
    findContragent({
      variables: {
        SubjISN: stateContragentIsn,
      }
    })
        .then(({data: {GetSubjectInfo}}) => {
          if (((GetSubjectInfo || [])[0] || {}).isn) {
            console.log('Contragent found:', GetSubjectInfo[0]);
          } else {
            return setNotification({
              type: "warning",
              text: "Не найден"
            });
          }
          const cont = ((GetSubjectInfo || [])[0] || {});
          setFullName(cont?.fullName);
          setPhoneMobile(cont?.phoneMobile);
          setBirthDate(cont?.birthDate);
          setGender(cont?.gender == "0" ? radioSex[1] : radioSex[0]);
        })
        .catch(error => setNotification({type: "error", text: error}))
  }, [stateContragentIsn]);

  React.useEffect(() => {
    if (!stateContragents || !setContragents) return;
    const {isn: isnContragent} = contragentInfo || {};
    const isAlreadyAdded = !!stateContragents.find(({isn}) => isn === isnContragent);
    if (isAlreadyAdded) return setNotification({
      type: "error",
      text: "Искомый контрагент уже добавлен"
    });
    if (!isnContragent) return saveContragentData("", {});
    setNotification({
      type: "success",
      text: "Искомый контрагент найден"
    });
    return saveContragentData(isnContragent, contragentInfo)
    // eslint-disable-next-line
  }, [contragentInfo])

  const saveContragentData = (isn: string, data: any) => {
    if (!stateContragents || !setContragents) return;
    setContragents(stateContragents.map((contragen) => {
      const {number: stateNumber} = contragen;
      if (stateNumber !== number) return contragen;
      return {
        ...contragen,
        isn,
        data,
      }
    }))
  }

  const fullNameChangeHandler = (currentName: FioValue) => {
    const {value, gender} = currentName;
    if (stateCountry?.value === "9602") {
      const reg: RegExp = /[A-Za-z]/g;
      const radioGender: IRadioItem | undefined = typeof gender === "string" ? gender === "MALE" ? radioSex[0] : radioSex[1] : undefined
      if (radioGender) setGender(radioGender);
      return setFullName(value.replace(reg, ""))
    }
    setFullName(value)
  };

  const form: IFormField[] = [
    {
      isHidden: stateCountry?.value !== "9602",
      fieldItems: {
        type: "fio",
        name: setFieldName("fullName", number),
        placeholder: "ФИО заемщика",
        isRequired: true,
        defaultValue: {value: stateFullName},
        onChange: (value) => fullNameChangeHandler(value),
        errorMessage: "Введите Ф.И.О",
        validationType: "fullName",
      }
    },
    {
      isHidden: stateCountry?.value === "9602",
      fieldItems: {
        type: "fio",
        name: setFieldName("fullName", number),
        placeholder: "ФИО заемщика",
        isRequired: true,
        defaultValue: {value: stateFullName},
        onChange: value => fullNameChangeHandler(value),
        errorMessage: "Введите Ф.И.О",
        validationType: "fullName",
      }
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "date",
          name: setFieldName("birthDate", number),
          isDisabled: number === 0 && !!storeBirthDate && !rolesRightHandler("searchContragentByIsn", functions, type, isAdmin),
          isRequired: true,
          onChange: (value) => setBirthDate(value),
          defaultValue: stateBirthDate,
          errorMessage: "Введите дату рождения",
          placeholder: "Дата рождения"
        },
        {
          isHidden: isShowShare || !rolesRightHandler("searchContragentByIsn", functions, type, isAdmin),
          type: "input",
          name: setFieldName("isn", number),
          placeholder: "Поиск по ISN",
          onChange: (value) => setContragentIsn(value),
          defaultValue: stateContragentIsn,
          validationType: "contragentIsn",
        },
        {
          isHidden: !isShowShare,
          type: "range",
          isRequired: true,
          errorMessage: "Выберите долю",
          name: `share-${number}`,
          defaultValue: defaultShare,
          placeholder: `Доля в общем доходе, %`,
          startValue: 0,
          endValue: 100
        }
      ]
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "input",
          name: setFieldName("phoneMobile", number),
          isRequired: true,
          debounce: true,
          onChange: (value) => setPhoneMobile(value),
          defaultValue: statePhoneMobile,
          mask: "+7 (999) 999-99-99",
          validationType: "phone",
          errorMessage: "Введите телефон",
          placeholder: "Телефон"
        },
        {
          type: "input",
          name: setFieldName("email", number),
          isRequired: true,
          defaultValue: stateEmail,
          validationType: "email",
          errorMessage: "Введите e-mail",
          placeholder: "E-mail",
          onChange: (value) => setEmail(value)
        }
      ]
    },
    {
      label: "Пол заемщика",
      fieldItems: {
        type: "radio",
        name: setFieldName("gender", number),
        defaultValue: stateGender,
        onChange: value => setGender(value),
        items: radioSex,
        returnFullValue: true
      }
    },
    // {
    //   fieldItems:
    //     {
    //       type: "input",
    //       name: setFieldName("birthPlace", number),
    //       isRequired: true,
    //       defaultValue: birthPlace,
    //       errorMessage: "Введите место рождения",
    //       placeholder: "Место рождения"
    //     }
    // },
    {
      fieldItems: {
        type: "address",
        name: setFieldName("addr1", number),
        defaultValue: stateAddr1,
        placeholder: "Адрес регистрации",
        isRequired: true,
        errorMessage: "Введите адрес",
        onChange: (value) => setAddr1(value)
      }
    },
    {
      label: "Адрес регистрации совпадает с фактическим?",
      fieldItems: {
        type: "radio",
        name: setFieldName("addressRadio", number),
        defaultValue: stateShowAddress,
        onChange: (value) => setShowAddress(value),
        items: radioBoolean
      }
    },
    {
      isHidden: stateShowAddress.value === true,
      fieldItems: {
        type: "address",
        name: setFieldName("addr2", number),
        isRequired: true,
        defaultValue: addr2 || "",
        errorMessage: "Введите адрес",
        placeholder: "Фактический адрес проживания"
      }
    },
    // {
    //   columns: 2,
    //   columnGap: 4,
    //   fieldItems: {
    //     type: "select",
    //     name: setFieldName("familyStatus", number),
    //     returnFullValue: true,
    //     isRequired: true,
    //     errorMessage: "Выберите семейное положение",
    //     placeholder: "Выберите семейное положение",
    //     isLoading: loadingFamilyStatus,
    //     items: selectFamilyStatus
    //   }
    // },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "input",
          name: setFieldName("authDocSer", number),
          mask: "9999",
          isRequired: true,
          defaultValue: stateAuthDocSer,
          validationType: "passportSerial",
          errorMessage: "Введите серию паспорта",
          placeholder: "Серия паспорта",
          onChange: (value) => setAuthDocSer(value)
        },
        {
          type: "input",
          name: setFieldName("authDocNumber", number),
          mask: "999999",
          isRequired: true,
          defaultValue: stateAuthDocNumber,
          validationType: "passportNumber",
          errorMessage: "Введите номер паспорта",
          placeholder: "Номер паспорта",
          onChange: (value) => setAuthDocNumber(value)
        }
      ]
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "date",
          name: setFieldName("authDocDate", number),
          isRequired: true,
          defaultValue: stateAuthDocDate,
          errorMessage: "Введите дату выдачи паспорта",
          placeholder: "Дата выдачи паспорта",
          validationType: "minMaxDate",
          maxDate: moment().format("DD.MM.YYYY"),
          onChange: (value) => setAuthDocDate(value)
        },
        {
          type: "input",
          name: setFieldName("authDocIssuerCode", number),
          isRequired: true,
          mask: "999-999",
          defaultValue: stateAuthDocIssuerCode,
          errorMessage: "Введите код подразделения",
          placeholder: "Код подразделения",
          onChange: (value) => setAuthDocIssuerCode(value)
        }
      ]
    },
    {
      fieldItems: {
        type: "input",
        name: setFieldName("authDocIssuer", number),
        isRequired: true,
        defaultValue: stateAuthDocIssuer,
        errorMessage: "Введите кем выдан паспорт",
        placeholder: "Кем выдан",
        onChange: (value) => setAuthDocIssuer(value)
      }
    },
    {
      fieldItems: {
        type: "select",
        name: setFieldName("countryIsn", number),
        returnFullValue: true,
        isRequired: true,
        defaultValue: getSelectDefaultValue(countryIsn, selectCountries) || getSelectDefaultValue("9602", selectCountries),
        errorMessage: "Выберите гражданство",
        onChange: value => setCountry(value),
        placeholder: "Гражданство",
        isLoading: loadingCountries,
        items: selectCountries,
        isSearchAvailable: true,
        isClearable: true
      }
    },
  ]

  if (errorQueryContragent) setNotification({type: "error", text: errorQueryContragent})
  return <FieldsBuilder formFields={form}/>
}

export interface IContragentItem {
  number: number
  isn: string
  data: any
}

export const GeneralStep = ({title, id, changeStep, step, isAgreementEdit}: IStepsProviderProps) => {
  const {setNotification} = useCallNotification();
  const {saveAddress, loading: loadingSaveAddress} = UseSaveAddressPerson();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) => actionsAgreementForm.setState({
    agreementFormState: {
      ...agreementFormState,
      ...data
    }
  });
  const [stateContragents, setContragents] = React.useState<IContragentItem[]>([{number: 0, isn: "", data: {}}]);
  const [stateIsInsured, setIsInsured] = React.useState<boolean>(true);
  const {data: dataUser} = useQuery(ME);
  const [findContragent, {loading: loadingContragent}] = useMutation(CONTRAGENT_FIND);
  const [updateContragent, {loading: loadingUpdateContragent}] = useMutation(UPDATE_CONTRAGENT);
  const {isn: isnContragent} = getPerson(agreementFormState, "contragent");
  const {isn: isnInsured} = getPerson(agreementFormState, "insured");
  const {peoples, riskLife} = agreementFormState || {};
  const {restrictions} = (dataUser || {}).me || {};
  const restrictionsValue = getRestrictionsObject(restrictions);
  const maxAgeValidation = riskLife && !isNaN(restrictionsValue?.contragentMaxAge) ? restrictionsValue?.contragentMaxAge : undefined;

  React.useEffect(() => {
    if (!isnInsured) return;
    setContragents([...stateContragents, {number: 1, isn: "", data: {}}]);
    if (stateIsInsured) setIsInsured(false)
    // eslint-disable-next-line
  }, [isnInsured])

  React.useEffect(() => {
    if (stateIsInsured) return setContragents(stateContragents.filter(({number}) => number !== 1));
    setContragents([...stateContragents.filter(({number}) => number !== 1), {number: 1, isn: "", data: {}}]);
    // eslint-disable-next-line
  }, [stateIsInsured])

  const isInsuredForm: IFormField[] = [
    {
      fieldItems: {
        type: "checkbox",
        name: "isInsured",
        defaultValue: stateIsInsured,
        label: "Страхователь является заемщиком",
        onChange: (value) => setIsInsured(value)
      }
    }
  ]

  const nextStepHandler = (contragents: any[], needLength: number) => {
    const isnList: string[] = contragents.reduce((prev, {isn}) => isn ? [...prev, isn] : prev, []);
    if (isnList.length !== needLength) return;
    if (contragents[0]?.isn === contragents[1]?.isn) return setNotification({
      type: "error",
      text: "Добавлен один и тот же контрагент"
    })
    const oldContragents: any[] = (peoples || []).filter(({type}) => type !== "coBorrower");
    const newContragents: any[] = contragents.map(contragent => {
      const {isn} = contragent || {};
      const old = oldContragents.find(({isn: isnOld}) => isn === isnOld);
      if (old) return {...old, ...contragent};
      return contragent;
    })
    updateAgreementForm({
      peoples: [...(peoples || []).filter(({type}) => type === "coBorrower"), ...newContragents]
    })
    return changeStep(step + 1)
  }

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValueWithArray(getFormValue(data), CONTRAGENT_FIELDS, "contragents");
    const {contragents} = formData;
    const contragentsList: any[] = [];
    let ageError: boolean = false;
    let maxAgeError: boolean = false;
    let regionError: boolean = false;
    contragents.forEach(({birthDate, addr1}) => {
      const age: number = moment().diff(formatDateToUsa(birthDate), 'years');
      if (age < 14) ageError = true;
      if (maxAgeValidation && age > maxAgeValidation) maxAgeError = true;
      if(isNewRegion(addr1)) regionError = true;
    });
    const passportError: boolean = contragents.reduce((prev, {birthDate, authDocDate}) => {
      if (moment(formatDateToUsa(authDocDate)).isBefore(formatDateToUsa(birthDate))) return true;
      return prev;
    }, false);
    if (ageError) return setNotification({type: "error", text: "Запрещено для лиц моложе 14 лет"});
    if (maxAgeError) return setNotification({type: "error", text: `Принятие по личному страхованию  лиц старше ${maxAgeValidation} лет невозможно`});
    if (passportError) return setNotification({type: "error", text: "Дата выдачи паспорта не может быть раньше даты рождения"});
    if (regionError) return setNotification({type: "error", text: "Расчет по выбранным параметрам невозможен. Обратитесь к куратору!"});

    contragents.forEach((contragent, index) => {
      const dataContragent = {...((stateContragents || [])[index] || {}).data || {}, ...contragent};
      const {addr1, addr2, countryIsn, gender, addressRadio, ...other} = dataContragent;
      const {fullName, birthDate, phoneMobile, authDocSer, authDocNumber} = dataContragent;
      const isn = stateContragents[index].isn;
      const variables = {
        ...other,
        isn,
        gender: getSelectValue(gender),
        countryIsn: getSelectValue(countryIsn)
      };
      return findContragent({
        variables: {
          fullName,
          phone: phoneMobile,
          birthday: birthDate,
          docClassIsn: "1165",
          docClassName: "Паспорт гражданина России",
          docSer: authDocSer,
          docNo: authDocNumber,
          needCreated: 1
        }
      })
        .then(({data: {GetSubjectInfo}}) => {
          const dataContragent = {...(GetSubjectInfo || [])[0] || {}, ...contragent};
          const {isn, addr1, addr2, countryIsn, gender, addressRadio, ...other} = dataContragent;
          const variables = {
            ...other,
            isn,
            gender: getSelectValue(gender),
            countryIsn: getSelectValue(countryIsn),
            type: index === 0 ? "contragent" : "insured"
          };
          updateContragent({variables})
            .then(() => {
              contragentsList[index] = {...dataContragent, isn, type: index === 0 ? "contragent" : "insured"};
              saveAddress(isn, addr1, "2246");
              if (!addressRadio) saveAddress(isn, addr2, "2247");
              nextStepHandler(contragentsList, contragents.length);
            })
            .catch(error => setNotification({type: "error", text: error}))
        })
        .catch(error => setNotification({type: "error", text: error}))
    })
  }

  return (
    <Form onSubmit={data => onFormSubmit(data)}>
      {({formProps}) => (
        <form {...formProps} id={id}>
          <StepsLayout title={title}>
            {stateContragents.map(({isn, number}, index) => (
              <React.Fragment key={`info-${index}`}>
                {number !== 0 && <StyledStepFormHeader>
                    <Heading3>Общие сведения о застрахованном лице</Heading3>
                </StyledStepFormHeader>}
                <ContragentForm
                  isn={isAgreementEdit ? number === 0 ? isnContragent : isnInsured : undefined}
                  number={number}
                  stateContragents={stateContragents}
                  setContragents={setContragents}/>
                {number === 0 && riskLife && <FieldsBuilder formFields={isInsuredForm}/>}
              </React.Fragment>
            ))}
          </StepsLayout>
          <StepsPaginationButton isLoading={loadingUpdateContragent || loadingSaveAddress || loadingContragent}
                                 formId={id}/>
        </form>
      )}
    </Form>
  )
}

export default GeneralStep;
