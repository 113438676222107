import React, {useCallback, useEffect, useState}                           from 'react';
import {Image, StyledClosePreview, StyledImagePreviewWrapper, StyledPopup} from "./styles";
import {IImagePreviewProps}                                                from "./types";
import ClickAwayListener                                                   from "react-click-away-listener";
import Button                                                              from "../button/button";
import {CloseIcon}                                                         from "../../icons/ui/Close";

const PreviewImage = (props: IImagePreviewProps) => {
  const {onChange, isOpen, src, alt} = props;
  const [statePreviewIsOpen, setPreviewIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof isOpen !== "boolean" || statePreviewIsOpen === isOpen) return;
    setPreviewIsOpen(isOpen);
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (!onChange) return;
    onChange(statePreviewIsOpen);
    // eslint-disable-next-line
  }, [statePreviewIsOpen]);

  const handleOpen = useCallback(() => setPreviewIsOpen(true), [setPreviewIsOpen]);

  const handleClose = useCallback((ev) => {
    ev.stopPropagation();
    setPreviewIsOpen(false);
  }, [setPreviewIsOpen]);

  return (
    <StyledImagePreviewWrapper onClick={handleOpen}>

      <ClickAwayListener onClickAway={() =>  setPreviewIsOpen(false)}>
        <StyledPopup isOpen={statePreviewIsOpen}>
          <StyledClosePreview isOpen={statePreviewIsOpen}>
            <Button appearance="icon"
                    icon={<CloseIcon/>}
                    onClick={handleClose}
            />
          </StyledClosePreview>
          <Image src={src} alt={alt}/>
        </StyledPopup>
      </ClickAwayListener>
    </StyledImagePreviewWrapper>
  );
};

export default PreviewImage;
