import {IFormField}          from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {getFormError}        from "../../../../../../formUtils/getFormError";
import {getFormValue}        from "../../../../../../formUtils/getFormValue";
import FieldsBuilder         from "../../../../../../uiKit/fieldsBuilder";
import Button                from "../../../../../../uiKit/button/button";
import React                 from "react";
import Form                  from '@atlaskit/form';
import {useMutation}         from '@apollo/react-hooks';
import {AUTH}                from "../../../../../../utils/mutations";
import {useHistory}          from "react-router";
import {getAllowedPathname}  from "../../../../../../helpers/rolesRightHandler";
import {useCallNotification} from "../../../../../../hooks/useCallNotification";
import {client}              from "../../../../../../Apollo";

const {JSEncrypt} = require('jsencrypt');
const publicPasswordKey = process.env.REACT_APP_PASSWORD_KEY
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicPasswordKey);

const loginForm: IFormField[] = [
  {fieldItems: {type: "input", name: "login", placeholder: "Логин", isRequired: true, errorMessage: "Введите логин"}},
  {
    fieldItems: {
      type: "input",
      name: "password",
      inputType: "password",
      placeholder: "Пароль",
      isRequired: true,
      errorMessage: "Введите пароль",
      validationType: "password"
    }
  },
]

export const LoginDefault = (/*{setRecoveryPassword}: { setRecoveryPassword: (value: boolean) => void }*/) => {
  const history = useHistory();
  const {setNotification} = useCallNotification();
  const [userAuthorization, {loading: loadingUserAuthorization}] = useMutation(AUTH);

  const areYouWelcome = (id, callback) => {
    localStorage.setItem("rolesIsn", id);
    callback()
  }
  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {login, password} = getFormValue(data);
    client.resetStore();
    userAuthorization({
      variables: {
        login,
        password: encrypt.encrypt(password)
      }
    })
      .then(({data: {signIn: {id, isAdmin, type, role}}}) => {
        if (!id) return setNotification({type: "error", text: "Отсутствует roleIsn"})
        const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
        const path: string | undefined = getAllowedPathname(functions, type, isAdmin);
        if (path) return areYouWelcome(id, () => history.push(path));
        if (functions.length === 0) return setNotification({type: "loading", text: "Ожидайте подтверждения"});
        return setNotification({type: "error", text: "Неверный логин или пароль"});
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={loginForm}/>
            <Button isLoading={loadingUserAuthorization} type="submit" width="100%" appearance="filled">
              Войти
            </Button>
          </form>
        )}
      </Form>
      {/*<Button onClick={() => setRecoveryPassword(true)} appearance="link">Забыли пароль?</Button>*/}
    </>
  )
};

export default LoginDefault;