import React                                                                      from "react";
import {StyledCheckbox, StyledCheckboxWrapper, StyledCircle, StyledToggleWrapper} from "./styles";
import { IToggleCheckboxProps }                                                   from "./types";
import {Text4}                                                                    from "../../globalStyledComponents";

const ToggleCheckbox: React.FC<IToggleCheckboxProps> = props => {
  const { value = false, isDisabled, onChange, label, activeLabel, deactivatedLabel } = props;
  const [stateCheckbox, setCheckbox] = React.useState<boolean>(value);

  React.useEffect(() => {
    return setCheckbox(value);
  }, [value]);

  const checkboxChangeHandler = () => {
    if (isDisabled) return;
    setCheckbox(!stateCheckbox);
    if (onChange) onChange(!stateCheckbox);
  };

  return (
    <StyledToggleWrapper>
      <StyledCheckboxWrapper isChecked={stateCheckbox} className="toggleCheckbox">
        <StyledCheckbox type="checkbox" onChange={checkboxChangeHandler} checked={stateCheckbox} />
        <StyledCircle />
      </StyledCheckboxWrapper>
      {stateCheckbox && <Text4>{activeLabel || label}</Text4>}
      {!stateCheckbox && <Text4>{deactivatedLabel || label}</Text4>}
    </StyledToggleWrapper>

  );
};

export default React.memo(ToggleCheckbox);
