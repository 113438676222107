import styled       from "styled-components";
import {blue, grey} from "../../../../globalStyledComponents";

export const StyledRoleItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${grey};
  height: 6.5rem;
  
  :last-child {
    border-bottom: 1px solid ${grey};
  }
`;
export const StyledRoleHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
 cursor: pointer;
   :hover {
    color: ${blue};
  }
`;



export const StyledDeleteRoleModalBody = styled.div`
  margin: 2rem 6rem;
  h3 {
    margin: 0 0 4rem 0;
    text-align: center;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledRolesCategory = styled.div`
  h3 {
    margin: 4rem 0 2rem 0;
  }
`;
