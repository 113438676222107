import styled from "styled-components";
import {grey} from "../../../../../../../globalStyledComponents";

export const StyledCuratorContactsWrapper = styled.div`
margin-top: 2rem;
> p {
  opacity: 0.5;
}
`;
export const StyledCuratorContactItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1rem;
  
  > p:first-child {
    opacity: 0.5;
  }
  
  border-bottom: 1px solid ${grey};
  margin-bottom: 1.5rem;
  padding-bottom: 2rem;
  
  @media screen and (max-width: 767px) {
   > p:last-child {
      font-size: 1.6rem;
      line-height: 2.4rem;
   }
  }
`;