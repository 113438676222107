import styled       from "styled-components";
import {blue, grey} from "../../globalStyledComponents";

export const StyledRadioWrapper = styled.div<{ columns?: number, columnGap: number, isDisabled: boolean }>`
  ${({isDisabled}) => isDisabled ?
          `
          cursor: no-drop;
           `
          : ""}
  
  ${({columns, columnGap}) => {
    if (!columns || columns <= 0) return "";
    const gridColumns = Array.from({length: columns}).reduce((prev, next) => `${prev} 1fr`, "");
    return `
  display: grid;
  grid-template-columns: ${gridColumns};
  grid-column-gap: ${columnGap}rem;
  grid-row-gap: 1.5rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  `
  }}
  position: relative;
`;
export const StyledRadioItem = styled.div<{ isActive: boolean, isDisabled: boolean }>`
  display: inline-flex;
  align-items: center;
  margin: 0 3rem 1rem 0;
  white-space: nowrap;
  cursor: pointer;

  :hover > div {
    border: 1px solid ${blue};
  }

  ${({isDisabled}) => isDisabled ?
          `
          pointer-events: none;
           `
          : ""}
  p {
    transition: opacity 0.2s;
    ${({isActive}) =>
            isActive ?
                    `
    opacity: 1;
    `
                    :
                    `
    opacity: 0.5;
    `
    }
  }
`;


export const StyledRadio = styled.div<{ isShow: boolean }>`
  transition: border 0.2s;
  min-width: 2rem;
  max-width: 2rem;
  min-height: 2rem;
  max-height: 2rem;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid ${grey};
  margin-right: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    content: "";
    transition: opacity 0.2s;
    ${({isShow}) =>
            isShow ?
                    `
      opacity: 1;
      `
                    :
                    `
      opacity: 0;
      `
    }
    background: ${blue};
    width: 1rem;
    height: 1rem;
    box-sizing: border-box;
    border-radius: 50%;
  }
`;