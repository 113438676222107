import React                                         from "react";
import {StyledStepFormHeader, StyledStepFormWrapper} from "../../../generalStep/styles";
import {Heading3}                                  from "../../../../../../globalStyledComponents";
import UploadFile                                  from "../../../../../../uiKit/uploadFile";
import Form                                        from '@atlaskit/form';
import StoreAgreementForm                          from "../../../../store";
import {AnketaPaginationButtons}                   from "../../index";
import {useLazyQuery, useMutation}                 from "@apollo/react-hooks";
import {ANKETA_TEMPLATE}                           from "../../../../../../utils/queries";
import {Loader}                                    from "../../../../../../uiKit/loader";
import {useCallNotification}                       from "../../../../../../hooks/useCallNotification";
import {anketaPartsBuilder, anketaSettingsBuilder} from "../../../../../../helpers/buildTemplateForBack";
import {UPDATE_ANKETA}                             from "../../../../../../utils/mutations";

export const UploadFileFillingForm = ({person, onClose}) => {
  const {setNotification} = useCallNotification();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const [stateFile, setFile] = React.useState<any[]>([]);
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [updateAnketa, {loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const {peoples, templateVariables} = agreementFormState || {};
  const {isn: isnPerson, attachments} = person || {};

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: templateVariables})
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    if (!attachments || stateFile.length === attachments.length) return;
    setFile(attachments);
    // eslint-disable-next-line
  }, [attachments?.length])

  const addFileHandler = (file) => {
    setFile(file)
  }

  const onFormSubmit = () => {
    const partsWithValue: any[] = anketaPartsBuilder({...agreementFormState, ...person}, parts);
    const settingsWithValue: any[] = anketaSettingsBuilder({...agreementFormState, ...person}, settings);
    updateAnketa({
      variables: {
        clientIsn: isnPerson,
        settings: settingsWithValue,
        parts: partsWithValue,
        templateVariables
      }
    }).then(({data: {anketa: {isn: isnAnketa}}}) => {
      updateAgreementForm({
        peoples: (peoples || []).map(person => {
          const {isn} = person || {};
          if (isn === isnPerson) return {
            ...person,
            isnAnketa,
            fillingType: "upload",
            attachments: stateFile
          };
          return person;
        })
      });
      onClose();
    }).catch(error => setNotification({type: "error", text: error}))
  }

  if (loadingAnketaTemplate) return <Loader type="simple"/>
  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})

  return (
    <>
      <Form onSubmit={() => onFormSubmit()}>
        {({formProps}) => (
          <form {...formProps} id="uploadAnketaFilesForm">
            <StyledStepFormWrapper>
              <StyledStepFormHeader>
                <Heading3>Документы</Heading3>
              </StyledStepFormHeader>
              <UploadFile value={stateFile} onChange={addFileHandler}/>
            </StyledStepFormWrapper>
          </form>
        )}
      </Form>
      <AnketaPaginationButtons onClose={onClose} isLoading={loadingUpdateAnketa} isDisabled={stateFile.length === 0} formId={"uploadAnketaFilesForm"}/>
    </>
  )
}

export default UploadFileFillingForm;