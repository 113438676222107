import React                     from "react";
import {StyledStepWrapper}       from "../generalStep/styles";
import HeaderStep                from "../stepsHeader";
import {IFormField}              from "../../../../uiKit/fieldsBuilder/interfaces";
import GeneralStep               from "../generalStep";
import PropertyLossInsuranceStep from "../proprtyLossInsuranceStep";
import BusynessStep              from "../busynessStep";
import AgreementStep             from "../agreementStep";
import InsuranceStep             from "../insuranceStep";
import PropertyInsuranceStep     from "../propertyInsuranceStep";
import DocumentsStep             from "../documentsStep";

export interface IStepsProviderProps {
  title: string
  form: IFormField[]
  id: string
  step: number,
  stepCount: number,
  showSaveModal: (isDeclaration?: boolean) => void
  changeStep: (step: number) => void
  kiasTitle: string
  stepItems: any[]
  addRisksSteps: string[]
  isAgreementEdit: boolean
  editType: string | undefined
}

// steps
// 0 precalculate
// 1 generalForm
// 2 busynessForm
// 3 agreementForm
// 4 insuranceForm
// 5 propertyInsuranceForm
// 6 propertyLossInsuranceForm
// 7 additionalInsuranceForm

export const StepSwitcher = (props: IStepsProviderProps) => {
  const {id} = props;
  switch (id) {
    case "generalForm":
      return <GeneralStep {...props}/>
    case "busynessForm":
      return <BusynessStep {...props}/>
    case "agreementForm":
      return <AgreementStep {...props}/>
    case "insuranceForm":
      return <InsuranceStep {...props}/>
    case "propertyInsuranceForm":
      return <PropertyInsuranceStep {...props}/>
    case "propertyLossInsuranceForm":
      return <PropertyLossInsuranceStep {...props}/>
    case "documentsForm":
      return <DocumentsStep {...props}/>
    default:
      return null;
  }
};

export const StepsProvider = (props: IStepsProviderProps) => {
  return (
    <StyledStepWrapper>
      <HeaderStep/>
      <StepSwitcher {...props}/>
    </StyledStepWrapper>
  )
};

export default StepsProvider;