import React                                 from "react";
import Modal, {ModalProps}                   from "../../../../uiKit/modal";
import Form                                  from '@atlaskit/form';
import Button                                from "../../../../uiKit/button/button";
import {ISelectItem}                         from "../../../../uiKit/select";
import {StyledButtonsGroup}                  from "./styles";
import {IFormField}                          from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                         from "../../../../uiKit/fieldsBuilder";
import {getFormError}                        from "../../../../formUtils/getFormError";
import {getFormValue, getSelectDefaultValue} from "../../../../formUtils/getFormValue";
import {useMutation, useQuery}               from "@apollo/react-hooks";
import {CREATE_UPDATE_GROUP}                 from "../../../../utils/mutations";
import {GROUPS, USERS}                   from "../../../../utils/queries";
import {useCallNotification}                 from "../../../../hooks/useCallNotification";
import {TimeReloadIcon}                      from "../../../../icons/ui/TimeReload";
import ChangeLogModal                        from "./сhangeLogModal";

export const CreateGroupModal = ({header, isOpen, onClose, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const [stateIsOpenHistory, setIsOpenHistory] = React.useState<boolean>(false);
  const [createGroup, {loading: loadingCreateGroup}] = useMutation(CREATE_UPDATE_GROUP, {refetchQueries: ["groups", "users", "changeLog"]});
  const {data: dataGroups, loading: loadingGroups, error: errorGroups} = useQuery(GROUPS);
  const {data: dataUsers, loading: loadingUsers, error: errorUsers} = useQuery(USERS);
  const users: ISelectItem[] = ((dataUsers || {}).users || []).map(({login, id}) => ({label: login, value: id}));
  const {id, name, leaderId, curatorId, parentId, email, isEdit} = data || {};
  const groups: ISelectItem[] = ((dataGroups || {}).groups || []).map(({name, id}) => ({label: name, value: id}));

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {name, leader, parent, curator, email} = getFormValue(data);
    createGroup({
      variables: {
        id: id ? id : null,
        name,
        leader,
        curator,
        parent,
        email
      }
    }).then(() => {
      setNotification({type: "success", text: `Группа успешно ${isEdit ? "обновлена" : "создана"}`})
      onClose()
    })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const form: IFormField[] = [
    {
      columns: 2,
      columnGap: 1,
      fieldItems: [
        {
          type: "input",
          name: "name",
          isRequired: true,
          placeholder: "Название группы",
          errorMessage: "Введите название",
          defaultValue: name
        },
        {
          type: "input",
          name: "email",
          validationType: "email",
          placeholder: "Email группы",
          defaultValue: email
        }
      ]
    },
    {
      fieldItems: {
        type: "select",
        name: "leader",
        placeholder: "Лидер группы",
        isLoading: loadingUsers,
        items: users,
        defaultValue: getSelectDefaultValue(leaderId, users),
        isSearchAvailable: true,
        isClearable: true
      }
    },
    {
      fieldItems: {
        type: "select",
        name: "curator",
        placeholder: "Куратор группы",
        isLoading: loadingUsers,
        items: users,
        defaultValue: getSelectDefaultValue(curatorId, users),
        isSearchAvailable: true,
        isClearable: true
      }
    },
    {
      fieldItems: {
        type: "select",
        name: "parent",
        isLoading: loadingGroups,
        items: groups,
        isSearchAvailable: true,
        isClearable: true,
        placeholder: "Родительская группа",
        defaultValue: getSelectDefaultValue(parentId, groups),
        height: 15
      }
    },
  ]

  if (errorUsers) setNotification({type: "error", text: errorUsers})
  if (errorGroups) setNotification({type: "error", text: errorGroups})
  return (
    <Modal
      width={60}
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}
      headerButton={isEdit ? <Button tooltip="История изменений" onClick={() => setIsOpenHistory(true)} appearance="icon"
                            icon={<TimeReloadIcon/>}/> : null}
    >
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button type="submit" isLoading={loadingCreateGroup} appearance="filled">
                {isEdit ? "Сохранить" : "Создать группу"}
              </Button>
              <Button onClick={() => onClose()} isLoading={loadingCreateGroup} appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
      <ChangeLogModal
        data={{id, model: 'GROUP'}}
        onClose={() => setIsOpenHistory(false)}
        isOpen={stateIsOpenHistory}
        header={`Изменения группы: ${name}`}/>
    </Modal>
  )
};

export default CreateGroupModal;
