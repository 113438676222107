import React                                                              from "react";
import {StyledPasswordRecoveryForm}                                       from "./styles";
import {Heading1}                                    from "../../../../globalStyledComponents";
import Form                                          from '@atlaskit/form';
import Button                                        from "../../../../uiKit/button/button";
import Tabs, {ITabsItem}                             from "../../../../uiKit/tabs";
import {IFormField}                                  from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                 from "../../../../uiKit/fieldsBuilder";
import {getFormError}                from "../../../../formUtils/getFormError";
import {getClearPhone, getFormValue} from "../../../../formUtils/getFormValue";
import {useHistory}                  from "react-router";
import {useMutation}                                 from "@apollo/react-hooks";
import {RECOVERY_PASSWORD, RECOVERY_PASSWORD_CLIENT} from "../../../../utils/mutations";
import {useCallNotification}                         from "../../../../hooks/useCallNotification";

const formPhone: IFormField[] = [
  {
    fieldItems: {
      type: "input",
      name: "phone",
      mask: "+7 (999) 999-99-99",
      errorMessage: "Введите телефон",
      isRequired: true,
      placeholder: "Введите телефон",
      validationType: "phone"
    }
  }
]

const formEmail: IFormField[] = [
  {
    fieldItems: {
      type: "input",
      name: "email",
      isRequired: true,
      placeholder: "Введите e-mail",
      errorMessage: "Введите e-mail",
      validationType: "email"
    }
  }
]

export const RecoveryPassword = ({setRecoveryPassword}: { setRecoveryPassword: any }) => {
  const {location} = useHistory();
  const {setNotification} = useCallNotification();
  const isClient: boolean = location.pathname === "/authorization/client";

  const tabs = isClient ? [{label: "Телефон", value: "phone"}/*, {label: "E-mail", value: "email"}*/] : [{
    label: "E-mail",
    value: "email"
  }];
  const [stateRecoveryType, setRecoveryType] = React.useState<ITabsItem>(tabs[0]);
  const [recovery, {loading: loadingRecovery}] = useMutation(RECOVERY_PASSWORD);
  const [recoveryClient, {loading: loadingRecoveryClient}] = useMutation(RECOVERY_PASSWORD_CLIENT);

  const formSubmit = (data) => {
    const error = getFormError(data)
    if (error) return error;
    const {phone, email} = getFormValue(data);
    switch (stateRecoveryType.value) {
      case "phone":
        return recoveryClient({
          variables: {
            phone: getClearPhone(phone)
          }
        })
          .then(() => {
            setTimeout(() => {
              setRecoveryPassword(false)
            }, 3000);
            setNotification({type: "success", text: `Пароль был выслан на номер ${phone}`})
          })
          .catch(error => setNotification({type: "error", text: error}))
      case "email":
        if (isClient) return recoveryClient({
          variables: {
            email
          }
        })
          .then(() => {
            setNotification({type: "success", text: `Пароль был выслан на E-mail ${email}`})
            setTimeout(() => {
              setRecoveryPassword(false)
            }, 3000);
          })
          .catch(error => setNotification({type: "error", text: error}))
        return recovery({
          variables: {
            login: email
          }
        })
          .then(() => {
            setNotification({type: "success", text: `Пароль был выслан на E-mail ${email}`})
            setTimeout(() => {
              setRecoveryPassword(false)
            }, 3000);
          })
          .catch(error => setNotification({type: "error", text: error}))
    }
  };

  return (
    <StyledPasswordRecoveryForm>
      <Heading1>Восстановление пароля</Heading1>
      <Tabs tabMargin={2.5} tabWidth={7} activeTab={stateRecoveryType}
            onChange={(tab) => setRecoveryType(tab)} tabs={tabs}/>
      <Form onSubmit={data => formSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            {stateRecoveryType.value === "phone" ?
              <FieldsBuilder formFields={formPhone}/>
              :
              <FieldsBuilder formFields={formEmail}/>
            }
            <Button type="submit" width="100%" appearance="filled" isLoading={loadingRecovery || loadingRecoveryClient}>
              Получить пароль
            </Button>
          </form>
        )}
      </Form>
      <Button onClick={() => setRecoveryPassword(false)} appearance="link">Уже помню! Войти</Button>
    </StyledPasswordRecoveryForm>
  )
};

export default RecoveryPassword;