import styled                         from "styled-components";
import {halfDarkGrey, lightBlue, red} from "../../globalStyledComponents";

export const StyledUploadFileArea = styled.div<{isInvalid: boolean}>` 
  cursor: pointer;
  position: relative;
  height: 12rem;
  width: 100%;
  margin: 3rem 0 2rem 0;
  
  ${({isInvalid}) => 
    isInvalid ?
      `
        border: 1px dashed ${red};
      `
      :
      `
     border: 1px dashed ${halfDarkGrey};
  
  :hover {
    border-color: ${lightBlue};
  }   
      `
  }
  
  > p {
    color: black;
    opacity: 0.5;
    position: absolute;
    top: 2rem;
    left: 1.5rem;
    pointer-events: none;
    z-index: 3;
  }
  input {
    outline: none;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
`;

export const StyledFileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;
export const StyledFileEventsButton = styled.div`
  > button:first-child {
    margin-right: 1.5rem;
  }
`;

export const StyledFileError = styled.div`
    position: absolute;
    top: 2rem;
    left: 1.5rem;
    pointer-events: none;
    z-index: 3;
    > p {
    color: ${red}
    }
`;