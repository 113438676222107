import React                          from "react";
import {StyledStatisticsHeader}       from "./styles";
import {Heading2}                     from "../../../../globalStyledComponents";
import Button                         from "../../../../uiKit/button/button";
import {useHistory}                   from "react-router";
import FlyButtons                     from "../../../../components/flyButtons";
import {useQuery}                     from "@apollo/react-hooks";
import {ME}                           from "../../../../utils/queries";
import {rolesRightHandler}            from "../../../../helpers/rolesRightHandler";
import {ErrorMessage}                 from "../../../../components/errorMessage";
import SkipPrecalculationModal        from "../../../createRequest/components/skipPrecalculationModal";
import DropDown                       from "../../../../uiKit/dropDownArea";
import {useWindowWidth}               from "@react-hook/window-size";


export const StatisticsHeader = () => {
  const windowWidth = useWindowWidth({wait: 250});
  const history = useHistory();

  const [stateDropDownIsOpen, setDropDownIsOpen] = React.useState<boolean>(false);
  const [stateSkipPrecalculationModal, setSkipPrecalculationModal] = React.useState<boolean>(false);


  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role, banksOk} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);

  const headerButtons: { label: string, event: () => void, isDisabled?: boolean }[] = [
    {
      label: "С предварительным расчетом",
      event: () => history.push("/createRequest")
    },
    {
      label: "Без предварительного расчета",
      event: () => {
        setDropDownIsOpen(false)
        setSkipPrecalculationModal(true)
      }
    },
  ];

  if (errorUser) return <ErrorMessage error={errorUser}/>
  return (
    <StyledStatisticsHeader>
      <Heading2>Главная</Heading2>
      <FlyButtons>
        <DropDown
          trigger={
            <Button
              isDisabled={!rolesRightHandler("precalculationPage", functions, type, isAdmin) || !banksOk}
              appearance="filled"
              onClick={() => setDropDownIsOpen(true)}
            >
              Создать заявку
            </Button>
          }
          isDropDownList={true}
          position={windowWidth < 768 ? "top" : undefined}
          bodyWidth={windowWidth < 768 ? "100%" : undefined}
          items={headerButtons.map(({label, event}) => ({label, event}))}
          isOpen={stateDropDownIsOpen}
          onClose={() => setDropDownIsOpen(false)}/>
      </FlyButtons>
      <SkipPrecalculationModal header="Переход к заявке" onClose={() => setSkipPrecalculationModal(false)}
                               isOpen={stateSkipPrecalculationModal}/>
    </StyledStatisticsHeader>
  )
};

export default StatisticsHeader;