import styled from "styled-components";

export const StyledAcceptChangesButton = styled.div`
  @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
  }
`;

export const StyledPayPeriodModalButtons = styled.div`
  margin-top: 2rem;
`;