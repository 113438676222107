import styled  from "styled-components";

export const StyledFilter = styled.div`
  padding: 3rem;
  h3 {
    margin-bottom: 2rem;
  }
  
  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;
export const StyledFilterButtons = styled.div`
  display: flex;
  align-items: center;
  .filledButton {
    margin-right: 3rem;
  }
  
    @media screen and (max-width: 767px) {
    flex-direction: column;
    
      .filledButton {
      width: 100%;
    margin: 0 0 2rem 0;
  }
  }
`;