import React                                              from "react";
import {getFormError}                                     from "../../../../formUtils/getFormError";
import Form                                               from '@atlaskit/form';
import InsuranceLossSubjects, {insuranceLossSubjectNames} from "./insuranceSubject";
import StoreAgreementForm                                 from "../../store";
import {IStepsProviderProps}                              from "../stepsProvider";
import StepsLayout                                        from "../stepsLayout";
import {getFormValueWithArray}                            from "../../../../formUtils/getFormValueWithArray";
import StepsPaginationButton                              from "../stepsPaginationButton";
import {getFormValue}                                     from "../../../../formUtils/getFormValue";
import {useCallNotification}                              from "../../../../hooks/useCallNotification";

export const PropertyLossInsuranceStep = ({title, showSaveModal,  id, step, changeStep, isAgreementEdit, editType, addRisksSteps} : IStepsProviderProps) => {
  const {setNotification} = useCallNotification();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const isSaveImmediately: boolean = isAgreementEdit && editType === "addRisks" && !addRisksSteps.includes("riskProperty") && !addRisksSteps.includes("riskLife");

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValueWithArray(getFormValue(data), insuranceLossSubjectNames, "lossInsuranceSubjects");
    const {lossInsuranceSubjects} = formData;
    const totalShare: number = lossInsuranceSubjects.reduce((prev, {titleShare}) => {
      return prev + Number(titleShare);
    }, 0)
    if (totalShare === 0) return setNotification({type: "error", text: "Выберите доли"})
    if (totalShare > 100) return setNotification({type: "error", text: "Сумма долей превышает 100"})
    updateAgreementForm({
      lossInsuranceSubjects
    })
    // if (!isAgreementEdit && step === stepCount - 1) return showSaveModal(true);
    // if (step === stepCount - 1) return showSaveModal();
    if (isSaveImmediately) return showSaveModal();
    changeStep(step + 1)
  }

  return (
        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps} id={id}>
              <StepsLayout title={title}>
                <InsuranceLossSubjects/>
              </StepsLayout>
              <StepsPaginationButton disableBackButton={isAgreementEdit && !addRisksSteps.includes("riskProperty")} saveImmediately={isSaveImmediately} formId={id}/>
            </form>
          )}
        </Form>
  )
}

export default PropertyLossInsuranceStep;