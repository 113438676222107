import React                           from "react";
import Button                          from "../../../../uiKit/button/button";
import {StyledPaginationButtonWrapper} from "../busynessStep/styles";
import StoreAgreementStep              from "../../store";

interface IPaginationButtonProps {
  formId?: string
  onNextStep?: () => void
  isDisabled?: boolean
  isLoading?: boolean
  tooltip?: string
  disableBackButton?: boolean
  saveImmediately?: boolean
}

export const StepsPaginationButton = ({formId, isDisabled, isLoading, disableBackButton, saveImmediately, onNextStep, tooltip}: IPaginationButtonProps) => {
  const [{agreementStep: {agreementStepState: {step, stepsCount}}}, {agreementStep: actionsAgreementStep}]: any = StoreAgreementStep();
  const changeStep = (step: number) => {
    if (step > stepsCount || step < 0) return;
    actionsAgreementStep.setState({
      agreementStepState: {
        step,
        stepsCount
      }
    });
  }

  const preStep = <Button width="100%" isDisabled={disableBackButton} appearance="transparent" isLoading={isLoading} onClick={(ev) => {
    ev.preventDefault();
    return changeStep(step - 1)
  }}>Предыдущий шаг</Button>;
  const nextStep = <Button width="100%" onClick={onNextStep ? onNextStep : undefined} tooltip={tooltip} isDisabled={isDisabled} isLoading={isLoading} appearance="filled" form={formId} type="submit">Следующий шаг</Button>;
  const finalStep = <Button width="100%" onClick={onNextStep ? onNextStep : undefined} appearance="filled" isDisabled={isDisabled} isLoading={isLoading} form={formId} type="submit">Создать</Button>;
  const saveImmediatelyButton = <Button width="100%" onClick={onNextStep ? onNextStep : undefined} appearance="filled" isLoading={isLoading} form={formId} type="submit">Сохранить изменения</Button>;

  if (saveImmediately) return saveImmediatelyButton;
  return (
    <>
      {step === 1 && nextStep}
      {step !== 1 && step !== stepsCount - 1 &&
      <StyledPaginationButtonWrapper>
        {preStep}
        {nextStep}
      </StyledPaginationButtonWrapper>}
      {step === stepsCount - 1 &&
      <StyledPaginationButtonWrapper>
        {preStep}
        {finalStep}
      </StyledPaginationButtonWrapper>
      }
    </>
  )
};

export default StepsPaginationButton;