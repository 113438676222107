import styled         from "styled-components";
import {grey, grey20} from "../../../../globalStyledComponents";

export const StyledToggleAreaWrapper = styled.div`
  border-top: 1px solid ${grey};
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledFormTextField = styled.div`
  > p:first-child {
    opacity: 0.5;
    margin-bottom: 0.5rem;
  }
  padding-bottom: 1rem;
  border-bottom: 1px solid ${grey20};
  margin-bottom: 1.5rem;
`;