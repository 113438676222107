import React                                                   from 'react';
import {Heading3, Text2}                                       from "../../../../globalStyledComponents";
import {StyledSearch}                     from "../groupsTab/styles";
import NivaInput                          from "../../../../uiKit/nivaInput/NivaInput";
import {SearchIcon}                       from "../../../../icons/ui/Search";
import {StyledRoleHeader, StyledRoleItem} from "./styles";
import Button                             from "../../../../uiKit/button/button";
import {TrashIcon}                        from "../../../../icons/ui/Trash";
import DeleteRoleModal                    from "./deleteRoleModal";
import {useHistory}                       from "react-router";
import {useQuery}                         from "@apollo/react-hooks";
import {ROLES}                            from "../../../../utils/queries";
import {Loader}                           from "../../../../uiKit/loader";
import {ErrorMessage}                     from "../../../../components/errorMessage";


export const RolesTab = () => {
  const history = useHistory();
  const [stateSearch, setSearch] = React.useState<string>("");
  const [stateDeleteRoleId, setDeleteRoleId] = React.useState<string | undefined>(undefined)
  const {data: dataRoles, loading: loadingRoles, error: errorRoles} = useQuery(ROLES);
  const roles: { id: string, name: string, readOnly: boolean }[] = ((dataRoles || {}).roles || []).map(({id, name, readOnly}) => ({
    id,
    name,
    readOnly
  }));
  const filterRoles = roles.filter(({name}) => name.toLowerCase().includes(stateSearch.toLowerCase()));

  const roleClickHandler = (id: string) => history.push(`/roles/${id}`);
  if (errorRoles) return <ErrorMessage error={errorRoles}/>
  if (loadingRoles) return <Loader type="simple"/>
  return (
    <>
      <StyledSearch>
        <NivaInput placeholder="Поиск по названию роли" debounce={true} onChange={(value) => setSearch(value)}
                   iconAfter={<SearchIcon/>}/>
      </StyledSearch>
      {filterRoles.map(({id, name, readOnly}, index) => (
        <StyledRoleItem key={`role-${index}`}>
          <StyledRoleHeader onClick={() => roleClickHandler(id)}>
            {readOnly ? <Heading3>{name}</Heading3> : <Text2>{name}</Text2>}
          </StyledRoleHeader>
          {!readOnly && <Button tooltip="Удалить роль" onClick={() => setDeleteRoleId(id)} appearance="icon" icon={<TrashIcon/>}/>}
        </StyledRoleItem>
      ))}
      <DeleteRoleModal onClose={() => setDeleteRoleId(undefined)} isOpen={!!stateDeleteRoleId}
                       data={{id: stateDeleteRoleId}}/>
    </>
  )
};

export default RolesTab;