import styled                  from "styled-components";
import {black20, blue} from "../../globalStyledComponents";

export const StyledTabsWrapper = styled.div<{activeTab: number | undefined, tabWidth: number, tabMargin: number}>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${black20};
  position: relative;
  
  ::before {
    content: "";
    transition: left 0.3s;
    position: absolute;
    margin-bottom: -1px;
    ${({tabWidth}) => `
      width: ${tabWidth}rem;
    `}
    height: 2px;
    bottom: 0;
    background: ${blue};
    ${({activeTab, tabWidth, tabMargin}) => 
      activeTab !== undefined ?
        `
        left: ${activeTab === 0 ? 0 : activeTab * tabWidth + (tabMargin * activeTab)}rem; 
        `
        :
        `
        display: none;
        `
    }
  }
`;
export const StyledTab = styled.div<{isActive: boolean, tabWidth: number, tabMargin: number}>`
  ${({tabWidth, tabMargin}) => `
      min-width: ${tabWidth}rem;
      max-width: ${tabWidth}rem;
      margin-right: ${tabMargin}rem;
  `}
  padding-bottom: 1.7rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  ${({isActive}) =>
    isActive ?
      "opacity: 1;"
      :
      "opacity: 0.5;"
  }
  :hover {
    opacity: 1;
  }
`;