import React                                                 from "react";
import Modal, {ModalProps}                                   from "../../../../uiKit/modal";
import Button                                                from "../../../../uiKit/button/button";
import {StyledButtonsGroup}                                  from "../../../roles/components/header/styles";
import {TextButton}                                          from "../../../../globalStyledComponents";
import moment                                                from "moment";
import StoreAgreementForm                    from "../../store";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {BANKS_RAISING, ME} from "../../../../utils/queries";
import {
  AGREEMENT_MAKE,
  CALC_AGREEMENT, NOTIFICATION,
  SET_STATUS_RISK,
  UPDATE_AGREEMENT_CALC_FULL
} from "../../../../utils/mutations";
import {
  agreementAdditionalFilling,
  agreementAttributesBuilder,
  createRiskStatusObject, getBankRaising, getPerson, getRestrictionsObject, getVariablesForDoc
} from "../../../../helpers/buildTemplateForBack";
import {useHistory}                                          from "react-router";
import {StyledSaveAgreementText, StyledSaveAgreementWrapper} from "./styles";
import {ErrorMessage}                                        from "../../../../components/errorMessage";
import {useCallNotification}                                 from "../../../../hooks/useCallNotification";
import {getAllowedPathname}                                  from "../../../../helpers/rolesRightHandler";
import {useSendAnketa}                                       from "../../../../hooks/useSendAnketa";
import {useSaveAddress}                                      from "../../../../hooks/useSaveAddress";
import {useClearGlobalStore}                                 from "../../../../hooks/useClearGlobalStore";
import {stringToNumber} from "../../../../formUtils/getFormValue";

export const SaveAgreementModal = ({header, isOpen, onClose}: ModalProps) => {
  const history = useHistory();
  const {setNotification} = useCallNotification();
  const {sendAnketa, saveAdditionalFiles, loading: loadingSendAnketa} = useSendAnketa();
  const {saveAddress, loading: loadingSaveAddress} = useSaveAddress();
  const {clearGlobalStore} = useClearGlobalStore();
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {data: dataBanksRising, error: errorBanksRising} = useQuery(BANKS_RAISING);
  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL);
  const [notification, {loading: loadingNotification}] = useMutation(NOTIFICATION);
  const [calcAgreement, {loading: loadingCalcAgreement}] = useMutation(CALC_AGREEMENT, {fetchPolicy: "no-cache"});
  const [setStatusRisk, {loading: loadingStatusRisk}] = useMutation(SET_STATUS_RISK);
  const {isAdmin, type, role, restrictions} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const path: string | undefined = getAllowedPathname(functions, type, isAdmin);
  const {templateVariables, agentIsn, creditOrganizationName, creditRate, riskLife, riskProperty} = agreementFormState || {};

  const isSberbankExpressMode = (creditOrganizationName?.value == "950291") && riskLife && !riskProperty;
  const [agreementMake, {loading: loadingAgreementMake}] = useMutation(AGREEMENT_MAKE, {refetchQueries: ["docs"]});

  const date = moment().format("DD.MM.YYYY");

  const bankRising = getBankRaising((dataBanksRising?.banks||[]).find(bank => bank?.isn === creditOrganizationName?.value)?.raising, stringToNumber(creditRate));

  const createContractSberbankExpress = (isn) => {
    agreementMake({
      variables: {
        isn: isn,
        sberbankExpress: true
      }
    })
      .then(({data: {agreementMake}}) => {
        if (!agreementMake) return setNotification({type: "error", text: "Отсутствует isn договора"});
        history.push(`/contract/${agreementMake}`)
      })
      .catch(error => setNotification({type: "error", text: error}))
  }

  const saveHandler = () => {
      const contragent = getPerson(agreementFormState, "contragent");
      const {isn: isnContragent, isnAnketa} = contragent;

      const isDeclaration : boolean = ( contragent.fillingType == "declaration" );
      const isCustomFileUpload: boolean = ( contragent.fillingType == "upload" );

    agreementCalc({
      variables: {
        dateBeg: date,
        dateSign: date,
        dateEnd: moment().add(1, "year").format("DD.MM.YYYY"),
        clientIsn: isnContragent,
        agentIsn,
        attribs: agreementAttributesBuilder({...agreementFormState, isnAnketa, isDeclaration, isCustomFileUpload})
      }
    }).then(({data: {agreementCalcFull}}) => {
      const agreementCalcVariables = agreementAdditionalFilling(agreementFormState, agreementCalcFull)
      agreementCalc({
        variables: agreementCalcVariables
      }).then(({data: {agreementCalcFull}}) => {
          const {isn: isnAgreement} = agreementCalcFull || {};
        saveAddress(agreementCalcVariables, agreementFormState)
            .then(() => {
              calcAgreement({
                variables: {
                  isn: isnAgreement,
                  data: {
                    templateVariables: {isn: isnAnketa, ...templateVariables},
                    ...getVariablesForDoc(agreementCalcVariables)
                  }
                }
              }).then(({data: {calcAgreement}}) => {
                sendAnketa(agreementFormState, isnAgreement);
                saveAdditionalFiles(agreementFormState, isnAgreement);
                const {isn, riskObjects, filledAgreement} = createRiskStatusObject(calcAgreement, agreementFormState, [], getRestrictionsObject(restrictions), bankRising);
                setStatusRisk({variables: {isn, riskObjects}})
                  .then(() => {
                    agreementCalc({
                      variables: {...filledAgreement}
                    })
                      .then(() => {
                        notification({
                          variables: {
                            isn,
                            type: "new_document",
                            city: agreementFormState?.dealCountry
                          }
                        })
                          .then(() => {
                            onClose();
                            clearGlobalStore();
                            if(isDeclaration && isSberbankExpressMode) return createContractSberbankExpress(isnAgreement);
                            setNotification({type: "success", text: "Заявка успешно сохранена"})
                            return history.push(path || "/page404/rights")
                          })
                          .catch((error) => {
                            onClose();
                            setNotification({type: "error", text: error})
                          })
                      })
                      .catch((error) => setNotification({type: "error", text: error}))
                  })
                  .catch((error) => setNotification({type: "error", text: error}))
              })
                .catch(error => setNotification({type: "error", text: error}))
            })
            .catch(error => setNotification({type: "error", text: error}))
        })
        .catch(error => setNotification({type: "error", text: error}))
    })
      .catch(error => setNotification({type: "error", text: error}))
  };

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorBanksRising) return <ErrorMessage error={errorBanksRising}/>
  const isLoading = loadingSaveAddress || loadingNotification || loadingSendAnketa || loadingStatusRisk || loadingAgreementCalc || loadingCalcAgreement;
  return (
    <StyledSaveAgreementWrapper>
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        header={header}>
        <StyledSaveAgreementText>
          <TextButton>Сохранение расширенной заявки может занять продолжительное время. Пожалуйста, подождите завершения
            процесса сохранения заявки.</TextButton>
        </StyledSaveAgreementText>
        <StyledButtonsGroup>
          <Button
            isLoading={isLoading}
            onClick={saveHandler} appearance="filled">
            Создать
          </Button>
          <Button onClick={() => onClose()} isLoading={isLoading} appearance="transparent">
            Отмена
          </Button>
        </StyledButtonsGroup>
      </Modal>
    </StyledSaveAgreementWrapper>
  )
};

export default SaveAgreementModal;