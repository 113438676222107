import React                       from 'react';
import Modal, {ModalProps}         from "../../../../../uiKit/modal";
import {DELETE_USER} from "../../../../../utils/mutations";
import {useMutation}               from "@apollo/react-hooks";
import {Heading3}                  from "../../../../../globalStyledComponents";
import Button                      from "../../../../../uiKit/button/button";
import DeleteRoleModal             from "../../rolesTab/deleteRoleModal";
import {StyledDeleteRoleModalBody} from "../../rolesTab/styles";
import {StyledButtonsGroup}  from "../../header/styles";
import {useCallNotification} from "../../../../../hooks/useCallNotification";


export const DeleteUserModal = ({onClose, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id} = data || {};
  const [deleteUser, {loading: loadingDeleteUser}] = useMutation(DELETE_USER, {refetchQueries: ["users"]});
  const deleteGroupHandler = () => {
    deleteUser({
      variables: {
        id
      }
    }).then(() => onClose())
      .catch(error => setNotification({type: "error", text: error}))
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen} header={undefined}>
      <StyledDeleteRoleModalBody>
        <Heading3>Вы действительно хотите удалить пользователя?</Heading3>
        <StyledButtonsGroup>
          <Button appearance="filled" onClick={deleteGroupHandler} isLoading={loadingDeleteUser}>
            Да
          </Button>
          <Button onClick={() => onClose()} appearance="transparent" isLoading={loadingDeleteUser}>
            Нет
          </Button>
        </StyledButtonsGroup>
      </StyledDeleteRoleModalBody>
    </Modal>
  )
};

export default DeleteRoleModal;