import React                                                 from 'react';
import {Heading2, Heading3, TextButton}                      from "../../globalStyledComponents";
import {StyledUnderwritingWrapper}                           from "../applicationById/styles";
import ContractRegistrationHeader                            from "./components/header";
import {useParams}                                           from "react-router";
import {useGetDict}                                          from "../../hooks/useGetDicti";
import {useLazyQuery, useMutation, useQuery}                 from "@apollo/react-hooks";
import {CONTRAGENT, DOCS, GET_CONTRACT, ME} from "../../utils/queries";
import {
  AGREEMENT_MAKE_SBERBANK,
  CALC_AGREEMENT,
  CHANGE_CONTRACT_STATUS,
  SAVE_ADDRESS,
  UPDATE_CONTRACT,
  UPDATE_CONTRAGENT
}                                                            from "../../utils/mutations";
import {
  CLASS_ISN_BORROWERS,
  CLASS_ISN_OBJECTS,
  CLASS_ISN_TITLES, contractFilling, getKiasDate, getRestrictionsObject,
  getValueByAttributeId, SBERBANK_ISN
} from "../../helpers/buildTemplateForBack";
import {ISelectItem}                                         from "../../uiKit/select";
import {IFormField}                                          from "../../uiKit/fieldsBuilder/interfaces";
import {getFormError}                                        from "../../formUtils/getFormError";
import {getFormValue, getSelectDefaultValue, getSelectValue} from "../../formUtils/getFormValue";
import {getFormValueWithArray}                               from "../../formUtils/getFormValueWithArray";
import {ErrorMessage}                                        from "../../components/errorMessage";
import {Loader}                                              from "../../uiKit/loader";
import {
  StyledContractRegistrationForm, StyledRisksInformation
}                                                            from "./styles";
import FieldsBuilder                                         from "../../uiKit/fieldsBuilder";
import Form                                                  from '@atlaskit/form';
import {StyledInformationItem} from "../applicationById/components/header/styles";
import {useWindowWidth}        from "@react-hook/window-size";
import {money}                 from "../../utils/formats";
import {useCallNotification}   from "../../hooks/useCallNotification";
import {rolesRightHandler}     from "../../helpers/rolesRightHandler";
import moment                  from "moment";
import {BorrowerObject}        from "./components/borrowerObject";
import {PropertyObject}        from "./components/propertyObject";
import {TitleObject}           from "./components/titleObject";
import {SubjectsTable}         from "./components/subjectsTable";
import {formatDateToUsa}       from "../../formUtils/fieldsValidation";
import {DocumentsToggle}       from "../applicationById/components/toggleArea/components/rightToggles";
import DeclarationModal from "../createRequest/components/declarationModal";

export interface IObjectProps {
  index: number
  object: any
  onDelete: () => void
  isDisabledDelete?: boolean
  isDisabled: boolean
  changeIsPreCalcNeeded: () => void
  maxDiscount: number | null;
}

export const ContractById = () => {
  const {id} = useParams();
  const windowWidth = useWindowWidth({wait: 250});
  const {setNotification} = useCallNotification();
  const {data: selectCurrency, loading: loadingCurrency} = useGetDict("currency");
  const {data: selectSalesChannel, loading: loadingSalesChannel} = useGetDict("saleChannel");
  const {data: selectAgreementTypes, loading: loadingAgreementTypes} = useGetDict("agreementTypes");
  const {data: selectCountries, loading: loadingCountries} = useGetDict("nationality");
  const {data: selectBanks, loading: loadingBanks} = useGetDict("banks");
  const {data: selectInsuranceCompany, loading: loadingInsuranceCompany} = useGetDict("insuranceCompany");
  const [stateIsPreCalcNeeded, setIsPreCalcNeeded] = React.useState<boolean>(false);
  const [stateSubmitType, setSubmitType] = React.useState<"preCalc" | undefined>(undefined);
  const [stateInsureType, setInsureType] = React.useState<ISelectItem | undefined>(undefined);
  const [stateDeletedObjects, setDeletedObjects] = React.useState<string[]>([]);
  // const [stateIsBeneficiary, setIsBeneficiary] = React.useState<boolean>(false);
  // const [stateIsHaveCoBorrower, setIsHaveCoBorrower] = React.useState<IRadioItem>(radioBoolean[1]);
  const {data: dataContract, loading: loadingContract, error: errorContract} = useQuery(GET_CONTRACT, {
    variables: {
      isn: id
    }
  });
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {isn: id},
    fetchPolicy: "network-only"
  });

  const {data: dataUser, error: errorUser} = useQuery(ME);
  // const [downloadSchedule, {
  //   data: dataDownloadSchedule,
  //   loading: loadingDownloadSchedule,
  //   error: errorDownloadSchedule
  // }] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [getContragent, {
    data: dataContragent,
    loading: loadingContragent,
    error: errorContragent
  }] = useLazyQuery(CONTRAGENT);
  // const urlFileSchedule = ((dataDownloadSchedule || {}).documentForm || {}).url;

  const [calcAgreement, {loading: loadingCalcAgreement}] = useMutation(CALC_AGREEMENT, {refetchQueries: ["agreement", "getKvAgent"]});
  const [updateContragent, {loading: loadingUpdateContragent}] = useMutation(UPDATE_CONTRAGENT);
  const [agreementMakeSberbank, {loading: loadingAgreementMakeSberbank}] = useMutation(AGREEMENT_MAKE_SBERBANK, {refetchQueries: ["agreement", "docs"]});
  const [saveAddress, {loading: loadingSaveAddress}] = useMutation(SAVE_ADDRESS);
  const [updateContract, {loading: loadingUpdateContract}] = useMutation(UPDATE_CONTRACT);
  const [changeContractStatus, {loading: loadingChangeContractStatus}] = useMutation(CHANGE_CONTRACT_STATUS, {refetchQueries: ["agreement", "docs"], awaitRefetchQueries: true});
  const [stateDeclarationModal, setDeclarationModal] = React.useState<boolean>(false);
  const {
    objects,
    attribs,
    dateSign,
    dateBeg,
    dateEnd,
    curIsn,
    id: number,
    clientIsn,
    prevIsn
  } = (dataContract || {}).agreement || {};
  const borrowers = (objects || []).filter(({
                                              ClassISN,
                                              ISN
                                            }) => ClassISN === CLASS_ISN_BORROWERS && !stateDeletedObjects.includes(ISN))
  const properties = (objects || []).filter(({
                                               ClassISN,
                                               ISN
                                             }) => ClassISN === CLASS_ISN_OBJECTS && !stateDeletedObjects.includes(ISN))
  const titles = (objects || []).filter(({
                                           ClassISN,
                                           ISN
                                         }) => ClassISN === CLASS_ISN_TITLES && !stateDeletedObjects.includes(ISN))
  const {
    fullName: insurerFullName,
    addr1: insurerAddress,
    birthDate: insurerBirthday,
    countryIsn: insurerCountry,
    phoneMobile: insurerPhone,
    email: insurerEmail,
    authDocSer: insurerSerial,
    authDocNumber: insurerNumber,
    authDocDate: insurerDocDate,
    authDocIssuer: insurerDocIssuer,
    ...otherContragent
  } = (dataContragent || {}).contragent || {};
  const {isAdmin, type, role, restrictions} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {statusCode, type: docType} = (docsList || [])[0] || {};
  const isDisabledSigned: boolean = statusCode === "signed";

  const {maxDiscount} = getRestrictionsObject(restrictions);

  const insureTypeSelect: ISelectItem | undefined = getSelectDefaultValue(getValueByAttributeId(attribs, "insureType"), selectAgreementTypes);

  React.useEffect(() => {
    if (!clientIsn) return;
    getContragent({
      variables: {
        isn: clientIsn
      }
    })
    // eslint-disable-next-line
  }, [clientIsn])

  // React.useEffect(() => {
  //   fileDownloader(urlFileSchedule)
  //   // eslint-disable-next-line
  // }, [urlFileSchedule])

  React.useEffect(() => {
    if (!insureTypeSelect || (stateInsureType || {}).value) return;
    if ((stateInsureType || {}).value === (insureTypeSelect || {}).value) return;
    setInsureType(insureTypeSelect || selectAgreementTypes[0])
    // eslint-disable-next-line
  }, [insureTypeSelect])

  const isDeleteDisabled: boolean = !rolesRightHandler("prolongation", functions, type, isAdmin);
  const bankIsn: string = getValueByAttributeId(attribs, "creditOrganizationName");
  const isSberbankDoubleAgreement: boolean = bankIsn === SBERBANK_ISN && borrowers.length !== 0 && properties.length !== 0;
  const currencySelect: ISelectItem | undefined = getSelectDefaultValue(curIsn, selectCurrency);
  const countrySelect: ISelectItem | undefined = getSelectDefaultValue(insurerCountry, selectCountries)
  const salesChannelSelect: ISelectItem | undefined = getSelectDefaultValue(getValueByAttributeId(attribs, "salesChannel"), selectSalesChannel);
  const bankSelect: ISelectItem | undefined = getSelectDefaultValue(bankIsn, selectBanks);
  const insuranceCompanySelect: ISelectItem | undefined = getSelectDefaultValue(getValueByAttributeId(attribs, "prevInsuranceCompany"), selectInsuranceCompany);
  const annuityPay: string = getValueByAttributeId(attribs, "annuityPay");

  const canChangeRisks = prevIsn ?
    rolesRightHandler('prolongationRiskChange', functions, type, isAdmin)
    : rolesRightHandler('agreementRiskChange', functions, type, isAdmin);
  const isDisabledBorrowerObject: boolean = docType === "P" ? false : !canChangeRisks;
  const isDisabledPropertyObject: boolean = docType === "P" ? false : !canChangeRisks;

  const showProlongDeclaration = () => {
    console.log('decl!');
    setDeclarationModal(true);
  }

  const defaultForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "id",
          isRequired: true,
          isDisabled: true,
          errorMessage: "Введите номер",
          placeholder: "Номер договора",
          defaultValue: number
        },
        {
          type: "date",
          name: "dateSign",
          isRequired: true,
          isDisabled: isDisabledSigned,
          onChange: (value) => {
            if (value === dateSign) return
            changeIsPreCalcNeeded()
          },
          errorMessage: "Выберите дату",
          placeholder: "Договор подписан",
          defaultValue: dateSign
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "fio",
          name: "borrower",
          isRequired: true,
          isDisabled: true,
          errorMessage: "Введите имя",
          validationType: "fullName",
          defaultValue: {value: insurerFullName},
          placeholder: "Страхователь"
        },
        {
          type: "date",
          name: "dateBeg",
          onChange: (value) => {
            if (value === dateBeg) return
            changeIsPreCalcNeeded()
          },
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "Выберите дату",
          placeholder: "Дата начала действия договора",
          validationType: "minMaxDate",
          minDate: moment().format("DD.MM.YYYY"),
          maxDate: moment().add(31, 'years').format("DD.MM.YYYY"),
          defaultValue: dateBeg
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "number",
          name: "creditTerms",
          isRequired: true,
          errorMessage: "Введите срок",
          isDisabled: true,
          placeholder: "Общий срок страхования",
          defaultValue: money(getValueByAttributeId(attribs, "creditTerms"), 0)
        },
        {
          type: "date",
          name: "dateEnd",
          isRequired: true,
          isDisabled: isDisabledSigned,
          onChange: (value) => {
            if (value === dateEnd) return
            changeIsPreCalcNeeded()
          },
          errorMessage: "Выберите дату",
          placeholder: "Дата конца действия договора",
          defaultValue: dateEnd
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "select",
          name: "curIsn",
          isRequired: true,
          isDisabled: true,
          errorMessage: "Введите валюту",
          items: selectCurrency,
          isLoading: loadingCurrency,
          defaultValue: currencySelect,
          placeholder: "Валюта условий"
        },
        {
          isHidden: bankIsn === SBERBANK_ISN || ["1", "3"].includes(String(stateInsureType?.value)),
          type: "number",
          name: "annuityPay",
          digitsAfterDot: 2,
          isDisabled: isDisabledSigned,
          onChange: (value) => {
            if (value === money(annuityPay, 2)) return;
            changeIsPreCalcNeeded()
          },
          placeholder: "Аннуитетный платеж",
          defaultValue: annuityPay
        },
        // {
        //   type: "date",
        //   name: "dateLiability",
        //   isRequired: false,
        //   errorMessage: "Выберите дату",
        //   placeholder: "Ответственность с",
        //   defaultValue: dateLiability
        // },
        // {
        //   type: "date",
        //   name: "dateLiabilityEnd",
        //   isRequired: false,
        //   errorMessage: "Выберите дату",
        //   placeholder: "Ответственность по",
        //   defaultValue: dateLiabilityEnd
        // },
      ]
    }
  ];

  const generalInformationForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "fio",
          name: "fullName",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "Введите ФИО",
          placeholder: "ФИО страхователя",
          onChange: (value) => {
            if (value?.value === insurerFullName) return
            changeIsPreCalcNeeded()
          },
          validationType: 'fullName',
          defaultValue: {value: insurerFullName}

        },
        {
          type: "address",
          name: "addr1",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "Введите адрес",
          placeholder: "Адрес регистрации",
          // onChange: (value) => {
          //   if (value === insurerAddress) return
          //   changeIsPreCalcNeeded()
          // },
          defaultValue: insurerAddress
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "date",
          name: "birthDate",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          placeholder: "Дата рождения",
          onChange: (value) => {
            if (value === insurerBirthday) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerBirthday
        },
        {
          type: "select",
          name: "countryIsn",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          placeholder: "Гражданство",
          onChange: (value) => {
            if (getSelectValue(value) === getSelectValue(countrySelect)) return
            changeIsPreCalcNeeded()
          },
          items: selectCountries,
          isLoading: loadingCountries,
          defaultValue: countrySelect,
          isSearchAvailable: true,
          isClearable: true
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "authDocSer",
          isRequired: true,
          isDisabled: isDisabledSigned,
          mask: "9999",
          validationType: "passportSerial",
          errorMessage: "",
          placeholder: "Серия паспорта",
          onChange: (value) => {
            if (value === insurerSerial) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerSerial
        },
        {
          type: "input",
          name: "email",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          placeholder: "Укажите e-mail",
          validationType: "email",
          onChange: (value) => {
            if (value === insurerEmail) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerEmail
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "authDocNumber",
          mask: "999999",
          validationType: "passportNumber",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          placeholder: "Номер паспорта",
          onChange: (value) => {
            if (value === insurerNumber) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerNumber
        },
        {
          type: "input",
          name: "phoneMobile",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          mask: "+7 (999) 999-99-99",
          placeholder: "Укажите телефон",
          validationType: "phone",
          onChange: (value) => {
            if (value === insurerPhone) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerPhone
        },
      ]
    },
    {
      columns: 2, columnGap: 4,
      fieldItems: [
        {
          type: "date",
          name: "authDocDate",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          placeholder: "Дата выдачи паспорта",
          onChange: (value) => {
            if (value === insurerDocDate) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerDocDate,
          validationType: "minMaxDate",
          maxDate: moment().format("DD.MM.YYYY")
        },
        {
          type: "input",
          name: "authDocIssuer",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "Заполните поле",
          placeholder: "Кем выдан паспорт",
          onChange: (value) => {
            if (value === insurerDocIssuer) return
            changeIsPreCalcNeeded()
          },
          defaultValue: insurerDocIssuer,
        }
      ]
    },
  ];

  const generalCreditInformationForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "select",
          name: "insureType",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          items: selectAgreementTypes,
          isLoading: loadingAgreementTypes,
          defaultValue: stateInsureType,
          onChange: (value) => {
            setInsureType(value)
            if (getSelectValue(value) === getSelectValue(stateInsureType)) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Тип договора"
        },
        {
          isHidden: (stateInsureType || {}).value !== "4",
          type: "select",
          name: "prevInsuranceCompany",
          isRequired: true,
          isDisabled: isDisabledSigned,
          placeholder: "Предыдущая СК",
          onChange: (value) => {
            if (getSelectValue(value) === getSelectValue(insuranceCompanySelect)) return
            changeIsPreCalcNeeded()
          },
          items: selectInsuranceCompany,
          isLoading: loadingInsuranceCompany,
          defaultValue: insuranceCompanySelect
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "select",
          name: "creditOrganizationName",
          isRequired: true,
          errorMessage: "",
          isDisabled: true,
          onChange: (value) => {
            if ((value || {}).value === (bankSelect || {}).value) return;
            changeIsPreCalcNeeded()
          },
          items: selectBanks,
          isLoading: loadingBanks,
          defaultValue: bankSelect,
          placeholder: "Наименование кредитной организации"
        },
        {
          type: "number",
          name: "summ",
          isRequired: true,
          isDisabled: isDisabledSigned,
          digitsAfterDot: 2,
          errorMessage: "",
          onChange: (value) => {
            if (value === money(getValueByAttributeId(attribs, "summ"))) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Размер кредита",
          defaultValue: getValueByAttributeId(attribs, "summ"),
          validationType: "minMax",
          maxValue: 1000000000
        },
      ]
    },

    // {
    //   columns: 2,
    //   columnGap: 4,
    //   fieldItems: {
    //     type: "checkbox",
    //     name: "isBeneficiary",
    //     label: "Является выгодоприоретателем",
    //     defaultValue: stateIsBeneficiary,
    //     onChange: (value) => setIsBeneficiary(value)
    //   }
    // },
    // {
    //   isHidden: stateIsBeneficiary,
    //   columns: 2,
    //   columnGap: 4,
    //   fieldItems: {
    //     type: "input",
    //     name: "beneficiary",
    //     validationType: "fullName",
    //     errorMessage: "",
    //     isRequired: true,
    //     placeholder: "Выгодоприобретатель"
    //   }
    // },

    {
      isHidden: bankIsn === SBERBANK_ISN,
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "number",
          name: "creditTerms",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          validationType: "minMax",
          maxValue: 600,
          onChange: (value) => {
            if (value === money(getValueByAttributeId(attribs, "creditTerms"), 0)) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Срок кредитования (месяцы) / оставшийся срок кредитования",
          defaultValue: money(getValueByAttributeId(attribs, "creditTerms"), 0)
        },
        {
          type: "number",
          name: "creditRate",
          digitsAfterDot: 2,
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          onChange: (value) => {
            if (value === money(getValueByAttributeId(attribs, "creditRate"))) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Годовая процентная ставка по кредиту, %",
          defaultValue: getValueByAttributeId(attribs, "creditRate"),
          validationType: "minMax",
          maxValue: 100
        },
      ]
    },
    {
      isHidden: bankIsn === SBERBANK_ISN,
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "date",
          name: "creditIssueDate",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          onChange: (value) => {
            if (value === getKiasDate(getValueByAttributeId(attribs, "creditIssueDate") || "")) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Дата выдачи кредита",
          defaultValue: getKiasDate(getValueByAttributeId(attribs, "creditIssueDate") || ""),
          validationType: "minMaxDate",
          minDate: "01.01.1950",
          maxDate: moment().add(31, 'years').format("DD.MM.YYYY"),
        },
        {
          type: "date",
          name: "firstPayDate",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          onChange: (value) => {
            if (value === getKiasDate(getValueByAttributeId(attribs, "firstPayDate") || "")) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Дата первого платежа (дата списания аннуитета)",
          defaultValue: getKiasDate(getValueByAttributeId(attribs, "firstPayDate"))
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "contractNumber",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          onChange: (value) => {
            if (value === getValueByAttributeId(attribs, "contractNumber")) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Номер кредитного договора",
          validationType: "agreementNumber",
          defaultValue: getValueByAttributeId(attribs, "contractNumber")
        },
        {
          type: "date",
          name: "agreementDate",
          isRequired: true,
          isDisabled: isDisabledSigned,
          errorMessage: "",
          onChange: (value) => {
            if (value === getKiasDate(getValueByAttributeId(attribs, "agreementDate") || "")) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Дата кредитного договора / договора займа",
          defaultValue: getKiasDate(getValueByAttributeId(attribs, "agreementDate") || "")
        },
      ]
    },
    {
      columns: 2, columnGap: 4,
      fieldItems: {
        type: "select",
        name: "salesChannel",
        placeholder: "Канал продаж",
        isRequired: true,
        isDisabled: type !== "admin" || isDisabledSigned,
        errorMessage: "",
        onChange: (value) => {
          if (getSelectValue(value) === getSelectValue(salesChannelSelect)) return
          changeIsPreCalcNeeded()
        },
        items: selectSalesChannel,
        isLoading: loadingSalesChannel,
        defaultValue: salesChannelSelect,
        isSearchAvailable: true,
        isClearable: true
      }
    }
  ];

  const risksInformation = [
    {
      title: windowWidth > 767 ? "Застраховано людей" : "Людей",
      description: <TextButton>{borrowers.length}</TextButton>
    },
    {
      title: windowWidth > 767 ? "Застраховано объектов имущества" : "Объектов имущества",
      description: <TextButton>{properties.length}</TextButton>
    },
    {
      title: windowWidth > 767 ? "Застраховано титулов" : "Титулов",
      description: <TextButton>{titles.length}</TextButton>
    }
  ]

  const changeIsPreCalcNeeded = () => {
    if (stateIsPreCalcNeeded) return;
    setIsPreCalcNeeded(true);
  }

  // const scheduleDownloadHandler = () => {
  //   // const scheduleValue: string | undefined = ((attribs || []).find(({isn}) => isn === scheduleIsn) || {}).val;
  //   // if (!scheduleValue) return;
  //   downloadSchedule({
  //     variables: {
  //       isn: id,
  //       templateIsn: "11170",
  //       classId: "2"
  //     }
  //   })
  // };

  // const scheduleButton = (
  //   <StyledInsurancePay>
  //     <Button
  //       isLoading={loadingDownloadSchedule}
  //       onClick={(ev) => {
  //         ev.preventDefault();
  //         scheduleDownloadHandler()
  //       }}
  //       appearance="link"
  //       iconBefore={<ScheduleIcon/>}>
  //       График страховых взносов
  //     </Button>
  //   </StyledInsurancePay>);

  const goToCreateInvoice = () => {
    return setNotification({type: "success", text: "Договор успешно подписан"});
  };

  const onDeleteObject = (isn: string) => {
    return setDeletedObjects([...stateDeletedObjects, isn])
  };

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;

    if (!stateSubmitType && isSberbankDoubleAgreement) return agreementMakeSberbank({
      variables: {
        isn: id
      }
    })
      .then(() => goToCreateInvoice())
      .catch(error => setNotification({type: "error", text: error}))

    if (!stateSubmitType) return changeContractStatus({
      variables: {
        isn: id,
        status: "П",
        isProlongation: !!prevIsn
      }
    })
      .then(() => goToCreateInvoice())
      .catch(error => setNotification({type: "error", text: error}))

    const formData = getFormValue(data);
    const {insureType, fullName, addr1, birthDate, countryIsn, authDocSer, dateEnd, dateBeg, creditIssueDate,  email, authDocNumber, phoneMobile, authDocDate, authDocIssuer} = formData || {};

    const dateDiff = moment(formatDateToUsa(dateEnd)).diff(formatDateToUsa(dateBeg), 'days');
    const dateDiffMonth = moment(formatDateToUsa(dateEnd)).diff(formatDateToUsa(dateBeg), 'month');
    if (dateDiff < 364) {
      setNotification({type: "error", text: "Срок действия договора менее 1 года"})
      return {dateEnd: "Срок действия договора менее 1 года"}
    }
    if (dateDiffMonth > 600) {
      setNotification({type: "error", text: "Срок действия договора более 600 месяцев"})
      return {dateEnd: "Срок действия договора более 600 месяцев"}
    }
    // if (dateDiff > ) {
    //   setNotification({type: "error", text: "Срок действия договора менее 1 года"})
    //   return {dateEnd: "Срок действия договора менее 1 года"}
    // }

    if(insureType != '4' && moment().isAfter(formatDateToUsa(creditIssueDate), "day")){
      return {
        creditIssueDate: `Дата выдачи не может быть ранее ${moment().format("DD.MM.YYYY")}`
      }
    }

    if (creditIssueDate !== dateBeg && bankIsn !== SBERBANK_ISN && insureType !== '4') {
        setNotification({type: "error", text: "Дата выдачи кредита должна быть равна дате начала действия договора"})
        return {creditIssueDate: `Должна быть равна ${dateBeg}`}
    }

    const {borrowersList} = getFormValueWithArray(formData, ["borrowerType", "borrowerShare", "borrowerName", "borrowerSumm", "borrowerTariff", "borrowerPremium", "heightWeight", "pressure", "discount", "allowance"], "borrowersList")
    const {propertiesList} = getFormValueWithArray(formData, ["propertyPledge", "propertyPledgeAddress", "propertySumInsured", "propertyPremium", "propertyConstantPay", "propertyOneTimePay", "propertyBasicTariff", "propertyDiscount", "propertyAllowance"], "propertiesList")
    const {titlesList} = getFormValueWithArray(formData, ["titleAddress", "titleDateEnd", "titleTariff", "titlePremium", "titleConstantPay", "titleOneTimePay"], "titlesList")
    const contractFilled = contractFilling({
      ...formData,
      borrowersList,
      propertiesList,
      titlesList
    }, (dataContract || {}).agreement || {}, stateDeletedObjects, !!prevIsn);

    updateContragent(({
      variables: {
        ...otherContragent,
        fullName,
        countryIsn: getSelectValue(countryIsn),
        birthDate,
        authDocSer,
        email,
        authDocNumber,
        phoneMobile,
        authDocDate,
        authDocIssuer,
      }
    }))
      .then(({data: {contragent: {isn}}}) => {
        saveAddress({
          variables: {
            isn,
            daData: {suggestions: addr1},
            addrType: "2246"
          }
        });
      })
      .then(() => {
        updateContract({variables: contractFilled})
          .then(() => {
              calcAgreement({
                variables: {
                  isn: id,
                  data: {
                    type: "A"
                  }
                }
              })
                .then(() => {
                  setIsPreCalcNeeded(false)
                  setSubmitType(undefined)
                  return setNotification({type: "success", text: "Премия успешно пересчитана"})
                })
                .catch(error => setNotification({type: "error", text: error}))
            }
          )
          .catch(error => setNotification({type: "error", text: error}))
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const isLoading: boolean = loadingChangeContractStatus /*|| loadingDownloadSchedule*/ || loadingAgreementMakeSberbank || loadingCalcAgreement || loadingSaveAddress || loadingUpdateContract || loadingUpdateContragent;
  if (errorUser) return <ErrorMessage error={errorUser}/>
  // if (errorDownloadSchedule) setNotification({type: "error", text: errorDownloadSchedule})
  if (errorContract) return <ErrorMessage error={errorContract}/>
  if (errorContragent) return <ErrorMessage error={errorContragent}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  if (loadingContract || loadingContragent || loadingDocs) return <Loader type="simple"/>

  return (
    <>
      <Heading2>Формирование договора</Heading2>
      <StyledUnderwritingWrapper>
        <ContractRegistrationHeader isLoading={isLoading}
                                    setSubmitType={setSubmitType}
                                    isDisabled={stateIsPreCalcNeeded}
                                    showProlongDeclaration={showProlongDeclaration}
        />
        <StyledContractRegistrationForm>
          <Form onSubmit={data => onFormSubmit(data)}>
            {({formProps}) => (
              <form {...formProps} id="contract-form">
                <StyledRisksInformation>
                  {risksInformation.map(({title, description}, index) => (
                    <StyledInformationItem key={`risk-item-${index}`}>
                      <TextButton>{title}</TextButton>
                      <TextButton>{description}</TextButton>
                    </StyledInformationItem>
                  ))}
                  {/*{windowWidth > 767 && scheduleButton}*/}
                </StyledRisksInformation>
                {/*{windowWidth <= 767 && scheduleButton}*/}
                <FieldsBuilder formFields={defaultForm}/>
                <Heading3>Общие сведения о страхователе</Heading3>
                <FieldsBuilder formFields={generalInformationForm}/>
                <Heading3>Основные положения кредитного договора</Heading3>
                <FieldsBuilder formFields={generalCreditInformationForm}/>
                {!!borrowers.length &&
                <>
                    <Heading3>Страхование жизни, потери трудоспособности залогодателя</Heading3>
                  {borrowers.map((borrower, index) => <BorrowerObject
                    key={`borrowerObject-${index}`}
                    object={borrower}
                    isDisabled={isDisabledSigned || isDisabledBorrowerObject}
                    isDisabledDelete={isDeleteDisabled}
                    onDelete={() => onDeleteObject(borrower.ISN)}
                    changeIsPreCalcNeeded={changeIsPreCalcNeeded}
                    maxDiscount={maxDiscount}
                    index={index}/>)}
                </>
                }
                {!!properties.length &&
                <>
                    <Heading3>Страхование имущества от гибели,утраты, повреждения</Heading3>
                  {properties.map((property, index) => <PropertyObject
                    key={`propertyObject-${index}`}
                    isDisabled={isDisabledSigned || isDisabledPropertyObject}
                    isDisabledDelete={isDeleteDisabled}
                    onDelete={() => onDeleteObject(property.ISN)}
                    object={property}
                    changeIsPreCalcNeeded={changeIsPreCalcNeeded}
                    maxDiscount={maxDiscount}
                    index={index}/>)}
                </>
                }
                {!!titles.length &&
                <>
                    <Heading3>Страхование потери имущества в результате прекращения, ограничения (обременения) права
                        собственности</Heading3>
                  {titles.map((title, index) => <TitleObject
                    key={`titleObject-${index}`}
                    object={title}
                    isDisabled={isDisabledSigned}
                    isDisabledDelete={isDeleteDisabled}
                    onDelete={() => onDeleteObject(title.ISN)}
                    changeIsPreCalcNeeded={changeIsPreCalcNeeded}
                    maxDiscount={maxDiscount}
                    index={index}/>)}
                </>
                }
              </form>
            )}
          </Form>
          <SubjectsTable/>
          <DocumentsToggle header='Документы по договору' type='A' id={id} openByDefault={true}/>
        </StyledContractRegistrationForm>
      </StyledUnderwritingWrapper>
      <DeclarationModal data={{
        openSaveModal: () => {},
        afterProlongation: true,
        phoneMobile: insurerPhone,
        clientIsn,
      }} isOpen={stateDeclarationModal} header="Вариант заполнения"
                        onClose={() => setDeclarationModal(false)}/>
    </>
  )
};

export default ContractById;