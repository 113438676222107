import React                from 'react';
import Modal, {ModalProps}       from "../../../uiKit/modal";
import Button                    from "../../../uiKit/button/button";
import {StyledButtonsGroup}          from "../../roles/components/header/styles";
import {useMutation}                 from "@apollo/react-hooks";
import FieldsBuilder                 from "../../../uiKit/fieldsBuilder";
import Form                          from '@atlaskit/form';
import {getFormError}                from "../../../formUtils/getFormError";
import {getFormValue}                from "../../../formUtils/getFormValue";
import {IFormField}                  from "../../../uiKit/fieldsBuilder/interfaces";
import {ISelectItem}                                     from "../../../uiKit/select";
import {CANCEL_AGREEMENT_REASON, CHANGE_CONTRACT_STATUS} from "../../../utils/mutations";
import {useCallNotification}                             from "../../../hooks/useCallNotification";
import {TextButton}        from "../../../globalStyledComponents";
import {StyledCancelAgreementHeader} from "../styles";

const selectReasons: ISelectItem[] = [
  {label: "Дорого", value: "cost"},
  {label: "Передумал", value: "changed"},
  {label: "Иное", value: "other"},
];
export const SelectReasonModal = ({onClose, header, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id, type, agreementType} = data || {};
  const [stateSelectReason, setSelectReason] = React.useState<ISelectItem | undefined>(undefined);
  const [cancelAgreementReason, {loading: loadingCancelAgreementReason}] = useMutation(CANCEL_AGREEMENT_REASON, {refetchQueries: ["docs"]});
  const [changeContractStatus, {loading: loadingChangeContractStatus}] = useMutation(CHANGE_CONTRACT_STATUS, {refetchQueries: ["docs"]});

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {reason, reasonText} = getFormValue(data);
    if (agreementType === "A") return changeContractStatus({variables: {
        isn: id,
        status: "А",
        isNotDelete: true
      }})
      .then(() => onClose())
      .catch(error => setNotification({type: "error", text: error}))

    cancelAgreementReason({variables: {
        subjIsn: id,
        couseIsn: type === "client" ? "1877271" : null,
        remark: type === "canceled" ? reasonText : reason?.value === "other" ? reasonText : reason?.label,
        status: type === "client" ? "clientRefusal" : "annul",
      }})
      .then(() => onClose())
      .catch(error => setNotification({type: "error", text: error}))
  };

  const form: IFormField[] = [
    {
      isHidden: type === "canceled",
      fieldItems: {
        type: "select",
        name: "reason",
        returnFullValue: true,
        placeholder: "Выберите причину",
        items: selectReasons,
        onChange: value => setSelectReason(value),
        isRequired: true,
        errorMessage: "Выберите причину"
      }
    },
    {
      isHidden: type === "canceled" ? false : stateSelectReason?.value !== "other",
      fieldItems: {
        type: "textarea",
        name: "reasonText",
        placeholder: "Укажите причину",
        isRequired: true,
        errorMessage: "Укажите причину"
      }
    },
    ]

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            {agreementType === "A" && <StyledCancelAgreementHeader><TextButton>Вы действительно хотите аннулировать договор?</TextButton></StyledCancelAgreementHeader>}
            {agreementType !== "A" && <FieldsBuilder formFields={form}/>}
            <StyledButtonsGroup>
              <Button
                type="submit"
                appearance="filled"
                isLoading={loadingCancelAgreementReason || loadingChangeContractStatus}
              >
                {agreementType === "A" ? "Аннулировать" : "Отправить"}
              </Button>
              <Button
                onClick={() => onClose()}
                appearance="transparent"
                isLoading={loadingCancelAgreementReason || loadingChangeContractStatus}
              >
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default SelectReasonModal;