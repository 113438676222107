import styled from "styled-components";
import {grey} from "../../../../globalStyledComponents";

export const StyledDefaultUserArea = styled.div`
  border-top: 1px solid ${grey};
  border-bottom: 1px solid ${grey};
`;
export const StyledDefaultUserRoleHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0.5rem;
  padding: 2rem 0;
  border-bottom: 1px solid ${grey};
      p:first-child {
      opacity: 0.4;
    }
    
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
export const StyledDefaultUserRoleBody = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5rem;
   > p {
      opacity: 0.4;
    }
    
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledDefaultUserRole = styled.div`
  display: flex;
  flex-direction: column;
  p {
    padding: 0.5rem 0;
  }
`;

export const StyledDefaultUserAreaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;