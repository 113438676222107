import React                                             from "react";
import Modal, {ModalProps}                               from "../../../uiKit/modal";
import Button
                                                         from "../../../uiKit/button/button";
import {DownloadIcon}                                    from "../../../icons/ui/Download";
import {
  Heading1,
  TextButton
}                                                                               from "../../../globalStyledComponents";
import {StyledInvoiceCurrency, StyledInvoiceDescription, StyledInvoiceDropDown} from "../styles";
import {useLazyQuery}                                                           from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD}                                 from "../../../utils/queries";
import {fileDownloader}                                  from "../../../helpers/fileDownloader";
import {useCallNotification}                             from "../../../hooks/useCallNotification";
import DropDown, {IDropDownListItem}                     from "../../../uiKit/dropDownArea";
import {useWindowWidth}                                  from "@react-hook/window-size";
import SendClientModal                                   from "./SendClientModal";
import FieldsBuilder from "../../../uiKit/fieldsBuilder";
import {getSelectValue} from "../../../formUtils/getFormValue";
import {radioBoolean} from "../../createRequest/staticData";


export const InvoiceModal = ({header, isOpen, data, onClose}: ModalProps) => {
  const windowWidth = useWindowWidth({wait: 250});
  const {setNotification} = useCallNotification();
  // const [stateModalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
  const [stateDropDownIsOpen, setDropDownIsOpen] = React.useState<boolean>(false)
  const [stateDropDownSendIsOpen, setDropDownSendIsOpen] = React.useState<boolean>(false)
  const [stateSendModal, setSendModal] = React.useState<any>(null)
  const {isnInvoice, summForPay, contractId, email} = data || {};
  const [downloadInvoice, {data: dataDownloadInvoice, loading: loadingDownloadInvoice, error: errorDownloadInvoice}] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const urlFile = ((dataDownloadInvoice || {}).documentForm || {}).url;

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  const downloadInvoiceHandler = (templateIsn: string) => {
    downloadInvoice({
      variables: {
        isn: isnInvoice,
        templateIsn
      }
    })
  };

  const headerButtons: IDropDownListItem[] = [
    {label: "Cчeт для ФЛ Терминал ТКБ+МКБ", event: () => downloadInvoiceHandler("4610"), isDisabled: loadingDownloadInvoice},
    {label: "Cчeт для ФЛ Сбербанк онлайн + Отделение", event: () => downloadInvoiceHandler("11151"), isDisabled: loadingDownloadInvoice},
    {label: "Cчeт oбщая форма (или для ФЛ оплата на сайте + ВТБ)", event: () => downloadInvoiceHandler("10950"), isDisabled: loadingDownloadInvoice},
    {label: "Cчет для ФЛ Альфа Банк", event: () => downloadInvoiceHandler("11221"), isDisabled: loadingDownloadInvoice},
  ]

  const headerButtonsSend: IDropDownListItem[] = [
    {
      label: "Cчeт для ФЛ Терминал ТКБ+МКБ", event: () => {
        setSendModal({templateIsn: "4610"})
        setDropDownSendIsOpen(false);
      }
    },
    {
      label: "Cчeт для ФЛ Сбербанк онлайн + Отделение", event: () => {
        setSendModal({templateIsn: "11151"})
        setDropDownSendIsOpen(false);
      }
    },
    {
      label: "Cчeт oбщая форма (или для ФЛ оплата на сайте + ВТБ)", event: () => {
        setSendModal({templateIsn: "10950"})
        setDropDownSendIsOpen(false);
      }
    },
    {
      label: "Cчет для ФЛ Альфа Банк", event: () => {
        setSendModal({templateIsn: "11221"})
        setDropDownSendIsOpen(false);
      }
    },
  ]

  if (errorDownloadInvoice) setNotification({type: "error", text: errorDownloadInvoice})
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <StyledInvoiceCurrency>
        <TextButton>Сумма к оплате</TextButton>
        <Heading1>{summForPay}</Heading1>
      </StyledInvoiceCurrency>
      <StyledInvoiceDescription>
        <TextButton>Назначение платежа:</TextButton>
        <TextButton>Страховая премия по договору {contractId}</TextButton>
      </StyledInvoiceDescription>

      <StyledInvoiceDropDown>
        <DropDown
          trigger={
            <Button
              appearance="filled"
              width="100%"
              onClick={() => setDropDownIsOpen(true)}
              // isDisabled={!isnInvoice}
              iconBefore={<DownloadIcon/>}
              isLoading={loadingDownloadInvoice}>
              Скачать
            </Button>
          }
          isDropDownList={true}
          position={windowWidth < 768 ? "top" : undefined}
          bodyWidth="100%"
          items={headerButtons}
          isOpen={stateDropDownIsOpen}
          onClose={() => setDropDownIsOpen(false)}/>
        <DropDown
          trigger={
            <Button
              appearance="filled"
              width="100%"
              onClick={() => setDropDownSendIsOpen(true)}
              // isDisabled={!isnInvoice}
              iconBefore={<DownloadIcon/>}
              isLoading={loadingDownloadInvoice}>
              Отправить на почту
            </Button>
          }
          isDropDownList={true}
          position={windowWidth < 768 ? "top" : undefined}
          bodyWidth="100%"
          items={headerButtonsSend}
          isOpen={stateDropDownSendIsOpen}
          onClose={() => setDropDownSendIsOpen(false)}/>
      </StyledInvoiceDropDown>

      <SendClientModal data={{...stateSendModal, number: contractId, isn: isnInvoice, email, type: "bill"}} onClose={() => {
        onClose();
        setSendModal(null)
      }} isOpen={!!stateSendModal} header='Отправить счет'/>
      {/*<SendResultModal header="Отправить" data={{isnInvoice}} onClose={() => setModalIsOpen(false)} isOpen={stateModalIsOpen}/>*/}
    </Modal>
  )
};

export default InvoiceModal;