import React                   from "react";
import {ApolloError}           from "apollo-client";
import {getGraphqlError}       from "../helpers/getGraphqlError";
import StoreStatusNotification from "../components/statusNotification/store";
import {useHistory}            from "react-router";
import {NotificationTypes}     from "../components/statusNotification/store/states/statusNotification";


interface INotificationProps {
  type: NotificationTypes,
  text: ApolloError | string
}

interface INotificationState {
  type: NotificationTypes,
  text: string
}

export const useCallNotification = () => {
  const history = useHistory();
  const [{statusNotification: {statusNotificationState: {text: textStoreNotification, type: typeStoreNotification}}}, {statusNotification: actionsStatusNotification}]: any = StoreStatusNotification();
  const [stateNotification, setNotification] = React.useState<INotificationState | undefined>(undefined)
  const {type, text} = stateNotification || {};

  const callNotificationHandler = (type: NotificationTypes) => actionsStatusNotification.setState({
      statusNotificationState: {
        type,
        text
      }
    })

  React.useEffect(() => {
    if (!textStoreNotification && stateNotification) return setNotification(undefined);
    // eslint-disable-next-line
  }, [textStoreNotification])

  React.useEffect(() => {
    if (!type) return;
    if (type === "success") return callNotificationHandler("success")
    if (type === "error") return callNotificationHandler("error")
    if (type === "warning") return callNotificationHandler("warning")
    if (type === "loading") return callNotificationHandler("loading")
    // eslint-disable-next-line
  }, [text])

  return {
    setNotification: (notification: INotificationProps) => {
      const {type: propsType, text: propsText} = notification || {};
      // @ts-ignore
      if(window.lastNotificationType === 'error' && propsType === 'success') {
        console.log('RETURNO');
        return ;
      }
      const notificationMessage: string = typeof propsText === "string" ? propsText : getGraphqlError(propsText);
      if (["KIAS: Не указан идентификатор сессии или сессия устарела", "Требуется авторизация"].includes(notificationMessage)) return history.push("/authorization")
      if (notificationMessage === text) return;
      setNotification({type: propsType, text: notificationMessage})
    }
  }
};