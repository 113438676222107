import React                                      from "react";
import {Heading1, Heading2, Heading3, TextButton} from "../../globalStyledComponents";
import {
  StyledCreateInvoiceBodyWrapper,
  StyledCreateInvoiceWrapper, StyledInvoiceCurrency, StyledInvoiceEventsButtons,
  StyledLeftCreateInvoiceArea,
  StyledRightCreateInvoiceArea
}                                                 from "./styles";
import CreateInvoiceHeader                        from "./components/header";
import {StyledUnderwritingWrapper}                from "../applicationById/styles";
import Button                                     from "../../uiKit/button/button";
import {FileIcon}                                 from "../../icons/ui/File";
import InvoiceModal                               from "./components/InvoiceModal";
import {useLazyQuery, useMutation, useQuery}                                from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, CONTRAGENT, DOCS, DOWNLOAD_CONTRACT, GET_CONTRACT, ME} from "../../utils/queries";
import {useParams}                                                          from "react-router";
import {CANCEL_INVOICE, CREATE_INVOICE}           from "../../utils/mutations";
import {Loader}                                   from "../../uiKit/loader";
import {
  StyledButtonsWrapper, StyledDownloadFileItem
}                              from "../applicationById/components/toggleArea/components/rightToggles/styles";
import {StyledFileName}        from "../createRequest/components/insuranceStep/components/onlineFilling/styles";
import {DownloadIcon}          from "../../icons/ui/Download";
import Toggle                  from "../../uiKit/toggle";
import {CLASS_ISN_BORROWERS, CLASS_ISN_OBJECTS, getValueByAttributeId} from "../../helpers/buildTemplateForBack";
import {fileDownloader}        from "../../helpers/fileDownloader";
import {ErrorMessage}          from "../../components/errorMessage";
import {getSummForNextInvoice, getSummForPay} from "../../helpers/templateToFormRebuild";
import {useCallNotification}   from "../../hooks/useCallNotification";
import {TrashIcon}             from "../../icons/ui/Trash";
import SendClientModal         from "./components/SendClientModal";
import SendPayLinkModal from "./components/header/SendPayLinkModal";
import {SendIcon} from "../../icons/ui/Send";
import {money} from "../../utils/formats";
import {rolesRightHandler} from "../../helpers/rolesRightHandler";
import moment from "moment";

export const CreateInvoice = () => {
  const {setNotification} = useCallNotification();
  const {id} = useParams();
  const [stateModalIsOpen, setModalIsOpen] = React.useState<any>(undefined)
  const [stateSendModal, setSendModal] = React.useState<any>(null)
  const [stateSendPayLinkModal, setSendPayLinkModal] = React.useState<any>(undefined);

  const [createInvoice, {
    loading: loadingCreateInvoice
  }] = useMutation(CREATE_INVOICE, {fetchPolicy: "no-cache", refetchQueries: ["agreement"]});
  const [cancelInvoice, {
    loading: loadingCancelInvoice
  }] = useMutation(CANCEL_INVOICE, {refetchQueries: ["agreement"]});
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {variables: {isn: id}});
  const {data: dataContract, loading: loadingContract, error: errorContract} = useQuery(GET_CONTRACT, {
    variables: {
      isn: id
    }
  });
  const [getContractSber, {
    data: dataContractSber,
    loading: loadingContractSber,
    error: errorContractSber
  }] = useLazyQuery(GET_CONTRACT);
  const [downloadContract, {
    data: dataDownloadContract,
    loading: loadingDownloadContract,
    error: errorDownloadContract
  }] = useLazyQuery(DOWNLOAD_CONTRACT, {fetchPolicy: "cache-and-network"});
  const [downloadAnketa, {
    data: dataDownloadAnketa,
    loading: loadingDownloadAnketa,
    error: errorDownloadAnketa
  }] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [getContragent, {
    data: dataContragent,
    loading: loadingContragent,
    error: errorContragent
  }] = useLazyQuery(CONTRAGENT);
  const {email: insurerEmail, phoneMobile: insurerPhone} = (dataContragent || {}).contragent || {};

  const urlFile = ((dataDownloadContract || {}).agreementForm || {}).url;
  const urlFileAnketa = ((dataDownloadAnketa || {}).documentForm || {}).url;
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {data, nextInvoiceAllowed} = (docsList || [])[0] || {};
  const {isnSber, sberbankExpress} = data || {};
  const {objects, curCode, attribs, id: contractId, sums, clientIsn, prevIsn} = (dataContract || {}).agreement || {};

  const borrowers = (objects || []).filter(({ ClassISN }) => ClassISN === CLASS_ISN_BORROWERS);

  const hasLife = (borrowers || []).length > 0;

  console.log('sums', sums);
  const {
    objects: objectsSber,
    curCode: curCodeSber,
    id: contractIdSber,
    sums: sumsSber
  } = (dataContractSber || {}).agreement || {};

  const isnBank = getValueByAttributeId(attribs, "creditOrganizationName");
  let isAlreadyPayed: boolean = Array.isArray(sums) ? !!(sums || []).find(({Discr}) => Discr === "F") : false
  const isAlreadyPayedSber: boolean = Array.isArray(sumsSber) ? !!(sumsSber || []).find(({Discr}) => Discr === "F") : false
  const isnInvoice = (Array.isArray(sums) ? (sums || []).find(({Discr}) => Discr === "f") || {} : sums || {}).OperDocISN;
  const sumInvoice = isnInvoice ? getSummForNextInvoice(sums, isnInvoice) : null;
  const summForPay = sumInvoice ? sumInvoice : getSummForPay(objects, curCode);
  const summForPaySber = getSummForPay(objectsSber, curCodeSber);
  isAlreadyPayed = isAlreadyPayed && !isnInvoice;
  const isnInvoiceSber = (Array.isArray(sumsSber) ? (sumsSber || []).find(({Discr}) => Discr === "f") || {} : sumsSber || {}).OperDocISN;
  
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role, show_kv} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const haveRightForConfirmLetter: boolean = rolesRightHandler("confirmLetter", functions, type, isAdmin);
  const canSendAgreement = rolesRightHandler("sendAgreement", functions, type, isAdmin);

  const isCurrentPeriodPayed = (sums: any[]) => {
    let isPayed = false;
    for (let i = 0; i < sums.length; i++) {
      const dateMoment = moment(sums[i].DatePay, 'DD.MM.YYYY');
      if ((sums[i].Discr === "F") && (dateMoment.add(305, 'day').isAfter(moment()))) {
        isPayed = true;
      }
    }
    return isPayed;
  }

  React.useEffect(() => {
    if (!clientIsn) return;
    getContragent({
      variables: {
        isn: clientIsn
      }
    })
    // eslint-disable-next-line
  }, [clientIsn])

  React.useEffect(() => {
    if (!isnSber) return;
    getContractSber({variables: {isn: isnSber}})
    // eslint-disable-next-line
  }, [isnSber])

  React.useEffect(() => {
    fileDownloader(urlFileAnketa);
    // eslint-disable-next-line
  }, [urlFileAnketa]);

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  const downloadLetterHandler = (isn) => {
    if(!isCurrentPeriodPayed(sums)){
        setNotification({type: "error", text: "Отсутствует оплата за выбранный период"});
        return;
    }
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "12490",
        classId: 2,
      }
    });
  };
  
  const downloadKidHandler = (isn) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "15741",
        classId: 2,
      }
    });
  }

  const downloadTariffsHandler = (isn) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "15937",
        classId: 2,
      }
    });
  }

  const downloadSertificatHanlder = (isn) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "12628",
        classId: 2,
      }
    });
  }

  const downloadSberbankExpress = (isn) => {
    console.log('download sberbank express');
    downloadContract({
      variables: {
        isn,
        bankIsn: isnBank,
        project: 0,
        template: 16901
      }
    })
  }

  const downloadContractHandler = (isn, type: string) => {
    if (type === "letter") return downloadLetterHandler(isn);
    if (type === "kid") return downloadKidHandler(isn);
    if (type === "tariffs") return downloadTariffsHandler(isn);
    if (type === "sertificat") return downloadSertificatHanlder(isn);
    if (sberbankExpress || (prevIsn && hasLife)) return downloadSberbankExpress(isn);
    downloadContract({
      variables: {
        isn,
        bankIsn: isnBank,
        project: 0
      }
    })
  };

  const createInvoiceHandler = (id, contractId, summForPay) => {
    createInvoice({
      variables: {
        argIsn: id
      }
    })
      .then(({data: {createInvoice: isnInvoice}}) => setModalIsOpen({isnInvoice, summForPay, contractId}))
      .catch(error => setNotification({type: "error", text: error}))
  }

  const cancelInvoiceHandler = (isnInvoice) => {
    cancelInvoice({
      variables: {
        docIsn: isnInvoice
      }
    })
      .then(() => setNotification({type: "success", text: "Счет успешно аннулирован"}))
      .catch(error => setNotification({type: "error", text: error}))
  }

  const isRaiffeisenBank = (isnBank === "850421" || isnBank === "1966971");
  const isShowSerfificatForBank = [
    "1885261", // НСБанк
    "1860301", // Новиком
    "850411", // Абсолют Банк
    "1917681", // New_Газпромбанк
    "850401", // Old_ДОМ.РФ
    "1917301", // New_ДОМ.РФ_04.10.21
    "870581", // Банк Открытие
    "877381", // Транскапиталбанк
    "877391", // ИнвестТоргБанк,
    "1987251", // Абсолют Банк новый кредит
    "2014711", // ДОМ.РФ переход
    "1931721", // New_ДОМ.РФ_11.02.22

    "2055791", // Газпромбанк (переход)
    "870571", // Газпромбанк КД до 14.10.2021
    "858881", // Банк Уралсиб
    "949741", // МеталлИнвестБанк
    "1921501", // МТС Банк
    "2061461", // Банк Открытие_переход
    "954001", // Альфа-Банк до 19.06.22
    "850431", // Альфа-Банк до 30.03.20
    "2014681", // Альфа-Банк переход
    "1943011", // Альфа-Банк с 20.06.22
  ].includes(isnBank);

  const files = isnSber ? [
    {name: "Сбербанк Жизнь", id, type: "contract"},
    {name: "Сбербанк Имущество", type: "contract", id: isnSber},
    ...haveRightForConfirmLetter ? [{name: "Письмо - подтверждение оплаты", id, type: "letter"}] : [],
    {name: "КИД", type: "kid", id},
  ] :
    [
    {name: "Страховой полис", id, type: "contract"},
    ...haveRightForConfirmLetter ? [{name: "Письмо - подтверждение оплаты", id, type: "letter"}] : [],
    {name: "КИД", type: "kid", id},
      ...isRaiffeisenBank ? [{name: "Тарифы", id, type: "tariffs"}] : [],
      ...(nextInvoiceAllowed && isShowSerfificatForBank) ? [{name: "Сертификат", id, type: "sertificat"}] : [],
  ];

  const invoices = isnSber ? [
      {
        invoiceName: "Сформировать счет на оплату жизнь",
        summName: "Сумма к оплате жизнь",
        summ: summForPay,
        id,
        contractId,
        isnInvoice,
        isAlreadyPayed: isAlreadyPayed
      },
      {
        invoiceName: "Сформировать счет на оплату имущество",
        summName: "Сумма к оплате имущество",
        summ: summForPaySber,
        id: isnSber,
        contractId: contractIdSber,
        isnInvoice: isnInvoiceSber,
        isAlreadyPayed: isAlreadyPayedSber
      }
    ] :
    [
      {
        invoiceName: "Сформировать счет на оплату",
        summName: "Сумма к оплате",
        summ: summForPay,
        id,
        contractId,
        isnInvoice,
        isAlreadyPayed: isAlreadyPayed
      }
    ];

  if (errorDownloadContract) setNotification({type: "error", text: errorDownloadContract})
  if (errorContract) return <ErrorMessage error={errorContract}/>
  if (errorContractSber) return <ErrorMessage error={errorContractSber}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  if (errorContragent) return <ErrorMessage error={errorContragent}/>
  if (errorDownloadAnketa) setNotification({type: "error", text: errorDownloadAnketa});
  // if (errorDownloadInvoice) return <ErrorMessage error={errorDownloadInvoice} notImportant={true}/>
  if (loadingContract || loadingDocs || loadingContractSber || loadingContragent) return <Loader type="simple"/>
  return (
    <StyledCreateInvoiceWrapper>
      <Heading2>Договор страхования</Heading2>
      <StyledUnderwritingWrapper>
        <CreateInvoiceHeader/>
        <StyledCreateInvoiceBodyWrapper>
          <StyledLeftCreateInvoiceArea>
            <Heading3>
              Общие сведения
            </Heading3>
            {invoices.map(({invoiceName, id, summ, summName, contractId, isnInvoice, isAlreadyPayed}, index) => (
              <>
                    <StyledInvoiceCurrency key={`invoice-${index}`}>
                      <TextButton>{summName}</TextButton>
                      <Heading1>{summ}</Heading1>
                    </StyledInvoiceCurrency>
                    {!isAlreadyPayed && <StyledInvoiceEventsButtons>
                      <Button
                          iconBefore={<SendIcon/>}
                          onClick={() => setSendPayLinkModal({contractId: id})}
                          appearance="link">
                        Отправить ссылку на оплату
                      </Button>
                      <Button
                        key={`invoice-${index}`}
                        appearance="link"
                        iconBefore={<FileIcon/>}
                        isLoading={loadingCreateInvoice}
                        onClick={() => {
                          if (!!isnInvoice) return setModalIsOpen({isnInvoice, summForPay, contractId});
                          createInvoiceHandler(id, contractId, summ);
                        }}>
                        {!!isnInvoice ? "Перейти к счету" : invoiceName}
                      </Button>
                      {!!isnInvoice && <Button
                          key={`invoice-${index}`}
                          appearance="link"
                          iconBefore={<TrashIcon/>}
                          isLoading={loadingCancelInvoice}
                          onClick={() => cancelInvoiceHandler(isnInvoice)}>
                          Аннулировать счет
                      </Button>}
                    </StyledInvoiceEventsButtons>}
                  </>
            ))}
          </StyledLeftCreateInvoiceArea>
          <StyledRightCreateInvoiceArea>
            <Toggle
              emptyMessage={files.length === 0 ? "Нет документов" : undefined}
              header={"Документы по договору"}>
              <>
                {files.length !== 0 && files.map(({name, id, type}, index) => (
                  <StyledDownloadFileItem key={`download-file-${index}`}>
                    <StyledFileName>
                      <FileIcon/>
                      <TextButton>{name}</TextButton>
                    </StyledFileName>

                    <StyledButtonsWrapper>
                      <Button appearance="link" isDisabled={!id || !isnBank} isLoading={loadingDownloadContract || loadingDownloadAnketa}
                              onClick={() => downloadContractHandler(id, type)} iconBefore={<DownloadIcon/>}>Скачать</Button>

                      { (type !== 'contract' || canSendAgreement) &&
                          <Button appearance="link" isDisabled={!id || !isnBank} isLoading={loadingDownloadContract || loadingDownloadAnketa}
                                  onClick={() => setSendModal({isn: id, type, isnBank})}>Отправить</Button>
                      }

                    </StyledButtonsWrapper>
                  </StyledDownloadFileItem>
                ))
                }
              </>
            </Toggle>
          </StyledRightCreateInvoiceArea>
        </StyledCreateInvoiceBodyWrapper>

      </StyledUnderwritingWrapper>
      <InvoiceModal header="Формирование счета" data={{...stateModalIsOpen, email: insurerEmail}}
                    onClose={() => setModalIsOpen(undefined)} isOpen={!!stateModalIsOpen}/>
      <SendClientModal data={{...stateSendModal, number: contractId, email: insurerEmail}} onClose={() => setSendModal(null)} isOpen={!!stateSendModal} header='Отправить документы'/>
      <SendPayLinkModal
          onClose={() => setSendPayLinkModal(undefined)}
          data={{
            ...stateSendPayLinkModal,
            email: insurerEmail,
            phone: insurerPhone,
          }}
          isOpen={!!stateSendPayLinkModal}
      />
    </StyledCreateInvoiceWrapper>
  )
};

export default CreateInvoice;