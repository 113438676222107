export interface IFormError {
  [key: string]: string
}

export const getFormError = (fields: any) => {
  const defaultError: string = "Это обязательное поле";
  const errors: { [key: string]: any }[] = Object.entries<any>(fields).reduce<IFormError[]>((prev, [key, objectValue]) => {
    const {type, isRequired, value, errorMessage} = objectValue;
    if (!isRequired) return prev;
    switch (type) {
      case "input":
      case "textarea":
        if (!(String(value) || "").trim()) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "inn":
        if (!value || value == "") return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "select":
      case "address":
      case "fio":
        if (!value || !(value || {}).value) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "radio":
        if (value?.value === undefined || value === undefined) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "enums":
        if (!value || (value || []).length === 0) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "checkbox":
      case "toggle":
        if (!value) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "range":
      case "date":
        if (!value) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "dateInterval":
        if (!value || !value.start || !value.end) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      case "number":
        if (!value || Number((String(value||"")).replace(/\s+/g, '').trim()) === 0) return [...prev, {[key]: errorMessage || defaultError}]
        return prev;
      default:
        return prev;
    }
  }, []);
  if (errors.length !== 0) return errors[0];
  return undefined;
};