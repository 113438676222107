import React                 from 'react';
import {Heading1, Text2}  from "../../globalStyledComponents";

export const Help = () => {

  return (
    <div>
      <Heading1>Помощь</Heading1>
      <Text2>Данный раздел не заполнен</Text2>
      {/* <NumberInputSwitcher placeholder="Размер кредита"/>
      <Heading3>{`",1231" -> ${money(",1231")}`}</Heading3>
      <Heading3>{`"100.22" -> ${money("100.22")}`}</Heading3>
      <Heading3>{`100.22 -> ${money(100.22)}`}</Heading3>
      <Heading3>{`5000000 -> ${money(5000000)}`}</Heading3> */}
    </div>
  )
};

export default Help;