import styled                    from "styled-components";
import {blue7, lightBlue, white} from "../../globalStyledComponents";

export const StyledSelectWrapper = styled.div`
  position: relative;
  
  .listShow {
    opacity: 1;
  } 
  .listHide {
    pointer-events: none;
    opacity: 0;
  }  
`;
export const StyledSelectItemsWrapper = styled.div<{height?: number}>`
  position: absolute;
  width: 100%;
  background: ${white};
  box-sizing: border-box;
  border: 1px solid ${lightBlue};
  border-top: unset;
  padding: 0.5rem 0;
  z-index: 2;
  overflow-y: auto;
  transition: opacity 0.3s;
  opacity: 0;
  
  ${({height}) => 
   `max-height: ${typeof height === "number" ? height : 30}rem;` 
  }
`;
export const StyledSelectItem = styled.div<{ isActive: boolean }>`
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  
  :hover {
    background: ${blue7};
  }
  p {
    opacity: 0.5;
  }
  ${({isActive}) =>
  isActive ?
    `
      p {
        opacity: 1;
      }
      `
    : ""
}
`;

export const StyledSelectEmpty = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`;

export const StyledSelectArrowIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledSelectEvents = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  > .iconButton {
    margin: 0.5rem 1rem 0 0;
  }
`;