import styled from "styled-components";

export const StyledSubjectsTableHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 6rem 0 3rem 0;
  > h3 {
    margin: 0;
  }
`;