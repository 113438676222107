import styled from "styled-components";

export const StyledCoBorrowersWrapper = styled.div`

`;


export const StyledCoBorrowerItem = styled.div`

`;
export const StyledCoBorrowerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;