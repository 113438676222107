import moment from "moment";

export type ValidationTypes = "phone" | "email" | "agreementNumber" | "code" | "password" | "date" | "fullName" | "addressWithFlat" | "passportSerial" | "passportNumber" | "minMax" | "minMaxDate" | "minMaxLength" | "cadastralNumber" | "contragentIsn" | undefined;

export interface IValidationProps {
  value: any,
  validationType: ValidationTypes,
  maxValue?: number,
  minValue?: number,
  minDate?: string,
  maxDate?: string,
}

const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const regAddress = /(кв)\s(\w+)/;
const regDate = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
const regAgreementNumber = /[^a-zA-Z0-9а-яА-ЯёЁ().\/\-_\\]/;
const regCadastralNumber = /[^0-9:\/\-_\\]/;

const checkDateFormat = (date: string) => {
  if (moment(formatDateToUsa(date)).format("DD.MM.YYYY") === "Invalid date" || !regDate.test(date)) return "Неверный формат даты";
  return undefined;
}

export const fieldsValidate = (props: IValidationProps) => {
  const {value, validationType, maxValue, minValue, maxDate, minDate} = props;
  if (!value || !validationType) return undefined;
  const trimValue = String(value).trim();
  switch (validationType) {
    case "phone":
      if (!trimValue) return "Введите телефон"
      const clearValue = trimValue.replace(/[^\d;]/g, '');
      if (clearValue.length !== 11) return "Введите телефон полностью";
      return undefined
    case "contragentIsn":
      if (!trimValue) return "Введите телефон"
      const clearValueIsn = trimValue.replace(/[^\d;]/g, '');
      if(trimValue != clearValueIsn) return "Разрешены только цифры";
      if (clearValueIsn.length !== 9) return "Введите ISN полностью";
      return undefined
    case "email":
      if (!trimValue) return "Введите email"
      if (regEmail.test(trimValue)) return undefined;
      return "Неверный формат email"
    case "code":
      if (!trimValue) return "Введите пароль"
      if (trimValue.length < 6) return "Введите пароль полностью";
      return undefined
    case "addressWithFlat":
      if (!value?.value) return "Выберите адрес";
      if (!value?.data?.flat) return "Выберите адрес вплоть до квартиры";
      return undefined;
    case "password":
      if (!trimValue) return "Введите пароль"
      if (trimValue.length < 6) return "Введите пароль полностью";
      return undefined
    case "date":
      return checkDateFormat(trimValue);
    case "fullName":
      const fullNameArray: string[] = (value?.value||'').trim().split(" ");
      if (fullNameArray.length >= 2) return undefined;
      return "Введите Ф.И.О. полностью"
    case "passportSerial":
      if (trimValue.length === 4) return undefined;
      return "Введите серию в верном формате"
    case "passportNumber":
      if (trimValue.length === 6) return undefined;
      return "Введите номер в верном формате"
    case "agreementNumber":
      if (trimValue.length > 255) return "Не может быть длиннее 255 символов";
      if (regAgreementNumber.test(trimValue)) return "Не должен содержать спец символов";
      return undefined;
    case "cadastralNumber":
      if (regCadastralNumber.test(trimValue)) return "Может содержать только цифры и разделитель";
      return undefined;
    case "minMax":
      const valueToNumber = Number((String(value)||"").replace(/\s+/g, '').trim().replace(",","."));
      if (isNaN(valueToNumber)) return "Не число";
      if (typeof minValue === "number" && valueToNumber < minValue) return `Введенное число меньше минимально допустимого в ${minValue}`;
      if (typeof maxValue === "number" && valueToNumber > maxValue) return `Введенное число больше максимально допустимого в ${maxValue}`;
      return undefined;
    case "minMaxDate":
      if (checkDateFormat(trimValue)) return "Неверный формат даты";
      if (minDate && moment(formatDateToUsa(trimValue)).isBefore(formatDateToUsa(minDate))) return `Дата не может быть ранее ${minDate}`;
      if (maxDate && moment(formatDateToUsa(maxDate)).isBefore(formatDateToUsa(trimValue))) return `Дата не может быть позднее ${maxDate}`;
      return undefined;
    case "minMaxLength":
      if (typeof minValue === "number" && trimValue.length < minValue) return `Количество символов меньше минимально допустимого в ${minValue}`;
      if (typeof maxValue === "number" && trimValue.length > maxValue) return `Количество символов больше максимально допустимого в ${maxValue}`;
      return undefined;
    default:
      return "Unknown validation type"
  }
};

export const formatDateToUsa = (date: string) => {
  return moment(date, "DD.MM.YYYY").format("MM.DD.YYYY");
}