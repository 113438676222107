import React                            from 'react';
import {Heading3, Heading5, TextButton} from "../../globalStyledComponents";
import {
  StyledEmptyToggle,
  StyledToggleBody,
  StyledToggleHeader,
  StyledToggleIcon, StyledToggleNewItems,
  StyledToggleNewItemsWrapper,
  StyledToggleWrapper
} from "./styles";
import {Loader}                         from "../loader";

interface IToggleProps {
  header: string
  children?: any
  isLoading?: boolean
  isOpen?: boolean
  openByDefault?: boolean
  onClick?: (isOpen: boolean) => void
  newItems?: number
  notUnmount?: boolean
  padding?: number
  emptyMessage?: string
  scrollToTop?: boolean
}

export const Toggle = ({header, emptyMessage, notUnmount, children, scrollToTop, newItems, padding, isOpen, openByDefault, onClick, isLoading}: IToggleProps) => {
  const toggleHeaderRef = React.useRef(null);
  const [stateIsOpen, setIsOpen] = React.useState<boolean>(openByDefault || false)

  React.useEffect(() => {
    if (typeof isOpen === "undefined") return;
    if (stateIsOpen === isOpen) return;
    setIsOpen(isOpen);
    // eslint-disable-next-line
  }, [isOpen])

  React.useEffect(() => {
    if (!scrollToTop) return;
    const toggleHeaderElement: any = (toggleHeaderRef||{}).current;
    if (isLoading || !stateIsOpen || !toggleHeaderElement) return;
    const rect = toggleHeaderElement.getBoundingClientRect();

    if (rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)) return;

    window.scrollTo({
      left: 0,
      top: rect.top + window.pageYOffset - 150,
      behavior: "smooth"
    });
    // eslint-disable-next-line
  }, [stateIsOpen, isLoading])

  const changeToggleHandler = () => {
    setIsOpen(!stateIsOpen)
    if (onClick) onClick(!stateIsOpen)
  }

  return (
    <StyledToggleWrapper>
      <StyledToggleHeader className='toggleHeader' ref={toggleHeaderRef} onClick={changeToggleHandler} isOpen={stateIsOpen}>
        <StyledToggleNewItemsWrapper>
          <Heading3>{header}</Heading3>
          {!!newItems &&
          <StyledToggleNewItems>
              <TextButton>
                {newItems} {newItems === 1 ? "новое сообщение" : "новых сообщений"}
              </TextButton>
          </StyledToggleNewItems>
          }
        </StyledToggleNewItemsWrapper>
        <StyledToggleIcon isOpen={stateIsOpen}/>
      </StyledToggleHeader>
      {(stateIsOpen || !!notUnmount)  && <StyledToggleBody padding={padding} isLoading={!!isLoading} isShow={stateIsOpen}>
        {isLoading ?
          <Loader type="simple"/>
          :
          <>
            {children}
            {emptyMessage && <StyledEmptyToggle className="emptyToggleMessage"><Heading5>{emptyMessage}</Heading5></StyledEmptyToggle>}
          </>
        }
      </StyledToggleBody>}
    </StyledToggleWrapper>
  )
};

export default Toggle;