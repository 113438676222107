import React          from 'react';
import NivaInput      from "../nivaInput/NivaInput";
import ReactDadataBox from 'react-dadata-box';
import {StyledDaData} from "./styles";

const dadataToken = `${process.env.REACT_APP_DADATA_TOKEN}`;

interface IDaDataProps {
  value?: string
  onChange?: (value: any) => void
  placeholder?: string
  errorMessage?: string
  isDisabled?: boolean
  isRequired?: boolean
}

const getDefaultDaData = async address => {
  const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
  const options: RequestInit = {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization": "Token " + dadataToken
    },
    body: JSON.stringify({query: address})
  }
  const response = await fetch(url, options);
  if (response.status === 200) {
    const {suggestions} = await response.json() || {};
    if (suggestions[0]) return suggestions[0];
    return undefined;
  }
  return undefined;
};


export const DaDataEditor = ({value, onChange, isRequired, isDisabled, placeholder, errorMessage}: IDaDataProps) => {
  const [stateDaDataValue, setDaDataValue] = React.useState<any>(undefined);
  const [stateInputValue, setInputValue] = React.useState<string>("");

  React.useEffect(() => {
    if (!value) return;
   getDefaultDaData(value)
     .then(daData => {
       if (!daData) return;
       setDaDataValue(daData)
     })
    // eslint-disable-next-line
  }, [value])

  React.useEffect(() => {
    const {unrestricted_value, value} = stateDaDataValue||{};
    if (!unrestricted_value || !value) return;
    setInputValue((stateDaDataValue || {}).value || "")
    if (!onChange) return;
    onChange(stateDaDataValue)
    // eslint-disable-next-line
  }, [stateDaDataValue])

  return (
    <StyledDaData>
      <ReactDadataBox
        onChange={(data) => {
          setDaDataValue(data)
        }}
        customInput={(params: any) => <NivaInput
          isDisabled={isDisabled}
          isControlled={true}
          value={stateInputValue}
          placeholder={placeholder}
          isRequired={!!isRequired}
          errorMessage={errorMessage}
          isInvalid={!!errorMessage}
          onChange={value => {
            params.onChange({target: {value}})
            setInputValue(value)
          }}/>}
        token={dadataToken}/>
    </StyledDaData>
  );
};
export default DaDataEditor;