type EventsType =
  "statisticsPage"
  | "applicationsLogPage"
  | "agreementsLogPage"
  | "prolongationsLogPage"
  | "searchContragentByIsn"
  | "settingsPage"
  | "reportsPage"
  | "precalculationPage"
  | "createApplicationPage"
  | "applicationPage"
  | "underwritingPage"
  | "agreementPage"
  | "invoicePage"

  | "approveRisks"
  | "editAgreement"

  | "setDiscount"
  | "createAgreement"
  | "underwritingRiskProperty"
  | "underwritingRiskLife"
  | "underwritingRiskTitul"
  | "prolongation"
  | "confirmLetter"
  | "sendAgreement"
  | "applyAllowance"
  | "precalcChangeAgent"
  | "promocode"

  | "disableUsers"

  | "agreementRiskChange"
  | "prolongationRiskChange"

  | "unknown"


// tag: 'underwritingRiskProperty',
// label: 'Согласование заявки по риску "Имущество"'
//
// tag: 'underwritingRiskLife',
// label: 'Согласование заявки по риску "Жизнь"'
//
// tag: 'underwritingRiskTitul',
// label: 'Согласование заявки по риску "Титул"'
//
// tag: 'statisticsPage',
// label: 'Доступ в раздел "Статистика"'
//
// tag: 'applicationsLogPage',
// label: 'Доступ в журнал заявок'
//
// tag: 'agreementsLogPage',
// label: 'Доступ в журнал договоров'
//
// tag: 'settingsPage',
// label: 'Доступ в раздел настроек ограничений'
//
// tag: 'reportsPage',
// label: 'Доступ в раздел отчетов'
//
// tag: 'precalculationPage',
// label: 'Доступ в раздел "Предаврительная заявка"'
//
// tag: 'createApplicationPage',
// label: 'Доступ в раздел "Расширенная форма заявки"'
//
// tag: 'applicationPage',
// label: 'Доступ к просмотру информации по заявке"'
//
// tag: 'underwritingPage',
// label: 'Доступ в раздел "Андеррайтинг"'
//
// tag: 'agreementPage',
// label: 'Доступ в раздел "Договор"'
//
// tag: 'setDiscount',
// label: 'Возможность применить скидку к заявке'
//
// tag: 'createAgreement',
// label: 'Возможность создать договор'
//
// tag: 'sendToUnderwriting',
// label: 'Возможность направить заявку на андеррайтинг'


export const rolesRightHandler = (event: EventsType | undefined, functions: string[], userType: "client" | "user" | "admin" | "kias", isAdmin: boolean) => {
  if (!event) return false;
  if (userType === "admin") return true;
  switch (event) {
    case "statisticsPage":
      return Boolean(functions.find(func => ["statisticsPage"].includes(func)));
    case "applicationsLogPage":
      return Boolean(functions.find(func => ["applicationsLogPage"].includes(func)));
    case "agreementsLogPage":
      return Boolean(functions.find(func => ["agreementsLogPage"].includes(func)));
    case "prolongationsLogPage":
      return Boolean(functions.find(func => ["prolongationsLogPage"].includes(func)));
    case "searchContragentByIsn":
      return Boolean(functions.find(func => ["searchContragentByIsn"].includes(func)));
    case "settingsPage":
      return Boolean(functions.find(func => ["settingsPage"].includes(func))) && isAdmin;
    case "reportsPage":
      return Boolean(functions.find(func => ["reportsPage"].includes(func)));
    case "precalculationPage":
      return Boolean(functions.find(func => ["precalculationPage"].includes(func)));
    case "createApplicationPage":
      return Boolean(functions.find(func => ["createApplicationPage"].includes(func)));
    case "invoicePage":
      return Boolean(functions.find(func => ["invoicePage"].includes(func)));
    case "underwritingPage":
      return Boolean(functions.find(func => ["underwritingPage"].includes(func)));
    case "applicationPage":
      return Boolean(functions.find(func => ["applicationPage"].includes(func)));
    case "agreementPage":
      return Boolean(functions.find(func => ["agreementPage"].includes(func)));
    case "underwritingRiskProperty":
      return Boolean(functions.find(func => ["underwritingRiskProperty"].includes(func)));
    case "underwritingRiskLife":
      return Boolean(functions.find(func => ["underwritingRiskLife"].includes(func)));
    case "underwritingRiskTitul":
      return Boolean(functions.find(func => ["underwritingRiskTitul"].includes(func)));
    case "approveRisks":
      return Boolean(functions.find(func => ["underwritingRiskTitul", "underwritingRiskLife", "underwritingRiskProperty"].includes(func)));
    case "editAgreement":
      return Boolean(functions.find(func => ["editAgreement"].includes(func)));
    case "createAgreement":
      return Boolean(functions.find(func => ["createAgreement"].includes(func)));
    case "setDiscount":
      return Boolean(functions.find(func => ["setDiscount"].includes(func)));
    case "prolongation":
      return Boolean(functions.find(func => ["prolongation"].includes(func)));
    case "disableUsers":
      return Boolean(functions.find(func => ["disableUsers"].includes(func)));
    case "confirmLetter":
      return Boolean(functions.find(func => ["confirmLetter"].includes(func)));
    case "sendAgreement":
      return Boolean(functions.find(func => ["sendAgreement"].includes(func)));
    case "promocode":
      return Boolean(functions.find(func => ["promocode"].includes(func)));
    case "applyAllowance":
      return Boolean(functions.find(func => ["applyAllowance"].includes(func)));
    case "precalcChangeAgent":
      return Boolean(functions.find(func => ["precalcChangeAgent"].includes(func)));
    case "agreementRiskChange":
      return Boolean(functions.find(func => ["agreementRiskChange"].includes(func)));
    case "prolongationRiskChange":
      return Boolean(functions.find(func => ["prolongationRiskChange"].includes(func)));
    default:
      return false;
  }
}

export const getClearPathname = (pathname: string) => {
  if (!pathname) return "unknown";
  if (pathname === "/") return "statistics";
  return pathname.split("/")[1]
}

export const getAllowedPathname = (functions, type, isAdmin, ignoreStatistics?: boolean) => {
  if (rolesRightHandler("statisticsPage", functions, type, isAdmin) && !ignoreStatistics) return "/";
  if (rolesRightHandler("applicationsLogPage", functions, type, isAdmin)) return "/agreementsLog/1";
  if (rolesRightHandler("agreementsLogPage", functions, type, isAdmin)) return "/contractsLog/1";
  if (isAdmin) return  "/roles";
  return undefined;
}

export const getRightNameByPathname: (pathname: string) => EventsType = (pathname) => {
  if (!pathname) return "unknown";
  switch (getClearPathname(pathname)) {
    case "statistics":
      return "statisticsPage"
    case "reports":
      return "reportsPage"
    case "createRequest":
      return "precalculationPage";
    // case "agreementEdit":
    //   return "editAgreement";
    case "agreementsLog":
    case "agreement":
      return "applicationsLogPage"
    case "contractsLog":
    case "contract":
      return "agreementsLogPage";
    case "prolongationLog":
      return "prolongationsLogPage";
    case "underwriting":
      return "underwritingPage";
    case "createInvoice":
      return "invoicePage";
    case "settings":
      return "settingsPage";
    default:
      return "unknown";
  }
}

export const getPageNameByPathname = (pathname: string) => {
  if (!pathname) return "unknown";
  switch (getClearPathname(pathname)) {
    case "statistics":
      return "Статистика"
    case "createRequest":
      return "Предварительный расчет";
    // case "agreementEdit":
    //   return "Изменение договора";
    case "agreementsLog":
      return "Журнал заявок"
    case "agreement":
      return "Заявка"
    case "contractsLog":
      return "Журнал договоров";
    case "prolongationLog":
      return "Журнал пролонгаций";
    case "contract":
      return "Договор";
    case "underwriting":
      return "Андеррайтинг";
    case "createInvoice":
      return "Счет по договору";
    case "roles":
      return "Роли и группы";
    case "settings":
      return "Настройки";
    case "reports":
      return "Отчеты";
    case "profile":
      return "Профиль";
    case "help":
      return "Помошь";
    case "page404":
      return "ошибка 404";
    case "myPolicies":
      return "Главная";
    case "myAnketa":
      return "Ваша анкета";
    case "myAgreements":
      return "Ваши договора";
    case "myAgreement":
      return "Оплатить период";
    default:
      return "unknown";
  }
}

export const PAGES_FOR_EVERYONE: string[] = ["profile", "help", "page404"]