import styled             from "styled-components";
import {black10, black40} from "../../../../globalStyledComponents";

export const StyledPaymentsWrapper = styled.div`
  margin-top: 3rem;
`;

export const StyledPaymentsHeader = styled.div`
  padding: 0 2.5rem 0 4rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 6fr;
  font-family: Verdana;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: ${black40};
  @media screen and (max-width: 767px) {
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const StyledPaymentsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const StyledPayButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledPaymentRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid ${black10};
  align-items: center;
  height: 5rem;

  padding: 2.5rem 2.5rem 2.5rem 4rem;

  @media screen and (max-width: 767px) {
    padding: 2rem 0;
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
    height: unset;
    .filledButton {
      width: 100%;
    }
  }
`;
