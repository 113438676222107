import React        from "react";
import NivaInput
                    from "../nivaInput/NivaInput";
import {
  StyledEnumFieldWrapper, StyledEnumItem, StyledEnumItemsWrapper, StyledEnumsLoader,
  StyledSelectEmpty,
} from "./styles";
import {
  Heading4
}                   from "../../globalStyledComponents";
import {SearchIcon} from "../../icons/ui/Search";
import Checkbox     from "../checkbox";
import FormMessage  from "../formMessage";
import {Loader}     from "../loader";

export interface IEnumItem {
  label: string
  value: string | number
  isDisabled?: boolean
}

interface EnumProps {
  items: IEnumItem[]
  value?: IEnumItem[]
  onChange?: (item: IEnumItem[]) => void
  placeholder?: string
  errorMessage?: string
  emptyMessage?: string
  isInvalid?: boolean
  isLoading?: boolean
  isRequired?:boolean
}

export const Enums = ({items, value, emptyMessage, isRequired, errorMessage, isLoading, isInvalid, onChange, placeholder}: EnumProps) => {
  const [stateSearch, setSearch] = React.useState<string>("");
  const [stateEnums, setEnums] = React.useState<IEnumItem[]>(value || []);


  React.useEffect(() => {
    if (!value) return;
    setEnums(value)
    // eslint-disable-next-line
  }, [(value || []).reduce((prev, {value}) => `${prev}${value}`, "")])

  React.useEffect(() => {
    if (onChange) return onChange(stateEnums)
    // eslint-disable-next-line
  }, [stateEnums.length])

  const selectEnumHandler = (item: IEnumItem) => {
    if (stateEnums.find(({value}) => item.value === value)) return setEnums(stateEnums.filter(({value}) => value !== item.value))
    return setEnums([...stateEnums, item])
  }

  const filterItems = (items || []).filter(({label}) => (label||"").toLocaleLowerCase().trim().includes(stateSearch.toLocaleLowerCase().trim()));

  return (
    <>
      <StyledEnumFieldWrapper>
        <NivaInput
          debounce={true}
          placeholder={placeholder}
          isRequired={!!isRequired}
          isInvalid={isInvalid}
          onChange={(value) => setSearch(value)}
          iconAfter={<SearchIcon/>}/>
        <StyledEnumItemsWrapper isInvalid={!!isInvalid}>
          {isLoading ?
            <StyledEnumsLoader>
              <Loader type="simple"/>
            </StyledEnumsLoader>
            :
            <>
              {!!filterItems.length ?
                <>
                  {filterItems.map(({label, value, isDisabled}, index) => (
                    <StyledEnumItem
                      key={`enum-item-${index}`}
                      onClick={isDisabled ? undefined : () => selectEnumHandler({label, value})}>
                      <Checkbox label={label} isDisabled={!!isDisabled} value={!!stateEnums.find((stateEnum) => value === stateEnum.value)}/>
                    </StyledEnumItem>
                  ))}
                </>
                :
                <StyledSelectEmpty>
                  <Heading4>{emptyMessage || "Нет элементов"}</Heading4>
                </StyledSelectEmpty>
              }
            </>
          }
        </StyledEnumItemsWrapper>
      </StyledEnumFieldWrapper>
      {errorMessage && <FormMessage message={errorMessage} type="error"/>}
    </>
  )
};

export default Enums;