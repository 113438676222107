import styled       from "styled-components";
import {green, red} from "../../globalStyledComponents";

export const StyledFormMessage = styled.div<{type: "error" | "success"}>`
 position: absolute;
 ${({type}) => 
    type === "error" ?
      `
      color: ${red}
      `
      :
      `
      color: ${green}
      `
  }
`;