import React                                         from 'react';
import {IFormField}                                  from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {fieldsValidate}                              from "../../../../../../formUtils/fieldsValidation";
import {useCallNotification}                         from "../../../../../../hooks/useCallNotification";
import {StyledStepFormHeader, StyledStepFormWrapper} from "../../../generalStep/styles";
import {Heading3}                                    from "../../../../../../globalStyledComponents";
import FieldsBuilder                                 from "../../../../../../uiKit/fieldsBuilder";
import Button                                        from "../../../../../../uiKit/button/button";

interface ISignByNumberProps {
  isSigningAnketa: boolean
  isLoading: boolean
  isnAnketa: string
  signAnketa: any
  person: any
  setSubmitType?: any
  formId: string
  skipSigning?: boolean
  isDeclarationForm?: boolean
}

const SignByNumber = ({signAnketa, person, isnAnketa, formId, setSubmitType, isLoading, isSigningAnketa, skipSigning, isDeclarationForm}: ISignByNumberProps) => {
  const {setNotification} = useCallNotification();
  const [stateVerifyCode, setVerifyCode] = React.useState<string>("");
  const {phoneMobile} = person || {};

  React.useEffect(() => {
    if (!stateVerifyCode || fieldsValidate({value: stateVerifyCode, validationType: "code"})) return;
    signAnketa({
      variables: {
        isn: isnAnketa || person?.isn,
        code: stateVerifyCode,
        declMode: !!skipSigning,
        afterProlong: !isnAnketa,
        isDeclarationForm: !!isDeclarationForm
      }
    }).then(() => setNotification({type: "success", text: "Анкета подтверждена"}))
      .catch(error => setNotification({type: "error", text: error}))
    // eslint-disable-next-line
  }, [stateVerifyCode])

  const sendCodeForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "phone",
          validationType: "phone",
          mask: "+7 (999) 999-99-99",
          isRequired: true,
          defaultValue: phoneMobile || "",
          isDisabled: true,
          errorMessage: "Введите телефон",
          placeholder: "Номер телефона"
        },
        {
          type: "input",
          name: "code",
          mask: "999999",
          isDisabled: isSigningAnketa,
          onChange: (value) => setVerifyCode(value),
          validationType: "code",
          placeholder: "Код из СМС"
        },
      ]
    }
  ]
  return (
    <StyledStepFormWrapper>
      <StyledStepFormHeader>
        <Heading3>Введите номер телефона, чтобы мы могли выслать Вам смс с кодом подтверждения</Heading3>
      </StyledStepFormHeader>
      <FieldsBuilder formFields={sendCodeForm}/>
      <Button
        appearance="transparent"
        isLoading={isLoading}
        isDisabled={isSigningAnketa}
        onClick={() => setSubmitType("verify")}
        form={formId}
        width="100%">
        Выслать код
      </Button>
    </StyledStepFormWrapper>
  );
};

export default SignByNumber;