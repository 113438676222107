import React                       from 'react';
import {Heading3}                  from "../../../../globalStyledComponents";
import Button                      from "../../../../uiKit/button/button";
import Modal, {ModalProps}         from "../../../../uiKit/modal";
import {StyledButtonsGroup}        from "../header/styles";
import {StyledDeleteRoleModalBody} from "./styles";
import {useHistory}                from "react-router";
import {DELETE_ROLE}               from "../../../../utils/mutations";
import {useMutation}         from "@apollo/react-hooks";
import {useCallNotification} from "../../../../hooks/useCallNotification";


export const DeleteRoleModal = ({onClose, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id} = data || {};
  const history = useHistory();
  const [deleteRole, {loading: loadingDeleteRole}] = useMutation(DELETE_ROLE, {refetchQueries: ["roles"]});
  const deleteRoleHandler = () => {
    deleteRole({
      variables: {
        id
      }
    }).then(() => {
      onClose()
      history.push("/roles")
    })
      .catch(error => setNotification({type: "error", text: error}))
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen} header={undefined}>
      <StyledDeleteRoleModalBody>
        <Heading3>Вы действительно хотите удалить роль?</Heading3>
        <StyledButtonsGroup>
          <Button appearance="filled" onClick={deleteRoleHandler} isLoading={loadingDeleteRole}>
            Да
          </Button>
          <Button onClick={() => onClose()} appearance="transparent" isLoading={loadingDeleteRole}>
            Нет
          </Button>
        </StyledButtonsGroup>
      </StyledDeleteRoleModalBody>
    </Modal>
  )
};

export default DeleteRoleModal;