import React               from "react";
import {useParams}         from "react-router";
import RoleByIdHeader      from "./components/header";
import {
  StyledRoleContentWrapper,
  StyledRoleForm,
  StyledRoleName,
}                             from "./styles";
import {Heading3} from "../../globalStyledComponents";
import Form                   from '@atlaskit/form';
import {IEnumItem}             from "../../uiKit/enums";
import SelectedFunctions       from "./components/selectedFunctions";
import DefaultUserRolePage         from "./components/defaultUserRolePage";
import {IFormField}                from "../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder               from "../../uiKit/fieldsBuilder";
import {getFormError}              from "../../formUtils/getFormError";
import {FUNCTIONS, ME, ROLE_BY_ID} from "../../utils/queries";
import {useQuery, useMutation}     from "@apollo/react-hooks";
import {Loader}                    from "../../uiKit/loader";
import {CREATE_UPDATE_ROLE}        from "../../utils/mutations";
import {ErrorMessage}              from "../../components/errorMessage";
import {useCallNotification}       from "../../hooks/useCallNotification";
import {getFormValue}              from "../../formUtils/getFormValue";
import Button                      from "../../uiKit/button/button";
import {UsersWithSameRole}         from "./components/UsersWithSameRoleModal";

export const RoleById = () => {
  const {id} = useParams();
  const {setNotification} = useCallNotification();
  const [stateSelectedFunctions, setSelectedFunctions] = React.useState<IEnumItem[]>([]);
  const [stateUsersSameRole, setUsersSameRole] = React.useState<boolean>(false);
  const {data: dataRole, loading: loadingRole, error: errorRole} = useQuery(ROLE_BY_ID, {
    variables: {
      id: [id]
    }
  });
  const [updateRole,{loading: loadingUpdateRole}] = useMutation(CREATE_UPDATE_ROLE);
  const {data: dataFunctions, loading: loadingFunctions, error: errorFunctions} = useQuery(FUNCTIONS);
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {name, functions, group, readOnly, users_count} = ((dataRole || {}).roles || [])[0] || {};
  const roleFunctions: IEnumItem[] = (functions || []).map(({id, name}) => ({label: name, value: id}));
  const {role, isAdmin} = (dataUser || {}).me || {};
  const functionsMe: any[] = ((role || {}).functions || []).map(({id}) => id);
  const functionsAll: IEnumItem[] = ((dataFunctions || {}).functions || []).map(({id, name}) => ({
    label: name,
    value: id
  }));
  const functionsList: IEnumItem[] = isAdmin ? functionsAll : functionsAll.map(func => {
    const {value} = func;
    if (functionsMe.includes(value)) return func;
    return {...func, isDisabled: true};
  })

  React.useEffect(() => {
    if (!roleFunctions || roleFunctions.length === 0) return;
    setSelectedFunctions(roleFunctions);
    // eslint-disable-next-line
  }, [roleFunctions.reduce((prev, {value}) => `${prev}${value}`, "")])

  const formRoleName: IFormField[] = [
    {
      fieldMargin: 0,
      fieldItems: {
        type: "input",
        name: "roleName",
        defaultValue: name,
        isRequired: true,
        placeholder: "Название роли",
        errorMessage: "Введите название"
      }
    }
  ]
  const formFunctions: IFormField[] = [
    {
      fieldItems: {
        type: "enums",
        name: "functions",
        isRequired: true,
        isLoading: loadingFunctions,
        defaultValue: stateSelectedFunctions,
        placeholder: "Поиск по функциям",
        onChange: (value) => setSelectedFunctions(value),
        items: functionsList,
        errorMessage: "Выберите функции"
      }
    },
  ]

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {roleName} = getFormValue(data);
    updateRole({
      variables: {
        id,
        name: roleName,
        group: (group||{}).id,
        functions: stateSelectedFunctions.map(({value}) => value)
      }
    }).then(() => setNotification({type: "success", text: "Роль успешно обновлена"}))
      .catch(error => setNotification({type: "error", text: error}))
  };

  const handleDeleteFunction = (deleteValue: string | number) => {
    setSelectedFunctions(stateSelectedFunctions.filter(({value}) => value !== deleteValue))
  };

  if (errorFunctions) setNotification({type: "error", text: errorFunctions})
  if (errorRole) return <ErrorMessage error={errorRole}/>
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (loadingRole || loadingUser) return <Loader type="simple"/>
  return (
    <>
      <RoleByIdHeader isLoading={loadingUpdateRole} name={name} readOnly={readOnly}/>
      {!readOnly &&
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps} id="roleByIdChangeForm">
            <StyledRoleName>
              <div>
                <Heading3>Название роли</Heading3>
                <FieldsBuilder formFields={formRoleName}/>
              </div>
              <div>
                <Heading3>Количество пользователей с данной ролью: <Button appearance='link' type='button' onClick={() => setUsersSameRole(true)}>{users_count}</Button></Heading3>
              </div>
            </StyledRoleName>

            <StyledRoleContentWrapper>
              <StyledRoleForm>

                <Heading3>Доступные функции</Heading3>
                <FieldsBuilder formFields={formFunctions}/>
              </StyledRoleForm>

              <SelectedFunctions items={stateSelectedFunctions}
                                 onDelete={({value}) => handleDeleteFunction(value)}/>
            </StyledRoleContentWrapper>
          </form>
        )}
      </Form>}
      {readOnly && <DefaultUserRolePage name={name} functions={roleFunctions}/>}
      <UsersWithSameRole onClose={() => setUsersSameRole(false)} isOpen={stateUsersSameRole} header='Пользователи с данной ролью'/>
    </>
  )
};

export default RoleById;