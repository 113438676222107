import React                                       from 'react';
import RolesHeader                                 from "./components/header";
import Tabs, {ITabsItem}                           from "../../uiKit/tabs";
import {StyledRolesTabWrapper, StyledRolesWrapper} from "./styles";
import RolesTab                                    from "./components/rolesTab";
import GroupsTab                                   from "./components/groupsTab";
import {useQuery}                                  from "@apollo/react-hooks";
import {ME}                                        from "../../utils/queries";
import {ErrorMessage}                              from "../../components/errorMessage";
import {Loader}                                    from "../../uiKit/loader";
import {useHistory}                                from "react-router";

const tabs: ITabsItem[] = [
  {label: "Роли пользователей", value: "roles"},
  {label: "Группы пользователей", value: "groups"}
]

export const Roles = () => {
  const history = useHistory();
  const activeTab: ITabsItem | undefined = tabs.find(({value}) => value === localStorage.getItem("roleTab"));
  const [stateTabs, setTabs] = React.useState<ITabsItem>(activeTab || tabs[1])
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {isAdmin} = (dataUser || {}).me || {};

  if (loadingUser) return <Loader type="simple"/>
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (!isAdmin){
    history.push("/page404/rights")
    return null;
  }
  return (
    <StyledRolesWrapper>
      <RolesHeader tab={stateTabs}/>
      <StyledRolesTabWrapper>
        <Tabs tabs={tabs} activeTab={stateTabs} onChange={(tab) => {
          localStorage.setItem("roleTab", String(tab.value))
          setTabs(tab)}
        } tabWidth={18}/>
      </StyledRolesTabWrapper>
      {stateTabs.value === "roles" && <RolesTab/>}
      {stateTabs.value === "groups" && <GroupsTab/>}
    </StyledRolesWrapper>
  )
};

export default Roles;