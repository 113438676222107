import styled from "styled-components";

export const StyledRolesTabWrapper = styled.div`
  width: 50%;
  
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StyledRolesWrapper = styled.div`
  @media screen and (max-width: 767px) {
    padding-bottom: 4rem;
  }
`;