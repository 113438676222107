import React                                                                                                       from 'react';
import Modal, {ModalProps}                                                                                         from "../../../../uiKit/modal";
import {useLazyQuery}                                                                                              from "@apollo/react-hooks";
import {USER_GROUP_HISTORY}                                                                                        from "../../../../utils/queries";
import {ErrorMessage}                                                                                                                              from "../../../../components/errorMessage";
import {StyledGroupHistoryHeaderItem, StyledGroupHistoryTableWrapper, StyledGroupHistoryWrapper} from "./styles";
import {Heading3, Text4}                                                                                                                           from "../../../../globalStyledComponents";


const headsList = ["Измененное поле", "Старое значение", "Новое значение"];

export const ChangeLogModal = ({onClose, header, data, isOpen}: ModalProps) => {
  const {id, model} = data || {};

  const [getHistory, {data: dataHistory, loading: loadingHistory, error: errorHistory}] = useLazyQuery(USER_GROUP_HISTORY);

  React.useEffect(() => {
    if (!isOpen || !id) return;
    getHistory({
      variables: {
        id,
        model
      }
    })
  }, [id, model, isOpen, getHistory])

  const rows = (dataHistory?.changeLog || []).map(({createdAt, fields, user}) => {
    return {
      title: `Изменения от: ${createdAt}`,
      user: `Пользователь: ${user?.name}`,
      rows: (fields || []).map((({label, oldValue, newValue}) => ({
        label,
        oldValue: oldValue || "Отсутствовало",
        newValue: newValue || "Отсутствует"
      })))
    }
  })

  if (errorHistory) return <ErrorMessage error={errorHistory}/>

  return (
    <Modal width={130} onClose={onClose} isOpen={isOpen} header={header} isLoading={loadingHistory}>
      <StyledGroupHistoryWrapper>
        <StyledGroupHistoryHeaderItem><Text4>Дата/Пользователь</Text4></StyledGroupHistoryHeaderItem>
        <StyledGroupHistoryTableWrapper>
          {headsList.map((head, index) => <StyledGroupHistoryHeaderItem key={`history-head-${index}`}><Text4>{head}</Text4></StyledGroupHistoryHeaderItem>)}
        </StyledGroupHistoryTableWrapper>
        {!rows.length && <Heading3>Нет изменений</Heading3>}
        {!!rows.length &&
            <>
              {rows.map(({title, user, rows}, index) => <React.Fragment key={`history-row-${index}`}><div>
                <Text4>{title}</Text4>
                <Text4>{user}</Text4>
              </div>
                <StyledGroupHistoryTableWrapper>
                  {rows.map(({label, oldValue, newValue}) => <>
                    <Text4>{label}</Text4>
                    <Text4>{oldValue}</Text4>
                    <Text4>{newValue}</Text4>
                  </>)}
                </StyledGroupHistoryTableWrapper>
                </React.Fragment>)}
            </>
        }
      </StyledGroupHistoryWrapper>
    </Modal>
  )
};

export default ChangeLogModal;