import React                                                 from 'react';
import {Heading2}                                            from "../../globalStyledComponents";
import UnderwritingHeader                                    from "./components/header";
import Button                                                from "../../uiKit/button/button";
import {ArrowIcon}                                           from "../../icons/ui/Arrow";
import {StyledUnderwritingWrapper}                           from "../applicationById/styles";
import {StyledUnderwritingHeader}                            from "./styles";
import {useHistory, useParams}                               from "react-router";
import TableArea
                                                             from "../applicationById/components/tableArea";
import Form                                                  from '@atlaskit/form';
import {getFormError}                                        from "../../formUtils/getFormError";
import {getFormValue, stringToNumber}                        from "../../formUtils/getFormValue";
import {useMutation, useQuery}                               from "@apollo/react-hooks";
import {DOCS, GET_AGREEMENT, ME}                             from "../../utils/queries";
import {
  CALC_AGREEMENT,
  CHANGE_STATUS_RISK, NOTIFICATION,
  UPDATE_AGREEMENT_CALC_FULL
}                                                                                    from "../../utils/mutations";
import {agreementUnderwritingFilling, getRestrictionsObject} from "../../helpers/buildTemplateForBack";
import {ErrorMessage}                                                                from "../../components/errorMessage";
import {
  CommentsToggle,
  DocumentsToggle
}                                                            from "../applicationById/components/toggleArea/components/rightToggles";
import {StyledToggleAreaLeft, StyledToggleAreaWrapper}       from "./components/underwritingForm/styles";
import UnderwritingForm                                      from "./components/underwritingForm";
import {useCallNotification}                                 from "../../hooks/useCallNotification";
import BigDiscountModal                                      from "./components/BigDiscountModal";

export const Underwriting = () => {
  const {id} = useParams();
  const history = useHistory();
  const {setNotification} = useCallNotification();
  const [stateIsFormToggleOpen, setIsFormToggleOpen] = React.useState<boolean>(true);
  const [stateDropDownIsOpen, setDropDownIsOpen] = React.useState<boolean>(false);
  const [stateSubmitType, setSubmitType] = React.useState<"risks" | undefined>(undefined);
  const [stateDiscountModal, setDiscountModal] = React.useState<boolean>(false);

  const {data: dataAgreement, loading: loadingAgreement, error: errorAgreement} = useQuery(GET_AGREEMENT, {variables: {isn: id}});
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {variables: {isn: id}});
  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL);
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const [calcAgreement, {loading: loadingCalcAgreement}] = useMutation(CALC_AGREEMENT, {refetchQueries: ["agreementCalc", "anketaTemplate"]});
  const [changeStatus, {loading: loadingChangeStatus}] = useMutation(CHANGE_STATUS_RISK, {refetchQueries: ["docs", "agreementCalc"]});
  const [notification, {loading: loadingNotification}] = useMutation(NOTIFICATION);
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {risks: docRisks} = (docsList || [])[0] || {};
  const agreementData = (dataAgreement || {}).agreementCalc || {};
  const {restrictions} = (dataUser || {}).me || {};
  const {maxDiscount} = getRestrictionsObject(restrictions);

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {agreementVariables, riskVariables, discountsList} = agreementUnderwritingFilling(getFormValue(data), agreementData, docRisks);
    const isOkDiscount: boolean = isNaN(maxDiscount) ? true : discountsList.reduce<boolean>((prev, next) => {
      return !prev ? false : stringToNumber(maxDiscount) >= next;
    }, true);
    if (!isOkDiscount) return setDiscountModal(true);
    const oldAgreement = {...agreementData, attribs: agreementData.attribs.map(({__typename, ...other}) => other)};
    agreementCalc({
      variables: agreementVariables
    })
      .then(({data: {agreementCalcFull}}) => {
        const {isn: isnAgreement} = agreementCalcFull || {};
        calcAgreement({variables: {isn: isnAgreement}})
          .then(() => {
            if (stateSubmitType === "risks") return changeStatus({variables: {doc: id, risks: riskVariables}})
              .then(() => {
                notification({
                  variables: {
                    isn: id,
                    type: "change_document_status"
                  }
                })
                  .then(() => {
                    setDropDownIsOpen(false);
                    setSubmitType(undefined);
                    setNotification({type: "success", text: "Данные успешно обновлены"});
                  })
                  .catch(error => setNotification({type: "error", text: error}));
              })
              .catch(error => setNotification({type: "error", text: error}));
            return setNotification({type: "success", text: "Данные успешно обновлены"});
          })
          .catch(error => {
            setNotification({type: "error", text: error})
            agreementCalc({
              variables: oldAgreement
            })
              .catch(error => setNotification({type: "error", text: error}))
          })
      })
      .catch(error => setNotification({type: "error", text: error}));
  };


  const updateLoading = loadingAgreementCalc || loadingNotification ||  loadingCalcAgreement || loadingChangeStatus;

  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>;
  if (errorDocs) return <ErrorMessage error={errorDocs}/>;
  if (errorUser) return <ErrorMessage error={errorUser}/>;
  return (
    <>
      <StyledUnderwritingHeader>
        <Button appearance="text" onClick={() => history.goBack()}
                iconBefore={<ArrowIcon direction="left"/>}>Назад</Button>
        <Heading2>Андеррайтинг заявки</Heading2>
      </StyledUnderwritingHeader>
      <StyledUnderwritingWrapper>
        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps} id="underwritingForm">
              <UnderwritingHeader
                stateDropDownIsOpen={stateDropDownIsOpen}
                setDropDownIsOpen={setDropDownIsOpen}
                setSubmitType={setSubmitType}
                isLoading={updateLoading}/>
              <UnderwritingForm
                setIsFormToggleOpen={setIsFormToggleOpen}
                stateIsFormToggleOpen={stateIsFormToggleOpen}
              />
            </form>
          )}
        </Form>
        <TableArea isLoading={loadingDocs || loadingAgreement}/>
        <StyledToggleAreaWrapper>
          <StyledToggleAreaLeft>
            <DocumentsToggle header="Прикрепленные документы" type="C" id={id}/>
          </StyledToggleAreaLeft>
          <CommentsToggle header="Обсуждение"/>
        </StyledToggleAreaWrapper>
      </StyledUnderwritingWrapper>
      <BigDiscountModal isOpen={stateDiscountModal} onClose={() => setDiscountModal(false)} header="Внимание"/>
    </>
  );
};

export default Underwriting;