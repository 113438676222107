import styled           from "styled-components";
import {grey20, grey40} from "../../globalStyledComponents";

export const StyledLoadingString = styled.div<{ width: number, height: number }>`
  ${({width, height}) => `
    width: ${width}rem;
    height: ${height}rem;
  `}

  animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading-animation;
    animation-timing-function: ease-in;
    background: linear-gradient(to right, ${grey20} 8%, ${grey40} 18%, ${grey20} 33%);
    background-size: 80rem 10rem;
    position: relative;

@keyframes loading-animation{
    0%{
        background-position: -40rem 0
    }
    100%{
        background-position: 40rem 0
    }
}
`;