import React                          from 'react';
import Modal, {ModalProps}            from "../../../../uiKit/modal";
import Button                         from "../../../../uiKit/button/button";
import {useHistory, useParams}        from "react-router";
import {IFormField}                   from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                  from "../../../../uiKit/fieldsBuilder";
import {StyledButtonsGroup}           from "../../../roles/components/header/styles";
import {getFormError}                 from "../../../../formUtils/getFormError";
import {getFormValue, getSelectValue, stringToNumber} from "../../../../formUtils/getFormValue";
import Form                           from '@atlaskit/form';
import moment                         from "moment";
import {useCallNotification}          from "../../../../hooks/useCallNotification";
import {useMutation, useQuery}                   from "@apollo/react-hooks";
import {AGREEMENT_PROLONGATION} from "../../../../utils/mutations";
import {GET_AGREEMENT_AGENTS}                    from "../../../../utils/queries";
import {radioBoolean} from "../../../createRequest/staticData";

const ProlongationModal = ({header, isOpen, data, onClose}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id} = useParams();
  const history = useHistory();

  const {data: dataAgents, loading: loadingAgents} = useQuery(GET_AGREEMENT_AGENTS, {variables: {agreementIsn: id}});

  const [agreementProlongation, {loading: loadingAgreementProlongation}] = useMutation(AGREEMENT_PROLONGATION);

  const {defaultAgentSelectValue} = data || {};
  const selectAgents = ((dataAgents||{}).getAgent||[]).map(({SUBJISN, AGENTNAME}) => ({label: AGENTNAME, value: SUBJISN}));

  const agentsItems = [...selectAgents, ...(defaultAgentSelectValue ? [defaultAgentSelectValue] : [])];

  const date = moment().format("DD.MM.YYYY");

  const form: IFormField[] = [
    {
      fieldItems: {
        name: "limitSum",
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите страховую сумму",
        placeholder: "Страховая сумма"
      }
    },
    {
      fieldItems: {
        name: "signDate",
        type: "date",
        isRequired: true,
        validationType: "minMaxDate",
        minDate: date,
        defaultValue: date,
        errorMessage: "Выберите дату подписания",
        placeholder: "Дата подписания"
      }
    },
    {
      isHidden: !agentsItems.length,
      fieldItems: {
        name: "agentIsn",
        type: "select",
        items: agentsItems,
        isClearable: true,
        defaultValue: defaultAgentSelectValue,
        errorMessage: "Выберите агента",
        placeholder: "Агент"
      }
    },
  ]

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValue(data);
    const {limitSum} = formData;

    agreementProlongation({
      variables: {
        isn: id,
        ...formData,
        limitSum: Number(stringToNumber(limitSum))
      }
    })
      .then(({data: {agreementProlongation}}) => {
        if (!agreementProlongation) return setNotification({type: "error", text: "Отсутствует id договора"})
        return history.push(`/contract/${agreementProlongation}`)
      })
      .catch(error => {
        onClose();
        setNotification({type: "error", text: error})
      })
  };

  return (
    <Modal
      isLoading={loadingAgents}
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                type="submit"
                width="100%"
                isLoading={loadingAgreementProlongation}
                appearance="filled">
                Продлить
              </Button>
              <Button
                onClick={() => onClose()}
                width="100%"
                isLoading={loadingAgreementProlongation}
                appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default ProlongationModal;