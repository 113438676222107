import React                                                           from "react";
import {IFormField}                                                    from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {StyledStepFormHeader, StyledStepFormWrapper}                   from "../../../generalStep/styles";
import {Heading3, TextButton}                                          from "../../../../../../globalStyledComponents";
import FieldsBuilder                                                   from "../../../../../../uiKit/fieldsBuilder";
import {StyledDownloadPdf, StyledFileName, StyledOnlineFillingWrapper} from "./styles";
import Button                                                          from "../../../../../../uiKit/button/button";
import {DownloadIcon}                                                  from "../../../../../../icons/ui/Download";
import {FileIcon}                                                      from "../../../../../../icons/ui/File";
import {useMutation, useLazyQuery}                                     from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, ANKETA_TEMPLATE}                              from "../../../../../../utils/queries";
import {Loader}                                                        from "../../../../../../uiKit/loader";
import {templateToFormRebuild}                                         from "../../../../../../helpers/templateToFormRebuild";
import {getFormError}                              from "../../../../../../formUtils/getFormError";
import {getFormValue}                              from "../../../../../../formUtils/getFormValue";
import StoreAgreementForm                          from "../../../../store";
import Form                                        from '@atlaskit/form';
import {SIGN_ANKETA, UPDATE_ANKETA, VERIFY_ANKETA} from "../../../../../../utils/mutations";
import {anketaPartsBuilder, anketaSettingsBuilder} from "../../../../../../helpers/buildTemplateForBack";
import {fileDownloader}                            from "../../../../../../helpers/fileDownloader";
import {useCallNotification}                       from "../../../../../../hooks/useCallNotification";
import HealthForm                                  from "./healthForm";
import {AnketaPaginationButtons}                   from "../../index";
import SignByNumber                                from "../signByNumber";
import {getFormAdditionalInsurance}                from "./staticData";

export const OnlineFillingForm = ({person, onClose}) => {
  const {setNotification} = useCallNotification();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const [stateSubmitType, setSubmitType] = React.useState<"download" | "verify" | undefined>(undefined)
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [downloadAnketa, {data: dataDownloadAnketa, loading: loadingDownloadAnketa, error: errorDownloadAnketa}] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [updateAnketa, {data: dataUpdateAnketa, loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const [verifyAnketa, {loading: loadingVerifyAnketa}] = useMutation(VERIFY_ANKETA);
  const [signAnketa, {data: dataSignAnketa, loading: loadingSignAnketa}] = useMutation(SIGN_ANKETA);
  const urlFile = ((dataDownloadAnketa || {}).documentForm || {}).url;
  const isnAnketa = ((dataUpdateAnketa || {}).anketa || {}).isn;
  const {templateVariables, peoples} = agreementFormState || {};
  const {fullName, isn: isnPerson} = person || {};
  const isSigningAnketa: boolean = !!(dataSignAnketa || {}).anketaSign;
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const healthForm: IFormField[] = ((templateToFormRebuild(parts).find(({id}) => id === "insuranceForm") || {}).form || []);
  const {
    CheckRes: scoringCheck,
    ResultScoring,
    Koef_Height_weight: heightWeight,
    Koef_Pressure: pressure
  } = (dataSignAnketa || {}).anketaSign || {};
  const scoringResult: string | undefined = heightWeight === "МО" || pressure === "МО" ? `${ResultScoring} Необходимо прохождение медицинского обследования.` : ResultScoring;

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: templateVariables})
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  const saveAnketaHandler = () => {
    if (!isnAnketa) return setNotification({type: "error", text: "Отсутствует isn анкеты"})
    if (!isSigningAnketa) return setNotification({type: "error", text: "Анкета еще не подтверждена"})
    updateAgreementForm({
      peoples: (peoples || []).map(person => {
        const {isn} = person || {};
        if (isn === isnPerson) return {
          ...person,
          isnAnketa,
          scoringCheck,
          scoringResult,
          heightWeight,
          pressure,
          fillingType: "online"
        };
        return person;
      })
    });
    onClose();
  }

  const onFormSubmit = (data) => {
    if (isSigningAnketa) return saveAnketaHandler();
    const error = getFormError(data);
    if (error) return error;
    const partsWithValue: any[] = anketaPartsBuilder({...agreementFormState, ...person, ...getFormValue(data)}, parts);
    const settingsWithValue: any[] = anketaSettingsBuilder({...agreementFormState, ...person, ...getFormValue(data)}, settings);
    updateAnketa({
      variables: {
        isn: isnAnketa,
        clientIsn: isnPerson,
        settings: settingsWithValue,
        parts: partsWithValue,
        templateVariables
      }
    }).then(({data: {anketa: {isn}}}) => {
      if (stateSubmitType === "download") {
        downloadAnketa({
          variables: {
            isn
          }
        })
        return setSubmitType(undefined)
      }
      if (stateSubmitType === "verify") {
        verifyAnketa({
          variables: {
            clientIsn: isnPerson,
            isn
          }
        })
          .catch(error => setNotification({type: "error", text: error}))
        return setSubmitType(undefined)
      }
      return saveAnketaHandler();
    }).catch(error => setNotification({type: "error", text: error}))
  }

  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})
  if (errorDownloadAnketa) setNotification({type: "error", text: errorDownloadAnketa})
  if (loadingAnketaTemplate) return <Loader type="simple"/>
  return (
    <StyledOnlineFillingWrapper>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps} id="lifeAnketaForm">
            <StyledStepFormWrapper>
              <StyledStepFormHeader>
                <Heading3>Общие данные</Heading3>
              </StyledStepFormHeader>
              <HealthForm healthPartForm={healthForm}/>
            </StyledStepFormWrapper>
            <StyledStepFormWrapper>
              <StyledStepFormHeader>
                <Heading3>Дополнительная информация о страхователе</Heading3>
              </StyledStepFormHeader>
              <FieldsBuilder formFields={getFormAdditionalInsurance(parts)}/>
              <Heading3>Вы можете скачать PDF с заполненной анкетой</Heading3>
              <StyledDownloadPdf>
                <StyledFileName>
                  <FileIcon/>
                  <TextButton>{fullName || "заполни 1 шаг 🧐"}</TextButton>
                </StyledFileName>
                <Button appearance="link" isLoading={loadingUpdateAnketa || loadingDownloadAnketa}
                        onClick={() => setSubmitType("download")} form="lifeAnketaForm"
                        iconBefore={<DownloadIcon/>}>Скачать</Button>
              </StyledDownloadPdf>
            </StyledStepFormWrapper>

          </form>
        )}
      </Form>

      <SignByNumber
        isSigningAnketa={isSigningAnketa}
        isLoading={loadingVerifyAnketa || loadingSignAnketa || loadingUpdateAnketa}
        isnAnketa={isnAnketa}
        signAnketa={signAnketa}
        formId="lifeAnketaForm"
        person={person}
        setSubmitType={setSubmitType}
      />
      <AnketaPaginationButtons onClose={onClose} formId={"lifeAnketaForm"}/>
    </StyledOnlineFillingWrapper>
  )
}

export default OnlineFillingForm;
