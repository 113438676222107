import React           from "react";
import {Route, Switch} from "react-router";
import {useHistory}    from "react-router";
import Statistics      from "../../pages/statistics";
import CreateRequest   from "../../pages/createRequest";
import AgreementsLog   from "../../pages/agreementsLog";
import Underwriting    from "../../pages/underwriting";
import Roles           from "../../pages/roles";
import RoleById        from "../../pages/roleById";
import Settings        from "../../pages/settings";
import Profile         from "../../pages/profile";
import Help            from "../../pages/help";
import MyPolicies      from "../../pages/myPolicies";
import CreateInvoice                  from "../../pages/createInvoice";
import AnketasLog, {ContragentAnketa} from "../../pages/fillAnketa";
import ContractById                   from "../../pages/contractById";
import AgreementById   from "../../pages/applicationById";
import {useQuery}      from "@apollo/react-hooks";
import {ME}            from "../../utils/queries";
import {ErrorMessage}  from "../errorMessage";
import {
  getClearPathname, getPageNameByPathname, getRightNameByPathname,
  PAGES_FOR_EVERYONE, rolesRightHandler
}                      from "../../helpers/rolesRightHandler";
import Page404         from "../../pages/404";
import {MyAgreements}  from "../../pages/myAgreements";
import MyAgreementById from "../../pages/myAgreementById";
import Reports         from "../../pages/reports";
import PageWork        from "../../pages/engineeringWorks";

const ContractsLogOtherType = () => <AgreementsLog type="A"/>;
const AgreementsLogOtherType = () => <AgreementsLog type="C"/>;
const ContractsProlongationLogOtherType = () => <AgreementsLog type="P"/>;

export const RootRedirect = () => {
  const history = useHistory();
  const {pathname} = history.location;

  const haveNotRights = () => history.push(`/page404/rights`)

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const clearPathname: string = getClearPathname(pathname);
  const statusWork: string = `${process.env.REACT_APP_ENGINEERING_WORK}`;

  const haveWork = () => history.push(`/pageWork/rights`)

  React.useEffect(() => {
    document.title = `Абсолют | ${getPageNameByPathname(pathname)}`;
    if (!type || PAGES_FOR_EVERYONE.includes(clearPathname)) return;
    if (isAdmin && clearPathname === "roles") return;
    if (type === "client" && ["myPolicies", "myAnketa", "myAgreements", "myAgreement"].includes(clearPathname)) return;
    if (!rolesRightHandler(getRightNameByPathname(pathname), functions, type, isAdmin)) return haveNotRights();
    if (statusWork === "yes") return haveWork();
    // eslint-disable-next-line
  }, [pathname, type])

  if (!localStorage.getItem('rolesIsn')) history.push("/authorization");
  if (errorUser) return <ErrorMessage error={errorUser}/>

  return (
    <Switch>
      <Route exact path="/" component={Statistics}/>
      <Route path="/createRequest/:id?/:type?/:stepsStack?" component={CreateRequest}/>
      <Route exact path="/agreementsLog/:page" component={AgreementsLogOtherType}/>
      <Route exact path="/agreement/:id" component={AgreementById}/>
      <Route exact path="/underwriting/:id" component={Underwriting}/>
      <Route exact path="/contractsLog/:page" component={ContractsLogOtherType}/>
      <Route exact path="/prolongationLog/:page" component={ContractsProlongationLogOtherType}/>
      <Route exact path="/contract/:id" component={ContractById}/>
      <Route exact path="/createInvoice/:id" component={CreateInvoice}/>
      <Route exact path="/roles" component={Roles}/>
      <Route exact path="/roles/:id" component={RoleById}/>
      <Route path="/settings" component={Settings}/>
      <Route path="/reports" component={Reports}/>
      <Route path="/profile" component={Profile}/>
      <Route path="/help" component={Help}/>
      <Route path="/page404/:type" component={Page404}/>
      <Route path="/pageWork/:type" component={PageWork}/>

      <Route path="/myPolicies" component={MyPolicies}/>
      <Route exact path="/myAgreements" component={MyAgreements}/>
      <Route exact path="/myAgreement/:id" component={MyAgreementById}/>
      <Route exact path="/myAnketa" component={AnketasLog}/>
      <Route exact path="/myAnketa/currentAnketa" component={ContragentAnketa}/>
      <Route component={Page404}/>
    </Switch>
  )
};

export default RootRedirect;