import React                                 from "react";
import Modal, {ModalProps}                   from "../../../../uiKit/modal";
import Form                                  from '@atlaskit/form';
import Button                                from "../../../../uiKit/button/button";
import {StyledButtonsGroup}                  from "./styles";
import {IEnumItem}                           from "../../../../uiKit/enums";
import {IFormField}                          from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder  from "../../../../uiKit/fieldsBuilder";
import {getFormError}          from "../../../../formUtils/getFormError";
import {getFormValue}          from "../../../../formUtils/getFormValue";
import {FUNCTIONS, GROUPS, ME} from "../../../../utils/queries";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_UPDATE_ROLE}                  from "../../../../utils/mutations";
import {ISelectItem}                         from "../../../../uiKit/select";
import {ErrorMessage}        from "../../../../components/errorMessage";
import {useCallNotification} from "../../../../hooks/useCallNotification";

export const CreateRoleModal = ({header, isOpen, onClose}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {data: dataFunctions, loading: loadingFunctions, error: errorFunctions} = useQuery(FUNCTIONS);
  const [getGroups, {data: dataGroups, loading: loadingGroups, error: errorGroups}] = useLazyQuery(GROUPS);
  const [createRole, {loading: loadingCreateRole}] = useMutation(CREATE_UPDATE_ROLE, {refetchQueries: ["roles"]});
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {role, group, isAdmin, type} = (dataUser || {}).me || {};
  const {id: groupId} = group || {}
  const {id: parentGroupId, name: parentGroupName} = ((dataUser || {}).me || {}).group || {};
  const groups: ISelectItem[] = [...((dataGroups || {}).groups || []).map(({name, id}) => ({label: name, value: id})), ...(type === "admin" ? [] : [{label: parentGroupName, value: parentGroupId}])];
  const functionsMe: any[] = ((role || {}).functions || []).map(({id}) => id);
  const functionsAll: IEnumItem[] = ((dataFunctions || {}).functions || []).map(({id, name}) => ({
    label: name,
    value: id
  }));
  const functionsList: IEnumItem[] = isAdmin ? functionsAll : functionsAll.map(func => {
    const {value} = func;
    if (functionsMe.includes(value)) return func;
    return {...func, isDisabled: true};
  })

  React.useEffect(() => {
    getGroups({
      variables: {
        parent: groupId ? groupId : null,
        children: true
      }
    })
    // eslint-disable-next-line
  }, [groupId])

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {name, functions, group} = getFormValue(data);
    createRole({
      variables: {
        name,
        functions,
        group
      }
    }).then(() => {
      onClose()
      setNotification({type: "success", text: "Роль успешно создана"})
    })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "input",
        name: "name",
        isRequired: true,
        placeholder: "Название роли",
        errorMessage: "Введите название"
      }
    },
    {
      fieldItems: {
        type: "select",
        name: "group",
        isLoading: loadingUser || loadingGroups,
        items: groups,
        placeholder: "Группа для роли"
      }
    },
    {
      fieldItems: {
        type: "enums",
        name: "functions",
        isRequired: true,
        placeholder: "Поиск по функциям",
        isLoading: loadingFunctions,
        items: functionsList,
        errorMessage: "Выберите функции"
      }
    },
  ]

  if (errorGroups) setNotification({type: "error", text: errorGroups})
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorFunctions) setNotification({type: "error", text: errorFunctions})
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button type="submit" isLoading={loadingCreateRole} appearance="filled">
                Создать роль
              </Button>
              <Button onClick={() => onClose()} isLoading={loadingCreateRole} appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default CreateRoleModal;