import React        from "react";
import {
  StyledDefaultUserArea,
  StyledDefaultUserAreaWrapper, StyledDefaultUserRole,
  StyledDefaultUserRoleBody,
  StyledDefaultUserRoleHeader
}                   from "./styles";
import {TextButton} from "../../../../globalStyledComponents";
import {IEnumItem}  from "../../../../uiKit/enums";

export const DefaultUserRolePage = ({name, functions} : {name: string, functions: IEnumItem[]}) => {
  return (
    <StyledDefaultUserAreaWrapper>
      <StyledDefaultUserArea>
        <StyledDefaultUserRoleHeader>
          <TextButton>Название роли</TextButton>
          <TextButton>{name}</TextButton>
        </StyledDefaultUserRoleHeader>

        <StyledDefaultUserRoleBody>
          <TextButton>Назначенные функции</TextButton>
          <div>
            {functions.map(({label}, index) => (
              <StyledDefaultUserRole key={`function-${index}`}>
                <TextButton>- {label}</TextButton>
              </StyledDefaultUserRole>
            ))}
          </div>
        </StyledDefaultUserRoleBody>
      </StyledDefaultUserArea>
    </StyledDefaultUserAreaWrapper>
  )
};

export default DefaultUserRolePage;