import styled         from "styled-components";
import {blue7, white} from "../../globalStyledComponents";

export const StyledPoliciesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledPoliciesItem = styled.div`
  padding: 2rem;
  background: ${white};
  cursor: pointer;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
  transition: background 0.3s;
  h5 {
    margin: 4rem 0 0.5rem 0;
  }
  :hover {
    background: ${blue7};
  }
  p {
    opacity: 0.5;
  }
`;