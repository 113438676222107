import React                    from "react";
import {
  StyledHeader, StyledHeaderActions,
  StyledHeaderNavigation,
  StyledMobileLogo,
  StyledNavigationItems,
  StyledServiceName,
  StyledUserName
}                               from "./styles";
import StoreNavigation          from "../leftMenu/store";
import {PersonIcon}             from "../../../icons/ui/Person";
import {QuestionIcon}           from "../../../icons/ui/Question";
import {LogOutIcon}             from "../../../icons/ui/LogOut";
import {BurgerIcon}             from "../../../icons/ui/Burger";
import {TextButton}             from "../../../globalStyledComponents";
import {useHistory}             from "react-router-dom";
import {LogoMobile}             from "../../../icons/logo/LogoMobile";
import {useWindowWidth}         from "@react-hook/window-size";
import Button                   from "../../../uiKit/button/button";
import {LOGOUT, ME}             from "../../../utils/queries";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import {ErrorMessage}           from "../../errorMessage";
import {useCallNotification}    from "../../../hooks/useCallNotification";
import Onboarding               from "../../onboarding";
import StoreOnboarding          from "../../onboarding/store";

export const Header = () => {
  const {setNotification} = useCallNotification();
  const windowWidth = useWindowWidth({wait: 250});
  const history = useHistory();
  const [{navigation: {navigationState: {isOpen}}}, {navigation: actionsNavigation}]: any = StoreNavigation();
  const toggleNavigation = () => actionsNavigation.setState({
    navigationState: {
      isOpen: !isOpen
    }
  });
  const [, {onboarding: actionsOnboarding}]: any = StoreOnboarding();
  const toggleOnboarding = (isOpen) => actionsOnboarding.setState({
    onboardingState: {
      isOpen
    }
  });

  const [getLogout, {data: dataLogout, loading: loadingLogout, error: errorLogout}] = useLazyQuery(LOGOUT);
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {name, type} = (dataUser || {}).me || {};
  const isLogout: boolean = !!dataLogout?.logout;

  React.useEffect(() => {
    if (!isLogout) return;
    localStorage.removeItem("rolesIsn");
    if (type === "client") return history.push("/authorization/client");
    history.push("/authorization");
    // eslint-disable-next-line
  }, [isLogout]);

  const links: { icon: any, isLoading?: boolean, event: () => void, isHidden?: boolean }[] = [
    {icon: <PersonIcon/>, event: () => history.push("/profile"), isLoading: loadingUser},
    {icon: <QuestionIcon/>, event: () => toggleOnboarding(true), isHidden: type === "client", isLoading: loadingUser},
    {
      icon: <LogOutIcon/>, event: () => getLogout(), isLoading: loadingLogout || loadingUser
    }
  ];

  if (errorUser) return <ErrorMessage error={errorUser}/>;
  if (errorLogout) setNotification({type: "error", text: errorLogout});
  return (
    <>
      <StyledHeader>
        <StyledHeaderActions>
          <Button appearance="flag" onClick={toggleNavigation} icon={<BurgerIcon/>}/>
          {windowWidth < 768 ?
            <StyledMobileLogo>
              <LogoMobile/>
            </StyledMobileLogo>
            :
            <StyledServiceName>
              <TextButton>Ипотека</TextButton>
            </StyledServiceName>
          }
        </StyledHeaderActions>

        {windowWidth > 767 &&
        <StyledHeaderNavigation>
            <StyledUserName>
                <TextButton>{name}</TextButton>
            </StyledUserName>
            <StyledNavigationItems>
              {links.map(({icon, event, isLoading, isHidden}, index) => {
                if (isHidden) return null;
                return (
                  <Button
                    appearance="flag"
                    type="button"
                    isLoading={isLoading}
                    key={`header-link-${index}`}
                    onClick={event} icon={icon}/>
                )
              })}
            </StyledNavigationItems>
        </StyledHeaderNavigation>
        }
      </StyledHeader>
      <Onboarding/>
    </>
  );
};

export default Header;