import React                                        from 'react';
import Modal, {ModalProps}                          from "../../../../uiKit/modal";
import {StyledButtonsGroup}                         from "../../../roles/components/header/styles";
import Button                                       from '../../../../uiKit/button/button';
import {IFormField}                                 from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                                from "../../../../uiKit/fieldsBuilder";
import Form                                         from '@atlaskit/form';
import {getFormError}                               from "../../../../formUtils/getFormError";
import {useMutation}                                from "@apollo/react-hooks";
import {CALC_AGREEMENT, UPDATE_AGREEMENT_CALC_FULL} from "../../../../utils/mutations";
import {useCallNotification}                        from "../../../../hooks/useCallNotification";
import {getFormValue}                               from "../../../../formUtils/getFormValue";
import {setAgreementKvDiscount}                     from "../../../../helpers/buildTemplateForBack";

export const ChangeDiscountModal = ({onClose, header, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL);
  const [calcAgreement, {loading: loadingCalcAgreement}] = useMutation(CALC_AGREEMENT, {refetchQueries: ["agreementCalc"]});
  const {discount, agreementData} = data || {};
  const form: IFormField[] = [
    {
      fieldItems: {
        type: "number",
        name: "discount",
        digitsAfterDot: 2,
        defaultValue: discount,
        placeholder: "Скидка за счет КВ, %",
        errorMessage: "Введите скидку",
        validationType: "minMax",
        maxValue: 100
      }
    }
  ]

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {discount: currentDiscount} = getFormValue(data);
    const oldDiscount = discount;
    agreementCalc({
      variables: setAgreementKvDiscount(currentDiscount, agreementData)
    })
      .then(({data: {agreementCalcFull}}) => {
        const {isn: isnAgreement} = agreementCalcFull || {};
        calcAgreement({variables: {isn: isnAgreement}})
          .then(() => {
            onClose()
            setNotification({type: "success", text: "Данные успешно обновлены"})
          })
          .catch(error => {
            setNotification({type: "error", text: error})
            agreementCalc({
              variables: setAgreementKvDiscount(oldDiscount, agreementData)
            })
              .catch(error => setNotification({type: "error", text: error}))
          })
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                type="submit"
                appearance="filled"
                isLoading={loadingAgreementCalc || loadingCalcAgreement}>
                Пересчитать
              </Button>
              <Button
                onClick={() => onClose()}
                appearance="transparent"
                isLoading={loadingAgreementCalc || loadingCalcAgreement}>
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default ChangeDiscountModal;