import React                                             from "react";
import Modal, {ModalProps}                               from "../../../uiKit/modal";
import Button                                            from "../../../uiKit/button/button";
import {
  Heading1, Heading3,
  TextButton
}                                                        from "../../../globalStyledComponents";
import {StyledInvoiceCurrency, StyledInvoiceDescription} from "../styles";
import {useLazyQuery, useMutation}                       from "@apollo/react-hooks";
import {GET_AGREEMENT_PAYMENT, GET_CONTRACT}             from "../../../utils/queries";
import {useCallNotification}                             from "../../../hooks/useCallNotification";
import {useParams}                                       from "react-router";
import {money}                                           from "../../../utils/formats";
import {StyledButtonsGroup}                              from "../../roles/components/header/styles";
import {StyledPayPeriodModalButtons}                     from "./header/styles";
import {CREATE_INVOICE}                                  from "../../../utils/mutations";
import InvoiceModal                                      from "./InvoiceModal";
import {radioBoolean} from "../../createRequest/staticData";
import FieldsBuilder from "../../../uiKit/fieldsBuilder";
import Form                                              from '@atlaskit/form';
import {getFormError} from "../../../formUtils/getFormError";
import {getFormValue} from "../../../formUtils/getFormValue";

export const PayPeriodModal = ({header, isOpen, onClose}: ModalProps) => {
  const {id} = useParams();
  const {setNotification} = useCallNotification();
  const [stateModalIsOpen, setModalIsOpen] = React.useState<any>(undefined)
  const [getContract, {data: dataContract, loading: loadingContract, error: errorContract}] = useLazyQuery(GET_CONTRACT);
  const [getPayments, {data: dataAgreementPayment, loading: loadingAgreementPayment, error: errorAgreementPayment}] = useLazyQuery(GET_AGREEMENT_PAYMENT);
  const [createInvoice, {
    loading: loadingCreateInvoice
  }] = useMutation(CREATE_INVOICE, {fetchPolicy: "no-cache", refetchQueries: ["agreement"], awaitRefetchQueries: true});
  const {userGetPaymentQuery} = dataAgreementPayment || {};
  const payments: any[] = Array.isArray(userGetPaymentQuery) ? userGetPaymentQuery : !!userGetPaymentQuery ? [userGetPaymentQuery] : [];
  const rows = payments.map(({ROWNUM, PAYMENT_SCHEDULE, PAYMENT_DATE, PAYMENT_RUB_ON_CURRENT_DATE}) => ({summ: money(PAYMENT_SCHEDULE), date: PAYMENT_DATE, status: PAYMENT_RUB_ON_CURRENT_DATE === "0" ? "payed" : "notPay", number: ROWNUM}))
  const firstNotPayed: any = rows?.find(({status}) => status === "notPay");
  const {summ} = firstNotPayed || {};
  const {id: contractId} = (dataContract || {}).agreement || {};

  React.useEffect(() => {
    if (!isOpen) return;
    getPayments({
      variables: {
        agreementIsn: id
      }
    })
    getContract({
      variables: {
        isn: id
      }
    })
    // eslint-disable-next-line
  }, [isOpen])

  const createInvoiceHandler = (id, contractId, summForPay) => {
    createInvoice({
      variables: {
        argIsn: id
      }
    })
      .then(({data: {createInvoice: isnInvoice}}) => setModalIsOpen({isnInvoice, summForPay, contractId}))
      .catch(error => setNotification({type: "error", text: error}))
  }

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;

    const formData = getFormValue(data);

    console.log(formData);

    if(formData.hasEarlyPays || formData.hasHealthIssues){
      return {
        hasEarlyPays: "Продлить договор через платформу невозможно. Пожалуйста, свяжитесь с куратором"
      }
    }

    createInvoiceHandler(id, contractId, summ);
  }

  if (errorAgreementPayment) setNotification({type: "error", text: errorAgreementPayment})
  if (errorContract) setNotification({type: "error", text: errorContract})
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      isLoading={loadingAgreementPayment || loadingContract}
      header={header}>
      {!firstNotPayed && <Heading3>Отсутствуют взносы для оплаты</Heading3>}
      <StyledInvoiceCurrency>
        <TextButton>Сумма к оплате</TextButton>
        <Heading1>{summ}</Heading1>
      </StyledInvoiceCurrency>
      <StyledInvoiceDescription>
        <TextButton>Назначение платежа:</TextButton>
        <TextButton>Страховая премия по договору {contractId}</TextButton>
      </StyledInvoiceDescription>

      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
            <form {...formProps}>
              <FieldsBuilder formFields={[{
                isHidden: false,
                label: "Были ли у клиента проблемы со здоровьем за текущий период страхования",
                fieldItems: {type: "radio", isRequired: true, name: "hasHealthIssues", items: radioBoolean}
              }, {
                isHidden: false,
                label: "Были досрочные платежи",
                fieldItems: {type: "radio", isRequired: true, name: "hasEarlyPays", items: radioBoolean}
              }]}/>

              <StyledPayPeriodModalButtons>
                <StyledButtonsGroup>
                  <Button
                    type="submit"
                    //onClick={() => createInvoiceHandler(id, contractId, summ)}
                    isLoading={loadingCreateInvoice}
                    appearance="filled">
                    Оплатить
                  </Button>
                  <Button onClick={() => onClose()} appearance="transparent">
                    Отмена
                  </Button>
                </StyledButtonsGroup>
              </StyledPayPeriodModalButtons>
            </form>
        )}
      </Form>
      <InvoiceModal header="Оплата очередного взноса по договору" data={stateModalIsOpen}
                    onClose={() => setModalIsOpen(undefined)} isOpen={!!stateModalIsOpen}/>
    </Modal>
  )
};

export default PayPeriodModal;