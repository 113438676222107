import styled        from "styled-components";
import {grey, white} from "../../../../globalStyledComponents";

export const StyledStepWrapper = styled.div`
  padding: 0 15rem;
    @media screen and (max-width: 767px) {
    padding: 0;
  }  
`;

export const StyledStepFormWrapper = styled.div`
  padding: 4rem;
  background: ${white};
  box-shadow: 0px 1px 10px rgba(0, 61, 129, 0.1);
  margin-bottom: 5rem;

  @media screen and (max-width: 767px) {
    padding: 2rem 1.5rem;
    margin-bottom: 2rem;
  }  
`;


export const StyledStepFormHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3rem;
  p {
    white-space: nowrap;
    color: ${grey};
  }
  
  @media screen and (max-width: 767px) {
 flex-direction: column-reverse;
    align-items: flex-start;
    p {
      margin-bottom: 2rem;
    }
   margin-bottom: 2rem;   
  }  
`;

