import styled                                      from "styled-components";
import {black, grey, halfDarkGrey, lightBlue, red} from "../../globalStyledComponents";

export const StyledTextAreaWrapper = styled.div`
  position: relative;
  overflow: hidden;
  > textarea:focus + label, > textarea:not(:placeholder-shown) + label {
    font-size: 1.2rem;
    line-height: 2rem;
    top: 0;  
  }
`;

export const StyledTextArea = styled.textarea<{isInvalid: boolean}>`
    padding: 1.7rem 1.5rem 0 1.5rem;
    box-sizing: border-box;
    font-size: 1.4rem;
    outline: none;
    min-height: 12rem;
    max-height: 20rem;
    resize: none;
    width: 100%;
    color: ${black};
    ${({isInvalid}) =>
      isInvalid ? 
        `
        border: 1px solid ${red};
        `
        :
        `
        border: 1px solid ${grey};
        :hover, :focus {
        border: 1px solid ${lightBlue}; 
        `
    }
`;

export const StyledTextAreaLabel = styled.label<{isRequired: boolean}>`
    position: absolute;
    white-space: nowrap;
    color: ${halfDarkGrey};
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: Verdana;
    pointer-events: none;
    transition: all .2s ease;
    top: 1.7rem;
    left: 1.5rem;


    ${({isRequired}) =>
      isRequired ?
        `
        ::before {
          content: "*";
          color: ${red};
        }
        `
        :
        ""
    }
`;