import React                                   from 'react';
import {StyledCheckbox, StyledCheckboxWrapper} from "./styles";
import {CheckboxIcon}                          from "../../icons/ui/Checkbox";
import {TextButton}                            from "../../globalStyledComponents";

interface CheckboxProps {
  value?: boolean
  onChange?: (checked: boolean) => void
  label?: string
  isDisabled?: boolean
  isInvalid?: boolean
  errorMessage?: string
}

export const Checkbox = ({value, label, isInvalid, errorMessage, isDisabled, onChange}: CheckboxProps) => {
  const [stateCheckbox, setCheckbox] = React.useState<boolean>(value || false);

  React.useEffect(() => {
    if (typeof value === "undefined" || stateCheckbox === value) return;
    return setCheckbox(value);
    // eslint-disable-next-line
  }, [value]);

  React.useEffect(() => {
    if (!onChange) return;
    return onChange(stateCheckbox)
    // eslint-disable-next-line
  }, [stateCheckbox]);

  const checkboxChangeHandler = () => {
    if (isDisabled) return;
    setCheckbox(!stateCheckbox)
  };

  return (
    <StyledCheckboxWrapper onClick={checkboxChangeHandler} isDisabled={!!isDisabled} isChecked={stateCheckbox}>
      <StyledCheckbox isChecked={stateCheckbox} isDisabled={!!isDisabled} isInvalid={!!isInvalid}>
        <CheckboxIcon/>
      </StyledCheckbox>
      {!!label && <TextButton>{label}</TextButton>}
    </StyledCheckboxWrapper>

  )
};

export default Checkbox;