import styled from "styled-components";

export const StyledRoleContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
export const StyledRoleForm = styled.div`
  h3 {
    margin-bottom: 3rem;
  }
`;

export const StyledRoleName = styled.div`
  margin-bottom: 4rem;
  h3 {
    margin-bottom: 3rem;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    h3 {
      margin-bottom: 2rem;
    }
  }
`;
export const StyledUsersWithSameRoleWrapper = styled.div`
    margin-bottom: 3rem;

  > p {
    margin-bottom: 1.5rem;
  }
  min-height: 50rem;
  max-height: 60vh;
  overflow-y: auto;
`;