import React                           from 'react';
import Modal                           from "../../uiKit/modal";
import Button                                                                                                                                                          from "../../uiKit/button/button";
import {StyledFeature, StyledFeatureIcon, StyledOnboardingEventsWrapper, StyledOnboardingNavigationItem, StyledOnboardingStepsNavigation, StyledStepBody} from "./styles";
import {StyledButtonsGroupAutoSize}                                                                                                                                    from "../../pages/roles/components/header/styles";
import img1 from "./img/1.png"
import img2 from "./img/2.png"
import img3       from "./img/3.png"
import img4                   from "./img/4.png"
import img5                   from "./img/5.png"
import img6                   from "./img/6.png"
import img7                   from "./img/7.png"
import img8                   from "./img/8.png"
import img9                   from "./img/9.png"
import img10                  from "./img/10.png"
import img11                  from "./img/11.png"
import img12                  from "./img/12.png"
import img13                  from "./img/13.png"
import {Heading3, TextButton} from "../../globalStyledComponents";
import {useQuery}             from "@apollo/react-hooks";
import {ME}                   from "../../utils/queries";
import StoreOnboarding        from "./store";
import PreviewImage           from "../../uiKit/imagePreview";

const Feature = ({text}: {text: string}) => {

  return <StyledFeature>
    <StyledFeatureIcon/>
    <TextButton>{text}</TextButton>
  </StyledFeature>
};

const STEPS = [
  {
    type: ["seller", "underwriter"],
    header: "Добро пожаловать в Абсолют Страхование!",
    img: img1,
    title: "Начните обучение",
    description: <>
      <TextButton>Мы расскажем вам как пользоваться программой.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Главная",
    img: img2,
    title: "Статистика содержит",
    description: <>
      <Feature text="Договоры"/>
      <Feature text="Заявки"/>
      <br/>
      <TextButton>Чтобы оформить предварителую заявку и рассчитать стоимость, необходимо нажать на кнопку Создать заявку. Вы можете оформить предварительный расчет или перейти к расширенной заявке.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Предварительная заявка",
    img: img3,
    title: "Создание заявки включает ввод данных",
    description: <>
      <Feature text="Вид договора"/>
      <Feature text="Размер кредита и кредитная организация"/>
      <Feature text="Строящееся или готовое жилье"/>
      <Feature text="Доля заемщика в общем доходе"/>
      <Feature text="Сведения о здоровье и наличии созаемщиков"/>
    </>
  },
  {
    type: ["seller"],
    header: "Предварительная заявка",
    img: img4,
    title: "Результаты расчета содержат",
    description: <>
      <Feature text="Наименование кредитной организации"/>
      <Feature text="Сумму страховой премии и страховую сумму"/>
      <Feature text="Параметры предложения"/>
      <br/>
      <TextButton>Доступна выгрузка всех предложений. Нажав на чекбокс, вы сможете выгрузить данное предложение и все выбранные. Чтобы получить подробный расчет по конкретному предложению, необходимо нажать на кнопку Выбрать.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Расширенная заявка",
    img: img5,
    title: "Для создания заявки необходимо внести",
    description: <>
      <Feature text="Общие сведения о страхователе"/>
      <Feature text="Сведения о занятости страхователя"/>
      <Feature text="Основные положения кредитного договора"/>
      <br/>
      <TextButton>После ввода этих данных появляется окно со всеми участниками договора.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Расширенная заявка",
    img: img6,
    title: "Страхование жизни",
    description: <>
    <TextButton>Варианты заполнения анкеты заемщика/созаемщика:</TextButton>
      <br/>
      <Feature text="Заполнить онлайн"/>
      <Feature text="Загрузить документ"/>
      <Feature text="Отправить анкету клиенту"/>
      <br/>
    <TextButton>После ввода всех данных необходимо нажать на кнопку Создать.</TextButton>
    </>
  },


  {
    type: ["underwriter"],
    header: "Журнал заявок",
    img: img7,
    title: "Заявка ожидает андеррайтинга ",
    description: <>
    <TextButton>Для взаимодействия с заявкой необходимо нажать на поле заявки.</TextButton>
    </>
  },
  {
    type: ["underwriter"],
    header: "Андеррайтинг заявки",
    img: img8,
    title: "Вы можете посмотреть общие сведения, а также",
    description: <>
      <Feature text="Скачать прикрепленные документы"/>
      <Feature text="Оставить сообщение в блоке Обсуждения "/>
      <Feature text="Изменить повышающий коэффициент и одобрить риски"/>
      <Feature text="Предоставить скидку андеррайтера"/>
      <br/>
      <TextButton>Для дальнейшего шага необходимо Пересчитать данные либо Согласовать заявку.</TextButton>
    </>
  },
  {
    type: ["underwriter"],
    header: "Андеррайтинг заявки",
    img: img9,
    title: "Согласование заявки",
    description: <>
      <TextButton>При нажатии на Согласование заявки возникает выбор действия:</TextButton>
      <br/>
      <Feature text="Согласовать"/>
      <Feature text="Не согласовывать"/>
      <Feature text="Не согласовывать"/>
      <br/>
      <TextButton>При согласовании заявки работа с ней завершается.</TextButton>
    </>
  },



  // {
  //   type: ["seller"],
  //   header: "Журнал заявок",
  //   img: img7,
  //   title: "Заявка отправляется на андеррайтинг",
  //   description: <div></div>
  // },


  {
    type: ["seller"],
    header: "Согласование заявки",
    img: img10,
    title: "Вы можете посмотреть данные, а также",
    description: <>
      <Feature text="Применить скидку за счет комиссионного вознаграждения агента"/>
      <Feature text="Отправить на повторное согласование"/>
      <Feature text="Оставить сообщение в обсуждении"/>
      <br/>
      <TextButton>Для дальнейшего шага необходимо нажать на кнопку Создать договор.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Формирование договора",
    img: img11,
    title: "Вы можете посмотреть данные, а также",
    description: <>
      <Feature text="Скачать проект договора"/>
      <Feature text="Пересчитать премию"/>
      <Feature text="Перейти к подписанию договора"/>
      <br/>
      <TextButton>Для дальнейшего шага необходимо нажать на кнопку Подписать договор.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Формирование договора",
    img: img12,
    title: "После подписания договора вы можете",
    description: <>
      <Feature text="Получить документы по договору"/>
      <Feature text="Внести изменения"/>
      <br/>
      <TextButton>Для дальнейшего шага необходимо нажать на кнопку Получить документы.</TextButton>
    </>
  },
  {
    type: ["seller"],
    header: "Договор страхования",
    img: img13,
    title: "На этапе сформированного договора вы можете",
    description: <>
      <Feature text="Посмотреть общие сведения и документы"/>
      <Feature text="Создать счет и получить его печатную форму"/>
      <Feature text="Получить печатную форму договора"/>
      <br/>
      <TextButton>Для выставления счета клиенту необходимо Сформировать счет на оплату.</TextButton>
    </>
  },
];

const Onboarding = () => {
  const [{onboarding: {onboardingState: {isOpen}}}, {onboarding: actionsOnboarding}]: any = StoreOnboarding();
  const toggleOnboarding = (isOpen) => actionsOnboarding.setState({
    onboardingState: {
      isOpen
    }
  });

  const [stateActiveStep, setActiveStep] = React.useState<number>(0);
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {role, isAdmin, type} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const isSeller: boolean = functions.includes("applicationPage");
  const isUnderwriter: boolean = functions.includes("underwritingPage");
  const isSellerAndUnderwriter: boolean = (isSeller && isUnderwriter) || isAdmin;
  const currentSteps = isSellerAndUnderwriter ? STEPS : isUnderwriter ? STEPS.filter(({type}) => type.includes("underwriter")) : STEPS.filter(({type}) => type.includes("seller"))

  const closeHandler = () => {
    localStorage.setItem("onboarding", "true");
    toggleOnboarding(false);
  };

  const changeStepHandler = (step: number) => {
    setActiveStep(step);
  };

  const {header, title, img, description} = currentSteps[stateActiveStep];
  const isLastStep: boolean = stateActiveStep + 1 === currentSteps.length;

  if (errorUser || loadingUser || type === "client") return null;

  return (
    <Modal header={header} width={70} onClose={() => closeHandler()} isOpen={isOpen}>
      <PreviewImage src={img}/>
      <StyledStepBody>
        <Heading3>{title}</Heading3>
        {description}
      </StyledStepBody>
      <StyledOnboardingEventsWrapper>
        {stateActiveStep === 0 && <Button appearance="filled" onClick={() => changeStepHandler(stateActiveStep + 1)}>Начать</Button>}
        {stateActiveStep !== 0 && <StyledButtonsGroupAutoSize>
            <Button appearance="transparent" onClick={() => changeStepHandler(stateActiveStep - 1)}>Назад</Button>
            <Button appearance="filled" onClick={() => {
              if (isLastStep) return closeHandler();
              changeStepHandler(stateActiveStep + 1);
            }}>{isLastStep ? "Завершить" : "Далее"}</Button>
        </StyledButtonsGroupAutoSize>}

        <StyledOnboardingStepsNavigation>
          {currentSteps.map((item,index) => <StyledOnboardingNavigationItem key={`nav-${index}`} onClick={() => setActiveStep(index)} isActive={stateActiveStep === index}/>)}
        </StyledOnboardingStepsNavigation>
      </StyledOnboardingEventsWrapper>
    </Modal>
  );
};

export default Onboarding;
