import styled                    from "styled-components";
import {blue7, lightBlue, white} from "../../globalStyledComponents";

export const StyledDaData = styled.div`
.react-dadata__suggestion-note {
  display: none;
}

.react-dadata__suggestions {
  width: 100%;
  background: ${white};
  box-sizing: border-box;
  border: 1px solid ${lightBlue};
  border-top: unset;
  padding: 0.5rem 0;
  z-index: 2;
  overflow-y: auto;
  max-height: 30rem;
}

.react-dadata__suggestion {
    padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  
  :hover {
    background: ${blue7};
  }
}

.react-dadata__suggestion--current {
  background: unset;
}

.react-dadata--highlighted {
  color: ${lightBlue};
}
`;
