import React, {useEffect} from 'react';
import NivaInput                                                                                                       from "../../uiKit/nivaInput/NivaInput";
import {SearchIcon}                                                                                                    from "../../icons/ui/Search";
import {FilterIcon}                                                                                                    from "../../icons/ui/Filter";
import Status                                                                                                          from "../../uiKit/status";
import {StyledAgreementLogWrapper, StyledApplicationSearch, StyledDropDownStatus, StyledFilterIcon, StyledTableButton} from "./styles";
import ApplicationFilter                                                                                               from "./components/filter";
import {useHistory, useParams}                                                                                         from "react-router";
import DropDown, {IDropDownListItem}                                                                                   from "../../uiKit/dropDownArea";
import Button                                                                                                          from "../../uiKit/button/button";
import {useQuery}                                                                                                      from "@apollo/react-hooks";
import {DOCS, ME}                                                                                                      from "../../utils/queries";
import {money}                                                                                                         from "../../utils/formats";
import AgreementsLogHeader                                                                                             from "./components/header";
import {PencilIcon}                                                                                                    from "../../icons/ui/Pencil";
import {rolesRightHandler}                                                                                             from "../../helpers/rolesRightHandler";
import Table, {ITableHeadItem, ITableRowItem}                                                                          from "../../uiKit/table";
import {ErrorMessage}                                                                                                  from "../../components/errorMessage";
import {DateIntervalValue}                                                                                             from "../../uiKit/dateIntervalPicker";
import moment                                                                                                          from "moment";
import {formatDateStr}                                                                                                 from "../../uiKit/datePicker";
import {TrashIcon}                                                                                                     from "../../icons/ui/Trash";
import DeleteDocModal                                                                                                  from "./components/DeleteDocModal";
import RolesHierarchy                                                                                                  from "./components/RolesHierarchy";
import {ArrowIcon}                                                                                                     from "../../icons/ui/Arrow";
import SelectReasonModal                                                                                               from "./components/SelectReasonModal";
import UserWarning from "../statistics/components/UserWarning";

export interface IFilterState {
  dateRadio: string
  dateInterval: DateIntervalValue
  banks: string[]
  statuses: string[]
}

const getShortName = (name: string) => {
  if (!name) return "unknown";
  return name.split(" ").reduce((prev, next, index) => {
    if (index === 0) return `${next}`;
    return `${prev} ${next[0]}.`
  }, "")
};

const defaultStart: string = moment().subtract(10, 'year').format(formatDateStr);
const defaultEnd: string = moment().add(1, "day").format(formatDateStr);

export const defaultFilterValue: IFilterState = {
  dateRadio: "year",
  dateInterval: {start: defaultStart, end: defaultEnd},
  banks: [],
  statuses: []
}

const agreementLogRowsPerPage: number = 10;

const isFilterCheck = (stateFilter: IFilterState, stateSearch: string) => {
  const {dateInterval, statuses, banks} = stateFilter || {};
  const {start, end} = dateInterval || {};
  if (!!stateSearch) return true;
  if (statuses.length !== 0) return true;
  if (banks.length !== 0) return true;
  return start !== defaultStart || end !== defaultEnd;
};

export type AgreementsLogType = "C" | "A" | "P"
export const AgreementsLog = ({type}: { type: AgreementsLogType }) => {
  const history = useHistory();
  const {page} = useParams();
  const [stateSearch, setSearch] = React.useState<string>("");
  const [stateFilter, setFilter] = React.useState<IFilterState>(defaultFilterValue);
  const [stateFilterIsOpen, setFilterIsOpen] = React.useState<boolean>(false);
  const [stateSetStatusIsOpen, setSetStatusIsOpen] = React.useState<string | undefined>(undefined);
  const [stateDeleteDocModal, setDeleteDocModal] = React.useState<number | undefined>(undefined);
  const [stateSelectReasonModal, setSelectReasonModal] = React.useState<{ id: string, type: string } | undefined>(undefined);
  const {data: dataUser, error: errorUser, loading: userLoading} = useQuery(ME);
  const {isAdmin, type: userType, role, id: userId, banksOk} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {dateInterval, statuses, banks} = stateFilter || {};
  const {start, end} = dateInterval || {};

  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {
      type,
      page,
      limit: agreementLogRowsPerPage,
      status: statuses,
      banks,
      filter: stateSearch ? stateSearch : undefined,
      period: {
        start: moment(start, formatDateStr).format(),
        end: moment(end, formatDateStr).format(),
      }
    },
    fetchPolicy: "cache-and-network"
  });

  const {items: docsList, total} = (dataDocs || {}).docs || {};
  const sellerAndUnderwriter = (functions.includes("applicationPage") && functions.includes("underwritingPage")) || userType === "admin";
  const headsListDefault = [...(sellerAndUnderwriter && type === "C" ? ["просмотр"] : []), type === "C" ? "№ заявки" : "№ договора", "Сумма кредита, RUB", "Банк", "Дата создания", "Заемщик", "Статус", "Создана", "Группа"];

  useEffect(() => {
    if(total > 0 && page > Math.ceil(total / agreementLogRowsPerPage)) {
      paginationChangeHandler(Math.ceil(total / agreementLogRowsPerPage));
    }
  }, [total]);

  const setStatusItems: IDropDownListItem[] = [
    {label: "Аннулирована", value: "canceled"},
    {label: "Отказ клиента", value: "client", isDisabled: type === "A"},
  ]

  const paginationChangeHandler = (page: number) => {
    if (type === 'A') history.push(`/contractsLog/${page}`);
    if (type === 'C')history.push(`/agreementsLog/${page}`);
    if (type === 'P')history.push(`/prolongationLog/${page}`);
  }

  const headsList = sellerAndUnderwriter ? [...headsListDefault, ""] : headsListDefault;
  const head: ITableHeadItem = {
    cells: headsList.map((content, index) => ({key: `head-${index}`, content}))
  };

  const rows: ITableRowItem[] = (docsList || []).reduce((prev, {id, isn, added, number, statusCode, user: createdAt, kias, data}, index) => {
    const {AGREEMENT_ADDATTR, CLIENTNAME, CLIENTFULLNAME} = kias || {};
    const {bankName, insurer, summ} = data || {};
    const summKias: string = ((((AGREEMENT_ADDATTR || {}).row || []).find(({ATTRISN}) => ATTRISN === "831731")) || {}).VALUE;
    const bankLabelKias: string = ((((AGREEMENT_ADDATTR || {}).row || []).find(({ATTRISN}) => ATTRISN === "831791")) || {}).VALUE;
    const insurerKias: string = CLIENTNAME || CLIENTFULLNAME;

    return [
      ...prev,
      {
        key: `row-${isn}-${type}-${index}`,
        id: isn,
        onClick: (ev) => {
          if (!["TD"].includes(ev.target.tagName)) return;
          if (["A", "P"].includes(type)) return history.push(`/contract/${isn}`);
          if (rolesRightHandler("underwritingPage", functions, userType, isAdmin)) return history.push(`/underwriting/${isn}`);
          if (rolesRightHandler("applicationPage", functions, userType, isAdmin)) return history.push(`/agreement/${isn}`);
        },
        cells: [
          ...(sellerAndUnderwriter && type === "C" ? [{
            key: "cell-goToInfo", content:
              <StyledTableButton>
                <Button
                  onClick={(ev) => {
                    ev.preventDefault();
                    history.push(`/agreement/${isn}`)
                  }}
                  appearance="flag"
                  icon={<PencilIcon/>}/>
              </StyledTableButton>
          }] : []),
          // {key: `cell-${isn}`, content: isn},
          {key: `cell-${isn}-${type}-${index}`, content: number || "unknown"},
          {key: `cell-${isn}-${type}-${index}`, content: money(summKias || summ)},
          {key: `cell-${isn}-${type}-${index}`, content: bankLabelKias || bankName || "unknown"},
          {key: `cell-${isn}-${type}-${index}`, content: added || "unknown"},
          {key: `cell-${isn}-${type}-${index}`, content: (insurerKias || insurer || "").toLowerCase()},
          {
            key: `cell-${isn}-${type}-${index}`, content: (createdAt.id === userId || isAdmin) && !["annul", "clientRefusal"].includes(statusCode) ?
              <DropDown
                trigger={
                  <StyledDropDownStatus onClick={() => setSetStatusIsOpen(isn)}>
                    <Status status={statusCode} iconAfter={<ArrowIcon direction={stateSetStatusIsOpen === isn ? "top" : "down"}/>}/>
                  </StyledDropDownStatus>}
                isOpen={stateSetStatusIsOpen === isn}
                isDropDownList={true}
                items={setStatusItems.map(item => ({
                  ...item, event: () => {
                    setSetStatusIsOpen(undefined)
                    setSelectReasonModal({id: isn, type: item.value || ""})
                  }
                }))}
                onClose={() => setSetStatusIsOpen(undefined)}
              />
              : <Status status={statusCode}/>
          },
          {key: `cell-${isn}-${type}-${index}`, content: `${getShortName((createdAt || "").name) || "Не известный"}`},
          {key: `cell-${isn}-${type}-${index}`, content: <RolesHierarchy isAdmin={createdAt?.type === "admin"} roleId={createdAt?.group?.id}/>},
          ...(userType === "admin" ? [{
            key: "cell-delete", content:
              <StyledTableButton>
                <Button
                  onClick={(ev) => {
                    ev.preventDefault();
                    setDeleteDocModal(id);
                  }}
                  appearance="flag"
                  icon={<TrashIcon/>}/>
              </StyledTableButton>
          }] : [])
        ]
      }
    ]
  }, [])

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  return (
    <StyledAgreementLogWrapper>
      <AgreementsLogHeader type={type}/>
      {!banksOk && !userLoading && <UserWarning/>}
      <DropDown
        trigger={
          <StyledApplicationSearch>
            <NivaInput
              placeholder="Поиск"
              debounce={true}
              debounceOffset={600}
              value={stateSearch}
              onChange={(value) => setSearch(value)}
              iconBefore={<SearchIcon/>}
              iconAfter={
                <Button
                  appearance="flag"
                  onClick={() => setFilterIsOpen(true)}
                  icon={
                    <StyledFilterIcon
                      isFilter={isFilterCheck(stateFilter, stateSearch)}>
                      <FilterIcon/>
                    </StyledFilterIcon>
                  }/>
              }/>
          </StyledApplicationSearch>
        }
        isOpen={stateFilterIsOpen}
        maxBodyWidth={54}
        onClose={() => setFilterIsOpen(false)}
      >
        <ApplicationFilter
          isShowBanks={type !== "P"}
          stateFilter={stateFilter}
          setFilter={setFilter}
          setFilterIsOpen={setFilterIsOpen}
        />
      </DropDown>
      <Table
        emptyViewMessage={isFilterCheck(stateFilter, stateSearch) ? "По данным параметрам ничего не найдено" : `Здесь пока нет ${type === "C" ? "заявок" : "договоров"}`}
        isLoading={loadingDocs}
        onPageChange={paginationChangeHandler}
        page={Number(page)}
        totalRows={total}
        rowsPerPage={agreementLogRowsPerPage}
        head={head}
        rows={rows}
      />

      <SelectReasonModal
        header={type === "A" ? "Аннулировать договор" : "Выберите причину"}
        data={{agreementType: type, ...stateSelectReasonModal}}
        onClose={() => setSelectReasonModal(undefined)}
        isOpen={!!stateSelectReasonModal}/>
      <DeleteDocModal
        isOpen={!!stateDeleteDocModal}
        onClose={() => setDeleteDocModal(undefined)}
        data={{id: stateDeleteDocModal, type: type === "A" ? "договор" : "заявку"}}/>
    </StyledAgreementLogWrapper>
  )
};

export default AgreementsLog;