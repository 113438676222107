import React                                                                            from 'react';
import StatisticsHeader                                                                 from "./components/header";
import {StatisticsCardsWrapper, StyledCardHeader, StyledCardItem, StyledStatisticsCard} from "./styles";
import {
  Heading3,
  Text3
}                                                                                       from "../../globalStyledComponents";
import Button
                                                                                        from "../../uiKit/button/button";
import {useQuery}                                                                       from "@apollo/react-hooks";
import {DASHBOARD, ME}                                                                  from "../../utils/queries";
import {Loader}                                                                         from "../../uiKit/loader";
import {useHistory}                                                                     from "react-router";
import {ErrorMessage}                                                                   from "../../components/errorMessage";
import {rolesRightHandler}                                                              from "../../helpers/rolesRightHandler";
import UserWarning from "./components/UserWarning";

interface ICardProps {
  title: string
  linkText: string
  link: string
  items: any[]
  isDisabled?: boolean
}

const StatisticCard = ({title, items, link, linkText}: ICardProps) => {
  const history = useHistory();
  return (
    <StyledStatisticsCard>
      <StyledCardHeader>
        <Heading3>{title}</Heading3>
        <Button appearance="link" onClick={() => history.push(link)}>{linkText}</Button>
      </StyledCardHeader>
      {items.map((item, index) => (
        <StyledCardItem key={`main-card-item-${index}`}>
          {item}
        </StyledCardItem>
      ))}
    </StyledStatisticsCard>
  )
};

export const Statistics = () => {
  const {data: dataUser, error: errorUser, loading: userLoading} = useQuery(ME);
  const {isAdmin, type, role, banksOk} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);

  const {data: dataDashboard, loading: loadingDashboard, error: errorDashboard} = useQuery(DASHBOARD);

  const cards: ICardProps[] = [
    {
      title: "Договоры",
      linkText: "Журнал договоров",
      link: "/contractsLog/1",
      isDisabled: !rolesRightHandler("agreementsLogPage", functions, type, isAdmin),
      items: (dataDashboard?.dashboard?.contracts || []).map((status, index) =>
        <React.Fragment key={`contractsLog-${index}`}>
          <Text3>{status?.status || "unknown"}</Text3>
          <Text3>{status?.count || 0}</Text3>
        </React.Fragment>)
    },
    {
      title: "Заявки",
      linkText: "Журнал заявок",
      link: "/agreementsLog/1",
      isDisabled: !rolesRightHandler("applicationsLogPage", functions, type, isAdmin),
      items: (dataDashboard?.dashboard?.agreements|| []).map((status, index) =>
        <React.Fragment key={`contractsLog-${index}`}>
          <Text3>{status?.status || "unknown"}</Text3>
          <Text3>{status?.count || 0}</Text3>
        </React.Fragment>)
    },
    // {
    //   title: "Заявки на согласовании",
    //   linkText: "Все",
    //   isDisabled: !rolesRightHandler("applicationsLogPage", functions, type, isAdmin),
    //   link: "/agreementsLog",
    //   items: []
    // }
  ];

  // <div>
  //   <Text3>{statusName}</Text3>
  //   <Status status={status}/>
  // </div>

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorDashboard) return <ErrorMessage error={errorDashboard}/>
  if (loadingDashboard) return <Loader type="simple"/>
  return (
    <>
      <StatisticsHeader/>
      {!banksOk && !userLoading && <UserWarning/>}
      <StatisticsCardsWrapper>
        {cards.map((props, index) => <StatisticCard key={`card-${index}`} {...props}/>)}
      </StatisticsCardsWrapper>
    </>
  )
};

export default Statistics;