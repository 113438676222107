import styled                           from "styled-components";
import {blue, grey, grey20, red, white} from "../../globalStyledComponents";

export const StyledCheckboxWrapper = styled.div<{isChecked: boolean, isDisabled?: boolean}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  p {
  transition: opacity 0.2s;
  ${({isChecked}) =>
    isChecked ?
    `
    opacity: 1;
    `
    :
    `
    opacity: 0.5;
    `
  }
  }
  
    ${({isDisabled}) =>
  isDisabled ?
    "cursor: no-drop;"
    :
    `
      :hover > div {
    border: 1px solid ${blue}; 
  }
    `
}
`;

export const StyledCheckbox = styled.div<{isChecked: boolean, isInvalid?: boolean, isDisabled?: boolean}>`
  max-width: 2rem;
  min-width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border, background 0.2s;
  
  ${({isInvalid, isChecked, isDisabled}) => 
    isDisabled ?
      `
      background: ${grey20};
      border: 1px solid ${grey};
      `
      :
    isInvalid ?
      `
    border: 1px solid ${red};
    background: ${white};
    color: ${red}; 
  `
      :
      isChecked ?
        `
    border: 1px solid ${blue};
    background: ${blue};
    color: ${white}; 
  `
        :
        `
    border: 1px solid ${grey};
  `
  }
    
  > svg {
    transition: opacity 0.2s;
    ${({isChecked}) => 
      isChecked ?
        `
        opacity: 1;
        `
        :
        `
        opacity: 0;
        `
    }
  }
`;