import styled from "styled-components";

export const StyledFieldColumnsWrapper = styled.div<{ columns: number | undefined , rowGap: number, columnGap: number}>`
  ${({columns, rowGap, columnGap}) => {
  if (!columns || columns <= 0) return "";
  const gridColumns = Array.from({length: columns}).reduce((prev, next) => `${prev} 1fr`, "");
  return `
  display: grid;
  grid-template-columns: ${gridColumns};
  grid-column-gap: ${columnGap}rem;
  grid-row-gap: ${rowGap}rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  `
}}
`;