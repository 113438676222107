import React from "react";

import {radioBoolean}         from "../../../staticData";
import {IRadioItem}           from "../../../../../uiKit/radio";
import {IFormField}           from "../../../../../uiKit/fieldsBuilder/interfaces";
import {TrashIcon}            from "../../../../../icons/ui/Trash";
import {Heading3}             from "../../../../../globalStyledComponents";
import Button                 from "../../../../../uiKit/button/button";
import FieldsBuilder          from "../../../../../uiKit/fieldsBuilder";
import {AddIcon}              from "../../../../../icons/ui/Add";
import {StyledSubjectHeader}  from "./styles";
import StoreAgreementForm     from "../../../store";
import {getRadioDefaultValue} from "../../../../../formUtils/getFormValue";
import {StyledAddressButton}  from "../../propertyInsuranceStep/components/insuranceSubject/styles";

interface ISubjectProps {
  index: number
  deleteHandler: () => void
  number: number
  subjectsLength: number
}

const Subject = ({index, deleteHandler, number, subjectsLength}: ISubjectProps) => {
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const [stateEncumbrances, setEncumbrances] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateAddressEgrn, setAddressEgrn] = React.useState<boolean>(false);
  const {propertyInsuranceSubjects} = agreementFormState || {};
  const {titleShare, subjectEncumbrances,cadastralNumber, typeEncumbrances, minors, unregisterPersons, litigation} = ((agreementFormState||{}).lossInsuranceSubjects||[])[number] || {};

  React.useEffect(() => {
    if (!subjectEncumbrances) return;
    setEncumbrances(getRadioDefaultValue(subjectEncumbrances, radioBoolean) || radioBoolean[1]);
    // eslint-disable-next-lines
  }, [subjectEncumbrances])

  const formAddress: IFormField[] = [
    {
      fieldItems: {
        type: "address",
        name: `titleAddress-${number}`,
        placeholder: "Адрес",
        isRequired: true,
        errorMessage: "Выберите адрес",
        defaultValue: (((propertyInsuranceSubjects || [])[number] || {}).pledgeAddress||{}).unrestricted_value
      }
    },
    {
      fieldItems: {
        type: "input",
        name: `cadastralNumber-${number}`,
        placeholder: "Кадастровый номер",
        validationType: "cadastralNumber",
        defaultValue: ((propertyInsuranceSubjects || [])[number] || {}).cadastralNumber || cadastralNumber
      }
    },
    {
      fieldMargin: 0.5,
      isHidden: !stateAddressEgrn,
      fieldItems: {
        type: "input",
        name: `titleAddressEgrn-${number}`,
        placeholder: "Ввод адреса из ЕГРН",
        defaultValue: ((propertyInsuranceSubjects || [])[number] || {}).pledgeAddressEgrn,
      }
    }
  ]

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "range",
        name: `titleShare-${number}`,
        placeholder: "Доля титула",
        isRequired: true,
        startValue: 0,
        endValue: 100,
        defaultValue: index === 0 && !titleShare ? 100 : titleShare,
        errorMessage: "Выберите долю",
      }
    },
    {
      label: "Наличие действующих обременений объекта залога (рента, арест, залог, доверительное управление)?",
      fieldItems: {
        type: "radio",
        name: `subjectEncumbrances-${number}`,
        onChange: (value) => setEncumbrances(value),
        defaultValue: stateEncumbrances,
        items: radioBoolean
      }
    },
    {
      isHidden: stateEncumbrances.value === false,
      fieldItems: {
        type: "input",
        name: `typeEncumbrances-${number}`,
        defaultValue: typeEncumbrances,
        placeholder: "Вид обременения",
        isRequired: true,
        errorMessage: "Введите вид обременения"
      }
    },
    {
      label: "Наличие несовершеннолетних членов семьи, являющихся собственниками",
      fieldItems: {type: "radio", name: `minors-${number}`, defaultValue: getRadioDefaultValue(minors, radioBoolean) || radioBoolean[1], items: radioBoolean}
    },
    {
      label: "Наличие граждан, незарегистрированных в объекте залога, но имеющих право пользования (ранее выписанных в месте лишения свободы, призванных в армию и т.д.)",
      fieldItems: {
        type: "radio",
        name: `unregisterPersons-${number}`,
        defaultValue: getRadioDefaultValue(unregisterPersons, radioBoolean) || radioBoolean[1],
        items: radioBoolean
      }
    },
    {
      label: "Является ли предмет залога объектом судебных разбирательтв в прошлом или в настоящее время?",
      fieldItems: {type: "radio", name: `litigation-${number}`, defaultValue: getRadioDefaultValue(litigation, radioBoolean) || radioBoolean[1], items: radioBoolean}
    }
  ];
  return (
    <>
      {subjectsLength > 1 && <StyledSubjectHeader>
          <Heading3>Объект страхования {index + 1}</Heading3>
          <Button appearance="icon" onClick={() => deleteHandler()} icon={<TrashIcon/>}/>
      </StyledSubjectHeader>}
      <FieldsBuilder formFields={formAddress}/>
      <StyledAddressButton>
        <Button appearance="link" onClick={ev => {
          ev.preventDefault();
          setAddressEgrn(!stateAddressEgrn)
        }}>Ввести адрес как в ЕГРН</Button>
      </StyledAddressButton>
      <FieldsBuilder formFields={form}/>
    </>
  )
};

export const InsuranceLossSubjects = () => {
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const [stateSubjectList, setSubjectList] = React.useState<number[]>([0]);
  const [stateDeleteSubjectList, setDeleteSubjectList] = React.useState<number[]>([]);
  const {lossInsuranceSubjects} = agreementFormState || {};

  React.useEffect(() => {
    if (!lossInsuranceSubjects || lossInsuranceSubjects?.length === stateSubjectList.length) return;
    setSubjectList(Array.from({length: lossInsuranceSubjects.length}).map((coBorrower, index) => index))
    // eslint-disable-next-line
  }, [lossInsuranceSubjects?.length])

  const addSubjectHandler = (ev) => {
    ev.preventDefault();
    const nextSubject = stateSubjectList.length + stateDeleteSubjectList.length;
    setSubjectList([...stateSubjectList, nextSubject]);
  };

  const deleteSubjectHandler = (index: number) => {
    setDeleteSubjectList([...stateDeleteSubjectList, index])
  };

  const filterSubject = stateSubjectList.filter((subject) => !stateDeleteSubjectList.includes(subject));

  return (
    <>
      {filterSubject.map((subject, index) =>
        <Subject
          key={`coBorrower-${subject}`}
          index={index}
          number={subject}
          subjectsLength={filterSubject.length}
          deleteHandler={() => deleteSubjectHandler(subject)}/>)
      }
      <Button appearance="link" iconBefore={<AddIcon/>} onClick={addSubjectHandler}>Добавить объект
        страхования</Button>
    </>
  )
}

export default InsuranceLossSubjects;

export const insuranceLossSubjectNames: string[] = [
  "subjectEncumbrances",
  "typeEncumbrances",
  "titleShare",
  "titleAddress",
  "cadastralNumber",
  "titleAddressEgrn",
  "minors",
  "unregisterPersons",
  "litigation"]