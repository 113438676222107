import React                                                                           from "react";
import {StyledFileItem, StyledUploadFileArea, StyledFileEventsButton, StyledFileError} from "./styles";
import Button                                                                          from "../button/button";
import {AddFileIcon}                                                                   from "../../icons/ui/AddFile";
import {TextButton}                                                                    from "../../globalStyledComponents";
import {StyledFileName}                                                                from "../../pages/createRequest/components/insuranceStep/components/onlineFilling/styles";
import {FileIcon}                                                                      from "../../icons/ui/File";
import {TrashIcon}                                                                     from "../../icons/ui/Trash";
import {SaveFileIcon}                                                                  from "../../icons/ui/SaveFile";
import {StyledFilesEventsButtons}                                                      from "../../pages/createRequest/components/insuranceStep/components/uploadFilesFilling/styles";
import {idGenerator}                                                                   from "../../helpers/idGenerator";

interface IUploadProps {
  onChange: (files) => void
  isLoading?: boolean
  isDisabled?: boolean
  value?: any[]
  onSave?: () => void
}

export const UploadFile = React.memo(({value, onChange, isLoading, onSave, isDisabled}: IUploadProps) => {
  const id: string = React.useMemo(() => idGenerator(), []);
  const [stateFiles, setFiles] = React.useState<any[]>([]);
  const [stateError, setError] = React.useState<string | undefined>(undefined);


  React.useEffect(() => {
    if ((Array.isArray(value) && value.length === 0) || !value) return;
    if ((value || []).reduce((prev, file) => `${prev}${((file || {}).name)}`, "") === (stateFiles || []).reduce((prev, file) => `${prev}${((file || {}).name)}`, "")) return;
    setFiles(value);
    // eslint-disable-next-line
  }, [(value || []).reduce((prev, file) => `${prev}${((file || {}).name)}`, "")])

  React.useEffect(() => {
    onChange(stateFiles);
    // eslint-disable-next-line
  }, [stateFiles.length])

  React.useEffect(() => {
    if (!stateError) return;
    setTimeout(() => setError(undefined), 3000)
    // eslint-disable-next-line
  }, [stateError])

  const addFileHandler = ({target: {files}}) => {
    if ((files||[]).length === 0) return setError("Файлы не выбраны");
    const stateFileNames: string[] = stateFiles.map(({name}) => name);
    const filterFiles = Object.entries(files).reduce<any>((prev, [, value]) => {
      const {name} = value as any || {};
      if (stateFileNames.includes(name)) return prev;
      return [...prev, value];
    }, [])
    setFiles([...stateFiles, ...filterFiles])
    const uploadFileInput: any = document.getElementById(`uploadFileInput-${id}`);
    if (!uploadFileInput) return;
    uploadFileInput.value = "";
  };

  const deleteFileHandler = (deleteName: string) => {
    setFiles(stateFiles.filter(({name}) => name !== deleteName))
  };

  const inputClickHandler = () => {
    const uploadFileInput = document.getElementById(`uploadFileInput-${id}`);
    if (!uploadFileInput) return;
    uploadFileInput.click();
  };

  return (
    <>
      {stateFiles.length !== 0 && stateFiles.map(({name}, index) => (
        <StyledFileItem key={`uploaded-file-${index}`}>
          <StyledFileName>
            <FileIcon/>
            <TextButton>
              {name?.length > 35 ? `${name.substr(0, 35)}...` : name}
            </TextButton>
          </StyledFileName>
          <StyledFileEventsButton>
            <Button appearance="link" type='button' onClick={() => deleteFileHandler(name)} iconBefore={<TrashIcon/>}>Удалить</Button>
          </StyledFileEventsButton>
        </StyledFileItem>
      ))}
      <StyledUploadFileArea isInvalid={!!stateError}>
        {stateError ?
          <StyledFileError>
            <TextButton>{stateError}</TextButton>
          </StyledFileError>
          :
          <TextButton>Перетащите файлы в это поле</TextButton>}
        <input type="file"
               multiple
               onChange={addFileHandler}
               id={`uploadFileInput-${id}`}/>
      </StyledUploadFileArea>

      <StyledFilesEventsButtons>
        <Button
          appearance="link"
          isLoading={isLoading}
          onClick={(ev) => {
            ev.preventDefault();
            inputClickHandler()
          }}
          isDisabled={!!isDisabled}
          iconBefore={<AddFileIcon/>}>
          Прикрепить файлы
        </Button>
        {onSave && <Button
            iconBefore={<SaveFileIcon/>}
            appearance="link"
            onClick={(ev) => {
              ev.preventDefault();
              if (!onSave) return;
              return onSave();
            }}
            isDisabled={stateFiles.length === 0 || !!isDisabled}
            isLoading={isLoading}>
            Сохранить прикрепленные
        </Button>}
      </StyledFilesEventsButtons>
    </>
  )
});

export default UploadFile;