import React                                 from "react";
import {IFormField}                          from "../../../../uiKit/fieldsBuilder/interfaces";
import {
  radioBoolean
}                                            from "../../staticData";
import FieldsBuilder                         from "../../../../uiKit/fieldsBuilder";
import {IRadioItem}                          from "../../../../uiKit/radio";
import Form                                  from '@atlaskit/form';
import {getFormError}                                                        from "../../../../formUtils/getFormError";
import {getFormValue, getSelectDefaultValue, getSelectValue, stringToNumber} from "../../../../formUtils/getFormValue";
import CoBorrowerList                                                        from "./components/coBorrower";
import {useGetDict}                          from "../../../../hooks/useGetDicti";
import StoreAgreementForm                    from "../../store";
import {getFormValueWithArray} from "../../../../formUtils/getFormValueWithArray";
import {IStepsProviderProps}   from "../stepsProvider";
import StepsLayout                from "../stepsLayout";
import StepsPaginationButton                from "../stepsPaginationButton";
import {useQuery, useMutation}              from "@apollo/react-hooks";
import {GET_AGREEMENT_AGENTS, ME}           from "../../../../utils/queries";
import {ErrorMessage}                       from "../../../../components/errorMessage";
import {useCallNotification}                from "../../../../hooks/useCallNotification";
import moment                               from "moment";
import {CONTRAGENT_FIND, UPDATE_CONTRAGENT} from "../../../../utils/mutations";
import {getPerson, getRestrictionsObject, SBERBANK_ISN} from "../../../../helpers/buildTemplateForBack";
import {formatDateToUsa}                                from "../../../../formUtils/fieldsValidation";
import {ISelectItem}                        from "../../../../uiKit/select";
import {CONTRAGENT_FIELDS, IContragentItem} from "../generalStep";
import UseSaveAddressPerson                 from "../../../../hooks/useSaveAddressPerson";
import {rolesRightHandler} from "../../../../helpers/rolesRightHandler";
import {isMoRegion, isNewRegion} from "../../../../utils/address";

export const AGREEMENT_FIELDS: string[] = ["insureType", "creditOrganizationName", "summ", "creditTerms", "creditRate", "creditIssueDate", "firstPayDate", "salesChannel", "borrowerShare", "dealCountry", "prevInsuranceCompany"]

export const AgreementForm = ({defaultValue}: { defaultValue?: any }) => {
  const {data: selectSalesChannel, loading: loadingSalesChannel} = useGetDict("saleChannel");
  const {data: selectAgreementTypes, loading: loadingAgreementTypes} = useGetDict("agreementTypes");
  const {data: selectBanks, loading: loadingBanks} = useGetDict("banks");
  const {data: selectSbpBanks, loading: loadingSbpBanks} = useGetDict("sbpBanks");
  const {data: selectInsuranceCompany, loading: loadingInsuranceCompany} = useGetDict("insuranceCompany");
  const [stateCreditOrganizationName, setCreditOrganizationName] = React.useState<ISelectItem | undefined>(undefined);
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {data: dataAgents, loading: loadingAgents} = useQuery(GET_AGREEMENT_AGENTS);
  const selectAgents = ((dataAgents||{}).getAgent||[]).map(({SUBJISN, AGENTNAME}) => ({label: AGENTNAME, value: SUBJISN}));
  const {type, kias, isAdmin, role} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {UpperDeptISN} = kias || {};
  const today = moment().format("DD.MM.YYYY");
  const [stateHavePromocode, setHavePromocode] = React.useState<boolean>(false);
  const [stateInsureType, setInsureType] = React.useState<any>(null);

  const isOnlyLife: boolean = !!defaultValue.riskLife && !defaultValue.riskProperty && !defaultValue.riskTitle;

  const showPromocode = rolesRightHandler("promocode", functions, type, isAdmin)

  const {
    borrowerShare,
    insureType,
    summ,
    creditOrganizationName,
    creditTerms,
    creditRate,
    creditIssueDate,
    firstPayDate,
    salesChannel,
    agentIsn,
    riskLife,
    dealCountry,
    peoples,
    prevInsuranceCompany,
  } = defaultValue || {};

  React.useEffect(() => {
    if (creditOrganizationName?.value === stateCreditOrganizationName?.value) return;
    setCreditOrganizationName(creditOrganizationName);
    // eslint-disable-next-line
  }, [creditOrganizationName?.value])

  const form: IFormField[] = [
    {
      fieldItems: {
        placeholder: "Населенный пункт сделки",
        type: "address",
        name: "dealCountry",
        defaultValue: dealCountry,
        isRequired: true,
      }
    },
    {
      label: "Вид договора",
      fieldItems: {
        type: "radio",
        columns: 2,
        columnGap: 4,
        name: "insureType",
        isLoading: loadingAgreementTypes,
        items: selectAgreementTypes,
        defaultValue: getSelectDefaultValue(insureType, selectAgreementTypes) || selectAgreementTypes[0],
        returnFullValue: true,
        onChange: (value) => {
          setInsureType(value)
        },
      }
    },
    {
      isHidden: (stateInsureType || {}).value !== "4",
      fieldItems: {
        type: "select",
        name: "prevInsuranceCompany",
        returnFullValue: true,
        isRequired: true,
        isLoading: loadingInsuranceCompany,
        errorMessage: "Выберите предыдущую СК",
        placeholder: "Предыдущая СК",
        items: selectInsuranceCompany,
        isSearchAvailable: true,
        isClearable: true
      }
    },

    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "select",
          name: "creditOrganizationName",
          returnFullValue: true,
          isRequired: true,
          isLoading: loadingBanks,
          defaultValue: stateCreditOrganizationName,
          onChange: value => setCreditOrganizationName(value),
          errorMessage: "Выберите наименование",
          placeholder: "Наименование кредитной организации",
          items: selectBanks,
          isSearchAvailable: true,
          isClearable: true
        },
        {
          type: "number",
          name: "summ",
          isRequired: true,
          digitsAfterDot: 2,
          defaultValue: summ,
          errorMessage: "Введите размер кредита",
          placeholder: "Размер кредита",
          validationType: "minMax",
          maxValue: 1000000000
        }
      ]
    },

    {
      fieldItems: {
        type: "number",
        name: "creditTerms",
        defaultValue: creditTerms ? creditTerms : SBERBANK_ISN === stateCreditOrganizationName?.value ? "12" : undefined,
        isRequired: true,
        isDisabled: SBERBANK_ISN === stateCreditOrganizationName?.value,
        validationType: "minMax",
        maxValue: 600,
        errorMessage: "Введите срок кредитования",
        placeholder: "Срок кредитования (месяцы) / оставшийся срок кредитования"
      }
    },
    {
      isHidden: SBERBANK_ISN === stateCreditOrganizationName?.value,
      fieldItems: {
        type: "number",
        name: "creditRate",
        digitsAfterDot: 2,
        defaultValue: creditRate,
        isRequired: true,
        errorMessage: "Введите ставку",
        placeholder: "Годовая процентная ставка по кредиту, %",
        validationType: "minMax",
        maxValue: 100
      }
    },
    {
      fieldItems: {
        type: "date",
        name: "creditIssueDate",
        defaultValue: creditIssueDate ? creditIssueDate : SBERBANK_ISN === stateCreditOrganizationName?.value ? today : undefined,
        isRequired: true,
        errorMessage: "Выберите дату",
        validationType: "minMaxDate",
        placeholder: "Дата кредитного договора",
        maxDate: "01.01.2100",
        minDate: "01.01.1950"
      }
    },
    {
      fieldItems: {
        type: "date",
        name: "firstPayDate",
        isDisabled: SBERBANK_ISN === stateCreditOrganizationName?.value,
        defaultValue: firstPayDate ? firstPayDate : SBERBANK_ISN === stateCreditOrganizationName?.value ? today : undefined,
        placeholder: "Дата первого платежа (дата списания аннуитета)"
      }
    },
    {
      fieldItems: {
        type: "select",
        name: "salesChannel",
        returnFullValue: true,
        isRequired: true,
        isDisabled: type !== "admin",
        defaultValue: getSelectDefaultValue(salesChannel, selectSalesChannel) || selectSalesChannel[0],
        isLoading: loadingSalesChannel,
        errorMessage: "Выберите канал продаж",
        placeholder: "Канал продаж",
        items: selectSalesChannel,
        isSearchAvailable: true,
        isClearable: true
      }
    },
    {
      isHidden: UpperDeptISN === "171602000",
      fieldItems: {
        type: "select",
        name: "agentIsn",
        defaultValue: getSelectDefaultValue(agentIsn, selectAgents),
        isLoading: loadingAgents,
        placeholder: "Добавить агента",
        items: selectAgents,
        isSearchAvailable: true,
        isClearable: true,
      }
    },
    {
      isHidden: !riskLife,
      fieldItems: {
        type: "range",
        name: "borrowerShare",
        isRequired: true,
        defaultValue: isOnlyLife || !borrowerShare ? 100 : borrowerShare,
        errorMessage: "Выберите долю заемщика",
        placeholder: "Доля заемщика",
        startValue: 0,
        endValue: 100
      }
    },
    {
      isHidden: !showPromocode,
      fieldItems: {
        type: "checkbox",
        name: "havePromocode",
        defaultValue: stateHavePromocode,
        label: "Есть промокод?",
        onChange: (value) => setHavePromocode(value)
      }
    },
    {
      columns: 3,
      columnGap: 4,
      isHidden: !showPromocode || !stateHavePromocode,
      fieldItems: [
        {
          type: "input",
          name: "promocode",
          isRequired: true,
          errorMessage: "Введите промокод",
          placeholder: "Промокод"
        },
        {
          type: "input",
          name: "promocodePhone",
          isRequired: true,
          debounce: true,
          mask: "+7 (999) 999-99-99",
          defaultValue: peoples && peoples[0] ? peoples[0]?.phoneMobile : undefined,
          validationType: "phone",
          errorMessage: "Введите телефон",
          placeholder: "Телефон для кешбека"
        },
        {
          type: "select",
          name: "promocodeBank",
          returnFullValue: true,
          isRequired: true,
          isLoading: loadingSbpBanks,
          errorMessage: "Выберите банк",
          placeholder: "Банк для кешбека",
          items: selectSbpBanks,
          isSearchAvailable: true,
          isClearable: true
        },
      ]
    },
  ];

  if (errorUser) return <ErrorMessage error={errorUser}/>

  return <FieldsBuilder formFields={form}/>
}

export const AgreementStep = ({title, id, step, stepItems, changeStep, showSaveModal, editType}: IStepsProviderProps) => {
  const {setNotification} = useCallNotification();
  const {saveAddress, loading: loadingSaveAddress} = UseSaveAddressPerson();
  const insuranceStep: number = stepItems.findIndex(({id}) => id === "insuranceForm");
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });

  const {data: dataUser} = useQuery(ME);
  const [stateCoBorrowers, setCoBorrowers] = React.useState<IContragentItem[]>([]);
  const [stateHaveCoBorrower, setHaveCoBorrower] = React.useState<IRadioItem>(radioBoolean[1]);
  const [findContragent, {loading: loadingFindContragent}] = useMutation(CONTRAGENT_FIND);
  const [updateContragent, {loading: loadingUpdateContragent}] = useMutation(UPDATE_CONTRAGENT);
  const {coBorrower, riskLife} = agreementFormState || {};
  const {restrictions, group} = (dataUser || {}).me || {};
  const restrictionsValue = getRestrictionsObject(restrictions);
  const maxAgeValidation = riskLife && !isNaN(restrictionsValue?.contragentMaxAge) ? restrictionsValue?.contragentMaxAge : undefined;


  React.useEffect(() => {
    if (!coBorrower || coBorrower === stateHaveCoBorrower?.value) return;
    setHaveCoBorrower(radioBoolean.find(({value}) => value === coBorrower) || radioBoolean[1]);
    // eslint-disable-next-line
  }, [coBorrower])


  const haveCoBorrowerForm: IFormField[] = [
    {
      isHidden: !riskLife,
      label: "Есть ли созаемщики по кредиту?",
      fieldItems: {
        type: "radio",
        name: "coBorrower",
        defaultValue: stateHaveCoBorrower,
        onChange: (value) => setHaveCoBorrower(value),
        items: radioBoolean
      }
    }
  ]

  const nextStepHandler = (formData, coBorrowers) => {
    const oldCoBorrowers: any[] = getPerson(agreementFormState, "coBorrower");
    const oldContragent = getPerson(agreementFormState, "contragent");
    const newCoBorrower: any[] = coBorrowers.map(coBorrower => {
      const {isn} = coBorrower || {};
      const old = oldCoBorrowers.find(({isn: isnOld}) => isn === isnOld);
      if (old) return {...old, ...coBorrower};
      return coBorrower;
    })
    updateAgreementForm({
      ...formData,
      peoples: [{...oldContragent, share: formData.borrowerShare}, ...newCoBorrower]
    })
    if (editType === "edit" && (insuranceStep < 0)) return showSaveModal();
    if (editType === "recalculation") return showSaveModal();
    if (editType === "edit" && insuranceStep) return changeStep(insuranceStep + 1);
    return changeStep(step + 1)
  }

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValueWithArray(getFormValue(data), [...CONTRAGENT_FIELDS, "share"], "coBorrowers");

    const {borrowerShare, creditIssueDate, firstPayDate, coBorrowers, dealCountry, prevInsuranceCompany, insureType} = formData;
    const coBorrowersList: any[] = [];

    let ageError: boolean = false;
    let maxAgeError: boolean = false;
    coBorrowers.forEach(({birthDate}) => {
      const age: number = moment().diff(formatDateToUsa(birthDate), 'years');
      if (age < 14) ageError = true;
      if (maxAgeValidation && age > maxAgeValidation) maxAgeError = true;
    });
    const passportError: boolean = coBorrowers.reduce((prev, {birthDate, authDocDate}) => {
      if (moment(formatDateToUsa(authDocDate)).isBefore(formatDateToUsa(birthDate))) return true;
      return prev;
    }, false);
    const totalShare: number = coBorrowers.reduce((prev, {share}) => prev + stringToNumber(share), stringToNumber(borrowerShare))
    if (ageError) return setNotification({type: "error", text: "Запрещено для лиц моложе 14 лет"});
    if (maxAgeError) return setNotification({type: "error", text: `Принятие по личному страхованию  лиц старше ${maxAgeValidation} лет невозможно`});
    if (passportError) return setNotification({type: "error", text: "Дата выдачи паспорта не может быть раньше даты рождения"});
    if (moment(formatDateToUsa(firstPayDate)).isBefore(formatDateToUsa(creditIssueDate))) return setNotification({
      type: "error",
      text: "Дата первого платежа не может быть раньше даты выдачи кредита"
    })
    if (!!dealCountry && !dealCountry?.data?.city) return {dealCountry: "Выберите вплоть до города"}
    if(!!dealCountry){
      if(isMoRegion(dealCountry) && group?.is_filial || isNewRegion(dealCountry)){
        return setNotification({type: "error", text: "Расчет по выбранным параметрам невозможен. Обратитесь к куратору!"});
      }
    }

    if (totalShare === 0 && riskLife) return setNotification({type: "error", text: "Выберите доли"})
    if (!coBorrowers.length) return nextStepHandler(formData, coBorrowers);

    coBorrowers.forEach((coBorrower, index) => {
      const dataContragent = {...((stateCoBorrowers || [])[index] || {}).data || {}, ...coBorrower};
      const {addr1, addr2, countryIsn, gender, addressRadio, ...other} = dataContragent;
      const {fullName, birthDate, phoneMobile} = dataContragent;
      const isn = stateCoBorrowers[index].isn;
      const variables = {
        ...other,
        isn,
        gender: getSelectValue(gender),
        countryIsn: getSelectValue(countryIsn)
      };
      if (isn) return updateContragent({variables})
        .then(() => {
          coBorrowersList[index] = {...dataContragent, isn, type: "coBorrower"};
          saveAddress(isn, addr1, "2246");
          if (!addressRadio) saveAddress(isn, addr2, "2247");
          nextStepHandler(formData, coBorrowersList);
        })
        .catch(error => setNotification({type: "error", text: error}))
      return findContragent({
        variables: {
          fullName,
          phone: phoneMobile,
          birthday: birthDate,
          needCreated: 1
        }
      })
        .then(({data: {GetSubjectInfo}}) => {
          const dataContragent = {...(GetSubjectInfo || [])[0] || {}, ...coBorrower};
          const {isn, addr1, addr2, countryIsn, gender, addressRadio, ...other} = dataContragent;
          const variables = {
            ...other,
            isn,
            gender: getSelectValue(gender),
            countryIsn: getSelectValue(countryIsn)
          };
          updateContragent({variables})
            .then(() => {
              coBorrowersList[index] = {...dataContragent, isn, type: "coBorrower"};
              saveAddress(isn, addr1, "2246");
              if (!addressRadio) saveAddress(isn, addr2, "2247");
              nextStepHandler(formData, coBorrowersList);
            })
            .catch(error => setNotification({type: "error", text: error}))
        })
        .catch(error => setNotification({type: "error", text: error}))
    })
  }

  return (
    <Form onSubmit={data => onFormSubmit(data)}>
      {({formProps}) => (
        <form {...formProps} id={id}>
          <StepsLayout title={title}>
            <AgreementForm defaultValue={agreementFormState}/>
            <FieldsBuilder formFields={haveCoBorrowerForm}/>
            {stateHaveCoBorrower.value && <CoBorrowerList stateCoBorrowers={stateCoBorrowers} setCoBorrowers={setCoBorrowers}/>}
          </StepsLayout>
          <StepsPaginationButton disableBackButton={editType === "edit"} saveImmediately={(editType === "edit" && insuranceStep < 0) || editType === "recalculation"} formId={id} isLoading={loadingFindContragent || loadingSaveAddress || loadingUpdateContragent}/>
        </form>
      )}
    </Form>
  )
}

export default AgreementStep;