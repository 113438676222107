import styled                           from "styled-components";
import {black, blue7, grey, red, white} from "../../globalStyledComponents";

export const StyledEnumFieldWrapper = styled.div`
  position: relative;
`;
export const StyledEnumItemsWrapper = styled.div<{isInvalid: boolean}>`
  width: 100%;
  background: ${white};
  box-sizing: border-box;
  ${({isInvalid}) => 
    isInvalid ?
      `
      border: 1px solid ${red};
      `
      :
      `
      border: 1px solid ${grey};
      `
  }
  border-top: unset;
  padding: 0.5rem 0;
  max-height: 28rem;
  overflow: auto; 
`;
export const StyledEnumItem = styled.div`
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  
  > div {
    pointer-events: none;
  }
  
  :hover {
    background: ${blue7};
  }
`;

export const StyledSelectEmpty = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`;

export const StyledEnumsLoader = styled.div`
  padding: 1rem;
  svg {
  width: 2.5rem;
  height: 2.5rem;
  color: ${black}!important;
  }
`;