import styled from "styled-components";

export const StyledStepsHeader = styled.div`
  h2 {
    margin: 1rem 0 4rem 0;
  }
  @media screen and (max-width: 767px) {
    h2 {
      margin: 0.5rem 0 2rem 0;
    }    
  }  
`;