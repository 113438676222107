import styled               from "styled-components";
import {black3, blue, grey} from "../../../../../../globalStyledComponents";

const toggleHeight = 6.5;

export const StyledGroupToggle = styled.div`
  height: ${toggleHeight}rem;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  
  border-bottom: 1px solid ${grey};
`;

export const StyledGroupToggleHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  
  :hover {
    color: ${blue};
  }
  h4 {
    margin-left: 2rem;
  }
`;
export const StyledGroupToggleButtons = styled.div`
    display: flex;
  align-items: center;
  
  button {
    margin-right: 2rem;
  }
`;

export const StyledGroupToggleBody = styled.div`
  padding-left: 2.5rem;  
`;

export const StyledGroupToggleWrapper = styled.div`

`;

export const StyledGroupBodyLoader = styled.div`
  width: 100%;
  height: ${toggleHeight}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${black3};
`;