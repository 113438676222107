import React          from 'react';
import {useLazyQuery} from "@apollo/react-hooks";
import {GROUPS}       from "../../../utils/queries";
import LoadingString  from "../../../uiKit/loadingString";

const RolesItem = ({roleId}: { roleId?: string }) => {
  const [getGroup, {data: dataGroups, loading: loadingGroups, error: errorGroups}] = useLazyQuery(GROUPS);
  const {name, parent} = ((dataGroups||{}).groups||[])[0] || {};
  React.useEffect(() => {
    if (!roleId) return;
    getGroup({
      variables: {
        id: [roleId]
      }
    })
    // eslint-disable-next-line
  }, [roleId]);

  if (!roleId || name === "Головная компания" || errorGroups) return null;

  return (
    <div>
      <LoadingString isLoading={loadingGroups}>{name}</LoadingString>
      <RolesItem roleId={parent?.id}/>
    </div>
  );
};


export const RolesHierarchy = ({roleId, isAdmin}: { roleId?: string, isAdmin: boolean }) => {
  const [getGroup, {data: dataGroups, loading: loadingGroups}] = useLazyQuery(GROUPS);
  const {name, parent} = ((dataGroups||{}).groups||[])[0] || {};
  React.useEffect(() => {
    if (!roleId) return;
    getGroup({
      variables: {
        id: [roleId]
      }
    })
    // eslint-disable-next-line
  }, [roleId])

  return (
    <div>
      <LoadingString isLoading={loadingGroups}>{isAdmin ? "Администратор" : name || "неизвестно"}</LoadingString>
      <RolesItem roleId={parent?.id}/>
    </div>
  );
};

export default RolesHierarchy;