import styled  from "styled-components";

export const StyledPrecalculationFormWrapper = styled.div`
  .textButton {
    margin-bottom: 1rem;
  }
  
  @media screen and (max-width: 767px) {
    .textButton {
      margin-bottom: 0.5rem;
    }
  }
`;
export const StyledPrecalculationFormCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
`;

export const StyledPrecalculationFormCard = styled.div`
  h2 {
    margin-bottom: 4rem;
  }
  
  .filledButton {
    margin-top: 4rem;
  }
  
  @media screen and (max-width: 767px) {
    h2 {
      margin-bottom: 2rem;
    }
  
  .filledButton {
    margin-top: 1.5rem;
  }
  }
`;