import styled from "styled-components";

export const StyledStatisticsHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  
  @media screen and (max-width: 767px) {
  margin-bottom: 2rem;
  .filledButton {
    width: 100%;
  }
  }
`;