import React from 'react';
import {useMutation}        from "@apollo/react-hooks";
import {useParams}          from "react-router";
import {IRadioItem} from "../../../../uiKit/radio";
import {Modal, ModalProps} from "../../../../uiKit/modal";
import {useCallNotification} from "../../../../hooks/useCallNotification";
import {IFormField} from "../../../../uiKit/fieldsBuilder/interfaces";
import Form                                       from '@atlaskit/form';
import {getFormError} from "../../../../formUtils/getFormError";
import {getFormValue} from "../../../../formUtils/getFormValue";
import FieldsBuilder from "../../../../uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "../../../roles/components/header/styles";
import Button from "../../../../uiKit/button/button";
import {SEND_PAYMENT_LINK} from "../../../../utils/mutations";


const typeRadioItems: IRadioItem[] = [
  {label: "E-mail", value: "email"},
  {label: "Телефон", value: "phone"},
];

const SendPayLinkModal = (props: ModalProps) => {
  const {data} = props || {};
  const {email, phone, contractId} = data || {}
  const {setNotification} = useCallNotification();
  const [stateType, setType] = React.useState<string>(String(typeRadioItems[0].value));


  const [sendPayLink, {loading: loadingSendPayLink}] = useMutation(SEND_PAYMENT_LINK);

  const isHavePhone: boolean = !!phone;


  const form: IFormField[] = [
    {
      isHidden: !isHavePhone,
      fieldItems: {
        type: "radio",
        name: "type",
        items: typeRadioItems,
        onChange: value => setType(value.value),
        defaultValue: typeRadioItems[0]
      }
    },
    {
      isHidden: isHavePhone ? stateType !== "phone" : true,
      fieldItems: {
        type: "input",
        name: "phone",
        placeholder: "Телефон",
        isRequired: true,
        defaultValue: phone,
        errorMessage: "Введите Телефон",
        mask: "+7 (999)-999-99-99"
      }
    },
    {
      isHidden: stateType !== "email",
      fieldItems: {
        type: "input",
        name: "email",
        isRequired: true,
        defaultValue: email,
        validationType: "email",
        errorMessage: "Введите Email",
        placeholder: "Email",
      }
    }
  ]


  const onFormSubmit = (data) => {
    if (!contractId) return;
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValue(data);

    sendPayLink({
      variables: {
        isn: contractId,
        ...formData
      }
    })
        .then(() => {
          const sentTo = formData.email || formData.phone;
          setNotification({type: "success", text: `Ссылка для оплаты отправлена на ${sentTo}`});
          props.onClose();
        })
        .catch(error => setNotification({type: "error", text: error}))
  }

  return (
      <Modal {...props} header={"Отправить"}>

        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
              <form {...formProps}>
                <FieldsBuilder formFields={form}/>
                <StyledButtonsGroup>
                  <Button isLoading={loadingSendPayLink} appearance="transparent" onClick={props.onClose}>Отмена</Button>
                  <Button isLoading={loadingSendPayLink} appearance="filled" type="submit">Отправить</Button>
                </StyledButtonsGroup>
              </form>
          )}
        </Form>
      </Modal>
  );
};

export default SendPayLinkModal;