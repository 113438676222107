import React from 'react';
import {StyledStatisticsCardWarning} from "../styles";
import {Text3} from "../../../globalStyledComponents";

const UserWarning = () => {
    return (
        <StyledStatisticsCardWarning>
            <Text3 style={{color: "red"}}>Тарификатор для вашей точки продаж не настроен. Обратитесь к вашему куратору</Text3>
        </StyledStatisticsCardWarning>
    );
}

export default UserWarning;