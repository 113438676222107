import React                                  from 'react';
import {StyledTableAreaWrapper}               from "./styles";
import Status                                 from "../../../../uiKit/status";
import {Heading3, Heading5}                   from "../../../../globalStyledComponents";
import {useQuery}                             from "@apollo/react-hooks";
import {DOCS, GET_AGREEMENT}                  from "../../../../utils/queries";
import {useParams}                            from "react-router";
import {money}                                from "../../../../utils/formats";
import {
  ALLOWANCE_ISN, BORROWER_ALLOWANCE_ISN,
  CLASS_ISN_BORROWERS,
  CLASS_ISN_OBJECTS,
  CLASS_ISN_TITLES, DISCOUNT_ISN, getValueFromObjectsAttributes, HEIGHT_WEIGHT_ISN, PRESSURE_ISN
} from "../../../../helpers/buildTemplateForBack";
import Table, {ITableHeadItem, ITableRowItem} from "../../../../uiKit/table";
import {ErrorMessage}                         from "../../../../components/errorMessage";
import {stringToNumber}                       from "../../../../formUtils/getFormValue";

export const TableArea = ({isLoading}: { isLoading?: boolean }) => {
  const {id} = useParams();
  const {data: dataAgreement, loading: loadingAgreement, error: errorAgreement} = useQuery(GET_AGREEMENT, {
    variables: {
      isn: id
    }
  });
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {
      isn: id
    }
  });
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {risks} = (docsList || [])[0] || {};
  const {objects} = (dataAgreement || {}).agreementCalc || {};
  const borrowersRisks = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS)
  const propertiesRisks = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS)
  const titleRisks = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_TITLES)
  const headsList = ["Риск", "Статус", "Страховая сумма RUB", "Базовый тариф", "Базовая премия RUB", "Надбавка", "Скидка %", "Общий тариф", "Итоговая премия RUB"];

  const head: ITableHeadItem = {
    cells: headsList.map((content, index) => ({key: `head-${index}`, content}))
  };

  const rowsCategories: { id: string, title: string, risks: any[] }[] = [
    {id: "underwritingRiskLife", risks: borrowersRisks, title: "Жизнь"},
    {id: "underwritingRiskProperty", risks: propertiesRisks, title: "Имущество"},
    {id: "underwritingRiskTitul", risks: titleRisks, title: "Титул"}
  ]

  const rowsBuilder = (risk) => {
    const {
      ISN,
      AGRCOND: agrCondObject,
      ClassISN,
      SubClassName: classNameObject
    } = risk || {};
    const currentRisk = (risks || []).find(({object}) => ISN === object);
    const {status, data: dataCurrentRisk} = currentRisk || {};
    const {basicTariff, basicPremium} = dataCurrentRisk || {};
    const {row: rowAgrCond} = agrCondObject || {};
    const {
      LimitSum,
      Tariff,
      PremiumSum
    } = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} : rowAgrCond || {}
    const borrowerAllowance: number = stringToNumber(getValueFromObjectsAttributes(risk, BORROWER_ALLOWANCE_ISN));
    const heightWeight: number = stringToNumber(getValueFromObjectsAttributes(risk, HEIGHT_WEIGHT_ISN));
    const pressure: number = stringToNumber(getValueFromObjectsAttributes(risk, PRESSURE_ISN));
    const allowance = getValueFromObjectsAttributes(risk, ALLOWANCE_ISN);
    const discount = getValueFromObjectsAttributes(risk, DISCOUNT_ISN);

    return {
      key: `row-${ISN}`,
      id: ISN,
      cells: [
        {key: "1", content: classNameObject},
        {key: "2", content: <Status status={status}/>},
        {key: "3", content: money(LimitSum)},
        {key: "4", content: money(basicTariff, 4)},
        {key: "5", content: money(basicPremium)},
        {
          key: "6",
          content: ClassISN === CLASS_ISN_BORROWERS ? money(borrowerAllowance + heightWeight + pressure) : money(allowance)
        }, // надбавка
        {key: "7", content: money(discount)}, // скидка
        {key: "8", content: money(Tariff, 4)}, // общий тариф
        {key: "9", content: money(PremiumSum)} // итоговая премия
      ]
    }
  }

  const rows: ITableRowItem[] = rowsCategories.reduce<any>((prev, {id, title, risks}) => {
    if (risks.length === 0) return prev;
    return [...prev,
      {
        key: id,
        id,
        width: 10,
        cells: [
          {
            key: id,
            content: <Heading5>{title}</Heading5>
          },
          ...Array.from({length: headsList.length - 1}).map((value, index) => ({
            key: `empty-${id}-${index}`,
            content: ""
          }))
        ]
      },
      ...risks.map(risk => rowsBuilder(risk))
    ]
  }, [])

  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  return (
    <StyledTableAreaWrapper>
      <Heading3>Тарифы, страховые суммы, премии</Heading3>
      <Table
        head={head}
        rows={rows}
        isLoading={loadingDocs || loadingAgreement || isLoading}/>
    </StyledTableAreaWrapper>
  )
};

export default TableArea;