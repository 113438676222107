import React                                                 from "react";
import FieldsBuilder                                         from "../../../../uiKit/fieldsBuilder";
import {IFormField}                                          from "../../../../uiKit/fieldsBuilder/interfaces";
import {radioBoolean, radioSex} from "../../staticData";
import {getFormError}                                        from "../../../../formUtils/getFormError";
import {getFormValue, getSelectDefaultValue, getSelectValue} from "../../../../formUtils/getFormValue";
import Form                                                  from '@atlaskit/form';
import StoreAgreementForm                                    from "../../store";
import {IStepsProviderProps}                                 from "../stepsProvider";
import StepsPaginationButton                                 from "../stepsPaginationButton";
import StepsLayout                                           from "../stepsLayout";
import {useGetDict}                                          from "../../../../hooks/useGetDicti";
import {ISelectItem}                                         from "../../../../uiKit/select";
import {FioValue} from "../../../../uiKit/daDataFioNew/types";
import {IRadioItem} from "../../../../uiKit/radio";

export const BUSYNESS_FIELDS: string[] = ["jobPlace", "organizationName", "address", "isOfficeJob", "position", "field", "isWarrior"]

export const BusynessForm = ({defaultValue}: { defaultValue?: any }) => {
  const {data: selectActivity, loading: loadingActivity} = useGetDict("activity");
  const {data: selectJobPlace, loading: loadingJobPlace} = useGetDict("jobPlace");
  const [stateJobPlace, setJobPlace] = React.useState<ISelectItem | undefined>(undefined);
  const {jobPlace, organizationName, isOfficeJob, isWarrior, position, field} = defaultValue || {};
  const [stateOrganizationName, setOrganizationName] = React.useState();
  const [address, setAddress] = React.useState(undefined);

  React.useEffect(() => {
    if (!jobPlace?.value || jobPlace?.value === stateJobPlace?.value) return;
    setJobPlace(jobPlace);
    // eslint-disable-next-line
  }, [jobPlace?.value])

  const organizationChangeHandler = (value) => {
    console.log(value);
    setOrganizationName(value.value);
    if(value && value.address && value.address.value){
      setAddress(value.address.value);
    }
  };

  const formBusyness: IFormField[] = [
    {
      fieldItems: {
        type: "select",
        name: "jobPlace",
        returnFullValue: true,
        defaultValue: stateJobPlace,
        placeholder: "Настоящее место работы",
        isLoading: loadingJobPlace,
        items: selectJobPlace,
        onChange: value => setJobPlace(value),
        isRequired: true,
        errorMessage: "Введите место работы"
      }
    },
    {
      isHidden: getSelectValue(stateJobPlace) === "3",
      fieldItems: {
        type: "inn",
        name: "organizationName",
        placeholder: "Наименование организации",
        defaultValue: stateOrganizationName,
        isRequired: true,
        onChange: (value) => organizationChangeHandler(value),
        errorMessage: "Введите наименование организации"
      }
    },
    {
      isHidden: getSelectValue(stateJobPlace) === "3",
      fieldItems: {
        type: "address",
        name: "address",
        defaultValue: address,
        placeholder: "Юридический адрес"
      }
    },
    {
      isHidden: getSelectValue(stateJobPlace) === "3",
      label: "Связана ли ваша трудовая деятельность с работой в офисе (офисный сотрудник)?",
      fieldItems: {type: "radio", isRequired: true, name: "isOfficeJob", defaultValue: radioBoolean.find(({value}) => value === isOfficeJob), items: radioBoolean}
    },
    {
      isHidden: getSelectValue(stateJobPlace) === "3",
      fieldItems: {
        type: "input",
        name: "position",
        defaultValue: position,
        placeholder: "Должность",
        isRequired: true,
        errorMessage: "Введите дложность"
      }
    },
    {
      isHidden: getSelectValue(stateJobPlace) === "3",
      fieldItems: {
        type: "select",
        name: "field",
        returnFullValue: true,
        isLoading: loadingActivity,
        defaultValue: getSelectDefaultValue(field, selectActivity),
        placeholder: "Укажите сферу деятельности вашей организации",
        items: selectActivity,
        isRequired: true,
        errorMessage: "Укажите сферу деятельности",
        isSearchAvailable: true,
        isClearable: true,
      }
    },
    {
      isHidden: getSelectValue(stateJobPlace) === "3",
      label: "Является военнослужащим(ей)",
      fieldItems: {type: "radio", isRequired: true, name: "isWarrior", defaultValue: radioBoolean.find(({value}) => value === isWarrior), items: radioBoolean}
    },
  ]

  return <FieldsBuilder formFields={formBusyness}/>
}

export const BusynessStep = ({title, id, step, changeStep}: IStepsProviderProps) => {
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    updateAgreementForm(getFormValue(data))
    changeStep(step + 1)
  }

  return (
    <Form onSubmit={data => onFormSubmit(data)}>
      {({formProps}) => (
        <form {...formProps} id={id}>
          <StepsLayout title={title}>
            <BusynessForm defaultValue={agreementFormState}/>
          </StepsLayout>

          <StepsPaginationButton formId={id}/>
        </form>
      )}
    </Form>
  )
}

export default BusynessStep;