import React              from 'react';

export const LogoMobile = () => (
    <svg width="3.9rem" height="2.8rem" viewBox="0 0 39 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.7596 0H20.0068V26.852H26.7596V0Z" fill="#003D81"/>
      <path
        d="M20.0068 16.4747C19.3103 17.9982 18.1136 19.2376 16.6155 19.9869C15.1173 20.7363 13.4079 20.9506 11.7712 20.5942C10.1344 20.2378 8.66882 19.3322 7.61787 18.0278C6.56691 16.7234 5.99384 15.0987 5.99384 13.4236C5.99384 11.7485 6.56691 10.1238 7.61787 8.81938C8.66882 7.51497 10.1344 6.60936 11.7712 6.25298C13.4079 5.89659 15.1173 6.11089 16.6155 6.86026C18.1136 7.60964 19.3103 8.84899 20.0068 10.3725V1.77762C18.2247 0.769545 16.2354 0.183049 14.1914 0.06312C12.1475 -0.0568094 10.1032 0.293014 8.21549 1.08576C6.32774 1.8785 4.64664 3.09309 3.30116 4.63638C1.95567 6.17966 0.98155 8.01061 0.453512 9.9888C-0.0745268 11.967 -0.14245 14.0398 0.254953 16.0484C0.652356 18.0569 1.50452 19.9477 2.74609 21.5757C3.98766 23.2038 5.58563 24.5258 7.41744 25.4404C9.24924 26.3551 11.2662 26.838 13.3136 26.8521C15.6624 26.8602 17.9715 26.247 20.0068 25.0745V16.4747Z"
        fill="#41B6E6"/>
      <path
        d="M38.3478 23.1977C38.3478 23.9666 38.1198 24.7183 37.6926 25.3576C37.2654 25.9969 36.6582 26.4953 35.9478 26.7895C35.2374 27.0838 34.4557 27.1608 33.7016 27.0107C32.9474 26.8607 32.2547 26.4905 31.711 25.9467C31.1673 25.403 30.797 24.7103 30.647 23.9561C30.497 23.202 30.574 22.4203 30.8682 21.7099C31.1625 20.9995 31.6608 20.3923 32.3001 19.9651C32.9395 19.5379 33.6911 19.3099 34.4601 19.3099C35.4908 19.3112 36.4789 19.7212 37.2077 20.45C37.9365 21.1788 38.3465 22.167 38.3478 23.1977Z"
        fill="#003D81"/>
    </svg>
);
