import styled                  from "styled-components";
import {black40, blue3, white} from "../../globalStyledComponents";

export const StyledLayout = styled.div`
  display: flex;
  overflow-x: hidden;
`;
export const StyledContentArea = styled.div<{isMenuOpen: boolean}>`
  will-change: max-width;
  transition: max-width 0.3s;
  ${({isMenuOpen}) =>
  isMenuOpen ?
    "max-width: calc(100% - 26rem);"
    :
    "max-width: 100%;"
}
  width: 100%;
  position: relative;
`;

export const StyledContent = styled.div<{ isAuth: boolean, isBackground: boolean }>`
  ${({isAuth, isBackground}) =>
  isAuth ?
    `
        width: 100%;
        min-height: 100vh;
        background: ${white};
      
      `
    :
    `
        width: calc(100% - 8rem);
        min-height: calc(100vh - 14.5rem);
        padding: 4rem;
        background: ${ isBackground ? blue3 : white};
        @media screen and (max-width: 767px) {
          padding: 4rem 1.5rem;
          width: calc(100% - 3rem);
          min-height: calc(100vh - 14rem);
        }
      `
}
`;

export const StyledMobileShadow = styled.div`
    z-index: 15;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${black40};
`;