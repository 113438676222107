import React                                                                  from "react";
import Button                                                                 from "../../../../uiKit/button/button";
import {ArrowIcon}                                                            from "../../../../icons/ui/Arrow";
import {useHistory}                                                           from "react-router";
import PrecalculationForm                                                     from "./components/precalculationForm";
import PrecalculationResult, {IPrecalculationItem}                            from "./components/precalculationResult";
import {StyledPrecalculationFormCardWrapper, StyledPrecalculationFormWrapper} from "./styles";
import {PRECALCULATION}                                                       from "../../../../utils/mutations";
import {useMutation}                                                          from '@apollo/react-hooks';
import {useGetDict}                                                           from "../../../../hooks/useGetDicti";
import StoreAgreementForm                                                     from "../../store";
import {useCallNotification}                                                  from "../../../../hooks/useCallNotification";
import {getSelectValue}                                                       from "../../../../formUtils/getFormValue";

export const PrecalculationStep = () => {
  const {setNotification} = useCallNotification();
  const history = useHistory();
  const {data: dataBanks} = useGetDict("banks");
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const [loadPrecalculation, {data: dataPrecalculation, loading: loadingPrecalculation}] = useMutation(PRECALCULATION);
  const precalculationItems: IPrecalculationItem[] = ((dataPrecalculation || {}).preCalc || []).map(({isn, bank: {isn: bankIsn}, riskTitle, tariffTitle, riskProperty, tariffProperty, summ: sumInsured, total, borrowers}) => {
    const bankName = (dataBanks.find(({value}) => value === bankIsn) || {}).label || "unknown"
    return ({
      isn,
      bankIsn,
      bankName,
      sumInsured,
      borrowers,
      riskTitle,
      tariffTitle,
      riskProperty,
      tariffProperty,
      premium: total
    })
  })

  React.useEffect(() => {
    if (precalculationItems.length === 0) return;
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }, [precalculationItems.length])

  const loadResultHandler = (data) => {
    const {borrowers, insureType, objectType} = data;
    loadPrecalculation({
      variables: {
        ...data,
        insureType: getSelectValue(insureType),
        insureTypeName: getSelectValue(insureType, true),
        objectType: getSelectValue(objectType),
        objectTypeName: getSelectValue(objectType, true),
      }
    })
      .then(() => updateAgreementForm({
          ...data,
          borrowerShare: ((borrowers || [])[0] || {}).share || 100,
          birthDate: ((borrowers || [])[0] || {}).birthDate
        }))
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <StyledPrecalculationFormWrapper>
      <Button
        onClick={() => history.goBack()}
        appearance="text"
        iconBefore={<ArrowIcon direction="left"/>}>
        Назад
      </Button>
      <StyledPrecalculationFormCardWrapper>
        <PrecalculationForm loadResultHandler={loadResultHandler} loading={loadingPrecalculation}/>
        <PrecalculationResult precalculationItems={precalculationItems}
                              loading={loadingPrecalculation}/>
      </StyledPrecalculationFormCardWrapper>
    </StyledPrecalculationFormWrapper>
  )
}

export default PrecalculationStep;