import React from "react";
import {
  StyledBankCardsWrapper,
  StyledResultButtonsWrapper,
  StyledBankCard,
  StyledBankCardHeader,
  StyledBankLogo,
  StyledBankPremium,
  StyledBankTable,
  StyledBorrowerNeedOsm,
  StyledBankTableColumns
} from "./styles";
// import SendResultModal                       from "./sendResultModal";
import {StyledPrecalculationFormCard} from "../../styles";
import {DownloadIcon} from "../../../../../../icons/ui/Download";
import {Heading2, Heading3, Heading4, Text4} from "../../../../../../globalStyledComponents";
import {Loader} from "../../../../../../uiKit/loader";
import Button from "../../../../../../uiKit/button/button";
import Checkbox from "../../../../../../uiKit/checkbox";
import {money} from "../../../../../../utils/formats";
import StoreAgreementForm from "../../../../store";
import StoreAgreementStep from "../../../../store";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {DOWNLOAD_PRE_CALC, ME} from "../../../../../../utils/queries";
import {rolesRightHandler} from "../../../../../../helpers/rolesRightHandler";
import {QuestionIcon} from "../../../../../../icons/ui/Question";
import {ErrorMessage} from "../../../../../../components/errorMessage";
import {useCallNotification} from "../../../../../../hooks/useCallNotification";
import {fileDownloader} from "../../../../../../helpers/fileDownloader";
import {getSelectValue} from "../../../../../../formUtils/getFormValue";
import {getVariablesForTemplate} from "../../../../../../helpers/getVariablesForTemplate";
// import {SendIcon}                            from "../../../../../../icons/ui/Send";

const getRiskHead = (risk: "Титул" | "Имущество" | "Жизнь") => [`Риск. ${risk}`, "Тариф, %", "Страх. премия"]

const getRiskLifeRows = borrower => {
  const {number, tariffLife, riskLife, needOms} = borrower || {};
  return [
    <StyledBorrowerNeedOsm>
      <Text4>{number < 2 ? "Заемщик" : "Созаемщик"}</Text4>
      {needOms &&
      <Button appearance="icon" icon={<QuestionIcon/>} tooltip="Необходимо прохождение медицинского обследования."/>}
    </StyledBorrowerNeedOsm>,
    <Text4 className="precalculationRow">{money(tariffLife, 4)}</Text4>,
    <Text4 className="precalculationRow">{money(riskLife)}</Text4>
  ];
}

const getRowsForRisk = (tariff: number, premium: number, objectTypeName: string) => [objectTypeName || "unknown", money(tariff, 4), money(premium)];

const RiskTable = ({
                     risk,
                     borrowers,
                     premium,
                     tariff,
                     objectTypeName
                   }: { risk: "Титул" | "Имущество" | "Жизнь", borrowers?: any[], tariff?: number, premium?: number, objectTypeName?: string }) => {
  return (
    <StyledBankTable>
      <StyledBankTableColumns>
        {getRiskHead(risk).map((head, index) =>
          <Text4
            key={`lifeHead-${index}`}>
            {head}
          </Text4>)}

        {risk === "Жизнь" &&
        <>
          {(borrowers || []).map((borrower, index) => (
            <React.Fragment key={`borrowerRisk-${index}`}>
              {getRiskLifeRows(borrower).map((row, index) => (
                <React.Fragment key={`lifeRow-${index}`}>
                  {row}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </>
        }
        {risk !== "Жизнь" && getRowsForRisk(tariff || 0, premium || 0, objectTypeName || "").map((row, index) => (
          <React.Fragment key={`propertyRow-${index}`}>
            <Text4 className="precalculationRow">{row}</Text4>
          </React.Fragment>
        ))}
      </StyledBankTableColumns>
    </StyledBankTable>
  )
}

export interface IPrecalculationItem {
  isn: string
  bankIsn: string
  bankName: string
  sumInsured: number
  premium: number
  riskProperty?: number
  tariffProperty?: number
  riskTitle?: number
  tariffTitle?: number
  borrowers: any
}

export const PrecalculationResult = ({
                                       loading,
                                       precalculationItems
                                     }: { loading: boolean, precalculationItems: IPrecalculationItem[] }) => {
  const {setNotification} = useCallNotification();
  // const [stateSendResultModal, setSendResultModal] = React.useState<boolean>(false);
  const [{
    agreementStep: {
      agreementStepState: {
        step,
        stepsCount
      }
    }
  }, {agreementStep: actionsAgreementStep}]: any = StoreAgreementStep();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const {objectType} = agreementFormState;
  const [downloadPreCalc, {
    data: dataDownloadPreCalc,
    loading: loadingDownloadPreCalc,
    error: errorDownloadPreCalc
  }] = useLazyQuery(DOWNLOAD_PRE_CALC, {fetchPolicy: "cache-and-network"});
  const {url} = (dataDownloadPreCalc || {}).preCalcForm || {};
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const objectTypeName: string = String(getSelectValue(objectType, true));
  const {isAdmin, type, role} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
//!rolesRightHandler("precalculationPage", functions, type, isAdmin)

  React.useEffect(() => {
    fileDownloader(url)
    // eslint-disable-next-line
  }, [url])

  const changeStep = (step: number) => {
    if (step > stepsCount || step < 0) return;
    actionsAgreementStep.setState({
      agreementStepState: {
        step,
        stepsCount
      }
    });
  }

  const downloadResultHandler = (type: "all" | "selected") => {
    if (type === "all") {
      downloadPreCalc({
        variables: {
          calcs: precalculationItems.map(({isn}) => isn)
        }
      })
    } else {
      downloadPreCalc({
        variables: {
          calcs: precalculationItems.filter(({bankIsn}) => stateCheckedBank.includes(bankIsn)).map(({isn}) => isn)
        }
      })
    }
  }

  const [stateCheckedBank, setCheckedBank] = React.useState<string[]>([])

  const selectBankHandler = (id: string, value: boolean) => {
    if (!value && stateCheckedBank.includes(id)) return setCheckedBank(stateCheckedBank.filter(stateId => stateId !== id));
    if (value && !stateCheckedBank.includes(id)) return setCheckedBank([...stateCheckedBank, id])
  };

  const chooseBankHandler = (bank: IPrecalculationItem) => {
    const {bankIsn, bankName, tariffTitle} = bank;
    updateAgreementForm({
      ...agreementFormState,
      riskTitle: !!tariffTitle,
      creditOrganizationName: {label: bankName, value: bankIsn},
      templateVariables: getVariablesForTemplate({
        ...agreementFormState,
        riskTitle: !!tariffTitle
      }),
    })
    changeStep(step + 1)
  }

  if (errorDownloadPreCalc) setNotification({type: "error", text: errorDownloadPreCalc})
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (loading) return <Loader/>
  if (precalculationItems.length === 0) return null;
  return (
    <StyledPrecalculationFormCard>
      <Heading2>Результаты расчета</Heading2>
      <StyledResultButtonsWrapper>
        <Button appearance="text" onClick={() => downloadResultHandler("all")} isLoading={loadingDownloadPreCalc}
                iconBefore={<DownloadIcon/>}>Выгрузить все</Button>
        {!!stateCheckedBank.length &&
        <Button appearance="text" onClick={() => downloadResultHandler("selected")} isLoading={loadingDownloadPreCalc}
                iconBefore={<DownloadIcon/>}>
            Выгрузить выбранные ({stateCheckedBank.length})
        </Button>}
        {/* кнопки что бы отправить на эмейл клиенту результаты предрасчета */}
        {/*<Button*/}
        {/*  onClick={() => setSendResultModal(true)}*/}
        {/*  appearance="text"*/}
        {/*  iconBefore={<SendIcon/>}>*/}
        {/*  Отправить все*/}
        {/*</Button>*/}
        {/*{!!stateCheckedBank.length &&*/}
        {/*  <Button*/}
        {/*    onClick={() => setSendResultModal(true)}*/}
        {/*    appearance="text"*/}
        {/*    iconBefore={<SendIcon/>}>*/}
        {/*  Отправить выбранные ({stateCheckedBank.length})*/}
        {/*  </Button>}*/}
        {/* кнопки что бы отправить на эмейл клиенту результаты предрасчета */}
      </StyledResultButtonsWrapper>

      <StyledBankCardsWrapper>
        {precalculationItems.map(bank => {
          const {
            bankIsn,
            bankName,
            sumInsured,
            riskTitle,
            riskProperty,
            tariffProperty,
            tariffTitle,
            premium,
            borrowers
          } = bank;
          return (
            <StyledBankCard key={`card-${bankIsn}`}>
              <StyledBankCardHeader>
                <Checkbox onChange={(value) => selectBankHandler(bankIsn, value)} label={bankName}/>
                <StyledBankLogo>
                  <img alt={bankName} src={`/banksLogo/${bankIsn}.png`}/>
                </StyledBankLogo>
              </StyledBankCardHeader>

              <StyledBankPremium>
                <Text4>Страховая премия, итог</Text4>
                <Heading3>{money(premium)} RUB</Heading3>
                <Text4>Страховая сумма</Text4>
                <Heading4>{money(sumInsured)} RUB</Heading4>
              </StyledBankPremium>

              {borrowers && borrowers.length && <RiskTable risk="Жизнь" borrowers={borrowers}/>}
              {typeof riskProperty === "number" && riskProperty !== 0 && typeof tariffProperty === "number" && tariffProperty !== 0 &&
              <RiskTable risk="Имущество" tariff={tariffProperty} premium={riskProperty}
                         objectTypeName={objectTypeName}/>}
              {typeof riskTitle === "number" && riskTitle !== 0 && typeof tariffTitle === "number" && tariffTitle !== 0 &&
              <RiskTable risk="Титул" tariff={tariffTitle} premium={riskTitle} objectTypeName={objectTypeName}/>}
              <Button
                isDisabled={!rolesRightHandler("createApplicationPage", functions, type, isAdmin)}
                onClick={() => chooseBankHandler(bank)}
                width="100%"
                appearance="transparent">
                Выбрать
              </Button>
            </StyledBankCard>
          )
        })
        }

      </StyledBankCardsWrapper>
      {/* модалка что бы отправить на эмейл клиенту результаты предрасчета */}
      {/*<SendResultModal header="Отправить результаты расчета" onClose={() => setSendResultModal(false)} isOpen={stateSendResultModal}/>*/}
      {/* модалка что бы отправить на эмейл клиенту результаты предрасчета */}
    </StyledPrecalculationFormCard>
  )
};

export default PrecalculationResult;