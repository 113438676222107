import styled from "styled-components";
import {grey} from "../../../../globalStyledComponents";

export const StyledToggleAreaWrapper = styled.div`
  border-top: 1px solid ${grey};
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
export const StyledToggleAreaLeft = styled.div`
  border-right: 1px solid ${grey};
`;

export const StyledRiskInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  border-top: 1px solid ${grey};
  border-bottom: 1px solid ${grey};
  margin: 2rem 0 3rem 0;
  padding: 2rem 0;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledCalculationDataWrapper = styled.div`
  padding: 1.5rem 0 4rem 0;
`;
export const StyledRiskFormWrapper = styled.div`
  h4, h5 {
    margin-bottom: 1rem;
  }
`;

export const StyledRemark = styled.div`
  display: flex;
  align-items: flex-start;
  opacity: 0.5;
  > p {
    margin-left: 1rem;
  }
  > svg {
    max-width: 1.5rem;
    max-height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
  margin-bottom: 2rem;
`;