import styled                   from "styled-components";
import {blue, lightBlue, white} from "../../globalStyledComponents";

export const StyledWorkPage = styled.div`
  background: ${lightBlue};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
      display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  z-index: 50;
`;
export const StyledWork = styled.div`
  display: flex;
  align-items: center;
  height: 25rem;
  > h1 {
    font-size: 25rem;
  }
  padding: 3rem 0;
  > h1:first-child, h1:last-child {
    color: ${blue};
  }
  color: ${white};
  
  @media screen and (max-width: 767px) {
   > h1 {
    font-size: 20rem;
    }
  }
`;

export const StyledWorkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 70%;
`;

export const StyledWorkcontent = styled.div`
  > h2 {
    text-transform: uppercase;
  }
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 4rem;
`;