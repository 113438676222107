import React                   from "react";
import FieldsBuilder           from "../../../../../../uiKit/fieldsBuilder";
import {IRadioItem}            from "../../../../../../uiKit/radio";
import {radioBoolean}          from "../../../../staticData";
import {IFormField}            from "../../../../../../uiKit/fieldsBuilder/interfaces";
import Button                  from "../../../../../../uiKit/button/button";
import {StyledAutoFillButtons} from "./styles";
import {TextButton}            from "../../../../../../globalStyledComponents";

export const PropertyInsuranceRisks = ({number}:{number: number}) => {
  const [stateSignaling, setSignaling] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateSignalingInput, setSignalingInput] = React.useState<string>("");
  const [stateOpenFire, setOpenFire] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateOpenFireInput, setOpenFireInput] = React.useState<string>("");
  // const [stateFlammable, setFlammable] = React.useState<IRadioItem>(radioBoolean[1]);
  // const [stateFlammableInput, setFlammableInput] = React.useState<string>("");
  const [stateAdditionalRisks, setAdditionalRisks] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateAdditionalRisksInput, setAdditionalRisksInput] = React.useState<string>("");

  const formList = [
    {
      label: "Сигнализация",
      radioName: `signaling-${number}`,
      isHidden: stateSignaling.value === false,
      autoFillItems: ["Охранная", "Пожарная", "Другая"],
      inputDefault: stateSignalingInput,
      radioDefault: stateSignaling,
      inputName: `signalingInput-${number}`,
      onRadioChange: (value) => setSignaling(value),
      onInputChange: (value) => setSignalingInput(value),
      onAutoFill: (text) => setSignalingInput(`${stateSignalingInput}${text}`)
    },
    {
      label: "Наличие источников открытого огня (газовая плита / колонка /другое)",
      radioName: `openFire-${number}`,
      isHidden: stateOpenFire.value === false,
      autoFillItems: ["Газовая плита / колонка", "Камин", "Другое"],
      inputDefault: stateOpenFireInput,
      radioDefault: stateOpenFire,
      inputName: `openFireInput-${number}`,
      onRadioChange: (value) => setOpenFire(value),
      onInputChange: (value) => setOpenFireInput(value),
      onAutoFill: (text) => setOpenFireInput(`${stateOpenFireInput}${text}`)
    },
    // {
    //   label: "Хранение горючих, легковоспламеняющихся материалов",
    //   radioName: `flammable-${number}`,
    //   isHidden: stateFlammable.value === false,
    //   autoFillItems: ["Бензин", "Спирт", "Другое"],
    //   inputDefault: stateFlammableInput,
    //   radioDefault: stateFlammable,
    //   inputName: `flammableInput-${number}`,
    //   onRadioChange: (value) => setFlammable(value),
    //   onInputChange: (value) => setFlammableInput(value),
    //   onAutoFill: (text) => setFlammableInput(`${stateFlammableInput}${text}`)
    // },
    {
      label: "Наличие дополнительных факторов риска (бассейн, сауна, другое)",
      radioName: `additionalRisks-${number}`,
      isHidden: stateAdditionalRisks.value === false,
      autoFillItems: ["Бассейн", "Сауна", "Другое"],
      inputDefault: stateAdditionalRisksInput,
      radioDefault: stateAdditionalRisks,
      inputName: `additionalRisksInput-${number}`,
      onRadioChange: (value) => setAdditionalRisks(value),
      onInputChange: (value) => setAdditionalRisksInput(value),
      onAutoFill: (text) => setAdditionalRisksInput(`${stateAdditionalRisksInput}${text}`)
    },
  ]

  return (
    <>
      {formList.map(({label, radioName, onAutoFill, autoFillItems, isHidden, inputDefault, inputName, radioDefault, onRadioChange, onInputChange}) => {
        const form: IFormField[] = [
          {
            label,
            fieldItems: {
              type: "radio",
              name: radioName,
              items: radioBoolean,
              defaultValue: radioDefault,
              onChange: onRadioChange
            }
          },
          {
            isHidden,
            fieldMargin: 0,
            fieldItems: {
              type: "input",
              name: inputName,
              placeholder: "Подробнее",
              isRequired: true,
              errorMessage: "Введите подробнее",
              isControlled: true,
              defaultValue: inputDefault,
              onChange: onInputChange,
            }
          }
        ]
        return (
          <React.Fragment key={label}>
            <FieldsBuilder formFields={form}/>
            {!isHidden && <StyledAutoFillButtons>
              {autoFillItems.length !== 0 && autoFillItems.map((text, index) => (
                <React.Fragment key={`autoFill-${index}`}>
                  <Button  onClick={(ev) => {
                    ev.preventDefault();
                    onAutoFill(` ${text}`)
                  }}
                          appearance="link">{text}</Button>
                  {index + 1 !== autoFillItems.length && <TextButton>&nbsp;,&nbsp;</TextButton>}
                </React.Fragment>
              ))}
            </StyledAutoFillButtons>}
          </React.Fragment>
        )
      })}
    </>
  )
};

export default PropertyInsuranceRisks;