import React                             from 'react';
import ReactDOM                          from 'react-dom';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import App                               from './App';
import {ApolloProvider}                  from '@apollo/react-hooks';
import {client}                          from "./Apollo";

const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

const WrappedApp = () => (
  <ApolloProvider client={client}>
    <Router basename={BASE_URL}>
      <Switch>
        <App/>
      </Switch>
    </Router>
  </ApolloProvider>
);
ReactDOM.render(<WrappedApp/>, document.getElementById('root') as HTMLElement);
