import styled         from "styled-components";
import {blue7, white} from "../../../../globalStyledComponents";

export const StyledRoleFunctions = styled.div`
  h3 {
    margin-bottom: 3rem;
  }
`;

export const StyledSelectedFunctionsArea = styled.div`
box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
background: ${white};
padding: 2rem;

.linkButton {
  margin-top: 1rem;
}
`;

export const StyledSelectedFunctionItem = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  
  .iconButton {
    margin-left: 0.5rem; 
  }
  padding: 0.5rem 0.8rem;
  margin: 0 1rem 1rem 0;
  background: ${blue7};
`;