import React                                 from "react";
import Modal, {ModalProps}                   from "../../../../uiKit/modal";
import Form                                  from '@atlaskit/form';
import Button                                from "../../../../uiKit/button/button";
import {ISelectItem}                         from "../../../../uiKit/select";
import {StyledButtonsGroup}                  from "./styles";
import {IFormField}                          from "../../../../uiKit/fieldsBuilder/interfaces";
import {getFormError}                        from "../../../../formUtils/getFormError";
import {getFormValue, getSelectDefaultValue} from "../../../../formUtils/getFormValue";
import FieldsBuilder                         from "../../../../uiKit/fieldsBuilder";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {CREATE_UPDATE_USER}                  from "../../../../utils/mutations";
import {GROUPS, ME, ROLES}                   from "../../../../utils/queries";
import {ErrorMessage}                        from "../../../../components/errorMessage";
import {useCallNotification}                 from "../../../../hooks/useCallNotification";
import {rolesRightHandler}                   from "../../../../helpers/rolesRightHandler";
import ChangeLogModal                        from "./сhangeLogModal";
import {TimeReloadIcon}                      from "../../../../icons/ui/TimeReload";

export const CreateUserModal = ({header, isOpen, onClose, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const [stateIsOpenHistory, setIsOpenHistory] = React.useState<boolean>(false);
  const [createUser, {loading: loadingCreateUser}] = useMutation(CREATE_UPDATE_USER, {refetchQueries: ["users", "groups", "changeLog"], awaitRefetchQueries: true });
  const {data: dataRoles, loading: loadingRoles, error: errorRoles} = useQuery(ROLES);
  const roles: ISelectItem[] = ((dataRoles || {}).roles || []).map(({name, id}) => ({label: name, value: id}));
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {type, isAdmin, role: userRoles} = (dataUser || {}).me || {};
  const {data: dataGroups, loading: loadingGroups, error: errorGroups} = useQuery(GROUPS);
  const functions: string[] = ((userRoles || {}).functions || []).map(({tag}) => tag);
  const groups: ISelectItem[] = ((dataGroups || {}).groups || []).map(({name, id, leader}) => ({
    label: `${name} ${(leader || {}).name ? `(Лидер: ${(leader || {}).name})` : ""}`,
    value: id
  }));

  const {login, email, phone, name, group: groupId, showKv, type: userType, role, id, isLeader, isEnabled, isEdit, isDisabledEdit} = data || {};

  const isCanEdit: boolean = !id ? true : id !== dataUser?.me?.id || dataUser?.me?.type === "admin"

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {group, role} = getFormValue(data);
    createUser({
      variables: {
        ...getFormValue(data),
        id: id ? id : null,
        group: group ? group : null,
        role: role ? role : null,
        password: "password123"
      }
    })
      .then(() => {
        setNotification({
          type: "success",
          text: isEdit ? "Изменения сохранены" : "Пользователь создан, логин /пароль направлены на почту"
        })
        onClose()
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const form: IFormField[] = [
    {
      columns: 2,
      columnGap: 1,
      fieldItems: [
        {
          type: "fio",
          name: "name",
          isRequired: true,
          isDisabled: isDisabledEdit,
          defaultValue: {value: name || ""},
          placeholder: "ФИО сотрудника",
          validationType: "fullName",
          errorMessage: "Введите ФИО"
        },
        {
          type: "input",
          name: "login",
          isRequired: true,
          isDisabled: isDisabledEdit,
          defaultValue: login || "",
          placeholder: "Логин сотрудника",
          errorMessage: "Введите логин"
        }
      ]
    },
    {
      columns: 2,
      columnGap: 1,
      fieldItems: [
        {
          type: "input",
          name: "email",
          isRequired: true,
          isDisabled: isDisabledEdit,
          defaultValue: email || "",
          validationType: "email",
          placeholder: "E-mail сотрудника",
          errorMessage: "Введите E-mail"
        },
        {
          type: "input",
          name: "phone",
          // isRequired: true,
          isDisabled: isDisabledEdit,
          defaultValue: phone || "",
          mask: "+7 (999) 999-99-99",
          validationType: "phone",
          placeholder: "Телефон сотрудника",
          errorMessage: "Введите телефон"
        }
      ]
    },
    {
      fieldItems: {
        type: "select",
        name: "group",
        isLoading: loadingGroups || loadingUser,
        isDisabled: isDisabledEdit || !isCanEdit,
        placeholder: "Группа для учетной записи",
        defaultValue: getSelectDefaultValue(groupId, groups),
        isSearchAvailable: true,
        isClearable: true,
        items: groups,
        height: 20
      }
    },
    {
      fieldItems: {
        type: "checkbox",
        name: "lead",
        isDisabled: isDisabledEdit || !isCanEdit,
        label: "Сделать лидером в этой группе",
        defaultValue: !!isLeader
      }
    },
    {
      isHidden: type !== "admin",
      fieldItems: {
        type: "select",
        name: "role",
        isLoading: loadingRoles,
        isDisabled: isDisabledEdit || !isCanEdit,
        placeholder: "Роль для учетной записи",
        isSearchAvailable: true,
        isClearable: true,
        defaultValue: getSelectDefaultValue(role, roles),
        items: roles,
        height: 20
      }
    },
    {
      fieldItems: {
        type: "checkbox",
        name: "showKv",
        isDisabled: isDisabledEdit || !isCanEdit,
        label: "Отображение суммы КВ агента",
        defaultValue: !!showKv
      }
    },
    {
      isHidden: type !== "admin" || !isCanEdit,
      fieldItems: {
        type: "checkbox",
        name: "isSuper",
        isDisabled: isDisabledEdit,
        label: "Сделать супер пользователем",
        defaultValue: userType === "admin"
      }
    },
    {
      isHidden: !rolesRightHandler("disableUsers", functions, type, isAdmin) || !isCanEdit,
      fieldItems: {
        type: "checkbox",
        name: "isEnabled",
        isDisabled: isDisabledEdit,
        label: "Активен",
        defaultValue: isEnabled
      }
    }
  ]

  if (errorGroups) setNotification({type: "error", text: errorGroups})
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorRoles) setNotification({type: "error", text: errorRoles})
  return (
    <Modal
      width={60}
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}
      headerButton={isEdit ? <Button tooltip="История изменений" onClick={() => setIsOpenHistory(true)} appearance="icon"
                                     icon={<TimeReloadIcon/>}/> : null}
    >
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                isDisabled={isDisabledEdit}
                type="submit"
                width="100%"
                isLoading={loadingCreateUser}
                appearance="filled">
                {isEdit ? "Сохранить" : "Создать"}
              </Button>
              <Button onClick={() => onClose()} width="100%" isLoading={loadingCreateUser} appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
      <ChangeLogModal
        data={{id, model: 'USER'}}
        onClose={() => setIsOpenHistory(false)}
        isOpen={stateIsOpenHistory}
        header={`Изменения пользователя: ${name}`}/>
    </Modal>
  )
};

export default CreateUserModal;