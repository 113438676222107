import React                                      from "react";
import {getFormError}                             from "../../../../formUtils/getFormError";
import Form                                       from '@atlaskit/form';
import InsuranceSubjects, {insuranceSubjectNames} from "./components/insuranceSubject";
import StoreAgreementForm                         from "../../store";
import {IStepsProviderProps}                      from "../stepsProvider";
import StepsLayout                                from "../stepsLayout";
import {getFormValueWithArray}                    from "../../../../formUtils/getFormValueWithArray";
import StepsPaginationButton                      from "../stepsPaginationButton";
import {getFormValue}                             from "../../../../formUtils/getFormValue";
import {useCallNotification}                      from "../../../../hooks/useCallNotification";
import moment from "moment/moment";
import {formatDateToUsa} from "../../../../formUtils/fieldsValidation";
import {isNewRegion} from "../../../../utils/address";

export const PropertyInsuranceStep = ({title, showSaveModal, id, isAgreementEdit, step, changeStep, addRisksSteps, editType}: IStepsProviderProps) => {
  const {setNotification} = useCallNotification();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const isSaveImmediately: boolean = isAgreementEdit && editType === "addRisks" && !addRisksSteps.includes("riskTitle") && !addRisksSteps.includes("riskLife");

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValueWithArray(getFormValue(data), insuranceSubjectNames, "propertyInsuranceSubjects");
    const {propertyInsuranceSubjects} = formData;
    const floorError = propertyInsuranceSubjects.reduce((prev, next, index) => {
      const {floor, floorCount} = next;
      if (!floorCount || !floor) return prev;
      if (Number(floor) <= Number(floorCount)) return prev;
      return {[`floor-${index}`]: "Этаж не может быть больше этажности"}
    }, undefined)
    if (floorError) return floorError;
    let regionError: boolean = false;
    propertyInsuranceSubjects.forEach(({pledgeAddress}) => {
      if(isNewRegion(pledgeAddress)) regionError = true;
    });
    if (regionError) return setNotification({type: "error", text: "Расчет по выбранным параметрам невозможен. Обратитесь к куратору!"});

    const totalShare: number = propertyInsuranceSubjects.reduce((prev, {pledgeShare}) => {
      return prev + Number(pledgeShare);
    }, 0)
    if (totalShare === 0) return setNotification({type: "error", text: "Выберите доли"})
    if (totalShare > 100) return setNotification({type: "error", text: "Сумма долей превышает 100"})

    updateAgreementForm({
      propertyInsuranceSubjects
    })
    // if (!isAgreementEdit && step === stepCount - 1) return showSaveModal(true);
    if (isSaveImmediately) return showSaveModal();
    // if (step === stepCount - 1) return showSaveModal();
    changeStep(step + 1)
  }

  return (
    <Form onSubmit={data => onFormSubmit(data)}>
      {({formProps}) => (
        <form {...formProps} id={id}>
          <StepsLayout title={title}>
            <InsuranceSubjects/>
          </StepsLayout>
          <StepsPaginationButton disableBackButton={editType === "addRisks"} saveImmediately={isSaveImmediately} formId={id}/>
        </form>
      )}
    </Form>


  )
}

export default PropertyInsuranceStep;