import React                                                        from "react";
import {StyledTextArea, StyledTextAreaLabel, StyledTextAreaWrapper} from "./styles";
import FormMessage                                                  from "../formMessage";

interface ITextAreaProps {
  value?: string
  placeholder?: string
  onChange?: (value: string) => void
  isInvalid?: boolean
  errorMessage?: string
  isRequired?: boolean
}

export const TextArea = ({value, placeholder, isInvalid, isRequired, errorMessage, onChange}: ITextAreaProps) => {
  return (
    <>
      <StyledTextAreaWrapper>
        <StyledTextArea
          onChange={onChange ? ({target: {value}}) => onChange(value) : undefined}
          isInvalid={!!isInvalid}
          placeholder={" "}
          defaultValue={value || ""}
        />
        {placeholder && <StyledTextAreaLabel isRequired={!!isRequired}>{placeholder}</StyledTextAreaLabel>}
      </StyledTextAreaWrapper>
      {errorMessage && <FormMessage message={errorMessage} type="error"/>}
    </>
  )
}

export default TextArea;