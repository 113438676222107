import {getSelectValue, stringToNumber} from "../formUtils/getFormValue";
import moment from "moment";
import {IGNORED_STEPS_NAME} from "./templateToFormRebuild";
import {getVariablesForTemplate} from "./getVariablesForTemplate";
import {formatDateToUsa} from "../formUtils/fieldsValidation";

const BORROWERS: { isn: string, fieldName: string, index: number }[] = [
    {isn: "834141", fieldName: "fullName", index: 0},// "Созаемщик1 (ФИО)", …}
    {isn: "854601", fieldName: "share", index: 0},// "Созаемщик1 (доля в общем доходе, %)", …}
    {isn: "854611", fieldName: "fullName", index: 1},// "Созаемщик2 (ФИО)", …}
    {isn: "854621", fieldName: "share", index: 1},// "Созаемщик2 (доля в общем доходе, %)", …}
    {isn: "854631", fieldName: "fullName", index: 2},// "Созаемщик3 (ФИО)", …}
    {isn: "854641", fieldName: "share", index: 2},// "Созаемщик3 (доля в общем доходе, %)", …}
    {isn: "854651", fieldName: "fullName", index: 3},// "Созаемщик4 (ФИО)", …}
    {isn: "854661", fieldName: "share", index: 3},// "Созаемщик4 (доля в общем доходе, %)", …}
    {isn: "854671", fieldName: "fullName", index: 4},// "Созаемщик5 (ФИО)", …}
    {isn: "854681", fieldName: "share", index: 4},// "Созаемщик5 (доля в общем доходе, %)", …}
]

interface StepItem {
    isn: string,
    name: string,
    isSelect?: boolean
    isAddress?: boolean
    remarkName?: string
    protectedField?: string
}

export const ANKETA_ATTRIBUTES: { [key: string]: StepItem[] } = {
    // step 1
    step1: [
        {isn: "833991", name: "fullName"},// "Фамилия, Имя,  Отчество", …}
        {isn: "834001", name: "gender"},// "Ваш пол", …}
        {isn: "834011", name: "addr1", isAddress: true},// "Адрес регистрации", …}
        {isn: "860831", name: "addressRadio"},// "Адрес регистрации совпадает с фактическим", …}
        {isn: "834021", name: "addr2", isAddress: true},// "Адрес фактического проживания (если отличается от адреса регистрации)", …}
        {isn: "834051", name: "birthDate"},// "Дата рождения", …}
        // {isn: "834071", name: "familyStatus", isSelect: true},// "Семейное положение", …}
        {isn: "834081", name: "phoneMobile"},// "Контактные телефоны", …}
        {isn: "854581", name: "email"},// "e-mail", …}
        {isn: "834101", name: "countryIsn", isSelect: true},// "Гражданство", …}
        {isn: "834091", name: "authDocSer"},// "Серия паспорта", …}
        {isn: "860861", name: "authDocNumber"},// "Номер паспорта", …}
        {isn: "860871", name: "authDocDate"},// "Дата выдачи паспорта", …}
        {isn: "950311", name: "authDocIssuerCode"},// "Код подразделения", …}
        {isn: "860881", name: "authDocIssuer"},// "Кем выдан"
    ],
    // step 2
    step2: [
        {isn: "833921", name: "jobPlace", isSelect: true},// "Настоящее место работы", …}
        {isn: "833931", name: "organizationName"},// "Наименование организации", …}
        {isn: "833941", name: "address", isAddress: true},// "Юридический адрес", …}
        {isn: "833961", name: "isOfficeJob"},// "Связана ли Ваша трудовая деятельность с работой в офисе (офисный сотрудник)", …}
        {isn: "833971", name: "position"},// "Должность", …}
        {isn: "833981", name: "field", isSelect: true},//"Укажите сферу деятельности Вашей
        {isn: "1941371", name: "isWarrior"},//Является военнослужащим(ей)
    ],
    // step 3
    step3: [
        {isn: "833851", name: "creditOrganizationName", isSelect: true},// "Наименование кредитной организации, с которой заключен кредитный договор/договор залога", …}
        {isn: "833861", name: "summ"},// "Сумма кредита/Остаток ссудной задолженности по кредитному договору", …}
        {isn: "833871", name: "creditTerms"},// "Срок кредитования(месяцы)/оставшийся срок кредитования", …}
        {isn: "833881", name: "creditRate"},// "Годовая процентная ставка по кредиту(%)", …}
        {isn: "833891", name: "haveCoBorrower"},// "Есть ли Созаемщики по кредиту", …}
        {isn: "833901", name: ""},//"Ваш консультант в банке, те
    ],


    // step 5
    step5: [
        {isn: "834151", name: "pledge", isSelect: true},// "Предмет залога", …}
        {isn: "834161", name: "pledgeAddress", isAddress: true, protectedField: "pledgeAddressEgrn"},// "Адрес приобретаемого / закладываемого объекта залога", …}
        {isn: "834171", name: "agency"},// "Агентство недвижимости, которое готовит сделку купли-продажи, Ф.И.О. и телефон агента", …}
        {isn: "834181", name: "subjectCost"},// "Рыночная стоимость объекта залога (согласно отчету об оценке)", …}
        {isn: "834191", name: "nameOfAppraiser"},// "Наименование независимого оценщика", …}
        {isn: "834201", name: "subjectCostByContract"},// "Стоимость объекта залога по договору купли-продажи", …}
        {isn: "834211", name: "area"},// "Общая площадь(кв. м)", …}
        {isn: "834231", name: "roomsCount"},// "Количество комнат", …}
        {isn: "834241", name: "signaling", remarkName: "signalingInput"},// "Сигнализация", …}
        {isn: "834301", name: "openFire", remarkName: "openFireInput"},// "Наличие источников открытого огня (газовая плита/колонка, камин, другое)", …}
        {isn: "834311", name: "additionalRisks", remarkName: "additionalRisksInput"},// "Наличие дополнительных факторов, влияющих на степе…ссейн, сауна, хлебопекарное производство, другое)", …}
        {isn: "834321", name: "floor"},// "Этаж", …}
        {isn: "834331", name: "floorCount"},// "Этажность дома", …}
        {isn: "834341", name: "yearOfBuild"},// "Год постройки", …}
        {isn: "834351", name: "yearOfCapitalRepair"},// "Год последней реконструкции (капитального ремонта)", …}
        {isn: "834361", name: "wallMaterial", isSelect: true},// "Материал стен", …}
        {isn: "834371", name: "floorMaterial", isSelect: true},// "Материал межэтажных перекрытий", …}
        {isn: "834611", name: "repairPlanning"},// "Планируете ли Вы проведение ремонтных работ, связа…мов в стенах, замена труб)? Укажите сроки ремонта", …}
        {isn: "834621", name: "rent"},// "Будет ли недвижимое имущество сдаваться в аренду третьим лицам", …}
        {isn: "834631", name: "damage"},//"Известны ли Вам значительные физические повреждени…а, сумма ущерба которых превышала 20
    ],
// step 6
    step6: [
        {isn: "833791", name: "subjectEncumbrances"},// "Наличие действующих обременений объекта залога (рента, арест, залог, доверительное управление)", …}
        {isn: "833801", name: "minors"},// "Наличие несовершеннолетних членов семьи, являющихся собственниками недвижимого имущества", …}
        {isn: "833811", name: "unregisterPersons"},// "Наличие граждан, незарегистрированных в объекте за…еста лишения свободы, призванных в армию и т. д.)", …}
        {isn: "833821", name: "litigation"},//"Являлся ли предмет залога объектом судебных разбирательств в прошлом или в на
    ],
// step 7
    step7: [
        {isn: "833751", name: "foreigner"},// "Являетесь ли Вы иностранным публичным должностным …те ли вы от имени и(или) в интересах такого лица?", …}
        {isn: "833761", name: "faceOfInternational"},// "Являетесь ли Вы должностным лицом публичной междун…те ли Вы от имени и(или) в интересах такого лица?", …}
        {isn: "833771", name: "civilServants"},// "Являетесь ли вы госслужащим / или родственником та…е ли вы от имени и (или) в интересах такого лица?", …}
        {isn: "833781", name: "additionalDetails"},// "Если на один из трех предыдущих вопросов Вы ответи…в страхователя - ФИО, степень родства и должность", …}
    ],
    settings: [
        {isn: "871481", name: "dealCountry"}
    ]
}

const ANKETA_SETTINGS: StepItem[] = [
    {isn: "871481", name: "dealCountry"}
]

export type AttributesFormName =
    "creditOrganizationName"
    | "summ"
    | "creditTerms"
    | "creditRate"
    | "creditIssueDate"
    | "firstPayDate"
    | "salesChannel"
    | "isnAnketa"
    | "insureType"
    | "agreementDate"
    | "prevInsuranceCompany"
    | "contractNumber"
    | "annuityPay"
    | "isDeclaration"
    | "isCustomFileUpload"
    | "promocode"
    | "promocodeBank"
    | "promocodePhone"

export const AGREEMENT_ATTRIBUTES: { isn: string, name: AttributesFormName, isSelect?: boolean, isCanBeZero?: boolean }[] = [
    {isn: "831791", name: "creditOrganizationName", isSelect: true}, // банк кредитор
    {isn: "831731", name: "summ"}, // сумма кредита
    {isn: "831761", name: "creditTerms"}, // срок кредита
    {isn: "831771", name: "creditRate", isCanBeZero: true}, // процентная ставка
    {isn: "831741", name: "creditIssueDate"}, // дата выдачи кредита
    {isn: "831751", name: "firstPayDate"}, // дата первого платежа
    {isn: "450341", name: "salesChannel", isSelect: true}, // канал продаж
    {isn: "877111", name: "isnAnketa"}, // анкета
    {isn: "1925031", name: "isDeclaration"}, // является декларацией
    {isn: "1856521", name: "isCustomFileUpload"}, // Ручное заполнение заявления-анкеты
    {isn: "950711", name: "insureType", isSelect: true}, // тип договора
    {isn: "748741", name: "agreementDate"}, // дата кредитного договора
    {isn: "858471", name: "prevInsuranceCompany", isSelect: true}, // предидущая СК
    {isn: "748731", name: "contractNumber"}, // номер кредитного договора
    {isn: "861721", name: "annuityPay"}, // номер кредитного договора
]

const valueFormatter = (type: string, isSelect: boolean, isAddress: boolean, value, oldValue, oldValueDesc, remark?) => {
    switch (type) {
        case "CHECKBOX":
            return typeof value === "boolean" ? {value: value ? "1" : "0", value_desc: value ? "1" : "0", remark} : {
                value: oldValue === "1" ? "1" : "0",
                value_desc: oldValueDesc === "1" ? "1" : "0"
            }
        case "INTEGER":
            return value ? {value: Number(Math.floor(value)), value_desc: Number(Math.floor(value))} : {
                value: oldValue,
                value_desc: oldValueDesc
            }
        case "MONEY":
            return value ? {value, value_desc: value} : {value: oldValue, value_desc: oldValueDesc}
        case "FLOAT":
            return value ? {value, value_desc: value} : {value: oldValue, value_desc: oldValueDesc}
        case "SQL":
            return value ? {value: getSelectValue(value), value_desc: getSelectValue(value, true)} : {
                value: oldValue,
                value_desc: oldValueDesc
            }
        default:
            if (isSelect) return value ? {
                value: getSelectValue(value, true),
                value_desc: getSelectValue(value, true)
            } : {
                value: oldValue,
                value_desc: oldValueDesc
            };
            if (isAddress) return value ? {
                value: value.unrestricted_value,
                value_desc: value.unrestricted_value
            } : {value: oldValue, value_desc: oldValueDesc};
            return value ? {value, value_desc: value} : {value: oldValue, value_desc: oldValueDesc};
    }
}

const buildStep = (data: any, part: any[], namesById: StepItem[]) => {
    return part.map((question) => {
        const {isn, type, value: oldValue, value_desc: oldValueDesc} = question;
        const {
            name,
            isSelect,
            isAddress,
            remarkName,
            protectedField
        } = namesById.find(({isn: fieldIsn}) => fieldIsn === isn) || {};
        const formValue = data[name || "unknown"];
        // only for pledge address
        const protectedValue = data[protectedField || "unknown"]
        const resultValue = !!protectedValue ? {unrestricted_value: protectedValue} : formValue;
        const remark = remarkName ? data[remarkName || "unknown"] : undefined;
        return ({...question, ...valueFormatter(type, !!isSelect, !!isAddress, resultValue, oldValue, oldValueDesc, remark)})
    })
}

const buildStep3 = (formData: any, part: any, namesById: StepItem[]) => {
    const borrowers: any[] = getPerson(formData, "coBorrower")
    const borrowersIsn = BORROWERS.map(({isn}) => isn);
    return part.map((question) => {
        const {isn, type, value: oldValue, value_desc: oldValueDesc} = question;
        const {fieldName, index} = BORROWERS.find(({isn: borrowerIsn}) => borrowerIsn === isn) || {};
        if (borrowersIsn.includes(isn)) return ({
            ...question,
            value: (borrowers[typeof index === "number" ? index : 6] || {})[fieldName || ""] || oldValue
        })
        const {name, isSelect, isAddress} = namesById.find(({isn: fieldIsn}) => fieldIsn === isn) || {};
        const formValue = formData[name || "unknown"];
        return ({...question, ...valueFormatter(type, !!isSelect, !!isAddress, formValue, oldValue, oldValueDesc)})
    })
};

const buildStep4 = (formData: any, part: any) => {
    return part.map((question) => {
        const {isn, type, value: oldValue, value_desc: oldValueDesc} = question;
        const formValue = formData[`nivanavi-${isn}`];
        const formRemark = formData[`nivanavi-${isn}-remark`];
        return ({...question, ...valueFormatter(type, false, false, formValue, oldValue, oldValueDesc, formRemark)})
    })
};

export const anketaPartsBuilder = (formData: any, parts: any[], onlyLife?: boolean) => {
    const {step1, step2, step3, step5, step6, step7} = ANKETA_ATTRIBUTES;
    if (onlyLife) return parts.reduce((prev, next) => {
        const {row} = next.questions;
        const {name} = next;
        if (!Array.isArray(row) || name !== "Страхование жизни, потери трудоспособности залогодателя вопросы общего характера") return [...prev, next];
        return [...prev, {...next, questions: {row: buildStep4(formData, row)}}];
    }, []);

    return parts.reduce((prev, next) => {
        const {row} = next.questions;
        const {name} = next;
        if (!Array.isArray(row)) return [...prev, next];
        switch (name) {
            case "Общие сведения о страхователе":
                return [...prev, {
                    ...next,
                    questions: {row: buildStep(formData, row, step1)}
                }]
            case "Сведения о занятости страхователя":
                return [...prev, {...next, questions: {row: buildStep(formData, row, step2)}}]
            case "Основные положения  кредитного договора":
                return [...prev, {...next, questions: {row: buildStep3(formData, row, step3)}}]
            case "Страхование жизни, потери трудоспособности залогодателя вопросы общего характера":
                return [...prev, {...next, questions: {row: buildStep4(formData, row)}}]
            case "Страхование имущества от гибели, утраты, повреждения":
                return [...prev, {
                    ...next,
                    questions: {row: buildStep(((formData || {}).propertyInsuranceSubjects || [])[0] || {}, row, step5)}
                }]
            case "Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности":
                return [...prev, {
                    ...next,
                    questions: {row: buildStep(((formData || {}).lossInsuranceSubjects || [])[0] || {}, row, step6)}
                }]
            case "Дополнительная информация о страхователе":
                return [...prev, {...next, questions: {row: buildStep(formData, row, step7)}}]
            default:
                return prev;
        }
    }, [])
}

export const anketaSettingsBuilder = (formData: any, settingsBack: any[]) => settingsBack.reduce((prev, next) => {
    const {isn, value, value_desc} = next;
    const findName: string | undefined = ANKETA_SETTINGS.find(anketaSetting => anketaSetting.isn === isn)?.name;
    if (isn == "950711" && getSelectValue(formData['insureType']) != "1") {
        return [...prev, {
            ...next,
            value: getSelectValue(formData['insureType']),
            value_desc: getSelectValue(formData['insureType'], true)
        }];
    }
    if (!findName) return [...prev, next];
    return [...prev, {
        ...next,
        value: formData[findName]?.data?.city || value,
        value_desc: formData[findName]?.data?.city || value_desc
    }]
}, []);

export const agreementAttributesBuilder = (formData: any) => {
    formData.agreementDate = formData.creditIssueDate;
    formData.creditIssueDate = moment().format("DD.MM.YYYY");

    const {propertyInsuranceSubjects, lossInsuranceSubjects, riskLife} = formData || {};
    const propertyInsuranceSubjectsLength = (propertyInsuranceSubjects || []).length;
    const lossInsuranceSubjectsLength = (lossInsuranceSubjects || []).length;
    const coBorrowers = getPerson(formData, "coBorrower");
    const borrowersLength = riskLife ? (coBorrowers || []).length + 1 : 0;
    return [
        {isn: "833221", val: borrowersLength},
        {isn: "833231", val: propertyInsuranceSubjectsLength},
        {isn: "833301", val: lossInsuranceSubjectsLength},
        ...AGREEMENT_ATTRIBUTES.map(({name, isn, isSelect, isCanBeZero}) => {
            if (isSelect) return {
                isn,
                val: getSelectValue(formData[name]),
                value: getSelectValue(formData[name], true)
            }
            if (isCanBeZero) return {
                isn,
                val: formData[name] || "10"
            }
            return {
                isn,
                val: formData[name]
            }
        }),
        {
            isn: "1989151",
            val: formData['dealCountry']?.value,
            // value: formData['dealCountry']?.value,
            remark: formData['dealCountry']?.data?.city_fias_id
        },
        ...(formData.havePromocode ? [
            {isn: "696341", val: formData?.promocode, value: formData?.promocode},
            {isn: "1956811", val: getSelectValue(formData?.promocodeBank), value: getSelectValue(formData?.promocodeBank, true)  },
            {isn: "1956801", value: formData?.promocodePhone}
        ] : [])
    ]
};

export const BORROWER_SUB_CLASS_ISN = "3028"; // SubClassISN заемщика;
export const COBORROWER_SUB_CLASS_ISN = "303981"; // SubClassISN созаемщика;
export const CLASS_ISN_OBJECTS: string = "654381"; //
export const CLASS_ISN_BORROWERS: string = "2135"; //
export const CLASS_ISN_TITLES: string = "2124"; //
export const CLASS_ISN_INSURER: string = "2103"; // isn роли страховатля
export const CLASS_ISN_INSURED: string = "2082"; // isn роли застрахованного
export const CLASS_ISN_BENEFICARY: string = "2081"; // isn роли выгодопреобретателя
export const CLASS_ISN_SIGNER: string = "221507"; // isn роли подписанта
export const SQUARE_ISN: string = "859691"; // isn доп атрибута общая площадь
export const SHARE_ISN: string = "833241"; // isn доп атрибута доля созаемщика
export const TARIFF_ISN: string = "849081"; // isn доп атрибута тариф
export const STATUS_ISN: string = "1862781"; // isn доп атрибута статус
export const ANKETA_ISN: string = "877111"; // isn доп атрибута анкета
export const IS_BY_DECLARATION: string = "1925031";
export const IS_BY_CUSTOM_FILE: string = "1856521";
export const REMARK_ISN: string = "1862771"; // isn доп атрибута заключение скоринга
export const BORROWER_ALLOWANCE_ISN: string = "772541"; // isn доп атрибута надбавка у заемщиков
export const ALLOWANCE_ISN: string = `${process.env.REACT_APP_ALLOWANCE_ISN}`; // isn доп атрибута надбавка
export const DISCOUNT_ISN: string = "836271"; // isn доп атрибута скидка
export const CADASTRAL_NUMBER_ISN: string = "859701"; // isn доп атрибута кадастровый номер
export const PROPERTY_COST_ISN: string = `${process.env.REACT_APP_PROPERTY_COST_ISN}`; // isn доп атрибута стоимость объекта недвижимости
export const PROPERTY_YEAR_OF_BUILD_ISN: string = "834341"; // isn доп атрибута год постройки объекта недвижимости
export const TITLE_DATE_END_ISN: string = "833441"; // isn доп атрибута дата окончания титуда
export const CONSTANT_PAY_ISN: string = "856031"; // isn доп атрибута постоянная страховая сумма
export const ONE_TIME_PAY_ISN: string = "856021"; // isn доп атрибута единовременная оплата
export const HEIGHT_WEIGHT_ISN: string = "852981"; // isn доп атрибута надбавки по росту или весу
export const PRESSURE_ISN: string = "852991"; // isn доп атрибута надбавки по давоению
export const KV_DISCOUNT_ISN: string = "606471"; // isn атрибута скидка за счет компании
export const SBERBANK_ISN: string = "950291"; // isn банка сбер
export const BALANCE_BEFORE_ADD_RISKS_ISN: string = "957061"; // isn атрибута отстатка при достраховании
export const PROPERTY_ADDRESS_EGRN: string = "1879201"; // isn атрибута отстатка при достраховании
export const TITLE_ADDRESS_EGRN: string = "1879201"; // isn атрибута адреса титула строкой
export const CLASS_ISN_AGENT: string = "2077"; // isn класса агента
export const CLASS_ISN_AGENT_UOS2: string = "654491"; // isn класса агента уос2
export const TARIFF_GROUP_ISN: string = "654151"; // isn аттрибута тарифной группы
export const DEALER_CITY_ISN: string = "1988931";
// export const DEALER_CITY_ISN: string = "1988271"; // for prod
export const PROGRAM_ISN: string = "654161"; // isn аттрибута программы
// export const scheduleIsn: string = "850661"; // isn доп атрибута График страховых взносов

type StatusTypes = "approved" | "deny" | "underwriting";

export interface IRiskItem {
    risk: string,
    object: string,
    status: StatusTypes,
    data: any
}

export const getVariablesForDoc = (agreementData) => {
    const {attribs, clientInfo} = agreementData || {};
    const summ: string = (((attribs || []).find(({isn}) => isn === "831731")) || {}).val;
    const {val: bankIsn, value: bankName} = ((attribs || []).find(({isn}) => isn === "831791")) || {};
    const insurer: string = (clientInfo || {}).FULLNAME || "unknown";

    return {
        summ,
        bankIsn,
        bankName,
        insurer
    }
};

export const getRestrictionsObject = (restrictions: any[]) => {
    if (!(restrictions || []).length) return {};
    return restrictions.reduce((prev, {tag, value}) => ({...prev, [tag]: value}), {})
}

export const getBankRaising = (raising: number | undefined, creditRate?: number) => {
    switch (raising) {
        case 0:
            return 0;
        case 1:
            return 10;
        case 2:
            return creditRate || 0;
        default:
            return 0;
    }
}

export const checkDeclaration = (restrictions: any, formData: any) => {
    const {
        declaration,
        declarationLife,
        declarationProperty,
        declarationTitle,
        declarationSummLife: maxDeclarationSummLife,
        declarationAgeLimit: declarationMaxAge,
        declarationSummProperty: maxDeclarationSummProperty,
        // declarationSummTitle: maxDeclarationSummTitle,
        declarationYearOfBuild: maxDeclarationYearOfBuild,
        declarationObjectType,
        declarationWallMaterials,
        declarationFloorMaterials,
        // declarationOwnTitle
    } = restrictions;
    const {riskLife, riskProperty, riskTitle, propertyInsuranceSubjects, summ} = formData;
    const {birthDate} = getPerson(formData, "contragent");
    const isHaveCoBorrowers: boolean = (getPerson(formData, "coBorrower") || []).length !== 0;
    const currentAge: number = moment().year() - moment(formatDateToUsa(birthDate)).year();
    const currentSumm: number = stringToNumber(summ);

    if (!declaration || isHaveCoBorrowers) return false;
    const isOkLife: boolean = riskLife ? declarationLife && currentSumm <= maxDeclarationSummLife && currentAge <= declarationMaxAge : true;
    const isOkPeoperty: boolean = riskProperty ? (propertyInsuranceSubjects || []).reduce((prev, property) => {
        const {yearOfBuild, pledge, floorMaterial, wallMaterial} = property;
        const isOk: boolean =
            maxDeclarationYearOfBuild <= stringToNumber(yearOfBuild)
            && declarationProperty
            && declarationObjectType.includes(pledge?.value)
            && declarationFloorMaterials.includes(floorMaterial?.value)
            && declarationWallMaterials.includes(wallMaterial?.value)
            && currentSumm <= maxDeclarationSummProperty
        if (isOk) return prev;
        return false;
    }, true) : true;
    const isOkTitle: boolean = riskTitle ? declarationTitle /*&& currentSumm <= maxDeclarationSummTitle*/ : true;
    return isOkLife && isOkPeoperty && isOkTitle;
};

const buildRiskObject = (risk: string, object: string, status: string | undefined, data: any) => {
    return {
        risk,
        object,
        status,
        data
    }
};

export const createRiskStatusObject = (agreementData, formData, notKiasRisks: any[], restrictions: any, bankRaising: number) => {
    const {
        autoscoring,
        autoscoringLife,
        autoscoringProperty,
        objectType,
        autoscoringTitle,
        yearOfBuild: maxYearOfBuild,
        summLife: maxSummLife,
        summProperty: maxSummProperty,
        ageLimit: maxAge
    } = restrictions || {};
    const {objects, isn, attribs} = agreementData || {};
    const summWithoutRaising: number = stringToNumber(getValueByAttributeId(attribs, "summ"));
    const summ: number = (summWithoutRaising / 100) * bankRaising + summWithoutRaising;
    const contragent = getPerson(formData, "contragent");
    const isDeclaration: boolean = contragent.fillingType === "declaration";
    const riskObjects: IRiskItem[] = objects.map(object => {
        const {ClassISN, ISN, AGRCOND: agrCondObject, SUBJINFO, SubjISN, SubClassName} = object;
        const {scoringCheck} = ((formData || {}).peoples || []).find(({isn}) => isn === SubjISN) || contragent || {};
        const currentRisk = (notKiasRisks || []).find(({object}) => ISN === object);
        const {data: dataCurrentRisk, status} = currentRisk || {};
        const {basicTariff: basicTariffFromDoc, basicPremium: basicPremiumFromDoc} = dataCurrentRisk || {};
        const {row: rowAgrCond} = agrCondObject || {};
        const {
            PremiumSum: basicPremium,
            Tariff: basicTariff,
            LimitSum: limitSum
        } = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} : rowAgrCond || {};

        const basicData = {
            basicTariff: basicTariffFromDoc || basicTariff,
            basicPremium: basicPremiumFromDoc || basicPremium,
            riskName: SubClassName
        }

        const sumToCheck = stringToNumber(limitSum) || summ;

        if (isDeclaration) return buildRiskObject(ClassISN, ISN, status ? status : "approved", basicData);
        if (autoscoring) {
            if (ClassISN === CLASS_ISN_BORROWERS && scoringCheck === "successful") {
                const age: number = moment().diff(formatDateToUsa(String(getKiasDate((SUBJINFO || {}).BIRTHDAY || ""))), 'years');
                const isOkLife: boolean = !!autoscoringLife && sumToCheck <= maxSummLife && age <= maxAge;
                return buildRiskObject(ClassISN, ISN, status ? status : isOkLife ? "approved" : "underwriting", basicData);
            }
            if (ClassISN === CLASS_ISN_OBJECTS) {
                const pledgeType = object.SubClassISN;
                const yearOfBuild: number = stringToNumber(((((object || {}).AGROBJECT_ADDATTR || {}).row || []).find(({ATTRISN}) => ATTRISN === PROPERTY_YEAR_OF_BUILD_ISN) || {}).VAL);
                const isOkProperty: boolean = !!autoscoringProperty && summ <= maxSummProperty && objectType.includes(pledgeType) && Number(maxYearOfBuild) <= yearOfBuild;
                return buildRiskObject(ClassISN, ISN, status ? status : isOkProperty ? "approved" : "underwriting", basicData);
            }
            if (ClassISN === CLASS_ISN_TITLES) {
                const isOkTitle: boolean = !!autoscoringTitle;
                return buildRiskObject(ClassISN, ISN, status ? status : isOkTitle ? "approved" : "underwriting", basicData);
            }
            return buildRiskObject(ClassISN, ISN, status ? status : "underwriting", basicData);
        }

        return buildRiskObject(ClassISN, ISN, status ? status : "underwriting", basicData);
    });

    const filledAgreement = {
        ...agreementData,
        attribs: (attribs || []).map(({__typename, ...other}) => other),
        objects: objects.map(object => {
            const {ISN, AGROBJECT_ADDATTR} = object;
            const status: StatusTypes = riskObjects.find(({object}) => object === ISN)?.status || "underwriting";
            const newAttributes = {
                row: ((AGROBJECT_ADDATTR || {}).row || []).map(attribute => {
                    const {ATTRISN} = attribute || {};
                    if (ATTRISN === STATUS_ISN) return {
                        ...attribute,
                        VALUE: getRiskStatusForKias(status),
                        VAL: getRiskStatusForKias(status)
                    }; // статус

                    return attribute;
                })
            }

            return {...object, AGROBJECT_ADDATTR: newAttributes};
        })
    }

    return {
        isn,
        riskObjects,
        filledAgreement
    }
}


export const getRiskForObject = (objectClassName: string) => {
    switch (objectClassName) {
        case CLASS_ISN_OBJECTS:
            return "Имущество"
        case CLASS_ISN_BORROWERS:
            return "Жизнь"
        case CLASS_ISN_TITLES:
            return "Титул"
        default:
            return "Unknown"
    }
}

export const agreementAdditionalFilling = (formData: any, agreementData: any, isClientFiling?: boolean) => {
    const {objects, attribs, roles} = agreementData || {};
    const {propertyInsuranceSubjects, lossInsuranceSubjects} = formData || {};
    const insuredIsn: string | undefined = getPerson(formData, "insured").isn;
    const coBorrowers = getPerson(formData, "coBorrower");
    const contragent = getPerson(formData, "contragent");
    const borrowers = objects.filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS).map((borrower, index) => {
        const {
            fullName,
            isn,
            share,
            heightWeight,
            pressure,
            isnAnketa,
            scoringCheck,
            scoringResult
        } = (coBorrowers || [])[index - 1] || {};
        const {
            fullName: fullNameContragent,
            share: shareContragent,
            heightWeight: heightWeightContragent,
            pressure: pressureContragent,
            isnAnketa: isnAnketaContragent,
            scoringCheck: scoringCheckContragent,
            scoringResult: scoringResultContragent
        } = contragent || {};

        const {SubClassISN, AGROBJECT_ADDATTR} = borrower || {};
        const {row} = AGROBJECT_ADDATTR || {};
        if (SubClassISN === BORROWER_SUB_CLASS_ISN) return ({
            ...borrower,
            ObjName: fullNameContragent,
            AGROBJECT_ADDATTR: {
                row: row.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SHARE_ISN) return {
                        ...attribute,
                        VAL: shareContragent || 100,
                        VALUE: shareContragent || 100
                    };
                    if (ATTRISN === ANKETA_ISN) return {
                        ...attribute,
                        VAL: isnAnketaContragent,
                        VALUE: isnAnketaContragent
                    };
                    if (ATTRISN === HEIGHT_WEIGHT_ISN) return {
                        ...attribute,
                        VAL: heightWeightContragent,
                        VALUE: heightWeightContragent
                    };
                    if (ATTRISN === PRESSURE_ISN) return {
                        ...attribute,
                        VAL: pressureContragent,
                        VALUE: pressureContragent
                    };
                    if (ATTRISN === REMARK_ISN) return {
                        ...attribute,
                        VAL: scoringCheckContragent,
                        VALUE: scoringCheckContragent,
                        REMARK: (scoringResultContragent || "").substring(0, 500)
                    };
                    return attribute;
                })
            }
        })
        return ({
            ...borrower,
            SubjISN: isn,
            ObjName: fullName,
            AGROBJECT_ADDATTR: {
                row: row.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SHARE_ISN) return {...attribute, VAL: share, VALUE: share};
                    if (ATTRISN === ANKETA_ISN) return {...attribute, VAL: isnAnketa, VALUE: isnAnketa};
                    if (ATTRISN === HEIGHT_WEIGHT_ISN) return {...attribute, VAL: heightWeight, VALUE: heightWeight};
                    if (ATTRISN === PRESSURE_ISN) return {...attribute, VAL: pressure, VALUE: pressure};
                    if (ATTRISN === REMARK_ISN) return {
                        ...attribute,
                        VAL: scoringCheck,
                        VALUE: scoringCheck,
                        REMARK: (scoringResult || "").substring(0, 500)
                    };
                    return attribute;
                })
            }
        })
    });

    const insuredObjectsOld = objects.filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS);
    const titlesObjectsOld = objects.filter(({ClassISN}) => ClassISN === CLASS_ISN_TITLES);

    if (isClientFiling) return {
        ...agreementData,
        attribs: (attribs || []).map(({__typename, ...other}) => other),
        objects: [...insuredObjectsOld, ...borrowers, ...titlesObjectsOld]
    };

    const insuredObjects = insuredObjectsOld.map((object, index) => {
        const {
            pledge,
            area,
            pledgeAddress,
            pledgeAddressEgrn,
            cadastralNumber,
            pledgeShare,
            subjectCost,
            yearOfBuild
        } = (propertyInsuranceSubjects || [])[index] || {};
        const row: any[] = ((object || {}).AGROBJECT_ADDATTR || {}).row || [];
        return ({
            ...object,
            AGROBJADDR: pledgeAddress,
            SubClassISN: getSelectValue(pledge),
            SubClassName: getSelectValue(pledge, true),
            AGROBJECT_ADDATTR: {
                row: row.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SQUARE_ISN) return {...attribute, VAL: area, VALUE: area};
                    if (ATTRISN === CADASTRAL_NUMBER_ISN) return {
                        ...attribute,
                        VAL: cadastralNumber,
                        VALUE: cadastralNumber
                    };
                    if (ATTRISN === SHARE_ISN) return {...attribute, VAL: pledgeShare, VALUE: pledgeShare};
                    if (ATTRISN === PROPERTY_COST_ISN) return {...attribute, VAL: subjectCost, VALUE: subjectCost};
                    if (ATTRISN === PROPERTY_YEAR_OF_BUILD_ISN) return {
                        ...attribute,
                        VAL: yearOfBuild,
                        VALUE: yearOfBuild
                    };
                    if (ATTRISN === PROPERTY_ADDRESS_EGRN) return {
                        ...attribute,
                        VAL: pledgeAddressEgrn,
                        VALUE: pledgeAddressEgrn
                    };
                    return attribute;
                })
            }
        })
    });

    const titlesObjects = titlesObjectsOld.map((object, index) => {
        const {
            titleShare,
            titleAddress,
            cadastralNumber,
            titleAddressEgrn
        } = (lossInsuranceSubjects || [])[index] || {};
        const row: any[] = ((object || {}).AGROBJECT_ADDATTR || {}).row || [];
        return ({
            ...object,
            AGROBJADDR: titleAddress,
            AGROBJECT_ADDATTR: {
                row: row.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === CADASTRAL_NUMBER_ISN) return {
                        ...attribute,
                        VAL: cadastralNumber,
                        VALUE: cadastralNumber
                    };
                    if (ATTRISN === SHARE_ISN) return {...attribute, VAL: titleShare, VALUE: titleShare};
                    if (ATTRISN === TITLE_ADDRESS_EGRN) return {
                        ...attribute,
                        VAL: titleAddressEgrn,
                        VALUE: titleAddressEgrn
                    };
                    return attribute;
                })
            }
        })
    });

    const newRoles = !insuredIsn ? roles : roles.map((role) => {
        const {ClassISN} = role;
        if (ClassISN !== CLASS_ISN_INSURED) return role;
        return {...role, SubjISN: insuredIsn}
    })
    return {
        ...agreementData,
        attribs: (attribs || []).map(({__typename, ...other}) => other),
        roles: newRoles,
        objects: [...insuredObjects, ...borrowers, ...titlesObjects]
    };
};

const getRiskStatusForKias = (status: StatusTypes) => {
    switch (status) {
        case "approved":
            return "1";
        case "deny":
            return "0";
        case "underwriting":
            return undefined;
    }
}

export const agreementUnderwritingFilling = (formData, agreementData, docRisks) => {
    const {objects, roles, attribs} = agreementData || {};
    const {currentBeneficiary, tariffGroup, program} = formData || {};
    const riskVariables: any[] = [];
    const discountsList: number[] = [];
    const newObjects = (objects || []).map(object => {
            const {AGROBJECT_ADDATTR, /*AGRCOND,*/ ISN} = object || {};
            const valueHeightWeight = formData[`heightWeight-${ISN}`];
            const valuePressure = formData[`pressure-${ISN}`];
            const valueAllowance = formData[`allowance-${ISN}`];
            const valueDiscount = stringToNumber(formData[`discount-${ISN}`]) === 0 ? '0' : formData[`discount-${ISN}`];
            const valueTariff = formData[`tariff-${ISN}`];
            discountsList.push(stringToNumber(valueDiscount))
            const riskDoc = (docRisks || []).find(({object: objectIsn}) => objectIsn === ISN) || {};
            const {__typename, ...riskDocData} = riskDoc;
            const {object: isnRisk, status, data: dataDoc} = riskDocData;
            const {basicTariff} = dataDoc || {};
            const newStatus: StatusTypes = formData[`risk-${isnRisk}`] || status;
            const newTariff: string = formData[`tariff-${isnRisk}`] || basicTariff;
            if (isnRisk) riskVariables.push({...riskDocData, status: newStatus, data: {...dataDoc, basicTariff: newTariff}})
            const {row: rowAttributes} = AGROBJECT_ADDATTR || {};
            const newAGROBJECT_ADDATTR = {
                row: (rowAttributes || []).map(attribute => {
                    const {ATTRISN, VAL, VALUE} = attribute || {};
                    if (ATTRISN === BORROWER_ALLOWANCE_ISN) return {
                        ...attribute,
                        VALUE: valueAllowance,
                        VAL: valueAllowance
                    }; // надбавка у заемщика
                    if (ATTRISN === ALLOWANCE_ISN) return {
                        ...attribute,
                        VALUE: valueAllowance || VALUE,
                        VAL: valueAllowance || VAL
                    }; // надбавка
                    if (ATTRISN === TARIFF_ISN) return {...attribute, VALUE: valueTariff || VALUE, VAL: valueTariff || VAL}; // тариф
                    if (ATTRISN === STATUS_ISN) return {
                        ...attribute,
                        VALUE: getRiskStatusForKias(newStatus),
                        VAL: getRiskStatusForKias(newStatus)
                    }; // статус
                    if (ATTRISN === DISCOUNT_ISN) return {
                        ...attribute,
                        VALUE: `-${valueDiscount}`,
                        VAL: `-${valueDiscount}`
                    }; // скидка
                    if (ATTRISN === HEIGHT_WEIGHT_ISN) return {
                        ...attribute,
                        VALUE: valueHeightWeight,
                        VAL: valueHeightWeight
                    }; // надбавка рост вес
                    if (ATTRISN === PRESSURE_ISN) return {...attribute, VALUE: valuePressure, VAL: valuePressure}; // надбавка давление
                    return attribute;
                })
            }
            return {...object, AGROBJECT_ADDATTR: newAGROBJECT_ADDATTR/*, AGRCOND: newAgrCond*/};
        }
    )

    const newAttributes = (attribs || []).map(attribute => {
        const {__typename, ...other} = attribute || {};
        const {isn} = other;
        if (isn === TARIFF_GROUP_ISN && tariffGroup) return {
            ...other,
            val: tariffGroup.value,
            value: tariffGroup.label
        };
        if (isn === PROGRAM_ISN && program) return {...other, val: program.value, value: program.label};
        return other

    })

    const newRoles = (roles || []).map(role => {
        const {ClassISN, SubjISN} = role || {};
        if (ClassISN !== CLASS_ISN_BENEFICARY) return role; // выгодопреобретаткль
        return {...role, SubjISN: currentBeneficiary || SubjISN}
    })

    return {
        agreementVariables: {
            ...agreementData,
            objects: newObjects,
            roles: newRoles,
            attribs: newAttributes
        },
        riskVariables,
        discountsList
    }
}

export const agreementSellerFilling = (formData, agreementData, canChangeDiscount, canChangeAllowance) => {
    const {objects,attribs} = agreementData || {};
    const discountsList: number[] = [];
    const newObjects = (objects || []).map(object => {
          const {AGROBJECT_ADDATTR, /*AGRCOND,*/ ISN} = object || {};
          const valueAllowance = formData[`allowance-${ISN}`];
          const valueDiscount = stringToNumber(formData[`discount-${ISN}`]) === 0 ? '0' : formData[`discount-${ISN}`];
          discountsList.push(stringToNumber(valueDiscount))
          const {row: rowAttributes} = AGROBJECT_ADDATTR || {};
          const newAGROBJECT_ADDATTR = {
              row: (rowAttributes || []).map(attribute => {
                  const {ATTRISN, VAL, VALUE} = attribute || {};
                  if (ATTRISN === BORROWER_ALLOWANCE_ISN && canChangeAllowance) return {
                      ...attribute,
                      VALUE: valueAllowance,
                      VAL: valueAllowance
                  }; // надбавка у заемщика
                  if (ATTRISN === ALLOWANCE_ISN && canChangeAllowance) return {
                      ...attribute,
                      VALUE: valueAllowance || VALUE,
                      VAL: valueAllowance || VAL
                  }; // надбавка
                  if (ATTRISN === DISCOUNT_ISN && canChangeDiscount) return {
                      ...attribute,
                      VALUE: `-${valueDiscount}`,
                      VAL: `-${valueDiscount}`
                  }; // скидка
                  return attribute;
              })
          }
          return {...object, AGROBJECT_ADDATTR: newAGROBJECT_ADDATTR/*, AGRCOND: newAgrCond*/};
      }
    )

    const newAttributes = (attribs || []).map(attribute => {
        const {__typename, ...other} = attribute || {};
        return other
    })

    return {
        agreementVariables: {
            ...agreementData,
            objects: newObjects,
            attribs: newAttributes
        },
        discountsList
    }
}

export const contractFilling = (formData, contractData, deletedObjects: string[], isProlong: boolean) => {
    const {borrowersList, propertiesList, titlesList, dateSign, dateBeg, curIsn, dateEnd} = formData || {};
    const {attribs, objects} = contractData || {};

    const newAttributes = (attribs || []).map(attribute => {
        const {__typename, ...other} = attribute || {};
        const {isn} = other;
        if(isn === "1925031" && isProlong) return {...other, val: "1", value: "1"};
        const {name} = AGREEMENT_ATTRIBUTES.find(({isn: attributeIsn}) => attributeIsn === isn) || {};
        if (isn === "877111" || !name) return other;
        if(["1856521", "1925031"].includes(isn)) return other;
        return {...other, val: formData[name]}
    })

    const borrowersObjects = (objects || []).filter(({
                                                         ClassISN,
                                                         ISN
                                                     }) => ClassISN === CLASS_ISN_BORROWERS && !deletedObjects.includes(ISN)).map((borrower, index) => {
        const {AGROBJECT_ADDATTR} = borrower || {};
        const {heightWeight, pressure, discount, allowance} = (borrowersList || [])[index] || {}
        const newAGROBJECT_ADDATTR = {
            row: ((AGROBJECT_ADDATTR || {}).row || []).map(attribute => {
                const {ATTRISN, VAL, VALUE} = attribute || {};
                console.log(allowance, allowance || VALUE)

                if (ATTRISN === DISCOUNT_ISN) return {...attribute, VALUE: `-${discount}`, VAL: `-${discount}`}; // скидка
                if (ATTRISN === HEIGHT_WEIGHT_ISN) return {...attribute, VALUE: heightWeight, VAL: heightWeight}; // надбавка рост вес
                if (ATTRISN === PRESSURE_ISN) return {...attribute, VALUE: pressure, VAL: pressure}; // надбавка давление
                if (ATTRISN === BORROWER_ALLOWANCE_ISN) return {
                    ...attribute,
                    VALUE: allowance || VALUE,
                    VAL: allowance || VAL
                }; // надбавка
                return attribute;
            })
        }
        return {
            ...borrower,
            AGROBJECT_ADDATTR: newAGROBJECT_ADDATTR
        }
    });

    const propertiesObjects = (objects || []).filter(({
                                                          ClassISN,
                                                          ISN
                                                      }) => ClassISN === CLASS_ISN_OBJECTS && !deletedObjects.includes(ISN)).map((property, index) => {
        const {AGROBJECT_ADDATTR} = property || {};
        const {
            propertyConstantPay,
            propertyOneTimePay,
            propertyAllowance,
            propertyBasicTariff,
            propertyDiscount
        } = (propertiesList || [])[index] || {}

        const newAGROBJECT_ADDATTR = {
            row: ((AGROBJECT_ADDATTR || {}).row || []).map(attribute => {
                const {ATTRISN, VALUE, VAL} = attribute || {};
                if (ATTRISN === TARIFF_ISN) return {
                    ...attribute,
                    VALUE: propertyBasicTariff || VALUE,
                    VAL: propertyBasicTariff || VAL
                };
                if (ATTRISN === DISCOUNT_ISN) return {
                    ...attribute,
                    VALUE: `-${propertyDiscount}`,
                    VAL: `-${propertyDiscount}`
                }; // скидка
                if (ATTRISN === ALLOWANCE_ISN) return {
                    ...attribute,
                    VALUE: propertyAllowance || VALUE,
                    VAL: propertyAllowance || VAL
                }; // надбавка
                if (ATTRISN === CONSTANT_PAY_ISN) return {
                    ...attribute,
                    VAL: propertyConstantPay ? "1" : "0",
                    VALUE: propertyConstantPay ? "1" : "0"
                }; // доля
                if (ATTRISN === ONE_TIME_PAY_ISN) return {
                    ...attribute,
                    VAL: propertyOneTimePay ? "1" : "0",
                    VALUE: propertyOneTimePay ? "1" : "0"
                }; // доля
                return attribute;
            })
        }
        return {
            ...property,
            AGROBJECT_ADDATTR: newAGROBJECT_ADDATTR
        }
    })

    const titlesObjects = (objects || []).filter(({
                                                      ClassISN,
                                                      ISN
                                                  }) => ClassISN === CLASS_ISN_TITLES && !deletedObjects.includes(ISN)).map((title, index) => {
        const {AGROBJECT_ADDATTR} = title || {};
        const {titleDateEnd, titleConstantPay, titleOneTimePay} = (titlesList || [])[index] || {}

        const newAGROBJECT_ADDATTR = {
            row: ((AGROBJECT_ADDATTR || {}).row || []).map(attribute => {
                const {ATTRISN} = attribute || {};
                if (ATTRISN === CONSTANT_PAY_ISN) return {
                    ...attribute,
                    VAL: titleConstantPay ? "1" : "0",
                    VALUE: titleConstantPay ? "1" : "0"
                }; // доля
                if (ATTRISN === ONE_TIME_PAY_ISN) return {
                    ...attribute,
                    VAL: titleOneTimePay ? "1" : "0",
                    VALUE: titleOneTimePay ? "1" : "0"
                }; // доля
                if (ATTRISN === TITLE_DATE_END_ISN) return {...attribute, VAL: titleDateEnd, VALUE: titleDateEnd}; // доля
                return attribute;
            })
        }
        return {
            ...title,
            AGROBJECT_ADDATTR: newAGROBJECT_ADDATTR
        }
    })

    return {
        ...contractData,
        dateSign, dateBeg, curIsn: getSelectValue(curIsn), dateEnd,
        attribs: newAttributes,
        objects: [
            ...borrowersObjects,
            ...propertiesObjects,
            ...titlesObjects
        ],
    }
}

export const agreementEditFilling = (formData: any, agreementData: any, addRisks?: boolean) => {
    const {attribs, objects} = agreementData || {};
    const {propertyInsuranceSubjects, lossInsuranceSubjects} = formData || {};
    const contragent = getPerson(formData, "contragent");
    const coBorrowers = getPerson(formData, "coBorrower");
    const {
        share: shareContragent,
        heightWeight: heightWeightContragent,
        pressure: pressureContragent,
        isnAnketa: isnAnketaContragent,
        scoringCheck: scoringCheckContragent,
        scoringResult: scoringResultContragent
    } = contragent || {};
    const isnAttributesToChange = AGREEMENT_ATTRIBUTES.map(({isn}) => isn);

    const newAttribs = attribs.map(attribute => {
        const {isn, __typename, ...other} = attribute || {};
        if (!isnAttributesToChange.includes(isn) || isn === ANKETA_ISN) return {...other, isn};
        const {name, isSelect} = AGREEMENT_ATTRIBUTES.find(({isn: isnAttribute}) => isnAttribute === isn) || {};
        return {
            ...other,
            isn,
            val: isSelect ? getSelectValue(formData[name || "unknown"]) : formData[name || "unknown"]
        }
    })

    const contragentObject = (objects || []).find(({SubClassISN}) => SubClassISN === BORROWER_SUB_CLASS_ISN);
    const rowAgrCondContragent = contragentObject?.AGRCOND?.row || {};
    const rowAttributesContragent = contragentObject?.AGROBJECT_ADDATTR?.row || {};
    const newAttributesContragent = {
        row: rowAttributesContragent.map(attribute => {
            const {ATTRISN} = attribute;
            if (ATTRISN === SHARE_ISN) return {
                ...attribute,
                VAL: shareContragent || 100,
                VALUE: shareContragent || 100
            };
            if (ATTRISN === ANKETA_ISN) return {...attribute, VAL: isnAnketaContragent, VALUE: isnAnketaContragent};
            if (ATTRISN === HEIGHT_WEIGHT_ISN) return {
                ...attribute,
                VAL: heightWeightContragent,
                VALUE: heightWeightContragent
            };
            if (ATTRISN === PRESSURE_ISN) return {...attribute, VAL: pressureContragent, VALUE: pressureContragent};
            if (ATTRISN === REMARK_ISN) return {
                ...attribute,
                VAL: scoringCheckContragent,
                VALUE: scoringCheckContragent,
                REMARK: (scoringResultContragent || "").substring(0, 500)
            };
            return attribute;
        })
    }
    const newContragentObject = {...contragentObject, AGROBJECT_ADDATTR: newAttributesContragent};

    const clearAgrCondContragent = Array.isArray(rowAgrCondContragent) ? {
        ...((rowAgrCondContragent || []).find(({CondName}) => CondName === "1") || {}),
        ISN: "",
        ObjISN: ""
    } : {
        ...rowAgrCondContragent,
        ISN: "",
        ObjISN: ""
    };
    const clearBorrowerForSave = {
        ...contragentObject,
        SubClassISN: COBORROWER_SUB_CLASS_ISN,
        ISN: "",
        SubjISN: "",
        AGRCOND: {row: clearAgrCondContragent}
    };

    const newCoBorrowersList = coBorrowers.map(({
                                                    isn,
                                                    share,
                                                    fullName,
                                                    heightWeight,
                                                    pressure,
                                                    isnAnketa,
                                                    scoringResult,
                                                    scoringCheck
                                                }) => {
        const isAlreadyHaveCoBorrower = (objects || []).find(({
                                                                  SubjISN,
                                                                  SubClassISN
                                                              }) => SubClassISN === COBORROWER_SUB_CLASS_ISN && SubjISN === isn);
        const {AGROBJECT_ADDATTR} = isAlreadyHaveCoBorrower || {};
        const {row: rowAlreadyObject} = AGROBJECT_ADDATTR || {};

        if (isAlreadyHaveCoBorrower) return {
            ...isAlreadyHaveCoBorrower,
            AGROBJECT_ADDATTR: {
                row: rowAlreadyObject.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SHARE_ISN) return {...attribute, ISN: "", VAL: share, VALUE: share};
                    if (ATTRISN === HEIGHT_WEIGHT_ISN) return {
                        ...attribute,
                        ISN: "",
                        VAL: heightWeight,
                        VALUE: heightWeight
                    };
                    if (ATTRISN === PRESSURE_ISN) return {...attribute, ISN: "", VAL: pressure, VALUE: pressure};
                    if (ATTRISN === ANKETA_ISN) return {...attribute, ISN: "", VAL: isnAnketa, VALUE: isnAnketa};
                    if (ATTRISN === REMARK_ISN) return {
                        ...attribute,
                        VAL: scoringCheck,
                        VALUE: scoringCheck,
                        REMARK: (scoringResult || "").substring(0, 500)
                    };
                    return {...attribute, ISN: ""};
                })
            }
        }
        return {
            ...clearBorrowerForSave,
            ObjName: fullName,
            SubjISN: isn,
            AGROBJECT_ADDATTR: {
                row: rowAttributesContragent.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SHARE_ISN) return {...attribute, ISN: "", VAL: share, VALUE: share};
                    if (ATTRISN === HEIGHT_WEIGHT_ISN) return {
                        ...attribute,
                        ISN: "",
                        VAL: heightWeight,
                        VALUE: heightWeight
                    };
                    if (ATTRISN === PRESSURE_ISN) return {...attribute, ISN: "", VAL: pressure, VALUE: pressure};
                    if (ATTRISN === ANKETA_ISN) return {...attribute, ISN: "", VAL: isnAnketa, VALUE: isnAnketa};
                    if (ATTRISN === REMARK_ISN) return {
                        ...attribute,
                        VAL: scoringCheck,
                        VALUE: scoringCheck,
                        REMARK: (scoringResult || "").substring(0, 500)
                    };
                    return {...attribute, VAL: [], VALUE: [], ISN: ""};
                })
            }
        }
    })


    const propertyObject = (objects || []).find(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS);
    const rowAgrCondProperty = propertyObject?.AGRCOND?.row || {};
    const rowAttributesProperty = propertyObject?.AGROBJECT_ADDATTR?.row || {};
    const clearAgrCondProperty = Array.isArray(rowAgrCondProperty) ? {
        ...((rowAgrCondProperty || []).find(({CondName}) => CondName === "1") || {}),
        ISN: "",
        // ObjISN: ""
    } : {
        ...rowAgrCondProperty,
        ISN: "",
        // ObjISN: ""
    };
    const clearPropertyForSave = {
        ...propertyObject,
        ISN: "",
        // SubjISN: "",
        AGRCOND: {row: clearAgrCondProperty}
    };

    const titleObject = (objects || []).find(({ClassISN}) => ClassISN === CLASS_ISN_TITLES);
    const rowAgrCondTitle = titleObject?.AGRCOND?.row || {};
    const rowAttributesTitle = titleObject?.AGROBJECT_ADDATTR?.row || {};
    const clearAgrCondTitle = Array.isArray(rowAgrCondTitle) ? {
        ...((rowAgrCondTitle || []).find(({CondName}) => CondName === "1") || {}),
        ISN: "",
        // ObjISN: ""
    } : {
        ...rowAgrCondTitle,
        ISN: "",
        // ObjISN: ""
    };
    const clearTitleForSave = {
        ...titleObject,
        ISN: "",
        // SubjISN: "",
        AGRCOND: {row: clearAgrCondTitle}
    };

    const propertiesObjects = !addRisks ? (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS) : (propertyInsuranceSubjects || []).map(({
                                                                                                                                                              pledge,
                                                                                                                                                              area,
                                                                                                                                                              pledgeShare,
                                                                                                                                                              pledgeAddressEgrn,
                                                                                                                                                              subjectCost,
                                                                                                                                                              yearOfBuild
                                                                                                                                                          }) => {
        return ({
            ...clearPropertyForSave,
            SubClassISN: getSelectValue(pledge),
            SubClassName: getSelectValue(pledge, true),
            AGROBJECT_ADDATTR: {
                row: rowAttributesProperty.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SQUARE_ISN) return {...attribute, ISN: "", VAL: area, VALUE: area};
                    if (ATTRISN === SHARE_ISN) return {...attribute, ISN: "", VAL: pledgeShare, VALUE: pledgeShare};
                    if (ATTRISN === PROPERTY_COST_ISN) return {
                        ...attribute,
                        ISN: "",
                        VAL: subjectCost,
                        VALUE: subjectCost
                    };
                    if (ATTRISN === PROPERTY_YEAR_OF_BUILD_ISN) return {
                        ...attribute,
                        ISN: "",
                        VAL: yearOfBuild,
                        VALUE: yearOfBuild
                    };
                    if (ATTRISN === PROPERTY_ADDRESS_EGRN) return {
                        ...attribute,
                        ISN: "",
                        VAL: pledgeAddressEgrn,
                        VALUE: pledgeAddressEgrn
                    };
                    return {...attribute, ISN: ""};
                })
            }
        })
    });

    const titlesObjects = !addRisks ? (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_TITLES) : (lossInsuranceSubjects || []).map(({titleShare}) => {
        return ({
            ...clearTitleForSave,
            AGROBJECT_ADDATTR: {
                row: rowAttributesTitle.map(attribute => {
                    const {ATTRISN} = attribute;
                    if (ATTRISN === SHARE_ISN) return {...attribute, ISN: "", VAL: titleShare, VALUE: titleShare};
                    return {...attribute, ISN: ""};
                })
            }
        })
    });

    return {
        ...agreementData,
        attribs: newAttribs,
        objects: [
            ...[newContragentObject, ...newCoBorrowersList],
            ...propertiesObjects,
            ...titlesObjects,
        ]
    }
}

export const setAgreementKvDiscount = (discount: string, agreementData: any) => {
    const {attribs} = agreementData || {};
    const newAttribs = attribs.map(attribute => {
        const {isn, __typename, ...other} = attribute || {};
        if (isn !== KV_DISCOUNT_ISN) return {...other, isn};
        return {...other, isn, val: `-${discount}`, value: `-${discount}`}
    })
    return {
        ...agreementData,
        attribs: newAttribs,
    }
}

export const getPerson = (formData: any, personType: "contragent" | "insured" | "coBorrower") => {
    if (personType === "coBorrower") return ((formData || {}).peoples || []).filter(({type}) => type === personType);
    return ((formData || {}).peoples || []).find(({type}) => type === personType) || {};
}

export const getValueByAttributeId = (attributes: any[], name: AttributesFormName, isGetValue?: boolean) => {
    const {isn: isnAttribute} = AGREEMENT_ATTRIBUTES.find(({name: attributeName}) => attributeName === name) || {};
    if (!isnAttribute) return undefined;
    const {val, value} = (attributes || []).find(({isn}) => isn === isnAttribute) || {};
    if (isGetValue && (Array.isArray(value) || !value)) return undefined;
    if (Array.isArray(val)) return undefined;
    if (isGetValue) return value;
    return val;
}


export const findValueByIsn = (typesArray, valuesArray) => {
    return typesArray.reduce((prev, {isn, name, isAddress, isSelect}) => {
        const {value, value_desc} = valuesArray.find(({isn: isnAnketaAttribute}) => isnAnketaAttribute === isn) || {};
        const valueToValue = Array.isArray(value) ? undefined : value;
        const valueDescToValue = Array.isArray(value_desc) ? undefined : value_desc;
        if (isAddress && !!valueToValue) return {...prev, [name]: {unrestricted_value: valueToValue}};
        if (isSelect) return {
            ...prev, [name]: {
                label: valueDescToValue,
                value: valueToValue
            }
        };
        return {...prev, [name]: valueToValue}
    }, {});
}

export const getValueFromObjectsAttributes = (object: any, isn: string, isGetValue?: boolean) => {
    if (!object) return undefined;
    const {VAL, VALUE} = (object?.AGROBJECT_ADDATTR?.row || []).find(({ATTRISN}) => ATTRISN === isn) || {};
    if (isGetValue && (Array.isArray(VALUE) || !VALUE)) return undefined;
    if (Array.isArray(VAL) || !VAL) return undefined;
    if (isGetValue) return VALUE;
    return VAL;
}

export const getKiasDate = (date) => {
    if (!date) return undefined;
    return (String(date).split(" ") || [])[0];
}

interface IKiasAgreementToFormState {
    agreementData: any
    anketaParts?: any[]
}

export const kiasAgreementToFormState: (data: IKiasAgreementToFormState) => any = ({agreementData, anketaParts}) => {
    const {objects, attribs} = agreementData || {};
    if (!objects || !attribs) return {};
    const borrowers = objects.filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS);
    const contragent = borrowers.find(({SubClassISN}) => SubClassISN === BORROWER_SUB_CLASS_ISN);
    const coBorrowers = borrowers.filter(({SubClassISN}) => SubClassISN === COBORROWER_SUB_CLASS_ISN)
    const titles = objects.filter(({ClassISN}) => ClassISN === CLASS_ISN_TITLES);
    const properties = objects.filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS);

    const anketaOneLevelValue = (anketaParts || []).reduce((prev, {name, questions: {row}}) => {
        if (IGNORED_STEPS_NAME.includes(name)) return prev;
        return [...prev, ...row]
    }, []);

    const agreementOneLevelValue = attribs.map(({isn, val, value}) => ({isn, value: val, value_desc: value}));
    const anketaAttributesOneLevel: any = findValueByIsn(Object.entries(ANKETA_ATTRIBUTES).reduce<any>((prev, [, value]) => ([...prev, ...value]), []), anketaOneLevelValue);
    const agreementAttributesOneLevel: any = findValueByIsn(AGREEMENT_ATTRIBUTES, agreementOneLevelValue);
    const allAttributes: any = {...(anketaAttributesOneLevel || {}), ...(agreementAttributesOneLevel || {})};

    const {
        summ, insureType, birthDate, field, jobPlace, creditTerms,
        creditRate,
        creditIssueDate,
        firstPayDate,
        salesChannel, creditOrganizationName, position, organizationName, address, isOfficeJob, annuityPay
    } = allAttributes || {};

    const borrowerShare: number = stringToNumber(getValueFromObjectsAttributes(contragent, SHARE_ISN))
    const objectTypeBuildYear: number = stringToNumber(getValueFromObjectsAttributes((properties || [])[0], PROPERTY_YEAR_OF_BUILD_ISN))
    const objectType = (properties || [])[0]?.SubClassName;

    // console.log("anketaParts", anketaParts)

    const resultFormState = {
        summ,
        riskLife: !!contragent,
        haveCoBorrower: !!coBorrowers.length,
        riskProperty: !!properties.length,
        objectType,
        objectTypeBuildYear,
        insureType,
        riskTitle: !!titles.length,
        property: Boolean(!!properties.length || !!titles.length) ? "1" : "2",
        borrowerShare,
        birthDate,
        creditOrganizationName,
        annuityPay,
        organizationName,
        address,
        isOfficeJob,
        position,
        field,
        jobPlace,
        creditTerms,
        creditRate,
        creditIssueDate: getKiasDate(creditIssueDate),
        firstPayDate: getKiasDate(firstPayDate),
        salesChannel,
        coBorrower: !!coBorrowers.length,
        peoples: borrowers.map(borrower => {
            const {SubClassISN, SubjISN, SubjFullName, SubjBirthDay, AGROBJECT_ADDATTR} = borrower || {};
            const {row} = AGROBJECT_ADDATTR || {};
            const remark = ((row || []).find(({ATTRISN}) => ATTRISN === REMARK_ISN) || {}).REMARK;
            const remarkValue = Array.isArray(remark) ? undefined : remark;
            const fillingType: string | undefined = remarkValue ? "online" : undefined;
            return {
                fillingType,
                scoringCheck: getValueFromObjectsAttributes(borrower, REMARK_ISN),
                isnAnketa: getValueFromObjectsAttributes(borrower, ANKETA_ISN),
                type: SubClassISN === BORROWER_SUB_CLASS_ISN ? "contragent" : "coBorrower",
                fullName: SubjFullName,
                phoneMobile: "",
                birthDate: getKiasDate(SubjBirthDay),
                isn: SubjISN,
                share: getValueFromObjectsAttributes(borrower, SHARE_ISN),
                heightWeight: getValueFromObjectsAttributes(borrower, HEIGHT_WEIGHT_ISN),
                pressure: getValueFromObjectsAttributes(borrower, PRESSURE_ISN),
                scoringResult: remarkValue
            }
        }),
        propertyInsuranceSubjects: properties.map((property, index) => {
            const {SubClassISN, AGROBJADDR} = property || {};
            const agreementPropertyData = {
                pledge: SubClassISN,
                area: getValueFromObjectsAttributes(property, SQUARE_ISN),
                pledgeAddress: {unrestricted_value: AGROBJADDR?.row?.ADDRESS},
                pledgeAddressEgrn: getValueFromObjectsAttributes(property, PROPERTY_ADDRESS_EGRN),
                pledgeShare: getValueFromObjectsAttributes(property, SHARE_ISN),
                yearOfBuild: getValueFromObjectsAttributes(property, PROPERTY_YEAR_OF_BUILD_ISN),
                subjectCost: getValueFromObjectsAttributes(property, PROPERTY_COST_ISN)
            };
            const anketaPropertyData = ANKETA_ATTRIBUTES.step5.reduce((prev, {name}) => {
                return {...prev, [name]: allAttributes[name]};
            }, {})
            if (index === 0) return {...anketaPropertyData, ...agreementPropertyData};
            return agreementPropertyData;
        }),
        lossInsuranceSubjects: titles.map(title => {
            const {AGROBJADDR} = title || {};
            return {
                titleAddress: {unrestricted_value: AGROBJADDR?.row?.ADDRESS},
                titleShare: getValueFromObjectsAttributes(title, SHARE_ISN),
            };
        }),
    };

    return {
        ...resultFormState,
        templateVariables: {
            ...getVariablesForTemplate(resultFormState),
            isn: getValueFromObjectsAttributes(contragent, ANKETA_ISN)
        }
    };
}