import styled from "styled-components";

export const StyledFilesEventsButtons = styled.div`
  display: flex;
  align-items: center;
  > .linkButton:first-child {
    margin-right: 2rem;
  }
  
  @media screen and (max-width: 767px) {
    > .linkButton:first-child {
      margin-bottom: 1.5rem;
      margin-right: 0;
    }
    align-items: flex-start;
    flex-direction: column;
  }
`;