import React                                      from "react";
import {StyledAddressButton, StyledSubjectHeader} from "./styles";
import {Heading3}                                 from "../../../../../../globalStyledComponents";
import Button                  from "../../../../../../uiKit/button/button";
import {TrashIcon}                                   from "../../../../../../icons/ui/Trash";
import FieldsBuilder                                 from "../../../../../../uiKit/fieldsBuilder";
import {AddIcon}                                     from "../../../../../../icons/ui/Add";
import {IFormField}                                  from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {radioBoolean}                                from "../../../../staticData";
import {IRadioItem}                                  from "../../../../../../uiKit/radio";
import PropertyInsuranceRisks                        from "../risks";
import {useGetDict}                                  from "../../../../../../hooks/useGetDicti";
import StoreAgreementForm                            from "../../../../store";
import {getRadioDefaultValue, getSelectDefaultValue} from "../../../../../../formUtils/getFormValue";
import {ISelectItem}                                 from "../../../../../../uiKit/select";
import moment                                        from "moment";

interface ISubjectProps {
  number: number
  index: number
  deleteHandler: () => void
  subjectsLength: number
}

const floorRequired: string[] = ["654391", "1867861", "833631"];
const floorCountHidden: string[] = ["654411"];
const roomsRequired: string[] = ["654391", "1867861"];

// 0: {value: "654391", label: "Квартира"}
// 1: {value: "1867861", label: "Аппартаменты"}
// 2: {value: "1868531", label: "Таунхаус"}
// 3: {value: "654401", label: "Жилой дом"}
// 4: {value: "654411", label: "Земельный участок"}
// 5: {value: "833631", label: "Нежилое помещение"}
// 6: {value: "654421", label: "Машиноместо"}

const Subject = ({number, index, deleteHandler, subjectsLength}: ISubjectProps) => {
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const {data: selectPledge, loading: loadingPledge} = useGetDict("pledgeType");
  const {data: selectWallMaterial, loading: loadingWallMaterial} = useGetDict("wallMaterial");
  const [statePledgeType, setPledgeType] = React.useState<ISelectItem>(selectPledge[0]);
  const [stateRepair, setRepair] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateRedevelopment, setRedevelopment] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateAddressEgrn, setAddressEgrn] = React.useState<boolean>(false);
  const {pledge, yearOfBuild, pledgeAddress, cadastralNumber, pledgeShare, pledgeAddressEgrn, agency, subjectCost, nameOfAppraiser, subjectCostByContract, floorMaterial, interiorDecoration, replanPlanning, replanInterval, rent, damage, area,  roomsCount,  objectOfUnfinishedConstruction, repairPlanning, repairInterval, riskCoefficient, basementOrAttic, floor, floorCount, yearOfCapitalRepair, wallMaterial} = ((agreementFormState||{}).propertyInsuranceSubjects||[])[number] || {};
  const {objectType, objectTypeBuildYear} = agreementFormState||{};

  const filteredSelectPledge = (selectPledge||[]).filter(({value}) => value === "654391")

  React.useEffect(() => {
    if (!replanPlanning) return;
    setRedevelopment(getRadioDefaultValue(replanPlanning, radioBoolean) || radioBoolean[1])
    // eslint-disable-next-line
  }, [replanPlanning])

  React.useEffect(() => {
    if (!repairPlanning) return;
    setRepair(getRadioDefaultValue(repairPlanning, radioBoolean) || radioBoolean[1])
    // eslint-disable-next-line
  }, [repairPlanning])

  const formAddress: IFormField[] = [
    {
      fieldItems: {
        type: "select",
        name: `pledge-${number}`,
        returnFullValue: true,
        placeholder: "Предмет залога",
        isRequired: true,
        errorMessage: "Выберите предмет залога",
        onChange: value => setPledgeType(value),
        isLoading: loadingPledge,
        defaultValue: getSelectDefaultValue(number === 0 ? objectType || pledge : pledge, filteredSelectPledge) || filteredSelectPledge[0],
        items: filteredSelectPledge
      }
    },
    {
      isHidden: statePledgeType?.value === "654391",
      fieldItems: {
        type: "address",
        name: `pledgeAddress-${number}`,
        placeholder: "Адрес приобретаемого / закладываемого объекта залога",
        isRequired: true,
        defaultValue: pledgeAddress?.unrestricted_value,
        errorMessage: "Введите адрес объекта залога"
      }
    },
    {
      isHidden: statePledgeType?.value !== "654391",
      fieldItems: {
        type: "address",
        name: `pledgeAddress-${number}`,
        placeholder: "Адрес приобретаемого / закладываемого объекта залога",
        isRequired: true,
        validationType: "addressWithFlat",
        defaultValue: pledgeAddress?.unrestricted_value,
        errorMessage: "Введите адрес объекта залога"
      }
    },
    {
      fieldItems: {
        type: "input",
        name: `cadastralNumber-${number}`,
        validationType: "cadastralNumber",
        placeholder: "Кадастровый номер",
        defaultValue: cadastralNumber
      }
    },
    {
      fieldMargin: 0.5,
      isHidden: !stateAddressEgrn,
      fieldItems: {
        type: "input",
        name: `pledgeAddressEgrn-${number}`,
        placeholder: "Ввод адреса из ЕГРН",
        defaultValue: pledgeAddressEgrn,
      }
    }
  ];

  const formPartOne: IFormField[] = [
    {
      fieldItems: {
        type: "range",
        name: `pledgeShare-${number}`,
        placeholder: "Доля приобретаемого / закладываемого объекта залога",
        isRequired: true,
        startValue: 0,
        endValue: 100,
        defaultValue: index === 0 && !pledgeShare ? 100 : pledgeShare,
        errorMessage: "Выберите долю",
      }
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "number",
          name: `subjectCost-${number}`,
          digitsAfterDot: 2,
          defaultValue: subjectCost,
          placeholder: "Рыночная стоимость объекта залога (согласно отчету об оценке)",
          isRequired: true,
          errorMessage: "Введите стоимость",
          validationType: "minMax",
          maxValue: 1000000000
        },
        {
          type: "number",
          name: `subjectCostByContract-${number}`,
          defaultValue: subjectCostByContract,
          digitsAfterDot: 2,
          placeholder: "Стоимость объекта залога по договору купли-продажи"
        },
      ]
    },
    {
      isHidden: index !== 0,
      fieldItems: {
        type: "input",
        name: `agency-${number}`,
        defaultValue: agency,
        placeholder: "Агентство недвижимости, которое готовит сделку купли-продажи, ФИО и телефон агента"
      }
    },
    {
      isHidden: index !== 0,
      fieldItems: {
        type: "input",
        defaultValue: nameOfAppraiser,
        name: `nameOfAppraiser-${number}`,
        placeholder: "Наименование назависимого оценщика"
      }
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "number",
          name: `area-${number}`,
          defaultValue: area,
          digitsAfterDot: 2,
          placeholder: "Общая площадь, м2",
          isRequired: true,
          validationType: "minMax",
          maxValue: 1000,
          errorMessage: "Введите площадь"
        },
        {
          isHidden: !roomsRequired.includes(String(statePledgeType?.value)),
          type: "number",
          name: `roomsCount-${number}`,
          placeholder: "Количество комнат",
          isRequired: true,
          validationType: "minMax",
          minValue: 1,
          defaultValue: roomsCount
        },
      ]
    },
  ];
  const formPartTwo: IFormField[] = [
    {
      isHidden: index !== 0,
      label: "Объект незавершенного строительства",
      fieldItems: {
        type: "radio",
        name: `objectOfUnfinishedConstruction-${number}`,
        items: radioBoolean,
        defaultValue: getRadioDefaultValue(objectOfUnfinishedConstruction, radioBoolean) || radioBoolean[1]
      }
    },
    {
      isHidden: index !== 0,
      label: "Планируете ли Вы проведение ремонтных работ, связанных с изменениями в конструктивных элементах недвижимого имущества (перенос стен, создание перегородок и проемов в стенах, замена труб)? ",
      fieldItems: {
        type: "radio",
        name: `repairPlanning-${number}`,
        items: radioBoolean,
        onChange: (value) => setRepair(value),
        defaultValue: stateRepair
      }
    },
    {
      isHidden: stateRepair.value === false || index !== 0, columnGap: 4, columns: 2, fieldItems: [
        {
          type: "dateInterval",
          name: `repairInterval-${number}`,
          defaultValue: repairInterval,
          placeholder: "Сроки ремонта",
        },
        {
          type: "number",
          name: `riskCoefficient-${number}`,
          defaultValue: riskCoefficient,
          placeholder: "Коэффициент за повышенный риск объекта страхования",
        },
      ]
    },
    {
      isHidden: index !== 0,
      label: "Подвальное или чердачное помещение?",
      fieldItems: {type: "radio", name: `basementOrAttic-${number}`, items: radioBoolean, defaultValue: getRadioDefaultValue(basementOrAttic, radioBoolean) || radioBoolean[1]}
    },
    {
      isHidden: index !== 0,
      columnGap: 4, columns: 2, fieldItems: [
        {
          isHidden: !floorRequired.includes(String(statePledgeType?.value)),
          type: "number",
          name: `floor-${number}`,
          defaultValue: floor,
          isRequired: true,
          errorMessage: "Введите этаж",
          validationType: "minMax",
          minValue: 1,
          placeholder: "Этаж",
        },
        {
          isHidden: floorCountHidden.includes(String(statePledgeType?.value)),
          type: "number",
          isRequired: true,
          errorMessage: "Введите этажность строения",
          name: `floorCount-${number}`,
          defaultValue: floorCount,
          placeholder: "Этажность строения",
        },
      ]
    },
    {
      columnGap: 4, columns: 2, fieldItems: [
        {
          type: "input",
          name: `yearOfBuild-${number}`,
          placeholder: "Год постройки / год приобретения",
          mask: "9999",
          validationType: "minMax",
          // minValue: 1960,
          maxValue: Number(moment().format("YYYY")) + 1,
          defaultValue: number === 0 ? objectTypeBuildYear || yearOfBuild : yearOfBuild,
          isRequired: true,
          errorMessage: "Введите год"
        },
        {
          isHidden: index !== 0,
          type: "input",
          name: `yearOfCapitalRepair-${number}`,
          placeholder: "Год капитального ремонта",
          defaultValue: yearOfCapitalRepair,
          mask: "9999",
        },
      ]
    },
    {
      isHidden: index !== 0 || floorCountHidden.includes(String(statePledgeType?.value)),
      columnGap: 4, columns: 2,
      fieldItems: [{
        type: "select",
        name: `wallMaterial-${number}`,
        returnFullValue: true,
        isRequired: true,
        items: selectWallMaterial,
        defaultValue: getSelectDefaultValue(wallMaterial, selectWallMaterial),
        isLoading: loadingWallMaterial,
        placeholder: "Материал стен",
      },
        {
          type: "select",
          name: `floorMaterial-${number}`,
          returnFullValue: true,
          isRequired: true,
          items: selectWallMaterial,
          isLoading: loadingWallMaterial,
          defaultValue: getSelectDefaultValue(floorMaterial, selectWallMaterial),
          placeholder: "Материал межэтажных перекрытий",
        }]
    },

    {
      isHidden: index !== 0,
      label: "Внутрення отделка и инженерное оборудование",
      fieldItems: {
        type: "radio",
        name: `interiorDecoration-${number}`,
        defaultValue: getRadioDefaultValue(interiorDecoration, radioBoolean) || radioBoolean[1],
        items: radioBoolean
      }
    },
    {
      isHidden: index !== 0,
      label: "Вы планируете или проводите перепланировку / переоборудование? Укажите сроки перепланировки / переоборудования",
      fieldItems: {
        type: "radio",
        name: `replanPlanning-${number}`,
        items: radioBoolean,
        onChange: (value) => setRedevelopment(value),
        defaultValue: stateRedevelopment
      }
    },
    {
      isHidden: stateRedevelopment.value === false || index !== 0,
      fieldItems: {
        type: "date",
        name: `replanInterval-${number}`,
        placeholder: "Сроки перепланировки / переоборудования",
        defaultValue: replanInterval
      }
    },
    {
      isHidden: index !== 0,
      label: "Будет ли недвижимое имущество сдаваться в аренду третьим лицам?",
      fieldItems: {type: "radio", name: `rent-${number}`, items: radioBoolean, defaultValue: getRadioDefaultValue(rent, radioBoolean) || radioBoolean[1],}
    },
    {
      isHidden: index !== 0,
      label: "Известны ли Вам физические повреждения объекта залога за последние 2 года, сумма ущерба которых превышала 200 000 ₽? ",
      fieldItems: {type: "radio", name: `damage-${number}`, items: radioBoolean, defaultValue: getRadioDefaultValue(damage, radioBoolean) || radioBoolean[1],}
    },
  ];

  return (
    <>
      {subjectsLength > 1 &&
      <StyledSubjectHeader>
          <Heading3>Объект страхования {index + 1}</Heading3>
          <Button appearance="icon" onClick={() => deleteHandler()} icon={<TrashIcon/>}/>
      </StyledSubjectHeader>}
      <FieldsBuilder formFields={formAddress}/>
      <StyledAddressButton>
        <Button appearance="link" onClick={ev => {
        ev.preventDefault();
        setAddressEgrn(!stateAddressEgrn)
      }}>Ввести адрес как в ЕГРН</Button>
      </StyledAddressButton>
      <FieldsBuilder formFields={formPartOne}/>
      {index === 0 && <PropertyInsuranceRisks number={number}/>}
      <FieldsBuilder formFields={formPartTwo}/>
    </>
  )
}

export const InsuranceSubjects = () => {
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const [stateSubjectList, setSubjectList] = React.useState<number[]>([0]);
  const [stateDeleteSubjectList, setDeleteSubjectList] = React.useState<number[]>([]);
  const {propertyInsuranceSubjects} = agreementFormState || {};

  React.useEffect(() => {
    if (!propertyInsuranceSubjects || propertyInsuranceSubjects?.length === stateSubjectList.length) return;
    setSubjectList(Array.from({length: propertyInsuranceSubjects.length}).map((coBorrower, index) => index))
    // eslint-disable-next-line
  }, [propertyInsuranceSubjects?.length])

  const addSubjectHandler = (ev) => {
    ev.preventDefault();
    const nextSubject = stateSubjectList.length + stateDeleteSubjectList.length;
    setSubjectList([...stateSubjectList, nextSubject]);
  };

  const deleteSubjectHandler = (index: number) => {
    setDeleteSubjectList([...stateDeleteSubjectList, index])
  };

  const filterSubject = stateSubjectList.filter((subject) => !stateDeleteSubjectList.includes(subject));

  return (
    <>
      {filterSubject.map((number, index) => (
        <Subject key={`subject-${number}`}
                 number={number} index={index} subjectsLength={filterSubject.length}
                 deleteHandler={() => deleteSubjectHandler(number)}/>
      ))}
      <Button appearance="link" iconBefore={<AddIcon/>} onClick={(ev) => addSubjectHandler(ev)}>Добавить объект
        страхования</Button>
    </>
  )
};

export default InsuranceSubjects;

export const insuranceSubjectNames: string[] = [
  "pledge",
  "pledgeAddress",
  "pledgeAddressEgrn",
  "pledgeShare",
    "cadastralNumber",
  "agency",
  "subjectCost",
  "nameOfAppraiser",
  "subjectCostByContract",
  "area",
  "roomsCount",
  "objectOfUnfinishedConstruction",
  "repairPlanning",
  "repairInterval",
  "riskCoefficient",
  "basementOrAttic",
  "warehouse",
  "floor",
  "floorCount",
  "yearOfBuild",
  "yearOfCapitalRepair",
  "wallMaterial",
  "floorMaterial",
  "interiorDecoration",
  "replanPlanning",
  "replanInterval",
  "rent",
  "damage",

  "signaling",
  "signalingInput",

  "openFire",
  "openFireInput",

  // "flammable",
  // "flammableInput",

  "additionalRisks",
  "additionalRisksInput"
];