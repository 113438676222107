import React                                                         from 'react';
import {StyledUnderwritingHeaderFields, StyledUnderwritingRisksForm} from "./styles";
import {Heading3, TextButton}                                        from "../../../../globalStyledComponents";
import Button                                                        from "../../../../uiKit/button/button";
import Status                                                        from "../../../../uiKit/status";
import {IFormField}                                                  from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                                                 from "../../../../uiKit/fieldsBuilder";
import {
  StyledEventsButtons,
  StyledHeaderEvents,
  StyledHeaderInformation,
  StyledHeaderWrapper,
  StyledInformationItem, StyledSumm, StyledSummsWrapper
}                                                                    from "../../../applicationById/components/header/styles";
import ChangeLogModal
                                                                     from "../../../applicationById/components/header/ChangeLogModal";
import DropDown                                                      from "../../../../uiKit/dropDownArea";
import {SelectArrowIcon}                                             from "../../../../icons/ui/SelectArrow";
import {IRadioItem}                                                  from "../../../../uiKit/radio";
import FlyButtons                                                    from "../../../../components/flyButtons";
import {useWindowWidth}                                              from "@react-hook/window-size";
import {useQuery}                                                    from "@apollo/react-hooks";
import {BANKS_RAISING, DOCS, GET_AGREEMENT, GET_KV_AGENT, ME} from "../../../../utils/queries";
import {useParams}                                                   from "react-router";
import {
  BORROWER_SUB_CLASS_ISN,
  CLASS_ISN_BORROWERS,
  CLASS_ISN_OBJECTS,
  CLASS_ISN_TITLES, getBankRaising, getKiasDate,
  getRestrictionsObject,
  getRiskForObject,
  getValueByAttributeId, KV_DISCOUNT_ISN, PROGRAM_ISN,
  PROPERTY_YEAR_OF_BUILD_ISN, TARIFF_GROUP_ISN

} from "../../../../helpers/buildTemplateForBack";
import {ISelectItem}                                                 from "../../../../uiKit/select";
import {rolesRightHandler}                                           from "../../../../helpers/rolesRightHandler";
import {ErrorMessage}                                                from "../../../../components/errorMessage";
import {getSummForPay}   from "../../../../helpers/templateToFormRebuild";
import {
  StyledImportantNumber,
  StyledSignetAgreementButtons
}                        from "../../../contractById/components/header/styles";
import LoadingString     from "../../../../uiKit/loadingString";
import moment                                  from "moment";
import {getSelectDefaultValue, stringToNumber} from "../../../../formUtils/getFormValue";
import {useHistory}                            from "react-router-dom";
import {money}           from "../../../../utils/formats";
import {formatDateToUsa} from "../../../../formUtils/fieldsValidation";
import {TimeReloadIcon}  from "../../../../icons/ui/TimeReload";
import {MoreIcon}        from "../../../../icons/ui/More";
import {useGetDict}      from "../../../../hooks/useGetDicti";

const radioUnderwriting: IRadioItem[] = [
  {label: "Согласовать", value: "approved"},
  {label: "Не согласовывать", value: "deny"},
  {label: "На согласовании", value: "underwriting"},
]

export const UnderwritingHeader = ({
                                     isLoading,
                                     stateDropDownIsOpen,
                                     setDropDownIsOpen,
                                     setSubmitType
                                   }: { isLoading?: boolean, stateDropDownIsOpen: boolean, setDropDownIsOpen: any, setSubmitType: any }) => {
  const {id} = useParams();
  const history = useHistory();
  const windowWidth = useWindowWidth({wait: 250});
  const {data: selectTariffGroup, loading: loadingTariffGroup} = useGetDict("tariffGroup");
  const {data: selectProgram, loading: loadingProgram} = useGetDict("program");
  const [stateChangeLogModalIsOpen, setChangeLogModalIsOpen] = React.useState<boolean>(false)
  const [stateMobileDropDownIsOpen, setMobileDropDownIsOpen] = React.useState<boolean>(false)

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {data: dataAgreement, loading: loadingAgreement, error: errorAgreement} = useQuery(GET_AGREEMENT, {
    variables: {
      isn: id
    }
  });
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {
      isn: id
    }
  });
  const {data: dataGetKv} = useQuery(GET_KV_AGENT, {variables: {isn: id}});

  const {isAdmin, type, role, restrictions} = (dataUser || {}).me || {};
  const {
    autoscoring,
    autoscoringLife,
    autoscoringProperty,
    autoscoringTitle,
    objectType,
    yearOfBuild: maxYearOfBuild,
    summLife: maxSummLife,
    summProperty: maxSummProperty,
    ageLimit: maxAge
  } = getRestrictionsObject(restrictions);
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {clientInfo, attribs, roles, objects, curCode} = (dataAgreement || {}).agreementCalc || {};
  const {FULLNAME: fullName, BIRTHDAY} = clientInfo || {};
  const birthDate: string = (BIRTHDAY || "").split(" ")[0]
  const beneficary = (roles || []).find(({ClassISN}) => ClassISN === "2081") || {};
  const {SUBJFULLNAME, SubjISN} = beneficary;
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {risks, added, user: createdAt, statusCode, number, data} = (docsList || [])[0] || {};
  const {agreementIsn} = data || {}
  const discount = stringToNumber(attribs?.find(({isn}) => isn === KV_DISCOUNT_ISN)?.val);

  const isRiskLife: boolean = !!(objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS).length
  const tariffGroup = attribs?.find(({isn}) => isn === TARIFF_GROUP_ISN)?.val;
  const program = attribs?.find(({isn}) => isn === PROGRAM_ISN)?.val;

  const sumAgreementWithDiscount: number = Number(getSummForPay(objects, curCode, true))
  const sumWithoutDiscount: number = (sumAgreementWithDiscount * 100) / (100 - discount)
  const {Komission_Bank, Komission_Proc, Komission_Sum, PartnerName} = dataGetKv?.getKvAgent || {}
  const isCanceled: boolean = ["annul", "clientRefusal"].includes(statusCode);

  const {data: dataBanksRising, error: errorBanksRising} = useQuery(BANKS_RAISING);
  const bankIsn = attribs?.find(({isn}) => isn === "831791")?.val;

  const bankRate = stringToNumber(attribs?.find(({isn}) => isn === "831771")?.val);

  const bankRising = getBankRaising((dataBanksRising?.banks||[]).find(bank => bank?.isn === bankIsn)?.raising, bankRate);

  const headerButtons: { label: string, event: () => void, icon: any, isLoading?: boolean, isDisabled?: boolean }[] = [
    {label: "История изменений", icon: <TimeReloadIcon/>, event: () => setChangeLogModalIsOpen(true)}
  ]

  const selectBeneficiary: ISelectItem[] = [
    {label: "АО БАНК ДОМ.РФ", value: "444271551"},
    {label: "ТКБ БАНК ПАО", value: "442490587"},
    {label: SUBJFULLNAME, value: SubjISN}
  ]

  const informationList = [
    {title: "Номер заявки", description: <LoadingString isLoading={loadingDocs}>{number}</LoadingString>},
    {
      title: "Статус",
      description: <LoadingString isLoading={loadingDocs}><Status status={statusCode}/></LoadingString>
    },
    {
      title: "Создана",
      description: <LoadingString
        isLoading={loadingDocs}>{`${(createdAt || "").name || "Не известный"}
        ${added}
        `}</LoadingString>
    },
    {
      title: "Точка продаж (группа)",
      description: <LoadingString
        isLoading={loadingDocs}>{`${createdAt?.type === "admin" ? "Администратор" : `${createdAt?.group?.name || "неизвестно"}`}`}</LoadingString>
    },
    // {
    //   title: "Последнее изменение",
    //   description: <LoadingString
    //     isLoading={loadingDocs}>{`${(editor || "").name || "Не известный"} ${edited || "неизвестно когда"}`}</LoadingString>
    // },
  ]

  const agreementSumms = <StyledSummsWrapper>
    <StyledSumm>
      <LoadingString
        width={20}
        isLoading={loadingAgreement}>
        <TextButton>Итого</TextButton>
        <StyledImportantNumber>
          <TextButton>{money(sumWithoutDiscount)} {curCode || "RUB"}</TextButton>
        </StyledImportantNumber>
      </LoadingString>
    </StyledSumm>
    <StyledSumm>
      <LoadingString
        width={20}
        isLoading={loadingAgreement}>
        <TextButton>Со скидкой КВ ({discount}%)</TextButton>
        <StyledImportantNumber>
          <TextButton>{money(sumAgreementWithDiscount)} {curCode || "RUB"}</TextButton>
        </StyledImportantNumber>
      </LoadingString>
    </StyledSumm>
  </StyledSummsWrapper>

  const form: IFormField[] = [
    {
      columns: 4, columnGap: 2, fieldMargin: 2, fieldItems: [
        {
          type: "fio",
          name: "name",
          placeholder: "ФИО Заемщика",
          validationType: "fullName",
          defaultValue: {value: fullName},
          isDisabled: true,
          isRequired: true,
          errorMessage: "Введите ФИО"
        },
        {
          type: "date",
          name: "age",
          defaultValue: birthDate,
          isDisabled: true,
          placeholder: "Дата рождения",
          isRequired: true,
          errorMessage: "Выберите дату рождения"
        },
        {
          type: "select",
          name: "currentBeneficiary",
          placeholder: "Выгодоприобретатель",
          isRequired: true,
          defaultValue: {label: SUBJFULLNAME, value: SubjISN},
          items: selectBeneficiary,
          isDisabled: !!agreementIsn || isCanceled,
          errorMessage: "Введите выгодоприобретателя"
        },
        {
          type: "number",
          name: "creditTerms",
          isDisabled: true,
          defaultValue: getValueByAttributeId(attribs, "creditTerms"),
          placeholder: "Срок кредитования, месяцы",
          isRequired: true,
          validationType: "minMax",
          maxValue: 600,
          errorMessage: "Введите срок"
        },
      ]
    },
    {
     isHidden: !PartnerName, columns: 4, columnGap: 2, fieldMargin: !isRiskLife ? 0 : 2, fieldItems: [
        {
          type: "fio",
          name: "partnerName",
          placeholder: "Партнер",
          defaultValue: {value: PartnerName},
          isDisabled: true
        },
        {
          type: "number",
          name: "kvProc",
          defaultValue: Komission_Proc,
          isDisabled: true,
          placeholder: "КВ %",
          digitsAfterDot: 2
        },
        {
          type: "number",
          name: "kvSum",
          isDisabled: true,
          placeholder: "КВ руб",
          defaultValue: Komission_Sum,
          digitsAfterDot: 2
        },
        {
          type: "number",
          name: "kvBank",
          isDisabled: true,
          defaultValue: Komission_Bank,
          placeholder: "КВ банка",
          digitsAfterDot: 2
        },
      ]
    },
    {
      isHidden: !isRiskLife,
      columns: 2,
      columnGap: 2,
      fieldMargin: 0,
      fieldItems: [
        {
          type: "select",
          name: "tariffGroup",
          returnFullValue: true,
          isDisabled: !!agreementIsn || isCanceled,
          placeholder: "Тарифная группа",
          errorMessage: "Выберите тарифную группу",
          isLoading: loadingTariffGroup,
          items: selectTariffGroup,
          isRequired: true,
          defaultValue: getSelectDefaultValue(tariffGroup, selectTariffGroup),
          isSearchAvailable: true,
          isClearable: true
        },
        {
          type: "select",
          name: "program",
          returnFullValue: true,
          isDisabled: !!agreementIsn || isCanceled,
          placeholder: "Программа",
          errorMessage: "Выберите программу",
          isLoading: loadingProgram,
          items: selectProgram,
          isRequired: true,
          defaultValue: getSelectDefaultValue(program, selectProgram),
          isSearchAvailable: true,
          isClearable: true
        }
      ]
    }
  ];

  const getRightType = (risk) => {
    switch (risk) {
      case CLASS_ISN_OBJECTS:
        return "underwritingRiskProperty"
      case CLASS_ISN_BORROWERS:
        return "underwritingRiskLife"
      case CLASS_ISN_TITLES:
        return "underwritingRiskTitul"
      default:
        return undefined;
    }
  }

  const risksForm: IFormField[] = (objects || []).map((object) => {
    const {ISN, ClassISN, SubClassName, AGRCOND: agrCondObject,} = object || {};
    const {risk, object: isnObject, status} = (risks || []).find(({object}) => ISN === object) || {};
    const summWithoutRaising: number = stringToNumber(getValueByAttributeId(attribs, "summ"));
    const summ: number = (summWithoutRaising / 100) * bankRising + summWithoutRaising;
    const birthday: string = String(getKiasDate((((objects.find(({SubClassISN}) => SubClassISN === BORROWER_SUB_CLASS_ISN) || {}).SUBJINFO || {}).BIRTHDAY || "").split(" ")[0]))
    const age: number = moment().year() -  moment(formatDateToUsa(birthday)).year();
    const isOkRole: boolean = rolesRightHandler(getRightType(risk), functions, type, isAdmin);
    let isHidden: boolean = false;
    switch (ClassISN) {
      case CLASS_ISN_BORROWERS:
        const {row: rowAgrCond} = agrCondObject || {};
        const {
          LimitSum,
        } = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} : rowAgrCond || {};
        const lifeSum = LimitSum ? stringToNumber(LimitSum) : summ;
        const isOkLife: boolean = autoscoring ? isOkRole && autoscoringLife && lifeSum <= Number(maxSummLife) && age <= Number(maxAge) : false;
        isHidden = !isOkLife;
        break;
      case CLASS_ISN_OBJECTS:
        const pledgeType = object.SubClassISN;
        const yearOfBuild = Number(((((object || {}).AGROBJECT_ADDATTR || {}).row || []).find(({ATTRISN}) => ATTRISN === PROPERTY_YEAR_OF_BUILD_ISN) || {}).VAL || 0);
        const isOkProperty: boolean = autoscoring ? isOkRole && autoscoringProperty && summ <= Number(maxSummProperty) && yearOfBuild >= Number(maxYearOfBuild) && objectType.includes(pledgeType) : false;
        isHidden = !isOkProperty;
        break;
      case CLASS_ISN_TITLES:
        isHidden = autoscoring ? !autoscoringTitle : true;
        break;
    }

    return {
      label: `Риск ${getRiskForObject(risk)} (${SubClassName})`,
      isHidden,
      fieldItems: {
        name: `risk-${isnObject}`,
        type: "radio",
        items: radioUnderwriting,
        defaultValue: radioUnderwriting.find(({value}) => value === status) || radioUnderwriting[0]
      }
    }
  })

  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>

  return (
    <StyledHeaderWrapper>
      <StyledHeaderEvents>
        <Heading3>Общие сведения</Heading3>
        <StyledEventsButtons>
          {windowWidth < 768 ?
            <DropDown
              trigger={
                <Button
                  icon={<MoreIcon/>}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setMobileDropDownIsOpen(true)
                  }}
                  appearance="flag">
                </Button>
              }
              isDropDownList={true}
              items={headerButtons.map(({label, event}) => ({label, event}))}
              isOpen={stateMobileDropDownIsOpen}
              onClose={() => setMobileDropDownIsOpen(false)}/>
            :
            <>
              {headerButtons.map(({label, event, icon, isLoading, isDisabled}, index) => (
                <Button
                  key={`headerButton-${index}`}
                  tooltip={label}
                  appearance="icon"
                  type="button"
                  icon={icon}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  onClick={event}/>
              ))}
            </>
          }
          <FlyButtons isWhiteBackground={true}>
            {!!agreementIsn && <Button
                isLoading={loadingAgreement || loadingDocs}
                isDisabled={!rolesRightHandler("agreementsLogPage", functions, type, isAdmin) || isCanceled}
                onClick={() => history.push(`/contract/${agreementIsn}`)}
                width={windowWidth < 768 ? "100%" : undefined}
                appearance="transparent"
            >
                Перейти к договору
            </Button>}
            {!agreementIsn && <StyledSignetAgreementButtons>
                <Button
                    isDisabled={!!agreementIsn || isCanceled}
                    isLoading={isLoading || loadingAgreement || loadingDocs}
                    onClick={() => setSubmitType(undefined)}
                    appearance="transparent"
                    form="underwritingForm"
                >
                    Пересчитать
                </Button>
                <DropDown
                    trigger={
                      <Button
                        iconAfter={<SelectArrowIcon direction={stateDropDownIsOpen ? "top" : "down"}/>}
                        onClick={(ev) => {
                          ev.preventDefault();
                          setDropDownIsOpen(true)
                        }}
                        isLoading={isLoading || loadingAgreement || loadingDocs}
                        width={windowWidth < 768 ? "100%" : undefined}
                        isDisabled={!rolesRightHandler("approveRisks", functions, type, isAdmin) || risksForm.filter(({isHidden}) => !isHidden).length === 0 || !!agreementIsn || isCanceled}
                        appearance="filled">
                        Согласовать
                      </Button>
                    }
                    maxBodyWidth={38}
                    isOpen={stateDropDownIsOpen}
                    onClose={() => setDropDownIsOpen(false)}>
                    <StyledUnderwritingRisksForm>
                        <FieldsBuilder formFields={risksForm}/>
                        <Button
                            isLoading={isLoading}
                            onClick={() => setSubmitType("risks")}
                            form="underwritingForm"
                            width="100%"
                            appearance="filled">
                            Применить
                        </Button>
                    </StyledUnderwritingRisksForm>
                </DropDown>
            </StyledSignetAgreementButtons>}
          </FlyButtons>
        </StyledEventsButtons>
      </StyledHeaderEvents>
      <StyledHeaderInformation>
        {informationList.map(({title, description}, index) => (
          <StyledInformationItem key={`info-item-${index}`}>
            <TextButton>{title}</TextButton>
            <TextButton>{description}</TextButton>
          </StyledInformationItem>
        ))}
        {agreementSumms}
      </StyledHeaderInformation>

      <StyledUnderwritingHeaderFields>
        <FieldsBuilder formFields={form}/>
      </StyledUnderwritingHeaderFields>

      <ChangeLogModal header="История изменений" onClose={() => setChangeLogModalIsOpen(false)}
                      isOpen={stateChangeLogModalIsOpen}/>
    </StyledHeaderWrapper>
  )
};

export default UnderwritingHeader;