import React                                       from "react";
import {Heading5}                      from "../../globalStyledComponents";
import {StyledPoliciesItem, StyledPoliciesWrapper} from "./styles";
import {useHistory}                                from "react-router";
import {AgreementsIcon}                            from "../../icons/ui/Agreements";
import {AnketsIcon}                                from "../../icons/ui/Ankets";

const policiesList = [
  {icon: <AgreementsIcon/>, name: "Мои договора", link: "/myAgreements"},
  {icon: <AnketsIcon/>, name: "Моя анкета", link: "/myAnketa"}
]

export const MyPolicies = () => {
  const history = useHistory();
  return (
    <StyledPoliciesWrapper>
      {policiesList.map(({icon, name, link}, index) => (
        <StyledPoliciesItem key={`policies-item-${index}`} onClick={() => history.push(link)}>
          {icon}
          <Heading5>{name}</Heading5>
        </StyledPoliciesItem>
      ))}
    </StyledPoliciesWrapper>
  )
};

export default MyPolicies;