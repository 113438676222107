import React                                                       from "react";
import {StyledContactPhone, StyledContacts, StyledCuratorContacts} from "./styles";
import {PhoneIcon}                                                 from "../../../../../icons/ui/Phone";
import {Heading5, Text3}                                           from "../../../../../globalStyledComponents";
import {ContactPersonIcon}                                         from "../../../../../icons/ui/ContactPerson";
import {CuratorContactsModal}                                      from "./components/curatorContacts";
import moment                                                      from "moment";
import {useLazyQuery}                                              from "@apollo/react-hooks";
import {ME}                                                        from "../../../../../utils/queries";
import {ErrorMessage}                                              from "../../../../errorMessage";

export const Contacts = ({isAuth}: { isAuth?: boolean }) => {
  const [stateCuratorModal, setCuratorModal] = React.useState<boolean>(false)
  const [getUser, {data: dataUser, error: errorUser}] = useLazyQuery(ME);
  const {group} = (dataUser || {}).me || {};
  const {curator} = group || {};
  const {name, email, phone} = curator || {};
  const isHaveCurator: boolean = !!name && !!email && !!phone;

  React.useEffect(() => {
    if (isAuth) return;
    getUser();
    // eslint-disable-next-line
  }, [isAuth])

  const copyright = isAuth ? <Text3>© 2009–{moment().format("YYYY")} ООО «Абсолют Страхование»</Text3> :
    <Text3>© 2009–{moment().format("YYYY")} ООО <br/> «Абсолют Страхование»</Text3>;
  const data = {
    phone: "+7 (495) 025-77-77",
    copyright
  };

  if (errorUser) return <ErrorMessage error={errorUser}/>
  return (
    <>
      <StyledContacts isAuth={!!isAuth}>
        {!isAuth && isHaveCurator && <StyledCuratorContacts onClick={() => setCuratorModal(true)}>
            <Heading5><ContactPersonIcon/><span>Контакты куратора</span></Heading5>
        </StyledCuratorContacts>}
        <StyledContactPhone href={`tel:${data.phone}`}>
          <Heading5><PhoneIcon/><span>{data.phone}</span></Heading5>
        </StyledContactPhone>
        {data.copyright}
      </StyledContacts>
      <CuratorContactsModal
        data={{
          name,
          email,
          phone
        }}
        isOpen={stateCuratorModal}
        onClose={() => setCuratorModal(false)}/>
    </>
  )
};

export default Contacts;