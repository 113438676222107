import styled from "styled-components";

export const StyledSettingsWrapper = styled.div`
  h2 {
    margin-bottom: 4rem;
  }
    @media screen and (max-width: 767px) {
    h2 {
      margin-bottom: 2rem;
    }    
  }  
`;