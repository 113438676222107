import styled                     from "styled-components";
import {black, blue, grey, white} from "../../globalStyledComponents";

export const StyledDatePickerWrapper = styled.div`
  position: relative;
`;

export const StyledDatePicker = styled.div<{interval?: boolean}>` 
  .rc-calendar-input-wrap {
    display: none;
  }  
  
  .rc-calendar-range-middle {
    display: none;
  }  
  
  .rc-calendar-clear-btn {
    display: none;
  } 
  
  .rc-calendar * {
    font-size: 1.4rem;
    color: black;
    font-family: Verdana;
  }
   
  .rc-calendar-body {
    height: auto;
  }
  
  .rc-calendar a:hover, a:focus {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: ${black};
  }
  
  .rc-calendar-date {
    border-color: #003D81;
    border-radius: 0;
  }
  
  .rc-calendar {
    padding: 1rem 2rem 2rem 2rem;
    border: none;
    border-radius: 0;
    width: auto;
  }
  
  .rc-calendar-range {
    width: auto;
  }
  
  .rc-calendar-today .rc-calendar-date {
    border: none;
  }
  div[aria-selected="true"] {
    color: ${white};
    background: ${blue};
  } 
  
  .rc-calendar-range .rc-calendar-in-range-cell {
    background: ${blue};
  }
  
  .rc-calendar-range .rc-calendar-in-range-cell > div {
    color: ${white};
  }
  thead, tbody, tfoot {
    border: none;
  }
  
  .rc-calendar-table th {
    padding-bottom: 1.5rem;
  }  
  
  .rc-calendar-table th span {
    text-transform: uppercase;
    font-weight: bold;
    color: #B3B3B3;
  }
  
  .rc-calendar-table th {
    width: 3.5rem;
  }
    
  .rc-calendar-my-select a {
    font-weight: normal;
    text-transform: capitalize;
  }
  
  .rc-calendar-header {
    border: none;
    margin-bottom: 1rem;
  }
  
  td[role="gridcell"] {
    padding: 0;
    width: 3.5rem;
    height: 3.5rem;
    border: 1px solid #E1E1E1;
  } 
  
  .rc-calendar-body {
    padding-top: 0;
  }
   
  td[role="gridcell"]:hover {
    cursor: pointer;
    background: ${blue};
  } 
  td[role="gridcell"]:hover > div {
    color: ${white};
    background: ${blue};
  }   
  td[role="gridcell"]:hover > a {
    color: ${white};
  } 
  td[role="gridcell"] > a:hover {
    color: ${white};
    background: ${blue};
  }
 
  .rc-calendar-selected-date .rc-calendar-date {
    background: ${blue};
  }
 
  .rc-calendar-range-part {
    width: auto;
    display: inline-flex;
  }
  
  .rc-calendar-date {
    width: auto;
    height: auto;
  }
   
  .rc-calendar-picker {
        ${({interval}) =>
  interval ?
    `
         min-width: 540px;
          `
    :
    `
    min-width: 300px;
    `
}
       
      top: 6.5rem!important;
      left: 0!important;
  }
    
  .rc-calendar-month-panel-selected-cell, .rc-calendar-selected-day {
    background: ${blue}; 
  }
  
  .rc-calendar-month-panel-month , .rc-calendar-month-panel-current-cell > a {
    background: ${blue};
    color: ${white};
    border-radius: 0;
  }  
  .rc-calendar-month-panel-selected-cell > a {
    background: ${blue};
    color: ${white};
  }  
  .rc-calendar-month-panel-selected-cell:hover {
    background: ${blue};
    color: ${white};
  }  
  
  .rc-calendar-year-panel-selected-cell, .rc-calendar-year-panel-selected-cell > a {
    background: ${blue};
    color: ${white};
  }
  
  .rc-calendar-decade-panel-selected-cell, .rc-calendar-decade-panel-selected-cell > a {
      background: ${blue};
      color: ${white};
  }
  
  .rc-calendar-today {
    position: relative;
    background: ${blue};
  }  
  .rc-calendar-today > div {
    color: ${white};
  }  
  .rc-calendar-today > div::after {
    content: "";
    position: absolute;
    width: 70%;
    left: 15%;
    bottom: 3px;
    border-bottom: 2px solid white;
  }
 

  .rc-calendar-next-month-btn , .rc-calendar-prev-month-btn, .rc-calendar-next-year-btn, .rc-calendar-prev-year-btn {
    font-size: 2.5rem;
    color: #808080;
    top: -2px;
  }  
  
  .rc-calendar-month-select {
    min-width: 5.5rem;
    margin-right: 1rem;
    border: 1px solid ${grey};
  }  
   
   .rc-calendar-month-select:hover {
    border-color: ${blue};
  } 
   
   .rc-calendar-year-select {
       min-width: 5.5rem;
    border: 1px solid ${grey};
  }

   .rc-calendar-year-select:hover {
    border-color: ${blue};
  } 

  .rc-calendar-range .rc-calendar-body {
    border: none;
  }
  .rc-calendar-footer {
    display: none;
  }
    
   .rc-calendar-date-panel {
      ${({interval}) =>
        interval ?
          `
          display: flex;
          `
            :
            ""
        }
    }
    
    
  @media screen and (max-width: 767px) {
  
    .rc-calendar-next-month-btn , .rc-calendar-prev-month-btn, .rc-calendar-next-year-btn, .rc-calendar-prev-year-btn {
      padding-top: 0.5rem;
    }  
    
    .rc-calendar-month-select, .rc-calendar-year-select {
      padding: 0.5rem;
    }  
    
    .rc-calendar * {
      font-size: 1.4rem;
    }
    .rc-calendar-header {
      margin-bottom: 2.5rem;
    }
    
    .rc-calendar-table th {
      width: 2rem;
    }
  
    td[role="gridcell"] {
      width: 3.5rem;
      height: 4.5rem;
    } 
        
    .rc-calendar-next-month-btn , .rc-calendar-prev-month-btn {
      font-size: 1.5rem;
    }  
    
    .rc-calendar-date-panel {
      width: 100%;
      flex-direction: column;
    }
    
    .rc-calendar-range-part > div {
      width: 100%;
    }    
    
    .rc-calendar-decade-panel * {
      font-size: 1.2rem;
    }
    
    .rc-calendar-year-panel a {
      width: unset;
    }
    
    .rc-calendar-month-panel a {
      width: unset;
    }
    
    .rc-calendar-range-part:first-child {
      margin-bottom: 1.5rem;
    }
    
    .rc-calendar-picker {
      width: calc(100% - 5.5rem);
    }
      
    .rc-calendar-picker {
      top: 32rem!important;
    }
    
      .rc-calendar-picker {
        min-width: 100%;
        top: 7rem!important;
      }

      td[role="gridcell"] {
        width: 2rem;
        height: 3.5rem;
      }
    
  }     
`;