import React                   from 'react';
import {
  StyledEventsButtons,
  StyledHeaderEvents,
  StyledHeaderInformation,
  StyledHeaderWrapper,
  StyledInformationItem, StyledSumm, StyledSummsWrapper
}                              from "./styles";
import {
  Heading3,
  TextButton
}                              from "../../../../globalStyledComponents";
import Button
                               from "../../../../uiKit/button/button";
import Status
                               from "../../../../uiKit/status";
import {useHistory, useParams} from "react-router";
import ChangeLogModal          from "./ChangeLogModal";
import DropDown
                               from "../../../../uiKit/dropDownArea";
import FlyButtons
                               from "../../../../components/flyButtons";
import {useWindowWidth}        from "@react-hook/window-size";
import {MoreIcon}              from "../../../../icons/ui/More";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {
  DOCS,
  GET_AGREEMENT,
  ME
}                              from "../../../../utils/queries";
import {
  AGREEMENT_MAKE
}                              from "../../../../utils/mutations";
import {rolesRightHandler}     from "../../../../helpers/rolesRightHandler";
import {ErrorMessage}          from "../../../../components/errorMessage";
import {useCallNotification}   from "../../../../hooks/useCallNotification";
import {getSummForPay}         from "../../../../helpers/templateToFormRebuild";
import {
  StyledImportantNumber,
  StyledSignetAgreementButtons
}                            from "../../../contractById/components/header/styles";
import LoadingString         from "../../../../uiKit/loadingString";
import {
  KV_DISCOUNT_ISN
}                            from "../../../../helpers/buildTemplateForBack";
import ChangeDiscountModal   from "./ChangeDiscountModal";
import {stringToNumber}      from "../../../../formUtils/getFormValue";
import {DiscountIcon}        from "../../../../icons/ui/Discount";
import {money}               from "../../../../utils/formats";
import {TimeReloadIcon}      from "../../../../icons/ui/TimeReload";
import {PersonWithArrowIcon} from "../../../../icons/ui/PersonWithArrow";
import DisapproveModal       from "./DisapproveModal";

export const ApplicationByIdHeader = (props) => {
  const {recalcLoading, showRecalcButton, setDiscountModalIsOpen, setDisapproveModalIsOpen, setSubmitType} = props;
  const {id} = useParams();
  const history = useHistory();
  const {setNotification} = useCallNotification();
  const windowWidth = useWindowWidth({wait: 250});
  const [stateChangeLogModalIsOpen, setChangeLogModalIsOpen] = React.useState<boolean>(false)
  const [stateMobileDropDownIsOpen, setMobileDropDownIsOpen] = React.useState<boolean>(false)

  const {
    data: dataAgreement,
    loading: loadingAgreement,
    error: errorAgreement
  } = useQuery(GET_AGREEMENT, {variables: {isn: id}});
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {
    variables: {
      isn: id
    }
  });

  const [agreementMake, {loading: loadingAgreementMake}] = useMutation(AGREEMENT_MAKE, {refetchQueries: ["docs"]});

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type, role} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const {objects, curCode, attribs} = (dataAgreement || {}).agreementCalc || {};
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {statusCode, risks, number, user: createdAt, data, added} = (docsList || [])[0] || {};
  const {agreementIsn: contractIsn} = data || {};
  const approvedRisks = (risks || []).filter(({status}) => status === "approved");
  const deniedRisks = (risks || []).filter(({status}) => status === "deny");
  const discount = stringToNumber(attribs?.find(({isn}) => isn === KV_DISCOUNT_ISN)?.val);
  const sumAgreementWithDiscount: number = Number(getSummForPay(objects, curCode, true))
  const sumWithoutDiscount: number = (sumAgreementWithDiscount * 100) / (100 - discount)

  const isCanceled: boolean = ["annul", "clientRefusal"].includes(statusCode);

  const contractMake = (isn: string) => {

    setSubmitType("contractMake");

    console.log('contractMake');

    agreementMake({
      variables: {
        isn: isn
      }
    })
      .then(({data: {agreementMake}}) => {
        if (!agreementMake) return setNotification({type: "error", text: "Отсутствует isn договора"});
        history.push(`/contract/${agreementMake}`)
      })
      .catch(error => setNotification({type: "error", text: error}))
  }

  // const contractMakeHandler = () => {
  //   if (!contractIsn) return contractMake(id);
  //   cancelAgreement({
  //     variables: {
  //       isn: contractIsn,
  //     }
  //   })
  //     .then(() => contractMake(isnAgreement))
  //     .catch(error => setNotification({type: "error", text: error}))
  // }

  const headerButtons: { label: string, icon: any, isDisabled?: boolean, isLoading?: boolean, event: () => void }[] = [
    {
      label: `Скидка за счет КВ: ${discount} %`,
      icon: <DiscountIcon/>,
      event: () => setDiscountModalIsOpen(true),
      isDisabled: !rolesRightHandler("setDiscount", functions, type, isAdmin) || isCanceled
    },
    {label: "История изменений", icon: <TimeReloadIcon/>, event: () => setChangeLogModalIsOpen(true)},
    {label: "Согласовать повторно", icon: <PersonWithArrowIcon/>, event: () => setDisapproveModalIsOpen(true)},
  ]
  const informationList = [
    {title: "Номер заявки", description: <LoadingString isLoading={loadingDocs}>{number}</LoadingString>},
    {
      title: "Статус",
      description: <LoadingString isLoading={loadingDocs}><Status status={statusCode}/></LoadingString>
    },
    {
      title: "Создана",
      description: <LoadingString
        isLoading={loadingDocs}>{`${(createdAt || "").name || "Не известный"}
        ${added}
        `}</LoadingString>
    },
    {
      title: "Точка продаж (группа)",
      description: <LoadingString
        isLoading={loadingDocs}>{`${createdAt?.type === "admin" ? "Администратор" : `${createdAt?.group?.name || "неизвестно"}`}`}</LoadingString>
    },
    // {
    //   title: "Последнее изменение",
    //   description: <LoadingString
    //     isLoading={loadingDocs}>{`${(editor || "").name || "Не известный"} ${edited || "неизвестно когда"}`}</LoadingString>
    // },
  ]

  const agreementSumms = <StyledSummsWrapper>
    <StyledSumm>
      <LoadingString
        width={20}
        isLoading={loadingAgreement}>
        <TextButton>Итого</TextButton>
        <StyledImportantNumber>
          <TextButton>{money(sumWithoutDiscount)} {curCode || "RUB"}</TextButton>
        </StyledImportantNumber>
      </LoadingString>
    </StyledSumm>
    <StyledSumm>
      <LoadingString
        width={20}
        isLoading={loadingAgreement}>
        <TextButton>Со скидкой КВ ({discount}%)</TextButton>
        <StyledImportantNumber>
          <TextButton>{money(sumAgreementWithDiscount)} {curCode || "RUB"}</TextButton>
        </StyledImportantNumber>
      </LoadingString>
    </StyledSumm>
  </StyledSummsWrapper>

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  return (
    <StyledHeaderWrapper>
      <StyledHeaderEvents>
        <Heading3>Общие сведения</Heading3>
        <StyledEventsButtons>
          {windowWidth < 768 ?
            <DropDown
              trigger={
                <Button
                  icon={<MoreIcon/>}
                  onClick={() => setMobileDropDownIsOpen(true)}
                  appearance="flag">
                </Button>
              }
              isDropDownList={true}
              items={headerButtons.map(({label, event, isDisabled}) => ({label, event, isDisabled}))}
              isOpen={stateMobileDropDownIsOpen}
              onClose={() => setMobileDropDownIsOpen(false)}/>
            :
            <>
              {headerButtons.map(({label, event, icon, isDisabled, isLoading}, index) => (
                <Button
                  key={`headerButton-${index}`}
                  isLoading={isLoading}
                  tooltip={label}
                  isDisabled={isDisabled}
                  appearance="icon"
                  icon={icon}
                  type="button"
                  onClick={event}/>
              ))}
            </>
          }

          <FlyButtons isWhiteBackground={true}>
            <StyledSignetAgreementButtons>
              {showRecalcButton &&
                  <Button
                      isLoading={loadingAgreement || recalcLoading}
                      isDisabled={false}
                      appearance="transparent"
                      form="sellerForm"
                      onClick={() => setSubmitType('recalc')}
                  >
                      Пересчитать
                  </Button>
              }
              <Button
                isLoading={loadingAgreement || loadingDocs}
                isDisabled={!rolesRightHandler("agreementsLogPage", functions, type, isAdmin) || !contractIsn || isCanceled}
                onClick={() => {
                  setSubmitType('gotoContract');
                  history.push(`/contract/${contractIsn}`)
                }}
                width={windowWidth < 768 ? "100%" : undefined}
                appearance="transparent"
              >
                Перейти к договору
              </Button>
              <Button
                isLoading={loadingAgreementMake || loadingAgreement}
                appearance="filled"
                isDisabled={isCanceled || !!contractIsn || (approvedRisks.length + deniedRisks.length !== risks?.length) || !rolesRightHandler("createAgreement", functions, type, isAdmin)}
                onClick={() => contractMake(id)}>
                Создать договор
              </Button>
            </StyledSignetAgreementButtons>
          </FlyButtons>
        </StyledEventsButtons>
      </StyledHeaderEvents>

      <StyledHeaderInformation>
        {informationList.map(({title, description}, index) => (
          <StyledInformationItem key={`info-item-${index}`}>
            <TextButton>{title}</TextButton>
            <TextButton>{description}</TextButton>
          </StyledInformationItem>
        ))}
        {agreementSumms}
      </StyledHeaderInformation>
      <ChangeLogModal header="История изменений" onClose={() => setChangeLogModalIsOpen(false)}
                      isOpen={stateChangeLogModalIsOpen}/>
    </StyledHeaderWrapper>
  )
};

export default ApplicationByIdHeader;