import React                             from "react";
import DynamicTable                      from "@atlaskit/dynamic-table";
import Pagination                                          from '@atlaskit/pagination';
import {StyledPagination, StyledTable, StyledTableWrapper} from "./styles";
import {StyledEmptyTableView}                              from "../../pages/agreementsLog/styles";
import {Heading3}                        from "../../globalStyledComponents";
import {Loader}                          from "../loader";
import {useWindowWidth}                  from "@react-hook/window-size";


export interface ITableRowItem {
  key: string
  id: string
  onClick: (event: any) => void
  cells: any[]
}

export interface ITableHeadItem {
  cells: any[]
}


interface ITableProps {
  head: ITableHeadItem
  rows: ITableRowItem[]
  emptyViewMessage?: string
  isLoading?: boolean
  page?: number
  rowsPerPage?: number
  onPageChange?: (page: number) => void
  totalRows?: number
}

const getTotalPages = (totalRows, rowsPerPage) => {
  if (isNaN(totalRows) || isNaN(rowsPerPage)) return 0;
  return Math.ceil(totalRows / rowsPerPage);
}

export const Table = ({head, rows, isLoading, totalRows, page, rowsPerPage, onPageChange, emptyViewMessage}: ITableProps) => {
  const windowWidth = useWindowWidth({wait: 250});
  const total: number = getTotalPages(totalRows, rowsPerPage);

  React.useEffect(() => {
    if (typeof page !== "number" || !onPageChange || isLoading) return;
    if (page > total) return onPageChange(1);
    // eslint-disable-next-line
  }, [])

  return (
    <StyledTableWrapper>
      <StyledTable>
        <DynamicTable
          emptyView={
            <StyledEmptyTableView>
              {isLoading ? <Loader type="simple"/> : <Heading3>{emptyViewMessage || "Пусто"}</Heading3>}
            </StyledEmptyTableView>
          }
          head={head}
          rows={isLoading ? [] : rows}
        />
      </StyledTable>
      {total !== 0 && !isLoading &&
      <StyledPagination>
          <Pagination
              max={windowWidth < 768 ? 7 : 14}
              selectedIndex={typeof page === "number" ? page - 1 : 0}
              onChange={onPageChange ? (ev, page) => onPageChange(page) : undefined}
              pages={Array.from({length: total}).map((i, index) => index + 1)}
          />
      </StyledPagination>}
    </StyledTableWrapper>
  );
};

export default Table;