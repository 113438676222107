import {useMutation}                         from "@apollo/react-hooks";
import {useCallNotification}                        from "./useCallNotification";
import {SAVE_ADDRESS} from "../utils/mutations";
import {CLASS_ISN_OBJECTS, CLASS_ISN_TITLES}        from "../helpers/buildTemplateForBack";

export const useSaveAddress = () => {
  const {setNotification} = useCallNotification();
  const [saveAddress, {loading: loadingSaveAddress}] = useMutation(SAVE_ADDRESS);

  const saveAddressHandler = async (agreementData: any, agreementFormState: any) => {
    const properties = ((agreementData || {}).objects || []).filter(({ClassISN}) => CLASS_ISN_OBJECTS === ClassISN).map((item, index) => ({
      ...item,
      AGROBJADDR: (agreementFormState?.propertyInsuranceSubjects || [])[index]?.pledgeAddress
    }));
    const titles = ((agreementData || {}).objects || []).filter(({ClassISN}) => CLASS_ISN_TITLES === ClassISN).map((item, index) => ({
      ...item,
      AGROBJADDR: (agreementFormState?.lossInsuranceSubjects || [])[index]?.titleAddress
    }));
    const insuranceObjects: any[] = [...properties, ...titles];
    if (insuranceObjects.length === 0) return;
    const promiseSaveAddress = new Promise((resolve) => {
      insuranceObjects.forEach(({ISN: isn, AGROBJADDR: address}, index) => {
          if (!isn || !address) return;
          saveAddress({
            variables: {
              isn,
              daData: {suggestions: address}
            }
          })
            .then(() => {
              if (index + 1 === insuranceObjects.length) return resolve("done")
            })
            .catch(error => setNotification({type: "error", text: error}))
        }
      )
    })
    return await promiseSaveAddress;
  }

  return {
    saveAddress: (agreementData: any, agreementFormState: any) => saveAddressHandler(agreementData, agreementFormState),
    loading: loadingSaveAddress
  }
};