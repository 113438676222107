import React                    from 'react';
import NivaInput                from "../../../../uiKit/nivaInput/NivaInput";
import {SearchIcon}             from "../../../../icons/ui/Search";
import {StyledSearch}           from "./styles";
import GroupToggle              from "./components/groupToggle";
import {useQuery, useLazyQuery} from "@apollo/react-hooks";
import {GROUPS, ME}             from "../../../../utils/queries";
import {Loader}                 from "../../../../uiKit/loader";
import {ErrorMessage}           from "../../../../components/errorMessage";
import {Heading4}               from "../../../../globalStyledComponents";

export interface IGroupItem {
  id?: string
  name: string
  leaderId?: string
  curatorId?: string
  parentId?: string
  email?: string
  specialGroup?: "noneGroup" | "clients"
}

const defaultGroups: IGroupItem[] = [
  {id: undefined, name: "Пользователи без группы", specialGroup: "noneGroup", leaderId: ""},
  {id: undefined, name: "Клиенты", specialGroup: "clients", leaderId: ""}
];

export const GroupsTab = () => {
  const [stateSearch, setSearch] = React.useState<string>("");
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const [getGroups, {data: dataGroups, loading: loadingGroups, error: errorGroups}] = useLazyQuery(GROUPS);
  const {type} = (dataUser || {}).me || {};
  const {id: parentId, name: parentName, email: parentEmail, leader: parentLeader, curator: parentCurator, parent: parentParent} = ((dataUser || {}).me || {}).group || {};
  const parentGroup: IGroupItem[] = parentId ? [{
    id: parentId,
    name: parentName,
    email: parentEmail,
    leaderId: (parentLeader || {}).id,
    curatorId: (parentCurator || {}).id,
    parentId: (parentParent || {}).id
  }] : [];

  const groups: IGroupItem[] = type === "admin" || !!stateSearch ? [
    ...((dataGroups || {}).groups || []).map(({id, name, leader, email, parent, curator}) => ({
      id,
      name,
      email,
      leaderId: (leader || {}).id,
      curatorId: (curator || {}).id,
      parentId: (parent || {}).id
    })),
    ...defaultGroups
  ] : [...parentGroup]

  const filterGroups: IGroupItem[] = groups.filter(({name}) => name.toLowerCase().includes(stateSearch.toLowerCase()));

  React.useEffect(() => {
    getGroups({
      variables: !!stateSearch ?
        {
          filter: stateSearch
        }
        :
        {
          parent: parentId ? parentId : null,
          children: true
        }
    })
    // eslint-disable-next-line
  }, [parentId, stateSearch])

  if (errorGroups) return <ErrorMessage error={errorGroups}/>
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (loadingUser) return <Loader type="simple"/>
  return (
    <>
      <StyledSearch>
        <NivaInput
          placeholder="Поиск по названию группы"
          debounce={true}
          debounceOffset={700}
          onChange={(value) => setSearch(value)}
          iconAfter={<SearchIcon/>}/>
      </StyledSearch>
      {!loadingGroups && !!stateSearch && !filterGroups.length && <Heading4>Группы по данному запросу не найдены</Heading4>}
      {!loadingGroups && !stateSearch && !filterGroups.length && <Heading4>Нет доступных групп</Heading4>}
      {loadingGroups && <Loader type="simple"/>}
      {!loadingGroups && filterGroups.map((props, index) => (
        <GroupToggle key={`group-${index}`} {...props}/>
      ))}
    </>
  )
};

export default GroupsTab;