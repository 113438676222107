import {ApolloClient, InMemoryCache} from '@apollo/client'
import { createUploadLink } from "apollo-upload-client";

const endPoint: string = `${process.env.REACT_APP_ENDPOINT_DEFAULT}`;

const uploadLink = createUploadLink({ uri: endPoint, credentials: "include" });

const link = uploadLink;

export const client: any = new ApolloClient({
  cache: new InMemoryCache(),
  link
});
