import React                 from 'react';
import {IStepsProviderProps} from "../stepsProvider";
import StoreAgreementForm    from "../../store";
import StepsLayout           from "../stepsLayout";
import StepsPaginationButton from "../stepsPaginationButton";
import Form                  from '@atlaskit/form';
import UploadFile            from "../../../../uiKit/uploadFile";
import {DocumentsHint}       from "../../../applicationById/components/toggleArea/components/rightToggles";

const DocumentsStep = ({title, showSaveModal,  id} : IStepsProviderProps) => {
  const [stateFile, setFile] = React.useState<any[]>([]);

  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const {additionalDocuments, riskLife, riskProperty, riskTitle} = agreementFormState || {};

  React.useEffect(() => {
    if (!additionalDocuments || stateFile.length === additionalDocuments.length) return;
    setFile(additionalDocuments);
    // eslint-disable-next-line
  }, [additionalDocuments?.length])

  const addFileHandler = (file) => {
    setFile(file)
  }

  const onFormSubmit = () => {
    updateAgreementForm({additionalDocuments: stateFile});
    if (!riskLife) return showSaveModal(true);
    showSaveModal();
  }

  return (
    <Form onSubmit={onFormSubmit}>
      {({formProps}) => (
        <form {...formProps} id={id}>
          <StepsLayout title={title}>
            <DocumentsHint riskProperty={riskProperty} riskTitle={riskTitle}/>
            <UploadFile value={stateFile} onChange={addFileHandler}/>
          </StepsLayout>
          <StepsPaginationButton formId={id}/>
        </form>
      )}
    </Form>
  );
};

export default DocumentsStep;