import styled  from "styled-components";

export const StyledUnderwritingHeaderFields = styled.div`
  margin-top: 3rem;
`;
export const StyledUnderwritingRisksForm = styled.div`
  padding: 3rem;
  
    @media screen and (max-width: 767px) {
    padding: 0;
  }
`;