import React, { useEffect, useRef, useState }                                                        from 'react';
import { DropDownDaDataContentProps }                                                                from './types';
import { DropDownDaDataContentEmpty, SelectItemDropDownDaDataContent, WrapperDropDownDaDataContent } from './styles';
import { useKeyPress }                                                                               from '../../hooks/useKeysPress';
import HighlightPattern  from '../helpers/highlightPattern';
import {Heading4, Text4} from "../../../globalStyledComponents";

const DropDownDaDataContent: React.FC<DropDownDaDataContentProps> = ({ dropDownHelpMessage, items, emptyMessage, daDataChangeHandler, inputValue }) => {
	const dropDownContent = useRef<HTMLDivElement | null>(null);

	const downPress = useKeyPress('ArrowDown');
	const upPress = useKeyPress('ArrowUp');
	const enterPress = useKeyPress('Enter');
	const [cursor, setCursor] = useState<number>(0);

	useEffect(() => {
		if (!items?.length || !downPress || !dropDownContent) return;
		dropDownContent.current?.focus();
		setCursor(prevState => (prevState < items.length - 1 ? prevState + 1 : prevState));
	}, [items, downPress, dropDownContent]);

	useEffect(() => {
		if (items?.length && upPress) {
			setCursor(prevState => (prevState > 0 ? prevState - 1 : prevState));
		}
	}, [items, upPress]);

	useEffect(() => {
		if (items?.length && enterPress) {
			daDataChangeHandler(items[cursor]);
		}
	}, [items, cursor, enterPress, daDataChangeHandler]);

	useEffect(() => {
		setCursor(0);
	}, [items]);

	return (
		<>
			{items && (
				<WrapperDropDownDaDataContent dropDownHelpMessage={dropDownHelpMessage} tabIndex={0} height={30} ref={dropDownContent}>
					{items?.map((item, index) => (
						<SelectItemDropDownDaDataContent key={item.value} onMouseEnter={(): void => setCursor(index)} active={index === cursor} onClick={(): void => daDataChangeHandler(item)}>
							<Text4>
								<HighlightPattern textToHighlight={item.value} source={inputValue} />
							</Text4>
						</SelectItemDropDownDaDataContent>
					))}
					{!items.length && (
						<DropDownDaDataContentEmpty>
							<Heading4>{emptyMessage || 'Нет элементов'}</Heading4>
						</DropDownDaDataContentEmpty>
					)}
				</WrapperDropDownDaDataContent>
			)}
		</>
	);
};

export default React.memo(DropDownDaDataContent);
