import {getSelectValue} from "../formUtils/getFormValue";

const insureTypes = [
{isn: "1", name: "Личное страхование(Страхование жизни)"},
{isn: "2", name: "Имущество(Страхование имущества)"},
{isn: "3", name: "Имущество(Страхование имущества)/Титульное(Страхование титула)"},
{isn: "4", name: "Личное страхование(Страхование жизни)/Имущество(Страхование имущества)/Титульное(Страхование титула)"},
{isn: "5", name: "Личное страхование(Страхование жизни)/Имущество(Страхование имущества)"},
{isn: "6", name: "Личное страхование(Страхование жизни)/Титульное(Страхование титула)"}
]
const insureTypesForNiva = [
  {name: "riskLife", value: "Личное страхование(Страхование жизни)"},
  {name: "riskProperty", value: "Имущество(Страхование имущества)"},
  {name: "riskTitle", value: "Титульное(Страхование титула)"},
]
export const getVariablesForTemplate = (formData: any) => {
  const {insureType: insureTypeForm, property} = formData || {};
  const insureTypeName: string[] = insureTypesForNiva.reduce<string[]>((prev, {name, value}, index) => {
    if (formData[name] && index === 0) return [value];
    if (formData[name]) return [...prev, value]
    return prev;
  }, [])

  const insureType: string  = (insureTypes.find(({name}) => name === insureTypeName.join("/"))||{}).isn || "1";
  if (!getSelectValue(insureTypeForm) || !property || !insureType) return undefined;
  return {
    role: "1",
    insureType,
    credit: getSelectValue(insureTypeForm),
    property
  }
};