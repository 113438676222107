import {gql} from '@apollo/client';

export const DICT = gql`
query dictionary($name: String!) {
  dictionary(name: $name)
}
`;

export const ME = gql`
query me {
  me {
    id
    type
    name
    email
    login
    isAdmin
    show_kv
    currentAgreement
    kias
    role {
      id
      name
      functions {
        id
        tag
      }
    }
    group {
      id
      name
      email
      leader {
        id
        name
      }
      parent {
        id
        name
      }    
      curator {
        id
        name
        email
        phone
      }
      is_filial
    }
    restrictions {
      tag
      value
    }
    banksOk
    skipDeclarationBanks
  }
}
`;

export const LOGOUT = gql`
query logout {
  logout
}
`;


export const ANKETA_DOWNLOAD = gql`
query documentForm(
  $isn: isn
  $templateIsn: isn
  $classId: isn
  $email: String
  $subject: String
) {
  documentForm(
    isn: $isn
    templateIsn: $templateIsn
    classId: $classId
    email: $email
    subject: $subject
  ) {
    id
    name
    url
  }
}
`;

export const DOWNLOAD_ALL_FILES = gql`
query attachmentFiles($type: String $ref: isn $fileInDb: String) {
  attachmentFiles(type: $type ref: $ref fileInDb: $fileInDb) {
    id
    url
  }
}
`;

export const GET_AGREEMENT = gql`
query agreementCalc($isn: isn) {
  agreementCalc(isn: $isn) {
    curIsn
    curCode
    curName
    stageIsn
    stageName
    statusIsn
    statusName
    isn
    id
    clientIsn
    clientInfo
    deptIsn
    deptName
    classIsn
    productIsn
    productName
    dateBeg
    dateEnd
    dateSign
    attribs {
      isn
      title
      val
      value
      remark
    }
    objects
    roles
    clauses
  }
}
`;
export const GET_CONTRACT = gql`
query agreement($isn: isn) {
  agreement(isn: $isn) {
    curIsn
    curCode
    curName
    stageIsn
    stageName
    statusName
    status
    isn
    id
    clientIsn
    deptIsn
    deptName
    emplIsn
    classIsn
    productIsn
    productName
    dateBeg
    dateEnd
    dateSign
    attribs {
      isn
      title
      val
      value
      remark
    }
    objects
    roles
    clauses
    dateLiability
    dateLiabilityEnd
    sums
    prevId
    prevIsn
    addendumDateBeg
    addendumDateSign
  }
}
`;

export const ANKETA_TEMPLATE = gql`
query anketaTemplate(
  $role: Int
  $insureType: Int
  $credit: Int
  $isn: isn
  $property: Int
) {
  anketaTemplate(
    isn: $isn
    role: $role
    insureType: $insureType
    credit: $credit
    property: $property
  ) {
    isn
    settings
    parts
    remark
  }
}
`;

export const DASHBOARD = gql`
query dashboard {
  dashboard {
    agreements {
      count
      status
    }
    contracts {
      count
      status
    }
  }
}
`;

export const GET_AGREEMENT_AGENTS = gql`
query getAgent($agreementIsn: isn) {
  getAgent(agreementIsn: $agreementIsn)
}
`;

export const GET_MY_AGREEMENT = gql`
query getCounterAgentPolicy($contragentIsn: isn) {
  getCounterAgentPolicy(contragentIsn: $contragentIsn)
}
`;

export const GET_AGREEMENT_PAYMENT = gql`
query userGetPaymentQuery($agreementIsn: isn) {
  userGetPaymentQuery(agreementIsn: $agreementIsn)
}
`;

export const DOWNLOAD_CONTRACT = gql`
query agreementForm(
  $isn: isn!
  $bankIsn: isn!
  $project: Int
  $template: String
  $email: String
  $subject: String
) {
  agreementForm(
    isn: $isn
    bankIsn: $bankIsn
    project: $project
    template: $template
    email: $email
    subject: $subject
  ) {
    id
    url
  }
}
`;

export const CHECK_INVOICE_STATUS = gql`
query invoiceStatus($isn: isn) {
  invoiceStatus(isn: $isn)
}
`;

export const CONTRAGENT = gql`
query contragent($isn: isn!) {
  contragent(isn: $isn) {
    isn
    fullName
    firstName
    lastName
    parentName
    shortName
    latName
    isLegal
    gender
    countryIsn
    classIsn
    className
    isVip
    isResident
    inn
    kpp
    ogrn
    orgFormIsn
    phoneMobile
    email
    birthDate
    addr1
    addr2
    authDocClassIsn
    authDocClassName
    authDocNumber
    authDocSer
    authDocDate
    authDocIssuer
    authDocIssuerCode
    addrs
  }
}
`;

export const ROLES = gql`
query roles {
  roles {
    id
    name
    readOnly
  }
}
`;

export const DOWNLOAD_PRE_CALC = gql`
query preCalcForm($bankIsn: isn, $calcs: [isn]) {
  preCalcForm(bankIsn: $bankIsn, calcs: $calcs) {
    id
    url
  }
}
`;
export const ROLE_BY_ID = gql`
query roles($id: [ID]) {
  roles(id: $id) {
    id
    name
    readOnly
    users_count
    group {
      id
    }
    functions {
      id
      name
    }
  }
}
`;
export const GROUPS = gql`
query groups($id: [ID], $parent: ID, $children: Boolean = false, $filter: String) {
  groups(id: $id, parent: $parent, children: $children, filter: $filter) {
    id
    name
    email
    leader {
      id
      name
    }
    parent {
      id
      name
    }    
    curator {
      id
      name
    }
    restrictions
  }
}
`;
export const FUNCTIONS = gql`
query functions {
  functions {
    id
    name
  }
}
`;
export const USERS = gql`
query users($group: [ID] $roleId: ID) {
  users(group: $group role_id: $roleId) {
    id
    login
    email
    phone
    name
    type
    isAdmin
    show_kv
    is_enabled
    role {
      id
    }
    group {
      id
    }
    restrictions {
      tag
      value
    }
  }
}
`;

export const DOCS = gql`
query docs(
  $type: String
  $isn: isn
  $status: [isn]
  $period: period
  $filter: String
  $page: Int
  $banks: [isn]
  $limit: Int
) {
  docs(
    type: $type
    isn: $isn
    status: $status
    period: $period
    filter: $filter
    page: $page
    banks: $banks
    limit: $limit
  ) {
  items {
      id
      isn
      type
      number
      kias
      status
      statusCode
      needProlongation
      nextInvoiceAllowed
      prevAgreementIsn
      nextAgreementIsn  
      data
      added(${
  `timezone: "${Intl.DateTimeFormat().resolvedOptions().timeZone}"`
})
      risks {
        risk
        object
        status
        data
      }
      edited(${
  `timezone: "${Intl.DateTimeFormat().resolvedOptions().timeZone}"`
})
      editor {
        id
        name
      }
      user {
        id
        name
        type
        group {
          id
          name
        }
      }
      }
      total
  }
}
`;

export const DOCS_HISTORY = gql`
query activity($isn: isn) {
  activity(isn: $isn) {
    added
    user {
      id
      name
      email
    }
    event
    eventName
    description
  }
}
`;

export const USER_GROUP_HISTORY = gql`
query changeLog($model: ChangeLogTableEnum, $id: String!) {
  changeLog(model: $model, id: $id) {
    createdAt
    user {
      id
      name
    }
    fields {
      field
      label
      oldValue
      newValue
    }
  }
}
`;
export const COMMENTS = gql`
query comments($doc: ID!, $new: Boolean) {
  comments(doc: $doc, new: $new) {
    new
    list {
      id
      text
      date
      isNew
      user {
        id
        name
      }
    }
  }
}
`;

export const AGREEMENT = gql`
query agreement($isn: isn) {
  agreement(isn: $isn) {
    isn
    curIsn
    curCode
    curName
    stageIsn
    stageName
    stageIsn
    stageName
    id
    clientIsn
    deptIsn
    deptName
    classIsn
    className
    dateBeg
    dateEnd
    dateSign
    objects
    roles
    clauses
    attribs {
      isn
      title
      val
      value
      remark
    }
  }
}
`;

export const GET_ATTACHMENT = gql`
query attachments($ref: isn, $type: String = "A", $fileInDb: String = "N") {
  attachments(ref: $ref, type: $type, fileInDb: $fileInDb) {
    isn
    fileName
    type
    ref
    note
    added
  }
}
`;

export const DOWNLOAD_ATTACHMENT = gql`
query attachmentFile($isn: isn, $ref: isn, $type: String) {
  attachmentFile(isn: $isn, ref: $ref, type: $type) {
    id
    url
  }
}
`;
export const GET_KV_AGENT = gql`
query getKvAgent($isn: isn) {
  getKvAgent(isn: $isn)
}
`;

export const GET_MULTI_YEAR_AGREEMENT = gql`
query getMultiyearAgreement($id: String) {
  getMultiyearAgreement(id: $id)
}

`;

export const BANKS_RAISING = gql`
query banks {
  banks {
    isn
    raising
  }
}
`;
