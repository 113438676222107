import React                                                                from "react";
import {StyledContent, StyledContentArea, StyledLayout, StyledMobileShadow} from "./styles";
import LeftMenu                                                             from "./leftMenu";
import Header                                                               from "./header";
import {useHistory}                                                         from "react-router";
import StoreNavigation                                                      from "./leftMenu/store";
import {useWindowWidth}                                                     from "@react-hook/window-size";
import {getClearPathname}                                                   from "../../helpers/rolesRightHandler";


const pagesWithoutBackground = ['roles', "agreementsLog", "contractsLog", "myAgreements", "myAnketa"];
export const Layout = ({children}: { children: any }) => {
  const windowWidth = useWindowWidth({wait: 250});
  const {location} = useHistory();
  const showNavigation = !["authorization", "page404", "authorization2"].includes(getClearPathname(location.pathname));
  const [{navigation: {navigationState: {isOpen}}}, {navigation: actionsNavigation}]: any = StoreNavigation();
  const toggleNavigation = () => actionsNavigation.setState({
    navigationState: {
      isOpen: !isOpen
    }
  });
  return (
    <StyledLayout>
      {showNavigation && <LeftMenu/>}
      <StyledContentArea isMenuOpen={!showNavigation ? false : isOpen}>
        {windowWidth < 768 && isOpen && showNavigation && <StyledMobileShadow onClick={toggleNavigation}/>}
        {showNavigation && <Header/>}
        <StyledContent isBackground={!pagesWithoutBackground.includes(getClearPathname(location.pathname))} isAuth={!showNavigation}>
          {children}
        </StyledContent>
      </StyledContentArea>
    </StyledLayout>
  )
};

export default Layout;