import React                                         from 'react';
import {StyledStepFormHeader, StyledStepFormWrapper} from "../../../generalStep/styles";
import {Heading3}                                    from "../../../../../../globalStyledComponents";
import UploadFile                                  from "../../../../../../uiKit/uploadFile";
import Form                                                                                 from '@atlaskit/form';
import {anketaPartsBuilder, anketaSettingsBuilder, checkDeclaration, getRestrictionsObject} from "../../../../../../helpers/buildTemplateForBack";
import {useCallNotification}                                                                from "../../../../../../hooks/useCallNotification";
import StoreAgreementForm                    from "../../../../store";
import {useLazyQuery, useMutation, useQuery}       from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, ANKETA_TEMPLATE, ME}      from "../../../../../../utils/queries";
import {
  CONFIRM_AGREEMENT_DECLARATION, SAVE_ATTACHMENT,
  SIGN_ANKETA,
  UPDATE_ANKETA,
  VERIFY_ANKETA
} from "../../../../../../utils/mutations";
import {Loader}                                    from "../../../../../../uiKit/loader";
import SignByNumber                                from "../signByNumber";
import {AnketaPaginationButtons}                   from "../../index";
import Button                                      from "../../../../../../uiKit/button/button";
import {fileDownloader}                            from "../../../../../../helpers/fileDownloader";
import {StyledDownloadDeclaration}                 from "../../styles";
import {DownloadIcon}                              from "../../../../../../icons/ui/Download";
import Tabs, {ITabsItem}                           from "../../../../../../uiKit/tabs";
import {useWindowWidth}                            from "@react-hook/window-size";
import {useParams} from "react-router";

const Declaration = ({person, onClose, isModal, openSaveModal, isHideAnketa, afterProlongation, clientIsn, isSberbank, isAllowedNoSmsForBank} : {person: any, onClose: () => void, openSaveModal?: () => void, isModal?: boolean, isHideAnketa?: boolean, afterProlongation?: boolean, clientIsn?: number, isSberbank?: boolean, isAllowedNoSmsForBank?: boolean}) => {
  const {id} = useParams();
  const windowWidth = useWindowWidth({wait: 250});
  const {setNotification} = useCallNotification();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {restrictions} = (dataUser || {}).me || {};
  const isDeclarationOk: boolean = checkDeclaration(getRestrictionsObject(restrictions), agreementFormState);

  const mainTabs: ITabsItem[] = [
    ...(isHideAnketa || afterProlongation ? [] : [ {label: "Анкета", value: "online"}]),
    ...(isDeclarationOk || afterProlongation ? [{label: "Декларация", value: "declaration"}] : []),
  ]

  const [stateMainTabs, setMainTabs] = React.useState<ITabsItem>(mainTabs[0])

  const declarationTabs: ITabsItem[] = [
    {label: `Загрузить ${stateMainTabs.value === "online" ? "анкету" : "декларацию"}`, value: "upload"},
    {label: "Подписать по смс", value: "sign"},
  ]

  const [stateSubmitType, setSubmitType] = React.useState<"verify" | "download" | undefined>(undefined)
  const [stateTabs, setTabs] = React.useState<ITabsItem>(declarationTabs[0])
  const [stateFile, setFile] = React.useState<any[]>([]);
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [signAnketa, {data: dataSignAnketa, loading: loadingSignAnketa}] = useMutation(SIGN_ANKETA);
  const [updateAnketa, {data: dataUpdateAnketa, loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const [confirmAgreementDeclaration, {loading: loadingConfirmAgreementDeclaration}] = useMutation(CONFIRM_AGREEMENT_DECLARATION, {refetchQueries: ["docs", "attachments"]});
  const [downloadDeclaration, {data: dataDownloadDeclaration, loading: loadingDownloadDeclaration, error: errorDownloadDeclaration}] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const urlDeclaration = ((dataDownloadDeclaration || {}).documentForm || {}).url;
  const [verifyAnketa, {loading: loadingVerifyAnketa}] = useMutation(VERIFY_ANKETA);
  const [saveAttachment, {loading: loadingSaveAttachment}] = useMutation(SAVE_ATTACHMENT);
  const isSigningAnketa: boolean = !!(dataSignAnketa || {}).anketaSign;
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const {peoples, templateVariables, riskLife} = agreementFormState || {};
  const {isn: isnPerson} = person || {};
  const isnAnketa = ((dataUpdateAnketa || {}).anketa || {}).isn;
  const {
    CheckRes: scoringCheck,
    ResultScoring,
    Koef_Height_weight: heightWeight,
    Koef_Pressure: pressure
  } = (dataSignAnketa || {}).anketaSign || {};
  const scoringResult: string | undefined = heightWeight === "МО" || pressure === "МО" ? `${ResultScoring} Необходимо прохождение медицинского обследования.` : ResultScoring;

  const isLoading: boolean = loadingSignAnketa || loadingDownloadDeclaration || loadingUpdateAnketa || loadingAnketaTemplate || loadingVerifyAnketa || loadingConfirmAgreementDeclaration || loadingSaveAttachment;
  const isDisabled: boolean = stateTabs.value === "upload" ? stateFile.length === 0 : !isSigningAnketa;

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: templateVariables})
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    fileDownloader(urlDeclaration)
    // eslint-disable-next-line
  }, [urlDeclaration])

  const addFileHandler = (file) => {
    setFile(file)
  }

  const saveAnketaHandler = (isnAnketa: string) => {
    if(afterProlongation){
      if(isSigningAnketa){
        setAfterProlongDeclaration(true);
      }
      if(stateFile.length !== 0){
        (stateFile || []).forEach(file => {
          saveAttachment({variables: {type: "A", ref: id, file}})
            .then(() => setAfterProlongDeclaration(false))
            .catch(error => setNotification({type: "error", text: error}))
        })
      }
      return;
    }
    updateAgreementForm({
      peoples: (peoples || []).map(person => {
        const {isn} = person || {};
        if (isn === isnPerson) return {
          ...person,
          isnAnketa,
          scoringCheck,
          scoringResult,
          heightWeight,
          pressure,
          fillingType: stateTabs.value === "upload" ? "upload" : stateMainTabs.value,
          attachments: stateFile
        };
        return person;
      })
    });
    onClose();
    if (openSaveModal) openSaveModal();
  }

  const setAfterProlongDeclaration = (viaSms: boolean) => {
    confirmAgreementDeclaration({
      variables: {
        isn: id,
        viaSms,
      }
    }).then(() => {
      onClose();
      if (openSaveModal) openSaveModal();
    })
  }

  const downloadDeclarationHandler = (isnAnketa: string) => {
    downloadDeclaration({
      variables: {
        isn: isnAnketa,
        templateIsn: stateMainTabs.value === "online" ? '10929' : process.env.REACT_APP_TEMPLATE_ISN
      }
    })
    setSubmitType(undefined)
  }

  const downloadAfterProlongationDeclarationHandler = () => {
    downloadDeclaration({
      variables: {
        isn: id,
        templateIsn: 15416,
        classId: 2,
      }
    })
    setSubmitType(undefined)
  }

  const verifyAfterProlongation = () => {
    verifyAnketa({
      variables: {
        clientIsn,
      }
    })
    setSubmitType(undefined)
  }

  const continueWithSberbank = () => {
    updateAgreementForm({
      peoples: (peoples || []).map(person => {
        const {isn} = person || {};
        if (isn === isnPerson) return {
          ...person,
          fillingType: "declaration"
        };
        return person;
      })
    });
    onClose();
    if (openSaveModal) openSaveModal();
  }

  const onFormSubmit = () => {
    if(id && afterProlongation && stateSubmitType === "download") return downloadAfterProlongationDeclarationHandler();
    if(id && afterProlongation && stateSubmitType === "verify") return verifyAfterProlongation();
    if (isnAnketa && stateSubmitType === "download") return downloadDeclarationHandler(isnAnketa);
    if ((isSigningAnketa || stateFile.length !== 0) && (isnAnketa || afterProlongation)) return saveAnketaHandler(isnAnketa);
    const partsWithValue: any[] = anketaPartsBuilder({...agreementFormState, ...person}, parts);
    const settingsWithValue: any[] = anketaSettingsBuilder({...agreementFormState, ...person}, settings);
    updateAnketa({
      variables: {
        clientIsn: isnPerson,
        settings: settingsWithValue,
        parts: partsWithValue,
        templateVariables
      }
    }).then(({data: {anketa: {isn: isnAnketa}}}) => {
      if (stateSubmitType === "verify") {
        verifyAnketa({
          variables: {
            clientIsn: isnPerson,
            isn: isnAnketa
          }
        })
          .catch(error => setNotification({type: "error", text: error}))
        return setSubmitType(undefined)
      }
      if (stateSubmitType === "download") return downloadDeclarationHandler(isnAnketa);
      return saveAnketaHandler(isnAnketa);
    }).catch(error => setNotification({type: "error", text: error}))
  }

  if (loadingAnketaTemplate) return <Loader type="simple"/>
  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})
  if (errorDownloadDeclaration) setNotification({type: "error", text: errorDownloadDeclaration})
  if (errorUser) setNotification({type: "error", text: errorUser})

  return (
    <>
      {!isSberbank && <>
        {mainTabs.length > 1 && <Tabs tabMargin={2.5} tabWidth={20} activeTab={stateMainTabs}
                                      onChange={tab => setMainTabs(tab)} tabs={mainTabs}/>}
        {!(isAllowedNoSmsForBank && stateMainTabs.value === "declaration") && <>
          <Form onSubmit={() => onFormSubmit()}>
            {({formProps}) => (
              <form {...formProps} id="uploadDeclarationForm">
                <StyledDownloadDeclaration>
                  <Button
                    appearance="filled"
                    width="100%"
                    type="submit"
                    isLoading={isLoading}
                    iconBefore={<DownloadIcon/>}
                    onClick={() => {
                      setSubmitType("download")
                    }}>Скачать {stateMainTabs.value === "online" ? "анкету" : "декларацию"}</Button>
                </StyledDownloadDeclaration>
                <StyledDownloadDeclaration>
                  <Tabs tabMargin={2.5} tabWidth={windowWidth < 768 ? 16 : 28.8} activeTab={stateTabs}
                        onChange={tab => setTabs(tab)} tabs={declarationTabs}/>
                </StyledDownloadDeclaration>
                {stateTabs.value === "upload" && <StyledStepFormWrapper>
                    <StyledStepFormHeader>
                        <Heading3>Загрузить подписанную {stateMainTabs.value === "online" ? "анкету" : "декларацию"}</Heading3>
                    </StyledStepFormHeader>
                    <UploadFile value={stateFile} onChange={addFileHandler}/>
                </StyledStepFormWrapper>}
                {stateTabs.value === "sign" && <SignByNumber
                    isSigningAnketa={isSigningAnketa}
                    isLoading={isLoading}
                    isnAnketa={isnAnketa}
                    setSubmitType={setSubmitType}
                    signAnketa={signAnketa}
                    formId="uploadDeclarationForm"
                    skipSigning={riskLife || afterProlongation}
                    isDeclarationForm={stateMainTabs.value !== "online"}
                    person={person}/>}
              </form>
            )}
          </Form>
          {!isModal && <AnketaPaginationButtons isDisabled={isDisabled} isLoading={isLoading} onClose={onClose} formId={"uploadDeclarationForm"}/>}
          {isModal && <Button
              width="100%"
              type="submit"
              form="uploadDeclarationForm"
              appearance="filled"
              isDisabled={isDisabled}
              isLoading={isLoading}>
            {stateMainTabs.value === "online" ? "Сохранить" : "Оформить"}
          </Button>}
        </>}
        {isAllowedNoSmsForBank && stateMainTabs.value === "declaration" && <>
          <div style={{height: "20px"}}></div>
        </>}
      </>}
      {(isSberbank || isAllowedNoSmsForBank && stateMainTabs.value === "declaration" ) && <>
          <Button
              width="100%"
              appearance="filled"
              isLoading={isLoading}
              onClick={() => continueWithSberbank()}>
            Сохранить
          </Button>
      </>}
    </>
  );
};

export default Declaration;