import React                    from "react";
import {Heading2}               from "../../../../globalStyledComponents";
import Button                   from "../../../../uiKit/button/button";
import {useHistory}             from "react-router";
import FlyButtons               from "../../../../components/flyButtons";
import {StyledStatisticsHeader} from "../../../statistics/components/header/styles";

export const MyAgreementHeader = () => {
  const history = useHistory();

  return (
    <StyledStatisticsHeader>
      <Heading2>Мои договоры</Heading2>
      <FlyButtons>
        <Button appearance="filled" onClick={() => history.push('/myAnketa')}>Заполнить анкету</Button>
      </FlyButtons>
    </StyledStatisticsHeader>
  )
};

export default MyAgreementHeader;