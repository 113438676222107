import React                                from 'react';
import Modal, {ModalProps}                  from "../../../uiKit/modal";
import Button                               from "../../../uiKit/button/button";
import {StyledButtonsGroup}                 from "../../roles/components/header/styles";
import FieldsBuilder                        from "../../../uiKit/fieldsBuilder";
import Form                                 from '@atlaskit/form';
import {getFormError}                       from "../../../formUtils/getFormError";
import {getFormValue}                       from "../../../formUtils/getFormValue";
import {IFormField}                         from "../../../uiKit/fieldsBuilder/interfaces";
import {useCallNotification}                from "../../../hooks/useCallNotification";
import {useLazyQuery}                       from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, DOWNLOAD_CONTRACT} from "../../../utils/queries";

// type = contract | letter | bill
export const SendClientModal = ({onClose, header, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {type, isn, number, isnBank, email: defaultEmail, templateIsn} = data || {};

  const [downloadContract, {
    data: dataDownloadContract,
    loading: loadingDownloadContract,
  }] = useLazyQuery(DOWNLOAD_CONTRACT, {fetchPolicy: "cache-and-network"});
  const [downloadAnketa, {
    data: dataDownloadAnketa,
    loading: loadingDownloadAnketa,
  }] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const idFile = ((dataDownloadContract || {}).agreementForm || {}).id;
  const idFileAnketa = ((dataDownloadAnketa || {}).documentForm || {}).id;

  React.useEffect(() => {
    if (!idFileAnketa) return;
    setNotification({type: "success", text: "Успешно отправлено"});
    onClose();
  }, [idFileAnketa])

  React.useEffect(() => {
    if (!idFile) return;
    setNotification({type: "success", text: "Успешно отправлено"});
    onClose();
  }, [idFile])

  const downloadLetterHandler = (email: string) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "12490",
        classId: 2,
        email,
        subject: `Письмо - подтверждение оплаты по договору №${number}`
      }
    });
  };
  
  const downloadKidHandler = (email: string) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "15741",
        classId: 2,
        email,
        subject: `Ключевой информационный документ по договору №${number}`
      }
    });
  };
  
  

  const downloadContractHandler = (email: string) => {
    downloadContract({
      variables: {
        isn,
        bankIsn: isnBank,
        project: 0,
        email,
        subject: `Договор ипотечного страхования №${number}`
      }
    })
  };

  const downloadInvoiceHandler = (email: string) => {
    downloadAnketa({variables: {
        isn,
        templateIsn,
        email,
        subject: `Счет на оплату страховой премии по договору №${number}`
      }})
  };

  const downloadTariffsHandler = (email: string) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "15937",
        classId: 2,
        email,
        subject: `Тарифы №${number}`
      }
    });
  };

  const downloadSertificatHandler = (email: string) => {
    downloadAnketa({
      variables: {
        isn,
        templateIsn: "12628",
        classId: 2,
        email,
        subject: `Сертификат по договору №${number}`
      }
    });
  };

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {email} = getFormValue(data);
    if (type === "contract") return downloadContractHandler(email);
    if (type === "letter") return downloadLetterHandler(email);
    if (type === "kid") return downloadKidHandler(email);
    if (type === "bill") return downloadInvoiceHandler(email);
    if (type === "tariffs") return downloadTariffsHandler(email);
    if (type === "sertificat") return downloadSertificatHandler(email);
  };

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "input",
        name: "email",
        defaultValue: defaultEmail,
        placeholder: "E-mail",
        isRequired: true,
        errorMessage: "Укажите E-mail",
        validationType: "email"
      }
    },
  ]

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                type="submit"
                appearance="filled"
                isLoading={loadingDownloadContract || loadingDownloadAnketa}
              >
                Отправить
              </Button>
              <Button
                onClick={onClose}
                appearance="transparent"
                isLoading={loadingDownloadContract || loadingDownloadAnketa}
              >
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default SendClientModal;