import styled               from "styled-components";
import {blue3, grey, white} from "../../globalStyledComponents";

export const StylesDropDownWrapper = styled.div`
  position: relative;
`;
export const StyledDropDownBody = styled.div<{position: "bottom" | "top", width: undefined | "100%", maxWidth: number | undefined}>`
  ${({width, maxWidth, position}) =>
    `
    width: ${width === "100%" ? "100%" : "auto"};
    max-width: ${typeof maxWidth === "undefined" ? "unset" : `${maxWidth}rem`};
    ${position === "bottom" ? "top: calc(100% + 1rem);" : "bottom: calc(100% + 1rem);"}
    `
  }
  z-index: 10;
  position: absolute;
  right: 0;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  background: ${white};
`;

export const StyledDropDownListItem = styled.div<{isDisabled: boolean}>`
  > p {
     overflow: hidden;
  text-overflow: ellipsis; 
  }
  padding: 1.3rem 1.5rem;
  border-bottom: 1px solid ${grey};
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  ${({isDisabled}) =>
    isDisabled ? 
      `
      > p {
     opacity: 0.2;   
      }
      cursor: no-drop;
      `
      :
      `
      :hover {
        background: ${blue3};
      }
      `
  }
  :last-child {
    border: unset;
  }
`;