import React                                                                    from "react";
import {StyledCoBorrowerHeader, StyledCoBorrowerItem, StyledCoBorrowersWrapper} from "./styles";
import {Heading3}         from "../../../../../../globalStyledComponents";
import Button
                          from "../../../../../../uiKit/button/button";
import {TrashIcon}        from "../../../../../../icons/ui/Trash";
import {AddIcon}          from "../../../../../../icons/ui/Add";
import StoreAgreementForm from "../../../../store";
import {getPerson}        from "../../../../../../helpers/buildTemplateForBack";
import {useParams}                       from "react-router";
import {ContragentForm, IContragentItem} from "../../../generalStep";

interface ICoBorrowerProps {
  index: number
  deleteHandler: () => void
  coBorrower: IContragentItem
  stateCoBorrowers: IContragentItem[]
  setCoBorrowers: any
}

const CoBorrower = ({index, coBorrower, deleteHandler, stateCoBorrowers, setCoBorrowers}: ICoBorrowerProps) => {
  const {number, isn, data} = coBorrower;
  const {share} = data || {}

  console.log("contragent-", index, number, isn, data)

  // const form: IFormField[] = [
  //   {
  //     fieldItems: {
  //       type: "fio",
  //       name: `fullName-${number}`,
  //       placeholder: "ФИО заемщика",
  //       isRequired: true,
  //       debounce: true,
  //       defaultValue: fullName,
  //       errorMessage: "Введите Ф.И.О",
  //       validationType: "fullName"
  //     }
  //   },
  //   {
  //     columns: 2,
  //     columnGap: 4,
  //     fieldItems: [
  //       {
  //         type: "input",
  //         name: `phoneMobile-${number}`,
  //         isRequired: true,
  //         debounce: true,
  //         mask: "+7 (999) 999-99-99",
  //         defaultValue: phoneMobile,
  //         validationType: "phone",
  //         errorMessage: "Введите телефон",
  //         placeholder: "Телефон"
  //       },
  //       {
  //         type: "date",
  //         name: `birthDate-${number}`,
  //         isRequired: true,
  //         defaultValue: birthDate,
  //         errorMessage: "Введите дату рождения",
  //         placeholder: "Дата рождения"
  //       }
  //     ]
  //   },
  //   {
  //     fieldItems:
  //       {
  //         type: "range",
  //         isRequired: true,
  //         errorMessage: "Выберите долю",
  //         name: `share-${number}`,
  //         defaultValue: share,
  //         placeholder: `Доля Созаемщика ${index + 1} в общем доходе, %`,
  //         startValue: 0,
  //         endValue: 100
  //       }
  //   }
  // ]

  return (
    <StyledCoBorrowerItem>
      <StyledCoBorrowerHeader>
        <Heading3>Созаемщик {index + 1}</Heading3>
        <Button appearance="icon" onClick={(ev) => {
          ev.preventDefault()
          deleteHandler()
        }} icon={<TrashIcon/>}/>
      </StyledCoBorrowerHeader>
      <ContragentForm isn={isn} number={number} isShowShare={true} defaultShare={share} stateContragents={stateCoBorrowers} setContragents={setCoBorrowers}/>
    </StyledCoBorrowerItem>
  )
};

export const CoBorrowerList = ({stateCoBorrowers, setCoBorrowers}: {stateCoBorrowers: IContragentItem[], setCoBorrowers: any}) => {
  const {type} = useParams();
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const [stateDeleteCoBorrowers, setDeleteCoBorrowers] = React.useState<number[]>([]);
  const defaultCoBorrower = getPerson(agreementFormState, "coBorrower");

  React.useEffect(() => {
    if (!defaultCoBorrower || defaultCoBorrower?.length === stateCoBorrowers.length) return;
    setCoBorrowers(defaultCoBorrower.map((coBorrower, index) => ({number: index, isn: coBorrower?.isn || "", data: {share: coBorrower?.share}})))
    // eslint-disable-next-line
  }, [defaultCoBorrower?.length])

  const addCoBorrowerHandler = (ev) => {
    ev.preventDefault();
    const nextCoBorrower = stateCoBorrowers.length + stateDeleteCoBorrowers.length;
    setCoBorrowers([...stateCoBorrowers, {number: nextCoBorrower, isn: "", data: {}}]);
  };

  const deleteCoBorrowerHandler = (number: number) => {
    setDeleteCoBorrowers([...stateDeleteCoBorrowers, number])
  };
  const filterCoBorrowers = stateCoBorrowers.filter(coBorrower => !stateDeleteCoBorrowers.includes(coBorrower.number));
  return (
    <>
      {filterCoBorrowers.length === 0 &&
      <Button
          width="100%"
          isDisabled={type === "recalculation"}
          onClick={(ev) => addCoBorrowerHandler(ev)}
          appearance="transparent">
          Добавить созаемщика
      </Button>
      }
      {filterCoBorrowers.length !== 0 &&
      <StyledCoBorrowersWrapper>
        {filterCoBorrowers.map((coBorrower, index) => (
            <CoBorrower
              key={`coBorrower-${coBorrower.number}`}
              index={index}
              coBorrower={coBorrower}
              stateCoBorrowers={stateCoBorrowers}
              setCoBorrowers={setCoBorrowers}
              deleteHandler={() => deleteCoBorrowerHandler(coBorrower.number)}/>
          ))}
          <Button
              appearance="link"
              onClick={addCoBorrowerHandler}
              isDisabled={filterCoBorrowers.length === 5 || type === "recalculation"}
              iconBefore={<AddIcon/>}>
              Добавить созаемщика
          </Button>
      </StyledCoBorrowersWrapper>
      }
    </>
  )
}

export default CoBorrowerList;