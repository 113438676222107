import React                                  from 'react';
import Modal, {ModalProps}                    from "../../../../uiKit/modal";
import Table, {ITableHeadItem, ITableRowItem} from "../../../../uiKit/table";
import {useQuery}               from "@apollo/react-hooks";
import {DOCS_HISTORY}                   from "../../../../utils/queries";
import {useParams}                            from "react-router";
import {ErrorMessage}                         from "../../../../components/errorMessage";
import {StyledHistoryWrapper}                 from "./styles";

const headsList = ["Дата", "Событие", "Инициатор", "Описание"];

const head: ITableHeadItem = {
  cells: headsList.map((content, index) => ({key: `head-${index}`, content}))
};

export const ChangeLogModal = ({onClose, header, isOpen}: ModalProps) => {
  const {id} = useParams();

  const {data: dataHistory, loading: loadingHistory, error: errorHistory} = useQuery(DOCS_HISTORY, {variables: {isn: id}});

  const rows: ITableRowItem[] = (dataHistory?.activity || []).map((item, index) => {
    const {added, eventName, user, description} = item || {};
    return {
      key: `row-${index}`,
      cells: [
        {key: `date-${index}`, content: added},
        {key: `event-${index}`, content: eventName},
        {key: `initiation-${index}`, content: user?.name},
        {key: `description-${index}`, content: description}
      ]
    }
  })


  if (errorHistory) return <ErrorMessage error={errorHistory}/>

  return (
    <Modal width={130} onClose={onClose} isOpen={isOpen} header={header} isLoading={loadingHistory}>
      <StyledHistoryWrapper>
        <Table head={head} rows={rows}/>
      </StyledHistoryWrapper>
    </Modal>
  )
};

export default ChangeLogModal;