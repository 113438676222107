import React        from 'react';

export const LogoDesktop = () => (
    <svg width='15rem'
         height='3.2rem'
         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 32">
      <path d="M28.5437 0H21.3408V28.6422H28.5437V0Z" fill="#003D81"/>
      <path
        d="M21.3405 17.573C20.5977 19.198 19.3212 20.52 17.7232 21.3193C16.1252 22.1186 14.3018 22.3472 12.5559 21.9671C10.81 21.5869 9.24674 20.621 8.12572 19.2296C7.00471 17.8382 6.39343 16.1052 6.39343 14.3184C6.39343 12.5316 7.00471 10.7986 8.12572 9.40726C9.24674 8.01589 10.81 7.0499 12.5559 6.66976C14.3018 6.28962 16.1252 6.5182 17.7232 7.31753C19.3212 8.11687 20.5977 9.43884 21.3405 11.0639V1.89605C19.4396 0.820768 17.3177 0.195173 15.1375 0.0672486C12.9573 -0.060676 10.7768 0.312469 8.76319 1.15806C6.74959 2.00365 4.95642 3.29922 3.52124 4.94539C2.08605 6.59155 1.04699 8.54457 0.483746 10.6546C-0.0794953 12.7647 -0.151946 14.9757 0.27195 17.1182C0.695846 19.2606 1.60483 21.2774 2.92916 23.014C4.2535 24.7506 5.95801 26.1608 7.91193 27.1364C9.86586 28.112 12.0173 28.6271 14.2012 28.6422C16.7065 28.6508 19.1696 27.9967 21.3405 26.7461V17.573Z"
        fill="#41B6E6"/>
      <path
        d="M40.9062 24.7441C40.9062 25.5643 40.663 26.3661 40.2074 27.048C39.7517 27.73 39.104 28.2615 38.3463 28.5754C37.5885 28.8893 36.7547 28.9714 35.9502 28.8114C35.1458 28.6514 34.4069 28.2564 33.8269 27.6765C33.247 27.0965 32.852 26.3576 32.692 25.5531C32.532 24.7487 32.6141 23.9149 32.928 23.1571C33.2419 22.3994 33.7734 21.7517 34.4553 21.296C35.1373 20.8404 35.9391 20.5971 36.7593 20.5971C37.8587 20.5985 38.9127 21.0359 39.6901 21.8133C40.4675 22.5907 40.9048 23.6447 40.9062 24.7441Z"
        fill="#003D81"/>
      <path
        d="M85.5577 3.77625C87.9834 3.77625 90.1495 5.26449 90.1495 8.17744C90.1495 11.0904 87.9834 12.5786 85.5577 12.5786C83.132 12.5786 80.9658 11.0904 80.9658 8.17744C80.9658 5.26449 83.1479 3.77625 85.5577 3.77625ZM85.5577 5.6776C84.1859 5.6776 83.4392 6.89574 83.4392 8.17744C83.4392 9.45913 84.1965 10.6773 85.5577 10.6773C86.9188 10.6773 87.708 9.45913 87.708 8.17744C87.708 6.89574 86.9453 5.6776 85.5577 5.6776Z"
        fill="#003D81"/>
      <path
        d="M55.3301 16.4608C56.473 16.4525 57.6011 16.7195 58.6191 17.2393V18.6428C57.7042 18.0693 56.6428 17.7731 55.5631 17.7901C53.148 17.7901 50.627 19.2413 50.6111 22.5568C50.6111 25.6762 53.1957 27.1804 55.6161 27.1804C56.7007 27.2219 57.7723 26.9319 58.6879 26.3489V27.7524C57.6373 28.2761 56.4774 28.543 55.3036 28.5309C52.3801 28.5309 49.1546 26.4813 49.1917 22.5779C49.1917 18.5263 52.2953 16.4608 55.3301 16.4608Z"
        fill="#41B6E6"/>
      <path d="M61.6018 28.335V21.5611H58.6465V20.327H65.8706V21.5611H62.9365V28.335H61.6018Z" fill="#41B6E6"/>
      <path
        d="M71.866 20.1258C74.1487 20.1258 75.87 21.9265 75.87 24.331C75.895 24.8727 75.81 25.4139 75.6202 25.9218C75.4304 26.4298 75.1396 26.894 74.7654 27.2865C74.3912 27.679 73.9414 27.9916 73.4431 28.2055C72.9448 28.4194 72.4083 28.5301 71.866 28.531C71.2753 28.5463 70.6887 28.4286 70.1496 28.1866C69.6106 27.9446 69.1328 27.5844 68.7518 27.1327V32H67.4277V20.3482H68.63V21.6617C69.0185 21.1781 69.5114 20.7887 70.0719 20.5227C70.6323 20.2567 71.2457 20.121 71.866 20.1258ZM71.7124 21.3598C71.0808 21.3646 70.4642 21.5536 69.9383 21.9035C69.4125 22.2535 69.0001 22.7492 68.7518 23.33V25.332C68.9996 25.9106 69.4106 26.4045 69.9344 26.7534C70.4583 27.1024 71.0724 27.2912 71.7018 27.2969C72.0864 27.3029 72.4681 27.2291 72.8228 27.0802C73.1775 26.9314 73.4975 26.7107 73.7627 26.4321C74.0279 26.1534 74.2325 25.8229 74.3636 25.4613C74.4948 25.0997 74.5496 24.7149 74.5247 24.331C74.5496 23.9469 74.4947 23.5619 74.3636 23.2C74.2325 22.8381 74.028 22.5072 73.7629 22.2282C73.4978 21.9492 73.1778 21.728 72.8231 21.5785C72.4684 21.4291 72.0867 21.3546 71.7018 21.3598"
        fill="#41B6E6"/>
      <path
        d="M120.063 21.6617V20.3271H121.265V28.335H119.931V27.1327C119.551 27.5839 119.076 27.9443 118.539 28.1872C118.001 28.4301 117.417 28.5494 116.827 28.5363C114.539 28.5363 112.823 26.7302 112.823 24.331C112.823 21.9318 114.539 20.1258 116.827 20.1258C117.448 20.121 118.061 20.2567 118.621 20.5227C119.182 20.7887 119.675 21.1781 120.063 21.6617ZM114.158 24.3098C114.132 24.6937 114.186 25.0786 114.317 25.4405C114.447 25.8024 114.651 26.1333 114.916 26.4124C115.181 26.6915 115.5 26.9128 115.855 27.0623C116.209 27.2118 116.591 27.2863 116.976 27.281C117.606 27.2752 118.222 27.0858 118.746 26.7359C119.271 26.386 119.683 25.8908 119.931 25.3108V23.33C119.683 22.75 119.271 22.2548 118.746 21.905C118.222 21.5551 117.606 21.3656 116.976 21.3598C116.591 21.3546 116.209 21.4291 115.855 21.5786C115.5 21.728 115.181 21.9493 114.916 22.2285C114.651 22.5076 114.447 22.8385 114.317 23.2004C114.186 23.5622 114.132 23.9472 114.158 24.331"
        fill="#41B6E6"/>
      <path
        d="M84.1121 21.6617V20.3271H85.309V28.335H83.9744V27.1328C83.596 27.5838 83.1212 27.9442 82.5849 28.1871C82.0486 28.43 81.4646 28.5494 80.876 28.5363C78.5881 28.5363 76.8721 26.7302 76.8721 24.331C76.8721 21.9318 78.5828 20.1258 80.8707 20.1258C81.4919 20.1206 82.1062 20.2561 82.6675 20.5221C83.2289 20.7881 83.7227 21.1777 84.1121 21.6617ZM78.2067 24.3099C78.1811 24.6937 78.2352 25.0787 78.3657 25.4405C78.4962 25.8024 78.7002 26.1333 78.9649 26.4124C79.2296 26.6916 79.5493 26.9128 79.9037 27.0623C80.2581 27.2118 80.6397 27.2863 81.0243 27.2811C81.6543 27.2749 82.2689 27.0853 82.7928 26.7354C83.3167 26.3854 83.7273 25.8904 83.9744 25.3109V23.3301C83.7273 22.7505 83.3167 22.2555 82.7928 21.9056C82.2689 21.5556 81.6543 21.366 81.0243 21.3598C80.6397 21.3546 80.2581 21.4291 79.9037 21.5786C79.5493 21.7281 79.2296 21.9494 78.9649 22.2285C78.7002 22.5076 78.4962 22.8385 78.3657 23.2004C78.2352 23.5623 78.1811 23.9472 78.2067 24.331"
        fill="#41B6E6"/>
      <path
        d="M94.487 20.327L91.5529 24.2145L94.6565 28.335H93.1206L90.7849 25.1678L88.4334 28.335H86.8975L90.017 24.2145L87.0669 20.327H88.6029L90.7849 23.2823L92.9564 20.327H94.487Z"
        fill="#41B6E6"/>
      <path
        d="M98.9431 20.1258C101.125 20.1258 103.212 21.5982 103.212 24.331C103.212 27.0639 101.125 28.531 98.9431 28.531C96.761 28.531 94.7061 27.0639 94.7061 24.331C94.7061 21.5982 96.7928 20.1258 98.9431 20.1258ZM98.9431 21.3969C98.5521 21.3755 98.1611 21.4367 97.7954 21.5766C97.4297 21.7164 97.0976 21.9318 96.8207 22.2087C96.5439 22.4855 96.3285 22.8176 96.1886 23.1833C96.0487 23.549 95.9875 23.9401 96.0089 24.331C96.0089 25.1092 96.3181 25.8555 96.8683 26.4058C97.4186 26.956 98.1649 27.2651 98.9431 27.2651C99.7212 27.2651 100.468 26.956 101.018 26.4058C101.568 25.8555 101.877 25.1092 101.877 24.331C101.899 23.9401 101.837 23.549 101.698 23.1833C101.558 22.8176 101.342 22.4855 101.065 22.2087C100.789 21.9318 100.456 21.7164 100.091 21.5766C99.7251 21.4367 99.334 21.3755 98.9431 21.3969Z"
        fill="#41B6E6"/>
      <path
        d="M104.729 28.335V20.327H109.115C110.518 20.327 111.588 21.0791 111.588 22.4985C111.599 22.863 111.505 23.2228 111.317 23.5354C111.129 23.848 110.856 24.1002 110.529 24.2621C110.917 24.3913 111.254 24.6405 111.491 24.9737C111.728 25.307 111.853 25.7069 111.848 26.1158C111.848 27.4346 110.995 28.335 109.263 28.335H104.729ZM106.064 23.7113H109.004C109.703 23.7113 110.259 23.4465 110.259 22.6521C110.268 22.489 110.241 22.3258 110.18 22.1743C110.119 22.0229 110.025 21.8868 109.905 21.7759C109.785 21.665 109.642 21.5821 109.486 21.5331C109.33 21.484 109.165 21.47 109.004 21.4922H106.085L106.064 23.7113ZM106.064 27.1645H109.067C109.899 27.1645 110.502 26.9156 110.502 26.0311C110.502 25.1466 109.782 24.8659 109.115 24.8659H106.064V27.1645Z"
        fill="#41B6E6"/>
      <path
        d="M123.277 28.335V20.327H124.612V23.7113H129.246V20.327H130.586V28.335H129.246V24.9454H124.612V28.335H123.277Z"
        fill="#41B6E6"/>
      <path
        d="M132.462 28.335V20.327H133.797V26.1794L138.696 20.327H139.829V28.335H138.5V22.4773L133.595 28.335H132.462Z"
        fill="#41B6E6"/>
      <path
        d="M145.394 20.1258C148.482 20.1258 149.631 22.6468 149.102 24.8924H142.746C142.91 26.5766 144.43 27.2757 146.046 27.2757C146.968 27.2807 147.877 27.0588 148.694 26.6296V27.9272C147.805 28.3363 146.833 28.5356 145.855 28.5098C143.488 28.5098 141.422 27.1592 141.422 24.3098C141.395 23.771 141.477 23.2323 141.665 22.7263C141.852 22.2203 142.14 21.7577 142.511 21.3664C142.883 20.9751 143.33 20.6634 143.825 20.4501C144.321 20.2369 144.855 20.1265 145.394 20.1258ZM145.479 21.3598C144.113 21.3598 142.831 22.1807 142.746 23.7431H147.931C148.048 22.8957 147.513 21.3598 145.479 21.3598Z"
        fill="#41B6E6"/>
      <path
        d="M67.1629 4.48066C66.3666 4.49068 65.5854 4.70008 64.8908 5.08973C65.0356 4.71177 65.2651 4.37202 65.5616 4.09658C65.8582 3.82115 66.2139 3.61735 66.6015 3.50085C68.1057 2.97122 70.5525 2.86 70.9127 2.25623V0.195984C70.0971 0.990423 66.9776 0.799757 65.0126 2.06027C63.5774 2.97652 62.5075 5.1215 62.6505 8.21452C62.6505 11.048 64.7055 12.5786 67.1629 12.5786C69.5145 12.5786 71.6171 11.2069 71.6171 8.53229C71.6171 5.85768 69.4986 4.48066 67.1629 4.48066ZM67.0729 10.7038C66.7848 10.7175 66.497 10.6707 66.2282 10.5662C65.9594 10.4618 65.7155 10.302 65.5123 10.0973C65.3091 9.89263 65.1511 9.64756 65.0486 9.37797C64.9462 9.10838 64.9014 8.82027 64.9173 8.53229C64.9028 8.23917 64.948 7.94616 65.0502 7.67104C65.1524 7.39592 65.3094 7.14443 65.5117 6.93184C65.7141 6.71925 65.9575 6.54999 66.2272 6.43434C66.4969 6.31868 66.7874 6.25905 67.0808 6.25905C67.3743 6.25905 67.6647 6.31868 67.9345 6.43434C68.2042 6.54999 68.4476 6.71925 68.6499 6.93184C68.8523 7.14443 69.0093 7.39592 69.1115 7.67104C69.2137 7.94616 69.2589 8.23917 69.2444 8.53229C69.2596 8.82147 69.2139 9.11062 69.1102 9.38099C69.0064 9.65135 68.847 9.89688 68.6422 10.1016C68.4375 10.3064 68.1919 10.4658 67.9216 10.5696C67.6512 10.6733 67.3621 10.719 67.0729 10.7038Z"
        fill="#003D81"/>
      <path d="M117.32 12.3402V6.04827H114.667V4.01981H122.362V6.04827H119.714V12.3402H117.32Z" fill="#003D81"/>
      <path
        d="M109.655 3.77625C107.537 3.77625 105.604 4.92024 105.159 7.16585H103.845V4.01987H101.436V12.3403H103.845V9.18902H105.159C105.604 11.4346 107.542 12.5786 109.655 12.5786C112.081 12.5786 114.247 11.0904 114.247 8.17744C114.247 5.26449 112.081 3.77625 109.655 3.77625ZM109.655 10.6614C108.284 10.6614 107.537 9.44324 107.537 8.16155C107.537 6.87985 108.299 5.66171 109.655 5.66171C111.011 5.66171 111.8 6.87985 111.8 8.16155C111.8 9.44324 111.038 10.6614 109.655 10.6614Z"
        fill="#003D81"/>
      <path
        d="M97.0772 4.01981H92.9302L92.0033 9.27899C91.9926 9.43604 91.9488 9.58903 91.8745 9.72785C91.8003 9.86667 91.6975 9.98815 91.5728 10.0843C91.4482 10.1804 91.3045 10.249 91.1514 10.2854C90.9983 10.3219 90.8392 10.3255 90.6846 10.2959V12.3614L90.8064 12.3879C92.7448 12.7322 94.0212 11.6835 94.3708 9.71328L95.0169 6.04827H97.0772V12.3402H99.4711V4.01981H97.0772Z"
        fill="#003D81"/>
      <path
        d="M59.6796 12.3403H62.3966L57.4287 0.206604H54.6217L49.6855 12.3403H52.3337L53.3929 9.5545H58.578L59.6372 12.3403H59.6796ZM54.1609 7.52603C55.056 5.19568 55.9404 2.84414 55.9828 2.6164C56.0252 2.84414 56.9096 5.19568 57.7941 7.52603H54.1609Z"
        fill="#003D81"/>
      <path
        d="M77.4122 3.7603C78.2705 3.75419 79.1168 3.96168 79.875 4.36407V6.32369C79.2511 5.89747 78.512 5.67203 77.7565 5.67755C77.4185 5.65518 77.0795 5.70384 76.7615 5.82042C76.4435 5.93699 76.1534 6.11888 75.9099 6.35437C75.6664 6.58987 75.4749 6.87375 75.3478 7.18773C75.2207 7.50171 75.1608 7.83883 75.1719 8.17738C75.1659 8.51136 75.2281 8.84306 75.3546 9.15222C75.4811 9.46137 75.6693 9.74148 75.9078 9.97544C76.1462 10.2094 76.4298 10.3923 76.7413 10.513C77.0528 10.6336 77.3856 10.6895 77.7194 10.6772C78.5172 10.6987 79.3037 10.4851 79.9809 10.0628V12.0436C79.1201 12.4074 78.193 12.5878 77.2586 12.5733C74.9865 12.5733 72.7197 11.2598 72.7197 8.2939C72.7197 5.11614 75.1083 3.755 77.4122 3.755"
        fill="#003D81"/>
    </svg>
);
