import React                                       from "react";
import {StyledRoleByIdHeader, StyledRoleByIdTitle} from "./styles";
import Button                                      from "../../../../uiKit/button/button";
import {ArrowIcon}                                 from "../../../../icons/ui/Arrow";
import {useHistory, useParams}                     from "react-router";
import {Heading2}                                  from "../../../../globalStyledComponents";
import DeleteRoleModal                             from "../../../roles/components/rolesTab/deleteRoleModal";
import {StyledButtonsGroupAutoSize}                from "../../../roles/components/header/styles";
import FlyButtons                                  from "../../../../components/flyButtons";

export const RoleByIdHeader = ({name, readOnly, isLoading}: { name: string, readOnly: boolean, isLoading: boolean }) => {
  const {id} = useParams();
  const history = useHistory();
  const [stateDeleteRoleIsOpen, setDeleteRoleIsOpen] = React.useState<boolean>(false);

  return (
    <StyledRoleByIdHeader>
      <StyledRoleByIdTitle>
        <Button
          appearance="text"
          onClick={() => history.goBack()}
          iconBefore={<ArrowIcon direction="left"/>}>
          Роли и группы
        </Button>
        <Heading2>{name}</Heading2>
      </StyledRoleByIdTitle>


      {!readOnly &&
      <FlyButtons isWhiteBackground={true}>
        <StyledButtonsGroupAutoSize>
          <Button isLoading={isLoading} type="submit" form="roleByIdChangeForm" appearance="filled">Сохранить роль</Button>
          <Button isLoading={isLoading} onClick={() => setDeleteRoleIsOpen(true)} appearance="transparent">Удалить роль</Button>
        </StyledButtonsGroupAutoSize>
        <DeleteRoleModal onClose={() => setDeleteRoleIsOpen(false)} isOpen={stateDeleteRoleIsOpen} data={{id}}/>
      </FlyButtons>}
    </StyledRoleByIdHeader>
  )
};

export default RoleByIdHeader;