import React               from "react";
import Modal, {ModalProps} from "../../../../uiKit/modal";
import Declaration         from "../insuranceStep/components/declaration";
import StoreAgreementForm  from "../../store";
import {getPerson}         from "../../../../helpers/buildTemplateForBack";
import {useQuery} from "@apollo/react-hooks";
import {ME} from "../../../../utils/queries";

export const DeclarationModal = ({header, isOpen, onClose, data}: ModalProps) => {
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const {openSaveModal, afterProlongation, clientIsn, phoneMobile} = data;
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {skipDeclarationBanks} = (dataUser || {}).me || {};
  const bankIsn = agreementFormState?.creditOrganizationName?.value;
  const isAllowedNoSmsForBank = skipDeclarationBanks && skipDeclarationBanks.includes(bankIsn);
  return (
    <Modal
      width={60}
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <Declaration
          isModal={true}
          onClose={onClose}
          openSaveModal={openSaveModal}
          person={afterProlongation ? { phoneMobile, isn: clientIsn } : getPerson(agreementFormState, "contragent")}
          afterProlongation={afterProlongation} clientIsn={clientIsn}
          isAllowedNoSmsForBank={isAllowedNoSmsForBank}
      />
    </Modal>
  )
};

export default DeclarationModal;