import React                      from "react";
import {StyledStatusNotification} from "./styles";
import StoreStatusNotification    from "./store";
import {Text2}                    from "../../globalStyledComponents";
import {ModalDialogError}         from "../../icons/ui/ModalDialogError";
import {ModalDialogDone}          from "../../icons/ui/ModalDialogDone";
import {Loader}                   from "../../uiKit/loader";
import {AttentionIcon}            from "../../icons/ui/Attention";
import Button from "../../uiKit/button/button";
import {CloseIcon} from "../../icons/ui/Close";

export const StatusNotification = () => {
  const [stateIsShow, setIsShow] = React.useState<boolean>(false)
  const [{statusNotification: {statusNotificationState: {type, text}}}, {statusNotification: actionsStatusNotification}]: any = StoreStatusNotification();
  const hideNotification = () => actionsStatusNotification.setState({
    statusNotificationState: {
      type: undefined,
      text: undefined
    }
  });

  React.useEffect(() => {
    if (!text || !type || stateIsShow) return;
    setIsShow(true)
    // eslint-disable-next-line
  }, [text])

  React.useEffect(() => {
    if (!stateIsShow) {
      // @ts-ignore
      window.lastNotificationType = undefined;
    }
    // @ts-ignore
    window.lastNotificationType = type;
    setTimeout(() => {
      setIsShow(false)
      setTimeout(() => {
        hideNotification()
      }, 300)
    }, type === 'error' ? 500000 : 5000)
    // eslint-disable-next-line
  }, [stateIsShow])

  const textHtml = text && text.includes("<br/>") ? text.split("<br/>").map((text, index) => <>{text}<br/></>) : text;

  return (
    <StyledStatusNotification isShow={stateIsShow}>
      {type === "success" && <ModalDialogDone/>}
      {type === "error" && <ModalDialogError/>}
      {type === "warning" && <AttentionIcon/>}
      {type === "loading" && <Loader loaderWidth={3} type="simple"/>}
      <Text2>{textHtml}</Text2>
      {type === 'error' &&
        <Button appearance='icon' icon={<CloseIcon />} onClick={() => setTimeout(() => {
          hideNotification();
          setIsShow(false);
        },  50)} />}
    </StyledStatusNotification>
  )
};

export default StatusNotification;