import styled  from "styled-components";
import {white} from "../../../../../../globalStyledComponents";

export const StyledPrecalculationForm = styled.div`
  background: ${white};
  padding: 4rem;
  width: calc(100% - 8rem);
  
  @media screen and (max-width: 767px) {
    padding: 2rem 1.5rem;
    width: calc(100% - 3rem);
  }
`;

export const StyledFormField = styled.div<{margin?: number}>`
  div[class^="Field"] {
    margin: 0;
  }
  > p {
   opacity: 0.5; 
   margin-bottom: 1.5rem;
  }
  ${({margin}) => `margin-bottom: ${typeof margin === "undefined" ? 3 : margin}rem;`} 
  
  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;

export const StyledRisksFormWrapper = styled.div`
  h4 {
    margin: 6rem 0 2rem 0; 
  }
  
  @media screen and (max-width: 767px) {
      h4 {
    margin: 4rem 0 2rem 0; 
  }
  }
`;
