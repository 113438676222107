import React                                                    from "react";
import Modal, {ModalProps}                                      from "../../../../../../../uiKit/modal";
import {Heading3, Text1, TextButton}                            from "../../../../../../../globalStyledComponents";
import {StyledCuratorContactsWrapper, StyledCuratorContactItem} from "./styles";

export const CuratorContactsModal = ({header, isOpen, onClose, data}: ModalProps) => {
  const {name, phone, email} = data || {};
  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <StyledCuratorContactsWrapper>
        <StyledCuratorContactItem>
          <TextButton>Ваш куратор</TextButton>
          <Heading3>{name}</Heading3>
        </StyledCuratorContactItem>
        <StyledCuratorContactItem>
          <TextButton>Телефон</TextButton>
          <Text1>{phone}</Text1>
        </StyledCuratorContactItem>
        <StyledCuratorContactItem>
          <TextButton>E-mail</TextButton>
          <Text1>{email}</Text1>
        </StyledCuratorContactItem>
        <TextButton>с 09:00 до 18:00 по московскому времени, пн-пт</TextButton>
      </StyledCuratorContactsWrapper>
    </Modal>
  );
};

export default CuratorContactsModal;