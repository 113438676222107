import React                       from "react";
import {StyledAuthorizationLayout} from "./styles";
import LoginForm                   from "./components/loginForm";
import Features                    from "./components/features";

export const Authorization = () => {
  return (
    <>
      <StyledAuthorizationLayout>
        <LoginForm/>
        <Features/>
      </StyledAuthorizationLayout>
    </>
  )
};

export default Authorization;