import React          from "react";
import {useLazyQuery} from '@apollo/react-hooks';
import {DICT}         from "../utils/queries";

type DictNames =
  "gender"
  | "agreementTypes"
  | "objectTypes"
  | "jobPlace"
  | "familyStatus"
  | "pledgeType"
  | "wallMaterial"
  | "risks"
  | "saleChannel"
  | "activity"
  | "nationality"
  | "banks"
  | "sbpBanks"
  | "currency"
  | "insuranceCompany"
  | "statusList"
  | "tariffGroup"
  | "program"

const disabledBanks: string[] = ["877421", "832481", "877411", "858891", "858901"];
interface IDictItem {
  label: string
  value: string
}
export const useGetDict = (name: DictNames) => {
  const [getDict, {data: dataDict, loading: loadingDict, error: errorDict}] = useLazyQuery(DICT);
  const dict: IDictItem[] = ((dataDict || {}).dictionary || []).map(({label, value}) => ({
    value,
    label
  }));

  const filterDict: IDictItem[] = name === "banks" ? dict.filter(({value}) => !disabledBanks.includes(value)) : dict;

  React.useEffect(() => {
    getDict({
      variables: {
        name
      }
    })
    // eslint-disable-next-line
  }, [])

  if (errorDict) {
    return {
      data: filterDict,
      loading: true,
      error: errorDict
    }
  }

  return {
    data: filterDict,
    loading: loadingDict,
    error: errorDict
  }
};