export type NotificationTypes = "success" | "error" | "loading" | "warning" | undefined

export interface StatusNotificationState {
  type: NotificationTypes
  text: string | undefined

}
export const statusNotificationState: StatusNotificationState = {
  type: undefined,
  text: undefined
};
