import React                         from "react";
import {getFormError}                from "../../../../../../formUtils/getFormError";
import {getClearPhone, getFormValue} from "../../../../../../formUtils/getFormValue";
import FieldsBuilder                 from "../../../../../../uiKit/fieldsBuilder";
import Button                        from "../../../../../../uiKit/button/button";
import {IFormField}                  from "../../../../../../uiKit/fieldsBuilder/interfaces";
import Form                          from '@atlaskit/form';
import {useMutation}                 from "@apollo/react-hooks";
import {AUTH}                        from "../../../../../../utils/mutations";
import {useHistory}                  from "react-router";
import {useCallNotification}         from "../../../../../../hooks/useCallNotification";
import {client}                      from "../../../../../../Apollo";

const {JSEncrypt} = require('jsencrypt');
const publicPasswordKey = process.env.REACT_APP_PASSWORD_KEY
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicPasswordKey);

const loginForm: IFormField[] = [
  {
    fieldItems: {
      type: "input",
      name: "login",
      mask: "+7 (999) 999-99-99",
      placeholder: "Телефон",
      isRequired: true,
      errorMessage: "Введите телефон"
    }
  },
  {
    fieldItems: {
      type: "input",
      name: "password",
      inputType: "password",
      placeholder: "Пароль",
      isRequired: true,
      errorMessage: "Введите пароль",
      validationType: "password"
    }
  },
]

export const LoginClient = ({setRecoveryPassword}: { setRecoveryPassword: (value: boolean) => void }) => {
  const history = useHistory();
  const {setNotification} = useCallNotification();
  const [userAuthorization, {loading: loadingUserAuthorization}] = useMutation(AUTH);

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    client.resetStore();
    const {login, password} = getFormValue(data);
    userAuthorization({
      variables: {
        isClient: true,
        login: getClearPhone(login),
        password: encrypt.encrypt(password)
      }
    }).then(({data: {signIn: {id}}}) => {
      if (!id) return setNotification({type: "error", text: "Отсутствует roleIsn"})
      localStorage.setItem("rolesIsn", id);
      history.push("/myPolicies");
    })
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={loginForm}/>
            <Button isLoading={loadingUserAuthorization} type="submit" width="100%" appearance="filled">
              Войти
            </Button>
          </form>
        )}
      </Form>
      <Button onClick={() => setRecoveryPassword(true)} appearance="link">Забыли пароль?</Button>
    </>
  )
};

export default LoginClient;