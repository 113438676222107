import styled             from "styled-components";
import {greyNotification} from "../../globalStyledComponents";

export const StyledStatusNotification = styled.div<{isShow: boolean}>`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background: ${greyNotification};
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  > p {
    white-space: nowrap;
    margin-left: 1rem;
  }
  > svg {
    width: 3rem;
    height: 3rem;
  }
  z-index: 31;
  transition: bottom 0.3s;
  position: fixed;
  right: 2rem;
  ${({isShow}) => 
    isShow ?
      `
      bottom: 1.5rem;
      `
      :
      `
      bottom: -10rem;
      `
  }
`;