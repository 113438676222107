import React                       from 'react';
import Modal, {ModalProps}         from "../../../uiKit/modal";
import {Heading3}                  from "../../../globalStyledComponents";
import Button                      from "../../../uiKit/button/button";
import {useCallNotification}       from "../../../hooks/useCallNotification";
import {StyledDeleteRoleModalBody} from "../../roles/components/rolesTab/styles";
import {StyledButtonsGroup}        from "../../roles/components/header/styles";
import {useMutation}               from "@apollo/react-hooks";
import {DELETE_DOC}                from "../../../utils/mutations";


export const DeleteDocModal = ({onClose, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id, type} = data || {};
  const [deleteDoc, {loading: loadingDeleteDoc}] = useMutation(DELETE_DOC, {refetchQueries: ["docs"]});
  const deleteDocHandler = () => {
    deleteDoc({
      variables: {
        id
      }
    }).then(() => onClose())
      .catch(error => setNotification({type: "error", text: error}))
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen} header={undefined}>
      <StyledDeleteRoleModalBody>
        <Heading3>Вы действительно хотите удалить {type}?</Heading3>
        <StyledButtonsGroup>
          <Button appearance="filled" onClick={deleteDocHandler} isLoading={loadingDeleteDoc}>
            Да
          </Button>
          <Button onClick={() => onClose()} appearance="transparent" isLoading={loadingDeleteDoc}>
            Нет
          </Button>
        </StyledButtonsGroup>
      </StyledDeleteRoleModalBody>
    </Modal>
  )
};

export default DeleteDocModal;