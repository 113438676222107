import React                                                              from "react";
import {ISelectItem}                                                      from "../../../../uiKit/select";
import {IFormField}                                                       from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                                                      from "../../../../uiKit/fieldsBuilder";
import OnlineFillingForm                                                  from "./components/onlineFilling";
import UploadFileFillingForm                                              from "./components/uploadFilesFilling";
import SendToClientFillingForm                                            from "./components/sendToClientFilling";
import {IStepsProviderProps}                                              from "../stepsProvider";
import StepsLayout                                                        from "../stepsLayout";
import StepsPaginationButton                                              from "../stepsPaginationButton";
import StoreAgreementForm                                                 from "../../store";
import {StyledBorrowerFooter, StyledBorrowerItem, StyledBorrowersWrapper} from "./styles";
import {Heading4, TextButton}          from "../../../../globalStyledComponents";
import Status                          from "../../../../uiKit/status";
import Button                          from "../../../../uiKit/button/button";
import {StyledPaginationButtonWrapper} from "../busynessStep/styles";
import {AttentionIcon}                 from "../../../../icons/ui/Attention";
import {OkIcon}                        from "../../../../icons/ui/Ok";
import OnlineFillingCoBorrowersForm              from "./components/onlineFillingCoBorrower";
import {checkDeclaration, getRestrictionsObject} from "../../../../helpers/buildTemplateForBack";
import {useQuery}                                from "@apollo/react-hooks";
import {ME}                            from "../../../../utils/queries";
import {ErrorMessage}                  from "../../../../components/errorMessage";
import Declaration                     from "./components/declaration";

export const AnketaPaginationButtons = ({onClose, isDisabled, isLoading, formId}: { onClose?: () => void, formId?: string, isDisabled?: boolean, isLoading?: boolean }) => {
  const close = <Button width="100%" isLoading={isLoading} onClick={onClose} appearance="transparent">Вернуться
    назад</Button>;
  const save = <Button width="100%" appearance="filled" isDisabled={isDisabled} isLoading={isLoading} form={formId}
                       type="submit">Сохранить данные</Button>;
  return (
    <StyledPaginationButtonWrapper>
      {close}
      {save}
    </StyledPaginationButtonWrapper>
  )
};

const getAnketaStatus = (fillingType: string | undefined) => {
  switch (fillingType) {
    case "send":
      return "filledSend";
    case "upload":
      return "filledUpload";
    case "online":
      return "filledOnline";
    case "declaration":
      return "filledDeclaration";
    default:
      return "notFilled";
  }
}

export const InsuranceStep = (props: IStepsProviderProps) => {
  const {title, changeStep, step, isAgreementEdit, editType, addRisksSteps} = props;
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const [stateActivePerson, setActivePerson] = React.useState<string | undefined>(undefined);
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {peoples} = agreementFormState || {};
  const activePerson = (peoples || []).find(({isn}) => isn === stateActivePerson) || {};
  const isSaveImmediately: boolean = isAgreementEdit && editType === "addRisks" ? !addRisksSteps.includes("riskProperty") && !addRisksSteps.includes("riskTitle") : isAgreementEdit;
  const isAllFilled: boolean = peoples?.filter(({type}) => type !== "insured").length === peoples?.reduce((prev, {fillingType}) => !!fillingType ? [...prev, fillingType] : prev, []).length
  const {fullName, type} = activePerson || {};
  const {restrictions, skipDeclarationBanks} = (dataUser || {}).me || {};
  const bankIsn = agreementFormState?.creditOrganizationName?.value;
  const isSberbank = (bankIsn == "950291");
  const isAllowedNoSmsForBank = skipDeclarationBanks.includes(bankIsn);

  const {riskLife, riskProperty} = agreementFormState || {};
  const insuranceSelectItems: ISelectItem[] = [
    {label: "Не выбран", value: "none"},
    {label: "Заполнить онлайн", value: "online"},
    {label: "Загрузка документов", value: "upload"},
    {label: "Отправить анкету клиенту", value: "send"},
    ...(checkDeclaration(getRestrictionsObject(restrictions), agreementFormState) ? [{label: "Декларация", value: "declaration"}] : [])
  ]
  const [stateInsuranceSelect, setInsuranceSelect] = React.useState<ISelectItem>(insuranceSelectItems[0]);

  const personProps = {
    ...props,
    person: activePerson,
    onClose: () => {
      setActivePerson(undefined)
      setInsuranceSelect(insuranceSelectItems[0])
    }
  }

  const selectForm: IFormField[] = [
    {
      fieldItems: {
        type: "select",
        name: "typeOfFilling",
        placeholder: "Тип заполнения",
        defaultValue: stateInsuranceSelect,
        items: insuranceSelectItems,
        onChange: value => setInsuranceSelect(value)
      }
    }
  ];

  const nextStepHandler = () => changeStep(step + 1);

  if (errorUser) return <ErrorMessage error={errorUser}/>
  return (
    <>
      {!stateActivePerson && <>
          <StepsLayout title={title}>
              <StyledBorrowersWrapper>
                {(peoples || []).filter(({type}) => type !== "insured").map(({
                                                                               isn,
                                                                               fullName,
                                                                               type,
                                                                               fillingType
                                                                             }, index) => {
                  const isDisabled: boolean = !!fillingType && (fillingType === "online" || fillingType === "declaration");
                  return (
                      <StyledBorrowerItem
                        isDisabled={isDisabled}
                        key={`borrower-${index}`}
                        onClick={() => {
                          if (isDisabled) return;
                          setActivePerson(isn)
                        }}>
                        <Heading4>{fullName}</Heading4>
                        <TextButton>{type === "contragent" ? "Заемщик" : "Созаемщик"}</TextButton>
                        <StyledBorrowerFooter>
                          <Status hideIcon status={getAnketaStatus(fillingType)}/>
                          {!!fillingType ? <OkIcon/> : <AttentionIcon/>}
                        </StyledBorrowerFooter>
                      </StyledBorrowerItem>
                    )
                  }
                )}
              </StyledBorrowersWrapper>
          </StepsLayout>
          <StepsPaginationButton formId={"undefined"} saveImmediately={isSaveImmediately} onNextStep={nextStepHandler}
                                 isDisabled={!isAllFilled}/>
      </>}
      {stateActivePerson && <>
          <StepsLayout title={`${fullName} заполнение анкеты`}>
              <FieldsBuilder formFields={selectForm}/>
          </StepsLayout>
        {stateInsuranceSelect.value === "online" && type !== "coBorrower" && <OnlineFillingForm {...personProps}/>}
        {stateInsuranceSelect.value === "online" && type === "coBorrower" &&
        <OnlineFillingCoBorrowersForm {...personProps}/>}
        {stateInsuranceSelect.value === "upload" && <UploadFileFillingForm  {...personProps}/>}
        {stateInsuranceSelect.value === "send" && <SendToClientFillingForm {...personProps}/>}
        {stateInsuranceSelect.value === "declaration" &&
            <Declaration
                {...personProps}
                isHideAnketa={true}
                isSberbank={isSberbank && riskLife && !riskProperty || isAllowedNoSmsForBank}
            />}
        {stateInsuranceSelect.value === "none" &&
        <AnketaPaginationButtons onClose={() => setActivePerson(undefined)}
                                 isDisabled={stateInsuranceSelect.value === "none"}/>
        }
      </>}
    </>
  )
}

export default InsuranceStep;