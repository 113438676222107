import React                                  from "react";
import MyAgreementHeader                      from "./components/header";
import {StyledAgreementLogWrapper}            from "../agreementsLog/styles";
import Table, {ITableHeadItem, ITableRowItem} from "../../uiKit/table";
import {useLazyQuery, useQuery}               from "@apollo/react-hooks";
import {GET_MY_AGREEMENT, ME}                 from "../../utils/queries";
import {ErrorMessage}                         from "../../components/errorMessage";
import Status                                 from "../../uiKit/status";
import {useHistory}                           from "react-router-dom";

const headsList = ["№ договора", "тип", "стоимость", "Дата создания", "Дата окончания", "Статус"];

const head: ITableHeadItem = {
  cells: headsList.map((content, index) => ({key: `head-${index}`, content}))
};

export const MyAgreements = () => {
  const history = useHistory();
  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {ISN: isnPerson} = ((dataUser || {}).me || {}).kias || {};
  const [getMyAgreements, {data: dataMyAgreements, loading: loadingMyAgreements, error: errorMyAgreements}] = useLazyQuery(GET_MY_AGREEMENT);
  const myAgreements: any[] = dataMyAgreements?.getCounterAgentPolicy || [];

  React.useEffect(() => {
    if (!isnPerson) return;
    getMyAgreements({variables: {
        contragentIsn: isnPerson
      }})
    // eslint-disable-next-line
  }, [isnPerson]);

  const rows: ITableRowItem[] = myAgreements.map(({IDs, ISN, PolicySum, DateBeg, DateEnd, Status: status, ProdName}, index) => {
    return {
        key: `row-${ISN}-${index}`,
        id: ISN,
        onClick: () => {
          if (ProdName !== "Ипотечное страхование (Многолетнее)") return;
          return history.push(`/myAgreement/${ISN}`)
        },
        cells: [
          // {key: `cell-${isn}`, content: isn},
          {key: `cell-${ISN}-${index}`, content: IDs},
          {key: `cell-${ISN}-${index}`, content: ProdName},
          {key: `cell-${ISN}-${index}`, content: PolicySum},
          {key: `cell-${ISN}-${index}`, content: DateBeg},
          {key: `cell-${ISN}-${index}`, content: DateEnd},
          {key: `cell-${ISN}-${index}`, content: <Status status={status}/>},
        ]
      }
  })

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorMyAgreements) return <ErrorMessage error={errorMyAgreements}/>

  console.log("dataMyAgreements", myAgreements)
  return (
    <StyledAgreementLogWrapper>
      <MyAgreementHeader/>
      <Table
        emptyViewMessage="Нет договоров"
        isLoading={loadingMyAgreements}
        head={head}
        rows={rows}
      />
    </StyledAgreementLogWrapper>
  )
}