import {BORROWER_ALLOWANCE_ISN, BORROWER_SUB_CLASS_ISN, DISCOUNT_ISN, getValueFromObjectsAttributes, HEIGHT_WEIGHT_ISN, PRESSURE_ISN, SHARE_ISN} from "../../../../helpers/buildTemplateForBack";
import {IFormField}                                                                                                                                                                  from "../../../../uiKit/fieldsBuilder/interfaces";
import {StyledObjectHeader}                                                                                                                      from "../../styles";
import {Heading4}                                                                                                                                from "../../../../globalStyledComponents";
import Button                                                                                                                                    from "../../../../uiKit/button/button";
import {TrashIcon}                                                                                                                               from "../../../../icons/ui/Trash";
import FieldsBuilder                                                                                                                             from "../../../../uiKit/fieldsBuilder";
import React                                                                                                                                     from "react";
import {IObjectProps}                                                                                                                            from "../../index";
import {stringToNumber}                                                                                                                          from "../../../../formUtils/getFormValue";

export const BorrowerObject = ({object, index, onDelete, isDisabledDelete, isDisabled, changeIsPreCalcNeeded, maxDiscount}: IObjectProps) => {
  const {SubClassName: type, SubjFullName: name, SubClassISN, AGROBJECT_ADDATTR: attributes, AGRCOND: agrCond} = object || {};
  const {row: rowAttributes} = attributes || {}
  const {row: rowAgrCond} = agrCond || {}
  const {PremiumSum: premium, LimitSum: summ, Tariff: tariff} = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} : rowAgrCond || {}
  const share = ((rowAttributes || []).find(({ATTRISN}) => ATTRISN === SHARE_ISN) || {}).VALUE;
  const discount: number = stringToNumber(getValueFromObjectsAttributes(object, DISCOUNT_ISN));
  const allowance: number = stringToNumber(getValueFromObjectsAttributes(object, BORROWER_ALLOWANCE_ISN));
  const heightWeight: number = stringToNumber(getValueFromObjectsAttributes(object, HEIGHT_WEIGHT_ISN));
  const pressure: number = stringToNumber(getValueFromObjectsAttributes(object, PRESSURE_ISN));

  const form: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: `borrowerType-${index}`,
          // isRequired: true,
          // errorMessage: "",
          placeholder: "Тип",
          isDisabled: true,
          defaultValue: type
        },
        {
          type: "range",
          name: `borrowerShare-${index}`,
          // isRequired: true,
          // errorMessage: "",
          placeholder: `Доля ${SubClassISN === BORROWER_SUB_CLASS_ISN ? "заемщика" : "созаемщика"} 1 в общем доходе, %`,
          startValue: 0,
          endValue: 100,
          isDisabled: true,
          defaultValue: share
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "fio",
          name: `borrowerName-${index}`,
          validationType: "fullName",
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: `ФИО ${SubClassISN === BORROWER_SUB_CLASS_ISN ? "заемщика" : "созаемщика"}`,
          defaultValue: {value: name}
        },
        {
          type: "number",
          name: `borrowerSumm-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          digitsAfterDot: 2,
          placeholder: "Страховая сумма",
          defaultValue: summ
        },
      ]
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "number",
          name: `heightWeight-${index}`,
          defaultValue: heightWeight,
          isDisabled,
          placeholder: "Повышающий коэф (рост/вес) %",
          errorMessage: "Введите повышающий коэф",
          onChange: (value) => {
            if (Number(value) === Number(heightWeight)) return
            changeIsPreCalcNeeded()
          },
          digitsAfterDot: 2
        },
        {
          type: "number",
          name: `pressure-${index}`,
          defaultValue: pressure,
          isDisabled,
          onChange: (value) => {
            if (Number(value) === Number(pressure)) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Повышающий коэф давление %",
          errorMessage: "Введите повышающий коэф",
          digitsAfterDot: 2
        }
      ]
    },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "number",
          name: `discount-${index}`,
          defaultValue: discount,
          isDisabled,
          placeholder: "Скидка",
          errorMessage: "Введите скидку",
          onChange: (value) => {
            if (Number(value) === Number(discount)) return
            changeIsPreCalcNeeded()
          },
          digitsAfterDot: 2,
          validationType: "minMax",
          maxValue: 100
        },
        {
          type: "number",
          name: `allowance-${index}`,
          isDisabled,
          defaultValue: allowance,
          onChange: (value) => {
            if (Number(value) === Number(allowance)) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Повышающий коэф %",
          errorMessage: "Введите повышающий коэф",
          digitsAfterDot: 2
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "number",
          name: `borrowerTariff-${index}`,
          // isRequired: true,
          // errorMessage: "",
          digitsAfterDot: 4,
          isDisabled: true,
          placeholder: "Тариф",
          defaultValue: tariff
        },
        {
          type: "number",
          name: `borrowerPremium-${index}`,
          // isRequired: true,
          // errorMessage: "",
          digitsAfterDot: 2,
          isDisabled: true,
          placeholder: "Премия",
          defaultValue: premium
        },
      ]
    },
  ]

  return (
    <>
      <StyledObjectHeader>
        <Heading4>{SubClassISN === BORROWER_SUB_CLASS_ISN ? "Заемщик" : "Созаемщик"} {index + 1}</Heading4>
        <Button
          appearance="icon"
          isDisabled={isDisabledDelete || isDisabled}
          onClick={ev => {
            ev.preventDefault();
            onDelete()
          }} icon={<TrashIcon/>}/>
      </StyledObjectHeader>
      <FieldsBuilder formFields={form}/>
    </>
  )
}