import {IRadioItem}     from "../../uiKit/radio";

// radios

export const radioSex: IRadioItem[] = [
  {label: "Мужской", value: "1"},
  {label: "Женский", value: "0"},
];

export const radioJobType: IRadioItem[] = [
  {label: "Офис", value: true},
  {label: "Не офис", value: false},
];

export const radioBoolean: IRadioItem[] = [
  {label: "Да", value: true},
  {label: "Нет", value: false},
];

export const radioTitle: IRadioItem[] = [
  {label: "Менее 3-х лет", value: 1},
  {label: "Более 3-х лет", value: 5},
];