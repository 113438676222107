import {gql} from '@apollo/client';

export const AUTH = gql`
mutation signIn($login: String! $password: String! $isClient: Boolean) {
  signIn(login: $login password: $password isClient: $isClient) {
    id
    isAdmin
    type
    role {
      id
      name
      functions {
        id
        tag
      }
    }
  }
}
`;

export const UPDATE_ANKETA = gql`
mutation anketa($isn: isn $clientIsn: isn $settings: [object] $parts: [object] $templateVariables: object) {
  anketa(isn: $isn clientIsn: $clientIsn settings: $settings parts: $parts templateVariables: $templateVariables) {
    isn
    clientIsn
    settings
    parts
  }
} 
`;

export const VERIFY_ANKETA = gql`
mutation anketaVerify($isn: isn $clientIsn: isn!){
  anketaVerify(isn: $isn clientIsn: $clientIsn)
}
`;

export const SIGN_ANKETA = gql`
mutation anketaSign($isn: isn! $code: String! $declMode: Boolean, $afterProlong: Boolean, $isDeclarationForm: Boolean) {
  anketaSign(isn: $isn code: $code declMode: $declMode, afterProlong: $afterProlong, isDeclarationForm: $isDeclarationForm)
}
`;

export const CREATE_UPDATE_ROLE = gql`
mutation role($id: ID, $name: String!, $group: ID, $functions: [ID]) {
  role(id: $id, name: $name, group: $group, functions: $functions) {
    id
  }
}
`;

export const DELETE_ROLE = gql`
mutation deleteRole($id: ID!) {
  deleteRole(id: $id)
}
`;
export const DELETE_GROUP = gql`
mutation deleteGroup($id: ID!) {
  deleteGroup(id: $id)
}
`;

export const UPDATE_DOC = gql`
mutation document($isn: isn, $type: String = "C", $data: object) {
  document(isn: $isn, type: $type, data: $data) {
    isn
  }
}
`;

export const CREATE_UPDATE_USER = gql`
mutation user(
  $id: ID
  $login: String
  $email: String
  $phone: String
  $password: String
  $name: String
  $role: ID
  $group: ID
  $lead: Boolean
  $isSuper: Boolean
  $showKv: Boolean
  $isEnabled: Boolean
  
) {
  user(
    id: $id
    login: $login
    email: $email
    phone: $phone
    password: $password
    name: $name
    role: $role
    group: $group
    lead: $lead
    isSuper: $isSuper
    show_kv: $showKv
    is_enabled: $isEnabled
  ) {
    id
  }
}
`;

export const CREATE_UPDATE_GROUP = gql`
mutation group($id: ID, $name: String, $email: String, $leader: ID, $parent: ID, $curator: ID) {
  group(id: $id, name: $name, email: $email, leader: $leader, parent: $parent, curator: $curator) {
    id
  }
}
`;

export const DELETE_USER = gql`
mutation userDelete($id: ID!) {
  userDelete(id: $id)
}
`;

export const USER_CHANGE_PASSWORD = gql`
mutation userPassword($password: String, $passwordNew: String) {
  userPassword(password: $password, passwordNew: $passwordNew)
}
`;

export const NOTIFICATION = gql`
mutation notification($isn: isn, $type: NotificationTypeEnum, $files: [String], $city: object) {
  notification(isn: $isn, type: $type, files: $files, city: $city)
}
`;


export const UPDATE_CONTRAGENT = gql`
mutation contragent(
  $isn: isn
  $fullName: String
  $firstName: String
  $lastName: String
  $parentName: String
  $shortName: String
  $latName: String
  $isLegal: booleanYN
  $gender: gender
  $classIsn: isn
  $className: String
  $isVip: booleanYN
  $isResident: booleanYN
  $inn: String
  $kpp: String
  $countryIsn: isn
  $ogrn: String
  $orgFormIsn: isn
  $phoneMobile: String
  $email: String
  $birthDate: date
  $addr1: inputAddress
  $authDocClassIsn: isn
  $authDocClassName: String
  $authDocNumber: String
  $authDocSer: String
  $authDocDate: date
  $authDocIssuer: String
  $authDocIssuerCode: String
) {
  contragent(
    isn: $isn
    fullName: $fullName
    firstName: $firstName
    lastName: $lastName
    parentName: $parentName
    shortName: $shortName
    latName: $latName
    isLegal: $isLegal
    gender: $gender
    classIsn: $classIsn
    className: $className
    isVip: $isVip
    isResident: $isResident
    inn: $inn
    kpp: $kpp
    countryIsn: $countryIsn
    ogrn: $ogrn
    orgFormIsn: $orgFormIsn
    phoneMobile: $phoneMobile
    email: $email
    birthDate: $birthDate
    addr1: $addr1
    authDocClassIsn: $authDocClassIsn
    authDocClassName: $authDocClassName
    authDocNumber: $authDocNumber
    authDocSer: $authDocSer
    authDocDate: $authDocDate
    authDocIssuer: $authDocIssuer
    authDocIssuerCode: $authDocIssuerCode
  ) {
    isn
  }
}
`;

export const RECOVERY_PASSWORD = gql`
mutation recovery($login: String!) {
  recovery(login: $login)
}
`;

export const RECOVERY_PASSWORD_CLIENT = gql`
mutation clientRecovery($phone: String, $email: String, $login: String) {
  clientRecovery(phone: $phone, email: $email, login: $login)
}
`;

export const VIEW_COMMENTS = gql`
mutation viewComment($id: [ID]!) {
  viewComment(id: $id) {
    id
  }
}
`;


export const SAVE_ADDRESS = gql`
mutation setAddrObject($isn: isn, $daData: json, $addrType: String) {
  setAddrObject(isn: $isn, daData: $daData, addrType: $addrType)
}
`;


export const SEND_PAYMENT_LINK = gql`
mutation sendPaymentLink($isn: isn, $phone: String, $email: String) {
  sendPaymentLink(isn: $isn, phone: $phone, email: $email)
}
`;

export const PRECALCULATION = gql`
mutation preCalc(
  $banks: [isn]
  $insureType: isn
  $insureTypeName: String
  $objectType: isn
  $objectTypeName: String
  $summ: float
  $rate: float
  $creditIssueDate: String
  $dealCountry: String
  $agentIsn: String
  $riskProperty: boolean
  $riskLife: boolean
  $riskTitle: boolean
  $borrowers: [inputBorrower]
  $objectIsn: isn
  $dateBuild: Int
  $ownershipYears: Int
) {
  preCalc(
    banks: $banks
    insureType: $insureType
    insureTypeName: $insureTypeName
    objectType: $objectType
    objectTypeName: $objectTypeName
    summ: $summ
    creditIssueDate: $creditIssueDate
    agentIsn: $agentIsn
    rate: $rate
    dealCountry: $dealCountry
    riskProperty: $riskProperty
    riskLife: $riskLife
    riskTitle: $riskTitle
    borrowers: $borrowers
    objectIsn: $objectIsn
    dateBuild: $dateBuild
    ownershipYears: $ownershipYears
  ) {
    isn
    bank {
      isn
      fullName
      shortName
      icon
    }
    summ
    riskProperty
    tariffProperty
    riskTitle
    tariffTitle
    riskLife
    borrowers {
      number
      riskLife
      tariffLife
      needOms
    }
    total
  }
}
`;

export const CONTRAGENT_FIND = gql`
mutation GetSubjectInfo(
  $fullName: String
  $birthday: String
  $phone: String
  $needCreated: Int
  $getFullInfo: Int = 1,
  $SubjISN: String
  $docClassIsn: String
  $docClassName: String
  $docSer: String
  $docNo: String
) {
  GetSubjectInfo(
    fullName: $fullName
    birthday: $birthday
    phone: $phone
    getFullInfo: $getFullInfo
    needCreated: $needCreated
    SubjISN: $SubjISN
    docClassIsn: $docClassIsn
    docClassName: $docClassName
    docSer: $docSer
    docNo: $docNo
  ) {
    isn
    fullName
    firstName
    lastName
    parentName
    shortName
    latName
    isLegal
    gender
    countryIsn
    classIsn
    className
    isVip
    isResident
    inn
    kpp
    ogrn
    orgFormIsn
    phoneMobile
    email
    birthDate
    addr1
    addr2
    authDocClassIsn
    authDocClassName
    authDocNumber
    authDocSer
    authDocDate
    authDocIssuer
    authDocIssuerCode
    addrs
  }
}
`;

export const UPDATE_AGREEMENT_CALC_FULL = gql`
mutation agreementCalcFull(
  $curIsn: isn
  $curCode: String
  $curName: String
  $stageIsn: isn
  $stageName: String
  $statusIsn: isn
  $statusName: String
  $isn: isn
  $id: String
  $clientIsn: isn
  $clientInfo: object
  $deptIsn: isn
  $deptName: String
  $classIsn: isn
  $className: String
  $productIsn: isn
  $productName: String
  $dateBeg: date
  $dateEnd: date
  $dateSign: date
  $attribs: [inputAttribute]
  $objects: [object]
  $roles: [object]
  $clauses: [object]
  $data: object
  $address: String
  $agentIsn: isn
) {
  agreementCalcFull(
    curIsn: $curIsn
    curCode: $curCode
    curName: $curName
    stageIsn: $stageIsn
    stageName: $stageName
    statusIsn: $statusIsn
    statusName: $statusName
    isn: $isn
    id: $id
    clientIsn: $clientIsn
    clientInfo: $clientInfo
    deptIsn: $deptIsn
    deptName: $deptName
    classIsn: $classIsn
    className: $className
    productIsn: $productIsn
    productName: $productName
    dateBeg: $dateBeg
    dateEnd: $dateEnd
    dateSign: $dateSign
    attribs: $attribs
    objects: $objects
    roles: $roles
    clauses: $clauses
    data: $data
    address: $address
    agentIsn: $agentIsn
  ) {
    curIsn
    curCode
    curName
    stageIsn
    stageName
    statusIsn
    statusName
    isn
    id
    clientIsn
    clientInfo
    deptIsn
    deptName
    classIsn
    productIsn
    productName
    dateBeg
    dateEnd
    dateSign
    attribs {
      isn
      title
      val
      value
      remark
    }
    objects
    roles
    clauses
  }
}
`;

export const UPDATE_CONTRACT = gql`
mutation agreement(
  $curIsn: isn
  $curCode: String
  $curName: String
  $stageIsn: isn
  $stageName: String
  $statusName: String
  $status: String
  $isn: isn
  $id: String
  $clientIsn: isn
  $deptIsn: isn
  $deptName: String
  $classIsn: isn
  $className: String
  $productIsn: isn
  $emplIsn: isn
  $productName: String
  $dateBeg: date
  $dateEnd: date
  $dateSign: date
  $attribs: [inputAttribute]
  $objects: [object]
  $roles: [object]
  $clauses: [object]
  $data: object
  $prevIsn: isn
  $prevId: String
  $addendumDateSign: date
  $addendumDateBeg: date
) {
  agreement(
    curIsn: $curIsn
    curCode: $curCode
    curName: $curName
    stageIsn: $stageIsn
    stageName: $stageName
    statusName: $statusName
    status: $status
    isn: $isn
    id: $id
    clientIsn: $clientIsn
    deptIsn: $deptIsn
    emplIsn: $emplIsn
    deptName: $deptName
    classIsn: $classIsn
    className: $className
    productIsn: $productIsn
    productName: $productName
    dateBeg: $dateBeg
    dateEnd: $dateEnd
    dateSign: $dateSign
    attribs: $attribs
    objects: $objects
    roles: $roles
    clauses: $clauses
    data: $data
    prevIsn: $prevIsn
    prevId: $prevId
    addendumDateSign: $addendumDateSign
    addendumDateBeg: $addendumDateBeg
  )
}`;

export const CALC_AGREEMENT = gql`
mutation calcAgreement($isn: isn, $data: object) {
  calcAgreement(isn: $isn, data: $data) {
    curIsn
    curCode
    curName
    stageIsn
    stageName
    statusIsn
    statusName
    isn
    id
    clientIsn
    clientInfo
    deptIsn
    deptName
    classIsn
    productIsn
    productName
    dateBeg
    dateEnd
    dateSign
    attribs {
      isn
      title
      val
      value
      remark
    }
    objects
    roles
    clauses
  }
}
`;

export const AGREEMENT_PROLONGATION = gql`
mutation agreementProlongation(
  $isn: isn
  $limitSum: Float
  $signDate: String
  $agentIsn: String
) {
  agreementProlongation(
    isn: $isn
    limitSum: $limitSum
    signDate: $signDate
    agentIsn: $agentIsn
  )
}
`;

export const AGREEMENT_MAKE_SBERBANK = gql`
mutation agreementMakeSber($isn: isn) {
  agreementMakeSber(isn: $isn)
}
`;

export const CANCEL_AGREEMENT = gql`
mutation cancelAgrand($isn: isn) {
  cancelAgrand(isn: $isn)
}
`;

export const DELETE_DOC = gql`
mutation docDelete($id: Int) {
  docDelete(id: $id)
}
`;

export const CLIENT_INIT = gql`
mutation clientInit(
  $firstName: String
  $lastName: String
  $parentName: String
  $phoneMobile: String
  $birthDate: date
  $email: String
  $agrCalcIsn: isn
  $userLogin: String
) {
  clientInit(
    firstName: $firstName
    lastName: $lastName
    parentName: $parentName
    phoneMobile: $phoneMobile
    birthDate: $birthDate
    email: $email
    agrCalcIsn: $agrCalcIsn
    userLogin: $userLogin
  ) {
    clientIsn
  }
}
`;

export const SAVE_ATTACHMENT= gql`
mutation attachment(
  $type: String
  $ref: isn
  $fileName: String
  $note: String
  $file: Upload
) {
  attachment(
    type: $type
    ref: $ref
    fileName: $fileName
    note: $note
    file: $file
  ) {
    fileName
  }
}
`;
export const ADD_COMMENT = gql`
mutation comment($text: String, $doc: ID) {
  comment(text: $text, doc: $doc) {
    id
  }
}

`;

export const SET_STATUS_RISK = gql`
mutation agreementCalcRisks($isn: isn, $riskObjects: [inputRisk]) {
  agreementCalcRisks(isn: $isn, riskObjects: $riskObjects)
}
`;
export const CHANGE_STATUS_RISK = gql`
mutation approve($doc: isn!, $risks: [inputRisk]) {
  approve(doc: $doc, risks: $risks) {
    doc
  }
}
`;

export const DISAPPROVE_RISKS = gql`
mutation disapprove($doc: isn!, $risks: [inputRisk]) {
  disapprove(doc: $doc, risks: $risks) {
    doc
  }
}
`;

export const CREATE_INVOICE = gql`
mutation createInvoice($argIsn: isn) {
  createInvoice(argIsn: $argIsn)
}
`;
export const CREATE_INVOICE_LINK = gql`
mutation createInvoiceLink($argIsn: isn! $email: String) {
  createInvoiceLink(argIsn: $argIsn email: $email)
}
`;

export const SET_RESTRICTIONS = gql`
mutation restrictions($user: String, $restrictions: [inputRestriction], $groupId: String) {
  restrictions(user: $user, restrictions: $restrictions, groupId: $groupId)
}
`;

export const AGREEMENT_MAKE = gql`
mutation agreementMake($isn: isn, $sberbankExpress: Boolean) {
  agreementMake(isn: $isn, sberbankExpress: $sberbankExpress)
}
`;
export const CHANGE_CONTRACT_STATUS = gql`
mutation agreementStatus($isn: isn, $status: String, $isProlongation: Boolean) {
  agreementStatus(isn: $isn, status: $status, isProlongation: $isProlongation)
}
`;

export const CREATE_ADDENDUM = gql`
mutation addendum(
  $agrIsn: isn
  $dateSign: String
  $dataBeg: String
  $addendumReason: String
  $addLife: Int
  $addProp: Int
  $addTitul: Int
  $riskSum: Int
) {
  addendum(
    agrIsn: $agrIsn
    dateSign: $dateSign
    dataBeg: $dataBeg
    addendumReason: $addendumReason
    addLife: $addLife
    addProp: $addProp
    addTitul: $addTitul
    riskSum: $riskSum
  )
}
`;

export const CREATE_REPORT = gql`
mutation getReportSign(
  $dateBeg: String
  $dateEnd: String
  $bankIsn: isn
  $datePay: String
  $agrId: String
  $frontNodeIsn: Int
) {
  getReportSign(
    dateBeg: $dateBeg
    dateEnd: $dateEnd
    bankIsn: $bankIsn
    datePay: $datePay
    agrId: $agrId
    frontNodeIsn: $frontNodeIsn
  )
}
`;

export const CANCEL_AGREEMENT_REASON = gql`
mutation cancelAgrCalc(
  $subjIsn: isn!
  $couseIsn: String
  $remark: String
  $status: String
) {
  cancelAgrCalc(
    subjIsn: $subjIsn
    couseIsn: $couseIsn
    remark: $remark
    status: $status
  )
}
`;


export const CANCEL_INVOICE = gql`
mutation cancelInvoice($docIsn: isn!) {
  cancelInvoice(docIsn: $docIsn)
}
`;

export const CONFIRM_AGREEMENT_DECLARATION = gql`
mutation confirmAgreementDeclaration($isn: isn!, $viaSms: Boolean) {
  confirmAgreementDeclaration(isn: $isn, viaSms: $viaSms)
}
`;


