import React                                 from "react";
import Modal, {ModalProps}                   from "../../../../uiKit/modal";
import Button                                from "../../../../uiKit/button/button";
import {StyledButtonsGroup}                  from "../../../roles/components/header/styles";
import {TextButton}                          from "../../../../globalStyledComponents";
import StoreAgreementForm                    from "../../../createRequest/store";
import {useMutation, useQuery, useLazyQuery} from "@apollo/react-hooks";
import {BANKS_RAISING, DOCS, GET_AGREEMENT, ME} from "../../../../utils/queries";
import {
  CALC_AGREEMENT, CANCEL_AGREEMENT, SET_STATUS_RISK, UPDATE_AGREEMENT_CALC_FULL
}                                            from "../../../../utils/mutations";
import {
    agreementEditFilling,
    BALANCE_BEFORE_ADD_RISKS_ISN,
    createRiskStatusObject,
    getBankRaising,
    getRestrictionsObject,
    getVariablesForDoc,
    kiasAgreementToFormState,
} from "../../../../helpers/buildTemplateForBack";
import {useHistory, useParams}               from "react-router";

import {
  StyledSaveAgreementText,
  StyledSaveAgreementWrapper
}                            from "../saveAgreementModal/styles";
import {ErrorMessage}        from "../../../../components/errorMessage";
import {useCallNotification} from "../../../../hooks/useCallNotification";
import {getAllowedPathname}  from "../../../../helpers/rolesRightHandler";
import {useSendAnketa}       from "../../../../hooks/useSendAnketa";
import {useSaveAddress}      from "../../../../hooks/useSaveAddress";
import {useClearGlobalStore} from "../../../../hooks/useClearGlobalStore";
import {stringToNumber}      from "../../../../formUtils/getFormValue";

export const SaveEditAgreementModal = ({header, isOpen, onClose}: ModalProps) => {
  const {id, type: agreementEditType} = useParams();
  const history = useHistory();
  const {setNotification} = useCallNotification();
  const {sendAnketa, saveAdditionalFiles, loading: loadingSendAnketa} = useSendAnketa();
  const {saveAddress, loading: loadingSaveAddress} = useSaveAddress();
  const {clearGlobalStore} = useClearGlobalStore();
  const [{agreementForm: {agreementFormState}}]: any = StoreAgreementForm();
  const [getDocs, {data: dataDocs, loading: loadingDocs, error: errorDocs}] = useLazyQuery(DOCS, {variables: {isn: id}});
  const {data: dataUser, error: errorUser} = useQuery(ME);
    const {data: dataBanksRising, error: errorBanksRising} = useQuery(BANKS_RAISING);
  const [getAgreement, {data: dataAgreement, loading: loadingAgreement, error: errorAgreement}] = useLazyQuery(GET_AGREEMENT, {variables: {isn: id}});
  const [cancelAgreement, {loading: loadingCancelAgreement}] = useMutation(CANCEL_AGREEMENT, {refetchQueries: ["docs"]});
  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL);
  const [calcAgreement, {loading: loadingCalcAgreement}] = useMutation(CALC_AGREEMENT);
  const [setStatusRisk, {loading: loadingStatusRisk}] = useMutation(SET_STATUS_RISK, {refetchQueries: ["docs", "agreementCalc"]});
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {risks, data: dataDoc} = (docsList || [])[0] || {};
  const {agreementIsn} = dataDoc || {};
  const {isAdmin, type, role, restrictions} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);
  const path: string | undefined = getAllowedPathname(functions, type, isAdmin, true);
    const {creditOrganizationName, creditRate} = agreementFormState || {};

    const bankRising = getBankRaising((dataBanksRising?.banks||[]).find(bank => bank?.isn === creditOrganizationName?.value)?.raising, stringToNumber(creditRate))

  React.useEffect(() => {
    if (!isOpen) return;
    getDocs({variables: {isn: id}});
    getAgreement({variables: {isn: id}});
    // eslint-disable-next-line
  }, [isOpen])

  const successSaveHandler = () => {
    onClose();
    clearGlobalStore();
    history.push(path || "/page404/rights")
    setNotification({type: "success", text: "Данные успешно обновлены"})
  }

  const saveEditHandler = () => {
    agreementCalc({
      variables: agreementEditFilling(agreementFormState, (dataAgreement || {}).agreementCalc || {})
    })
      .then(({data: {agreementCalcFull}}) => {
        const {isn: isnAgreement} = agreementCalcFull || {};
        calcAgreement({
          variables: {
            isn: isnAgreement,
            data: {
              ...dataDoc
            }
          }
        })
          .then(({data: {calcAgreement}}) => {
            sendAnketa(agreementFormState, isnAgreement)
            saveAdditionalFiles(agreementFormState, isnAgreement);
            const {isn, riskObjects, filledAgreement} = createRiskStatusObject(calcAgreement, {}, risks, getRestrictionsObject(restrictions), bankRising);
            setStatusRisk({variables: {isn, riskObjects}})
              .then(() => {
                agreementCalc({
                  variables: {...filledAgreement}
                })
                  .then(() => {
                    if (!agreementIsn) return successSaveHandler();
                    cancelAgreement({
                      variables: {
                        isn: agreementIsn
                      }
                    })
                      .then(() => successSaveHandler())
                      .catch(error => {
                        onClose();
                        setNotification({type: "error", text: error})
                      })
                  })
                  .catch(error => setNotification({type: "error", text: error}))
              })
              .catch(error => setNotification({type: "error", text: error}))
          })
          .catch(error => setNotification({type: "error", text: error}))
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const saveRecalculationHandler = () => {
    agreementCalc({
      variables: agreementEditFilling(agreementFormState, (dataAgreement || {}).agreementCalc || {})
    })
      .then(({data: {agreementCalcFull}}) => {
        const {isn: isnAgreement} = agreementCalcFull || {};
        const {templateVariables} = kiasAgreementToFormState({agreementData: agreementCalcFull})
        calcAgreement({
          variables: {
            isn: isnAgreement,
            data: {
              templateVariables,
              ...getVariablesForDoc(agreementCalcFull)
            }
          }
        })
          .then(({data: {calcAgreement}}) => {
            const {isn, riskObjects, filledAgreement} = createRiskStatusObject(calcAgreement, agreementFormState, [], getRestrictionsObject(restrictions), bankRising);
            setStatusRisk({variables: {isn, riskObjects}})
              .then(() => {
                agreementCalc({
                  variables: {...filledAgreement}
                })
                  .then(() => successSaveHandler())
                  .catch(error => setNotification({type: "error", text: error}))
              })
              .catch(error => setNotification({type: "error", text: error}))
          })
          .catch(error => setNotification({type: "error", text: error}))
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const saveAddRisksHandler = () => {
    const agreementCalcVariables = agreementEditFilling(agreementFormState, (dataAgreement || {}).agreementCalc || {}, true)
    agreementCalc({
      variables: agreementCalcVariables
    })
      .then(({data: {agreementCalcFull}}) => {
        const {isn: isnAgreement} = agreementCalcFull || {};
        const {templateVariables} = kiasAgreementToFormState({agreementData: agreementCalcFull})
        calcAgreement({
          variables: {
            isn: isnAgreement,
            data: {
              templateVariables,
              ...getVariablesForDoc(agreementCalcFull)
            }
          }
        })
          .then(({data: {calcAgreement}}) => {
            const oldRisksObject = (calcAgreement?.objects||[]).map(({AGROBJECT_ADDATTR, ISN}) => {
              const isNewRisk: boolean = !!stringToNumber((((AGROBJECT_ADDATTR || {}).row || []).find(({ATTRISN}) => ATTRISN === BALANCE_BEFORE_ADD_RISKS_ISN) || {}).VAL);
              if (isNewRisk) return {object: ISN, status: "underwriting"};
              return {object: ISN, status: "approved"};
            })
            sendAnketa(agreementFormState, isnAgreement)
            saveAddress(calcAgreement, agreementFormState)
            const {isn, riskObjects, filledAgreement} = createRiskStatusObject(calcAgreement, agreementFormState, oldRisksObject, getRestrictionsObject(restrictions), bankRising);
            setStatusRisk({variables: {isn, riskObjects}})
              .then(() =>
                agreementCalc({
                  variables: {...filledAgreement}
                })
                  .then(() => successSaveHandler())
                  .catch(error => setNotification({type: "error", text: error}))
              )
              .catch(error => setNotification({type: "error", text: error}))
          })
          .catch(error => setNotification({type: "error", text: error}))
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  const saveHandler = () => {
    switch (agreementEditType) {
      case "edit":
        return saveEditHandler();
      case "recalculation":
        return saveRecalculationHandler();
      case "addRisks":
        return saveAddRisksHandler();
      default:
        return setNotification({type: "error", text: "Что то пошло не так"})
    }
  };

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorDocs) return <ErrorMessage error={errorDocs}/>
  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>
  if (errorBanksRising) return <ErrorMessage error={errorBanksRising}/>
  const isLoading = loadingAgreementCalc || loadingSaveAddress || loadingSendAnketa || loadingCancelAgreement || loadingStatusRisk || loadingDocs || loadingAgreement || loadingCalcAgreement;
  return (
    <StyledSaveAgreementWrapper>
      <Modal
        isOpen={isOpen}
        onClose={() => onClose()}
        header={header}>
        <StyledSaveAgreementText>
          <TextButton>Сохранение изменений в заявке может занять продолжительное время. Пожалуйста,
            подождите.</TextButton>
        </StyledSaveAgreementText>
        <StyledButtonsGroup>
          <Button
            isLoading={isLoading}
            onClick={saveHandler} appearance="filled">
            Сохранить изменения
          </Button>
          <Button onClick={() => onClose()} isLoading={isLoading} appearance="transparent">
            Отмена
          </Button>
        </StyledButtonsGroup>
      </Modal>
    </StyledSaveAgreementWrapper>
  )
};

export default SaveEditAgreementModal;