import styled  from "styled-components";
import {white} from "../../../globalStyledComponents";

export const StyledHeader = styled.div`
  padding: 0 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 8rem);
  background: ${white};
  height: 6.5rem;
  
  @media screen and (max-width: 767px) {
    padding: 0 1.5rem;
    height: 6rem;
  }
`;
export const StyledNavigationItems = styled.div`
  display: flex;
  align-items: center;
  > .flagButton {
    margin-left: 1rem;
  }
`;

export const StyledUserName = styled.div`
margin-right: 3rem;
`;
export const StyledServiceName = styled.div`
  margin-left: 2rem;
`;

export const StyledMobileLogo = styled.div`
  margin-left: 1rem;
  cursor: pointer;
`;
export const StyledHeaderNavigation = styled.div`  
  display: flex;
  align-items: center;
`;

export const StyledHeaderActions = styled.div`  
  display: flex;
  align-items: center;
`;