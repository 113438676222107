import React              from "react";
import {StyledFlyButtons} from "./styles";
import StoreNavigation    from "../layout/leftMenu/store";
import {useWindowWidth}   from "@react-hook/window-size";

interface IFlyButtonsProps {
  children: any
  isWhiteBackground?: boolean
}

export const FlyButtons = ({children, isWhiteBackground}:IFlyButtonsProps) => {
  const windowWidth = useWindowWidth({wait: 250});
  const [{navigation: {navigationState: {isOpen}}}]: any = StoreNavigation();

  return (
    <StyledFlyButtons isWhiteBackground={!!isWhiteBackground} isMenuOpen={isOpen && windowWidth < 768}>
      {children}
    </StyledFlyButtons>
  )
};

export default FlyButtons;