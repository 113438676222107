import {useMutation}         from "@apollo/react-hooks";
import {SAVE_ADDRESS}        from "../utils/mutations";
import {useCallNotification} from "./useCallNotification";

const UseSaveAddressPerson = () => {
  const {setNotification} = useCallNotification();
  const [saveAddress, {loading: loadingSaveAddress}] = useMutation(SAVE_ADDRESS);

  const saveAddressHandler = (isn: string, daData: any, addrType: "2246" | "2247") => {
    if (!isn || !daData || !addrType) return;
    saveAddress({
      variables: {
        isn,
        daData: {suggestions: daData},
        addrType
      }
    })
      .catch(error => setNotification({type: "error", text: error}))
  }


  return {
    saveAddress: saveAddressHandler,
    loading: loadingSaveAddress
  }
};

export default UseSaveAddressPerson;