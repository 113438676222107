import React                                from 'react';
import Modal, {ModalProps}                  from "../../../uiKit/modal";
import Button                               from "../../../uiKit/button/button";
import {StyledButtonsGroup}                 from "../../roles/components/header/styles";
import FieldsBuilder                        from "../../../uiKit/fieldsBuilder";
import Form                                 from '@atlaskit/form';
import {getFormError}                       from "../../../formUtils/getFormError";
import {getFormValue}                       from "../../../formUtils/getFormValue";
import {IFormField}                         from "../../../uiKit/fieldsBuilder/interfaces";
import {useCallNotification}                from "../../../hooks/useCallNotification";
import {useLazyQuery}                       from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, DOWNLOAD_CONTRACT} from "../../../utils/queries";

export const SendAnketaModal = ({onClose, header, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {isn, templateIsn, subject, defaultEmail} = data || {};

  const [downloadAnketa, {
    data: dataDownloadAnketa,
    loading: loadingDownloadAnketa,
  }] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const idFileAnketa = ((dataDownloadAnketa || {}).documentForm || {}).id;

  React.useEffect(() => {
    if (!idFileAnketa) return;
    setNotification({type: "success", text: "Успешно отправлено"});
    onClose();
  }, [idFileAnketa])

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {email} = getFormValue(data);
    downloadAnketa({
      variables: {
        isn,
        templateIsn: templateIsn,
        email,
        subject,
      }
    });
  };

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "input",
        name: "email",
        defaultValue: defaultEmail,
        placeholder: "E-mail",
        isRequired: true,
        errorMessage: "Укажите E-mail",
        validationType: "email"
      }
    },
  ]

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button
                type="submit"
                appearance="filled"
                isLoading={loadingDownloadAnketa}
              >
                Отправить
              </Button>
              <Button
                onClick={onClose}
                appearance="transparent"
                isLoading={loadingDownloadAnketa}
              >
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  )
};

export default SendAnketaModal;