import React                                                       from "react";
import {StyledFeatures, StyledFeaturesWrapper} from "./styles";
import {Heading1, Text2}                                           from "../../../../globalStyledComponents";

const descriptionText = (<span>Мы заботимся о том, чтобы работа в системе Абсолют была максимально удобна для вас.<br/>
  Система Абсолют поможет вам комфортно оформить полис для каждого клиента.<br/>
  Если у вас есть пожелания и предложения, вы можете написать нам на почту <a style={{color:'#003D81'}} href='mailto:info@absolutins.ru'>info@absolutins.ru</a>.<br/>
  С уважением, команда Абсолют Страхование.</span>);

export const Features = () => {
  return (
    <StyledFeaturesWrapper>
    <StyledFeatures>
      <Heading1>Уважаемый клиент!</Heading1>
      <Text2>{descriptionText}</Text2>
    </StyledFeatures>
    </StyledFeaturesWrapper>
  )
};

export default Features;