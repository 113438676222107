import styled  from "styled-components";
import {white} from "../../../../../../globalStyledComponents";

export const StyledCoBorrowerStepWrapper = styled.div`
  background: ${white};
  margin-bottom: 4rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
`;
