import React                            from "react";
import Button                           from "../../../uiKit/button/button";
import Modal, {ModalProps}              from "../../../uiKit/modal";
import {useParams}                      from "react-router";
import {useLazyQuery}         from "@apollo/react-hooks";
import {USERS}                          from "../../../utils/queries";
import {ErrorMessage}                   from "../../../components/errorMessage";
import {Heading3, TextButton}           from "../../../globalStyledComponents";
import {StyledUsersWithSameRoleWrapper} from "../styles";


export const UsersWithSameRole = ({header, isOpen, onClose}: ModalProps) => {
  const {id} = useParams();
  const [getUsers, {data: dataUsers, loading: loadingUsers, error: errorUsers}] = useLazyQuery(USERS, {variables: {roleId: id}});

  React.useEffect(() => {
    if (!isOpen) return;
    getUsers();
  }, [isOpen])

  if (errorUsers) return <ErrorMessage error={errorUsers}/>
  return (
      <Modal
        isOpen={isOpen}
        isLoading={loadingUsers}
        onClose={() => onClose()}
        header={header}>
        <StyledUsersWithSameRoleWrapper>
          {!(dataUsers?.users||[]).length && <Heading3>Нет пользователей</Heading3>}
          {!!(dataUsers?.users||[]).length && (dataUsers?.users||[]).map(({id, login, email}) => <TextButton key={id}>{`${login} (${email})`}</TextButton>)}
        </StyledUsersWithSameRoleWrapper>
          <Button
            width='100%'
            onClick={() => onClose()}
            appearance="filled">
            Ок
          </Button>
      </Modal>
  )
};

export default UsersWithSameRole;