import styled       from "styled-components";
import {blue, grey} from "../../../../globalStyledComponents";

export const StyledContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .linkButton {
    margin-right: 2rem;
  }
  padding-bottom: 4rem;
  border-bottom: 1px solid ${grey};
  
  @media screen and (max-width: 767px) {
  .linkButton {
    margin-right: 0;
  }
  }
`;
export const StyledSignetAgreementButtons = styled.div`
  display: flex;

  > button {
    margin-right: 1rem;
  }
  
  @media screen and (max-width: 767px) {
    > button {
      width: calc(50% - 0.5rem);
      margin-right: 1rem;
    }
    .nivanaviDropDown {
      width: calc(50% - 0.5rem);
    }
  }
`;

export const StyledTotalInformation = styled.div`

`;

export const StyledImportantNumber = styled.div`
  color: ${blue};
  font-weight: bold;
`;
export const StyledTotalInformationItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  
  > p {
    white-space: nowrap;
  }  
  
  > p: first-child {
    opacity: 0.5;
  }
`;