import React from "react";
import {StyledFilter, StyledFilterButtons} from "./styles";
import {IFormField}        from "../../../../uiKit/fieldsBuilder/interfaces";
import {IRadioItem}        from "../../../../uiKit/radio";
import FieldsBuilder       from "../../../../uiKit/fieldsBuilder";
import {Heading3}          from "../../../../globalStyledComponents";
import Button              from "../../../../uiKit/button/button";
import {CloseIcon}         from "../../../../icons/ui/Close";
import Form           from '@atlaskit/form';
import {getFormValue} from "../../../../formUtils/getFormValue";
import {getFormError} from "../../../../formUtils/getFormError";
import {useGetDict}   from "../../../../hooks/useGetDicti";
import {defaultFilterValue, IFilterState} from "../../index";
import {DateIntervalValue}                from "../../../../uiKit/dateIntervalPicker";
import moment              from "moment";
import {formatDateStr}     from "../../../../uiKit/datePicker";

const radioDateInterval: IRadioItem[] = [
  {label: "за месяц", value: "month"},
  {label: "за квартал", value: "quarter"},
  {label: "за полгода", value: "halfYear"},
  {label: "за 10 лет", value: "10 years"},
  {label: "выбрать даты", value: "select"},
]

export const ApplicationFilter = ({stateFilter, setFilter, setFilterIsOpen, isShowBanks}: { stateFilter: IFilterState, setFilter: any, setFilterIsOpen: any, isShowBanks: boolean }) => {
  const {data: selectBanks, loading: loadingBanks} = useGetDict("banks");
  const {data: selectStatuses, loading: loadingStatuses} = useGetDict("statusList");
  const {dateRadio, dateInterval, statuses, banks} = stateFilter || {};

  const [stateInterval, setInterval] = React.useState<IRadioItem>(radioDateInterval.find(({value}) => value === dateRadio) || radioDateInterval[3])
  const form: IFormField[] = [
    {
      label: "Дата",
      fieldItems: {
        type: "radio",
        name: "dateRadio",
        onChange: (value) => setInterval(value),
        items: radioDateInterval,
        defaultValue: stateInterval
      }
    },
    {
      isHidden: stateInterval.value !== "select",
      fieldItems: {
        type: "dateInterval",
        name: "dateInterval",
        isRequired: true,
        errorMessage: "Выберите даты",
        placeholder: "Выберите даты",
        defaultValue: dateInterval
      }
    },
    {
      label: "Статусы",
      fieldItems: {
        type: "enums",
        name: "statuses",
        placeholder: "Выберите статусы",
        items: selectStatuses,
        isLoading: loadingStatuses,
        defaultValue: selectStatuses.filter(({value}) => statuses.includes(value))
      }
    },
    {
      label: "Банки",
      isHidden: !isShowBanks,
      fieldItems: {
        type: "enums",
        name: "banks",
        placeholder: "Выберите банки",
        items: selectBanks,
        isLoading: loadingBanks,
        defaultValue: selectBanks.filter(({value}) => banks.includes(value))
      }
    },
  ];

  const getIntervalDate = (interval: string, dateInterval: DateIntervalValue) => {
    switch (interval) {
      case "month":
        return {start: moment().subtract(1, 'month').format(formatDateStr), end: moment().format(formatDateStr)}
      case "quarter":
        return {start: moment().subtract(4, 'month').format(formatDateStr), end: moment().format(formatDateStr)}
      case "halfYear":
        return {start: moment().subtract(6, 'month').format(formatDateStr), end: moment().format(formatDateStr)}
      case "year":
        return {start: moment().subtract(1, 'year').format(formatDateStr), end: moment().format(formatDateStr)}
      case "10 years":
        return {start: moment().subtract(10, 'year').format(formatDateStr), end: moment().format(formatDateStr)}
      case "select":
        return dateInterval
      default:
        return dateInterval
    }
  };

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {dateInterval: dateIntervalForm, dateRadio, banks} = getFormValue(data);
    setFilter({
      ...getFormValue(data),
      banks: banks || [],
      dateInterval: getIntervalDate(dateRadio, dateIntervalForm || dateInterval)
    })
    setFilterIsOpen(false);
  };

  const clearFiltersHandler = () => {
    setFilter(defaultFilterValue);
    setFilterIsOpen(false);
  };

  const buttons = (<StyledFilterButtons>
    <Button appearance="filled">Показать</Button>
    <Button appearance="text" iconBefore={<CloseIcon/>} onClick={() => clearFiltersHandler()}>Очистить</Button>
  </StyledFilterButtons>);
  return (
    <Form onSubmit={data => onFormSubmit(data)}>
      {({formProps}) => (
        <form {...formProps}>
          <StyledFilter>
            <Heading3>Фильтры</Heading3>
            <FieldsBuilder formFields={form}/>
            {buttons}
          </StyledFilter>
        </form>
      )}
    </Form>
  )
};

export default ApplicationFilter;