import styled                                                   from "styled-components";
import {black, black10, black40, blue, blue7, white} from "../../globalStyledComponents";

export const StyledTableWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const StyledTable = styled.div`
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: auto;

  > div {
    margin: 0;
    position: unset;
  }
   
  table {
    table-layout: auto;
  }
  
  thead {
    border-bottom: 1px solid ${black10};
  }
  
  thead th {
    font-family: Verdana;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 1.1rem;
    line-height: 1.5rem;
    padding: 1.3rem 1rem;
    color: ${black40};
  }
  
  tbody tr {
    border-bottom: 1px solid ${black10};
  }
  
    
  tbody td {
    vertical-align: middle;
    white-space: nowrap;
    padding: 1.8rem 1rem;
    text-transform: capitalize;
    font-family: Verdana;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${black};
  }    
  
table {
  overflow: hidden;
}

tr:hover {
  transition: background-color 0.2s;
  background-color: ${blue7};
}

td, th {
    cursor: pointer;
  position: relative;
}

td::after {
  content: "";
  pointer-events: none;
  position: absolute;
  transition: background-color 0.2s;
  left: 0;
  top: -5000px;
  height: 10000rem;
  width: 100%;
}

td:hover::after {
   background-color: ${blue7};
}
`;

export const StyledPagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  
  button {
    padding: 0;
    border-radius: 0;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Verdana";
    font-size: 1.4rem;
    line-height: 2rem;
  }
  
  button[aria-label="previous"]:hover, button[aria-label="next"]:hover {
    cursor: pointer;
    background: ${blue};
  }
  
  button[aria-label="previous"]:hover svg, button[aria-label="next"]:hover svg {
    color: ${white};
  }
`;