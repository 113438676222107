import React                      from "react";
import {Heading2}       from "../../globalStyledComponents";
import MyAgreementByIdHeader      from "./components/header";
import MyAgreementPayments        from "./components/payments";

export const MyAgreementById = () => {

  return (
    <>
      <Heading2>Оплатить взнос договора страхования</Heading2>
      <MyAgreementByIdHeader/>
      <MyAgreementPayments/>
    </>
  )
};

export default MyAgreementById;