import React                    from 'react';
import {Heading3, TextButton}   from "../../../../globalStyledComponents";
import Status
                                from "../../../../uiKit/status";
import {
  StyledHeaderEvents,
  StyledHeaderInformation,
  StyledHeaderWrapper, StyledInformationItem
}                                  from "../../../applicationById/components/header/styles";
import {useQuery}          from "@apollo/react-hooks";
import {CHECK_INVOICE_STATUS, DOCS} from "../../../../utils/queries";
import {useParams}          from "react-router";
import {ErrorMessage}                   from "../../../../components/errorMessage";
import {useCallNotification}            from "../../../../hooks/useCallNotification";
import LoadingString                    from "../../../../uiKit/loadingString";

export const CreateInvoiceHeader = () => {
  const {setNotification} = useCallNotification();
  const {id} = useParams();
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {variables: {isn: id}});
  const {data: dataInvoiceStatus, loading: loadingInvoiceStatus, error: errorInvoiceStatus} = useQuery(CHECK_INVOICE_STATUS, {variables: {isn: id}});
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {number, statusCode} = (docsList || [])[0] || {};
  const invoiceStatus: string = ((dataInvoiceStatus||{}).invoiceStatus||{}).PAYSTATUSNAME === "Оплачен" ? "payed" : "notPay";

  const informationList = [
    {title: "Номер договора", description: <LoadingString isLoading={loadingDocs}>{number}</LoadingString>},
    {title: "Статус договора", description: <LoadingString isLoading={loadingDocs}><Status status={statusCode}/></LoadingString>},
    {title: "Статус счета", description: <LoadingString isLoading={loadingInvoiceStatus}><Status status={invoiceStatus}/></LoadingString>},
  ]

  if (errorInvoiceStatus) setNotification({type: "error", text: errorInvoiceStatus})
  if (errorDocs) return <ErrorMessage error={errorDocs}/>

  return (
    <StyledHeaderWrapper>
      <StyledHeaderEvents>
        <Heading3>Общие сведения</Heading3>
      </StyledHeaderEvents>

      <StyledHeaderInformation>
        {informationList.map(({title, description}, index) => (
          <StyledInformationItem key={`info-item-${index}`}>
            <TextButton>{title}</TextButton>
            <TextButton>{description}</TextButton>
          </StyledInformationItem>
        ))}
      </StyledHeaderInformation>
    </StyledHeaderWrapper>
  )
};

export default CreateInvoiceHeader;