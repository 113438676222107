import React                   from "react";
import {getGraphqlError}       from "../../helpers/getGraphqlError";
import {StyledErrorMessage}    from "./styles";
import {TextButton}            from "../../globalStyledComponents";
import {useHistory}            from "react-router";
import {ApolloError}           from "apollo-client";

export const ErrorMessage = ({error}: { error: ApolloError }) => {
  const history = useHistory();
  const {location} = history || {};
  const {pathname} = location;
  const message: string = getGraphqlError(error);

  if (["KIAS: Не указан идентификатор сессии или сессия устарела", "Требуется авторизация"].includes(message)) {
    if (pathname !== "/authorization") history.push("/authorization")
    return null;
  }

  return <StyledErrorMessage><TextButton>{message}</TextButton></StyledErrorMessage>
}