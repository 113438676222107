import {ISelectItem} from "../uiKit/select";
import {IRadioItem}  from "../uiKit/radio";

export const getFormValue = (fields: any) => {
  return Object.entries<any>(fields).reduce<any>((prev, [key, objectValue]) => {
    const {type, value, returnFullValue} = objectValue;
    switch (type) {
      case "input":
      case "textarea":
      case "date":
      case "dateInterval":
      case "address":
        return {...prev, [key]: value};
      case "inn":
        if(value?.value){
          console.log('inn is array');
          return {...prev, [key]: value?.value};
        }
        return {...prev, [key]: value};
      case "fio":
        return {...prev, [key]: (value || {}).value};
      case "select":
      case "radio":
        if (returnFullValue) return {...prev, [key]: value};
        return {...prev, [key]: (value || {}).value};
      case "enums":
        return {...prev, [key]: (value || []).map(({value}) => value)}
      case "checkbox":
      case "toggle":
        return {...prev, [key]: value};
      case "range":
      case "number":
        return {...prev, [key]: (String(value) || "0").replace(/\s+/g, '').trim().replace(".", ",")};
      default:
        return prev;
    }
  }, {});
};

export const getClearPhone = (phone: string) => {
  if (!phone) return "";
  return phone.replace(/\D/g, "").replace("7", "");
}

export const getSelectValue = (item?: ISelectItem | IRadioItem, isGetLabel?: boolean) => {
  const {label, value} = item || {};
  if (isGetLabel && !label) return undefined;
  if (isGetLabel) return label
  if (!value) return undefined;
  return value;
}

export const getSelectDefaultValue = (defaultValue?: string | ISelectItem, items?: ISelectItem[]) => {
  if (typeof defaultValue === "string" && !defaultValue) return undefined;
  if (typeof defaultValue !== "string" && !getSelectValue(defaultValue)) return undefined;
  if (typeof defaultValue !== "string") return defaultValue;
  return (items || []).find(({value}) => defaultValue === value);
}

export const getRadioDefaultValue = (defaultValue?: string | number | boolean | IRadioItem, items?: IRadioItem[]) => {
  if (typeof defaultValue === "undefined" || !items?.length) return undefined;
  if (typeof defaultValue === "object") return defaultValue;
  return (items || []).find(({value}) => defaultValue === value);
}

export const stringToNumber = (value: any) => {
  const num: number = Number(String(value).replace(",",".").replace("-", "").trim())
  return isNaN(num) ? 0 : num;
}