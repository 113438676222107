import React                                       from 'react';
import Picker                                      from 'rc-calendar/lib/Picker';
import RangeCalendar                               from 'rc-calendar/lib/RangeCalendar';
import ruRU                                        from "rc-calendar/lib/locale/ru_RU";
import 'rc-calendar/assets/index.css';
import "moment/locale/ru";
import NivaInput                                   from "../nivaInput/NivaInput";
import {CloseIcon}                                 from "../../icons/ui/Close";
import {DataPickerIcon}                            from "../../icons/ui/DataPicker";
import {StyledDatePicker, StyledDatePickerWrapper} from './styles';
import Button                                      from "../button/button";
import {idGenerator}                               from "../../helpers/idGenerator";
import moment                                      from "moment";
import {formatDateStr}                             from "../datePicker";

function formatFunc(v) {
  if (!v) return;
  return v.format(formatDateStr);
}

const isValidRange = (v: any[]) => {
  return v && v[0] && v[1];
}

export type DateIntervalValue = { start: string, end: string };

export interface IDateIntervalProps {
  value?: DateIntervalValue
  onChange?: (value: { start: string, end: string }) => void
  placeholder?: string
  isInvalid?: boolean
  errorMessage?: string
  isRequired?: boolean
}

const DateIntervalPicker = ({value, onChange, placeholder, isRequired, isInvalid, errorMessage}: IDateIntervalProps) => {
  const splitStart: string[] = ((value || {}).start || "").split(".");
  const splitEnd: string[] = ((value || {}).end || "").split(".");
  const defaultDateStart = splitStart.length === 3 ? `${splitStart[1]}.${splitStart[0]}.${splitStart[2]}` : undefined;
  const defaultDateEnd = splitEnd.length === 3 ? `${splitEnd[1]}.${splitEnd[0]}.${splitEnd[2]}` : undefined;

  const [stateDate, setDate] = React.useState<any[]>(defaultDateEnd && defaultDateStart ? [moment(defaultDateStart), moment(defaultDateEnd)] : []);
  const [stateHoverValue, setHoverValue] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (!onChange) return;
    onChange({
      start: stateDate[0] ? formatFunc(stateDate[0]) : "",
      end: stateDate[1] ? formatFunc(stateDate[1]) : ""
    })
    // eslint-disable-next-line
  }, [stateDate])

  const onDateChange = (value) => {
    setDate(value)
  };

  const onHoverChange = (hoverValue) => {
    setHoverValue(hoverValue)
  };

  const calendar = (
    <RangeCalendar
      hoverValue={stateHoverValue}
      onHoverChange={onHoverChange}
      showWeekNumber={false}
      dateInputPlaceholder={['Начальная дата', 'Конечная дата']}
      locale={ruRU}
    />
  );

  const dateIntervalPickerClass = `dateIntervalPicker-${idGenerator()}`;

  return (
    <StyledDatePickerWrapper>
      <StyledDatePicker interval={true}>
        <Picker
          getCalendarContainer={() => document.querySelector(`.${dateIntervalPickerClass}`)}
          value={stateDate}
          onChange={onDateChange}
          calendar={calendar}
        >
          {
            () => {
              return (
                <div className={dateIntervalPickerClass}>
                  <NivaInput
                    value={stateDate && isValidRange(stateDate) ? `${formatFunc(stateDate[0])} - ${formatFunc(stateDate[1])}` : 'Выберите даты'}
                    isInvalid={isInvalid}
                    isRequired={!!isRequired}
                    isControlled={true}
                    readOnly={true}
                    errorMessage={errorMessage}
                    iconAfter={stateDate ?
                      <Button appearance="icon" onClick={() => setDate([])} icon={<CloseIcon/>}/>
                      :
                      <Button appearance="icon" icon={<DataPickerIcon/>}/>
                    }
                    placeholder={placeholder}/>
                </div>
              );
            }
          }
        </Picker>
      </StyledDatePicker>
    </StyledDatePickerWrapper>
  );
}

export default DateIntervalPicker;
