import React                                from 'react';
import PrecalculationStep                   from "./components/precalculationForm";
import {StyledStepVisible}                  from "./styles";
import StoreAgreementStep                   from "./store";
import StepsProvider, {IStepsProviderProps} from "./components/stepsProvider";
import {templateToFormRebuild}          from "../../helpers/templateToFormRebuild";
import StoreAgreementForm               from "./store";
import {ANKETA_TEMPLATE, GET_AGREEMENT} from "../../utils/queries";
import {useLazyQuery}                   from "@apollo/react-hooks";
import {Loader}                         from "../../uiKit/loader";
import SaveAgreementModal               from "./components/saveAgreementModal";
import Button                           from "../../uiKit/button/button";
import {useCallNotification}            from "../../hooks/useCallNotification";
import {useParams}                      from "react-router";
import SaveEditAgreementModal           from "./components/saveEditAgreementModal";
import {kiasAgreementToFormState}       from "../../helpers/buildTemplateForBack";
import DeclarationModal                 from "./components/declarationModal";
import {useClearGlobalStore}            from "../../hooks/useClearGlobalStore";

export const getFormIdByRiskName = (risk: string) => {
  switch (risk) {
    case "riskLife": return "insuranceForm";
    case "riskProperty": return "propertyInsuranceForm";
    case "riskTitle": return "propertyLossInsuranceForm";
    default: return undefined;
  }
};

export const CreateRequest = () => {
  const {setNotification} = useCallNotification();
  const {clearGlobalStore} = useClearGlobalStore();
  const {type, id: agreementId, stepsStack} = useParams();
  const isAgreementEdit: boolean = !!type && !!agreementId;
  const [{agreementStep: {agreementStepState: {step, stepsCount}}}, {agreementStep: actionsAgreementStep}]: any = StoreAgreementStep();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const [stateSaveModal, setSaveModal] = React.useState<boolean>(false);
  const [stateSaveEditModal, setSaveEditModal] = React.useState<boolean>(false);
  const [stateDeclarationModal, setDeclarationModal] = React.useState<boolean>(false);
  const [getAgreement, {
    data: dataAgreement,
    loading: loadingAgreement,
    error: errorAgreement
  }] = useLazyQuery(GET_AGREEMENT);
  const agreementData = (dataAgreement || {}).agreementCalc;
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE, {fetchPolicy: "network-only"});
  const {parts} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const stepItems: IStepsProviderProps[] = templateToFormRebuild(parts);
  const {templateVariables} = agreementFormState || {};
  const addRisksSteps = stepsStack?.split("-")||[];

  console.log("step", step, "stepsCount", stepsCount)
  console.log("agreementFormState", agreementFormState)

  const getDefaultStepForAgreementEdit: () => number = () => {
    switch (type) {
      case "edit":
      case "recalculation":
        return stepItems.findIndex(({id}) => id === "agreementForm") + 1;
      case "addRisks":
        const firstRisk = addRisksSteps[0];
        if (!firstRisk) return 0;
        return stepItems.findIndex(({id}) => id === getFormIdByRiskName(firstRisk)) + 1;
      default:
        return 0;
    }
  }

  React.useEffect(() => {
    if (!agreementData) return;
    actionsAgreementForm.setState({
      agreementFormState: kiasAgreementToFormState({agreementData})
    })
    // eslint-disable-next-line
  }, [agreementData])

  React.useEffect(() => {
    if (!isAgreementEdit) return;
    getAgreement({variables: {isn: agreementId}})
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    return () => {
      clearGlobalStore()
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    if (!templateVariables || parts?.length || loadingAnketaTemplate) return;
    getAnketa({variables: templateVariables});
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    const defaultStep: number = getDefaultStepForAgreementEdit();
    if (!stepItems || !stepItems?.length || stepsCount === stepItems.length + 1) return;
    if (isAgreementEdit && step === defaultStep) return;
    actionsAgreementStep.setState({
      agreementStepState: {
        step: isAgreementEdit ? defaultStep : step,
        stepsCount: stepItems.length + 1
      }
    });
    if (!isAgreementEdit) return;
    actionsAgreementForm.setState({
      agreementFormState: kiasAgreementToFormState({agreementData, anketaParts: parts})
    })
    // eslint-disable-next-line
  }, [stepItems])

  const changeStep = (step: number) => {
    if (step > stepsCount || step < 0) return;
    actionsAgreementStep.setState({
      agreementStepState: {
        step,
        stepsCount
      }
    });
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "smooth"
    });
  }
  const showSaveModal = (isDeclaration?: boolean) => {
    if (isDeclaration) return setDeclarationModal(true);
    if (isAgreementEdit) return setSaveEditModal(true);
    setSaveModal(true)
  }

  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate});
  if (errorAgreement) setNotification({type: "error", text: errorAgreement});
  if (loadingAnketaTemplate || loadingAgreement) return <Loader type="simple"/>

  const mode = process.env.REACT_APP_MODE

  return (
    <>
      {mode === "dev" && <>{Array.from({length: stepsCount}).map((un, index) => (
        <span style={{marginRight: "0.5rem"}} key={index + "ghghfds"}>
        <Button appearance="link" onClick={() => changeStep(index)}>{index}</Button>
        </span>
      ))}</>}

      <StyledStepVisible isShow={step === 0}>
        <PrecalculationStep/>
      </StyledStepVisible>
      {stepItems.map((props, index) => (
          <StyledStepVisible key={`agreementFillStep-${index}`} isShow={step === index + 1}>
            <StepsProvider
              {...props}
              step={step}
              stepCount={stepsCount}
              showSaveModal={showSaveModal}
              stepItems={stepItems}
              changeStep={changeStep}
              addRisksSteps={addRisksSteps}
              isAgreementEdit={isAgreementEdit}
              editType={type}
              form={[]}/>
          </StyledStepVisible>
        )
      )}
      <SaveAgreementModal isOpen={stateSaveModal} header="Сохранить расширенную заявку"
                          onClose={() => setSaveModal(false)}/>
      <SaveEditAgreementModal isOpen={stateSaveEditModal} header="Сохранить изменения"
                              onClose={() => setSaveEditModal(false)}/>
      <DeclarationModal data={{
        openSaveModal: () => setSaveModal(true)
      }} isOpen={stateDeclarationModal} header="Вариант заполнения"
                              onClose={() => setDeclarationModal(false)}/>
    </>
  )
};

export default CreateRequest;