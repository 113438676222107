import styled        from "styled-components";
import {blue, blue3} from "../../../../globalStyledComponents";

export const StyledFeatures = styled.div` 
  max-width: 46rem; 
  h1 {
    color: ${blue};
    margin-bottom: 3rem;
  }
`;

export const StyledFeaturesWrapper = styled.div`
  padding: 4rem 1.5rem;
  min-height: calc(100vh - 8rem);
  background: ${blue3};
  display: flex;
  justify-content: center;
  align-items: center;
  
  @media screen and (max-width: 767px) {
     padding: 2rem 1.5rem;
     min-height: calc(100vh - 4rem);
  }
`;

export const StyledFeaturesItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  svg {
    min-width: 1.5rem;
  }
  p {
    padding-left: 1.5rem;
  }
`;