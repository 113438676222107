import React                     from "react";
import Button                    from "../../../uiKit/button/button";
import Modal, {ModalProps}       from "../../../uiKit/modal";
import {StyledButtonsGroup}      from "../../roles/components/header/styles";
import {TextButton}              from "../../../globalStyledComponents";
import {StyledSaveAgreementText} from "../../createRequest/components/saveAgreementModal/styles";

export const BigDiscountModal = ({header, isOpen, onClose}: ModalProps) => {

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => onClose()}
      header={header}>
      <StyledSaveAgreementText>
        <TextButton>
          Введенная вами скидка превышает установленный лимит! Пожалуйста, скорректируйте значение или направьте заявку на согласование.
        </TextButton>
      </StyledSaveAgreementText>
      <StyledButtonsGroup>
        <Button onClick={() => onClose()} appearance="transparent">
          Изменить скидку
        </Button>
        <Button type="submit" appearance="filled">
          На согласование
        </Button>
      </StyledButtonsGroup>
    </Modal>
  )
};

export default BigDiscountModal;