import {CONSTANT_PAY_ISN, getKiasDate, getValueFromObjectsAttributes, ONE_TIME_PAY_ISN, TARIFF_ISN, TITLE_DATE_END_ISN} from "../../../../helpers/buildTemplateForBack";
import {IFormField}                                                                                                     from "../../../../uiKit/fieldsBuilder/interfaces";
import {StyledObjectHeader}             from "../../styles";
import {Heading4}                       from "../../../../globalStyledComponents";
import Button                           from "../../../../uiKit/button/button";
import {TrashIcon}                      from "../../../../icons/ui/Trash";
import FieldsBuilder                    from "../../../../uiKit/fieldsBuilder";
import React                            from "react";
import {IObjectProps}                   from "../../index";

export const TitleObject = ({object, index, onDelete, isDisabledDelete, isDisabled}: IObjectProps) => {
  const {AGROBJECT_ADDATTR: attributes, AGRCOND: agrCond, AGROBJADDR: address} = object || {};
  const {row: rowAttributes} = attributes || {}
  const {row: rowAgrCond} = agrCond || {}
  const {row: rowAddress} = address || {}
  const {PremiumSum: premium, LimitSum: summ} = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} || {} : rowAgrCond || {}
  const tariff = ((rowAttributes || []).find(({ATTRISN}) => ATTRISN === TARIFF_ISN) || {}).VALUE;
  const titleDateEnd = ((rowAttributes || []).find(({ATTRISN}) => ATTRISN === TITLE_DATE_END_ISN) || {}).VALUE;
  const isConstantPay: boolean = getValueFromObjectsAttributes(object, CONSTANT_PAY_ISN) === "1";
  const isOneTimePay: boolean = getValueFromObjectsAttributes(object, ONE_TIME_PAY_ISN) === "1";

  const form: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "address",
          name: `titleAddress-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: "Адрес предмета титула",
          defaultValue: (rowAddress || {}).ADDRESS
        },
        {
          type: "date",
          name: `titleDateEnd-${index}`,
          isDisabled,
          // isRequired: true,
          // errorMessage: "",
          placeholder: "Дата окончания титула",
          defaultValue: getKiasDate(titleDateEnd)
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "number",
          name: `titleSumInsured-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: "Страховая сумма",
          digitsAfterDot: 2,
          defaultValue: summ
        },
        {
          type: "number",
          name: `titleTariff-${index}`,
          // isRequired: true,
          // errorMessage: "",
          digitsAfterDot: 4,
          isDisabled: true,
          placeholder: "Тариф",
          defaultValue: tariff
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "number",
          name: `titlePremium-${index}`,
          // isRequired: true,
          // errorMessage: "",
          digitsAfterDot: 2,
          isDisabled: true,
          placeholder: "Премия",
          defaultValue: premium
        },
      ]
    },
  ]

  const payCheckbox: IFormField[] = [{
    columns: 4,
    fieldItems: [
      {type: "checkbox", isDisabled, defaultValue: isConstantPay, name: `titleConstantPay-${index}`, label: "Постоянная страховая сумма"},
      {type: "checkbox", isDisabled, defaultValue: isOneTimePay, name: `titleOneTimePay-${index}`, label: "Единовременная оплата"}
    ]
  }]

  return (
    <>
      <FieldsBuilder formFields={payCheckbox}/>
      <StyledObjectHeader>
        <Heading4>Объект страхования {index + 1}</Heading4>
        <Button
          appearance="icon"
          isDisabled={isDisabledDelete || isDisabled}
          onClick={ev => {
            ev.preventDefault();
            onDelete()
          }} icon={<TrashIcon/>}/>
      </StyledObjectHeader>
      <FieldsBuilder formFields={form}/>
    </>
  )
}