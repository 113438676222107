import React                       from 'react';
import Select                      from "../select";
import Enums                       from "../enums";
import Radio                       from "../radio";
import {StyledFormField}           from "../../pages/createRequest/components/precalculationForm/components/precalculationForm/styles";
import {Field}                     from '@atlaskit/form';
import NivaInput                   from "../nivaInput/NivaInput";
import {TextButton}                from "../../globalStyledComponents";
import {StyledFieldColumnsWrapper} from "./styles";
import Checkbox                    from "../checkbox";
import {
  IAddressField, IAddressFormValue,
  ICheckboxField,
  ICheckboxFormValue,
  IDateIntervalField,
  IDateIntervalFormValue,
  IDatePickerField,
  IDatePickerFormValue,
  IEnumsField,
  IEnumsFormValue,
  IFormField,
  IInputField,
  IInputFormValue, INumberField, INumberFormValue,
  IRadioField,
  IRadioFormValue,
  IRangeField,
  IRangeFormValue,
  ISelectField,
  ISelectFormValue,
  ITextAreaField,
  ITextAreaFormValue, IToggleField, IToggleFormValue
}                                  from "./interfaces";
import RangePicker                 from "../rangePicker";
import {fieldsValidate}            from "../../formUtils/fieldsValidation";
import DatePicker                  from "../datePicker";
import TextArea                    from "../textArea";
import DateIntervalPicker          from "../dateIntervalPicker";
import DaDataEditor                from "../daData";
import {NumberInputSwitcher}       from "../floatInput";
import ToggleCheckbox              from "../toggleCheckbox";
import DaDataFio                   from "../daDataFioNew";
import DaDataInn from "../daDataInn";

const InputField = ({name, inputType, debounceOffset, isRequired, defaultValue, isLoading, height, debounce, maxValue, minValue, isControlled, isDisabled, errorMessage, type, mask, validationType, onChange, placeholder}: IInputField) => {
  const valueForForm: IInputFormValue = {
    value: defaultValue || "",
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }

  if (validationType === "minMax" && (typeof maxValue === "undefined" && typeof minValue === "undefined")) return <NivaInput
    placeholder={placeholder} isLoading/>

  return (
    <Field name={name} defaultValue={valueForForm}
           validate={(value) => fieldsValidate({value: value.value, validationType, maxValue, minValue})}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue || "";
        return (
          <NivaInput
            {...fieldProps}
            mask={mask}
            isLoading={!!isLoading}
            debounce={!!debounce}
            debounceOffset={debounceOffset}
            isControlled={!!isControlled}
            isDisabled={!!isDisabled}
            errorMessage={error}
            height={height}
            isRequired={!!isRequired}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            type={inputType}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const NumberField = ({name, isRequired, defaultValue, digitsAfterDot, isLoading, height, minValue, isDisabled, errorMessage, type, validationType, maxValue, onChange, placeholder}: INumberField) => {

  const valueForForm: INumberFormValue = {
    value: defaultValue || "",
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }

  if (validationType === "minMax" && (typeof maxValue === "undefined" && typeof minValue === "undefined")) return <NivaInput
    placeholder={placeholder} isLoading/>
  return (
    <Field name={name} defaultValue={valueForForm}
           validate={(value) => fieldsValidate({value: value.value, validationType, maxValue, minValue})}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue || "";
        return (
          <NumberInputSwitcher
            {...fieldProps}
            isDisabled={!!isDisabled}
            errorMessage={error}
            isLoading={isLoading}
            height={height}
            isRequired={!!isRequired}
            digitsAfterDot={digitsAfterDot}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const RangeField = ({name, onChange, defaultValue, endValue, isDisabled, validationType, minValue, errorMessage, type, maxValue, isLoading, isRequired, placeholder, startValue}: IRangeField) => {
  const valueForForm: IRangeFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }

  if (validationType === "minMax" && (typeof maxValue === "undefined" && typeof minValue === "undefined")) return <RangePicker
    startValue={startValue} endValue={endValue} placeholder={placeholder} isLoading/>

  return (
    <Field
      name={name} defaultValue={valueForForm}
      validate={value => fieldsValidate({value: value.value, validationType: validationType || "minMax", maxValue: maxValue || endValue, minValue: minValue || startValue})}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue;
        return (
          <RangePicker
            {...fieldProps}
            errorMessage={error}
            isRequired={!!isRequired}
            isLoading={!!isLoading}
            isDisabled={isDisabled}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            startValue={startValue}
            endValue={endValue}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const DatePickerField = ({name, isRequired, defaultValue, isDisabled, maxDate, minDate, errorMessage, validationType, type, mask, onChange, placeholder}: IDatePickerField) => {
  const valueForForm: IDatePickerFormValue = {
    value: defaultValue || undefined,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm} validate={value => fieldsValidate({value: value.value, validationType: validationType || "date", maxDate, minDate})}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue || undefined;
        return (
          <DatePicker
            {...fieldProps}
            mask={mask}
            errorMessage={error}
            isDisabled={isDisabled}
            isRequired={!!isRequired}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const SelectField = ({name, defaultValue, onChange, isSearchAvailable, height, isClearable, returnFullValue, isDisabled, isRequired, errorMessage, type, emptyMessage, items, placeholder, isLoading}: ISelectField) => {
  const valueForForm: ISelectFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    returnFullValue: !!returnFullValue,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue;
        return (
          <Select
            {...fieldProps}
            isLoading={!!isLoading}
            errorMessage={error}
            isRequired={!!isRequired}
            isSearchAvailable={isSearchAvailable}
            isDisabled={isDisabled}
            height={height}
            isClearable={!!isClearable}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            emptyMessage={emptyMessage}
            items={items}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const RadioField = ({name, defaultValue, onChange, returnFullValue, isRequired, isDisabled, errorMessage, type, items, columnGap, columns}: IRadioField) => {
  const valueForForm: IRadioFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    returnFullValue: !!returnFullValue,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue
        return (
          <Radio
            {...fieldProps}
            isDisabled={isDisabled}
            columnGap={columnGap}
            columns={columns}
            errorMessage={error}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            items={items}
          />
        )
      }}
    </Field>
  );
}
const AddressField = ({name, defaultValue, placeholder, isDisabled, onChange, validationType, isRequired, type, errorMessage}: IAddressField) => {
  const valueForForm: IAddressFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }

  const getFieldProps = () => ({
    name,
    defaultValue: valueForForm,
    ...(validationType ? {validate: value => fieldsValidate({value: value.value, validationType})} : {})
  })

  const getProps = (fieldProps, error) => ({
    ...fieldProps,
    isDisabled,
    isRequired,
    errorMessage: error,
    placeholder,
    onChange: (value) => {
      fieldProps.onChange({...valueForForm, value})
      if (onChange) onChange(value)
    }
  })

  return (
    <Field {...getFieldProps()}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue
        return (
          <DaDataEditor {...getProps(fieldProps, error)}/>
        )
      }}
    </Field>
  );
}
const FioField = ({name, defaultValue, placeholder, isDisabled, onChange, validationType, isRequired, type, errorMessage}: IAddressField) => {
  const valueForForm: IAddressFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }

  const getFieldProps = () => ({
    name,
    defaultValue: valueForForm,
    ...(validationType ? {validate: value => fieldsValidate({value: value.value, validationType})} : {})
  })

  const getProps = (fieldProps, error) => ({
    ...fieldProps,
    isDisabled,
    isRequired,
    errorMessage: error,
    placeholder,
    onChange: (value) => {
      fieldProps.onChange({...valueForForm, value})
      if (onChange) onChange(value)
    }
  })

  return (
    <Field {...getFieldProps()}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue
        return (
          <DaDataFio {...getProps(fieldProps, error)}/>
        )
      }}
    </Field>
  );
}
const InnField = ({name, defaultValue, placeholder, isDisabled, onChange, validationType, isRequired, type, errorMessage}: IAddressField) => {
  const valueForForm: IAddressFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }

  const getFieldProps = () => ({
    name,
    defaultValue: valueForForm,
    ...(validationType ? {validate: value => fieldsValidate({value: value.value, validationType})} : {})
  })

  const getProps = (fieldProps, error) => ({
    ...fieldProps,
    isDisabled,
    isRequired,
    errorMessage: error,
    placeholder,
    onChange: (value) => {
      fieldProps.onChange({...valueForForm, value})
      if (onChange) onChange(value)
    }
  })

  return (
      <Field {...getFieldProps()}>
        {({fieldProps, error}) => {
          fieldProps.value = defaultValue
          return (
              <DaDataInn {...getProps(fieldProps, error)}/>
          )
        }}
      </Field>
  );
}

const EnumsField = ({name, defaultValue, onChange, isRequired, errorMessage, type, items, emptyMessage, placeholder, isLoading}: IEnumsField) => {
  const valueForForm: IEnumsFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue
        return (
          <Enums
            {...fieldProps}
            isLoading={!!isLoading}
            errorMessage={error}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            isRequired={!!isRequired}
            placeholder={placeholder}
            emptyMessage={emptyMessage}
            items={items}
          />
        )
      }}
    </Field>
  );
}
const CheckboxField = ({name, onChange, type, errorMessage, defaultValue, isRequired, isDisabled, label}: ICheckboxField) => {
  const valueForForm: ICheckboxFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue
        return (
          <Checkbox
            {...fieldProps}
            errorMessage={error}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            label={label}
            isDisabled={isDisabled}
          />
        )
      }}
    </Field>
  );
}

const ToggleField = ({name, onChange, type, errorMessage, defaultValue, isRequired, isDisabled, activeLabel, deactivatedLabel, label}: IToggleField) => {
  const valueForForm: IToggleFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue
        return (
          <ToggleCheckbox
            {...fieldProps}
            errorMessage={error}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            label={label}
            activeLabel={activeLabel}
            deactivatedLabel={deactivatedLabel}
            isDisabled={isDisabled}
          />
        )
      }}
    </Field>
  );
}

const TextAreaField = ({name, onChange, defaultValue, type, errorMessage, isRequired, placeholder}: ITextAreaField) => {
  const valueForForm: ITextAreaFormValue = {
    value: defaultValue || "",
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue || "";
        return (
          <TextArea
            {...fieldProps}
            isRequired={isRequired}
            errorMessage={error}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const DateIntervalField = ({name, onChange, defaultValue, type, errorMessage, isRequired, placeholder}: IDateIntervalField) => {
  const valueForForm: IDateIntervalFormValue = {
    value: defaultValue,
    errorMessage: errorMessage,
    isRequired: !!isRequired,
    type
  }
  return (
    <Field name={name} defaultValue={valueForForm}>
      {({fieldProps, error}) => {
        fieldProps.value = defaultValue;
        return (
          <DateIntervalPicker
            {...fieldProps}
            errorMessage={error}
            isRequired={!!isRequired}
            onChange={(value) => {
              fieldProps.onChange({...valueForForm, value})
              if (onChange) onChange(value)
            }}
            placeholder={placeholder}
          />
        )
      }}
    </Field>
  );
}

const FieldSwitcher = (props: any) => {
  const {type, isHidden} = props;
  if (isHidden) return null;
  switch (type) {
    case "input":
      return <InputField {...props}/>
    case "number":
      return <NumberField {...props}/>
    case "select":
      return <SelectField {...props}/>
    case "radio":
      return <RadioField {...props}/>
    case "enums":
      return <EnumsField {...props}/>
    case "checkbox":
      return <CheckboxField {...props}/>
    case "toggle":
      return <ToggleField {...props}/>
    case "range":
      return <RangeField {...props}/>
    case "date":
      return <DatePickerField {...props}/>
    case "textarea":
      return <TextAreaField {...props}/>
    case "dateInterval":
      return <DateIntervalField {...props}/>
    case "address":
      return <AddressField {...props}/>
    case "fio":
      return <FioField {...props}/>
    case "inn":
      return <InnField {...props}/>
    default:
      return null
  }
}


export const FieldsBuilder = ({formFields}: { formFields: IFormField[] }) => {
  if (!formFields || (formFields || []).length === 0) return null;

  return (
    <>
      {formFields.map(({fieldItems, label, isHidden, fieldMargin, columns, rowGap, columnGap}, index) => {
        if (isHidden) return null;
        return (
          <StyledFormField key={`field-${index}`} margin={typeof fieldMargin === "undefined" ? undefined : fieldMargin}>
            {label && <TextButton>{label}</TextButton>}
            <StyledFieldColumnsWrapper columns={columns} rowGap={rowGap || 1.5} columnGap={columnGap || 0}>
              {Array.isArray(fieldItems) ?
                fieldItems.map((field, index) => {
                  if (field.isHidden) return null;
                  return <FieldSwitcher key={`field-item-${index}`} {...field}/>
                })
                : <FieldSwitcher {...fieldItems}/>}
            </StyledFieldColumnsWrapper>
          </StyledFormField>
        )
      })}
    </>
  )
};

export default FieldsBuilder;