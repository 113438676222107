export const fileDownloader = (url?: string, name?: string) => {
  if (!url) return;
  if (!name) return window.location.assign(url);

  const link = document.createElement("a");
  link.setAttribute('download', name);
  link.setAttribute('href', url);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};