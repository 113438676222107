import styled  from "styled-components";
import {white} from "../../globalStyledComponents";

export const StyledMyAgreementWrapper = styled.div`
  margin-top: 4rem;
  background: ${white};
  padding: 4rem;
  box-shadow: 0 2px 20px rgba(0, 61, 129, 0.1);

  @media screen and (max-width: 767px) {
    margin-top: 2rem;
    padding: 1.5rem;
  }
`;
