import styled from "styled-components";

export const StyledSaveAgreementWrapper = styled.div`
h2 {
  margin-bottom: 2rem;
}
`;

export const StyledSaveAgreementText = styled.div`
  > p {
    margin-bottom: 3rem;
  }
`;
