import React                         from 'react';
import {
  StyledGroupBodyLoader,
  StyledGroupToggle,
  StyledGroupToggleBody,
  StyledGroupToggleButtons,
  StyledGroupToggleHeader,
  StyledGroupToggleWrapper
}                                    from "./styles";
import {IGroupItem}                  from "../../index";
import {Heading4, Text3, TextButton} from "../../../../../../globalStyledComponents";
import Button
                                     from "../../../../../../uiKit/button/button";
import {TrashIcon}              from "../../../../../../icons/ui/Trash";
import {AddIcon}                from "../../../../../../icons/ui/Add";
import {GroupToggleArrowIcon}   from "../../../../../../icons/ui/GroupToggleArrow";
import {useLazyQuery, useQuery} from "@apollo/react-hooks";
import {GROUPS, ME, USERS}      from "../../../../../../utils/queries";
import {Loader}                 from "../../../../../../uiKit/loader";
import {DeleteGroupModal}       from "../deleteGroupModal";
import CreateUserModal          from "../../../header/createUserModal";
import {StyledEmptyGroup}       from "../../styles";
import {useCallNotification}    from "../../../../../../hooks/useCallNotification";
import {DeleteUserModal}        from "../deleteUserModal";
import {PencilIcon}             from "../../../../../../icons/ui/Pencil";
import CreateGroupModal         from "../../../header/createGroupModal";

const getSpecialUser = (users: IUserProps[], type?: "noneGroup" | "clients") => {
  if (!users.length || !type) return [];
  switch (type) {
    case "clients":
      return users.filter(({type}) => type === "client");
    case "noneGroup":
      return users.filter(({group, type}) => !group && type !== "client");
    default:
      return [];
  }
}

interface IUserProps {
  id: string
  name: string
  email: string
  login: string
  phone: string
  role: string
  type: string
  group: string
  isLeader: boolean
  isAdmin: boolean
  showKv: boolean
  isEnabled: boolean
}

const GroupToggleBody = ({id, specialGroup, leaderId}: IGroupItem) => {
  const {setNotification} = useCallNotification();
  const [stateUserModal, setUserModal] = React.useState<undefined | IUserProps>(undefined);
  const [stateDeleteUserModal, setDeleteUserModal] = React.useState<undefined | string>(undefined);
  const [getGroups, {data: dataGroups, loading: loadingGroups, error: errorGroups}] = useLazyQuery(GROUPS);
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const [getUsers, {data: dataUsersInGroup, loading: loadingUsersInGroup, error: errorUsersInGroup}] = useLazyQuery(USERS);
  const isAdmin: boolean = ((dataUser||{}).me||{}).type === "admin"

  React.useEffect(() => {
    if (!id || specialGroup) return;
    getGroups({
      variables: {
        children: true,
        parent: id
      }
    })
// eslint-disable-next-line
  }, [id])
  React.useEffect(() => {
    const variablesUser = specialGroup && id ? {} : {group: id};
    getUsers({variables: {...variablesUser}})
// eslint-disable-next-line
  }, [id])

  const groups: IGroupItem[] = ((dataGroups || {}).groups || []).map(({id, name, leader, email, curator, parent}) => ({
    id,
    name,
    email,
    leaderId: (leader || {}).id,
    curatorId: (curator || {}).id,
    parentId: (parent || {}).id,
    isAdmin
  }));
  const users: IUserProps[] = ((dataUsersInGroup || {}).users || []).map(({id: userId, login, isAdmin, name, type, email, phone, role, group, show_kv, is_enabled}) => ({
    id: userId,
    name,
    login,
    email,
    phone,
    type,
    role: (role || {}).id,
    group: (group || {}).id,
    isAdmin,
    isLeader: userId === leaderId,
    showKv: !!show_kv,
    isEnabled: is_enabled
  }));

  const specialUsers: IUserProps[] = getSpecialUser(users, specialGroup)
  const usersList: IUserProps[] = specialGroup ? specialUsers : users;
  const items: number = groups.length + usersList.length;

  if (errorUsersInGroup) setNotification({type: "error", text: errorUsersInGroup})
  if (errorGroups) setNotification({type: "error", text: errorGroups})
  if (errorUser) setNotification({type: "error", text: errorUser})
  if (loadingGroups || loadingUsersInGroup || loadingUser) return <StyledGroupBodyLoader><Loader
    type="simple"/></StyledGroupBodyLoader>
  return (
    <StyledGroupToggleBody>
      {items === 0 && <StyledEmptyGroup><TextButton>В этой группе пока пусто</TextButton></StyledEmptyGroup>}
      {usersList.length !== 0 && usersList.map(props => {
        const {name, id} = props;
        return (
          <StyledGroupToggle key={`user-${id}`}>
            <StyledGroupToggleHeader onClick={() => setUserModal({...props})}>
              <Text3>{name}</Text3>
            </StyledGroupToggleHeader>
            {isAdmin && <Button appearance="icon" icon={<TrashIcon/>} onClick={() => setDeleteUserModal(id)}/>}
          </StyledGroupToggle>
        )
      })}
      {groups.length !== 0 && groups.map((props, index) => (
          <GroupToggle key={`subGroup-${index}`} {...props}/>
      ))}
      <CreateUserModal header="Редактирование пользователя" onClose={() => setUserModal(undefined)}
                       isOpen={!!stateUserModal} data={{...stateUserModal, isEdit: true, isDisabledEdit: specialGroup === "clients"}}/>
      <DeleteUserModal header="Удалить пользователя" onClose={() => setDeleteUserModal(undefined)}
                       isOpen={!!stateDeleteUserModal} data={{id: stateDeleteUserModal}}/>
    </StyledGroupToggleBody>
  )
};

export const GroupToggle = (props: IGroupItem) => {
  const [stateEditGroupModal, setEditGroupModal] = React.useState<IGroupItem | undefined>(undefined);
  const [stateCreateUserModal, setCreateUserModal] = React.useState<boolean>(false);
  const [stateDeleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [stateToggleOpen, setToggleOpen] = React.useState<boolean>(false);
  const {name, id, specialGroup} = props;

  return (
    <StyledGroupToggleWrapper>
      <StyledGroupToggle>
        <StyledGroupToggleHeader onClick={() => setToggleOpen(!stateToggleOpen)}>
          <GroupToggleArrowIcon direction={stateToggleOpen ? "down" : "right"}/>
          <Heading4>{name}</Heading4>
        </StyledGroupToggleHeader>

        {!specialGroup && <StyledGroupToggleButtons>
            <Button tooltip="Удалить группу" onClick={() => setDeleteModal(true)} appearance="icon"
                    icon={<TrashIcon/>}/>
            <Button tooltip="Редактировать" onClick={() => setEditGroupModal(props)} appearance="icon"
                    icon={<PencilIcon/>}/>
            <Button tooltip="Добавить пользователя" onClick={() => setCreateUserModal(true)} appearance="icon"
                    icon={<AddIcon/>}/>
        </StyledGroupToggleButtons>}
      </StyledGroupToggle>
      {stateToggleOpen && <GroupToggleBody {...props}/>}
      <DeleteGroupModal onClose={() => setDeleteModal(false)} isOpen={stateDeleteModal} data={{id}}/>
      <CreateUserModal header="Добавить пользователя в группу" onClose={() => setCreateUserModal(false)}
                       isOpen={stateCreateUserModal} data={{group: id}}/>
      <CreateGroupModal header="Редактировать группу" onClose={() => setEditGroupModal(undefined)}
                       isOpen={!!stateEditGroupModal} data={{...stateEditGroupModal, isEdit: true}}/>
    </StyledGroupToggleWrapper>
  )
};

export default GroupToggle;