import React, { useCallback }                      from 'react';
import { CSSTransition }                           from 'react-transition-group';
import { WrapperDaData }                                   from './styles';
import {IDaDataProps, IDaDataValue, InnValue, TypesDaData} from './types';
import DropDownDaDataContent                               from './dropDownDaDataContent';
import NivaInput                                   from "../nivaInput/NivaInput";
import Button                                      from "../button/button";
import {CloseIcon}                                 from "../../icons/ui/Close";
import ClickAway                                   from "../clickAway";

const dadataToken = `${process.env.REACT_APP_DADATA_TOKEN}`;
let timeout: ReturnType<typeof setTimeout>;

const getDefaultDaData = async (address: string, type: TypesDaData): Promise<IDaDataValue[] | null> => {
	const url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${type}`;
	const options: RequestInit = {
		method: 'POST',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: `Token ${dadataToken}`,
		},
		body: JSON.stringify({ query: address }),
	};
	const response = await fetch(url, options);
	if (response.status === 200) {
		const { suggestions } = (await response.json()) || {};
		if (suggestions) return suggestions;
		return null;
	}
	return null;
};

const DaDataInn: React.FC<IDaDataProps> = React.forwardRef((props, ref) => {
	const { dropDownHelpMessage, value, isLoading, onChange, type, emptyMessage, debounceMs = 500, ...rest } = props;
	const [stateInputValue, setInputValue] = React.useState<InnValue | null>(value || null);
	const [itemsFromDaData, setItemsFromDaData] = React.useState<IDaDataValue[] | null>(null);
	const [isSearching, setIsSearching] = React.useState<boolean>(false);
	const [dropDownContentIsOpen, setDropDownContentIsOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!value?.value) return;
		setInputValue(value);
	}, [value]);

	React.useEffect(() => {
		if (stateInputValue) {
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => {
				setIsSearching(true);
				getDefaultDaData(stateInputValue.value, 'party')
					.then((data: IDaDataValue[] | null) => {
						if (!data) return;
						setItemsFromDaData(data);
					})
					.finally(() => setIsSearching(false));
			}, debounceMs);
		}
	}, [stateInputValue, type, debounceMs]);

	const inputChangeHandler = useCallback(
		(value: string): void => {
			if (!dropDownContentIsOpen) setDropDownContentIsOpen(true);
			setInputValue(state => ({...state, value}));
			if (onChange) onChange({...stateInputValue, value})
		},
		[dropDownContentIsOpen, stateInputValue, onChange]
	);

	const clearHandler = useCallback((): void => setInputValue(null), []);

	const daDataChangeHandler = useCallback(
		(currentValue: IDaDataValue): void => {
			const {value, data: {address}} = currentValue || {};
			setInputValue({value, address});
			setDropDownContentIsOpen(false);
			if (onChange) onChange({value, address})
		},
		[onChange]
	);

	return (
		<WrapperDaData>
			<ClickAway isOpen={dropDownContentIsOpen} onClickAway={(): void => setDropDownContentIsOpen(false)}>
				<NivaInput
					{...rest}
					isLoading={isLoading || isSearching}
					value={stateInputValue?.value || ''}
					isControlled={true}
					iconAfter={!!stateInputValue && <Button onClick={(): void => clearHandler()} appearance='icon' icon={<CloseIcon />} />}
					onChange={inputChangeHandler}
				/>
				<CSSTransition
					in={dropDownContentIsOpen}
					timeout={300}
					mountOnEnter
					unmountOnExit
					classNames={{
						enterActive: 'listShow',
						enterDone: 'listShow',
						exitActive: 'listHide',
						exitDone: 'listHide',
					}}
				>
					<DropDownDaDataContent
						dropDownHelpMessage={dropDownHelpMessage}
						inputValue={stateInputValue?.value || ''}
						emptyMessage={emptyMessage}
						items={itemsFromDaData}
						daDataChangeHandler={daDataChangeHandler}
					/>
				</CSSTransition>
			</ClickAway>
		</WrapperDaData>
	);
});
export default React.memo(DaDataInn);
