import React                 from "react";
import {StyledLoadingString} from "./styles";

interface ILoadingStringProps {
  width?: number
  height?: number
  count?: number
  isLoading?: boolean,
  children?: any
}

export const LoadingString = ({width, height, isLoading, children, count} : ILoadingStringProps) => {

  if (isLoading) return (
    <StyledLoadingString width={typeof width === "number" ? width : 12} height={typeof height === "number" ? height : 1.5}/>
  )
  return (<>{children}</>)
};

export default LoadingString;