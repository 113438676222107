import React                      from "react";
import {useHistory, useParams}                                        from "react-router";
import {Styled404, Styled404content, Styled404Page, Styled404Wrapper} from "./syles";
import Button               from "../../uiKit/button/button";
import {Heading1, Heading2} from "../../globalStyledComponents";

export const Page404 = () => {
  const history = useHistory();
  const {type} = useParams();
  const isRightError: boolean = type === "rights";

  const onMouseMove = (ev) => {
    const contentElement = document.getElementById("page404Content");
    if (!contentElement) return;
    const x: number = (window.innerWidth / 2 - ev.pageX) / 10;
    const y: number = (window.innerHeight / 2 - ev.pageY) / 10;
    contentElement.style.transform = `rotateY(${x}deg) rotateX(${-y}deg)`;
  }
  const onMouseEnter = () => {
    const contentElement = document.getElementById("page404Content");
    const titleElement = document.getElementById("title404");
    const textElement = document.getElementById("text404");
    const buttonElement = document.getElementById("backButton404");
    if (!contentElement || !titleElement || !textElement || !buttonElement) return;

    // content
    contentElement.style.willChange = "transition, transform";
    contentElement.style.transition = "unset";

    // title
    titleElement.style.willChange = "transition, transform";
    titleElement.style.transition = "all 0.5s";
    titleElement.style.transform = "translateZ(110px)"

    // 404
    textElement.style.willChange = "transition, transform";
    textElement.style.transition = "all 0.5s";
    setTimeout(() => {
      textElement.style.transform = "translateZ(150px)"
    }, 300)

    // button
    buttonElement.style.willChange = "transition, transform";
    buttonElement.style.transition = "all 0.5s";
    buttonElement.style.transform = "translateZ(110px)"

  }
  const onMouseLeave = () => {
    const contentElement = document.getElementById("page404Content");
    const titleElement = document.getElementById("title404");
    const textElement = document.getElementById("text404");
    const buttonElement = document.getElementById("backButton404");
    if (!contentElement || !titleElement || !textElement || !buttonElement) return;

    // content
    contentElement.style.transition = "all 0.7s";
    contentElement.style.transform = "rotateY(0deg) rotateX(0deg)";

    // title
    titleElement.style.transform = "translateZ(0px)"

    // 404
    setTimeout(() => {
      textElement.style.transform = "translateZ(0px)"
    }, 300)

    // button
    buttonElement.style.transform = "translateZ(0px)"

  }

  React.useEffect(() => {
    const pageElement = document.getElementById("page404Wrapper");
    if (!pageElement) return;
    pageElement.addEventListener("mousemove", (ev) => onMouseMove(ev))
    pageElement.addEventListener("mouseenter", () => onMouseEnter())
    pageElement.addEventListener("mouseleave", () => onMouseLeave())
  }, [])

  return (
    <Styled404Page>
      <Styled404Wrapper id={"page404Wrapper"}>
        <Styled404content id={"page404Content"}>
        <Heading2 id={"title404"}>{isRightError ? "к сожалению у вас нет прав" : "такой страницы не существует"}</Heading2>
        <Styled404 id={"text404"}>
          <Heading1>4</Heading1>
          <Heading1>0</Heading1>
          <Heading1>4</Heading1>
        </Styled404>
        <Button id={"backButton404"} appearance="transparent" onClick={() => history.go(isRightError ? -2 : -1)}>вернуться назад</Button>
        </Styled404content>
      </Styled404Wrapper>
    </Styled404Page>
  )
};

export default Page404;