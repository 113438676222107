import styled        from "styled-components";
import {blue3, grey} from "../../../../../../globalStyledComponents";

export const StyledDownloadFile = styled.div`
  margin-bottom: 3rem;
`;

export const StyledAddNewComment = styled.div`
  padding: 0 4rem;
  @media screen and (max-width: 767px) {
      padding: 0 1.5rem 0 0;
  }
`;

export const StyledDownloadFileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  :first-child {
    margin-top: 1rem;
  }
`;

export const StylesDocsHintWrapper = styled.div`
  > h4 {
    margin-bottom: 2rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
`;

export const StyledCommentToggleWrapper = styled.div`
  .emptyToggleMessage {
    padding-left: 4rem;
  }
  
    @media screen and (max-width: 767px) {
      .emptyToggleMessage {
        padding-left: 1.5rem;
      }
  }
`;

export const StyledComments = styled.div`
  max-height: 50rem;
  overflow: auto;
  > .linkButton {
    margin: 0 0 1.5rem 4rem;
  }
    
  @media screen and (max-width: 767px) {
    > .linkButton {
      margin: 0 0 1.5rem 0;
    }
      margin: 0 0 1.5rem 1.5rem;
  }
`;

export const StyledCommentItem = styled.div<{isNew: boolean}>`
  display: flex;
  align-items: flex-start;
  padding: 1.5rem 4rem 1.5rem 4rem;
  transition: background-color 0.5s;
  ${({isNew}) =>
    isNew ? `background-color: ${blue3};` : ""
  }
  
  @media screen and (max-width: 767px) {
      padding: 1.5rem;
  }
`;
export const StyledCommentHeader = styled.div`
  display: flex;
  align-items: center;
 
  h5:last-child {
    opacity: 0.5;
    margin-left: 1rem;
  }
  
  margin-bottom: 1.5rem;
`;

export const StyledProfileLogo = styled.div`
  min-width: 1.9rem;
  max-width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  margin-right: 1rem;
  position: relative;
  
  > img {
    width: 100%;
  }
  > svg {
    opacity: 0.5;
  }
`;

export const StyledCommentBody = styled.div`
  
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  button:last-child {
    margin-left: 1.5rem;
  }
`;
export const StyledUploadFileInToggle = styled.div`  
  margin-bottom: 2rem;
`;

export const StyledDownloadAllButton = styled.div`  
  margin-bottom: 2rem;
`;

export const StyledDivider = styled.div`  
  margin-bottom: 2.5rem;
  width: 100%;
  height: 1px;
  background: ${grey};
`;
