import styled from "styled-components";
import {grey} from "../../globalStyledComponents";

export const StyledCreateInvoiceWrapper = styled.div`
  > h2 {
    margin-bottom: 4.5rem;
  }
  
    @media screen and (max-width: 767px) {
  > h2 {
    margin-bottom: 2rem;
  }
  }
`;
export const StyledLeftCreateInvoiceArea = styled.div`
  border-right: 1px solid ${grey};
  padding: 4rem;
  
  @media screen and (max-width: 767px) {
  border: unset;
    padding: 3rem 1.5rem;
      > h3 {
    margin-bottom: 2rem;
  }
  }
`;
export const StyledRightCreateInvoiceArea = styled.div`

`;

export const StyledCreateInvoiceBodyWrapper = styled.div`
  border-top: 1px solid ${grey};
  display: grid;
  grid-template-columns: 1fr 1fr;
  
  @media screen and (max-width: 767px) {
  grid-template-columns: 1fr;
  }
`;

export const StyledInvoiceCurrency = styled.div`
  margin-top: 3rem;
  > p {
    opacity: 0.5;
  }
`;
export const StyledInvoiceDescription = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  > p:first-child {
    opacity: 0.5;
  }
`;

export const StyledInvoiceEventsButtons = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  .linkButton {
    margin-right: 2rem;
  }
`;
export const StyledInvoiceDropDown = styled.div`
  .nivanaviDropDown {
    margin-top: 2rem;
  }
`;

