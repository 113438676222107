import styled            from "styled-components";
import {darkGrey, white} from "../../globalStyledComponents";

export const StyledImagePreviewWrapper = styled.div`
  position: relative;
  overflow: hidden;
  cursor: pointer;
`;

export const StyledClosePreview = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: -13rem;
  top: -13rem;
  width: 15rem;
  height: 15rem;
  background: rgba(34, 60, 80, 0.7);
  transform: rotate(-45deg);
  transition: opacity 0.3s;

  .iconButton {
    position: absolute;
    top: 45%;
    left: -4rem;
    transform: rotate(45deg);
  }

  .iconButton > div {
    color: ${darkGrey};
  }

  .iconButton:hover > div {
    color: ${white};
  }

  box-shadow: -50px 0px 30px 20px rgba(34, 60, 80, 0.5);

  ${({isOpen}) => isOpen ?
          `
    opacity: 1;
    `
          :
          `
          opacity: 0;
          `
  }
`;

export const StyledPopup = styled.div<{ isOpen: boolean }>`
  transition: all 1s;
  position: ${props => props.isOpen ? 'fixed' : 'static'};
  z-index: ${props => props.isOpen ? '50' : '0'};
  top: 2rem;
  left: 2rem;
  right: 2rem;
  bottom: 2rem;
  overflow-y: ${props => props.isOpen ? 'auto' : 'none'};
  overflow-x: hidden;
  cursor: ${props => props.isOpen ? 'initial' : 'pointer'};
`;

export const Image = styled.img`
  max-width: 100%;
  width: 100%;
`;


