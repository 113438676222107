import styled from "styled-components";

export const StyledPaginationButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 1.5rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;