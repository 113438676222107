import React, {useEffect} from "react";
import {IFormField}   from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {IRadioItem}   from "../../../../../../uiKit/radio";
import {radioBoolean} from "../../../../staticData";
import FieldsBuilder  from "../../../../../../uiKit/fieldsBuilder";

const setStateToFieldHandler = (field, state, setstate) => {
  const {fieldItems} = field || {};
  return {
    ...field,
    fieldItems: {
      ...fieldItems,
      defaultValue: state,
      onChange: value => setstate(value)
    }
  }
};

const setAdditionalField = (field, state, setstate) => {
  const {fieldItems} = field || {};
  const {name} = fieldItems || {};
  return [{
    ...field,
    fieldItems: {
      ...fieldItems,
      name,
      defaultValue: radioBoolean[1],
      onChange: value => {
        if (value.value) return setstate([...state, name]);
        return setstate(state.filter(remarkField => remarkField !== name));
      }
    }
  },
    {
      isHidden: !state.includes(name),
      fieldItems: {
        type: "input",
        name: `${name}-remark`,
        placeholder: getRemarkPlaceholder(name),
      }
    },
  ]
};

const hiddenFieldHandler = (field, state) => ({
  isHidden: !state.value,
  ...field
})

const getRemarkPlaceholder = (name: string) => {
  switch (name) {
    case isnHospital:
      return "Укажите сколько раз, по поводу какого заболевания";
    case isnSugar:
      return "Укажите свой уровень глюкозы в крови";
    default:
      if (isnDiseases.includes(name)) return "Укажите даты, диагнозы, симптомы, жалобы, опишите подробности";
      return "Укажите подробности"
  }
};
const isnHospital: string = "nivanavi-835291";
const isnSugar: string = "nivanavi-835121";
const isnDiseases: string[] = ["nivanavi-835011", "nivanavi-835021", "nivanavi-835031", "nivanavi-835041", "nivanavi-835051", "nivanavi-835071", "nivanavi-835081", "nivanavi-835091", "nivanavi-953281", "nivanavi-835111", "nivanavi-835131", "nivanavi-835141", "nivanavi-835151", "nivanavi-835161", "nivanavi-835171", "nivanavi-835181"]
const isnPolice: string = "nivanavi-834991";

const isnWeight: string = "nivanavi-834851";
const hiddenWeight: string[] = ["nivanavi-857201"];
const isnSmoke: string = "nivanavi-834891";
const hiddenSmoke: string[] = ["nivanavi-834901", "nivanavi-834911"];
const isnAlcohol: string = "nivanavi-834921";
const hiddenAlcohol: string[] = ["nivanavi-834931", "nivanavi-834941", "nivanavi-834951", 'nivanavi-834961'];
const isnSport: string = "nivanavi-835371";
const hiddenSport: string[] = ["nivanavi-857211"];
const isnExtreme: string = "nivanavi-857231";
const hiddenExtreme: string[] = ["nivanavi-857351"];
const isnWhenDrink: string = "nivanavi-834961";
const hiddenWhenDrink: string[] = ["nivanavi-834921", "nivanavi-834931", "nivanavi-834941", "nivanavi-834951"];

export const HealthForm = ({healthPartForm}: { healthPartForm: any[] }) => {
  const [stateRemarks, setRemarks] = React.useState<string[]>([]);

  const [stateWeight, setWeight] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateSmoke, setSmoke] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateAlcohol, setAlcohol] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateSport, setSport] = React.useState<IRadioItem>(radioBoolean[1]);
  const [stateExtreme, setExtreme] = React.useState<IRadioItem>(radioBoolean[1]);

  const additionalNeedFields = healthPartForm.filter(({fieldItems: {type, name}}) => type === "radio"
    && ![isnWeight, isnSmoke, isnAlcohol, isnSport, isnExtreme, isnPolice].includes(name)).map(({fieldItems: {name}}) => name)

  const formWithHandlers: any[] = healthPartForm.reduce((prev, field) => {
    const {fieldItems} = field || {};
    const {name} = fieldItems || {};
    switch (name) {
      case isnWeight:
        return [...prev, setStateToFieldHandler(field, stateWeight, setWeight)]
      case isnSmoke:
        return [...prev, setStateToFieldHandler(field, stateSmoke, setSmoke)]
      case isnAlcohol:
        return [...prev, setStateToFieldHandler(field, stateAlcohol, setAlcohol)]
      case isnSport:
        return [...prev, setStateToFieldHandler(field, stateSport, setSport)]
      case isnExtreme:
        return [...prev, setStateToFieldHandler(field, stateExtreme, setExtreme)]
      default:
        return [...prev, field];
    }
  }, []);

  const form: IFormField[] = formWithHandlers.reduce((prev, field) => {
    const {fieldItems} = field || {};
    const {name} = fieldItems || {};

    if (hiddenWeight.includes(name)) return [...prev, hiddenFieldHandler(field, stateWeight)];
    if (hiddenWeight.includes(name)) return [...prev, hiddenFieldHandler(field, stateWeight)]
    if (hiddenSmoke.includes(name)) return [...prev, hiddenFieldHandler(field, stateSmoke)]
    if (hiddenAlcohol.includes(name)) return [...prev, hiddenFieldHandler(field, stateAlcohol)]
    if (hiddenSport.includes(name)) return [...prev, hiddenFieldHandler(field, stateSport)]
    if (hiddenExtreme.includes(name)) return [...prev, hiddenFieldHandler(field, stateExtreme)]
    if (additionalNeedFields.includes(name)) return [...prev, ...setAdditionalField(field, stateRemarks, setRemarks)];
    return [...prev, field];

  }, []);

  if (!form) return null;
  return (
    <>
      <FieldsBuilder formFields={form}/>
    </>
  )
};

export default HealthForm;