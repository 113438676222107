import React                                                                        from "react";
import {IFormField}                                                                 from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {StyledStepFormHeader, StyledStepFormWrapper}                                from "../../../generalStep/styles";
import {Heading3, TextButton}                                                       from "../../../../../../globalStyledComponents";
import FieldsBuilder                                                                from "../../../../../../uiKit/fieldsBuilder";
import Button                                                                       from "../../../../../../uiKit/button/button";
import {DownloadIcon}                                                               from "../../../../../../icons/ui/Download";
import {FileIcon}                                                                   from "../../../../../../icons/ui/File";
import {useMutation, useLazyQuery}                                                  from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, ANKETA_TEMPLATE}                                           from "../../../../../../utils/queries";
import {Loader}                                                                     from "../../../../../../uiKit/loader";
import {templateToFormRebuild}                                                      from "../../../../../../helpers/templateToFormRebuild";
import {getFormError}                 from "../../../../../../formUtils/getFormError";
import {getFormValue, getSelectValue} from "../../../../../../formUtils/getFormValue";
import StoreAgreementForm             from "../../../../store";
import Form                                                                         from '@atlaskit/form';
import {SIGN_ANKETA, UPDATE_ANKETA, UPDATE_CONTRAGENT, VERIFY_ANKETA} from "../../../../../../utils/mutations";
import {
  anketaPartsBuilder, anketaSettingsBuilder
} from "../../../../../../helpers/buildTemplateForBack";
import {fieldsValidate}                    from "../../../../../../formUtils/fieldsValidation";
import {fileDownloader}                    from "../../../../../../helpers/fileDownloader";
import {useCallNotification}               from "../../../../../../hooks/useCallNotification";
import {StyledDownloadPdf, StyledFileName} from "../onlineFilling/styles";
import HealthForm                          from "../onlineFilling/healthForm";
import {CONTRAGENT_FIELDS, ContragentForm} from "../../../generalStep";
import {BUSYNESS_FIELDS, BusynessForm}     from "../../../busynessStep";
import {AGREEMENT_FIELDS, AgreementForm}   from "../../../agreementStep";
import {AnketaPaginationButtons}           from "../../index";
import Toggle                              from "../../../../../../uiKit/toggle";
import {StyledCoBorrowerStepWrapper}       from "./styles";
import {getFormAdditionalInsurance}        from "../onlineFilling/staticData";
import UseSaveAddressPerson                from "../../../../../../hooks/useSaveAddressPerson";

export const HEALTH_ANKETA = [
  {
    id: "healthForm",
    title: "Страхование жизни, потери трудоспособности залогодателя"
  },
  {
    id: "additionalHealthForm",
    title: "Дополнительная информация о страхователе"
  },
]

export const OnlineFillingCoBorrowersForm = ({person, onClose}) => {
  const {setNotification} = useCallNotification();
  const {saveAddress, loading: loadingSaveAddress} = UseSaveAddressPerson();
  const [{agreementForm: {agreementFormState}}, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();
  const updateAgreementForm = (data: any) =>
    actionsAgreementForm.setState({
      agreementFormState: {
        ...agreementFormState,
        ...data
      }
    });
  const [stateVerifyCode, setVerifyCode] = React.useState<string>("");
  const [stateSubmitType, setSubmitType] = React.useState<"download" | "verify" | undefined>(undefined)
  const [stateOpenToggles, setOpenToggles] = React.useState<string[]>([]);

  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [downloadAnketa, {data: dataDownloadAnketa, loading: loadingDownloadAnketa, error: errorDownloadAnketa}] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [updateAnketa, {data: dataUpdateAnketa, loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const [verifyAnketa, {loading: loadingVerifyAnketa}] = useMutation(VERIFY_ANKETA);
  const [signAnketa, {data: dataSignAnketa, loading: loadingSignAnketa}] = useMutation(SIGN_ANKETA);
  const [updateContragent, {loading: loadingUpdateContragent}] = useMutation(UPDATE_CONTRAGENT);
  const urlFile = ((dataDownloadAnketa || {}).documentForm || {}).url;
  const isnAnketa = ((dataUpdateAnketa || {}).anketa || {}).isn;
  const {templateVariables, peoples} = agreementFormState || {};
  const {phoneMobile, fullName, isn: isnContragent} = person || {};
  const isSigningAnketa = (dataSignAnketa || {}).anketaSign;
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const forms = [...templateToFormRebuild(parts).filter(({id}) => id !== "insuranceForm"), ...HEALTH_ANKETA];
  const healthForm: IFormField[] = ((templateToFormRebuild(parts).find(({id}) => id === "insuranceForm") || {}).form || []);
  const {
    CheckRes: scoringCheck,
    ResultScoring,
    Koef_Height_weight: heightWeight,
    Koef_Pressure: pressure
  } = (dataSignAnketa || {}).anketaSign || {};
  const scoringResult: string | undefined = heightWeight === "МО" || pressure === "МО" ? `${ResultScoring} Необходимо прохождение медицинского обследования.` : ResultScoring;

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: {...templateVariables, role: "2"}})
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  React.useEffect(() => {
    if (!stateVerifyCode || fieldsValidate({value: stateVerifyCode, validationType: "code"})) return;
    signAnketa({
      variables: {
        isn: isnAnketa,
        code: stateVerifyCode
      }
    }).then(() => setNotification({type: "success", text: "Анкета подтверждена"}))
      .catch(error => setNotification({type: "error", text: error}))
    // eslint-disable-next-line
  }, [stateVerifyCode])

  const sendCodeForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "phone",
          validationType: "phone",
          mask: "+7 (999) 999-99-99",
          isRequired: true,
          defaultValue: phoneMobile || "",
          isDisabled: true,
          errorMessage: "Введите телефон",
          placeholder: "Номер телефона"
        },
        {
          type: "input",
          name: "code",
          mask: "999999",
          isDisabled: isSigningAnketa,
          onChange: (value) => setVerifyCode(value),
          validationType: "code",
          placeholder: "Код из СМС"
        },
      ]
    }
  ]

  const saveAnketaHandler = () => {
    if (!isnAnketa) return setNotification({type: "error", text: "Отсутствует isn анкеты"})
    if (!isSigningAnketa) return setNotification({type: "error", text: "Анкета еще не подтверждена"})
    updateAgreementForm({
      peoples: (peoples || []).map(person => {
        const {isn} = person || {};
        if (isn === isnContragent) return {
          ...person,
          isnAnketa,
          scoringCheck,
          scoringResult,
          heightWeight,
          pressure,
          fillingType: "online"
        };
        return person;
      })
    });
    onClose();
  }

  const toggleSwitchHandler = (isn: string, isOpen: boolean) => {
    if (!isOpen) return setOpenToggles(stateOpenToggles.filter(toggle => toggle !== isn));
    return setOpenToggles([...stateOpenToggles.filter(toggle => toggle !== isn), isn]);
  };

  const toggleOpenByError = (fieldName: string) => {
    if (CONTRAGENT_FIELDS.includes(fieldName)) return toggleSwitchHandler("generalForm", true);
    if (BUSYNESS_FIELDS.includes(fieldName)) return toggleSwitchHandler("busynessForm", true);
    if (AGREEMENT_FIELDS.includes(fieldName)) return toggleSwitchHandler("agreementForm", true);
    if (!stateOpenToggles.includes("healthForm")) return toggleSwitchHandler("healthForm", true);
  };

  const onFormSubmit = (data) => {
    if (isSigningAnketa) return saveAnketaHandler();
    const error = getFormError(data);
    const errorFieldName = (Object.keys(error || {}) || [])[0];
    if (!!errorFieldName) {
      toggleOpenByError(errorFieldName)
      return error;
    }
    const partsWithValue: any[] = anketaPartsBuilder(getFormValue(data), parts);
    const settingsWithValue: any[] = anketaSettingsBuilder(getFormValue(data), settings);
    const contragentVariables: any = {...person, ...CONTRAGENT_FIELDS.reduce((prev, key) => ({...prev, [key]: getFormValue(data)[key]}), {})};
    const {addr1, addr2, countryIsn, gender, ...other} = contragentVariables || {};
    const variables = {
      ...other,
      gender: getSelectValue(gender),
      countryIsn: getSelectValue(countryIsn)
    };
    updateContragent({variables})
      .then(() => {
        saveAddress(isnContragent, addr1, "2246");
        saveAddress(isnContragent, addr2, "2247");
        updateAnketa({
          variables: {
            isn: isnAnketa,
            clientIsn: isnContragent,
            settings: settingsWithValue,
            parts: partsWithValue,
            templateVariables: {variables: {...templateVariables, role: "2"}}
          }
        }).then(({data: {anketa: {isn}}}) => {
          if (stateSubmitType === "download") {
            downloadAnketa({
              variables: {
                isn
              }
            })
            return setSubmitType(undefined)
          }
          if (stateSubmitType === "verify") {
            verifyAnketa({
              variables: {
                clientIsn: isnContragent,
                isn
              }
            })
              .catch(error => setNotification({type: "error", text: error}))
            return setSubmitType(undefined)
          }
          return saveAnketaHandler();
        }).catch(error => setNotification({type: "error", text: error}))
      })
      .catch(error => setNotification({type: "error", text: error}))
  }

  const isLoading: boolean = loadingDownloadAnketa || loadingSaveAddress || loadingSignAnketa || loadingUpdateAnketa || loadingUpdateContragent || loadingVerifyAnketa;
  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})
  if (errorDownloadAnketa) setNotification({type: "error", text: errorDownloadAnketa})
  if (loadingAnketaTemplate) return <Loader type="simple"/>
  return (
    <>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps} id="healthFormCoBorrower">
            {forms.map(({title, id}) =>
              <StyledCoBorrowerStepWrapper key={`healthCoBorrowerPart-${id}`}>
                <Toggle
                  header={title}
                  scrollToTop={true}
                  notUnmount={true}
                  onClick={isOpen => toggleSwitchHandler(id, isOpen)}
                  isOpen={stateOpenToggles.includes(id)}
                >
                  {id === "generalForm" && <ContragentForm isn={isnContragent}/>}
                  {id === "busynessForm" && <BusynessForm/>}
                  {id === "agreementForm" && <AgreementForm defaultValue={agreementFormState}/>}
                  {id === "healthForm" && <HealthForm healthPartForm={healthForm}/>}
                  {id === "additionalHealthForm" && <FieldsBuilder formFields={getFormAdditionalInsurance(parts)}/>}
                </Toggle>
              </StyledCoBorrowerStepWrapper>
            )}
          </form>
        )}
      </Form>
      <StyledStepFormWrapper>
        <Heading3>Вы можете скачать PDF с заполненной анкетой</Heading3>
        <StyledDownloadPdf>
          <StyledFileName>
            <FileIcon/>
            <TextButton>{fullName || "заполни 1 шаг 🧐"}</TextButton>
          </StyledFileName>
          <Button
            appearance="link"
            isLoading={isLoading}
            onClick={() => setSubmitType("download")}
            form="healthFormCoBorrower"
            iconBefore={<DownloadIcon/>}>
            Скачать
          </Button>
        </StyledDownloadPdf>
      </StyledStepFormWrapper>
      <StyledStepFormWrapper>
        <StyledStepFormHeader>
          <Heading3>Введите номер телефона, чтобы мы могли выслать Вам смс с кодом подтверждения</Heading3>
        </StyledStepFormHeader>
        <FieldsBuilder formFields={sendCodeForm}/>
        <Button
          appearance="transparent"
          isLoading={isLoading}
          isDisabled={isSigningAnketa}
          onClick={() => setSubmitType("verify")}
          form="healthFormCoBorrower"
          width="100%">
          Выслать код
        </Button>
      </StyledStepFormWrapper>
      <AnketaPaginationButtons onClose={onClose} formId={"healthFormCoBorrower"}/>
    </>
  )
}

export default OnlineFillingCoBorrowersForm;
