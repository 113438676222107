import styled from "styled-components";
import {grey} from "../../globalStyledComponents";

export const StyledContractRegistrationForm = styled.div`
  padding: 0 4rem 4rem 4rem;

  h3 {
    margin: 6rem 0 3rem 0;
  }

  @media screen and (max-width: 767px) {
    padding: 0 1.5rem 3rem 1.5rem;
    h3 {
      margin: 3rem 0 2rem 0;
    }
  }
  .toggleHeader {
    padding: 0;
  }
`;

export const StyledRisksInformation = styled.div`

  padding: 2rem 0;

  p {
    white-space: nowrap;
  }

  margin-bottom: 4rem;
  border-bottom: 1px solid ${grey};

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1.5rem;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 1.5rem;
    grid-column-gap: 2.5rem;
  }
}
`;

export const StyledInsurancePay = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    margin-bottom: 2.5rem;
  }
`;
export const StyledObjectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;