import styled from "styled-components";

export const StyledRoleByIdHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  
  @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
  }
`;

export const StyledRoleByIdTitle = styled.div`
  display: flex;
  flex-direction: column;
  
  h2 {
    margin-top: 1rem;
  }
`;