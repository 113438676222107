import React                                           from "react";
import {Heading2}                                      from "../../../../globalStyledComponents";
import Button           from "../../../../uiKit/button/button";
import {
  StyledButtonsGroupAutoSize,
  StyledRolesHeader,
  StyledFullSizeOnMobileButton
}                       from "./styles";
import CreateUserModal  from "./createUserModal";
import CreateGroupModal from "./createGroupModal";
import {ITabsItem}      from "../../../../uiKit/tabs";
import CreateRoleModal  from "./createRoleModal";
import FlyButtons       from "../../../../components/flyButtons";
import {useWindowWidth} from "@react-hook/window-size";

export const RolesHeader = ({tab}: { tab: ITabsItem }) => {
  const windowWidth = useWindowWidth({wait: 250});
  const [stateCreateUserModal, setCreateUserModal] = React.useState<boolean>(false);
  const [stateCreateGroupModal, setCreateGroupModal] = React.useState<boolean>(false);
  const [stateCreateRoleModal, setCreateRoleModal] = React.useState<boolean>(false);

  return (
    <StyledRolesHeader>
      <Heading2>Роли и группы</Heading2>
      {tab.value === "groups" &&
      <FlyButtons isWhiteBackground={true}>
        <StyledButtonsGroupAutoSize>
          <Button
            onClick={() => setCreateUserModal(true)}
            appearance="filled">
            { windowWidth < 768 ? "Новый пользователь" : "Создать пользователя +" }
          </Button>
          <Button
            onClick={() => setCreateGroupModal(true)}
            appearance="filled">
            { windowWidth < 768 ? "Новая группа" : "Создать группу +" }
          </Button>
        </StyledButtonsGroupAutoSize>
      </FlyButtons>}
      {tab.value === "roles" &&
      <FlyButtons isWhiteBackground={true}>
        <StyledFullSizeOnMobileButton>
          <Button
            onClick={() => setCreateRoleModal(true)}
            appearance="filled">
            Создать роль +
          </Button>
        </StyledFullSizeOnMobileButton>
      </FlyButtons>}

      <CreateUserModal
        isOpen={stateCreateUserModal}
        onClose={() => setCreateUserModal(false)}
        header="Создание учетной записи"/>
      <CreateGroupModal
        isOpen={stateCreateGroupModal}
        onClose={() => setCreateGroupModal(false)}
        header="Создание группы"/>
      <CreateRoleModal
        isOpen={stateCreateRoleModal}
        onClose={() => setCreateRoleModal(false)}
        header="Создание роли"
      />
    </StyledRolesHeader>
  )
};

export default RolesHeader;