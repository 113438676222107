import styled  from "styled-components";
import {white} from "../../globalStyledComponents";

export const StyledUnderwritingWrapper = styled.div`
  background: ${white};
  margin-top: 4rem;
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  padding-bottom: 2rem;
  
    @media screen and (max-width: 767px) {
  margin-top: 2rem;
  }
`;