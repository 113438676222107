import React                 from "react";
import NivaInput             from "../nivaInput/NivaInput";
import {TypesNivaInputProps} from "../nivaInput/interfaces";
import {money}               from "../../utils/formats";

export interface IFloatInputProps extends TypesNivaInputProps {
  digitsAfterDot?: 2 | 4
}

export const toNumberFormat = (value) => (value||"").trim().replace(/\D/g, '')

export const NumberInput = (props: IFloatInputProps) => {
  const {value, onChange} = props;

  const [stateInputValue, setInputValue] = React.useState<string>(toNumberFormat(value) || "");

  React.useEffect(() => {
    if (!onChange) return;
    onChange(stateInputValue);
    // eslint-disable-next-line
  }, [stateInputValue])

  React.useEffect(() => {
    if (value === stateInputValue) return;
    setInputValue(toNumberFormat(value) || "")
    // eslint-disable-next-line
  }, [value])


  return (
    <NivaInput {...props}
               value={stateInputValue}
               isControlled={true}
               onChange={(value) => setInputValue(toNumberFormat(value))}
    />
  );
}

const FloatInput = (props: IFloatInputProps) => {
  const {digitsAfterDot, value, onChange} = props;
  const [stateFloatInput, setFloatInput] = React.useState<string>(money(value, digitsAfterDot) || "");

  React.useEffect(() => {
    if (!onChange) return;
    onChange(stateFloatInput);
    // eslint-disable-next-line
  }, [stateFloatInput])

  React.useEffect(() => {
    if (value === stateFloatInput) return;
    setFloatInput(money(value, (digitsAfterDot || 2)) || "")
    // eslint-disable-next-line
  }, [value])

  const currencyInputChangeHandler = (value: string) => {
    // eslint-disable-next-line
    value = value.replace(',', '.').replace(/\s+/g, '').trim().replace(',', '.').replace(/[^\d\.]/g, "").replace(/\./, "x").replace(/\./g, "").replace(/x/, ".");
    if (isNaN(Number(value))) return;
    if (Number(value) > 999999999999999) return;
    if (((value.split(".") || [])[1] || "").length > (digitsAfterDot || 2)) return;
    setFloatInput(value);
  }
  const changeEditing = (status) => {
    let value = stateFloatInput;
    if (status) {
      // eslint-disable-next-line
      value = `${parseFloat(value.replace(/[^\d\.]/g, ''))}`;
      if (isNaN(Number(value))) value = "";
    } else {
      if (value.length) value = money(value, (digitsAfterDot || 2)) || "";
    }
    setFloatInput(value);
  };
  return (
    <NivaInput {...props}
               value={stateFloatInput} isControlled={true}
               onChange={(value) => currencyInputChangeHandler(value)}
               onBlur={() => changeEditing(false)}
               onFocus={() => changeEditing(true)}
    />
  );
};

export const NumberInputSwitcher = (props: IFloatInputProps) => {
  const {digitsAfterDot} = props;
  if (typeof digitsAfterDot === "number") return <FloatInput {...props}/>
  return <NumberInput {...props}/>
};
