import {radioBoolean} from "../pages/createRequest/staticData";
import {IRadioItem}   from "../uiKit/radio";
import {IFormField}   from "../uiKit/fieldsBuilder/interfaces";
import {money}        from "../utils/formats";
import {useWindowWidth} from "@react-hook/window-size";

export const IGNORED_STEPS_NAME: string[] = ["Состав семьи", "Информация об объекте строительства", "Дополнительная информация о страхователе"]
const ignoredFieldsIsn: string[] = []

export const formIdByName: { title: string, kiasTitle: string, id: string }[] = [
  {kiasTitle: "Общие сведения о страхователе", title: "Общие сведения о страхователе", id: "generalForm"},
  {kiasTitle: "Сведения о занятости страхователя", title: "Сведения о занятости страхователя", id: "busynessForm"},
  {
    kiasTitle: "Основные положения  кредитного договора",
    title: "Основные положения кредитного договора",
    id: "agreementForm"
  },
  {
    kiasTitle: "Страхование жизни, потери трудоспособности залогодателя вопросы общего характера",
    title: "Страхование жизни, потери трудоспособности залогодателя",
    id: "insuranceForm"
  },
  {
    kiasTitle: "Страхование имущества от гибели, утраты, повреждения",
    title: "Страхование имущества от гибели, утраты, повреждения",
    id: "propertyInsuranceForm"
  },
  {
    kiasTitle: "Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности",
    title: "Страхование потери имущества в результате прекращения, ограничения (обременения) права собственности",
    id: "propertyLossInsuranceForm"
  },
  {
    kiasTitle: "Дополнительная информация о страхователе",
    title: "Дополнительная информация о страхователе",
    id: "additionalInsuranceForm"
  }
]

export const templateToFormRebuild = (partsTemplate: any[] | undefined, isDisabled?: boolean) => {
  if (!partsTemplate || (partsTemplate || []).length === 0) return [];
  const windowWidth = document.documentElement.clientWidth;

  console.log('ww', windowWidth);

  const kiasParts = partsTemplate.reduce((prev, {name, questions}) => {
    const {id, title, kiasTitle} = formIdByName.find(({kiasTitle}) => kiasTitle === name) || {};
    if (IGNORED_STEPS_NAME.includes(name)) return prev;
    const {row} = questions || {};
    const form: IFormField[] = (row || []).reduce((prev, {isn, type, name, dicti, value}) => {
      if (ignoredFieldsIsn.includes(isn)) return prev;
      const validateIsn = ["834831", "834841", "834881", "834871"]
      const requiredIsn = ["834961"];

      const validate = validateIsn.includes(isn) ? {
        validationType: "minMax",
        minValue: 1,
        maxValue: 999,
        isRequired: true
      } : requiredIsn.includes(isn) ? {
        isRequired: true
      } : {};

      if(windowWidth < 768){
        if(name.includes("систолическое")){
          name = "Верхнее (систолическое)";
          return [...prev, {
            label: "Какой рабочий уровень Вашего артериального давления? мм. рт. ст.",
            fieldItems: {
              type: "input",
              name: `nivanavi-${isn}`,
              placeholder: name,
              defaultValue: Array.isArray(value) ? "" : value,
              ...validate,
              isDisabled
            }
          }]
        }
        if(name.includes("диастолическое")){
          name = "Нижнее (диастолическое)";
        }
        if(name.includes("название и адрес")){
          return [...prev, {
            label: "Пожалуйста, сообщите название и адрес поликлиники (лечебного учреждения), которой Вы обычно пользуетесь",
            fieldItems: {
              type: "input",
              name: `nivanavi-${isn}`,
              placeholder: "Название и адрес",
              defaultValue: Array.isArray(value) ? "" : value,
              ...validate,
              isDisabled
            }
          }]
        }
      }

      switch (type) {
        case "INTEGER":
          return [...prev, {
            fieldItems: {
              type: "number",
              name: `nivanavi-${isn}`,
              placeholder: name,
              defaultValue: Array.isArray(value) ? "" : value,
              ...validate,
              isDisabled
            }
          }]
        case "MONEY":
        case "FLOAT":
          return [...prev, {
            fieldItems: {
              type: "number",
              name: `nivanavi-${isn}`,
              digitsAfterDot: 2,
              placeholder: name,
              defaultValue: Array.isArray(value) ? "" : value,
              ...validate,
              isDisabled
            }
          }]
        case "TEXT":
          return [...prev, {
            fieldItems: {
              type: "input",
              name: `nivanavi-${isn}`,
              placeholder: name,
              defaultValue: Array.isArray(value) ? "" : value,
              ...validate,
              isDisabled
            }
          }]
        case "CHECKBOX":
          return [...prev, {
            label: name, fieldItems: {
              type: "radio",
              name: `nivanavi-${isn}`,
              items: radioBoolean,
              defaultValue: Array.isArray(value) ? radioBoolean[1] : value === "1" ? radioBoolean[0] : radioBoolean[1],
              ...validate,
              isDisabled
            }
          }]
        case "SQL":
          const items: IRadioItem[] = ((dicti || {}).row || []).map(({isn, name}) => ({label: name, value: isn}));
          return [...prev, {
            fieldItems: {
              type: "select",
              name: `nivanavi-${isn}`,
              placeholder: name,
              returnFullValue: true,
              ...validate,
              isDisabled,
              items,
            }
          }]
        default:
          return prev;
      }
    }, [])

    return [...prev, ({title, id, form, kiasTitle})]
  }, []);

  return [...kiasParts, {
    form: [],
    id: "documentsForm",
    kiasTitle: "Дополнительные документы к заявке",
    title: "Дополнительные документы к заявке",
  }]
};

export const getOneLevelValue = (partsTemplate: any[] | undefined, forWork?: boolean) => {
  if (!partsTemplate || (partsTemplate || []).length === 0) return [];
  return partsTemplate.reduce((prev, {name, questions}) => {
    if (IGNORED_STEPS_NAME.includes(name)) return prev;
    const {row} = questions || {};
    return {
      ...prev, ...row.reduce((prev, {isn, value, type, name, dicti}) => {
        switch (type) {
          case "CHECKBOX":
            return {...prev, [`nivanavi-${isn}`]: {isn, value: forWork ? value : value === "1" ? "Да" : "Нет", label: name}}
          case "SQL":
            const items: IRadioItem[] = ((dicti || {}).row || []).map(({isn, name}) => ({label: name, value: isn}));
            const valueDicti = (items.find(({value: itemValue}) => itemValue === value) || {}).label || ""
            return {...prev, [`nivanavi-${isn}`]: {isn, value: forWork ? value : valueDicti, label: name}}
          default:
            if (Array.isArray(value)) return {...prev, [`nivanavi-${isn}`]: {isn, value: undefined, label: name}}
            return {...prev, [`nivanavi-${isn}`]: {isn, value, label: name}}
        }
      }, {})
    }
  }, {});
};

export const heightWeightIsn: string = "852981";
export const pressureIsn: string = "852991";
export const totalBorrowerAllowanceIsn: string = "836361";
export const getAllowanceFromAnketa = (settings: any[]) => {
  if ((settings || []).length === 0) return {};
  const heightWeight = ((settings || []).find(({isn}) => isn === heightWeightIsn) || {}).value;
  const pressure = ((settings || []).find(({isn}) => isn === pressureIsn) || {}).value;
  const total = ((settings || []).find(({isn}) => isn === totalBorrowerAllowanceIsn) || {}).value;
  return {
    heightWeight: Array.isArray(heightWeight) ? 0 : heightWeight,
    pressure: Array.isArray(pressure) ? 0 : pressure,
    total: Array.isArray(total) ? 0 : total,
  }
};

export const getSummForPay = (objects: any[], currency?: string, onlySum?: boolean) => {
  const sum: number = (objects || []).reduce((prev, object) => {
    const {AGRCOND} = object || {};
    const {row: rowAgrCond} = AGRCOND || {}
    const {PremiumSum: premium} = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} : rowAgrCond || {}
    const valueReplace = (String(premium) || "").replace(",", ".");
    if (isNaN(Number(valueReplace))) return prev;
    return prev + Number(valueReplace);
  }, 0);
  if (onlySum) return sum;
  return `${money(sum)} ${currency || "RUB"}`;
};

export const getSummForNextInvoice = (sums: any[], isnInvoice: any) => {
  const sum: number = (sums || []).reduce((prev, object) => {
    if(object.Discr == 'f' && object.OperDocISN == isnInvoice){
      const valueStr = object.AmountRub;
      const value = parseFloat(valueStr.replace(",", "."));
      return prev + value;
    }
    return prev;
  }, 0);
  return `${money(sum)} RUB`;
}