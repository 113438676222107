import React                                       from 'react';
import Picker                                      from 'rc-calendar/lib/Picker';
import Calendar                                    from 'rc-calendar';
import ruRU                                        from "rc-calendar/lib/locale/ru_RU";
import 'rc-calendar/assets/index.css';
import moment                                      from 'moment';
import "moment/locale/ru";
import NivaInput                                   from "../nivaInput/NivaInput";
import {CloseIcon}                                 from "../../icons/ui/Close";
import {DataPickerIcon}                                                   from "../../icons/ui/DataPicker";
import {StyledCalendarWrapper, StyledDatePicker, StyledDatePickerWrapper} from './styles';
import Button                                                             from "../button/button";
import {idGenerator}                               from "../../helpers/idGenerator";
// import ClickAwayListener                           from "react-click-away-listener";
// import {fieldsValidate}                            from "../../helpers/fieldsValidation";

export const formatDateStr = 'DD.MM.YYYY';

function formatFunc(v) {
  if (!v) return;
  return v.format(formatDateStr);
}

export interface IDatePickerProps {
  value?: string
  onChange?: (value: string | undefined) => void
  placeholder?: string
  isInvalid?: boolean
  errorMessage?: string
  isDisabled?: boolean
  isRequired?: boolean
}

const DatePicker = ({value, onChange, placeholder, isRequired, isInvalid, errorMessage, isDisabled}:IDatePickerProps) => {
  const [stateDate, setDate] = React.useState<string | undefined>(value);
  // const [stateIsOpen, setIsOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!onChange) return;
    onChange(stateDate)
    // eslint-disable-next-line
  }, [stateDate])

  React.useEffect(() => {
    if (value === stateDate) return;
    setDate(value)
    // eslint-disable-next-line
  }, [value])

  const onDateChange = value => {
    if (isDisabled) return;
    setDate(formatFunc(value))
    // if (stateIsOpen) return setIsOpen(false)
  };

  const inputChangeHandler = value => {
    setDate(value)
    // if (!fieldsValidate(value, "date") && stateIsOpen) return setIsOpen(false)
  };

  // const clickAwayHandler = ({target}) => {
  //   const className: string = String(target.className) || "";
  //   if (!stateIsOpen || className.startsWith("rc-calendar")) return;
  //   return setIsOpen(false);
  // }

  const date: any = moment(stateDate || "", formatDateStr);

  const calendar = (
    <Calendar
      showWeekNumber={false}
      dateInputPlaceholder={'Дата'}
      defaultValue={undefined}
      locale={ruRU}
    />
  );

  const datePickerClass = `datePicker-${idGenerator()}`;
  return (
    <StyledDatePickerWrapper>
      <StyledDatePicker>
        <Picker
          // open={stateIsOpen}
          getCalendarContainer={() => document.querySelector(`.${datePickerClass}`)}
          value={date}
          onChange={onDateChange}
          calendar={calendar}
        >
          {
            () => {
              return (
                <StyledCalendarWrapper isDisabled={!!isDisabled} className={datePickerClass}>
                  {/*<ClickAwayListener onClickAway={(ev) => clickAwayHandler(ev)}>*/}
                  <NivaInput
                    mask="99.99.9999"
                    isDisabled={!!isDisabled}
                    value={stateDate}
                    isInvalid={isInvalid}
                    isRequired={!!isRequired}
                    // onClick={() => setIsOpen(true)}
                    isControlled={true}
                    errorMessage={errorMessage}
                    iconAfter={stateDate ?
                      <Button isDisabled={!!isDisabled} appearance="icon" onClick={() => setDate(undefined)} icon={<CloseIcon/>}/>
                      :
                      <Button isDisabled={!!isDisabled} appearance="icon" icon={<DataPickerIcon/>}/>
                    }
                    placeholder={placeholder}
                    onChange={inputChangeHandler}/>
                  {/*</ClickAwayListener>*/}
                </StyledCalendarWrapper>
              );
            }
          }
        </Picker>
      </StyledDatePicker>
    </StyledDatePickerWrapper>
  );
}

export default DatePicker;
