export interface AgreementStepState {
  step: number;
  stepsCount: number;
}

export const DEFAULT_STEP_STATE: AgreementStepState = {
  step: 0,
  stepsCount: 2
};

export const agreementStepState: AgreementStepState = {...DEFAULT_STEP_STATE};