import React from 'react';
import {
  StyledAddNewComment,
  StyledButtonsWrapper,
  StyledCommentBody,
  StyledCommentHeader,
  StyledCommentItem,
  StyledComments, StyledCommentToggleWrapper, StyledDivider, StyledDownloadAllButton,
  StyledDownloadFileItem, StyledProfileLogo, StyledUploadFileInToggle, StylesDocsHintWrapper
} from "./styles";
import Toggle
  from "../../../../../../uiKit/toggle";
import Form from '@atlaskit/form';

import Button
  from "../../../../../../uiKit/button/button";
import {DownloadIcon}                             from "../../../../../../icons/ui/Download";
import {Heading4, Heading5, TextButton} from "../../../../../../globalStyledComponents";
import {StyledFileName}                           from "../../../../../createRequest/components/insuranceStep/components/onlineFilling/styles";
import {FileIcon} from "../../../../../../icons/ui/File";
import {AddIcon} from "../../../../../../icons/ui/Add";
import {ProfileIcon} from "../../../../../../icons/ui/Profile";
import {IFormField} from "../../../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder from "../../../../../../uiKit/fieldsBuilder";
import {StyledButtonsGroup} from "../../../../../roles/components/header/styles";
import {getFormError} from "../../../../../../formUtils/getFormError";
import {getFormValue} from "../../../../../../formUtils/getFormValue";
import {useQuery, useLazyQuery, useMutation} from "@apollo/react-hooks";
import {
  ANKETA_DOWNLOAD,
  COMMENTS,
  DOCS, DOWNLOAD_ALL_FILES,
  DOWNLOAD_ATTACHMENT,
  GET_AGREEMENT,
  GET_ATTACHMENT, ME
} from "../../../../../../utils/queries";
import {useParams} from "react-router";
import {ADD_COMMENT, NOTIFICATION, SAVE_ATTACHMENT, VIEW_COMMENTS} from "../../../../../../utils/mutations";
import UploadFile from "../../../../../../uiKit/uploadFile";
import {ErrorMessage} from "../../../../../../components/errorMessage";
import {fileDownloader} from "../../../../../../helpers/fileDownloader";
import {useCallNotification} from "../../../../../../hooks/useCallNotification";
import {useWindowWidth} from "@react-hook/window-size";
import {
  ANKETA_ISN,
  CLASS_ISN_BORROWERS, CLASS_ISN_OBJECTS, CLASS_ISN_TITLES, COBORROWER_SUB_CLASS_ISN, getValueByAttributeId,
  getValueFromObjectsAttributes
} from "../../../../../../helpers/buildTemplateForBack";
import SendClientModal from "../../../../../createInvoice/components/SendClientModal";
import SendAnketaModal from "../../../../../createInvoice/components/SendAnketaModal";

type Document = {
  isn: string,
  name: string,
  added?: string
  documentType: "file" | "anketa" | "letter" | "devider"
  isDisabled?: boolean
}

const lifeDocs: string[] = ['паспорт Страхователя'];
const propertyDocs: string[] = ['технический паспорт на объект недвижимости', 'выписка из ЕГРН', 'отчет об оценке недвижимости (обязательно! при страховании ДОМОВ)'];
const titleDocs: string[] = ['правоустанавливающие документы (документ-основание права собственности на объект)'];

export const DocumentsHint = ({riskProperty, riskTitle}: {riskProperty: boolean, riskTitle: boolean}) => {
  const docsList: string[] = [...lifeDocs, ...(riskProperty ? propertyDocs : []), ...(riskTitle ? titleDocs : [])];

  return (
    <StylesDocsHintWrapper>
      <Heading4>Необходимые документы</Heading4>
      {docsList.map(doc => <TextButton>- {doc}</TextButton>)}
    </StylesDocsHintWrapper>
  )
}

export const DocumentsToggle = ({header, type, id, openByDefault}: { header: string, type: "C" | "A", id: string, openByDefault?: boolean }) => {
  const {setNotification} = useCallNotification();
  const [stateFiles, setFiles] = React.useState<any[]>([]);
  const {
    data: dataAgreement,
    loading: loadingAgreement,
    error: errorAgreement
  } = useQuery(GET_AGREEMENT, {variables: {isn: id}});
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {variables: {isn: id}});
  const {data: dataAttachment, loading: loadingAttachment, error: errorAttachment} = useQuery(GET_ATTACHMENT, {
    variables: {
      ref: id.replace("-", ""),
      type
    }
  });
  const [downloadFile, {
    data: dataDownloadAttachment,
    loading: loadingDownloadAttachment,
    error: errorDownloadAttachment
  }] = useLazyQuery(DOWNLOAD_ATTACHMENT, {fetchPolicy: "cache-and-network"});
  const [downloadAnketa, {
    data: dataDownloadAnketa,
    loading: loadingDownloadAnketa,
    error: errorDownloadAnketa
  }] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [downloadAll, {
    data: dataDownloadAll,
    loading: loadingDownloadAll,
    error: errorDownloadAll
  }] = useLazyQuery(DOWNLOAD_ALL_FILES, {fetchPolicy: "cache-and-network"});
  const [saveAttachment, {loading: loadingSaveAttachment}] = useMutation(SAVE_ATTACHMENT, {refetchQueries: ["attachments"]});
  const [notification, {loading: loadingNotification}] = useMutation(NOTIFICATION);
  const {objects, attribs, clientInfo} = (dataAgreement || {}).agreementCalc || {};
  const borrowers = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_BORROWERS);
  const properties = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_OBJECTS);
  const titles = (objects || []).filter(({ClassISN}) => ClassISN === CLASS_ISN_TITLES);
  const isDoubleRiskAgreement: boolean = borrowers.length !== 0 && properties.length !== 0;
  const urlFile = ((dataDownloadAttachment || {}).attachmentFile || {}).url;
  const urlFileAnketa = ((dataDownloadAnketa || {}).documentForm || {}).url;
  const urlAllFiles = ((dataDownloadAll || {}).attachmentFiles || {}).url;
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {statusCode} = (docsList || [])[0] || {};
  const isCanceled: boolean = ["annul", "clientRefusal"].includes(statusCode);
  const isAgreement: boolean = type === "A";
  const riskProperty = !!properties.length;
  const riskTitle = !!titles.length;
  const isShowHint: boolean = !isAgreement && !["agreed", "inProgress"].includes(statusCode);
  const isDeclaration: boolean = (getValueByAttributeId(attribs, "isDeclaration") == "1");
  const [stateSendModal, setSendModal] = React.useState<any>(null);


  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {role} = (dataUser || {}).me || {};
  const haveRightForLetter: boolean = (role?.id != 25);

  React.useEffect(() => {
    fileDownloader(urlAllFiles);
    // eslint-disable-next-line
  }, [urlAllFiles]);

  React.useEffect(() => {
    fileDownloader(urlFile);
    // eslint-disable-next-line
  }, [urlFile]);

  React.useEffect(() => {
    fileDownloader(urlFileAnketa);
    // eslint-disable-next-line
  }, [urlFileAnketa]);

  const downloadAnketaHandler = (isn) => {
    if (!isn) return;
    downloadAnketa({
      variables: {
        isn,
        templateIsn: isDeclaration ? process.env.REACT_APP_TEMPLATE_ISN : '10929'
      }
    });
  };

  const downloadLetter = () => {
    const universalLetterTemplate = "16902";
    downloadAnketa({
      variables: {
        isn: id,
        templateIsn: universalLetterTemplate,
        classId: 2,
      }
    });
  };

  const downloadFileHandler = ({isn, documentType}) => {
    if (!isn) return setNotification({type: "error", text: "Отсутствует isn файла"});
    if (documentType === "anketa") return downloadAnketaHandler(isn);
    if (documentType === "letter") return downloadLetter();
    downloadFile({
      variables: {
        isn,
        ref: id.replace("-", ""),
        type
      }
    });
  };

  const sendAnketaPopupHandler = (isn, name) => {
    setSendModal({
      isn,
      templateIsn: isDeclaration ? process.env.REACT_APP_TEMPLATE_ISN : '10929',
      subject: name,

    });
  }

  const saveFilesHandler = () => {
    if (stateFiles.length === 0) return;
    setFiles([]);
    stateFiles.forEach(file => {
        saveAttachment({
          variables: {
            type,
            ref: id.replace("-", ""),
            file: file
          }
        })
          .then(() => {
            notification({
              variables: {
                isn: id,
                type: "add_file",
                files: stateFiles.map(({name}) => name)
              }
            })
              .catch(error => setNotification({type: "error", text: error}));
          })
          .catch(error => setNotification({type: "error", text: error}));
      }
    );
  };
  const downloadAllFilesHandler = () => {
    downloadAll({
      variables: {
        ref: id.replace("-", ""),
        type
      }
    });
  };

  const anketaType = isDeclaration ? "Декларация" : "Анкета";

  console.log('clientInfo', clientInfo);

  const defaultDocumentsForC: Document[] = [
    {
      documentType: "devider",
      isn: "devider",
      name: "devider"
    },
    {
      isn: getValueByAttributeId(attribs, "isnAnketa"),
      name: `${anketaType}: ${clientInfo?.FULLNAME || "unknown"}`,
      documentType: "anketa",
      isDisabled: !getValueByAttributeId(attribs, "isnAnketa")
    },
    ...borrowers.filter(({SubClassISN}) => SubClassISN === COBORROWER_SUB_CLASS_ISN).map(borrower => ({
      isn: getValueFromObjectsAttributes(borrower, ANKETA_ISN),
      name: `${anketaType}: ${borrower?.SubjFullName || "unknown"}`,
      documentType: "anketa",
      isDisabled: !getValueFromObjectsAttributes(borrower, ANKETA_ISN)
    })),
    ...haveRightForLetter ? [{
      isn: "letter",
      name: "Письмо - заключение в банк",
      documentType: "letter"
    }] : []
    //   ...borrowers.reduce((prev,borrower) => {
    //   const remark: string = getValueFromObjectsAttributes(borrower, REMARK_ISN);
    //   if (!!remark) return [...prev, {
    //     isn: getValueFromObjectsAttributes(borrower, ANKETA_ISN),
    //     name: `Анкета: ${borrower?.SubjFullName || "unknown"}`,
    //     type: "anketa",
    //     isDisabled: !getValueFromObjectsAttributes(borrower, ANKETA_ISN)
    //   }]
    //   return prev;
    // }, []),
  ]

  const documents: Document[] = [...((dataAttachment || {}).attachments || []).map(({isn, fileName, added}) => ({
    isn: isn,
    name: fileName,
    documentType: "file",
    added
  })), ...(isAgreement ? [] : defaultDocumentsForC)];

  if (errorDocs) setNotification({type: "error", text: errorDocs});
  if (errorAgreement) setNotification({type: "error", text: errorAgreement});
  if (errorAttachment) setNotification({type: "error", text: errorAttachment});
  if (errorDownloadAttachment) setNotification({type: "error", text: errorDownloadAttachment});
  if (errorDownloadAnketa) setNotification({type: "error", text: errorDownloadAnketa});
  if (errorDownloadAll) setNotification({type: "error", text: errorDownloadAll});
  return (
    <Toggle
      emptyMessage={documents.length === 0 ? "Нет документов" : undefined}
      header={header}
      openByDefault={openByDefault}
      padding={isAgreement ? 0 : undefined}
      isLoading={loadingAttachment || loadingAgreement || loadingDocs}>
      <StyledUploadFileInToggle>
        {isShowHint && <DocumentsHint riskProperty={riskProperty} riskTitle={riskTitle}/>}
        <UploadFile isDisabled={isCanceled} onSave={saveFilesHandler}
                    isLoading={loadingSaveAttachment || loadingNotification} value={stateFiles}
                    onChange={file => setFiles(file)}/>
      </StyledUploadFileInToggle>
      <StyledComments>
        {documents.length !== 0 && documents.map((file, index) => {
          const {name, documentType, isDisabled, added} = file;
          if (documentType === "devider") return <StyledDivider/>
          return (
            <StyledDownloadFileItem key={`download-file-${index}`}>
              <StyledFileName>
                <FileIcon/>
                <TextButton>{name?.length > 35 ? `${name.substr(0, 35)}...` : name}</TextButton>
                {!!added && <TextButton>{added}</TextButton>}
              </StyledFileName>
              <StyledButtonsWrapper>
                <Button
                  appearance="link"
                  isLoading={loadingDownloadAttachment || loadingDownloadAll || loadingDownloadAnketa}
                  isDisabled={isDisabled}
                  tooltip={isDisabled && documentType === "anketa" ? "Анкета еще не заполнена" : undefined}
                  onClick={(ev) => {
                    ev.preventDefault();
                    downloadFileHandler(file);
                  }}
                  iconBefore={<DownloadIcon/>}>
                  Скачать
                </Button>
                {documentType === 'anketa' && <Button
                    appearance="link"
                    isLoading={loadingDownloadAttachment || loadingDownloadAll || loadingDownloadAnketa}
                    isDisabled={isDisabled}
                    tooltip={isDisabled && documentType === "anketa" ? "Анкета еще не заполнена" : undefined}
                    onClick={(ev) => {
                      ev.preventDefault();
                      sendAnketaPopupHandler(file?.isn, file?.name);
                    }}>
                  Отправить
                </Button>}

              </StyledButtonsWrapper>
            </StyledDownloadFileItem>
          );
        })
        }
      </StyledComments>
      {documents.length !== 0 && <StyledDownloadAllButton>
          <Button
              appearance="link"
              isLoading={loadingDownloadAttachment || loadingDownloadAll}
              onClick={(ev) => {
                ev.preventDefault();
                downloadAllFilesHandler();
              }}
              iconBefore={<DownloadIcon/>}>
              Скачать все
          </Button>
      </StyledDownloadAllButton>}
      <SendAnketaModal data={{...stateSendModal, defaultEmail: ''}} onClose={() => setSendModal(null)} isOpen={!!stateSendModal} header={isDeclaration ? 'Отправить декларацию': 'Отправить анкету'}/>
    </Toggle>
  );
};


const form: IFormField[] = [
  {
    fieldMargin: 1,
    fieldItems: {
      type: "textarea",
      name: "text",
      placeholder: "Ваш комментарий",
      isRequired: true,
      errorMessage: "Введите комментарий"
    }
  }
];

export const CommentsToggle = ({header}: { header: string }) => {
  const {setNotification} = useCallNotification();
  const windowWidth = useWindowWidth({wait: 250});
  const {id} = useParams();
  const [stateAddComment, setAddComment] = React.useState<boolean>(false);
  const [stateIsOpen, setIsOpen] = React.useState<boolean>(false);
  const {data: dataDocs, loading: loadingDocs, error: errorDocs} = useQuery(DOCS, {variables: {isn: id}});
  const [getComments, {data: dataComments, loading: loadingComments, error: errorComments}] = useLazyQuery(COMMENTS);
  const [addComment, {loading: loadingAddComment}] = useMutation(ADD_COMMENT, {refetchQueries: ["comments"]});
  const [viewComments, {loading: loadingViewComments}] = useMutation(VIEW_COMMENTS, {refetchQueries: ["comments"]});
  const {items: docsList} = (dataDocs || {}).docs || {};
  const {id: docId, statusCode} = (docsList || [])[0] || {};
  const {new: newComments, list: comments} = (dataComments || {}).comments || {};
  const isCanceled: boolean = ["annul", "clientRefusal"].includes(statusCode);

  const newCommentsIdLst: string[] = (comments || []).reduce((prev, {isNew, id}) => {
    if (!isNew) return prev;
    return [...prev, id];
  }, []);

  const commentsList: {
    id: string
    text: string
    date: string
    user: string
    isNew: boolean
  }[] = (comments || []).map(({id, text, date, user, isNew}) => ({
    id,
    text,
    date,
    user: (user || {}).name,
    isNew
  }));

  React.useEffect(() => {
    if (!docId) return;
    getComments({
      variables: {
        doc: docId
      }
    });
    // eslint-disable-next-line
  }, [docId]);

  React.useEffect(() => {
    if (!stateIsOpen || loadingViewComments || loadingComments || newCommentsIdLst.length === 0) return;
    viewComments({
      variables: {
        id: newCommentsIdLst
      }
    })
      .catch(error => setNotification({type: "error", text: error}));
    // eslint-disable-next-line
  }, [stateIsOpen]);

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    addComment({
      variables: {
        ...getFormValue(data),
        doc: docId
      }
    })
      .then(() => {
        setAddComment(false);
        setNotification({type: "success", text: "Комментарий добавлен"});
      })
      .catch(error => setNotification({type: "error", text: error}));
  };

  if (errorComments) setNotification({type: "error", text: errorComments});
  if (errorDocs) return <ErrorMessage error={errorDocs}/>;
  return (
    <StyledCommentToggleWrapper>
      <Toggle
        header={header}
        emptyMessage={commentsList.length === 0 ? "Нет комментариев" : undefined}
        padding={0}
        newItems={newComments}
        isOpen={stateIsOpen}
        onClick={isOpen => setIsOpen(isOpen)}
        isLoading={loadingDocs || loadingComments}>
        <StyledComments>
          {!stateAddComment &&
          <Button
              onClick={() => setAddComment(true)}
              appearance="link"
              isDisabled={isCanceled}
              iconBefore={<AddIcon/>}>
              Добавить комментарий
          </Button>}
          {stateAddComment &&
          <StyledAddNewComment>
              <Form onSubmit={data => onFormSubmit(data)}>
                {({formProps}) => (
                  <form {...formProps}>
                    <FieldsBuilder formFields={form}/>
                    <StyledButtonsGroup>
                      <Button type="submit" appearance="filled" isLoading={loadingAddComment}>
                        {windowWidth < 768 ? "Добавить" : "Добавить комментарий"}
                      </Button>
                      <Button onClick={() => setAddComment(false)} appearance="transparent">
                        Отменить
                      </Button>
                    </StyledButtonsGroup>
                  </form>
                )}
              </Form>
          </StyledAddNewComment>
          }
          {commentsList.map(({id, user, date, text, isNew}) => (
            <StyledCommentItem key={`comment-item-${id}`} isNew={isNew}>
              {/*<StyledProfileLogo>{logo ? <img src={logo} alt={`logo-${name}`}/> : <ProfileIcon/>}</StyledProfileLogo>*/}
              <StyledProfileLogo><ProfileIcon/></StyledProfileLogo>
              <StyledCommentBody>
                <StyledCommentHeader>
                  <Heading5>{user}</Heading5>
                  <Heading5>{date}</Heading5>
                </StyledCommentHeader>
                <TextButton>{text}</TextButton>
              </StyledCommentBody>
            </StyledCommentItem>
          ))
          }
        </StyledComments>
      </Toggle>
    </StyledCommentToggleWrapper>
  );
};