import React                                      from 'react';
import {Heading2}                                 from "../../globalStyledComponents";
import {StyledStepFormWrapper, StyledStepWrapper} from "../createRequest/components/generalStep/styles";
import {StyledSettingsWrapper}                    from "./styles";
import {IFormField}                               from "../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                              from "../../uiKit/fieldsBuilder";
import {getFormError}                             from "../../formUtils/getFormError";
import {getFormValue}                             from "../../formUtils/getFormValue";
import Form                                       from '@atlaskit/form';
import Button                                     from "../../uiKit/button/button";
import {useMutation, useQuery}                    from "@apollo/react-hooks";
import {GROUPS, ME, USERS}                        from "../../utils/queries";
import {ISelectItem}                              from "../../uiKit/select";
import {useGetDict}                               from "../../hooks/useGetDicti";
import {SET_RESTRICTIONS}                         from "../../utils/mutations";
import {useCallNotification}                      from "../../hooks/useCallNotification";
import {ErrorMessage}                             from "../../components/errorMessage";
import {getRestrictionsObject}                    from "../../helpers/buildTemplateForBack";

export const Settings = () => {
  const {setNotification} = useCallNotification();
  const [stateSelectedUser, setSelectedUser] = React.useState<ISelectItem | undefined>(undefined);
  const {data: selectBanks, loading: loadingBanks} = useGetDict("banks");
  const {data: selectPledgeTypes, loading: loadingPledgeTypes} = useGetDict("pledgeType");
  const {data: selectWallMaterial, loading: loadingWallMaterial} = useGetDict("wallMaterial");
  const [stateAutoScoring, setAutoScoring] = React.useState<boolean>(false);
  const [stateAutoScoringLife, setAutoScoringLife] = React.useState<boolean>(false);
  const [stateAutoScoringProperty, setAutoScoringProperty] = React.useState<boolean>(false);
  const [stateDeclaration, setDeclaration] = React.useState<boolean>(false);

  const [stateDeclarationLife, setDeclarationLife] = React.useState<boolean>(false);
  const [stateDeclarationProperty, setDeclarationProperty] = React.useState<boolean>(false);
  // const [stateDeclarationTitle, setDeclarationTitle] = React.useState<boolean>(false);

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {restrictions, type, group} = (dataUser || {}).me || {};
  const {id: parentGroupId, name: parentGroupName} = group || {};
  const {
    autoscoring,
    autoscoringLife,
    autoscoringProperty,
    autoscoringTitle,
    objectType,
    maxDiscount,
    yearOfBuild: maxYearOfBuild,
    summLife: maxSummLife,
    summProperty: maxSummProperty,
    ageLimit: maxAge,
    contragentMaxAge,

    declaration,
    declarationLife,
    declarationProperty,
    declarationTitle,
    declarationSummLife: maxDeclarationSummLife,
    declarationAgeLimit: declarationMaxAge,
    declarationSummProperty: maxDeclarationSummProperty,
    // declarationSummTitle: maxDeclarationSummTitle,
    declarationYearOfBuild: maxDeclarationYearOfBuild,
    declarationObjectType,
    declarationWallMaterials,
    declarationFloorMaterials,
    // declarationOwnTitle

  } = getRestrictionsObject(restrictions);

  const {data: dataUsers, loading: loadingUsers, error: errorUsers} = useQuery(USERS);
  const {data: dataGroups, loading: loadingGroups, error: errorGroups} = useQuery(GROUPS);
  const [setRestrictions, {loading: loadingSetRestrictions}] = useMutation(SET_RESTRICTIONS, {
    refetchQueries: ["users", "groups"],
    awaitRefetchQueries: true
  });
  const groups: ISelectItem[] = [...((dataGroups || {}).groups || []).map(({name, id}) => ({
    label: name,
    value: id,
    type: "group"
  })), ...(type === "admin" ? [] : [{label: parentGroupName, value: parentGroupId, type: "group"}])];
  const users: ISelectItem[] = ((dataUsers || {}).users || []).map(({login, name, id}) => ({
    label: `${name} (${login})`,
    value: id,
    type: "user"
  }));
  const groupsUsers: ISelectItem[] = [...users, ...groups];

  const getCurrentRestriction = () => {
    console.log(((dataGroups || {}).groups || []).find(({id}) => id === (stateSelectedUser || {}).value) || {})
    if ((stateSelectedUser as any).type === "user") return (((dataUsers || {}).users || []).find(({id}) => id === (stateSelectedUser || {}).value) || {}).restrictions || {};
    if ((stateSelectedUser as any).type === "group") return (((dataGroups || {}).groups || []).find(({id}) => id === (stateSelectedUser || {}).value) || {}).restrictions || {};
  }

  const getDefaultValue = (tag: string/*, risk?: string*/) => {
    if (!(stateSelectedUser || {}).value) return undefined;
    const currentRestriction = getCurrentRestriction();
    const currentTag = (currentRestriction.find(({tag: restrictionTag}) => restrictionTag === tag) || {}).value;
    switch (tag) {
      case "banks":
        if ((currentTag || []).length === 0) return undefined;
        return selectBanks.filter(({value}) => currentTag.includes(value));
      case "objectType":
      case "declarationObjectType":
        if ((currentTag || []).length === 0) return undefined;
        return selectPledgeTypes.filter(({value}) => currentTag.includes(value));
      case "declarationWallMaterials":
      case "declarationFloorMaterials":
        if ((currentTag || []).length === 0) return undefined;
        return selectWallMaterial.filter(({value}) => currentTag.includes(value));
      case "declaration":
      case "autoscoring":
      case "autoscoringLife":
      case "autoscoringProperty":
      case "autoscoringTitle":
      case "declarationOwnTitle":
      case "declarationLife":
      case "declarationProperty":
      case "declarationTitle":
        if (typeof currentTag === "undefined") return false;
        return currentTag;
      case "ageLimit":
      case "contragentMaxAge":
      case "yearOfBuild":
      case "declarationYearOfBuild":
      case "declarationAgeLimit":
        if (typeof currentTag === "undefined" || isNaN(Number(currentTag))) return "";
        return String(currentTag);
      case "summLife":
      case "summProperty":
      case "declarationSummLife":
      case "declarationSummProperty":
      case "declarationSummTitle":
      case "maxDiscount":
        if (typeof currentTag === "undefined" || isNaN(Number(currentTag))) return "";
        return currentTag;
    }
  };

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "select",
        name: "agentOrGroup",
        placeholder: "Агент / группа",
        isRequired: true,
        items: groupsUsers,
        isLoading: loadingUsers || loadingGroups,
        onChange: value => setSelectedUser(value),
        errorMessage: "Выберите агента",
        isSearchAvailable: true,
        isClearable: true
      }
    },
    {
      label: "Исключить банки",
      fieldItems: {
        type: "enums",
        name: "banks",
        placeholder: "Поиск по банкам",
        items: selectBanks,
        isLoading: loadingBanks,
        defaultValue: getDefaultValue("banks")
      }
    },
    {
      label: "Максимально возможная скидка в %",
      fieldItems: {
        type: "number",
        digitsAfterDot: 2,
        placeholder: "Максимально возможная скидка в %",
        name: "maxDiscount",
        defaultValue: getDefaultValue("maxDiscount"),
        validationType: "minMax",
        maxValue: maxDiscount || 100,
      }
    },
    {
      label: "Максимально допустимый возраст заемщика/созаемщика",
      fieldItems: {
        type: "range",
        errorMessage: "Выберите возрастной лимит",
        placeholder: "Возраст",
        name: "contragentMaxAge",
        startValue: 0,
        endValue: 100,
        defaultValue: getDefaultValue("contragentMaxAge"),
        validationType: isNaN(contragentMaxAge) ? undefined : "minMax",
        maxValue: contragentMaxAge
      }
    },
    {
      fieldItems: {
        type: "checkbox",
        label: "Автосогласование",
        onChange: value => setAutoScoring(value),
        name: "autoscoring",
        defaultValue: getDefaultValue("autoscoring")
      }
    },
    {
      isHidden: !stateAutoScoring,
      fieldItems: {
        type: "checkbox",
        label: "Автосогласование жизни",
        name: "autoscoringLife",
        onChange: value => setAutoScoringLife(value),
        isDisabled: type !== "admin" && autoscoring && !autoscoringLife,
        defaultValue: getDefaultValue("autoscoringLife")
      }
    },
    {
      isHidden: !stateAutoScoring || !stateAutoScoringLife,
      label: "Максимальная страховая сумма по риску жизнь",
      fieldItems: {
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите максимальную страховую сумму по риску жизнь",
        placeholder: "Страховая сумма по риску жизнь",
        name: "summLife",
        defaultValue: getDefaultValue("summLife"),
        validationType: !autoscoring || isNaN(maxSummLife) ? undefined : "minMax",
        maxValue: maxSummLife,
      }
    },
    {
      isHidden: !stateAutoScoring || !stateAutoScoringLife,
      label: "Возрастной лимит",
      fieldItems: {
        type: "range",
        isRequired: true,
        errorMessage: "Выберите возрастной лимит",
        placeholder: "Возраст",
        name: "ageLimit",
        startValue: 0,
        endValue: 100,
        defaultValue: getDefaultValue("ageLimit"),
        validationType: !autoscoring || isNaN(maxAge) ? undefined : "minMax",
        maxValue: maxAge
      }
    },
    {
      isHidden: !stateAutoScoring,
      fieldItems: {
        type: "checkbox",
        label: "Автосогласование имущества",
        onChange: value => setAutoScoringProperty(value),
        name: "autoscoringProperty",
        isDisabled: type !== "admin" && autoscoring && !autoscoringProperty,
        defaultValue: getDefaultValue("autoscoringProperty")
      },
    },
    {
      isHidden: !stateAutoScoring || !stateAutoScoringProperty,
      label: "Максимальная страховая сумма по риску имущество",
      fieldItems: {
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите максимальную страховую сумму по риску имущество",
        placeholder: "Страховая сумма по риску имущество",
        name: "summProperty",
        defaultValue: getDefaultValue("summProperty"),
        validationType: !autoscoring || isNaN(maxSummProperty) ? undefined : "minMax",
        maxValue: maxSummProperty,
      }
    },
    {
      isHidden: !stateAutoScoring || !stateAutoScoringProperty,
      label: "Разрешенные объекты залога",
      fieldItems: {
        isRequired: true,
        errorMessage: "Выберите объекты залога",
        type: "enums",
        name: "objectType",
        placeholder: "Поиск по объектам залога",
        items: (type === "admin" || !autoscoring) ? selectPledgeTypes : selectPledgeTypes.map((pledge) => {
          const {value} = pledge;
          if (!objectType.includes(value)) return {...pledge, isDisabled: true};
          return pledge;
        }),
        isLoading: loadingPledgeTypes,
        defaultValue: getDefaultValue("objectType")
      }
    },
    {
      isHidden: !stateAutoScoring || !stateAutoScoringProperty,
      label: "Год постройки до",
      fieldItems: {
        isRequired: true,
        errorMessage: "Введите год постройки",
        type: "input",
        name: "yearOfBuild",
        mask: "9999",
        placeholder: "Год постройки / приобретения до",
        defaultValue: getDefaultValue("yearOfBuild"),
        validationType: !autoscoring || isNaN(maxYearOfBuild) ? undefined : "minMax",
        minValue: maxYearOfBuild
      }
    },
    {
      isHidden: !stateAutoScoring,
      columns: 3,
      columnGap: 2,
      fieldItems: {
        type: "checkbox",
        label: "Автосогласование титула",
        name: "autoscoringTitle",
        isDisabled: type !== "admin" && autoscoring && !autoscoringTitle,
        defaultValue: getDefaultValue("autoscoringTitle")
      }
    },
    {
      fieldItems: {
        type: "checkbox",
        name: "declaration",
        label: "Доступность декларации",
        onChange: value => setDeclaration(value),
        defaultValue: getDefaultValue("declaration")
      }
    },
    {
      isHidden: !stateDeclaration,
      fieldItems: {
        type: "checkbox",
        label: "Декларация жизни",
        name: "declarationLife",
        onChange: value => setDeclarationLife(value),
        isDisabled: type !== "admin" && declaration && !declarationLife,
        defaultValue: getDefaultValue("declarationLife")
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationLife,
      label: "Максимальная страховая сумма по заявке",
      fieldItems: {
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите максимальную страховую сумму",
        placeholder: "Страховая сумма",
        name: "declarationSummLife",
        defaultValue: getDefaultValue("declarationSummLife"),
        validationType: !declaration || isNaN(maxDeclarationSummLife) ? undefined : "minMax",
        maxValue: maxDeclarationSummLife,
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationLife,
      label: "Возрастной лимит",
      fieldItems: {
        type: "range",
        isRequired: true,
        errorMessage: "Выберите возрастной лимит",
        placeholder: "Возраст",
        name: "declarationAgeLimit",
        startValue: 0,
        endValue: 100,
        defaultValue: getDefaultValue("declarationAgeLimit"),
        validationType: !declaration || isNaN(declarationMaxAge) ? undefined : "minMax",
        maxValue: declarationMaxAge
      }
    },
    {
      isHidden: !stateDeclaration,
      fieldItems: {
        type: "checkbox",
        label: "Декларация имущества",
        name: "declarationProperty",
        onChange: value => setDeclarationProperty(value),
        isDisabled: type !== "admin" && declaration && !declarationProperty,
        defaultValue: getDefaultValue("declarationProperty")
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationProperty,
      label: "Максимальная страховая сумма по заявке",
      fieldItems: {
        type: "number",
        digitsAfterDot: 2,
        isRequired: true,
        errorMessage: "Введите максимальную страховую сумму",
        placeholder: "Страховая сумма",
        name: "declarationSummProperty",
        defaultValue: getDefaultValue("declarationSummProperty"),
        validationType: !declaration || isNaN(maxDeclarationSummProperty) ? undefined : "minMax",
        maxValue: maxDeclarationSummProperty,
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationProperty,
      label: "Год постройки до",
      fieldItems: {
        isRequired: true,
        errorMessage: "Введите год постройки",
        type: "input",
        name: "declarationYearOfBuild",
        mask: "9999",
        placeholder: "Год постройки / приобретения до",
        defaultValue: getDefaultValue("declarationYearOfBuild"),
        validationType: !autoscoring || isNaN(maxDeclarationYearOfBuild) ? undefined : "minMax",
        minValue: maxDeclarationYearOfBuild
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationProperty,
      label: "Объекты залога",
      fieldItems: {
        isRequired: true,
        errorMessage: "Выберите объекты залога",
        type: "enums",
        name: "declarationObjectType",
        placeholder: "Поиск по объектам залога",
        items: (type === "admin" || !declaration) ? selectPledgeTypes : selectPledgeTypes.map((pledge) => {
          const {value} = pledge;
          if (!declarationObjectType.includes(value)) return {...pledge, isDisabled: true};
          return pledge;
        }),
        isLoading: loadingPledgeTypes,
        defaultValue: getDefaultValue("declarationObjectType")
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationProperty,
      label: "Материал стен",
      fieldItems: {
        isRequired: true,
        errorMessage: "Выберите материал стен",
        type: "enums",
        name: "declarationWallMaterials",
        placeholder: "Поиск по материалам стен",
        items: (type === "admin" || !declaration) ? selectWallMaterial : selectWallMaterial.map((material) => {
          const {value} = material;
          if (!declarationWallMaterials.includes(value)) return {...material, isDisabled: true};
          return material;
        }),
        isLoading: loadingWallMaterial,
        defaultValue: getDefaultValue("declarationWallMaterials")
      }
    },
    {
      isHidden: !stateDeclaration || !stateDeclarationProperty,
      label: "Материал перекрытий",
      fieldItems: {
        isRequired: true,
        errorMessage: "Выберите материал перекрытий",
        type: "enums",
        name: "declarationFloorMaterials",
        placeholder: "Поиск по материалам перекрытий",
        items: (type === "admin" || !declaration) ? selectWallMaterial : selectWallMaterial.map((material) => {
          const {value} = material;
          if (!declarationFloorMaterials.includes(value)) return {...material, isDisabled: true};
          return material;
        }),
        isLoading: loadingWallMaterial,
        defaultValue: getDefaultValue("declarationFloorMaterials")
      }
    },
    {
      isHidden: !stateDeclaration,
      fieldItems: {
        type: "checkbox",
        label: "Декларация титула",
        name: "declarationTitle",
        // onChange: value => setDeclarationTitle(value),
        isDisabled: type !== "admin" && declaration && !declarationTitle,
        defaultValue: getDefaultValue("declarationTitle")
      }
    },
    // {
    //   isHidden: !stateDeclaration || !stateDeclarationTitle,
    //   label: "Максимальная страховая сумма по заявке",
    //   fieldItems: {
    //     type: "number",
    //     digitsAfterDot: 2,
    //     isRequired: true,
    //     errorMessage: "Введите максимальную страховую сумму",
    //     placeholder: "Страховая сумма",
    //     name: "declarationSummTitle",
    //     defaultValue: getDefaultValue("declarationSummTitle"),
    //     validationType: !declaration || isNaN(maxDeclarationSummTitle) ? undefined : "minMax",
    //     maxValue: maxDeclarationSummTitle,
    //   }
    // },
    // {
    //   isHidden: !stateDeclaration || !stateDeclarationTitle,
    //   fieldItems: {
    //     type: "checkbox",
    //     label: "В собственности свыше 3-х лет",
    //     name: "declarationOwnTitle",
    //     isDisabled: type !== "admin" && declaration && !declarationOwnTitle,
    //     defaultValue: getDefaultValue("declarationOwnTitle")
    //   }
    // },
  ];


  const onFormSubmit = (data, type: "user" | "group") => {
    const error = getFormError(data);
    if (error) return error;
    const {
      agentOrGroup,
      autoscoring,
      autoscoringLife,
      autoscoringProperty,
      autoscoringTitle,

      declaration,
      declarationLife,
      declarationProperty,
      declarationTitle
    } = getFormValue(data);
    if (autoscoring && (!autoscoringLife && !autoscoringProperty && !autoscoringTitle)) return setNotification({
      type: "error",
      text: "Вы должны выбрать хотя бы один риск для автосогласования"
    })
    if (declaration && (!declarationLife && !declarationProperty && !declarationTitle)) return setNotification({
      type: "error",
      text: "Вы должны выбрать хотя бы один риск для декларации"
    })

    setRestrictions({
      variables: {
        user: type === "user" ? agentOrGroup : null,
        groupId: type === "user" ? null : agentOrGroup,
        restrictions: Object.entries(getFormValue(data)).map(([key, value]) => {
          if (["summLife", "summProperty", "maxDiscount", "declarationSummLife", "declarationSummProperty", "declarationSummTitle"].includes(key)) return {tag: key, value: (value as any || "").replace(",", ".")};
          return {tag: key, value};
        })
      }
    })
      .then(() => setNotification({
        type: "success",
        text: `Ограничения для ${type === "user" ? "агента" : "группы"} сохранены`
      }))
      .catch(error => setNotification({type: "error", text: error}))
  };

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorGroups) return <ErrorMessage error={errorGroups}/>
  if (errorUsers) return <ErrorMessage error={errorUsers}/>

  return (
    <StyledSettingsWrapper>
      <StyledStepWrapper>
        <Heading2>Настройка</Heading2>
        <Form onSubmit={data => onFormSubmit(data, (stateSelectedUser as any).type)}>
          {({formProps}) => (
            <form {...formProps}>
              <StyledStepFormWrapper>
                <FieldsBuilder formFields={form}/>
              </StyledStepFormWrapper>
              <Button isLoading={loadingSetRestrictions} isDisabled={!(stateSelectedUser || {}).value} type="submit"
                      width="100%" appearance="filled">
                Сохранить изменения
              </Button>
            </form>
          )}
        </Form>
      </StyledStepWrapper>
    </StyledSettingsWrapper>
  )
};

export default Settings;