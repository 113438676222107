import {DEFAULT_STEP_STATE} from "../pages/createRequest/store/states/agreementStep";
import StoreAgreementStep   from "../pages/createRequest/store";
import StoreAgreementForm   from "../pages/createRequest/store";
import React                from "react";

export const useClearGlobalStore = () => {
  const [, {agreementStep: actionsAgreementStep}]: any = StoreAgreementStep();
  const [, {agreementForm: actionsAgreementForm}]: any = StoreAgreementForm();

  const clearGlobalStoreHandler = React.useCallback(() => {
    actionsAgreementForm.setState({
      agreementFormState: {}
    })
    actionsAgreementStep.setState({
      agreementStepState: {...DEFAULT_STEP_STATE}
    })
  }, []);

  return {
    clearGlobalStore: () => clearGlobalStoreHandler(),
  }
};