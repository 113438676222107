import styled      from "styled-components";
import {red} from "../../globalStyledComponents";

export const StyledApplicationSearch = styled.div`
  margin: 4rem 0;
  position: relative;
  
  @media screen and (max-width: 767px) {
    margin: 2rem 0 3rem 0;
  }
`;

export const StyledEmptyTableView = styled.div`
  margin-top: 1.5rem;
`;

export const StyledAgreementLogWrapper = styled.div`
  @media screen and (max-width: 767px) {
    padding-bottom: 4rem;
  }
`;

export const StyledTableButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledFilterIcon = styled.div<{isFilter: boolean}>`
  position: relative;
  
  ${({isFilter}) => 
    isFilter ? 
      `
      ::after {
        content: "";
        width: 5px;
        position: absolute;
        height: 5px;
        background: ${red};
        border-radius: 50%;
        top: -5px;
        right: -5px;
      }
      `
      : ""
  }
`;

export const StyledDropDownStatus = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 1rem;
  }
`;

export const StyledCancelAgreementHeader = styled.div`
  margin-bottom: 3rem;
`;

export const StyledProlongationInfoItem = styled.div`
  > p {
    display: inline-block;
  }  
  > p:first-child {
    opacity: 0.7;
  }
  margin-bottom: 1.5rem;
`;