import React                  from "react";
import {
  StyledRoleFunctions,
  StyledSelectedFunctionItem,
  StyledSelectedFunctionsArea
}                             from "./styles";
import Button                 from "../../../../uiKit/button/button";
import {CloseIcon}            from "../../../../icons/ui/Close";
import {Heading3, TextButton} from "../../../../globalStyledComponents";
import {IEnumItem}            from "../../../../uiKit/enums";

const maxFunctionSymbol = 30;
const functionsPerPage = 10;

export const SelectedFunctions = ({items, onDelete}: { items: IEnumItem[], onDelete: (item: IEnumItem) => void }) => {
  const [stateFunctionsPerPage, setFunctionsPerPage] = React.useState<number>(functionsPerPage)

  const filterFunctions = items.filter((item, index) => index < stateFunctionsPerPage);

  return (
    <StyledRoleFunctions>
      <Heading3>Назначенные функции</Heading3>
      <StyledSelectedFunctionsArea>
        {!!filterFunctions.length ?
          <>
            <div>
              {filterFunctions.map(({label, value}, index) => (
                <StyledSelectedFunctionItem key={`func-${index}`}>
                  <TextButton>{label.length > maxFunctionSymbol ? `${label.substr(0, maxFunctionSymbol)}...` : label}</TextButton>
                  <Button onClick={ev => {
                    ev.preventDefault();
                    onDelete({label, value})
                  }} appearance="icon" icon={<CloseIcon/>}/>
                </StyledSelectedFunctionItem>
              ))}
            </div>
            {filterFunctions.length < items.length &&
            <Button
              onClick={() => setFunctionsPerPage(stateFunctionsPerPage + functionsPerPage)}
              appearance="link">
              Еще {items.length - stateFunctionsPerPage} функций
            </Button>}
          </>
          :
          <TextButton>Нет назначенных функций</TextButton>
        }
      </StyledSelectedFunctionsArea>
    </StyledRoleFunctions>
  )
};

export default SelectedFunctions;