import React                                                                                from "react";
import NivaInput
                                                                                            from "../nivaInput/NivaInput";
import ClickAwayListener
                                                                                            from 'react-click-away-listener';
import {SelectArrowIcon}                                                                    from "../../icons/ui/SelectArrow";
import {
  StyledSelectArrowIcon,
  StyledSelectEmpty, StyledSelectEvents,
  StyledSelectItem,
  StyledSelectItemsWrapper,
  StyledSelectWrapper
} from "./styles";
import {
  Heading4,
  TextButton
}                          from "../../globalStyledComponents";
import {StyledEnumsLoader} from "../enums/styles";
import {Loader}            from "../loader";
import {CSSTransition}     from 'react-transition-group';
import Button              from "../button/button";
import {CloseIcon}         from "../../icons/ui/Close";

export interface ISelectItem {
  label: string
  value: string | number
}

interface SelectProps {
  items: ISelectItem[]
  value?: ISelectItem
  onChange?: (item: ISelectItem | undefined) => void
  placeholder?: string
  errorMessage?: string
  emptyMessage?: string
  isLoading?: boolean
  isInvalid?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  isSearchAvailable?: boolean
  isClearable?: boolean
  height?: number
}

export const Select = ({items, value, emptyMessage, height, isClearable, isRequired, errorMessage, isSearchAvailable, isDisabled, isInvalid, onChange, placeholder, isLoading}: SelectProps) => {
  const [stateSelectOpen, setSelectOpen] = React.useState<boolean>(false);
  const [stateInputValue, setInputValue] = React.useState<string>("");
  const [stateValue, setValue] = React.useState<ISelectItem | undefined>(value || undefined);
  const {value: valueFromState, label: labelFromState} = stateValue || {};

  React.useEffect(() => {
    if (onChange) onChange(stateValue)
    // eslint-disable-next-line
  }, [valueFromState])

  React.useEffect(() => {
    if (valueFromState === (value || {}).value) return;
    setValue(value)
    setInputValue((value || {}).label || "")
    // eslint-disable-next-line
  }, [(value || {}).value])

  React.useEffect(() => {
    if (stateSelectOpen || !isSearchAvailable) return;
    setInputValue((stateValue||{}).label || "")
    // eslint-disable-next-line
  }, [stateSelectOpen])

  const toggleSelectHandler = (isOpen: boolean) => {
    setSelectOpen(isOpen);
  };

  const selectItemHandler = (item: ISelectItem) => {
    const {value, label} = item;
    if (valueFromState === value) return toggleSelectHandler(false)
    toggleSelectHandler(false);
    setInputValue(label);
    return setValue(item);
  }
  const inputChangeHandler = (value: string) => {
    if (!stateSelectOpen) setSelectOpen(true);
    setInputValue(value)
  }

  const filterItems = isSearchAvailable ? items.filter(({label}) => String(label).toLowerCase().includes(stateInputValue.toLowerCase())) : items;
  return (
    <StyledSelectWrapper>
      <NivaInput
        readOnly={!isSearchAvailable}
        isControlled={true}
        isDisabled={!!isDisabled}
        onClick={() => toggleSelectHandler(true)}
        placeholder={placeholder}
        isRequired={!!isRequired}
        errorMessage={errorMessage}
        isInvalid={isInvalid}
        onChange={isSearchAvailable ? inputChangeHandler : undefined}
        value={isSearchAvailable ? stateInputValue : labelFromState || ""}
        iconAfter={
          <StyledSelectEvents>
            {!!(stateValue||{}).value && !!isClearable && <Button isDisabled={!!isDisabled} appearance="icon" onClick={(ev) => {
              ev.preventDefault();
              setValue(undefined)
              if (isSearchAvailable) setInputValue("");
            }} icon={<CloseIcon/>}/>}
            <StyledSelectArrowIcon onClick={!!isDisabled ? undefined : () => toggleSelectHandler(true)}>
              <SelectArrowIcon direction={stateSelectOpen ? "top" : "down"}/>
            </StyledSelectArrowIcon>
          </StyledSelectEvents>
        }/>
      <ClickAwayListener onClickAway={() => toggleSelectHandler(false)}>
        <CSSTransition
          in={stateSelectOpen}
          timeout={300}
          mountOnEnter
          unmountOnExit
          classNames={{
            enterActive: "listShow",
            enterDone: "listShow",
            exitActive: "listHide",
            exitDone: "listHide"
          }}
        >
          <StyledSelectItemsWrapper height={height}>
            {isLoading ?
              <StyledEnumsLoader>
                <Loader type="simple"/>
              </StyledEnumsLoader>
              :
              <>
                {!!(filterItems || []).length ?
                  <>
                    {filterItems.map((item, index) => (
                      <StyledSelectItem
                        key={`select-item-${index}`}
                        onClick={() => selectItemHandler(item)}
                        isActive={valueFromState === item.value}>
                        <TextButton>{item.label}</TextButton>
                      </StyledSelectItem>
                    ))}
                  </>
                  :
                  <StyledSelectEmpty>
                    <Heading4>{emptyMessage || "Нет элементов"}</Heading4>
                  </StyledSelectEmpty>
                }
              </>
            }
          </StyledSelectItemsWrapper>
        </CSSTransition>
      </ClickAwayListener>
    </StyledSelectWrapper>
  )
};

export default Select;