import styled                 from "styled-components";
import {blue7, white} from "../../globalStyledComponents";

export const StatisticsCardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 4rem;
  
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
`;

export const StyledStatisticsCard = styled.div`
  padding: 3rem;
  background: ${white};
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  min-height: 53rem;
  
  @media screen and (max-width: 767px) {
    padding: 1.5rem;
    min-height: 35rem;
  }
`;
export const StyledCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
  
  @media screen and (max-width: 767px) {
    align-items: unset;
    justify-content: unset;
    flex-direction: column;
    .linkButton {
      margin-top: 1.5rem;
      width: fit-content;
    }
  }
`;

export const StyledCardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  border-bottom: 1px solid ${blue7};
  > p:first-child {
    opacity: 0.5;
  }
`;

export const StyledStatisticsCardWarning = styled.div`
  padding: 3rem;
  background: ${white};
  box-shadow: 0px 2px 20px rgba(0, 61, 129, 0.1);
  margin-bottom: 4rem;
  
  @media screen and (max-width: 767px) {
    padding: 1.5rem;
  }
`;