import React from 'react';

export const AttentionIcon = () => (
  <svg width="2rem" height="2rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="9" stroke="#003D81" strokeWidth="2"/>
    <path d="M10 6V12" stroke="#D23630" strokeWidth="2"/>
    <path d="M10 13V14" stroke="#D23630" strokeWidth="2"/>
  </svg>

);
