import styled             from "styled-components";
import {black, lightBlue} from "../../../../../globalStyledComponents";

export const StyledContacts = styled.div<{isAuth?: boolean}>`
  ${({isAuth}) => 
    isAuth ?
      `
      p {
        opacity: 0.5;
      }
      margin-top: 4rem;
      `
      :
      `
      margin-top: 3rem;
      padding: 0 4rem;
      p {
        white-space: nowrap;
        margin-top: 1.5rem;
        opacity: 0.5;
      }
      `
  }
  display: flex;
  flex-direction: column;
  
  h5 {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }  
  
  h5 > span {
    display: block;
    margin-left: 1rem;
  } 
   
  h5 > svg {
    min-width: 1.5rem;
  }
`;

export const StyledCuratorContacts = styled.div`
  cursor: pointer;
  :hover {
    color: ${lightBlue};
  }
  margin-bottom: 1.5rem;
`;

export const StyledContactPhone = styled.a`
  :hover {
    color: ${lightBlue};
  }
  :focus {
    outline: none;
  }
  color: ${black};
  text-decoration: none;
  margin-bottom: 1.5rem;
`;