import React                                                                                                    from "react";
import {useCallNotification}                                                                                    from "../../hooks/useCallNotification";
import {useLazyQuery, useMutation, useQuery}                                                                    from "@apollo/react-hooks";
import {ANKETA_DOWNLOAD, ANKETA_TEMPLATE, GET_AGREEMENT, ME}                                                    from "../../utils/queries";
import { SIGN_ANKETA, UPDATE_AGREEMENT_CALC_FULL, UPDATE_ANKETA, UPDATE_CONTRAGENT, VERIFY_ANKETA} from "../../utils/mutations";
import {IGNORED_STEPS_NAME, templateToFormRebuild}                                                              from "../../helpers/templateToFormRebuild";
import {IFormField}                                                                                             from "../../uiKit/fieldsBuilder/interfaces";
import {fileDownloader}                                                                                         from "../../helpers/fileDownloader";
import {fieldsValidate}                                                                                         from "../../formUtils/fieldsValidation";
import {CONTRAGENT_FIELDS, ContragentForm}                                                                      from "../createRequest/components/generalStep";
import {BUSYNESS_FIELDS, BusynessForm}                                                                          from "../createRequest/components/busynessStep";
import {AGREEMENT_FIELDS, AgreementForm}                                                                        from "../createRequest/components/agreementStep";
import {getFormError}                                                                                           from "../../formUtils/getFormError";
import {
  agreementAdditionalFilling, ANKETA_ATTRIBUTES,
  anketaPartsBuilder, anketaSettingsBuilder, findValueByIsn, getValueByAttributeId, getValueFromObjectsAttributes,
  kiasAgreementToFormState, REMARK_ISN
} from "../../helpers/buildTemplateForBack";
import {getFormValue, getSelectValue}                                                                           from "../../formUtils/getFormValue";
import {Loader}                                                                                                 from "../../uiKit/loader";
import {StyledCoBorrowerStepWrapper}                                                                            from "../createRequest/components/insuranceStep/components/onlineFillingCoBorrower/styles";
import Toggle                                                                                                   from "../../uiKit/toggle";
import HealthForm                                                                                               from "../createRequest/components/insuranceStep/components/onlineFilling/healthForm";
import FieldsBuilder                                                    from "../../uiKit/fieldsBuilder";
import {getFormAdditionalInsurance}                                     from "../createRequest/components/insuranceStep/components/onlineFilling/staticData";
import {StyledStepFormHeader, StyledStepFormWrapper, StyledStepWrapper} from "../createRequest/components/generalStep/styles";
import {Heading2, Heading3, TextButton}                                from "../../globalStyledComponents";
import {StyledDownloadPdf, StyledFileName, StyledOnlineFillingWrapper} from "../createRequest/components/insuranceStep/components/onlineFilling/styles";
import {FileIcon}                                                      from "../../icons/ui/File";
import Button                                                          from "../../uiKit/button/button";
import {DownloadIcon}                                                  from "../../icons/ui/Download";
import Form                                                            from '@atlaskit/form';
import {HEALTH_ANKETA}                                                 from "../createRequest/components/insuranceStep/components/onlineFillingCoBorrower";
import {ErrorMessage}                                                  from "../../components/errorMessage";
import Table, {ITableHeadItem, ITableRowItem}                          from "../../uiKit/table";
import Status                                                          from "../../uiKit/status";
import {StyledMyAnketasPage}                                           from "./styles";
import {useHistory}                                                    from "react-router";
import UseSaveAddressPerson                                            from "../../hooks/useSaveAddressPerson";

export const ContragentAnketa = () => {
  const {setNotification} = useCallNotification();
  const [stateVerifyCode, setVerifyCode] = React.useState<string>("");
  const [stateSubmitType, setSubmitType] = React.useState<"download" | "verify" | undefined>(undefined)


  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const [getAgreement, {data: dataAgreement, loading: loadingAgreement, error: errorAgreement}] = useLazyQuery(GET_AGREEMENT)
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [downloadAnketa, {data: dataDownloadAnketa, loading: loadingDownloadAnketa, error: errorDownloadAnketa}] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [updateAnketa, {data: dataUpdateAnketa, loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL, {refetchQueries: ["agreementCalc", "anketaTemplate"]});
  const [verifyAnketa, {loading: loadingVerifyAnketa}] = useMutation(VERIFY_ANKETA);
  const [signAnketa, {data: dataSignAnketa, loading: loadingSignAnketa}] = useMutation(SIGN_ANKETA);

  const {currentAgreement} = (dataUser || {}).me || {};
  const {ISN: isnPerson} = ((dataUser || {}).me || {}).kias || {};

  const agreementData = React.useMemo(() => dataAgreement?.agreementCalc || {}, [dataAgreement])
  const formData = React.useMemo(() => kiasAgreementToFormState({agreementData}), [agreementData]);
  const {peoples} = formData || {};
  const person = (peoples || []).find(({isn}) => isn === isnPerson);
  const remarkAnketa = getValueFromObjectsAttributes(agreementData?.objects?.find(({SubjISN}) => SubjISN === isnPerson), REMARK_ISN)
  const isDisabled: boolean = !!remarkAnketa;
  const {type} = person || {};
  const {templateVariables} = formData || {};
  const urlFile = ((dataDownloadAnketa || {}).documentForm || {}).url;
  const isnAnketa = ((dataUpdateAnketa || {}).anketa || {}).isn || templateVariables?.isn;
  const {fullName, isn: isnContragent} = person || {};

  const isSigningAnketa = (dataSignAnketa || {}).anketaSign;
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const healthForm: IFormField[] = ((templateToFormRebuild(parts, isDisabled).find(({id}) => id === "insuranceForm") || {}).form || []);

  const anketaOneLevelValue = (parts || []).reduce((prev, {name, questions: {row}}) => {
    if (IGNORED_STEPS_NAME.includes(name)) return prev;
    return [...prev, ...row]
  }, []);
  const {phoneMobile} = findValueByIsn(Object.entries(ANKETA_ATTRIBUTES).reduce<any>((prev, [, value]) => ([...prev, ...value]), []), anketaOneLevelValue) || {};
  const {
    CheckRes: scoringCheck,
    ResultScoring,
    Koef_Height_weight: heightWeight,
    Koef_Pressure: pressure
  } = (dataSignAnketa || {}).anketaSign || {};
  const scoringResult: string | undefined = heightWeight === "МО" || pressure === "МО" ? `${ResultScoring} Необходимо прохождение медицинского обследования.` : ResultScoring;


  React.useEffect(() => {
    if (!currentAgreement) return;
    getAgreement({variables: {isn: currentAgreement}})
    // eslint-disable-next-line
  }, [currentAgreement])

  React.useEffect(() => {
    console.log("templateVariables", templateVariables)
    if (!templateVariables) return;
    getAnketa({variables: templateVariables})
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  React.useEffect(() => {
    if (!stateVerifyCode || fieldsValidate({value: stateVerifyCode, validationType: "code"})) return;
    signAnketa({
      variables: {
        isn: isnAnketa,
        code: stateVerifyCode
      }
    }).then(() => setNotification({type: "success", text: "Анкета подтверждена"}))
      .catch(error => setNotification({type: "error", text: error}))
    // eslint-disable-next-line
  }, [stateVerifyCode])

  const sendCodeForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "phone",
          validationType: "phone",
          mask: "+7 (999) 999-99-99",
          isRequired: true,
          defaultValue: phoneMobile || "",
          isDisabled: true,
          errorMessage: "Введите телефон",
          placeholder: "Номер телефона"
        },
        {
          type: "input",
          name: "code",
          mask: "999999",
          isDisabled: isSigningAnketa || isDisabled,
          onChange: (value) => setVerifyCode(value),
          validationType: "code",
          placeholder: "Код из СМС"
        },
      ]
    }
  ]

  const saveAnketaHandler = () => {
    if (!isnAnketa) return setNotification({type: "error", text: "Отсутствует isn анкеты"})
    if (!isSigningAnketa) return setNotification({type: "error", text: "Анкета еще не подтверждена"})
    agreementCalc({
      variables: agreementAdditionalFilling({
        ...kiasAgreementToFormState({agreementData, anketaParts: parts}), peoples: (peoples || []).map(person => {
          const {isn} = person || {};
          if (isn === isnContragent) return {
            ...person,
            isnAnketa,
            scoringCheck,
            scoringResult,
            heightWeight,
            pressure
          };
          return person;
        })
      }, agreementData, true)
    })
      .then(() => setNotification({type: "success", text: "Данные успешно сохранены"}))
      .catch(error => setNotification({type: "error", text: error}))
  }

  const onFormSubmit = (data) => {
    if (isSigningAnketa) return saveAnketaHandler();
    const error = getFormError(data);
    if (error) return error;
    const partsWithValue: any[] = anketaPartsBuilder({formData, ...person, ...getFormValue(data)}, parts);
    const settingsWithValue: any[] = anketaSettingsBuilder({formData, ...person, ...getFormValue(data)}, settings);
    updateAnketa({
      variables: {
        isn: isnAnketa,
        clientIsn: isnContragent,
        settings: settingsWithValue,
        parts: partsWithValue,
        templateVariables
      }
    }).then(({data: {anketa: {isn}}}) => {
      if (stateSubmitType === "download") {
        downloadAnketa({
          variables: {
            isn
          }
        })
        return setSubmitType(undefined)
      }
      if (stateSubmitType === "verify") {
        verifyAnketa({
          variables: {
            clientIsn: isnContragent,
            isn
          }
        })
          .catch(error => setNotification({type: "error", text: error}))
        return setSubmitType(undefined)
      }
      return saveAnketaHandler();
    }).catch(error => setNotification({type: "error", text: error}))
  }

  console.log("reeerererere")
  if (type === "coBorrower") return <CoBorrowerAnketa/>;
  const isLoading: boolean = loadingDownloadAnketa || loadingSignAnketa || loadingAgreementCalc || loadingUpdateAnketa || loadingVerifyAnketa;
  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})
  if (errorDownloadAnketa) setNotification({type: "error", text: errorDownloadAnketa})
  if (loadingAnketaTemplate || loadingUser || loadingAgreement) return <Loader type="simple"/>
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>

  return (
    <StyledStepWrapper>
      <StyledOnlineFillingWrapper>
        <Heading3>Анкета успешно заполнена вы можете посмотреть ее данные</Heading3>

        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps} id="lifeAnketaForm">
              <StyledStepFormWrapper>
                <StyledStepFormHeader>
                  <Heading3>Общие данные</Heading3>
                </StyledStepFormHeader>
                <HealthForm healthPartForm={healthForm}/>
              </StyledStepFormWrapper>
              <StyledStepFormWrapper>
                <StyledStepFormHeader>
                  <Heading3>Дополнительная информация о страхователе</Heading3>
                </StyledStepFormHeader>
                <FieldsBuilder formFields={getFormAdditionalInsurance(parts, isDisabled)}/>
                <Heading3>Вы можете скачать PDF с заполненной анкетой</Heading3>
                <StyledDownloadPdf>
                  <StyledFileName>
                    <FileIcon/>
                    <TextButton>{fullName || "заполни 1 шаг 🧐"}</TextButton>
                  </StyledFileName>
                  <Button appearance="link" isLoading={isLoading}
                          onClick={() => setSubmitType("download")} form="lifeAnketaForm"
                          iconBefore={<DownloadIcon/>}>Скачать</Button>
                </StyledDownloadPdf>
              </StyledStepFormWrapper>
            </form>
          )}
        </Form>

        <StyledStepFormWrapper>
          <StyledStepFormHeader>
            <Heading3>Введите номер телефона, чтобы мы могли выслать Вам смс с кодом подтверждения</Heading3>
          </StyledStepFormHeader>
          <FieldsBuilder formFields={sendCodeForm}/>
          <Button
            appearance="transparent"
            isLoading={isLoading}
            isDisabled={isSigningAnketa || isDisabled}
            onClick={() => setSubmitType("verify")}
            form="lifeAnketaForm"
            width="100%">
            Выслать код
          </Button>
        </StyledStepFormWrapper>
        <Button appearance="filled" width="100%" isDisabled={isDisabled} form="lifeAnketaForm" onClick={() => setSubmitType(undefined)}>Сохранить</Button>
      </StyledOnlineFillingWrapper>
    </StyledStepWrapper>
  )
};

const CoBorrowerAnketa = () => {
  const {setNotification} = useCallNotification();
  const {saveAddress, loading: loadingSaveAddress} = UseSaveAddressPerson();
  const [stateVerifyCode, setVerifyCode] = React.useState<string>("");
  const [stateSubmitType, setSubmitType] = React.useState<"download" | "verify" | undefined>(undefined)
  const [stateOpenToggles, setOpenToggles] = React.useState<string[]>([]);

  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const [getAgreement, {data: dataAgreement, loading: loadingAgreement, error: errorAgreement}] = useLazyQuery(GET_AGREEMENT)
  const [getAnketa, {data: dataAnketaTemplate, loading: loadingAnketaTemplate, error: errorAnketaTemplate}] = useLazyQuery(ANKETA_TEMPLATE);
  const [downloadAnketa, {data: dataDownloadAnketa, loading: loadingDownloadAnketa, error: errorDownloadAnketa}] = useLazyQuery(ANKETA_DOWNLOAD, {fetchPolicy: "cache-and-network"});
  const [updateAnketa, {data: dataUpdateAnketa, loading: loadingUpdateAnketa}] = useMutation(UPDATE_ANKETA);
  const [agreementCalc, {loading: loadingAgreementCalc}] = useMutation(UPDATE_AGREEMENT_CALC_FULL, {refetchQueries: ["agreementCalc", "anketaTemplate"]});
  const [verifyAnketa, {loading: loadingVerifyAnketa}] = useMutation(VERIFY_ANKETA);
  const [signAnketa, {data: dataSignAnketa, loading: loadingSignAnketa}] = useMutation(SIGN_ANKETA);
  const [updateContragent, {loading: loadingUpdateContragent}] = useMutation(UPDATE_CONTRAGENT);

  const {currentAgreement} = (dataUser || {}).me || {};
  const {ISN: isnPerson} = ((dataUser || {}).me || {}).kias || {};

  const agreementData = React.useMemo(() => dataAgreement?.agreementCalc || {}, [dataAgreement])
  const formData = React.useMemo(() => kiasAgreementToFormState({agreementData}), [agreementData]);
  const {peoples} = formData || {};
  const person = (peoples || []).find(({isn}) => isn === isnPerson);
  const remarkAnketa = getValueFromObjectsAttributes(agreementData?.objects?.find(({SubjISN}) => SubjISN === isnPerson), REMARK_ISN)
  const isDisabled: boolean = !!remarkAnketa;
  const urlFile = ((dataDownloadAnketa || {}).documentForm || {}).url;
  const isnAnketa = ((dataUpdateAnketa || {}).anketa || {}).isn;
  const {templateVariables} = formData || {};
  const {fullName, isn: isnContragent} = person || {};
  const isSigningAnketa = (dataSignAnketa || {}).anketaSign;
  const {parts, settings} = (dataAnketaTemplate || {}).anketaTemplate || {};
  const forms = [...templateToFormRebuild(parts).filter(({id}) => id !== "insuranceForm"), ...HEALTH_ANKETA];
  const healthForm: IFormField[] = ((templateToFormRebuild(parts, isDisabled).find(({id}) => id === "insuranceForm") || {}).form || []);

  const anketaOneLevelValue = (parts || []).reduce((prev, {name, questions: {row}}) => {
    if (IGNORED_STEPS_NAME.includes(name)) return prev;
    return [...prev, ...row]
  }, []);
  const {phoneMobile} = findValueByIsn(Object.entries(ANKETA_ATTRIBUTES).reduce<any>((prev, [, value]) => ([...prev, ...value]), []), anketaOneLevelValue) || {};


  const {
    CheckRes: scoringCheck,
    ResultScoring,
    Koef_Height_weight: heightWeight,
    Koef_Pressure: pressure
  } = (dataSignAnketa || {}).anketaSign || {};
  const scoringResult: string | undefined = heightWeight === "МО" || pressure === "МО" ? `${ResultScoring} Необходимо прохождение медицинского обследования.` : ResultScoring;

  React.useEffect(() => {
    if (!currentAgreement) return;
    getAgreement({variables: {isn: currentAgreement}})
    // eslint-disable-next-line
  }, [currentAgreement])

  React.useEffect(() => {
    if (!templateVariables) return;
    getAnketa({variables: {...templateVariables, role: "2"}})
    // eslint-disable-next-line
  }, [templateVariables])

  React.useEffect(() => {
    fileDownloader(urlFile)
    // eslint-disable-next-line
  }, [urlFile])

  React.useEffect(() => {
    if (!stateVerifyCode || fieldsValidate({value: stateVerifyCode, validationType: "code"})) return;
    signAnketa({
      variables: {
        isn: isnAnketa,
        code: stateVerifyCode
      }
    }).then(() => setNotification({type: "success", text: "Анкета подтверждена"}))
      .catch(error => setNotification({type: "error", text: error}))
    // eslint-disable-next-line
  }, [stateVerifyCode])

  const sendCodeForm: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "input",
          name: "phone",
          validationType: "phone",
          mask: "+7 (999) 999-99-99",
          isRequired: true,
          defaultValue: phoneMobile || "",
          isDisabled: true,
          errorMessage: "Введите телефон",
          placeholder: "Номер телефона"
        },
        {
          type: "input",
          name: "code",
          mask: "999999",
          isDisabled: isSigningAnketa,
          onChange: (value) => setVerifyCode(value),
          validationType: "code",
          placeholder: "Код из СМС"
        },
      ]
    }
  ]

  const saveAnketaHandler = () => {
    if (!isnAnketa) return setNotification({type: "error", text: "Отсутствует isn анкеты"})
    if (!isSigningAnketa) return setNotification({type: "error", text: "Анкета еще не подтверждена"})
    agreementCalc({
      variables: agreementAdditionalFilling({
        ...kiasAgreementToFormState({agreementData, anketaParts: parts}), peoples: (peoples || []).map(person => {
          const {isn} = person || {};
          if (isn === isnContragent) return {
            ...person,
            isnAnketa,
            scoringCheck,
            scoringResult,
            heightWeight,
            pressure
          };
          return person;
        })
      }, agreementData, true)
    })
      .then(() => setNotification({type: "success", text: "Данные успешно сохранены"}))
      .catch(error => setNotification({type: "error", text: error}))
  }

  const toggleSwitchHandler = (isn: string, isOpen: boolean) => {
    if (!isOpen) return setOpenToggles(stateOpenToggles.filter(toggle => toggle !== isn));
    return setOpenToggles([...stateOpenToggles.filter(toggle => toggle !== isn), isn]);
  };

  const toggleOpenByError = (fieldName: string) => {
    if (CONTRAGENT_FIELDS.includes(fieldName)) return toggleSwitchHandler("generalForm", true);
    if (BUSYNESS_FIELDS.includes(fieldName)) return toggleSwitchHandler("busynessForm", true);
    if (AGREEMENT_FIELDS.includes(fieldName)) return toggleSwitchHandler("agreementForm", true);
    if (!stateOpenToggles.includes("healthForm")) return toggleSwitchHandler("healthForm", true);
  };

  const onFormSubmit = (data) => {
    if (isSigningAnketa) return saveAnketaHandler();
    const error = getFormError(data);
    const errorFieldName = (Object.keys(error || {}) || [])[0];
    if (!!errorFieldName) {
      toggleOpenByError(errorFieldName)
      return error;
    }
    const partsWithValue: any[] = anketaPartsBuilder({...getFormValue(data), ...person}, parts);
    const settingsWithValue: any[] = anketaSettingsBuilder({...getFormValue(data), ...person}, settings);
    const contragentVariables: any = {...person, ...CONTRAGENT_FIELDS.reduce((prev, key) => ({...prev, [key]: getFormValue(data)[key]}), {})};
    const {addr1, addr2, countryIsn, gender, ...other} = contragentVariables || {};
    const variables = {
      ...other,
      gender: getSelectValue(gender),
      countryIsn: getSelectValue(countryIsn)
    };
    updateContragent({variables})
      .then(() => {
        saveAddress(isnContragent, addr1, "2246");
        saveAddress(isnContragent, addr2, "2247");
        updateAnketa({
          variables: {
            isn: isnAnketa,
            clientIsn: isnContragent,
            settings: settingsWithValue,
            parts: partsWithValue,
            templateVariables: {variables: {...templateVariables, role: "2"}}
          }
        }).then(({data: {anketa: {isn}}}) => {
          if (stateSubmitType === "download") {
            downloadAnketa({
              variables: {
                isn
              }
            })
            return setSubmitType(undefined)
          }
          if (stateSubmitType === "verify") {
            verifyAnketa({
              variables: {
                clientIsn: isnContragent,
                isn
              }
            })
              .catch(error => setNotification({type: "error", text: error}))
            return setSubmitType(undefined)
          }
          return saveAnketaHandler();
        }).catch(error => setNotification({type: "error", text: error}))
      })
      .catch(error => setNotification({type: "error", text: error}))
  }

  const isLoading: boolean = loadingDownloadAnketa || loadingAgreementCalc || loadingSaveAddress || loadingSignAnketa || loadingUpdateAnketa || loadingUpdateContragent || loadingVerifyAnketa;
  if (errorAnketaTemplate) setNotification({type: "error", text: errorAnketaTemplate})
  if (errorDownloadAnketa) setNotification({type: "error", text: errorDownloadAnketa})
  if (loadingAnketaTemplate || loadingUser || loadingAgreement) return <Loader type="simple"/>
  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>
  return (
    <StyledStepWrapper>
      <StyledOnlineFillingWrapper>
        <Form onSubmit={data => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps} id="healthFormCoBorrower">
              {forms.map(({title, id}) =>
                <StyledCoBorrowerStepWrapper key={`healthCoBorrowerPart-${id}`}>
                  <Toggle
                    header={title}
                    scrollToTop={true}
                    notUnmount={true}
                    onClick={isOpen => toggleSwitchHandler(id, isOpen)}
                    isOpen={stateOpenToggles.includes(id)}
                  >
                    {id === "generalForm" && <ContragentForm isn={isnContragent}/>}
                    {id === "busynessForm" && <BusynessForm/>}
                    {id === "agreementForm" && <AgreementForm defaultValue={formData}/>}
                    {id === "healthForm" && <HealthForm healthPartForm={healthForm}/>}
                    {id === "additionalHealthForm" && <FieldsBuilder formFields={getFormAdditionalInsurance(parts, isDisabled)}/>}
                  </Toggle>
                </StyledCoBorrowerStepWrapper>
              )}
            </form>
          )}
        </Form>
        <StyledStepFormWrapper>
          <Heading3>Вы можете скачать PDF с заполненной анкетой</Heading3>
          <StyledDownloadPdf>
            <StyledFileName>
              <FileIcon/>
              <TextButton>{fullName || "заполни 1 шаг 🧐"}</TextButton>
            </StyledFileName>
            <Button
              appearance="link"
              isLoading={isLoading}
              onClick={() => setSubmitType("download")}
              form="healthFormCoBorrower"
              iconBefore={<DownloadIcon/>}>
              Скачать
            </Button>
          </StyledDownloadPdf>
        </StyledStepFormWrapper>
        <StyledStepFormWrapper>
          <StyledStepFormHeader>
            <Heading3>Введите номер телефона, чтобы мы могли выслать Вам смс с кодом подтверждения</Heading3>
          </StyledStepFormHeader>
          <FieldsBuilder formFields={sendCodeForm}/>
          <Button
            appearance="transparent"
            isLoading={isLoading}
            isDisabled={isSigningAnketa || isDisabled}
            onClick={() => setSubmitType("verify")}
            form="healthFormCoBorrower"
            width="100%">
            Выслать код
          </Button>
        </StyledStepFormWrapper>
        <Button appearance="filled" width="100%" isDisabled={isDisabled} form="healthFormCoBorrower" onClick={() => setSubmitType(undefined)}>Сохранить</Button>
      </StyledOnlineFillingWrapper>
    </StyledStepWrapper>
  )
};

const AnketasLog = () => {
  const history = useHistory();
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const [getAgreement, {data: dataAgreement, loading: loadingAgreement, error: errorAgreement}] = useLazyQuery(GET_AGREEMENT)
  const agreementData = dataAgreement?.agreementCalc || {}
  const formState = kiasAgreementToFormState({agreementData});

  const {currentAgreement} = (dataUser || {}).me || {};
  const {ISN: isnPerson} = ((dataUser || {}).me || {}).kias || {};
  const {creditIssueDate} = formState || {};

  const remarkAnketa = getValueFromObjectsAttributes(dataAgreement?.agreementCalc?.objects?.find(({SubjISN}) => SubjISN === isnPerson), REMARK_ISN)
  const isAlreadyFilled: boolean = !!remarkAnketa;
  const anketaNumber: string = getValueByAttributeId(dataAgreement?.agreementCalc?.attribs, "isnAnketa", true);

  React.useEffect(() => {
    if (!currentAgreement) return;
    getAgreement({variables: {isn: currentAgreement}})
    // eslint-disable-next-line
  }, [currentAgreement])

  const headsList = ["Номер котировки", "Номер анкеты", "Дата анкеты", "Статус"];

  const head: ITableHeadItem = {
    cells: headsList.map((content, index) => ({key: `head-${index}`, content}))
  };

  const rows: ITableRowItem[] = [
    {
      onClick: () => history.push("/myAnketa/currentAnketa"),
      key: "currentAnketa",
      id:  "currentAnketa",
      cells: [
        {key: `cell-number`, content: agreementData?.id},
        {key: `cell-anketa-number`, content: anketaNumber},
        {key: `cell-date`, content: creditIssueDate},
        {key: `cell-status`, content: <Status status={isAlreadyFilled ? "filled" : "notFilled"}/>}
      ]
    }
  ]

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (errorAgreement) return <ErrorMessage error={errorAgreement}/>

  return (
    <StyledMyAnketasPage>
      <Heading2>Мои анкеты</Heading2>
      <Table head={head} rows={rows} isLoading={loadingUser || loadingAgreement} emptyViewMessage="У вас нет анкет нуждающихся в заполнении"/>
    </StyledMyAnketasPage>
  )
}

export default AnketasLog;