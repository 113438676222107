import React                       from 'react';
import Modal, {ModalProps}         from "../../../../../uiKit/modal";
import {useHistory}                from "react-router";
import {DELETE_GROUP}              from "../../../../../utils/mutations";
import {useMutation}               from "@apollo/react-hooks";
import {Heading3}                  from "../../../../../globalStyledComponents";
import Button                      from "../../../../../uiKit/button/button";
import DeleteRoleModal             from "../../rolesTab/deleteRoleModal";
import {StyledDeleteRoleModalBody} from "../../rolesTab/styles";
import {StyledButtonsGroup}  from "../../header/styles";
import {useCallNotification} from "../../../../../hooks/useCallNotification";


export const DeleteGroupModal = ({onClose, isOpen, data}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const {id} = data || {};
  const history = useHistory();
  const [deleteGroup, {loading: loadingDeleteGroup}] = useMutation(DELETE_GROUP, {refetchQueries: ["groups"]});
  const deleteGroupHandler = () => {
    deleteGroup({
      variables: {
        id
      }
    }).then(() => {
      onClose()
      history.push("/roles")
    })
      .catch(error => setNotification({type: "error", text: error}))
  }
  return (
    <Modal onClose={onClose} isOpen={isOpen} header={undefined}>
      <StyledDeleteRoleModalBody>
        <Heading3>Вы действительно хотите удалить группу?</Heading3>
        <StyledButtonsGroup>
          <Button appearance="filled" onClick={deleteGroupHandler} isLoading={loadingDeleteGroup}>
            Да
          </Button>
          <Button onClick={() => onClose()} appearance="transparent" isLoading={loadingDeleteGroup}>
            Нет
          </Button>
        </StyledButtonsGroup>
      </StyledDeleteRoleModalBody>
    </Modal>
  )
};

export default DeleteRoleModal;