import {useGetDict}                                                                                                 from "../../../../hooks/useGetDicti";
import {ALLOWANCE_ISN, CONSTANT_PAY_ISN, DISCOUNT_ISN, getValueFromObjectsAttributes, ONE_TIME_PAY_ISN, TARIFF_ISN} from "../../../../helpers/buildTemplateForBack";
import {IFormField}                                                                                                 from "../../../../uiKit/fieldsBuilder/interfaces";
import {StyledObjectHeader}    from "../../styles";
import {Heading4}              from "../../../../globalStyledComponents";
import Button                  from "../../../../uiKit/button/button";
import {TrashIcon}             from "../../../../icons/ui/Trash";
import FieldsBuilder           from "../../../../uiKit/fieldsBuilder";
import React                   from "react";
import {IObjectProps}                                                                                from "../../index";
import {getSelectDefaultValue, stringToNumber}                                                       from "../../../../formUtils/getFormValue";

export const PropertyObject = ({object, index, onDelete, isDisabledDelete, isDisabled, changeIsPreCalcNeeded, maxDiscount}: IObjectProps) => {
  const {data: selectPledge, loading: loadingPledge} = useGetDict("pledgeType");
  const {SubClassISN: type, AGRCOND: agrCond, AGROBJADDR: address} = object || {};
  const {row: rowAgrCond} = agrCond || {}
  const {row: rowAddress} = address || {}
  const {PremiumSum: premium, LimitSum: summ} = Array.isArray(rowAgrCond) ? (rowAgrCond || []).find(({CondName}) => CondName === "1") || {} : rowAgrCond || {}
  const isConstantPay: boolean = getValueFromObjectsAttributes(object, CONSTANT_PAY_ISN) === "1";
  const isOneTimePay: boolean = getValueFromObjectsAttributes(object, ONE_TIME_PAY_ISN) === "1";
  const discount: number = stringToNumber(getValueFromObjectsAttributes(object, DISCOUNT_ISN));
  const allowance: number = stringToNumber(getValueFromObjectsAttributes(object, ALLOWANCE_ISN));
  const tariff: number = stringToNumber(getValueFromObjectsAttributes(object, TARIFF_ISN));

  const form: IFormField[] = [
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "select",
          name: `propertyPledge-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: "Предмет залога",
          items: selectPledge,
          isLoading: loadingPledge,
          defaultValue: getSelectDefaultValue(type, selectPledge) || selectPledge[0]
        },
        {
          type: "address",
          name: `propertyPledgeAddress-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: "Адрес приобретаемого / закладываемого объекта залога",
          defaultValue: (rowAddress || {}).ADDRESS
        },
      ]
    }, {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "number",
          name: `propertySumInsured-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: "Страховая сумма",
          digitsAfterDot: 2,
          defaultValue: summ
        },
        {
          type: "number",
          name: `propertyDiscount-${index}`,
          defaultValue: discount,
          isDisabled,
          onChange: (value) => {
            if (Number(value) === Number(discount)) return
            changeIsPreCalcNeeded()
          },
          placeholder: "Скидка",
          errorMessage: "Введите скидку",
          digitsAfterDot: 2,
          validationType: "minMax",
          maxValue: 100
        }
        // {
        //   type: "number",
        //   name: `propertyTariff-${index}`,
        //   // isRequired: true,
        //   // errorMessage: "",
        //   isDisabled: true,
        //   placeholder: "Тариф",
        //   digitsAfterDot: 4,
        //   defaultValue: tariff
        // },
      ]
    },
    // {
    //   columns: 2, columnGap: 4, fieldItems: [
    //     {
    //       type: "number",
    //       name: `propertyPremium-${index}`,
    //       // isRequired: true,
    //       // errorMessage: "",
    //       isDisabled: true,
    //       placeholder: "Премия",
    //       digitsAfterDot: 2,
    //       defaultValue: premium
    //     },
    //   ]
    // },
    {
      columns: 2,
      columnGap: 4,
      fieldItems: [
        {
          type: "number",
          name: `propertyBasicTariff-${index}`,
          isRequired: true,
          defaultValue: tariff,
          isDisabled,
          onChange: (value) => {
            if (Number(value) === Number(tariff)) return
            changeIsPreCalcNeeded()
          },
          digitsAfterDot: 4,
          placeholder: "Базовый тариф",
          errorMessage: "Введите тариф"
        },
        {
          type: "number",
          name: `propertyPremium-${index}`,
          // isRequired: true,
          // errorMessage: "",
          isDisabled: true,
          placeholder: "Премия",
          digitsAfterDot: 2,
          defaultValue: premium
        },
      ]
    },
    {
      columns: 2, columnGap: 4, fieldItems: [
        {
          type: "number",
          name: `propertyAllowance-${index}`,
          placeholder: "Повышающий коэф %",
          defaultValue: allowance,
          errorMessage: "Введите повышающий коэф",
          digitsAfterDot: 2,
          isDisabled,
          onChange: (value) => {
            if (Number(value) === Number(allowance)) return
            changeIsPreCalcNeeded()
          },
        }
      ]
    },
  ]

  const payCheckbox: IFormField[] = [{
    columns: 4,
    fieldItems: [
      {type: "checkbox", isDisabled, defaultValue: isConstantPay, name: `propertyConstantPay-${index}`, label: "Постоянная страховая сумма"},
      {type: "checkbox", isDisabled, defaultValue: isOneTimePay, name: `propertyOneTimePay-${index}`, label: "Единовременная оплата"}
    ]
  }]

  return (
    <>
      <FieldsBuilder formFields={payCheckbox}/>
      <StyledObjectHeader>
        <Heading4>Объект страхования {index + 1}</Heading4>
        <Button
          appearance="icon"
          isDisabled={isDisabledDelete || isDisabled}
          onClick={ev => {
            ev.preventDefault();
            onDelete()
          }} icon={<TrashIcon/>}/>
      </StyledObjectHeader>
      <FieldsBuilder formFields={form}/>
    </>
  )
}