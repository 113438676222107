import React                                      from 'react';
import {StyledStepFormWrapper, StyledStepWrapper} from '../createRequest/components/generalStep/styles';
import {Heading2, Heading3, TextButton}           from "../../globalStyledComponents";
import {
  StyledProfileButtonsWrapper,
  StyledProfileFieldItem,
  StyledProfilePasswordWrapper,
  StyledProfileWrapper
}                                                 from "./styles";
import Button                                     from "../../uiKit/button/button";
import FieldsBuilder                              from "../../uiKit/fieldsBuilder";
import {IFormField}                               from "../../uiKit/fieldsBuilder/interfaces";
import Form                                       from '@atlaskit/form';
import ChangePasswordModal                        from "./components/changePassword";
import {getFormError}                             from "../../formUtils/getFormError";
import {getFormValue}                             from "../../formUtils/getFormValue";
import {useQuery, useLazyQuery, useMutation}      from "@apollo/react-hooks";
import {CONTRAGENT, ME}                           from "../../utils/queries";
import {Loader}                                   from "../../uiKit/loader";
import {CREATE_UPDATE_USER}                       from "../../utils/mutations";
import {ErrorMessage}                             from "../../components/errorMessage";
import {useCallNotification}                      from "../../hooks/useCallNotification";

interface IRequisiteItem {
  label: string,
  name: string,
  value?: string
}

const requisites: IRequisiteItem[] = [
  {label: "ИНН", name: "inn"},
  {label: "Юридический адрес", name: "addr1"},
  {label: "ОГРН", name: "orgn"},
  // {label: "Дата ОГРН", name: ""},
  {label: "КПП", name: "kpp"},
  // {label: "ОКПО", name: ""}
]

const ProfileAdmin = ({user}: { user: any }) => {
  const {ISN, UpperDeptName: company} = (user || {}).kias || {};
  const {login, name, email} = user || {};
  const [getContragent, {
    data: dataContragent,
    loading: loadingContragent,
    error: errorContragent
  }] = useLazyQuery(CONTRAGENT);
  const {phoneMobile, ...contragent} = (dataContragent || {}).contragent || {}

  React.useEffect(() => {
    if (!ISN) return;
    getContragent({
      variables: {
        isn: ISN
      }
    })
    // eslint-disable-next-line
  }, [ISN])

  const profile: { label: string, value: string }[] = [
    {label: "Компания", value: company},
    {label: "Логин", value: login},
  ]

  const form: IFormField[] = [
    {
      fieldItems: {
        type: "input",
        name: "fullName",
        isDisabled: true,
        validationType: "fullName",
        placeholder: "ФИО",
        defaultValue: name,
        errorMessage: "Введите ФИО"
      }
    },
    {
      columnGap: 4, columns: 2, fieldItems: [
        {
          type: "input",
          name: "phone",
          mask: "+7 (999) 999-99-99",
          isDisabled: true,
          validationType: "phone",
          placeholder: "Телефон",
          defaultValue: phoneMobile,
          errorMessage: "Введите телефон"
        },
        {
          type: "input",
          name: "email",
          validationType: "email",
          isDisabled: true,
          placeholder: "E-mail",
          defaultValue: email,
          errorMessage: "Введите e-mail"
        }
      ]
    }
  ]

  const requisitesList: IRequisiteItem[] = requisites.reduce<IRequisiteItem[]>((prev, {label, name}) => {
    const value = (contragent || {})[name];
    if (!value) return prev;
    return [...prev, {label, name, value}];
  }, [])

  if (errorContragent) return <ErrorMessage error={errorContragent}/>
  if (loadingContragent) return <Loader type="simple"/>
  return (
    <>
      <StyledProfileWrapper>
        <StyledStepWrapper>
          <Heading2>{name}</Heading2>
          <StyledStepFormWrapper>
            <Heading3>Профиль</Heading3>
            {profile.map(({label, value}, index) => (
              <StyledProfileFieldItem key={`profile-field-${index}`}>
                <TextButton>{label}</TextButton>
                <TextButton>{value}</TextButton>
              </StyledProfileFieldItem>
            ))}
            {!!requisitesList.length &&
            <>
              <Heading3>Реквизиты</Heading3>
              {requisitesList.map(({label, name, value}, index) => (
                <StyledProfileFieldItem key={`requisites-field-${index}`}>
                  <TextButton>{label}</TextButton>
                  <TextButton>{value}</TextButton>
                </StyledProfileFieldItem>
              ))}
            </>}

            <Heading3>Контакты</Heading3>
            <FieldsBuilder formFields={form}/>
          </StyledStepFormWrapper>
        </StyledStepWrapper>
      </StyledProfileWrapper>
    </>
  )
}

const ProfileUser = ({user}: { user: any }) => {
  const {setNotification} = useCallNotification();
  const [stateChangePasswordModal, setChangePasswordModal] = React.useState<boolean>(false);
  const [updateUser, {loading: loadingUpdateUser}] = useMutation(CREATE_UPDATE_USER, {refetchQueries: ["me"]});

  const {role, group, login, email, name, id} = user || {}
  const profile: { label: string, value: string }[] = [
    {label: "Группа", value: (group || {}).name},
    {label: "Роль", value: (role || {}).name},
    {label: "Логин", value: login}
  ]

  const form: IFormField[] = [
    {
      columnGap: 4, columns: 2, fieldItems: [
        {
          type: "fio",
          name: "name",
          validationType: "fullName",
          placeholder: "ФИО",
          defaultValue: {value: name},
          errorMessage: "Введите ФИО"
        },
        {
          type: "input",
          name: "email",
          validationType: "email",
          placeholder: "E-mail",
          isDisabled: true,
          defaultValue: email,
          errorMessage: "Введите e-mail"
        }
      ]
    }
  ]

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {name, email} = getFormValue(data);
    updateUser(({
      variables: {
        id,
        name,
        email
      }
    }))
      .then(() => setNotification({type: "success", text: "Данные успешно обновлены"}))
      .catch(error => setNotification({type: "error", text: error}))
  };
  return (
    <>
      <StyledProfileWrapper>
        <StyledStepWrapper>
          <Heading2>{name}</Heading2>
          <StyledStepFormWrapper>
            <Heading3>Профиль</Heading3>
            {profile.map(({label, value}, index) => {
              if (!value) return null;
              return (
                <StyledProfileFieldItem key={`profile-field-${index}`}>
                  <TextButton>{label}</TextButton>
                  <TextButton>{value}</TextButton>
                </StyledProfileFieldItem>
              )
            })}

            <StyledProfileFieldItem>
              <TextButton>Пароль</TextButton>
              <StyledProfilePasswordWrapper>
                <TextButton>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</TextButton>
                <Button appearance="link"
                        onClick={() => setChangePasswordModal(true)}>Изменить</Button>
              </StyledProfilePasswordWrapper>
            </StyledProfileFieldItem>

            <Heading3>Контакты</Heading3>
            <Form onSubmit={data => onFormSubmit(data)}>
              {({formProps}) => (
                <form {...formProps} id="userChangeInfoForm">
                  <FieldsBuilder formFields={form}/>
                </form>
              )}
            </Form>
          </StyledStepFormWrapper>
          <StyledProfileButtonsWrapper>
            <Button
              appearance="transparent"
              form="userChangeInfoForm"
              isDisabled={true}
              isLoading={loadingUpdateUser}
              width="100%"
              type="reset">
              Отменить изменения
            </Button>
            <Button
              appearance="filled"
              isLoading={loadingUpdateUser}
              form="userChangeInfoForm"
              width="100%"
              type="submit">
              Сохранить изменения
            </Button>
          </StyledProfileButtonsWrapper>
        </StyledStepWrapper>
      </StyledProfileWrapper>
      <ChangePasswordModal header="Изменение пароля" onClose={() => setChangePasswordModal(false)}
                           isOpen={stateChangePasswordModal}/>
    </>
  )
}

const ProfileKias = ({user}: { user: any }) => {
  const {role, group, login, email, name} = user || {}
  const profile: { label: string, value: string }[] = [
    {label: "Группа", value: (group || {}).name},
    {label: "Роль", value: (role || {}).name},
    {label: "Логин", value: login}
  ]

  const form: IFormField[] = [
    {
      columnGap: 4, columns: 2, fieldItems: [
        {
          type: "input",
          name: "name",
          isDisabled: true,
          validationType: "fullName",
          placeholder: "ФИО",
          defaultValue: name,
          errorMessage: "Введите ФИО"
        },
        {
          type: "input",
          name: "email",
          isDisabled: true,
          placeholder: "E-mail сотрудника",
          defaultValue: email,
          errorMessage: "Введите e-mail"
        }
      ]
    }
  ]

  return (
    <StyledProfileWrapper>
      <StyledStepWrapper>
        <Heading2>{name}</Heading2>
        <StyledStepFormWrapper>
          <Heading3>Профиль</Heading3>
          {profile.map(({label, value}, index) => {
            if (!value) return null;
            return (
              <StyledProfileFieldItem key={`profile-field-${index}`}>
                <TextButton>{label}</TextButton>
                <TextButton>{value}</TextButton>
              </StyledProfileFieldItem>
            )
          })}

          <Heading3>Контакты</Heading3>
          <FieldsBuilder formFields={form}/>
        </StyledStepFormWrapper>
      </StyledStepWrapper>
    </StyledProfileWrapper>
  )
}

const ProfileClient = ({user}: { user: any }) => {
  const {login, name, email} = user || {};

  const profile: { label: string, value: string }[] = [
    {label: "Логин", value: login}
  ]

  const form: IFormField[] = [
    {
      columnGap: 4, columns: 2, fieldItems: [
        {
          type: "input",
          name: "fullName",
          isDisabled: true,
          validationType: "fullName",
          placeholder: "ФИО",
          defaultValue: name,
          errorMessage: "Введите ФИО"
        },
        {
          type: "input",
          name: "email",
          validationType: "email",
          isDisabled: true,
          placeholder: "E-mail",
          defaultValue: email,
          errorMessage: "Введите e-mail"
        }
      ]
    }
  ]

  return (
    <StyledProfileWrapper>
      <StyledStepWrapper>
        <Heading2>{name}</Heading2>
        <StyledStepFormWrapper>
          <Heading3>Профиль</Heading3>
          {profile.map(({label, value}, index) => {
            if (!value) return null;
            return (
              <StyledProfileFieldItem key={`profile-field-${index}`}>
                <TextButton>{label}</TextButton>
                <TextButton>{value}</TextButton>
              </StyledProfileFieldItem>
            )
          })}

          <Heading3>Контакты</Heading3>
          <FieldsBuilder formFields={form}/>
        </StyledStepFormWrapper>
      </StyledStepWrapper>
    </StyledProfileWrapper>
  )
}

export const Profile = () => {
  const {data: dataUser, loading: loadingUser, error: errorUser} = useQuery(ME);
  const {type, ...user} = (dataUser || {}).me || {};

  if (errorUser) return <ErrorMessage error={errorUser}/>
  if (loadingUser) return <Loader type="simple"/>

  switch (type) {
    case "admin":
      return <ProfileAdmin user={user}/>
    case "user":
      return <ProfileUser user={user}/>
    case "kias":
      return <ProfileKias user={user}/>
    case "client":
      return <ProfileClient user={user}/>
    default:
      return null;
  }
};

export default Profile;