import {IFormField}                        from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {radioBoolean}                      from "../../../../staticData";
import {ANKETA_ATTRIBUTES, findValueByIsn} from "../../../../../../helpers/buildTemplateForBack";
import {getRadioDefaultValue}              from "../../../../../../formUtils/getFormValue";

export const getFormAdditionalInsurance = (parts?: any[], isDisabled?: boolean): IFormField[] => {
  const anketaOneLevelValue = (parts || []).reduce((prev, {questions: {row}}) => {
    if (!Array.isArray(row)) return prev;
    return [...prev, ...row]
  }, []);
  const anketaAttributesOneLevel: any = findValueByIsn(Object.entries(ANKETA_ATTRIBUTES).reduce<any>((prev, [, value]) => ([...prev, ...value]), []), anketaOneLevelValue);
  const {foreigner, faceOfInternational, civilServants, additionalDetails} = anketaAttributesOneLevel || {};

  return [
    {
      label: "Являетесь ли Вы иностранным публичным должностным лицом / или родственником такого лица / действуете ли вы от имени и(или) в интересах такого лица?",
      fieldItems: {
        type: "radio",
        name: "foreigner",
        isDisabled,
        items: radioBoolean,
        defaultValue: getRadioDefaultValue(foreigner, radioBoolean) || radioBoolean[1]
      }
    }, {
      label: "Являетесь ли Вы должностным лицом публичной международной организации или родственником такого лица / действуете ли Вы от имени и (или) в интересах такого лица?",
      fieldItems: {
        type: "radio",
        name: "faceOfInternational",
        isDisabled,
        items: radioBoolean,
        defaultValue: getRadioDefaultValue(faceOfInternational, radioBoolean) || radioBoolean[1]
      }
    }, {
      label: "Являетесь ли Вы госслужащим / или подственником такого лица / действуете ли вы от имени и (или) в интересах такого лица?",
      fieldItems: {
        type: "radio",
        name: "civilServants",
        isDisabled,
        items: radioBoolean,
        defaultValue: getRadioDefaultValue(civilServants, radioBoolean) || radioBoolean[1]
      }
    },
    {
      label: "Если на один из трех предыдущих вопросов Вы ответили «Да», укажите подробности: занимаемую должность, наименование и адрес работодателя, в отношении подственников страхователя — ФИО, степень родства и должность",
      fieldItems: {
        type: "input",
        name: "additionalDetails",
        placeholder: "Подробности",
        isDisabled,
        defaultValue: additionalDetails
      }
    }
  ];
}