import React                          from 'react';
import {
  StyledFormField, StyledPrecalculationForm,
  StyledRisksFormWrapper
}                                     from "./styles";
import Button                         from "../../../../../../uiKit/button/button";
import Form                           from '@atlaskit/form';
import {IRadioItem}                   from "../../../../../../uiKit/radio";
import {Heading2, Heading4}           from "../../../../../../globalStyledComponents";
import Tabs, {ITabsItem}              from "../../../../../../uiKit/tabs";
import {
  radioBoolean,
  radioSex,
  radioTitle
}                                     from "../../../../staticData";
import {StyledPrecalculationFormCard} from "../../styles";
import FieldsBuilder                  from "../../../../../../uiKit/fieldsBuilder";
import {IFormField}                   from "../../../../../../uiKit/fieldsBuilder/interfaces";
import {getFormError}                 from "../../../../../../formUtils/getFormError";
import {getFormValue}                 from "../../../../../../formUtils/getFormValue";
import {useGetDict}                   from "../../../../../../hooks/useGetDicti";
import {getFormValueWithArray}        from "../../../../../../formUtils/getFormValueWithArray";
import moment                         from "moment";
import {useQuery} from "@apollo/react-hooks";
import {GET_AGREEMENT_AGENTS, ME} from "../../../../../../utils/queries";
import {rolesRightHandler} from "../../../../../../helpers/rolesRightHandler";
import {useCallNotification} from "../../../../../../hooks/useCallNotification";
import {isMoRegion, isNewRegion} from "../../../../../../utils/address";

const mode = process.env.REACT_APP_MODE

const Risks = ({riskType}: { riskType: "life" | "title" | "property" }) => {
  const {data: selectPledge, loading: loadingPledge} = useGetDict("pledgeType");
  const filteredSelectPledge = (selectPledge||[]).filter(({value}) => value === "654391");

  const propertyForm: IFormField[] = [
    {
      fieldItems: {
        type: "select",
        name: "objectType",
        returnFullValue: true,
        isRequired: true,
        placeholder: "Тип объекта недвижимости",
        isLoading: loadingPledge,
        items: filteredSelectPledge,
        defaultValue: filteredSelectPledge[0],
        errorMessage: "Выберите тип объекта недвижимости"
      }
    },
    {
      fieldItems: {
        type: "input",
        name: "objectTypeBuildYear",
        isRequired: true,
        mask: "9999",
        validationType: "minMax",
        // minValue: 1960,
        maxValue: Number(moment().format("YYYY")) + 1,
        // validationType: "minMax",
        // minValue: 1960,
        // maxValue: Number(moment().format("YYYY")) + 1,
        placeholder: "Примерный год постройки / год приобретения",
        errorMessage: "Введите год"
      }
    },
  ]

  console.log(moment().format("YYYY"))
  const titleForm: IFormField[] = [
    {
      label: "Сколько лет собственники владеют объектом?",
      fieldItems: {type: "radio", name: "ownershipYears", defaultValue: radioTitle[1], items: radioTitle}
    }
  ];
  switch (riskType) {
    case "life":
      return (
        <>
          <Heading4>Жизнь</Heading4>
          <HousingInProgress/>
        </>)
    case "property":
      return (
        <>
          <Heading4>Имущество</Heading4>
          <FieldsBuilder formFields={propertyForm}/>
        </>)
    case "title":
      return (
        <>
          <Heading4>Титул</Heading4>
          <FieldsBuilder formFields={titleForm}/>
        </>)
  }
};

export const PersonFields = ({number}: { number: number }) => {
  const form: IFormField[] = [
    {
      fieldItems: {
        type: "date",
        name: `birthDate-${number}`,
        isRequired: true,
        placeholder: number === 0 ? "Дата рождения заемщика" : "Дата рождения созаемщика",
        errorMessage: "Выберите дату рождения",
        defaultValue: mode === "dev" ? "07.07.1998" : undefined
      }
    },
    {
      label: `Пол ${number === 0 ? "заемщика" : "созаемщика"}`,
      fieldItems: {
        type: "radio",
        name: `sex-${number}`,
        defaultValue: radioSex[0],
        items: radioSex
      }
    },
    {
      fieldItems: {
        type: "range",
        name: `share-${number}`,
        isRequired: true,
        placeholder: `Доля ${number === 0 ? "заемщика" : "созаемщика"} в общем доходе, %`,
        defaultValue: number === 0 ? 100 : 0,
        startValue: 0,
        endValue: 100,
        errorMessage: "Выберите долю заемщика"
      }
    },
    // {
    //   label: "Тип занятости",
    //   fieldItems: {
    //     type: "radio",
    //     name: `isField-${number}`,
    //     defaultValue: radioJobType[1],
    //     items: radioJobType
    //   }
    // },
    {
      label: "Наличие хронических заболеваний",
      fieldItems: {
        type: "radio",
        name: `isChronical-${number}`,
        defaultValue: radioBoolean[1],
        items: radioBoolean
      }
    },
  ];

  return <FieldsBuilder formFields={form}/>
};

const HousingReady = ({stateRisks, setRisks}: { stateRisks: string[], setRisks: (risks: string[]) => void }) => {
  const {data: checkboxRisks} = useGetDict("risks");

  const changeRisksHandler = (value: boolean, risk: string) => {
    if (!value && risk === "riskProperty") return setRisks(stateRisks.filter(stateRisk => !["riskProperty", "riskTitle"].includes(stateRisk)));
    if (!value) return setRisks(stateRisks.filter(stateRisk => stateRisk !== risk));
    return setRisks([...stateRisks, risk])
  }

  const form: IFormField[] = [
    {
      label: "Риски",
      columns: 3,
      fieldItems: checkboxRisks.map(({value: name, label}) => ({
        name,
        type: "checkbox",
        label,
        defaultValue: stateRisks.includes(name),
        isDisabled: name === "riskTitle" && !stateRisks.includes("riskProperty"),
        onChange: (value) => changeRisksHandler(value, name)
      }))
    },
  ];

  return (
    <>
      <FieldsBuilder formFields={form}/>
      <StyledRisksFormWrapper>
        {stateRisks.includes("riskLife") && <Risks riskType="life"/>}
        {stateRisks.includes("riskProperty") && <Risks riskType="property"/>}
        {stateRisks.includes("riskTitle") && <Risks riskType="title"/>}
      </StyledRisksFormWrapper>
    </>
  )
};

const HousingInProgress = () => {
  const [stateIsGetBorrower, setIsGetBorrower] = React.useState<IRadioItem>(radioBoolean[1]);

  const form: IFormField[] = [
    {
      label: "Есть ли созаемщики по кредиту?",
      fieldItems: {
        type: "radio",
        name: "haveCoBorrower",
        defaultValue: radioBoolean[1],
        items: radioBoolean,
        onChange: (item: IRadioItem) => {
          setIsGetBorrower(item)
        }
      }
    },
  ];

  return (
    <>
      <PersonFields number={0}/>
      <FieldsBuilder formFields={form}/>
      {stateIsGetBorrower.value && <PersonFields number={1}/>}
    </>
  )
};


export const PrecalculationForm = ({loadResultHandler, loading}: { loadResultHandler: (formData: any) => void, loading: boolean }) => {
    const {data: selectBanks, loading: loadingBanks} = useGetDict("banks");

    const {data: dataAgents, loading: loadingAgents} = useQuery(GET_AGREEMENT_AGENTS);
    const selectAgents = ((dataAgents||{}).getAgent||[]).map(({SUBJISN, AGENTNAME}) => ({label: AGENTNAME, value: SUBJISN}));

    const {data: dataUser, error: errorUser} = useQuery(ME);
    const {isAdmin, type, role, group} = (dataUser || {}).me || {};
    const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);

    const canChangeAgent = rolesRightHandler("precalcChangeAgent", functions, type, isAdmin);

    const {setNotification} = useCallNotification();

    const tabs: ITabsItem[] = [
      {label: "Строящееся жилье", value: "2"},
      {label: "Готовое жилье", value: "1"},
    ];

    const [stateTabs, setTabs] = React.useState<ITabsItem>(tabs[0]);
    const [stateRisks, setRisks] = React.useState<string[]>([]);

    const form: IFormField[] = [
      // {
      //   label: "Вид договора",
      //   fieldItems: {
      //     type: "radio",
      //     columns: 2,
      //     name: "insureType",
      //     isLoading: loadingAgreementTypes,
      //     defaultValue: selectAgreementTypes[0],
      //     items: selectAgreementTypes,
      //     isRequired: true,
      //     errorMessage: "Выберите вид договора",
      //     returnFullValue: true
      //   }
      // },
      {
        fieldItems: {
          placeholder: "Населенный пункт сделки",
          type: "address",
          name: "dealCountry",
          isRequired: true,
        }
      },
      {
        fieldItems: {
          type: "number",
          name: "summ",
          digitsAfterDot: 2,
          //
          defaultValue: "5000000",
          //
          placeholder: "Размер кредита",
          validationType: "minMax",
          maxValue: 1000000000,
          isRequired: true,
          errorMessage: "Введите размер кредита"
        }
      },
      {
        fieldItems: {
          type: "enums",
          name: "banks",
          isLoading: loadingBanks,
          isRequired: true,
          errorMessage: "Выберите кредитную организацию",
          items: selectBanks,
          placeholder: "Наименование кредитной организации"
        }
      },
      {
        fieldItems: {
          type: "number",
          name: "rate",
          digitsAfterDot: 2,
          placeholder: "Процентная ставка",
          validationType: "minMax",
          minValue: 0,
          maxValue: 100,
        }
      },
      {
        fieldItems: {
          type: "date",
          name: "creditIssueDate",
          placeholder: "Дата кредитного договора",
        }
      },
      {
        isHidden: !canChangeAgent,
        fieldItems: {
          name: "agentIsn",
          type: "select",
          items: selectAgents,
          errorMessage: "Выберите агента",
          placeholder: "Агент",
          isSearchAvailable: true,
          isClearable: true,
        }
      },
    ];

    const onFormSubmit = (data) => {
      const error = getFormError(data);
      if (error) return error;
      const formData = getFormValueWithArray(getFormValue(data), ["share", "birthDate", "sex", "isChronical"], "borrowers")
      const {riskLife, riskTitle, riskProperty} = formData;
      const risks = stateTabs.value === "2" ?
        {
          riskLife: true,
          riskTitle: false,
          riskProperty: false
        }
        :
        {
          riskLife,
          riskTitle,
          riskProperty
        }

      const dealCountry: any = formData?.dealCountry;
      if( !!dealCountry){
        if (dealCountry?.data?.city){
          if(isMoRegion(dealCountry) && group?.is_filial || isNewRegion(dealCountry)){
            setNotification({type: "error", text: "Расчет по выбранным параметрам невозможен. Обратитесь к куратору!"});
            return;
          }
          formData.dealCountry = dealCountry?.data?.city_fias_id;
        } else {
          return {dealCountry: "Выберите вплоть до города"}
        }
      }


      loadResultHandler({
        ...formData,
        insureType: {value: '1', label: 'Новый кредит'},
        ...risks,
        property: stateTabs.value,
      });
    }

    return (
      <StyledPrecalculationFormCard>
        <Heading2>Создание предварительной заявки на расчет</Heading2>
        <Form onSubmit={(data) => onFormSubmit(data)}>
          {({formProps}) => (
            <form {...formProps}>
              <StyledPrecalculationForm>
                <FieldsBuilder formFields={form}/>
                <StyledFormField>
                  <Tabs
                    onChange={(tab) => setTabs(tab)}
                    tabWidth={15}
                    tabs={tabs}
                    activeTab={tabs.find(tab => tab.value === stateTabs.value)}/>
                </StyledFormField>
                {stateTabs.value === "2" && <HousingInProgress/>}
                {stateTabs.value === "1" && <HousingReady stateRisks={stateRisks} setRisks={setRisks}/>}
              </StyledPrecalculationForm>
              <Button isDisabled={stateTabs.value === "1" && stateRisks.length === 0} isLoading={loading} type="submit"
                      appearance="filled" width="100%">Рассчитать</Button>
            </form>
          )}
        </Form>
      </StyledPrecalculationFormCard>
    )
  }
;

export default PrecalculationForm;