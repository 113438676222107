import React from 'react';

export const AnketsIcon = () => (
  <svg width="6rem" height="5.4rem" viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M34 19H50" stroke="#003D81" strokeWidth="3"/>
    <path d="M34 27H50" stroke="#003D81" strokeWidth="3"/>
    <path d="M34 35H43" stroke="#003D81" strokeWidth="3"/>
    <path d="M19 30C22.866 30 26 26.866 26 23C26 19.134 22.866 16 19 16C15.134 16 12 19.134 12 23C12 26.866 15.134 30 19 30Z" stroke="#41B6E6" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.5 37C11.5 36.452 11.7448 35.824 12.2889 35.1188C12.8291 34.4186 13.5815 33.7521 14.382 33.1623L12.6024 30.7471C11.6834 31.4243 10.6896 32.2805 9.91368 33.2862C9.14157 34.2869 8.5 35.548 8.5 37H11.5ZM14.382 33.1623C15.8387 32.0889 17.6389 31.5001 19.4998 31.5L19.4997 28.5C17.0109 28.5001 14.5847 29.2864 12.6024 30.7471L14.382 33.1623ZM19.4998 31.5C21.3607 31.4999 23.1609 32.0886 24.6177 33.1619L26.3972 30.7467C24.4148 29.2861 21.9885 28.4999 19.4997 28.5L19.4998 31.5ZM24.6177 33.1619C25.4182 33.7517 26.1706 34.4182 26.711 35.1185C27.2553 35.8239 27.5 36.4519 27.5 36.9994H30.5C30.5 35.5475 29.8583 34.2864 29.0861 33.2858C28.3101 32.2802 27.3163 31.4238 26.3972 30.7467L24.6177 33.1619Z" fill="#41B6E6"/>
    <path d="M55.6667 2H4.33333H2V4.5V49.5V52H4.33333H55.6667H58V49.5V4.5V2H55.6667Z" stroke="#41B6E6" strokeWidth="3"/>
  </svg>

);
