import React                                                              from "react";
import {useHistory, useParams}                                            from "react-router";
import {StyledWork, StyledWorkcontent, StyledWorkPage, StyledWorkWrapper} from "./styles";
import Button                                                             from "../../uiKit/button/button";
import {Heading2}                                                         from "../../globalStyledComponents";

export const PageWork = () => {
  const history = useHistory();
  const {type} = useParams();
  const isRightError: boolean = type === "rights";

  const onMouseMove = (ev) => {
    const contentElement = document.getElementById("pageWorkContent");
    if (!contentElement) return;
    const x: number = (window.innerWidth / 2 - ev.pageX) / 10;
    const y: number = (window.innerHeight / 2 - ev.pageY) / 10;
    contentElement.style.transform = `rotateY(${x}deg) rotateX(${-y}deg)`;
  }
  const onMouseEnter = () => {
    const contentElement = document.getElementById("pageWorkContent");
    const titleElement = document.getElementById("titleWork");
    const textElement = document.getElementById("textWork");
    const buttonElement = document.getElementById("backButtonWork");
    if (!contentElement || !titleElement || !textElement || !buttonElement) return;

    // content
    contentElement.style.willChange = "transition, transform";
    contentElement.style.transition = "unset";

    // title
    titleElement.style.willChange = "transition, transform";
    titleElement.style.transition = "all 0.5s";
    titleElement.style.transform = "translateZ(110px)"

    // Work
    textElement.style.willChange = "transition, transform";
    textElement.style.transition = "all 0.5s";
    setTimeout(() => {
      textElement.style.transform = "translateZ(150px)"
    }, 300)

    // button
    buttonElement.style.willChange = "transition, transform";
    buttonElement.style.transition = "all 0.5s";
    buttonElement.style.transform = "translateZ(110px)"

  }
  const onMouseLeave = () => {
    const contentElement = document.getElementById("pageWorkContent");
    const titleElement = document.getElementById("titleWork");
    const textElement = document.getElementById("textWork");
    const buttonElement = document.getElementById("backButtonWork");
    if (!contentElement || !titleElement || !textElement || !buttonElement) return;

    // content
    contentElement.style.transition = "all 0.7s";
    contentElement.style.transform = "rotateY(0deg) rotateX(0deg)";

    // title
    titleElement.style.transform = "translateZ(0px)"

    // Work
    setTimeout(() => {
      textElement.style.transform = "translateZ(0px)"
    }, 300)

    // button
    buttonElement.style.transform = "translateZ(0px)"

  }

  React.useEffect(() => {
    const pageElement = document.getElementById("pageWorkWrapper");
    if (!pageElement) return;
    pageElement.addEventListener("mousemove", (ev) => onMouseMove(ev))
    pageElement.addEventListener("mouseenter", () => onMouseEnter())
    pageElement.addEventListener("mouseleave", () => onMouseLeave())
  }, [])

  return (
    <StyledWorkPage>
      <StyledWorkWrapper id={"pageWorkWrapper"}>
        <StyledWorkcontent id={"pageWorkContent"}>
          <Heading2 id={"titleWork"}>Проводятся технические работы</Heading2>
          <StyledWork id={"textWork"}>
            Работа платформы скоро будет восстановлена
          </StyledWork>
          <Button id={"backButtonWork"} appearance="transparent" onClick={() => history.go(isRightError ? -2 : -1)}>Обновить страницу</Button>
        </StyledWorkcontent>
      </StyledWorkWrapper>
    </StyledWorkPage>
  )
};

export default PageWork;