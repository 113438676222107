import styled                            from "styled-components";
import {blue, lightBlue}                 from "../../globalStyledComponents";

export const StyledToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  > p {
    margin-left: 1rem;
  }
`;

export const StyledCheckboxWrapper = styled.label<{ isChecked: boolean }>`
  cursor: pointer;
  display: block;
  

  min-height: 2rem;
  max-height: 2rem;
  
  min-width: 4.2rem;
  max-width: 4.2rem;

  box-sizing: border-box;
  border: 1px solid ${blue};
  border-radius: 3.65rem;
  position: relative;

  background: ${props => (props.isChecked ? lightBlue : "transparent")};
  transition: all 0.3s ease-in-out;
`;

export const StyledCheckbox = styled.input`
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  border: 0;

  clip: rect(0 0 0 0);
  &:checked + * {
    left: calc(100% - 1px);
    transform: translateY(-50%) translateX(-100%);
  }
`;

export const StyledCircle = styled.span`
  display: block;
  box-sizing: border-box;
  width: 1.4rem;
  height: 1.4rem;
  border: 1px solid ${blue};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background: ${blue};
  transition: all 0.3s ease-in-out;
`;
