import React               from 'react';
import {StyledFormMessage} from "./styles";
import {TextCaption}       from "../../globalStyledComponents";
import {idGenerator}       from "../../helpers/idGenerator";

export const FormMessage = ({message, type}: { message: string | undefined, type: "error" | "success" }) => {
  const messageId = idGenerator();
  React.useEffect(() => {
    const el: HTMLInputElement | null = document.querySelector(`[data-scroll="${messageId}"]`);
    if (!el) return;
    const rect = el.getBoundingClientRect();

    if (rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)) return;

    window.scrollTo({
      left: 0,
      top: rect.top + window.pageYOffset - 150,
      behavior: "smooth"
    });
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {!!message &&
      <StyledFormMessage data-scroll={messageId} type={type}>
        <TextCaption>
          {message}
        </TextCaption>
      </StyledFormMessage>}
    </>
  )
};

export default FormMessage;