import React                      from 'react';
import {Heading3, TextButton}     from "../../../../globalStyledComponents";
import {
  StyledHeaderInformation,
  StyledInformationItem
}                                 from "../../../applicationById/components/header/styles";
import {useParams}                from "react-router";
import LoadingString              from "../../../../uiKit/loadingString";
import {StyledMyAgreementWrapper} from "../../styles";
import {useQuery}                 from "@apollo/react-hooks";
import {GET_CONTRACT}             from "../../../../utils/queries";
import {ErrorMessage}             from "../../../../components/errorMessage";

export const MyAgreementByIdHeader = () => {
  const {id} = useParams();
  const {data: dataContract, loading: loadingContract, error: errorContract} = useQuery(GET_CONTRACT, {
    variables: {
      isn: id
    }
  });
  const {id: contractId, productName, dateBeg, dateEnd} = (dataContract || {}).agreement || {};

  const informationList = [
    {title: "№ договора", description: <LoadingString isLoading={loadingContract}>{contractId}</LoadingString>},
    {title: "Тип", description: <LoadingString isLoading={loadingContract}>{productName}</LoadingString>},
    {title: "Дата создания", description: <LoadingString isLoading={loadingContract}>{dateBeg}</LoadingString>},
    {title: "Дата окончания", description: <LoadingString isLoading={loadingContract}>{dateEnd}</LoadingString>},
  ]

  if (errorContract) return <ErrorMessage error={errorContract}/>
  return (
    <StyledMyAgreementWrapper>
      <Heading3>Общие сведения</Heading3>
      <StyledHeaderInformation>
        {informationList.map(({title, description}, index) => (
          <StyledInformationItem key={`info-item-${index}`}>
            <TextButton>{title}</TextButton>
            <TextButton>{description}</TextButton>
          </StyledInformationItem>
        ))}
      </StyledHeaderInformation>
    </StyledMyAgreementWrapper>
  )
};

export default MyAgreementByIdHeader;