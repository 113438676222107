import React                                                                                                             from 'react';
import {Heading3, TextButton}                                                                                            from "../../../../globalStyledComponents";
import {useParams}                                                                                                       from "react-router";
import {StyledMyAgreementWrapper}                                                                                        from "../../styles";
import {useMutation, useQuery}               from "@apollo/react-hooks";
import {GET_AGREEMENT_PAYMENT, GET_CONTRACT} from "../../../../utils/queries";
import {ErrorMessage}                        from "../../../../components/errorMessage";
import {Loader}                                                                                                          from "../../../../uiKit/loader";
import {StyledPayButtonWrapper, StyledPaymentRowWrapper, StyledPaymentsHeader, StyledPaymentsRow, StyledPaymentsWrapper} from "./styles";
import Button                                                                                                            from "../../../../uiKit/button/button";
import Status                                                                                                            from "../../../../uiKit/status";
import {money}                                                                                                           from "../../../../utils/formats";
import InvoiceModal                                                                                                      from "../../../createInvoice/components/InvoiceModal";
import {CREATE_INVOICE}                                                                                                  from "../../../../utils/mutations";
import {useCallNotification}                                                                                             from "../../../../hooks/useCallNotification";

const heads = ["Сумма", "Дата оплаты", "Статус"];

export const MyAgreementPayments = () => {
  const {id} = useParams();
  const {setNotification} = useCallNotification();
  const [stateModalIsOpen, setModalIsOpen] = React.useState<any>(undefined)

  const {data: dataContract, loading: loadingContract, error: errorContract} = useQuery(GET_CONTRACT, {
    variables: {
      isn: id
    }
  });
  const {data: dataAgreementPayment, loading: loadingAgreementPayment, error: errorAgreementPayment} = useQuery(GET_AGREEMENT_PAYMENT, {
    variables: {
      agreementIsn: id
    }
  });
  const [createInvoice, {
    loading: loadingCreateInvoice
  }] = useMutation(CREATE_INVOICE, {fetchPolicy: "no-cache"});

  const {id: contractId} = (dataContract || {}).agreement || {};
  const {userGetPaymentQuery} = dataAgreementPayment || {};
  const payments: any[] = Array.isArray(userGetPaymentQuery) ? userGetPaymentQuery : !!userGetPaymentQuery ? [userGetPaymentQuery] : [];
  const rows = payments.map(({ROWNUM, PAYMENT_SCHEDULE, PAYMENT_DATE, PAYMENT_RUB_ON_CURRENT_DATE}) => ({summ: money(PAYMENT_SCHEDULE), date: PAYMENT_DATE, status: PAYMENT_RUB_ON_CURRENT_DATE === "0" ? "payed" : "notPay", number: ROWNUM}))
  const firstNotPayed: string | undefined = rows?.find(({status}) => status === "notPay")?.number;

  const createInvoiceHandler = (id, contractId, summForPay) => {
    createInvoice({
      variables: {
        argIsn: id
      }
    })
      .then(({data: {createInvoice: isnInvoice}}) => setModalIsOpen({isnInvoice, summForPay, contractId}))
      .catch(error => setNotification({type: "error", text: error}))
  }

  if (errorAgreementPayment) return <ErrorMessage error={errorAgreementPayment}/>
  if (errorContract) return <ErrorMessage error={errorContract}/>
  if (loadingAgreementPayment || loadingContract) return <Loader type="simple"/>

  return (
    <StyledMyAgreementWrapper>
      <Heading3>График платежей</Heading3>
      <StyledPaymentsWrapper>
        <StyledPaymentsHeader>
          {heads.map((head, index) => <span key={`head-${index}`}>{head}</span>)}
        </StyledPaymentsHeader>

        {rows.map(({date, summ, status, number}, index) =>
          <StyledPaymentRowWrapper key={`head-${index}`}>
            <StyledPaymentsRow>
              <TextButton>{summ}</TextButton>
              <TextButton>{date}</TextButton>
              <Status status={status} hideIcon={true}/>
            </StyledPaymentsRow>
            {number === firstNotPayed &&
            <StyledPayButtonWrapper>
                <Button
                    appearance="filled"
                    onClick={() => createInvoiceHandler(id, contractId, summ)}
                    isLoading={loadingCreateInvoice}>
                    Оплатить
                </Button>
            </StyledPayButtonWrapper>}
          </StyledPaymentRowWrapper>
        )}
      </StyledPaymentsWrapper>
      <InvoiceModal header="Оплата очередного взноса по договору" data={stateModalIsOpen}
                    onClose={() => setModalIsOpen(undefined)} isOpen={!!stateModalIsOpen}/>
    </StyledMyAgreementWrapper>
  )
};

export default MyAgreementPayments;