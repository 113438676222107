import React from 'react';

export const QuestionIcon = () => (
  <svg width="2.2rem" height="2.2rem" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" stroke="currentColor" strokeWidth="1.5"/>
    <path
      d="M9.92008 14.4173H11.7868V16.25H9.92008V14.4173ZM11.4228 5.29025C9.50007 5.01534 7.80133 6.17913 7.28798 7.84692C7.11997 8.37841 7.53066 8.91907 8.10001 8.91907H8.28669C8.66937 8.91907 8.97738 8.65332 9.10805 8.3051C9.40673 7.48953 10.2934 6.93055 11.2548 7.13215C12.1415 7.31542 12.7949 8.16764 12.7202 9.05652C12.6269 10.2845 11.2081 10.5502 10.4334 11.6957C10.4334 11.7048 10.4241 11.7048 10.4241 11.714C10.4148 11.7323 10.4054 11.7415 10.3961 11.7598C10.3121 11.8973 10.2281 12.053 10.1628 12.218C10.1534 12.2455 10.1348 12.2638 10.1254 12.2913C10.1161 12.3096 10.1161 12.328 10.1068 12.3554C9.99475 12.667 9.92008 13.0427 9.92008 13.5009H11.7868C11.7868 13.116 11.8895 12.7953 12.0482 12.5204C12.0668 12.4929 12.0762 12.4654 12.0948 12.4379C12.1695 12.3096 12.2628 12.1905 12.3562 12.0805C12.3655 12.0714 12.3748 12.053 12.3842 12.0439C12.4775 11.9339 12.5802 11.8331 12.6922 11.7323C13.5882 10.8984 14.8016 10.2203 14.5496 8.47005C14.3256 6.87557 13.0469 5.52851 11.4228 5.29025Z"
      fill="currentColor" stroke="white" strokeWidth="0.3"/>
  </svg>
);
