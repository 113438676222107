import React                                                                                                                                                                                        from 'react';
import Modal, {ModalProps}                                                                                                                                                                          from "../../../../uiKit/modal";
import {StyledButtonsGroup}                                                                                                                                                                         from "../../../roles/components/header/styles";
import Button                                                                                                                                                                                       from '../../../../uiKit/button/button';
import {IFormField}                                                                                                                                                                                 from "../../../../uiKit/fieldsBuilder/interfaces";
import FieldsBuilder                                                                                                                                                                                from "../../../../uiKit/fieldsBuilder";
import Form                                                                                                                                                                                         from '@atlaskit/form';
import {getFormError}                                                                                                                                                                               from "../../../../formUtils/getFormError";
import {useMutation}                                                                                                                                                                                from "@apollo/react-hooks";
import {DISAPPROVE_RISKS}                                                                                                                               from "../../../../utils/mutations";
import {useCallNotification}                                                                                                                                                                        from "../../../../hooks/useCallNotification";
import {getFormValue}                                                                                                                                                               from "../../../../formUtils/getFormValue";
import {getRiskForObject} from "../../../../helpers/buildTemplateForBack";
import {useParams}          from "react-router";
import {Heading3} from "../../../../globalStyledComponents";
import { StyledDisapproveWrapper } from './styles';

export const DisapproveModal = ({onClose, header, isOpen, data}: ModalProps) => {
  const {id} = useParams();
  const {setNotification} = useCallNotification();
  const [disapprove, {loading: loadingDisapprove}] = useMutation(DISAPPROVE_RISKS, {refetchQueries: ["docs"]});

  const {objects, risks} = data || {};

  const form: IFormField[] = (objects || []).map((object) => {
    const {ISN, SubClassName} = object || {};
    const {risk, object: isnObject, status} = (risks || []).find(({object}) => ISN === object) || {};

    return {
      isHidden: status === "underwriting",
      fieldItems: {
        label: `Риск ${getRiskForObject(risk)} (${SubClassName})`,
        name: `risk-${isnObject}`,
        type: "checkbox"
      }
    }
  })

  const isHaveRisks: boolean = !!form.filter(({isHidden}) => !isHidden).length;

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const formData = getFormValue(data);

    const selectedRisks = Object.entries(formData).reduce<string[]>((prev, [key, value]) => {
      if (!value) return prev;
      return [...prev, key.split("-")[1]];
    }, [])

    if (!selectedRisks.length) return setNotification({type: "error", text: "Риски не выбраны"});

    const risksToUpdate = risks.map(risk => {
      const {__typename, ...other} = risk;
      if (!selectedRisks.includes(risk.object)) return other;
      return {...other, status: "underwriting"}
    });

    disapprove({variables: {doc: id, risks: risksToUpdate}})
      .then(() => {
        setNotification({type: "success", text: "Риски отправлены на согласование"});
        onClose();
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <StyledDisapproveWrapper>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            {!isHaveRisks && <Heading3>Нет подходящих рисков</Heading3>}
            {isHaveRisks && <>
                <Heading3>Выберите риски для согласования</Heading3>
                <FieldsBuilder formFields={form}/>
            </>}
            <StyledButtonsGroup>
              <Button
                type="submit"
                appearance="filled"
                isDisabled={!isHaveRisks}
                isLoading={loadingDisapprove}>
                Отправить
              </Button>
              <Button
                onClick={() => onClose()}
                appearance="transparent"
                isLoading={loadingDisapprove}>
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
      </StyledDisapproveWrapper>
    </Modal>
  )
};

export default DisapproveModal;