import React                    from "react";
import {StyledStatisticsHeader} from "./styles";
import {Heading2}              from "../../../../globalStyledComponents";
import Button                  from "../../../../uiKit/button/button";
import {useHistory}            from "react-router";
import FlyButtons              from "../../../../components/flyButtons";
import {AgreementsLogType}     from "../../index";
import {useQuery}              from "@apollo/react-hooks";
import {ME}                    from "../../../../utils/queries";
import {rolesRightHandler}     from "../../../../helpers/rolesRightHandler";
import {ErrorMessage}          from "../../../../components/errorMessage";
import {useWindowWidth}        from "@react-hook/window-size";
import DropDown                from "../../../../uiKit/dropDownArea";
import SkipPrecalculationModal from "../../../createRequest/components/skipPrecalculationModal";
import ContinueContractModal   from "../ContinueContractModal";


export const AgreementsLogHeader = ({type}:{type: AgreementsLogType}) => {
  const history = useHistory();
  const windowWidth = useWindowWidth({wait: 250});
  const [stateDropDownIsOpen, setDropDownIsOpen] = React.useState<boolean>(false);
  const [stateSkipPrecalculationModal, setSkipPrecalculationModal] = React.useState<boolean>(false);
  const [stateContinueContractModal, setContinueContractModal] = React.useState<boolean>(false);

  const {data: dataUser, error: errorUser} = useQuery(ME);
  const {isAdmin, type: userType, role, banksOk} = (dataUser || {}).me || {};
  const functions: string[] = ((role || {}).functions || []).map(({tag}) => tag);

  const headerButtons: { label: string, event: () => void, isDisabled?: boolean }[] = [
    {
      label: "С предварительным расчетом",
      event: () => history.push("/createRequest")
    },
    {
      label: "Без предварительного расчета",
      event: () => {
        setDropDownIsOpen(false)
        setSkipPrecalculationModal(true)
      }
    },
  ];

  const closeContinueContractModal = () => setContinueContractModal(false);

  if (errorUser) return <ErrorMessage error={errorUser}/>
  switch (type) {
    case "C":
      return (
        <StyledStatisticsHeader>
          <Heading2>Журнал заявок</Heading2>
          <FlyButtons>
            <DropDown
              trigger={
                <Button
                  isDisabled={!rolesRightHandler("precalculationPage", functions, userType, isAdmin) || !banksOk}
                  appearance="filled"
                  onClick={() => setDropDownIsOpen(true)}
                >
                  Создать заявку
                </Button>
              }
              isDropDownList={true}
              position={windowWidth < 768 ? "top" : undefined}
              bodyWidth={windowWidth < 768 ? "100%" : undefined}
              items={headerButtons.map(({label, event}) => ({label, event}))}
              isOpen={stateDropDownIsOpen}
              onClose={() => setDropDownIsOpen(false)}/>
          </FlyButtons>
          <SkipPrecalculationModal header="Переход к заявке" onClose={() => setSkipPrecalculationModal(false)}
                                   isOpen={stateSkipPrecalculationModal}/>
        </StyledStatisticsHeader>
      )
    case "A":
      return (
        <StyledStatisticsHeader>
          <Heading2>Журнал договоров</Heading2>
        </StyledStatisticsHeader>
      )
    case "P":
      return (
        <StyledStatisticsHeader>
          <Heading2>Журнал пролонгаций</Heading2>
          <FlyButtons>
            <Button
              appearance="filled"
              onClick={() => setContinueContractModal(true)}
              isDisabled={!banksOk}
            >
              Продлить договор
            </Button>
          </FlyButtons>
          <ContinueContractModal header="Продлить договор" onClose={closeContinueContractModal}
                                   isOpen={stateContinueContractModal}/>
        </StyledStatisticsHeader>
      )
    default:
      return null
  }
};

export default AgreementsLogHeader;