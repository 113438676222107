import styled from "styled-components";
import {grey} from "../../../../globalStyledComponents";

export const StyledHeaderWrapper = styled.div`
padding: 4rem;
  width: calc(100% - 8rem);
    @media screen and (max-width: 767px) {
 padding: 1.5rem;
   width: calc(100% - 3rem);
  }
  
  border-bottom: 1px solid ${grey};
`;
export const StyledHeaderEvents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledHeaderInformation = styled.div`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 0.9fr 0.9fr 0.9fr 0.9fr 1.4fr;
  grid-row-gap: 2rem;
  grid-column-gap: 1.5rem;
    @media screen and (max-width: 767px) {
    margin-top: 1.5rem;
   grid-template-columns: 1fr;
  }
`;
export const StyledInformationItem = styled.div`
  display: flex;
  flex-direction: column;
  > p:first-child {
    opacity: 0.5;
    margin-bottom: 1rem;  
  }
`;

export const StyledStatusItem = styled.div`
  display: flex;
  flex-direction: column;
  > p:first-child {
    opacity: 0.5;
    margin-bottom: 0.5rem;  
  }
  margin-bottom: 1rem;
  :last-child {
    margin-bottom: 0;
  }
`;

export const StyledEventsButtons = styled.div`
display: flex;
align-items: center;
.iconButton {
  margin-right: 2.5rem;
}
.linkButton {
  margin-right: 2.5rem;
}
`;

export const StyledAgreementNumber = styled.div`
  display: flex;
  flex-direction: column;
  .linkButton {
  margin-top: 1rem;
}
`;
export const StyledSummsWrapper = styled.div`
  
`;
export const StyledSumm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  > p:first-child {
    opacity: 0.5;
  }
  p {
    text-align: right;
  }
  margin-bottom: 1rem;

  @media screen and (max-width: 767px) {
    p {
      text-align: unset;
    }
  }
`;

export const StyledHistoryWrapper = styled.div`
  max-height: 70vh;
  min-height: 30rem;
  overflow-y: auto;
`;
export const StyledDisapproveWrapper = styled.div`
  h3 {
    margin-bottom: 2rem;
  }
`;