import React                                        from 'react';
import {StyledStatus}                               from "./styles";
import {green, grey, lightBlue, red, Text3, yellow} from "../../globalStyledComponents";
import {StatusActionIcon}                           from "../../icons/ui/StatusAction";

const statuses: {id: string, label: string, color: string}[] = [
  {
    id: "created",
    label: "Создана",
    color: grey
  },

  {
    id: "waitingForClient",
    label: "Ожидание клиента",
    color: yellow
  },

  {
    id: "waitingForUnderwriting",
    label: "Согласование",
    color: lightBlue
  },

  {
    id: "agreed",
    label: "Согласовано",
    color: green
  }, // когда согласованы все риски

  {
    id: "partiallyAgreed",
    label: "Частично согласована",
    color: yellow
  }, // когда согласованы не все риски

  {
    id: "canceled",
    label: "Отклонено",
    color: red
  }, // когда отклонены все риски

  {
    id: "inProgress",
    label: "Оформление",
    color: lightBlue
  },

  {
    id: "signed",
    label: "Подписан",
    color: green
  },
  
  {
    id: "underwriting",
    label: "Согласование",
    color: lightBlue
  },  
  {
    id: "approved",
    label: "Согласована",
    color: green
  },  
  {
    id: "deny",
    label: "Не согласован",
    color: red
  },
  {
    id: "notPay",
    label: "Не оплачен",
    color: red
  },
  {
    id: "payed",
    label: "Оплачен",
    color: green
  },
  {
    id: "notFilled",
    label: "Не заполнено",
    color: red
  },
  {
    id: "filledOnline",
    label: "Заполнено онлайн",
    color: lightBlue
  },
  {
    id: "filledUpload",
    label: "Загружены документы",
    color: lightBlue
  },
  {
    id: "filledSend",
    label: "Будет отправлено клиенту",
    color: lightBlue
  },
  {
    id: "filledDeclaration",
    label: "Декларация",
    color: lightBlue
  },
  {
    id: "filled",
    label: "Заполнено",
    color: lightBlue
  },

  {
    id: "clientRefusal",
    label: "Отказ клиента",
    color: red
  },
  {
    id: "annul",
    label: "Аннулирована",
    color: red
  },

  {
    id: "М",
    label: "Макет",
    color: lightBlue
  },
  {
    id: "С",
    label: "Оформление",
    color: lightBlue
  },
  {
    id: "П",
    label: "Подписан",
    color: green
  },
  {
    id: "В",
    label: "Выпущен",
    color: green
  },
  {
    id: "А",
    label: "Аннулирован",
    color: red
  },
  {
    id: "Р",
    label: "Зарезервирован",
    color: lightBlue
  },
  {
    id: "Д",
    label: "Прекращен страхователем",
    color: red
  },
  {
    id: "Щ",
    label: "Прекращен страховщиком",
    color: red
  },
  {
    id: "Л",
    label: "Изменен по доп. согл.",
    color: lightBlue
  },
]

export const Status = ({status, hideIcon, iconAfter}: { status: string, hideIcon?: boolean, iconAfter?: any }) => {
  const {label, color} = statuses.find(({id}) => id === status) || {}
  return (
    <StyledStatus color={color || grey}>
      {!hideIcon && <StatusActionIcon/>}
      <Text3>{label || "unknown"}</Text3>
      {iconAfter}
    </StyledStatus>
  )
};

export default Status;

export const getStatusName = (status: string ) => {
  const {label} = statuses.find(({id}) => id === status) || {};
  return label || "unknown"
};