import React                  from "react";
import Modal, {ModalProps}    from "../../../../uiKit/modal";
import FieldsBuilder          from "../../../../uiKit/fieldsBuilder";
import Form                   from '@atlaskit/form';
import {IFormField}           from "../../../../uiKit/fieldsBuilder/interfaces";
import {StyledButtonsGroup}   from "../../../roles/components/header/styles";
import Button         from "../../../../uiKit/button/button";
import {getFormError} from "../../../../formUtils/getFormError";
import {getFormValue} from "../../../../formUtils/getFormValue";
import {useMutation}  from "@apollo/react-hooks";
import {USER_CHANGE_PASSWORD} from "../../../../utils/mutations";
import {useCallNotification}  from "../../../../hooks/useCallNotification";

const {JSEncrypt} = require('jsencrypt');
const publicPasswordKey = process.env.REACT_APP_PASSWORD_KEY
const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicPasswordKey);

const form: IFormField[] = [
  {
    fieldItems:
      {
        type: "input",
        name: "password",
        inputType: "password",
        placeholder: "Старый пароль",
        isRequired: true,
        errorMessage: "Введите старый пароль",
        validationType: "password",
      }
  },
  {
    fieldItems: {
      type: "input",
      name: "passwordNew",
      inputType: "password",
      placeholder: "Новый пароль",
      isRequired: true,
      errorMessage: "Введите новый пароль",
      validationType: "password"
    }
  }
]

export const ChangePasswordModal = ({header, isOpen, onClose}: ModalProps) => {
  const {setNotification} = useCallNotification();
  const [changePassword, {loading: loadingChangePassword}] = useMutation(USER_CHANGE_PASSWORD);

  const onFormSubmit = (data) => {
    const error = getFormError(data);
    if (error) return error;
    const {password, passwordNew} = getFormValue(data);
    changePassword(({
      variables: {
        password: encrypt.encrypt(password),
        passwordNew: encrypt.encrypt(passwordNew)
      }
    }))
      .then(() => {
        onClose();
        setNotification({type: "success", text: "Пароль изменен"})
      })
      .catch(error => setNotification({type: "error", text: error}))
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} header={header}>
      <Form onSubmit={data => onFormSubmit(data)}>
        {({formProps}) => (
          <form {...formProps}>
            <FieldsBuilder formFields={form}/>
            <StyledButtonsGroup>
              <Button type="submit" appearance="filled" isLoading={loadingChangePassword}>
                Подтвердить
              </Button>
              <Button onClick={() => onClose()} appearance="transparent">
                Отмена
              </Button>
            </StyledButtonsGroup>
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;