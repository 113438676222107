import styled                                         from "styled-components";
import {black, blue, grey, grey20, red, red20, white} from "../../globalStyledComponents";

export const StyledToggleWrapper = styled.div`
  border-bottom: 1px solid ${grey};
  box-sizing: border-box;
  
  .toggleShow {
    display: block;
  } 
  .toggleHide {
    display: none;
  }
`;

export const StyledToggleHeader = styled.div<{isOpen: boolean}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: color 0.3s;
  background: ${white};
  :hover {
    color: ${blue};
  }
    ${({isOpen}) =>
  isOpen ?
    `
      padding: 4rem 4rem 1.5rem 4rem;
      
        
  @media screen and (max-width: 767px) {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
  }
 
      `
    :
    `
       padding: 4rem; 
             @media screen and (max-width: 767px) {
    padding: 3rem 1.5rem;
  }
      `
}
`;

export const StyledToggleBody = styled.div<{isLoading: boolean, padding?: number, isShow: boolean}>`
  ${({isShow}) => isShow ? 
  ""
  :
  `
  height: 0;
  overflow: hidden;
  opacity: 0;
  `
  }
  ${({padding}) => `padding: 0 ${typeof padding === "number" ? padding : "4"}rem;`}
  ${({isLoading}) =>
  isLoading ?
      `
      height: 10rem;
      background: ${grey20};
      display: flex;
      align-items: center;
      `
      :
      ""
  }
  
  @media screen and (max-width: 767px) {
    ${({padding}) => `padding: 0 ${typeof padding === "number" ? padding : "1.5"}rem;`}
  }
`;

export const StyledToggleNewItemsWrapper = styled.div`
 display: flex;
 align-items: center;
 width: 100%;
`;
export const StyledToggleNewItems = styled.div`
 color: ${red};
 background: ${red20};
 border-radius: 30rem;
 padding: 0.5rem 1rem;
 margin-left: 2rem;
`;

export const StyledToggleIcon = styled.div<{isOpen: boolean}>`
  width: 1.4rem;
  height: 2px;
  background: ${black};
  position: relative;
  pointer-events: none;
  
  ::before, ::after {
    content: "";
    position: absolute;
    background: ${black};
    transition: all 0.3s;
    width: 2px;
    height: 0.8rem;
    left: calc(0.7rem - 1px);
  }
  
  ::before {
    ${({isOpen}) =>
    isOpen ?
    `
    top: 0.7rem;
    opacity: 0;
    `
    :
    `
    top: 0;
    opacity: 1;
    `
    }
  }
  
  ::after {
${({isOpen}) =>
  isOpen ?
    `
    bottom: 0.7rem;
    opacity: 0;
    `
    :
    `
    bottom: 0;
    opacity: 1;
    `
}
  }
`;

export const StyledEmptyToggle = styled.div`
  h5 {
  margin: 1.5rem 0;
  }
`;
