import React                                              from 'react';
import {StyledRadio, StyledRadioItem, StyledRadioWrapper} from "./styles";
import {TextButton}                                       from "../../globalStyledComponents";
import FormMessage                                        from "../formMessage";

export interface IRadioItem {
  label: string
  value: string | number | boolean
}

interface RadioProps {
  value?: IRadioItem
  onChange?: (item: IRadioItem) => void
  errorMessage?: string
  items: IRadioItem[]
  columns?: number
  columnGap?: number
  isDisabled?: boolean
}

export const Radio = ({value, items, errorMessage, columnGap, isDisabled, columns, onChange}: RadioProps) => {
  const [stateRadioValue, setRadioValue] = React.useState<IRadioItem | undefined>(value || undefined);

  const radioChangeHandler = (item: IRadioItem) => {
    if (isDisabled) return;
    const {label, value} = item;
    if ((stateRadioValue || {}).value === value) return;
    return setRadioValue({label, value});
  };

  React.useEffect(() => {
    if (onChange && stateRadioValue) onChange(stateRadioValue)
    // eslint-disable-next-line
  }, [(stateRadioValue || {}).value])

  React.useEffect(() => {
    if (!value || (stateRadioValue || {}).value === (value || {}).value) return;
    setRadioValue(value);
    // eslint-disable-next-line
  }, [(value || {}).value])

  return (
    <>
      <StyledRadioWrapper isDisabled={!!isDisabled} columns={columns} columnGap={columnGap || 0}>
        {!!items.length && items.map(({label, value}, index) => (
          <StyledRadioItem isDisabled={!!isDisabled} isActive={(stateRadioValue || {}).value === value} key={`radio-item-${index}`}
                           onClick={() => radioChangeHandler({label, value})}>
            <StyledRadio isShow={(stateRadioValue || {}).value === value}/>
            <TextButton>{label}</TextButton>
          </StyledRadioItem>
        ))}
      </StyledRadioWrapper>
      {errorMessage && <FormMessage message={errorMessage} type="error"/>}
    </>
  )
};

export default Radio;