import React               from "react";
import {StyledStepsHeader} from "./styles";
import {Heading2}          from "../../../../globalStyledComponents";
import Button              from "../../../../uiKit/button/button";
import {ArrowIcon}         from "../../../../icons/ui/Arrow";
import StoreAgreementStep  from "../../store";
import {useParams}         from "react-router";

export const HeaderStep = () => {
  const {type, id: agreementId} = useParams();
  const isAgreementEdit: boolean = !!type && !!agreementId;
  const [{agreementStep: {agreementStepState: {stepsCount}}}, {agreementStep: actionsAgreementStep}]: any = StoreAgreementStep();
  return (
    <StyledStepsHeader>
      {!isAgreementEdit && <Button onClick={() => actionsAgreementStep.setState({
        agreementStepState: {
          step: 0,
          stepsCount
        }
      })} appearance="text" iconBefore={<ArrowIcon direction="left"/>}>Результаты расчета</Button>}
      <Heading2>{isAgreementEdit ? "Изменение" : "Создание"} заявки на страхование</Heading2>
    </StyledStepsHeader>
  )
}

export default HeaderStep;