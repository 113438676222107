import styled from "styled-components";

export const StyledDownloadPdf = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledFileName = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 1.5rem;
  }
`;

export const StyledOnlineFillingWrapper = styled.div`
  h2 {
    margin-bottom: 4rem; 
  }
  h3 {
    margin-bottom: 2rem;
  }
  
  h4 {
    margin-bottom: 4rem;
  }
`;
